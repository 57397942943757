import { Paper } from "@material-ui/core";
import React from "react";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Align, Spacing } from "../../../Components/Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function AboutUs() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			<Align verticalHeight={_mobile ? 0 : 20}>
				<Paper style={_x(_mobile).aboutUs} elevation={_mobile ? 0 : 1}>
					<div style={_x(_mobile).header}>
						<p style={_x(_mobile).title}>About Quickmechs</p>
					</div>

					<div style={_x(_mobile).main}>
						<p style={_x(_mobile).text1}>
							Quickmechs is the best place to find auto mechanics and workshops
							near you for efficient car repair and great spare part deals.
						</p>

						<p style={_x(_mobile).text1}>
							We understand the inconvenience caused by unforeseen car problems
							and the challenge of finding mechanics whenever this occurs,
							especially in an unfamiliar environment. This is why we created
							Quickmechs, an online platform that connects car owners with
							mechanics and car spare part dealers.
						</p>

						<p style={_x(_mobile).text1}>
							Our mission is to help you find a genuine mechanic or auto shop of
							your choice and have them provide your desired services.
						</p>
						<Spacing vertical={8} />

						<p style={_x().subtitle}>Who We Serve</p>
						<p style={_x().text1}>
							Quickmechs is for <span style={_x().b}>mechanics</span>,
							<span style={_x().b}>spare parts dealers</span>, and
							<span style={_x().b}>workshops</span> that want to connect with
							potential customers by listing their businesses and showcasing
							their expertise on a reliable platform.
						</p>

						<p style={_x().text1}>
							We also serve <span style={_x().b}>car owners</span> by giving
							them access to professional mechanics of their choice whenever
							they need a routine or emergency repair for their vehicle.
						</p>
						<Spacing vertical={8} />

						<p style={_x().subtitle}>Our Values</p>
						<p style={_x().topic}>High-Level Competence</p>
						<p style={_x().text1}>
							Quickmechs promotes high-level competence and effective work
							delivery that is responsive, convenient, and accessible.
						</p>

						<Spacing vertical={2} />
						<p style={_x().topic}>Saftey, Trust, Transparency</p>
						<p style={_x().text1}>
							At Quickmenchs, we emphasize safety, trust, and transparency. This
							is why we ensure that enlisted mechanics and auto shops undergo
							thorough verification and vetting before being recommended to you.
						</p>

						<Spacing vertical={2} />
						<p style={_x().topic}>Reviews and Feedback for better services</p>
						<p style={_x().text1}>
							Quickmechs thrives by letting customers leave reviews and feedback
							about their experiences to help improve the quality of
							professionals and sellers we enlist and recommend.
						</p>
						<Spacing vertical={2} />

						<p style={_x().subtitle}>Join the Quickmechs Train</p>
						<p style={_x().text1}>
							Make a bold move towards an exceptional auto experience. Sign up
							or register as a mechanic or car owner today.
						</p>
					</div>
				</Paper>
			</Align>

			<DownloadApp />

			<Footer />
		</>
	);
}

export default AboutUs;

const _x = (_mobile) => ({
	aboutUs: {
		maxWidth: _mobile ? "100%" : "1200px",
		background: "white",
		marginTop: _mobile ? 0 : 50,
		borderRadius: 10,
		// display: "none",
	},

	header: {
		background: "var(--primary1)",
		borderTopRightRadius: _mobile ? 0 : 10,
		borderTopLeftRadius: _mobile ? 0 : 10,
		display: "flex",
		flexDirection: "column",
		paddingInline: _mobile ? 50 : 200,
	},

	main: {
		padding: 50,
		paddingTop: _mobile ? 10 : 50,
		paddingInline: _mobile ? 50 : 200,
	},

	subtitle: {
		fontWeight: 500,
		marginRight: 8,
		color: "var(--secondary1)",
		fontSize: 18,
	},

	title: {
		fontSize: 17,
		fontWeight: "bold",
		color: "white",
	},

	topic: {
		fontSize: 15,
		color: "lightslategrey",
		fontWeight: 500,
	},

	text1: {
		fontSize: 14,
		fontWeight: 400,
		lineHeight: 1.8,
	},

	b: {
		fontWeight: 500,
		marginLeft: 3,
		marginRight: 3,
		color: "lightslategrey",
	},
});
