import React, {useState} from 'react'
import { IoClose } from "react-icons/io5";
import "./filterbtn.css"

const FilterBtns = ({filterItem, children}) => {
    const [open, setOpen] = useState(true);
    return (
        <div className="displayed_filt">
									{open && <p className="each_filter">
                                       <pre className="pre_filter">{filterItem} </pre> 
                                         &nbsp; 
                                        {/* <span onClick={()=> setOpen(false)} > <IoClose/> </span>  */}
                                        </p>}
								</div>
    )
}

export default FilterBtns
