import axios from "axios";
import { authHeader } from "./auth-header";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;

const ENDPOINTS = {
	USERS: "users/current-user",
	CONTACT: "send-message",
};

const getPublicContent = () => {
	return axios.get(API_URL + ENDPOINTS.USERS + "all");
};

const getUserBoard = () => {
	return axios.get(API_URL + ENDPOINTS.USERS, { headers: authHeader() });
};

const getModeratorBoard = () => {
	return axios.get(API_URL + ENDPOINTS.USERS + "mod", {
		headers: authHeader(),
	});
};

const getAdminBoard = () => {
	return axios.get(API_URL + ENDPOINTS.USERS + "admin", {
		headers: authHeader(),
	});
};

function contactUs(full_name, email, message) {
	return axios
		.post(
			API_URL + ENDPOINTS.CONTACT,
			{
				full_name,
				email,
				message,
			},
			{ headers: authHeader() }
		)
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			console.log(e);
			return { success: false };
		});
}

export default {
	getPublicContent,
	getUserBoard,
	getModeratorBoard,
	getAdminBoard,
	contactUs,
};
