import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Align } from "src/Components/Style/Styles";
import mechService from "src/services/mech.service";

export default function CancelAppointment() {
	const { visitor_id, appointment_id } = useParams();

	const [cancelledstatus, setcancelledstatus] = useState("waiting");

	useEffect(() => {
		console.log("visitor_id : ", visitor_id);
		console.log("appointment_id : ", appointment_id);

		cancelIt();
	}, []);

	async function cancelIt() {
		const res = await mechService.cancelAppointment(appointment_id, visitor_id);

		console.log("response", res);

		setcancelledstatus(res.success ? "true" : "false");
	}

	return (
		<div style={_x.cancelAppointment_cntr}>
			<div style={_x.cancelAppointment}>
				<p style={_x.title}>
					{cancelledstatus === "waiting"
						? ""
						: cancelledstatus === "true"
						? "Appointment Cancelled"
						: "Could Not Cancel Appointment. Please try again."}
				</p>
			</div>
		</div>
	);
}

const _x = {
	cancelAppointment_cntr: {
		height: "90vh",
		display: "grid",
		placeItems: "center",
	},

	cancelAppointment: {
		height: 200,
		width: "80%",
		background: "white",
		textAlign: "center",
		padding: 40,
	},

	title: {
		fontWeight: "bold",
		fontSize: 30,
	},
};
