import React, { useState, useEffect } from 'react';

import axios from 'axios';
import '../seller/dashboard.css';
import AuthService from '../../../services/auth.service';
import { authHeader } from '../../../services/auth-header';
import { useLocation, useHistory, Link, useParams } from 'react-router-dom';
import dashbord from '../../../Resources/Images/dashbord.png';
import opps from '../../../Resources/Images/opps.png';
import search from '../../../Resources/Icons/search.png';
import DealerSingleAd from '../../../Components/Functional/UserAdvert/DealerSingleAd';
import LoadingBar from 'react-top-loading-bar';
import { BsFillFunnelFill } from 'react-icons/bs';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const DealerAds = ({ singleReview }) => {
  const [adData, setAdData] = useState([]);
  const [regular, setRegular] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [message, setMessage] = useState(false);
  const [display, setDisplay] = useState(false);
  const [searching, setSearching] = useState('');
  const [noAd, setNoAd] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [progress, setProgress] = useState(0);

  const currentUser = AuthService.getCurrentUser('user');
  const token = authHeader();
  const token2 = token.Authorization;

  let history = useHistory();
  const { id } = useParams();

  const getEditMenu = (id) => {
    adData.filter((item) => {
      if (item.id === id) {
        setDisplay(true);
      }
    });
  };

  const hideMenu = () => {
    setDisplay(false);
  };

  const getActiveUserAds = () => {
    // if (dealer || mech) {
    setisloading(true);
    setProgress(94);

    axios
      .get(API_URL + 'products/find-products-by-user/' + `${id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        setProgress(0);
        let sorted = response.data.data;
        const testing = sorted.length;
        if (testing === 0) {
          setNoAd(true);
        }

        let sortedByDate = sorted.sort(function (a, b) {
          return new Date(b.date_created) - new Date(a.date_created);
        });

        setisloading(false);
        setAdData(sortedByDate);
        sortedByDate.forEach((each, index) => {});
      })
      .catch((err) => {
        setisloading(false);
        setMessage(true);
        console.log(err);
      });
  };

  useEffect(() => {
    getActiveUserAds();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearching(e.target.value);
  };

  useEffect(() => {
    setFilteredData(
      adData.filter((item) =>
        item.product_title.toLowerCase().includes(searching.toLowerCase())
      )
    );
  }, [searching, adData]);

  return (
    <section style={{ width: '94%' }}>
      {noAd ? (
        <div className="details-info">
          <img src={dashbord} alt="" className="no-ad" />
          <p>There are no available adverts</p>
          <p>Store Adverts will appear here when published</p>
        </div>
      ) : (
        <section className="ad-container">
          <div className="ad-col-wrapper">
            {!isloading && !message ? (
              <section id="search-pr-col">
                <div className="search-wrapper">
                  <div className="dashboard-search-icon">
                    <img src={search} alt="" />
                  </div>
                  <input
                    type="text"
                    value={searching}
                    onChange={handleSearch}
                    placeholder="Type your search"
                  />
                </div>
                <div className="dashboard-filter-icon">
                  <BsFillFunnelFill />
                  <p>Sort</p>
                </div>
              </section>
            ) : null}
            {isloading ? (
              <LoadingBar
                color="#587FC0"
                loaderSpeed={4000}
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
              />
            ) : (
              <>
                {filteredData.length === 0 ? (
                  <div
                    style={{
                      marginTop: '2rem',
                      color: '#fbb500',
                      fontSize: '1.2rem',
                      fontWeight: '500',
                    }}
                  >
                    No Product Matches your Search
                  </div>
                ) : (
                  filteredData.map((item) => {
                    return (
                      <DealerSingleAd
                        key={item.id}
                        title={item.product_title}
                        image={item.product_photo[0]}
                        price={item.price}
                        product_no={item.product_no}
                        date={item.date_created}
                        adLink={`${item.id}/${item.product_title}`}
                        statusCheck={item.status === 'active' ? true : false}
                        statusCheck2={item.status === 'inactive' ? true : false}
                        pricestyle={
                          item.status === 'inactive'
                            ? { color: '#c4c4c4' }
                            : { color: '#587FC0' }
                        }
                        pricestyle2={
                          item.status === 'inactive'
                            ? { color: '#c4c4c4' }
                            : { color: '#646464' }
                        }
                      />
                    );
                  })
                )}
              </>
            )}

            {message ? (
              <div className="details-info" style={{ marginTop: '-3rem' }}>
                <img src={opps} alt="" className="no-ad" />

                <p
                  style={{
                    fontSize: '1rem',
                    marginTop: '-3rem',
                    fontStyle: 'italic',
                  }}
                >
                  Something went wrong, Please Refresh this Screen
                </p>
              </div>
            ) : null}
          </div>
        </section>
      )}
      {isloading && (
        <LoadingBar
          color="#587FC0"
          loaderSpeed={4000}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      )}
    </section>
  );
};

export default DealerAds;
