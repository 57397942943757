import Geocode from "react-geocode";

export const DEV = false;

function geocodeFromAddress(address) {
	return Geocode.fromAddress(address).then(
		(response) => {
			const { lat, lng } = response.results[0].geometry.location;

			return { lat, long: lng };
		},
		(error) => {
			console.error(error);
		}
	);
}

function array_move(arr, old_index, new_index) {
	if (new_index >= arr.length) {
		var k = new_index - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	return arr;
}

String.prototype.toTitleCase = () => {
	var i, j, str, lowers, uppers;
	str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});

	// Certain minor words should be left lowercase unless
	// they are the first or last words in the string
	lowers = [
		"A",
		"An",
		"The",
		"And",
		"But",
		"Or",
		"For",
		"Nor",
		"As",
		"At",
		"By",
		"For",
		"From",
		"In",
		"Into",
		"Near",
		"Of",
		"On",
		"Onto",
		"To",
		"With",
	];

	for (i = 0, j = lowers.length; i < j; i++)
		str = str.replace(
			new RegExp("\\s" + lowers[i] + "\\s", "g"),
			function (txt) {
				return txt.toLowerCase();
			}
		);

	// Certain words such as initialisms or acronyms should be left uppercase
	uppers = ["Id", "Tv"];
	for (i = 0, j = uppers.length; i < j; i++)
		str = str.replace(
			new RegExp("\\b" + uppers[i] + "\\b", "g"),
			uppers[i].toUpperCase()
		);

	return str;
};

function toTitleCase(str) {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

function concatenateDate(val) {
	const monthmap = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	const day = val.toString().split(" ", 3)[2];

	let month = monthmap.indexOf(val.toString().split(" ", 2)[1]) + 1;

	const year = val.toString().split(" ", 4)[3];

	// month = (month.length === 1 ? "0" : null) + month;

	// console.log(val);

	// console.log(`${month >= 10 ? "" : "0"}${month}`);

	return `${year}-${month >= 10 ? "" : "0"}${month}-${day}`;
}

const weekdays = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];

const weekmap = {
	Sun: "Sunday",
	Mon: "Monday",
	Tue: "Tuesday",
	Wed: "Wednesday",
	Thu: "Thursday",
	Fri: "Friday",
	Sat: "Saturday",
};

function getBrowserName() {
	let userAgent = navigator.userAgent;
	let browserName;

	if (userAgent.match(/chrome|chromium|crios/i)) {
		browserName = "chrome";
	} else if (userAgent.match(/firefox|fxios/i)) {
		browserName = "firefox";
	} else if (userAgent.match(/safari/i)) {
		browserName = "safari";
	} else if (userAgent.match(/opr\//i)) {
		browserName = "opera";
	} else if (userAgent.match(/edg/i)) {
		browserName = "edge";
	} else {
		browserName = "No browser detection";
	}

	return browserName;
}

function isEmpty(val) {
	// test results
	//---------------
	// []        true, empty array
	// {}        true, empty object
	// null      true
	// undefined true
	// ""        true, empty string
	// ''        true, empty string
	// 0         false, number
	// true      false, boolean
	// false     false, boolean
	// Date      false
	// function  false

	if (val === undefined) return true;

	if (
		typeof val === "function" ||
		typeof val === "number" ||
		typeof val === "boolean" ||
		Object.prototype.toString.call(val) === "[object Date]"
	)
		return false;

	if (val === null || val.length === 0)
		// null or 0 length array
		return true;

	if (typeof val === "object") {
		// empty object

		var r = true;

		for (var f in val) r = false;

		return r;
	}

	return false;
}

export {
	array_move,
	concatenateDate,
	geocodeFromAddress,
	getBrowserName,
	isEmpty,
	toTitleCase,
	weekdays,
	weekmap,
};
