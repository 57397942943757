import React from 'react'

const EditMech = () => {
    return (
        <div>
            <h1>Mechanic profile Edit Form goes here</h1>
        </div>
    )
}

export default EditMech
