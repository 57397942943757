import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useRef,
} from 'react';
import axios from 'axios';
import AuthService from '../../../services/auth.service';
import { authHeader } from '../../../services/auth-header';
import { useHistory } from 'react-router-dom';
import { AiOutlinePicture } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';
import { FaUserCircle } from 'react-icons/fa';
import ImageUploading from 'react-images-uploading';
import { RiCameraFill, RiDeleteBin6Line } from 'react-icons/ri';
import '../../Home/Profile/profile.css';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ImgDialog from '../../Home/Profile/ImgDialog';
import getCroppedImg from '../../Home/Profile/cropImage';
import { styles } from '../../Home/Profile/styles';
import ProfileDP from 'src/Components/Functional/profileImage/ProfileDP';
import { states } from 'src/Resources/Resources';
import './upload.css';
import LoadingBar from 'react-top-loading-bar';
import Webcam from 'react-webcam';
import Popup from 'src/Components/Functional/Popup/Popup';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const Profile = ({ classes }) => {
  const cam = useRef(null);
  const webcamRef = React.useRef(null);

  const currentUser = AuthService.getCurrentUser('user');
  const activeUser = currentUser.data.user_info;
  const [profile_image, setProfile_image] = useState();
  const [oldImageShowcase, setOldImageShowcase] = useState(false);
  // const [message, setMessage] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropWrapper, setCropWrapper] = useState(false);
  const [btnUp, setBtnUp] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isloading, setisloading] = useState(false);
  const [dogImg, setDogImg] = useState('');
  const [newImage, setNewImage] = useState(false);
  const [captured, setCaptured] = useState('');

  const [valid, setValid] = useState('');
  const [verified, setVerified] = useState(false);

  const [imageBox, setImageBox] = useState(false);
  const [oldProfile, setOldProfile] = useState(false);
  const [profileDetails, setProfileDetails] = useState('');
  const [showpassword, setshowpassword] = useState(false);
  const [showEditBox, setEditBox] = useState(false);
  const [showEditBoxLastName, setEditBoxLastName] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [phoneBox, setPhoneBox] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [message, setMessage] = useState('');
  const [format, setFormat] = useState('');
  const [pass, setPass] = useState(false);
  const [addressBox, setAddressBox] = useState(false);
  const [stateBox, setStateBox] = useState(false);
  const [webcam, setwebcam] = useState(false);
  const [imgSrc, setImgSrc] = React.useState(null);

  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    email: '',
    email_confirmation: '',
    current_password: '',
    password: '',
    password_confirmation: '',
    phone_number: '',
    address: '',
    state: '',
    city: '',
  });

  let history = useHistory();

  function togglePassword() {
    setshowpassword(!showpassword);
  }

  const logout = () => {
    AuthService.logout('user');
    history.push('/auth/login');
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    axios
      .patch(API_URL + 'profile/update', inputs, {
        headers: authHeader(),
      })
      .then((response) => {
        setEditBox(false);
        setEditBoxLastName(false);
        setShowEmail(false);
        setShowPassword(false);
        setSuccess(true);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setFailure(true);
        console.log(error.response.data.errorSource);

        setFormat(
          'Password must be 8 or more characters and must contain atleast one number, a capital letter and a symbol'
        );
        setMessage(resMessage);
      });
  };

  const getuserDetails = () => {
    axios
      .get(API_URL + 'users/current-user?fullDetails=true', {
        headers: authHeader(),
      })
      .then((response) => {
        const updatedDetails = response.data.data.user_info;
        setProfileDetails(updatedDetails);
        // console.log('callback', updatedDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getuserDetails();
  }, []);

  const switchField = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const revealBox = () => {
    setEditBox(true);
  };
  const revealLastname = () => {
    setEditBoxLastName(true);
  };
  const revealemail = () => {
    setShowEmail(true);
  };
  const revealpass = () => {
    setShowPassword(true);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    const loadedImage = imageList;
    let newArry = imageList.map((item) => item.data_url);
    let profilepix = newArry[0];
    setDogImg(profilepix);
    setOldImageShowcase(false);
    setCropWrapper(true);

    setImages(imageList);
    // if (loadedImage) {
    // 	setProfile_image(profilepix);
    // }
  };
  const handleSubmit = (e) => {
    // e.preventDefault();
    setisloading(true);
    setProgress(97);

    axios
      .post(
        API_URL + 'profile/upload-profile-image',
        { profile_image },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setisloading(false);
        setVerified(true);
        const finalImage = res.data.data.profile_image;

        let profileDp = JSON.parse(localStorage.getItem('user'));
        profileDp.data.user_info.profile_image = finalImage;
        localStorage.setItem('user', JSON.stringify(profileDp));
        setImageBox(false);
        setSuccess(true);
        // history.push("/home/mechanic-and-services/");
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setValid('Something went wrong');
        setMessage(resMessage);
      });
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      setImageBox(false);
      const croppedImage = await getCroppedImg(
        dogImg,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      const finalcrop = croppedImage;
      setProfile_image(finalcrop);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, imgSrc]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const handleSkip = () => {
    history.push('/home/mechanic-and-services/');
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setCaptured(imageSrc);
    setCroppedAreaPixels(imageSrc);
    setProfile_image(imageSrc);
  }, [webcamRef, setImgSrc]);

  function removeImage() {
    setImageBox(false);
    setBtnUp(false);
    setNewImage(false);
    setOldImageShowcase(false);
    setOldProfile(false);
    setwebcam(false);
    setProfile_image(null);
    // window.location.reload();
  }

  function retakeImage() {
    // setProfile_image(null);
    setImageBox(true);
    setBtnUp(true);
    setOldProfile(true);
    setNewImage(false);
  }

  return (
    <section className=" up_wrapper">
      <div
        className="upload-container"
        // style={{ display: 'block', paddingLeft: '1rem', height: '236px' }}
      >
        <p
          className="button"
          style={{ fontSize: '18px', fontWeight: '500', marginTop: '24px' }}
        >
          Upload your image
        </p>

        {profile_image ? (
          <div style={_x.img_preview}>
            <IoClose
              className="cancel-main-box"
              style={_x.close}
              onClick={removeImage}
              color="grey"
            />

            <img
              src={profile_image}
              alt=""
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                // backgroundColor: "red",
              }}
            />

            <RiCameraFill
              color="white"
              style={_x.retake}
              onClick={retakeImage}
            />
          </div>
        ) : (
          <div className="upload-main-col">
            <p
              className="button"
              style={{
                fontSize: '14px',
                color: '#222',
                fontWeight: '400',
                margin: '15px 0 -15px',
                textAlign: 'left',
                alignSelf: 'baseline',
              }}
            >
              Upload Profile Photo
            </p>
            <div
              className="inner-upload"
              // style={{ display: 'flex', alignItems: 'center' }}
            >
              <p
                className="choose"
                onClick={() => {
                  setImageBox(true);
                  setBtnUp(true);
                  setOldProfile(true);
                }}
                style={{
                  backgroundColor: 'rgba(196, 196, 196, 0.54)',
                  borderRadius: '6px',
                  width: '382px',
                  textAlign: 'center',
                  fontSize: '16px',
                  padding: '10px 15px',
                  fontWeight: '500',
                  cursor: 'pointer',
                  margin: '1rem 1rem 0rem 0',
                }}
              >
                Choose File
              </p>
              <p
                style={{
                  paddingBottom: '30px',
                  marginTop: '5px',
                  textAlign: 'center',
                }}
              >
                No File Chosen
              </p>
            </div>
          </div>
        )}
        <div className="skip-col">
          <button
            className="dis-btn"
            style={{ cursor: profile_image ? 'pointer' : 'not-allowed' }}
            onClick={profile_image ? handleSubmit : null}
          >
            Submit
          </button>
          <p
            className="skip"
            onClick={handleSkip}
            style={{
              color: 'red',
              fontWeight: '500',
              fontSize: '13px',
              cursor: 'pointer',
            }}
          >
            Skip
          </p>
        </div>
      </div>

      {imageBox ? (
        <section className="imageUpload-container">
          <div className="imageUpload-wrapper up_img_wrapper">
            <div className="photo-ring">
              {oldProfile && <FaUserCircle className="upload_user_icon_main" />}
              <ImageUploading
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <>
                    <div className="upload-col">
                      &nbsp;
                      <section className="image-list-colum">
                        {imageList.map((image, index) => (
                          <div className="image-item__btun-wrapper"></div>
                        ))}
                      </section>
                      <p
                        className="image-upload-btn"
                        style={
                          isDragging
                            ? { color: 'red', width: '150px' }
                            : undefined
                        }
                        // onClick={() => { setOldProfile(false); setOldImageShowcase(true); setBtnUp(false) }}
                        {...dragProps}
                      >
                        {btnUp && (
                          <p
                            style={{
                              fontSize: '14px',
                              padding: '0',
                              margin: '0px 0 30px',
                              borderRadius: '5px',
                              backgroundColor: 'var(--primary1)',
                              color: '#fff',
                              fontWeight: '700',
                              cursor: 'pointer',
                              padding: '5px 15px',
                            }}
                            onClick={() => {
                              setwebcam(true);
                              setBtnUp(false);
                              setOldProfile(false);
                            }}
                          >
                            Take Photo
                          </p>
                        )}
                      </p>
                      {btnUp && (
                        <p
                          style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            padding: '0',
                            margin: '10px 0 20px',
                            textAlign: 'center',
                          }}
                        >
                          or
                        </p>
                      )}
                      <p
                        className="image-upload-btn"
                        style={
                          isDragging
                            ? { color: 'red', width: '150px' }
                            : undefined
                        }
                        // onClick={() => {  setOldImageShowcase(true); setBtnUp(false) }}
                        onClick={() => {
                          onImageUpload();
                          setBtnUp(false);
                          setOldProfile(false);
                        }}
                        {...dragProps}
                      >
                        {isDragging ? (
                          'Drop image here'
                        ) : (
                          <>
                            {btnUp ? (
                              <>
                                <AiOutlinePicture />
                                <p
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    padding: '0',
                                    margin: '10px 0 10px',
                                  }}
                                >
                                  Choose from Gallery
                                </p>
                              </>
                            ) : null}
                          </>
                        )}
                      </p>
                    </div>
                    {webcam && (
                      <div
                        className="newProfile-wrapper"
                        style={{ flexDirection: 'column' }}
                      >
                        <Webcam
                          style={{
                            marginTop: '5rem',
                            border: '2px solid var(--primary1)',
                          }}
                          width={'70%'}
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          mirrored={true}
                        />

                        <div>
                          <button
                            style={{
                              fontSize: '14px',
                              padding: '0',
                              margin: '40px 0 30px',
                              borderRadius: '5px',
                              zIndex: '500',
                              backgroundColor: 'var(--primary1)',
                              color: '#000',
                              fontWeight: '700',
                              border: '1px solid #000',
                              cursor: 'pointer',
                              padding: '5px 15px',
                            }}
                            onClick={() => {
                              capture();
                              setwebcam(false);
                              setCropWrapper(true);
                            }}
                          >
                            Capture
                          </button>
                        </div>
                      </div>
                    )}
                    {oldImageShowcase ? (
                      <div className="oldImage-wrapper">
                        <div className="oldProf">
                          <img
                            src={currentUser.data.user_info.profile_image}
                            alt=""
                          />
                        </div>
                        <button
                          className="main-upload-btn"
                          onClick={() => {
                            onImageUpload();
                            setBtnUp(false);
                          }}
                        >
                          Upload Photo
                        </button>
                      </div>
                    ) : null}
                    <>
                      {cropWrapper ? (
                        <div className="zoom-container">
                          <div className={classes.cropContainer}>
                            <Cropper
                              image={dogImg || imgSrc}
                              crop={crop}
                              rotation={rotation}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onRotationChange={setRotation}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className={classes.controls}>
                            <div className={classes.sliderContainer}>
                              <Typography
                                variant="overline"
                                classes={{ root: classes.sliderLabel }}
                              >
                                Zoom
                              </Typography>
                              <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                classes={{ root: classes.slider }}
                                style={{ fontFamily: 'montserrat' }}
                                onChange={(e, zoom) => setZoom(zoom)}
                              />
                            </div>
                            <div className={classes.sliderContainer}>
                              <Typography
                                variant="overline"
                                classes={{ root: classes.sliderLabel }}
                              >
                                Rotation
                              </Typography>
                              <Slider
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="Rotation"
                                classes={{ root: classes.slider }}
                                onChange={(e, rotation) =>
                                  setRotation(rotation)
                                }
                              />
                            </div>
                          </div>
                          <div style={{ width: '100%', display: 'flex' }}>
                            <Button
                              onClick={() => {
                                showCroppedImage();
                                setCropWrapper(false);
                                setBtnUp(false);
                                setNewImage(true);
                              }}
                              variant="contained"
                              className="upload_btn_profile"
                              classes={{ root: classes.cropButton }}
                            >
                              Done
                            </Button>
                          </div>
                          <ImgDialog img={croppedImage} onClose={onClose} />
                        </div>
                      ) : null}
                      {newImage ? (
                        <>
                          <div className="newProfile-wrapper">
                            <div className="oldProf">
                              <img
                                src={croppedImage || imgSrc}
                                alt="profile-photo"
                              />
                              <button
                                className="new-upload-btn"
                                style={{
                                  bottom: -50,
                                }}
                                onClick={() => {
                                  // handleSubmit();
                                  setBtnUp(false);
                                }}
                              >
                                Confirm
                              </button>

                              <button
                                className="new-upload-btn"
                                style={{
                                  bottom: -110,
                                  width: 105,
                                }}
                                onClick={retakeImage}
                              >
                                Retake
                              </button>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  </>
                )}
              </ImageUploading>

              <div className="updat-btn-wrap">
                {/* <button className="submit-btn2" onClick={handleSubmit}>Update </button> */}
                <button
                  className="cancel-main-box"
                  onClick={() => {
                    setImageBox(false);
                    setBtnUp(false);
                    setNewImage(false);
                    setOldImageShowcase(false);
                    setOldProfile(false);
                    setwebcam(false);
                    window.location.reload();
                  }}
                >
                  <IoClose />
                </button>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      {isloading && (
        <LoadingBar
          color="#587FC0"
          loaderSpeed={4000}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      )}

      <Popup
        isOpen={success}
        closePressed={() => {
          setSuccess(false);
          window.location.reload();
        }}
        continuePressed={() => {
          setSuccess(false);
          history.push('/home/mechanic-and-services/');
        }}
        popupMessage={'Profile Updated Successfully'}
        headerText={'Success'}
        style={{ width: 450 }}
      />
    </section>
  );
};

export default withStyles(styles)(Profile);

const _x = {
  img_preview: {
    height: 100,
    width: 100,
    marginBottom: 15,
    position: 'relative',
  },

  close: {
    position: 'absolute',
    height: 18,
    width: 18,
    borderRadius: 18,
    background: 'white',
    display: 'grid',
    justifyContent: 'center',
    top: 5,
    right: 5,
    cursor: 'pointer',
  },

  retake: {
    position: 'absolute',
    height: 20,
    width: 20,
    display: 'grid',
    justifyContent: 'center',
    top: 75,
    right: 5,
    cursor: 'pointer',
  },
};
