import React, { useState, useRef } from "react";
import Footer from "../../../Components/Functional/Footer/Footer";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import { Align, Spacing } from "../../../Components/Style/Styles";
import axios from "axios";
import { RiCameraFill, RiDeleteBin6Line } from "react-icons/ri";
import NumberFormat from "react-number-format";
import AuthService from "../../../services/auth.service";
import { authHeader } from "../../../services/auth-header";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Select from "react-validation/build/select";
import { Modal } from "@material-ui/core";
import Textarea from "react-validation/build/textarea";
import { ThreeCircles } from "react-loader-spinner";
import "./freead.css";
import ImageUploading from "react-images-uploading";
import { makes3, category, years } from "../../../Resources/Resources";
import close2 from "../../../Resources/Icons/close2.png";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ModalComponent from "./ModalComponent";
import { createProduct } from "src/services/job/job.service";

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const required = (value) => {
  if (!value) {
    return (
      <div className="warning" role="alert">
        &#9432; This field is required!
      </div>
    );
  }
};

const PostFreeAd = () => {
  const form = useRef();
  const checkBtn = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [err, setErr] = useState(false);
  const [errMess, setErrMess] = useState("");
  const [showfielderror, setshowfielderror] = useState(false);

  const [inputs, setInputs] = useState({
    category_name: "Select Category",
    sub_category_name: "",
    product_title: "",
    product_type: "",
    keyword: "",
    condition: "",
    year: "",
    make: "Select Make",
    model: "",
    warranty: "",
    description: "",
    price: "",
    product_photo: "",
    negotiable: "",
    product_title: "",
  });

  function closeModal() {
    setSubmitted(false);
    window.location.reload();
  }

  function selectDropDown(e, field) {
    const { value } = e.target;

    if (field === "make") {
      if (value === "") {
      } else setInputs({ ...inputs, make: value });
    }
  }
  const currentUser = AuthService.getCurrentUser("user");
  const token = currentUser.data.access_token;

  const submitAdForm = async (e) => {
    e.preventDefault();
    setisloading(true);
    form.current.validateAll();
    let product = {
      product_title: inputs.product_title,
      product_type: inputs.product_type,
      condition: inputs.condition,
      year: inputs.year,
      make: inputs.make,
      model: inputs.year,
      warranty: inputs.warranty,
      price: inputs.price,
      category_name: inputs.category_name,
      sub_category_name: inputs.sub_category_name,
      description: inputs.description,
      relative_keyword: inputs.keyword,
      product_photo: [...inputs?.product_photo],
    };

    if (checkBtn.current.context._errors.length === 0) {
      try {
        const result = await createProduct(product);
        if (result?.status === 200 || result.status === 201) {
          window.scrollTo(0, 0);
          setshowfielderror(false);
          setInputs({
            category_name: "Select Category",
            sub_category_name: "",
            product_title: "",
            product_type: "",
            keyword: "",
            condition: "",
            year: "",
            make: "Audi",
            model: "2.0T",
            warranty: "",
            description: "",
            price: "",
            product_photo: "",
            negotiable: "",
            product_title: "",
          });
          setisloading(false);

          setSubmitted(true);
        }
      } catch (error) {
        window.scrollTo(0, 0);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setisloading(false);
        setErr(true);
        setErrMess(resMessage);
        console.log(resMessage);
      }

      console.log(inputs);
    } else {
      setisloading(false);
    }
  };
  const changeHandle = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 5;

  const [checkboxValue, setCheckboxValue] = useState(true);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    const loadedImage = imageList;
    let newArry = imageList.map((item) => item.data_url);
    setImages(imageList);
    if (loadedImage) {
      setInputs({
        ...inputs,
        product_photo: newArry,
      });
    }
  };

  const checkchange = (e) => {
    setCheckboxValue(!checkboxValue);
    if (checkboxValue) {
      setInputs({
        ...inputs,
        negotiable: "1",
      });
    } else {
      setInputs({
        ...inputs,
        negotiable: null,
      });
    }
  };

  return (
    <>
      <main className="post_wrapper">
        <section>
          <div style={_x.title_sect}>
            <h2
              style={{
                boxShadow: "0px 6px 8px #c4c4c470",
                paddingBottom: "1rem",
                marginTop: 0,
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Post Your Free Ad
            </h2>
            <p className="post_tag">
              Make sure you fill in all the necessary fields and upload all the
              required files.
            </p>
          </div>
          <div className="form_title">
            <p>Ad Details</p>
          </div>
          <Form
            id="ad-form"
            className="ad-form"
            onSubmit={submitAdForm}
            ref={form}
          >
            <div>
              <label htmlFor="Product">
                Product Category
                <Select
                  className="cat"
                  name="category_name"
                  value={inputs.category_name}
                  validations={[required]}
                  onChange={changeHandle}
                  onClick={(e) => selectDropDown(e, "category_name")}
                >
                  {Object.keys(category).map((key, val) => {
                    return (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    );
                  })}
                </Select>
              </label>

              <label htmlFor="Product">
                Sub-Category
                <Select
                  name="sub_category_name"
                  value={inputs.sub_category_name}
                  validations={[required]}
                  onChange={changeHandle}
                  onClick={(e) => selectDropDown(e, "sub_category_name")}
                >
                  <option value="">Select sub-category...</option>
                  {category[inputs.category_name].map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Select>
              </label>
            </div>
            <div>
              <label htmlFor="Product">
                Product Title
                <Input
                  type="text"
                  placeholder="Enter Item Title"
                  name="product_title"
                  maxLength="60"
                  validations={[required]}
                  onChange={changeHandle}
                  value={inputs.product_title}
                />
              </label>
            </div>
            <div>
              <label htmlFor="Product">
                Relative Keyword
                {/* (Optional) */}
                <input
                  type="text"
                  placeholder="Enter a keyword"
                  name="keyword"
                  onChange={changeHandle}
                  value={inputs.keyword}
                />
              </label>

              <label htmlFor="condition">
                Condition
                <Select
                  name="condition"
                  value={inputs.condition}
                  validations={[required]}
                  onChange={changeHandle}
                >
                  <option value="">Select Condition</option>
                  <option value="New">New</option>
                  <option value="Fairly Used">Fairly Used </option>
                </Select>
              </label>
            </div>
            <div>
              <label htmlFor="make">
                Make
                <Select
                  name="make"
                  value={inputs.make}
                  validations={[required]}
                  onChange={changeHandle}
                  onClick={(e) => selectDropDown(e, "make")}
                >
                  {Object.keys(makes3).map((key, val) => {
                    return (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    );
                  })}
                </Select>
              </label>

              <label htmlFor="model">
                Model
                <Select
                  name="model"
                  value={inputs.model}
                  validations={[required]}
                  onChange={changeHandle}
                  onClick={(e) => selectDropDown(e, "model")}
                >
                  <option value="">Select Model</option>

                  {makes3[inputs.make].map((eachModel) => {
                    return (
                      <option key={eachModel} value={eachModel}>
                        {eachModel}
                      </option>
                    );
                  })}
                </Select>
              </label>
            </div>
            <div>
              <label htmlFor="year">
                Year
                <Select
                  name="year"
                  value={inputs.year}
                  validations={[required]}
                  onChange={changeHandle}
                >
                  <option value="">Select Year</option>
                  {years.map((e, index) => (
                    <option key={index} value={e}>
                      {e}
                    </option>
                  ))}
                </Select>
              </label>

              <label htmlFor="warranty">
                Warranty
                <Select
                  name="warranty"
                  value={inputs.warranty}
                  validations={[required]}
                  onChange={changeHandle}
                >
                  <option value="make">Select Duration</option>
                  <option value="1 week">1 Week</option>
                  <option value="2 weeks">2 Weeks</option>
                  <option value="1 month">1 Month</option>
                  <option value="2 months">2 Months</option>
                  <option value="3 months">3 Months</option>
                  <option value="4 months">4 Months</option>
                  <option value="6 months">6 Months</option>
                  <option value="1 year">1 year</option>
                  <option value="1 year and above">1 year and above</option>
                </Select>
              </label>
            </div>
            <div>
              <label htmlFor="text-area">
                Description
                <Textarea
                  type="text"
                  placeholder="Type Description"
                  rows="3"
                  name="description"
                  minLength="10"
                  maxLength="500"
                  validations={[required]}
                  onChange={changeHandle}
                  value={inputs.description}
                />
                <p className="input-info">
                  Field must be between 10 and 500 characters. Note: CAPS LOCK
                  text will be automatically edited
                </p>
              </label>
            </div>

            <div>
              <label htmlFor="price" id="price">
                Price
                <div className="price-col">
                  <p>₦</p>
                  <NumberFormat
                    thousandSeparator={true}
                    placeholder="Enter Price"
                    name="price"
                    onChange={changeHandle}
                    value={inputs.price}
                    onKeyPress={(e) => {
                      if (e.key === "e" || e.key === "-" || e.key === "+") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </label>
              <label htmlFor="photo">
                Upload Item Photo
                <div
                  style={{
                    height: "40px",
                    fontSize: "1.1rem",
                    marginTop: "10px",
                  }}
                >
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div>
                        <p
                          className="image-up"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          +
                        </p>
                        &nbsp;
                        <section className="image-list-col">
                          {imageList.map((image, index) => (
                            <div
                              key={index}
                              className="image-item"
                              style={{ marginBottom: 15 }}
                            >
                              <img
                                src={image["data_url"]}
                                alt="quickmechs-product-image"
                                width="50"
                                height="50"
                              />
                              <div className="image-item__btn-wrapper">
                                <p
                                  className="reload"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <RiCameraFill />
                                </p>
                                <p
                                  className="reloadbin"
                                  onClick={() => onImageRemove(index)}
                                >
                                  x
                                </p>
                              </div>
                            </div>
                          ))}
                        </section>
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </label>
            </div>

            <div className="negotiate">
              <input
                type="checkbox"
                className="negotiable"
                name="negotiable"
                value={inputs.negotiable}
                checked={!checkboxValue}
                onChange={checkchange}
              />
              <p> Negotiable </p>
            </div>

            <button type="submit" className="post_btn">
              {isloading ? (
                <>
                  <ThreeCircles
                    height="20"
                    width="20"
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                  />
                </>
              ) : (
                "Submit"
              )}
            </button>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>

          {submitted && (
            <section style={_x.modal_bg}>
              <div
                open={submitted === false}
                // onClick={closeModal}
                style={{ border: "none" }}
              >
                <div style={_x.modall}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img
                      onClick={closeModal}
                      style={_x.close}
                      src={close2}
                      alt="close"
                    />
                  </div>

                  <p
                    style={{
                      fontFamily: "montserrat",
                      fontSize: "14px",
                      textAlign: "center",
                      color: "green",
                      fontSize: "1.2rem",
                      fontWeight: "500",
                    }}
                  >
                    Your Ad has been Successfully Submitted
                  </p>
                  <article style={{ display: "flex" }}>
                    <button onClick={closeModal} style={_x.continue}>
                      Post another Ad
                    </button>
                    <Link
                      to="/home/dashboard"
                      style={{ textDecoration: "none" }}
                    >
                      <button style={_x.continue}>Go to Dashboard</button>
                    </Link>
                  </article>
                </div>
              </div>
            </section>
          )}
        </section>
        <Spinner isloading={isloading} />
        <Helmet>
          <title>{"Post Free Advert - quickmechs Solutions"}</title>
        </Helmet>
      </main>
      <Spacing vertical={100} />
      {/* <DownloadApp /> */}
      {/* <Footer /> */}
      <ModalComponent modalState={modalState} setModalState={setModalState} />
      {/* ============ */}

      {err && (
        // <section style={_x.modal_bg}>
        <Modal open={err === false}>
          <div
            // onClick={closeModal}
            style={{ border: "none" }}
          >
            <div style={_x.modal2}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  onClick={() => setErr(false)}
                  style={_x.close}
                  src={close2}
                  alt="close"
                />
              </div>

              <p
                style={{
                  fontFamily: "montserrat",
                  fontSize: "14px",
                  textAlign: "center",
                  color: "red",
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  marginTop: "0px",
                  paddingTop: "0px",
                }}
              >
                {errMess === "Invalid argument supplied for foreach()"
                  ? "No Image Selected"
                  : errMess === "Network Error"
                  ? "Image(s) exceeds size limit"
                  : errMess}
              </p>
            </div>
          </div>
        </Modal>
        // </section>
      )}
    </>
  );
};

export default PostFreeAd;

const _x = {
  btn: {
    background: "transparent",
    height: 30,
    width: 20,
    fontSize: 22,
    color: "grey",
    borderRadius: 0,
    padding: 0,
    margin: 0,
  },
  title_sect: {
    marginBottom: "53px",
    textAlign: "center",
  },

  header: {
    fontWeight: "bold",
    fontSize: 15,
  },
  header2: {
    fontWeight: "normal",
    fontSize: "14px",
    marginBottom: "2px",
    color: "#222222",
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
  },
  btn2: {
    color: "var(--primary1)",
    fontFamily: "montserrat",
    paddingTop: "10px",
  },
  modall: {
    background: "white",
    // width: 374,
    height: 196,
    outline: "none",
    borderRadius: 6,
    margin: "170px auto",
    display: "grid",
    placeItems: "center",
    padding: 20,
    position: "absolute",
    top: "10%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "1px 1px 18px #00000040",
  },

  continue: {
    height: 40,
    // width: 137,
    backgroundColor: "var(--primary2)",
    paddingLeft: 15,
    paddingRight: 15,
    border: "1px solid",
    borderRadius: 6,
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: "1.7rem",
    marginLeft: "1rem",
  },

  close: {
    cursor: "pointer",
  },
  modal_bg: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100%",
    backgroundColor: "#c4c4c4",
    overflow: "hidden",
  },
};
