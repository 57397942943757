import React from 'react';
import '../../../Routes/Home/SparePartShowCase/showcase.css';
import {
  SiFacebook,
  SiWhatsapp,
  SiInstagram,
  SiLinkedin,
} from 'react-icons/si';
import { AiFillTwitterCircle, AiOutlineMail } from 'react-icons/ai';
import { RiWhatsappFill } from 'react-icons/ri';
import { FaLinkedinIn } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from 'react-share';
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  TelegramIcon,
  EmailIcon,
  FacebookMessengerIcon,
} from 'react-share';
import facebookSvg from '../../../Resources/Icons/facebook.svg';
import twitterSvg from '../../../Resources/Icons/twitterr.svg';
import whatsappp from '../../../Resources/Icons/whatsappp.svg';
import maill from '../../../Resources/Icons/maill.svg';

const ShareBtns = ({ title, link, description, quote, setrevealShareBtns }) => {
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <a
        href={`mailto:${email}?subject=${
          encodeURIComponent(subject) || ''
        }&body=${encodeURIComponent(body) || ''}`}
      >
        {children}
      </a>
    );
  };

  return (
    <div className="social-share-btn">
      <div className="mobileShareBar">
        <p>Share this product with friends</p>
        <p onClick={() => setrevealShareBtns(false)}>
          <IoClose size={30} />
        </p>
      </div>
      <FacebookShareButton
        url={
          link
          // "https://quickmechs.com/home/spare-parts/853ec372007b045280172145a049ff53/Sfasfasf"
        }
        title={title}
        quote={quote}
        hashtag={'#quickmechs'}
        description={description}
        className="each-share-button"
      >
        <div
          className="tab-to-view-share"
          // onClick={()=> setRevealPhone(true)}
          // onClick={confirmLogin}
        >
          <img
            src={facebookSvg}
            alt=""
            style={{
              marginRight: '1rem',
              paddingTop: '5px',
            }}
          />
          Facebook
        </div>
      </FacebookShareButton>

      <TwitterShareButton
        title={title}
        url={link}
        via={'quickmechs'}
        hashtags={['quickmechs', 'CarSpareParts']}
        className="each-share-button"
      >
        <div
          className="tab-to-view-share"
          // onClick={()=> setRevealPhone(true)}
          // onClick={confirmLogin}
        >
          <img
            src={twitterSvg}
            alt=""
            style={{
              marginRight: '1rem',
              paddingTop: '5px',
            }}
          />
          Twitter
        </div>
      </TwitterShareButton>
      <WhatsappShareButton
        url={link}
        title={title}
        className="each-share-button"
      >
        <div
          className="tab-to-view-share"
          // onClick={()=> setRevealPhone(true)}
          // onClick={confirmLogin}
        >
          <img
            src={whatsappp}
            alt=""
            style={{
              marginRight: '1rem',
              paddingTop: '5px',
            }}
          />
          WhatsApp
        </div>
      </WhatsappShareButton>

      <EmailShareButton
        url={link}
        subject={
          title + ' - Product Shared from quickmechs Mechanic and Services'
        }
        body={description}
        className="each-share-button "
        separator={' '}
      >
        <Mailto
          email=""
          subject="Message from Quickmechs"
          body="Hello from Quickmechs"
        >
          <div
            className="tab-to-view-share"

            // onClick={()=> setRevealPhone(true)}
            // onClick={confirmLogin}
          >
            <img
              src={maill}
              alt=""
              style={{
                marginRight: '1rem',
                paddingTop: '5px',
              }}
            />
            Email
          </div>
        </Mailto>
      </EmailShareButton>
    </div>
  );
};

export default ShareBtns;
