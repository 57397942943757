/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import { RatingStar } from "rating-star";
import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { FaHeart, FaRegHeart, FaShareAlt } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import LoginPopUp from "src/Components/Functional/loginpop/LoginPopUp";
import ShareBtns from "src/Components/Functional/share-buttons/ShareBtns";
import { getBrowserName } from "src/Global/Global";
import { authHeader } from "src/services/auth-header";
import authService from "src/services/auth.service";
import mechService from "src/services/mech.service";
import AdService from "../../../Components/Functional/AdService/AdService";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Align, Hr, Margin, Spacing } from "../../../Components/Style/Styles";
import caution from "../../../Resources/Icons/caution.png";
import star from "../../../Resources/Icons/star-yellow.png";
import Comment from "./Comment/Comment";
import MechOfficeTopside from "./MechOfficeTopside";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;

function MechOffice() {
	const _comment =
		"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including version of Lorem Ipsum.";

	const { mechId, vehicle_query, category, sub_category } = useParams();

	const history = useHistory();

	const [reviewcomments, setreviewcomments] = useState({});

	const [loginpopup, setloginpopup] = useState({
		caller: "helpful",
		value: false,
		title: "",
		description: "",
	});

	const [mechoffice, setmechoffice] = useState({
		email: "",
		phoneNumber: "",
		address: "",
		shop_name: "",
		description: "",
		mechanicId: "",
		mechanicShopId: "",
		workingHours: "",
		schedule: "",
		customer_reviews: "",
		city: "",
		state: "",
		category: "",
		sub_category: "",
		vehicle_type: "",
		storeImg: "",
		liked: false,
		instant: "",
		mobile: "",
	});

	const [showcovid, setshowcovid] = useState(true);
	const [revealShareBtns, setrevealShareBtns] = useState(false);
	const [isLiked, setisLiked] = useState(false);

	const _mobile = useMediaQuery("(max-width:800px)");

	useEffect(() => {
		getMechReviews();
	}, [mechoffice]);

	const likeProduct = () => {
		axios
			.post(API_URL + `mechanic/${mechoffice.mechanicShopId}/like`, "body", {
				headers: authHeader(),
			})
			.then((res) => setisLiked(true))
			.catch((err) => console.log(err));
	};

	const unlikeProduct = () => {
		axios
			.post(API_URL + `mechanic/${mechoffice.mechanicShopId}/unlike`, "body", {
				headers: authHeader(),
			})
			.then((res) => setisLiked(false))
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		(async () => {
			const res = await mechService.getMechanicShop(mechId);

			if (res.success) {
				setmechoffice({
					email: res.data.user.email,
					mechanicId: res.data.user.id,
					phoneNumber: res.data.user.phone_number,
					address: res.data.office_address,
					shop_name: res.data.shop_name,
					description: res.data.shop_description,
					workingHours: res.data.working_hours,
					schedule: res.data.schedule_data,
					customer_reviews: res.data.customer_reviews,
					city: res.data.user.city,
					state: res.data.user.state,
					vehicle_type: vehicle_query === "-" ? "" : vehicle_query,
					category: category,
					sub_category: sub_category,
					storeImg: res.data.company_photo,
					mechanicShopId: res.data.id,
					liked: res.data.liked,
					instant: res.data.instant === "yes" ? true : false,
					mobile: res.data.mobile === "yes" ? true : false,
				});

				setisLiked(res.data.liked);
			}
		})();
	}, [loginpopup]);

	async function getMechReviews() {
		const resp = await mechService.getReviews(mechId);

		let init_review_comment = {};

		if (resp.success) {
			resp.data.map((e) => {
				init_review_comment[e.id] = {
					id: e.id,
					display_name: e.display_name,
					display_photo: e.display_photo,
					description: e.description,
					date_created: e.date_created,
					experience: e.experience,
					headline: e.headline,
					written_review: e.written_review,
					review_photo: e.review_photo,
					reportAbuse: e.reportAbuse,
					found_helpful: e.found_helpful.includes(
						authService.getCurrentUser("user")?.data?.user_info?.id
					),
					all_helpful: e.found_helpful,
				};

				return null;
			});

			setreviewcomments(init_review_comment);
		}
	}

	function goToReview() {
		if (authService.getCurrentUser("user")) {
			history.push({
				pathname: "/home/mechanic-and-services/rate-service",
				state: { mechanicId: mechId },
			});
		} else {
			setloginpopup({ ...loginpopup, caller: "give-review", value: true });
		}
	}

	function onLogin(status) {
		if (status === "success") {
			setloginpopup({ ...loginpopup, value: false });

			if (loginpopup.caller === "give-review") {
				history.push({
					pathname: "/home/mechanic-and-services/rate-service",
					state: { mechId },
				});
			}

			if (loginpopup.caller === "abuse") {
				history.push({
					pathname: "/home/report-abuse",
					state: {
						title: loginpopup.title,
						description: loginpopup.decription,
					},
				});
			}
		}
	}

	function reportAbuse(title, description) {
		if (authService.getCurrentUser("user")) {
			history.push({
				pathname: "/home/report-abuse",
				state: { title, description },
			});
		} else {
			setloginpopup({
				...loginpopup,
				caller: "abuse",
				value: true,
				title: title,
				description: description,
			});
		}
	}

	async function foundHelpful(reviewId) {
		if (authService.getCurrentUser("user")) {
			setreviewcomments({
				...reviewcomments,
				[`${reviewId}`]: {
					...reviewcomments[reviewId],
					found_helpful: null,
				},
			});

			const res = await mechService.findHelpful(reviewId);

			setreviewcomments({
				...reviewcomments,
				[`${reviewId}`]: {
					...reviewcomments[reviewId],
					found_helpful: res.success ? true : false,
				},
			});
		} else {
			setloginpopup({ ...loginpopup, caller: "helpful", value: true });
		}
	}

	return (
		<>
			<Align verticalHeight={0}>
				<div style={_x(_mobile).homeRoute3}>
					<div>
						<Margin left={0} right={0}>
							{showcovid && (
								<div style={_x(_mobile).banner}>
									<img src={caution} alt="caution" style={_x().caution} />

									<div style={_x().banner_col2}>
										<p style={_x().banner_text}>
											Most of our shops may have restrictions in place.
											including self-quarantine, due to COVID-19.
										</p>

										<div style={{ display: "flex" }}>
											<Link
												to="/home/covid-assessment"
												style={{ textDecoration: "none" }}
											>
												<button style={_x().banner_btn}>Find out more</button>
											</Link>

											<Spacing horizontal={40} />

											<button
												style={_x().banner_btn}
												onClick={() => setshowcovid(false)}
											>
												Dismiss
											</button>
										</div>
									</div>
								</div>
							)}
						</Margin>

						<Spacing vertical={40} />

						{_mobile && (
							<div style={_x(_mobile).storeImg_cntr}>
								<img style={_x(_mobile).storeImg} src={mechoffice?.storeImg} />
								<div className="mech-like">
									<p
										className="likeBtn"
										onClick={isLiked ? unlikeProduct : likeProduct}
									>
										{isLiked ? (
											<FaHeart color={"red"} size={20} />
										) : (
											<FaRegHeart color={"#646464"} size={20} />
										)}
									</p>
									<p
										onClick={() => setrevealShareBtns(!revealShareBtns)}
										className="share"
									>
										<FaShareAlt color={"#646464"} size={20} />
									</p>
									{revealShareBtns && (
										<div className="share-icon-btn">
											<ShareBtns
												setrevealShareBtns={setrevealShareBtns}
												title={mechoffice.shop_name}
												link={`https://staging.quickmechs.com/home/mech-office/${mechoffice.mechanicShopId}/-/${mechoffice.category}/${mechoffice.sub_category}/`}
												// description={'info.description'}
											/>
										</div>
									)}
								</div>
							</div>
						)}

						<Paper style={_x().main} elevation={_mobile ? 0 : 1}>
							<div style={_x().main_left}>
								<MechOfficeTopside
									mechanicShopId={mechoffice.mechanicShopId}
									name={mechoffice.shop_name}
									email={mechoffice.email}
									phoneNumber={mechoffice.phoneNumber}
									address={mechoffice.address}
									workingHours={mechoffice.workingHours}
									mechanicId={mechoffice.mechanicId}
									city={mechoffice.city}
									state={mechoffice.state}
									category={mechoffice.category}
									sub_category={mechoffice.sub_category}
									vehicle_type={mechoffice.vehicle_type}
									customer_reviews={mechoffice.customer_reviews}
									liked={mechoffice.liked}
									instant={mechoffice.instant}
									mobile={mechoffice.mobile}
								/>

								<p style={_x().header}>Ratings & Reviews</p>

								<div style={_x(_mobile).ratings}>
									<div style={_x().ratings_left}>
										<div style={_x().ratings_header}>
											{mechoffice.customer_reviews.average_overall_rating}
										</div>

										<RatingStar
											rating={
												mechoffice.customer_reviews.average_overall_rating
											}
											noBorder
											size={20}
											colors={{ mask: "#FBB500", rear: "#c4c4c4" }}
										/>

										<p style={_x().ratings_sub_header}>
											{mechoffice.customer_reviews.total_rating} Customer
											Ratings
										</p>
									</div>

									<div style={_x().ratings_right}>
										<div style={_x().ratings_right_row}>
											<div style={_x().num_star}>
												<p style={_x().num}>5</p>
												<img src={star} alt="star" style={_x().star} />
											</div>

											<ProgressBar
												height={7}
												width={_mobile ? "60vw" : 279}
												bgcolor="#01195A"
												isLabelVisible={false}
												completed={
													mechoffice.customer_reviews["percentageRatings"]?.[5]
												}
											/>
										</div>

										<div style={_x().ratings_right_row}>
											<div style={_x().num_star}>
												<p style={_x().num}>4</p>
												<img src={star} alt="star" style={_x().star} />
											</div>

											<ProgressBar
												height={7}
												width={_mobile ? "60vw" : 279}
												bgcolor="#01195A"
												isLabelVisible={false}
												completed={
													mechoffice.customer_reviews["percentageRatings"]?.[4]
												}
											/>
										</div>

										<div style={_x().ratings_right_row}>
											<div style={_x().num_star}>
												<p style={_x().num}>3</p>
												<img src={star} alt="star" style={_x().star} />
											</div>

											<ProgressBar
												height={7}
												width={_mobile ? "60vw" : 279}
												bgcolor="#01195A"
												isLabelVisible={false}
												completed={
													mechoffice.customer_reviews["percentageRatings"]?.[3]
												}
											/>
										</div>

										<div style={_x().ratings_right_row}>
											<div style={_x().num_star}>
												<p style={_x().num}>2</p>
												<img src={star} alt="star" style={_x().star} />
											</div>

											<ProgressBar
												height={7}
												width={_mobile ? "60vw" : 279}
												bgcolor="#01195A"
												isLabelVisible={false}
												completed={
													mechoffice.customer_reviews["percentageRatings"]?.[2]
												}
											/>
										</div>

										<div style={_x().ratings_right_row}>
											<div style={_x().num_star}>
												<p style={_x().num}>1</p>
												<img src={star} alt="star" style={_x().star} />
											</div>

											<ProgressBar
												height={7}
												width={_mobile ? "60vw" : 279}
												bgcolor="#01195A"
												isLabelVisible={false}
												completed={
													mechoffice.customer_reviews["percentageRatings"]?.[1]
												}
											/>
										</div>
									</div>
								</div>

								<Hr thickness={0.5} />

								<div>
									<div style={_x().header}>Review thihhs Store</div>
									<Spacing vertical={5} />
									<div style={_x().sub_header}>
										Share your thoughts with other customers
									</div>

									<Spacing vertical={25} />

									<button style={_x().write_review} onClick={goToReview}>
										Write a customer review
									</button>
								</div>

								{true && (
									<>
										<Hr thickness={0.5} />

										<p style={_x().header}>Top Reviews</p>

										{Object.keys(reviewcomments).map((key, _) => {
											return (
												<Comment
													key={reviewcomments[key].id}
													id={reviewcomments[key].id}
													name={reviewcomments[key].display_name}
													profile={reviewcomments[key].display_photo}
													comment={reviewcomments[key].description}
													date={reviewcomments[key].date_created}
													experience={reviewcomments[key].experience}
													headline={reviewcomments[key].headline}
													description={reviewcomments[key].written_review}
													photos={reviewcomments[key].review_photo}
													helpful={foundHelpful}
													reportAbuse={reportAbuse}
													userFoundHelpful={reviewcomments[key].found_helpful}
													allThatFoundHelpful={reviewcomments[key].all_helpful}
												/>
											);
										})}
									</>
								)}
							</div>
						</Paper>

						<Spacing vertical={30} />

						<div style={_x().feedback_box}>
							<div>Tell us how we can improve our site</div>

							<Spacing />

							<Link
								target="_blank"
								to="/home/satisfaction"
								style={{ textDecoration: "none" }}
							>
								<button style={_x().feedback}>Feedback</button>
							</Link>
						</div>
					</div>

					{!_mobile && (
						<div style={_x().main_right}>
							<AdService vertical />

							<Spacing />

							<AdService vertical />

							<Spacing />

							<AdService vertical />
						</div>
					)}
				</div>

				<Modal open={loginpopup.value}>
					<LoginPopUp onLogin={onLogin}>
						<p
							style={{ cursor: "pointer" }}
							onClick={() => setloginpopup({ ...loginpopup, value: false })}
						>
							x
						</p>
					</LoginPopUp>
				</Modal>
			</Align>

			<DownloadApp />
			<Footer sideMargins={0} paddingInline={0} />
		</>
	);
}

export default MechOffice;

const _x = (_mobile) => {
	const getMinWidth = () => {
		switch (getBrowserName()) {
			case "firefox":
				return 1050;

			case "edge":
				return 100;

			default:
				// return 1010;
				return "73vw";
		}
	};

	return {
		homeRoute3: {
			display: _mobile ? "flex" : "grid",
			gridTemplateColumns: "1fr auto",
			gridGap: 30,
			justifyContent: "center",
			maxWidth: _mobile ? "100%" : "1200px",
			minWidth: _mobile ? "100%" : 1200,
		},

		storeImg_cntr: {
			width: "100%",
			display: "grid",
			placeItems: "center",
			position: "relative",
		},

		storeImg: {
			height: 160,
			width: "95%",
			borderTopLeftRadius: 10,
			borderTopRightRadius: 10,
			objectFit: "cover",
			// marginLeft: 10,
			// marginRight: 10,
		},

		banner: {
			backgroundColor: "var(--secondary1)",
			height: _mobile ? "100%" : 75,
			marginTop: 30,
			opacity: 0.7,
			padding: 10,
			borderRadius: 4,
			display: "flex",
			gridGap: 40,
			alignItems: "center",
			marginLeft: _mobile ? 10 : 0,
			marginRight: _mobile ? 10 : 0,
			paddingRight: _mobile ? 20 : 0,
		},

		caution: {
			width: 25,
			marginLeft: 20,
		},

		banner_col2: {
			// background: "tomato",
			display: "grid",
			alignItems: "center",
			paddingBottom: 10,
		},

		banner_text: {
			color: "white",
		},

		banner_btn: {
			color: "white",
			borderBottom: "1px solid white",
			marginBottom: 10,
		},

		main: {
			padding: 20,
			gridGap: 30,
		},

		main_left: {
			// background: "blue",
			height: "100%",
		},

		main_right: {
			width: 130,
			// background: "blue",
			paddingTop: 30,
			// height: "100%",
		},

		ratings: {
			display: "grid",
			gridTemplateColumns: _mobile ? null : "auto 1fr",
			gridTemplateRows: _mobile ? null : "auto 1fr",
			gridGap: 50,
		},

		ratings_left: {},

		ratings_right: {
			// background: "lightgrey",
			// height: 100,
			// width: 200,
		},

		ratings_right_row: {
			display: "flex",
			alignItems: "center",
			marginBottom: 10,
			height: 20,
			// justifyContent: "space-between",
		},

		num_star: {
			display: "flex",
			alignItems: "center",
			marginRight: 10,
		},

		num: {
			fontSize: 14,
			// color: "var(--secondary1)",
			marginLeft: 10,
			fontWeight: "bold",
		},

		star: {
			height: 10,
			width: 10,
			cursor: "pointer",
			marginLeft: 3,
		},

		ratings_header: {
			fontSize: 40,
			color: "var(--secondary1)",
		},
		ratings_sub_header: {
			color: "var(--secondary1)",
		},

		write_review: {
			height: 24,
			width: 165,
			background: "var(--primary1)",
			color: "white",
			fontSize: 12,
			borderRadius: 5,
		},
		helpful: {
			height: 28,
			width: 81,
			borderRadius: 2,
			color: "blue",
			background: "var(--secondary1)",
		},

		feedback_box: {
			display: "grid",
			placeItems: "center",
		},

		feedback: {
			width: 122,
			height: 26,
			fontSize: 13,
			color: "var(--primary1)",
			background: "white",
			borderRadius: 5,
		},
	};
};
