import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';

import {
  makes2,
  models,
  skillTree,
  statez,
  years,
  makes3,
} from 'src/Resources/Resources';
import search from 'src/Resources/Icons/search.png';
import { useHistory } from 'react-router-dom';
import { Spacing } from '../../Style/Styles';
import { FiSearch } from 'react-icons/fi';

export default function SparePartsSingle() {
  const _mobile = useMediaQuery('(max-width: 800px)');

  const [banner2, setbanner2] = useState({
    product: '',
    location: '',
    year: '',
    make: 'Select Make',
    model: '',
    engine: '',
  });

  const history = useHistory();

  function goToSPSQueryList() {
    history.push({
      pathname: '/home/s-p-s-querylist/',
      state: {
        product: banner2.product,
        engine: banner2.engine,
        model: banner2.model,
        location: banner2.location,
        year: banner2.year,
        make: banner2.make,
      },
    });
  }

  return (
    <div style={_x(_mobile).banner_type2}>
      <div style={_x(_mobile).searchbar_box}>
        <input
          style={_x(_mobile).search_bar}
          placeholder="Find Parts and Products"
          onChange={(e) => setbanner2({ ...banner2, product: e.target.value })}
        />

        <FiSearch style={_x(_mobile).search_icon} onClick={goToSPSQueryList} />
      </div>
    </div>
  );
}

const _x = (_mobile) => ({
  banner_type2: {
    // padding: "25px 0.5rem",
    gridTemplateRows: 'auto auto 1fr',
    display: 'grid',
    placeItems: 'center',
    // minWidth: 1030,
    width: _mobile ? '90%' : '1180px',
    minWidth: _mobile ? '300px' : 1020,
    // marginRight: 50,
  },

  bannerBtn: {
    backgroundSize: 20,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 15,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontSize: 13,
    borderBottom: '4px solid transparent',
  },

  ban_txt: {
    fontSize: 11,
    marginBottom: 15,
    fontWeight: 'bold',
  },

  searchbar_box: {
    border: '1px solid var(--secondary3)',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    borderRadius: 6,
    width: '100%',
  },

  search_icon: {
    height: 20,
    width: 20,
    backgroundColor: 'var(--primary1)',
    color: 'fff',
    padding: 10,
    borderRadius: '0 6px 6px 0',
    cursor: 'pointer',
  },

  search_bar: {
    width: '100%',
    border: 'none',
  },

  dropDown_box: {
    // height: 149,
    background: '#F2F2F2',
    width: '100%',
    paddingTop: 10,
    paddingLeft: 50,
    paddingRight: 50,
  },

  select_list: {
    height: 54,
    width: '100%',
    background: 'white',
    borderRadius: 6,
    display: 'grid',
    gridAutoFlow: 'column',
    borderRadius: '6px',
    overflow: 'hidden',
    paddingRight: '10px',
    border: '1px solid var(--secondary3)',
  },

  select1: {
    height: '100%',
    width: '100%',
    backgroundColor: '#dbdee7',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    border: '1px solid var(--primary1)',
    paddingLeft: '1rem',

    // padding: 20,
    // paddingRight: 20,
    // paddingLeft: 20,
  },

  select2: {
    height: '70%',
    width: '100%',
    border: 'none',
    borderRight: '0.5px solid #c4c4c450',
    padding: 10,
    margin: '10px 0 5px',
    paddingLeft: '1rem',
  },

  btn: {
    height: 48,
    width: 174,
    background: 'var(--primary1)',
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    borderRadius: 6,
  },
});
