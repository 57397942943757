/* eslint-disable react-hooks/exhaustive-deps */
import { Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useEffect, useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useHistory, useParams } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import Popup from "src/Components/Functional/Popup/Popup";
import Sectioner from "src/Components/Functional/Sectioner/Sectioner";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import { DEV, isEmpty } from "src/Global/Global";
import down_arrow from "src/Resources/Icons/selectarrow1.png";
import authService from "src/services/auth.service";
import mechService, { geocodeFromAdress } from "src/services/mech.service";
import DropDownBox from "../../../Components/Functional/DropDownBox/DropDownBox";
import { Align, Hr, Spacing } from "../../../Components/Style/Styles";
import { makes2, skillTree } from "../../../Resources/Resources";
import PersonalSection from "./FormSections/PersonalSection";
import ShopSection from "./FormSections/ShopSection";
import UploadSection from "./FormSections/UploadSection";
import WorkHourSection from "./FormSections/WorkHourSection";

function SellersForm() {
	const { user_type, ref, user_id } = useParams();

	const simpleValidator = useRef(new SimpleReactValidator());

	const [instantNotice, setinstantNotice] = useState(false);

	const [mobileNotice, setmobileNotice] = useState(false);

	const history = useHistory();

	const [form, setform] = useState({
		instant_service: "no",
		shopName: "",
		shopDescription: "",
		user_type: user_type,
		phoneNumber: "",
		taxIdNumber: "",
		skipTaxIdNumber: false,
		skipIdNumber: false,
		referralCode: "",
		skipReferralCode: false,
		address: "",
		skills: {
			Mechanic: {
				isTicked: false,
				specs: {
					"General Repairs": false,
					"Computer Diagnoses": false,
					"Routine  Servicing": false,
					"Engine Repair": false,
					"Brake Repair": false,
				},
			},
			"Body Shop": {
				isTicked: false,
				specs: {
					"Fix body part": false,
					"Remove dents": false,
					"Install windshields": false,
					"Painting Straighten": false,
					"Metal panels": false,
				},
			},
			"Car Air - Conditioning": {
				isTicked: false,
				specs: {
					Install: false,
					Compressor: false,
					Leakage: false,
					Fix: false,
					Condenser: false,
					"Fill Gas": false,
				},
			},
			Electrical: {
				isTicked: false,
				specs: {
					"Lighting system": false,
					Coil: false,
					Fuse: false,
					Fuelpump: false,
					"Re-wire": false,
				},
			},
			"Tire Shop": {
				isTicked: false,
				specs: {
					"Flat tire": false,
					"Gauge tire": false,
					"Wheel Balancing": false,
					"Alignment Rotating": false,
					"Tire replacement": false,
				},
			},

			Towing: {
				isTicked: false,
				specs: {
					"Accident  Car": false,
					"Road Assistant": false,
					Transportation: false,
					"Heavy Duty": false,
					"Small Duty": false,
				},
			},

			security: {
				isTicked: false,
				specs: {
					"Car Alarm": false,
					Tracker: false,
					GPS: false,
					"Key Programmer": false,
					Camera: false,
				},
			},
		},
		photos: [
			// "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVoIWVKYjrGtiU1Nvu3UuFKjks7lCyCezkUkTRzMI1JhGCNkEr7FQKxxqTgXvep7g-hQE&usqp=CAU",
			// "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVoIWVKYjrGtiU1Nvu3UuFKjks7lCyCezkUkTRzMI1JhGCNkEr7FQKxxqTgXvep7g-hQE&usqp=CAU",
			// "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVoIWVKYjrGtiU1Nvu3UuFKjks7lCyCezkUkTRzMI1JhGCNkEr7FQKxxqTgXvep7g-hQE&usqp=CAU",
			// "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVoIWVKYjrGtiU1Nvu3UuFKjks7lCyCezkUkTRzMI1JhGCNkEr7FQKxxqTgXvep7g-hQE&usqp=CAU",
			// "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVoIWVKYjrGtiU1Nvu3UuFKjks7lCyCezkUkTRzMI1JhGCNkEr7FQKxxqTgXvep7g-hQE&usqp=CAU",
		],
		experience: "",
		idType: "",
		idNumber: "",
		office_address: "",
		workHours: {
			Sunday: {
				isChecked: false,
				time: {
					from: {
						hour: "",
						meridian: "AM",
					},
					to: {
						hour: "",
						meridian: "PM",
					},
				},
			},
			Thursday: {
				isChecked: false,
				time: {
					from: {
						hour: "",
						meridian: "AM",
					},
					to: {
						hour: "",
						meridian: "PM",
					},
				},
			},
			Monday: {
				isChecked: false,
				time: {
					from: {
						hour: "",
						meridian: "AM",
					},
					to: {
						hour: "",
						meridian: "PM",
					},
				},
			},
			Friday: {
				isChecked: false,
				time: {
					from: {
						hour: "",
						meridian: "AM",
					},
					to: {
						hour: "",
						meridian: "PM",
					},
				},
			},
			Tuesday: {
				isChecked: false,
				time: {
					from: {
						hour: "",
						meridian: "AM",
					},
					to: {
						hour: "",
						meridian: "PM",
					},
				},
			},
			Saturday: {
				isChecked: false,
				time: {
					from: {
						hour: "",
						meridian: "AM",
					},
					to: {
						hour: "",
						meridian: "PM",
					},
				},
			},
			Wednesday: {
				isChecked: false,
				time: {
					from: {
						hour: "",
						meridian: "AM",
					},
					to: {
						hour: "",
						meridian: "PM",
					},
				},
			},
		},
		terms: false,
		showfielderror: false,
		mobile_service: false,
		country: "Nigeria",
		state: "Lagos",
		office_address_geolocation: {},
	});

	const [isloading, setisloading] = useState(false);

	const [validExp, setvalidExp] = useState([]);

	const [sectioner, setsectioner] = useState({
		index: 0, //! revert to 0
		mechSections: [
			// "Category",
			// "Professional",
			"Personal",
			"Work",
			"Shop",
			"Upload",
		],
	});

	const [vehicledropdown, setvehicledropdown] = useState({
		visible: false,
		stuff: {},
		tags: [],
	});

	const [specialtydropdown, setspecialtydropdown] = useState({
		visible: false,
		stuff: {},
		tags: [],
	});

	const [formerror, setformerror] = useState({
		visible: false,
		messages: {},
	});

	const [locationErr, setlocationErr] = useState(false);
	const [conf, setconf] = useState(false);

	const [prelude, setprelude] = useState({
		visible: !DEV,
		//  true, //! revert to true
		category: "",
		error: false,
	});

	const _form_theme = `var(--primary${user_type === "mechanic" ? 1 : 2})`;

	const _mobile = useMediaQuery("(max-width:800px)");

	const validHrs = [
		"1",
		"2",
		"3",
		"4",
		"5",
		"6",
		"7",
		"8",
		"9",
		"10",
		"11",
		"12",
	];

	const _y = {
		text2: {
			fontSize: 16,
			color: _mobile ? "black" : _form_theme,
			fontWeight: 500,
		},

		submit: {
			fontWeight: "bold",
			width: _mobile ? "100%" : 198,
			height: 40,
			background: _form_theme,
			borderRadius: 6,
			fontSize: 18,
			border: "1px solid",
			color: "white",
		},

		hyperlink: {
			color: _form_theme,
			cursor: "pointer",
			width: "100%",
			marginLeft: 5,
			marginRight: 5,
		},

		super_dropdown_box: {
			transform: `scaleY(${vehicledropdown.visible ? 1 : 0})`,
		},
	};

	const TitleBar = ({ title }) => {
		return (
			<>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div style={_y.text2}>{title}</div>

					{_mobile && (
						<div
							style={{ color: "var(--primary1)", cursor: "pointer" }}
							onClick={() => adjustSection("PREVIOUS")}
						>
							Back
						</div>
					)}
				</div>

				<Hr width="99%" height={10} color={_y.text2.color} />
			</>
		);
	};

	const section_props = {
		personal: {
			title: <TitleBar title="Details:" />,
			form,
			setform,
			updateTextField,
			_mobile,
			simpleValidator,
			updateTextField,
			selectDropDown,
			vehicledropdown,
			updateVehicleType,
			toggleVehicleDropdown,
			toggleSpecialtyDropDown,
			specialtydropdown,
			updateSpecialty,
		},

		professional: {
			title: <TitleBar title="Professional Details:" />,
			form,
			_mobile,
			simpleValidator,
			updateTextField,
			updateSkill,
			updateSpec,
			vehicledropdown,
			updateVehicleType,
			toggleVehicleDropdown,
		},

		work_hr: {
			title: <TitleBar title="Work Hours:" />,
			form,
			updateHour,
			updateMeridian,
			_mobile,
			toggleWorkDay,
			simpleValidator,
			validHrs,
			setinstantNotice,
			setmobileNotice,
			setform,
		},

		shop: {
			title: <TitleBar title="Shop Details:" />,
			form,
			setform,
			_mobile,
			updateTextField,
			simpleValidator,
			selectDropDown,
			sectionIndex: sectioner.mechSections.length - 1,
			locationErr,
			setlocationErr,
		},

		upload: {
			title: <TitleBar title="Upload Shop Photo:" />,
			routeRef: ref,
			uploadImage,
			form,
			setform,
			simpleValidator,
			_mobile,
			_form_theme,
			reorderImgList,
		},
	};

	const [currentUser, setCurrentUser] = useState();

	useEffect(() => {
		if (ref === "create") {
			const loggedInUser = authService.getCurrentUser("user").data?.user_info;

			setCurrentUser(loggedInUser);
			setform({
				...form,
				phoneNumber: loggedInUser.phone_number,
				office_address: loggedInUser.address,
			});
		}
	}, []);

	useEffect(() => {
		if (user_type === "mechanic") {
			let initSpecList = {};
			let initSkillList = {};
			let initVehicleStuff = {};
			let initSpecialtyStuff = {};

			Object.keys(makes2)?.map((key) => {
				Object.assign(initVehicleStuff, {
					[`${key}`]: false,
				});
				return "";
			});

			if (prelude.category?.length > 0) {
				skillTree[prelude.category]?.map((e) => {
					Object.assign(initSpecialtyStuff, {
						[`${e}`]: false,
					});
					return "";
				});
			}

			setspecialtydropdown({
				...specialtydropdown,
				stuff: initSpecialtyStuff,
			});

			if (ref !== "edit") {
				setvehicledropdown({ ...vehicledropdown, stuff: initVehicleStuff });
			}

			Object.keys(skillTree).map((key, val) => {
				skillTree[key].map((e) => {
					initSkillList[e] = false;
					return "";
				});

				Object.assign(initSpecList, {
					[`${key}`]: {
						isTicked: false,
						specs: initSkillList,
					},
				});

				return "";
			});
		}
		grabLoggedInUser();
	}, [prelude.category]);

	useEffect(() => {
		if (ref === "edit") {
			setprelude({ ...prelude, visible: false });
			setisloading(true);

			(async () => {
				// const res = await authService.getCurrentLoggedInUser();
				const res = await mechService.getMechanicShop(user_id);

				if (res.success) {
					setform({
						...form,
						shopName: res.data["shop_name"],
						shopDescription: res.data["shop_description"],
						phoneNumber: res.data["phone_number"],
						taxIdNumber: res.data["tax_identification_no"],
						skipTaxIdNumber:
							res.data["tax_identification_no"] === null ? true : false,
						city: res.data["user"]["city"],
						photos: res.data["company_photo"].map((e) => ({ data_url: e })),
						experience: res.data["experience_years"],
						instant_service: res.data["instant_service"],
						mobile_service: res.data["mobile_service"],
						office_address: res.data["office_address"],
						workHours:
							user_type === "mechanic" && res.data["working_hours_data"] != null
								? JSON.parse(res.data["working_hours_data"]) ?? form.workHours
								: {},
						skill: res.data["professional_skill"],
					});

					if (
						user_type === "mechanic" &&
						res.data["vehicle_type_data"] != null
					) {
						const parsed_vehicle_data = JSON.parse(
							res.data["vehicle_type_data"]
						);

						if (parsed_vehicle_data != null) {
							setvehicledropdown({
								visible: false,
								stuff: {
									...parsed_vehicle_data,
									// ...(parsed_vehicle_data ??
									// 	Object.keys(makes2).map((key, val) => {
									// 		return Object.assign(
									// 			{},
									// 			{
									// 				[`${key}`]: false,
									// 			}
									// 		);
									// 	})),
								},
								tags: [
									...Object.keys(parsed_vehicle_data).filter(
										(each) => parsed_vehicle_data[each] === true
									),
								],
							});
						}
					}

					setspecialtydropdown({
						...specialtydropdown,
						tags: JSON.parse(res.data["specialty"]) ?? [],
					});

					setprelude({
						...prelude,
						visible: false,
						category: res.data["professional_skill"],
						error: false,
					});
				}

				setisloading(false);
			})();
		}
	}, []);

	useEffect(() => {
		let _pre_exp = [" "];

		for (let x = 1; x <= 100; x++) _pre_exp.push(x.toString());

		setvalidExp(_pre_exp);
	}, []);

	async function grabLoggedInUser() {
		const resp = await authService.getCurrentLoggedInUser();

		// console.log("resp.data.data.user_info", resp.data.data.user_info);

		if (resp.success) {
			const response = resp.data;
			// console.log(response);

			setform({
				...form,
				phoneNumber: response.phone_number,
				office_address: response.address,
				state: response.state,
				city: response.city,
			});
		} else {
			// console.log("none")
		}
	}

	function reorderImgList(newList) {
		setform({ ...form, photos: newList });
	}

	function uploadImage(img) {
		setform({ ...form, photos: img });
	}

	function selectDropDown(e, field) {
		const { value } = e.target;

		if (field === "state") {
			if (value === "") {
			} else setform({ ...form, state: value });
		}

		if (field === "skill") {
			if (value === "") {
			} else setform({ ...form, skill: value });
		}

		if (field === "experience") {
			if (value === "") {
			} else setform({ ...form, experience: value });
		}

		if (field === "idType") {
			if (value === "") {
			} else setform({ ...form, idType: value, idNumber: "" });
		}

		if (field === "city") {
			if (value === "") {
			} else setform({ ...form, city: value });
		}
	}

	function updateTextField(text, field) {
		if (field === "number") {
			setform({ ...form, phoneNumber: text });
		}
		if (field === "tax") {
			setform({ ...form, taxIdNumber: text });
		}
		if (field === "id") {
			setform({ ...form, idNumber: text });
		}
		if (field === "exp") {
			setform({ ...form, experience: text });
		}
		if (field === "office") {
			setform({ ...form, office_address: text });
		}
		if (field === "street") {
			setform({ ...form, street_name: text });
		}
		if (field === "service") {
			setform({ ...form, serviceArea: text });
		}
		if (field === "name") {
			setform({ ...form, shopName: text });
		}
		if (field === "description") {
			setform({ ...form, shopDescription: text });
		}
		if (field === "referral") {
			setform({ ...form, referralCode: text });
		}
		if (field === "phoneNumber") {
			setform({ ...form, phoneNumber: text });
		}
	}

	async function submitForm(e) {
		setform({ ...form, showfielderror: true });

		let skillReqBody;

		let vehicleReqBody;

		let work_hrReqBody;

		if (user_type === "mechanic") {
			skillReqBody = collateChoices("skill");

			vehicleReqBody = collateChoices("vehicle");

			work_hrReqBody = collateChoices("workHr");
		}

		if (DEV || simpleValidator.current.allValid()) {
			setisloading(true);

			const geoCode = await geocodeFromAdress(form.office_address);

			console.log("geocodeFromAdress", geocodeFromAdress);

			form.office_address_geolocation.long = geoCode.lng;
			form.office_address_geolocation.lat = geoCode.lat;

			const resp = await authService.addUserRole(
				user_id,
				ref === "edit" ? true : false,
				form.shopName,
				form.shopDescription,
				user_type,
				form.phoneNumber,
				form.taxIdNumber,
				form.state,
				form.city,
				prelude.category,
				form.photos.map((e) => e.data_url),
				form.experience,
				form.idType,
				form.idNumber,
				form.office_address,
				work_hrReqBody,
				vehicleReqBody,
				form.workHours,
				form.skills,
				vehicledropdown.stuff,
				form.referralCode,
				form.instant_service,
				form.mobile_service,
				form.country,
				form.office_address_geolocation,
				specialtydropdown.tags
			);

			setisloading(false);

			if (resp.success) {
				setconf(true);
				// history.push(`/auth/${ref}/${user_type}/confirmation`);
			} else {
				setformerror({
					...formerror,
					visible: true,
					messages: resp.errorSource,
				});
			}
		} else {
			setform({ ...form, showfielderror: true });
		}
	}

	function updateSkill(skill) {
		setform({
			...form,
			skills: {
				...form.skills,
				[`${skill}`]: {
					...form.skills[skill],
					isTicked: !form.skills[skill].isTicked,
				},
			},
		});
	}

	function updateSpec(skill, spec) {
		setform({
			...form,
			skills: {
				...form.skills,
				[`${skill}`]: {
					...form.skills[skill],
					specs: {
						...form.skills[skill]["specs"],
						[`${spec}`]: !form.skills[skill]["specs"][spec],
					},
				},
			},
		});
	}

	function toggleSpecialtyDropDown(e) {
		e.stopPropagation();

		setspecialtydropdown({
			...specialtydropdown,
			visible: !specialtydropdown.visible,
		});
	}

	function toggleVehicleDropdown(e) {
		e.stopPropagation();

		setvehicledropdown({
			...vehicledropdown,
			visible: !vehicledropdown.visible,
		});
	}

	function updateSpecialty(spec) {
		setspecialtydropdown({
			...specialtydropdown,
			stuff: {
				...specialtydropdown.stuff,
				[`${spec}`]: !specialtydropdown.stuff[spec],
			},
			tags:
				specialtydropdown.stuff[spec] === false
					? [...specialtydropdown.tags, spec]
					: specialtydropdown.tags.filter((v) => v !== spec),
		});
	}

	function updateVehicleType(vehicle) {
		setvehicledropdown({
			...vehicledropdown,
			stuff: {
				...vehicledropdown.stuff,
				[`${vehicle}`]: !vehicledropdown.stuff[vehicle],
			},
			tags:
				vehicledropdown.stuff[vehicle] === false
					? [...vehicledropdown.tags, vehicle]
					: vehicledropdown.tags.filter((v) => v !== vehicle),
		});
	}

	function collateChoices(field) {
		switch (field) {
			case "skill":
				let finalSkill = {};

				Object.keys(form.skills).map((key, val) => {
					let finalSpec = [];

					if (form.skills[`${key}`]["isTicked"] === true) {
						Object.keys(form.skills[`${key}`]["specs"]).map((innerkey, _) => {
							if (form.skills[key]["specs"][innerkey] === true) {
								finalSpec.push(innerkey);
							}
						});
					}

					if (finalSpec.length !== 0) {
						finalSkill[`${key}`] = finalSpec;
					}
				});

				return finalSkill;

			case "vehicle":
				let finalVehicleList = [];

				Object.keys(vehicledropdown.stuff).map((key, val) => {
					if (vehicledropdown.stuff[key] === true) {
						finalVehicleList.push(key);
					}

					return "";
				});

				return finalVehicleList;

			case "workHr":
				let final_workHr = {};

				Object.keys(form.workHours).map((key, _) => {
					if (form.workHours[key]["isChecked"] === true) {
						Object.assign(final_workHr, {
							[`${key}`]: form.workHours[key]["time"],
						});
					}
				});

				return final_workHr;

			default:
				break;
		}
	}

	function closeDropDowns(e) {
		e.stopPropagation();

		setspecialtydropdown({ ...specialtydropdown, visible: false });
		setvehicledropdown({ ...vehicledropdown, visible: false });
	}

	function toggleWorkDay(key) {
		if (_mobile) setform({ ...form, showfielderror: false });

		setform({
			...form,
			workHours: {
				...form.workHours,
				[`${key}`]: {
					...form.workHours[key],
					isChecked: !form.workHours[key]["isChecked"],
					time: {
						...form.workHours[key]["time"],
						from: {
							...form.workHours[key]["time"]["from"],
							hour: !form.workHours[key]["isChecked"] ? 9 : "",
						},
						to: {
							...form.workHours[key]["time"]["to"],
							hour: !form.workHours[key]["isChecked"] ? 5 : "",
						},
					},
				},
			},
		});
	}

	function updateHour(e, key, trajectory) {
		const prev_hr_num = form.workHours[key]["time"][trajectory]["hour"];

		// console.log(e.target.value);

		setform({
			...form,
			workHours: {
				...form.workHours,
				[`${key}`]: {
					...form.workHours[key],
					time: {
						...form.workHours[key]["time"],
						[`${trajectory}`]: {
							...form.workHours[key]["time"][trajectory],
							hour: e.target.value,
						},
					},
				},
			},
		});
	}

	function updateMeridian(e, key, trajectory) {
		setform({
			...form,
			workHours: {
				...form.workHours,
				[`${key}`]: {
					...form.workHours[key],
					time: {
						...form.workHours[key]["time"],
						[`${trajectory}`]: {
							...form.workHours[key]["time"][trajectory],
							meridian: e.target.value,
						},
					},
				},
			},
		});
	}

	function adjustSection(query, currentIndex) {
		console.log(
			"🚀 ~ file: SellersForm.js:906 ~ adjustSection ~ currentIndex:",
			currentIndex
		);

		switch (query) {
			case "NEXT":
				setform({ ...form, showfielderror: true });
				if (resolveErrorsForSections(currentIndex)) {
					if (sectioner.index === 4 && locationErr) {
					} else {
						if (sectioner.index < sectioner.mechSections.length - 1) {
							setsectioner({ ...sectioner, index: sectioner.index + 1 });
							setform({ ...form, showfielderror: false });
						} else if (sectioner.index === sectioner.mechSections.length - 1) {
							submitForm();
						}
					}
				}
				break;

			case "PREVIOUS":
				if (sectioner.index > 0) {
					setsectioner({ ...sectioner, index: sectioner.index - 1 });
					if (sectioner.index === 1) {
						setprelude({ ...prelude, visible: true });
					}
				}
				break;

			default:
				break;
		}
	}

	function testRegexFor(idType, idNum) {
		switch (idType) {
			case "Drivers License":
				return /[a-z]{3}[1-9]{5}[a-z]{2}/gi.test(idNum);

			case "Voters Card":
				return /[a-z]{2}[0-9][a-z][0-9][a-z]{2}\d{2}[a-z]\d{5}/gi.test(idNum);

			case "National Identity Card":
				return /\d{10}/gi.test(idNum);

			case "International Passport":
				return /[a-z]\d{8}/gi.test(idNum);

			default:
				return false;
		}
	}

	function resolveErrorsForSections(index) {
		switch (index) {
			// case 0:
			// 	if (
			// 		form.phonspeciaeNumber !== "" &&
			// 		(form.taxIdNumber !== "" || form.skipTaxIdNumber) &&
			// 		(form.idNumber === ""
			// 			? true
			// 			: testRegexFor(form.idType, form.idNumber) || form.skipIdNumber) &&
			// 		(form.referralCode !== "" || form.skipReferralCode)
			// 	) {
			// 		return true;
			// 	}
			// 	break;

			case 0:
				if (form.experience !== "") return true;
				break;

			case 1:
				let fields_valid = true;

				Object.keys(form.workHours).map((key, _) => {
					if (form.workHours[key]["isChecked"]) {
						if (
							form.workHours[key]["time"]["from"]["hour"] === "" ||
							form.workHours[key]["time"]["to"]["hour"] === ""
						) {
							fields_valid = false;
						}
					}
				});

				return fields_valid;

			case 2:
				if (form.shopName !== "") return true;
				break;

			case 3:
				if (form.terms) return true;

			default:
				return false;
		}
	}

	return (
		<>
			<div style={_x(_mobile).sellersFormAlpha}>
				<Align verticalHeight={_mobile ? 0 : 100}>
					<Paper style={_x(_mobile).sellersForm} onClick={closeDropDowns}>
						{prelude.visible ? (
							<>
								<div style={{ ..._x(_mobile).header, background: _form_theme }}>
									<div style={_x().header_txt1}>Provided Details</div>
								</div>

								<div
									style={{
										height: "60vh",
										display: "grid",
										placeItems: "center",
									}}
								>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<div style={{ fontSize: 15, fontWeight: 600 }}>
											Category
										</div>

										<Spacing vertical={8} />

										<DropDownBox
											value={prelude.category}
											styles={{
												width: 400,
											}}
											endIcon={
												<img src={down_arrow} style={_x().vehicle_type_icon} />
											}
											onOptionClicked={(e) =>
												setprelude({ ...prelude, category: e, error: false })
											}
											placeholder={"Select Your Skill"}
											options={Object.keys(skillTree).map((key) => key)}
										/>

										<Spacing vertical={10} />

										<div
											style={{
												visibility: prelude.error ? "visible" : "hidden",
											}}
										>
											<div style={_x().error}>Select Category To Proceed</div>
											<Spacing vertical={20} />
										</div>

										<button
											style={{
												..._y.submit,
												width: _mobile ? "100%" : 400,
												color: "white",
												border: "none",
											}}
											onClick={() => {
												if (isEmpty(prelude.category)) {
													setprelude({ ...prelude, error: true });
												} else {
													if (prelude.category === "Spare Part") {
														let isPD =
															currentUser?.permissions.includes("part_dealer");

														if (isPD)
															return history.push("/home/submit-an-ad/");
														console.log(
															currentUser.permissions,
															"==========USER PERMISSIONS========"
														);
														return history.push("/home/become-a-dealer/");
													} else {
														setprelude({ ...prelude, visible: false });
													}
												}
											}}
										>
											Next
										</button>
									</div>
								</div>
							</>
						) : (
							<>
								{!_mobile ? (
									<>
										<div
											style={{ ..._x(_mobile).header, background: _form_theme }}
										>
											{user_type === "mechanic" ? (
												<div style={_x().header_txt1}>
													{prelude.category} Details
												</div>
											) : (
												<div style={_x().header_txt1}>Spare Parts Details</div>
											)}

											<div style={_x().header_txt2}>
												All fields are compulsory
											</div>
										</div>

										<div
											style={
												_mobile
													? { padding: 10 }
													: { padding: 40, paddingLeft: 200, paddingRight: 200 }
											}
										>
											<Spacing />

											<PersonalSection {...section_props.personal} />

											{user_type === "mechanic" && (
												<>
													<WorkHourSection {...section_props.work_hr} />
													<Spacing />
												</>
											)}

											<Spacing />

											<ShopSection {...section_props.shop} />

											<UploadSection {...section_props.upload} />

											<Spacing />

											<button onClick={submitForm} style={_y.submit}>
												{ref === "edit" ? "Save" : "Register"}
											</button>
										</div>
									</>
								) : (
									<div style={_x().sectioned_view}>
										{sectioner.index === 0 && (
											<PersonalSection {...section_props.personal} />
										)}

										{/* 
										{sectioner.index === 1 && (
											<ProfessionalSection {...section_props.professional} />
										)} */}

										{sectioner.index === 1 && (
											<WorkHourSection {...section_props.work_hr} />
										)}

										{sectioner.index === 2 && (
											<ShopSection {...section_props.shop} />
										)}

										{sectioner.index === 3 && (
											<UploadSection {...section_props.upload} />
										)}
									</div>
								)}
							</>
						)}
					</Paper>

					<Popup
						type="popup1"
						isOpen={conf}
						vertical={2}
						headerText="Congratulations"
						popupMessage={`You have successfully ${
							ref === "create"
								? // ? `registered as a service provider (Mechanic)`
								  `registered as a ${prelude.category}`
								: "updated form details"
						}`}
						buttonContainerStyles={{
							flexDirection: "column",
						}}
						actionComps={
							[
								// <button
								// 	onClick={() => {}}
								// 	style={{
								// 		..._x().popup_btn,
								// 		color: "var(--primary1)",
								// 	}}
								// >
								// 	Promote Your Shop
								// </button>,
								// <button onClick={() => {}} style={{ color: "var(--primary1)" }}>
								// 	Learn More about Promotion
								// </button>,
							]
						}
						continuePressed={() => history.push("/home/dashboard")}
					/>

					<Popup
						type="error"
						isOpen={formerror.visible}
						errorMessages={formerror.messages}
						closePressed={() => setformerror({ ...formerror, visible: false })}
					/>

					{_mobile && !prelude.visible && (
						<div
							style={{
								position: "fixed",
								bottom: 0,
								paddingBottom: 10,
								background: "white",
								width: "100%",
								padding: "0px 10px",
							}}
						>
							<>
								<Sectioner
									currentIndex={sectioner.index}
									sections={sectioner.mechSections}
								/>

								<button
									style={{ ..._y.submit, marginBottom: 10 }}
									onClick={() => {
										adjustSection("NEXT", sectioner.index);
									}}
								>
									{sectioner.index === sectioner.mechSections.length - 1
										? "Submit"
										: "Next"}
								</button>
							</>
						</div>
					)}

					{instantNotice ? (
						<section className="update-pop">
							<div className="firstname">
								<p
									style={{
										fontSize: "18px",
										margin: "36px auto 24px",
										colo: "#000",
										fontWeight: "500",
									}}
								>
									Instant Service
								</p>
								<p
									style={{
										margin: "0px auto 36px",
										colo: "#000",
										width: "70%",
										fontSize: "14px",
										lineHeight: "26px",
										letterSpacing: "0.5px",
									}}
								>
									Turn On Instant Service to give a quick response to nearby
									Motorist.
								</p>
								<p
									className="instantCloseBtn"
									onClick={() => setinstantNotice(false)}
								>
									<GrFormClose size={30} />
								</p>
							</div>
						</section>
					) : null}

					{mobileNotice ? (
						<section className="update-pop">
							<div className="firstname">
								<p
									style={{
										fontSize: "18px",
										margin: "36px auto 24px",
										colo: "#000",
										fontWeight: "500",
									}}
								>
									Mobile Service
								</p>
								<p
									style={{
										margin: "0px auto 36px",
										colo: "#000",
										width: "70%",
										fontSize: "14px",
										lineHeight: "26px",
										letterSpacing: "0.5px",
									}}
								>
									Turn On Mobile Service to give a quick response to nearby
									Motorist by driving down to meet them.
								</p>
								<p
									className="instantCloseBtn"
									onClick={() => setmobileNotice(false)}
								>
									<GrFormClose size={30} />
								</p>
							</div>
						</section>
					) : null}

					<Spinner isloading={isloading} />
				</Align>
			</div>
		</>
	);
}

export default SellersForm;

const _x = (_mobile) => ({
	sellersFormAlpha: _mobile
		? { background: "white", height: "100vh", position: "relative" }
		: {},

	popup_btn: {
		height: 40,
		// width: 137,
		paddingLeft: 15,
		paddingRight: 15,
		border: "3px solid var(--primary1)",
		borderRadius: 6,
		fontWeight: "bold",
		fontSize: 16,
		marginBottom: "1.7rem",
	},

	sellersForm: {
		position: "relative",
		boxShadow: _mobile ? "none" : "0 2px 2px rgba(0, 0, 0, 0.25)",
		borderRadius: _mobile ? 0 : 10,
		padding: _mobile ? 20 : 0,
		width: _mobile ? "100%" : "1200px",
		fontFamily: "montserrat",
		// padding: 30,
	},

	error: {
		color: "red",
		fontSize: 10,
	},

	sectioned_view: {
		height: "100%",
		overflow: "scroll",
		width: "90vw",
		paddingBottom: 110,
		// background: "red",
	},

	vehicle_type_icon: {
		width: 10,
		height: 10,
		display: "grid",
		placeItems: "center",
		objectFit: "contain",
		marginRight: 10,
	},

	header: {
		fontSize: 15,
		fontWeight: 550,
		textAlign: "center",
		padding: 5,
		borderTopRightRadius: _mobile ? 0 : 10,
		borderTopLeftRadius: _mobile ? 0 : 10,
		color: "white",
		boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
		paddingTop: 10,
	},

	header_txt1: {
		fontWeight: 500,
		fontSize: 20,
		marginBottom: 15,
		letterSpacing: 1.5,
	},

	header_txt2: {
		fontSize: 13,
		letterSpacing: 1.5,
	},

	checkbox_container: {
		display: "flex",
		alignItems: "center",
	},

	checkbox: {
		height: 15,
		width: 15,
		marginRight: 10,
		cursor: "pointer",
	},
});
