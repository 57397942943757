import { Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useContext } from "react";
import { FaHeart } from "react-icons/fa";
import StarRatingComponent from "react-star-rating-component";
import { Brim } from "src/State/Control";
import { toTitleCase } from "../../../Global/Global";
import { Spacing } from "../../Style/Styles";

function MechStorePost({
	// user_name,
	// image,
	// description,
	// address,
	// phoneNumber,
	// email,
	// mechanicId,
	// dp,
	// workingHours,
	// mechanicShopId,
	// customerReviews,
	// state,
	// city,
	// category,
	// subCategory,
	// vehicleType,
	//*.....................................................

	mechanicId,
	mechanicShopId,
	city,
	image,
	shopName,
	state,
	customerReviews,
	phoneNumber,
	description,
	vehicleType,
	address,
	category,
	subCategory,
	email,
	instant,
	liked,
	mobile,
}) {
	const [control, setcontrol] = useContext(Brim);
	const _mobile = useMediaQuery("(max-width:800px)");

	function postTapped() {
		setcontrol({
			...control,
			sidebar: {
				...control.sidebar,
				isOpen: true,
				values: {
					...control.sidebar.values,
					city,
					image,
					shopName,
					state,
					customerReviews,
					phoneNumber,
					description,
					vehicleType,
					address,
					category,
					subCategory,
					mechanicId,
					mechanicShopId,
					email,
					instant,
					mobile,
					liked,

					//*..............................
					// email,
					// address,
					// phoneNumber,
					// shopName,
					// description,
					// mechanicId,
					// dp,
					// workingHours,
					// workSchedule,
					// mechanicShopId,
					// customerReviews,
					// user_name,
					// state,
					// city,
					// vehicleType,
					// category,
					// subCategory,
					// storeImg: image,
				},
			},
		});
	}

	return (
		<Paper style={_x(_mobile).post} onClick={postTapped} elevation={0}>
			<div className="mech-main-like">
				{liked && (
					<p className="read-only-like likeBtn">
						<FaHeart color={"red"} size={20} />
					</p>
				)}
			</div>

			<div className="desktop-card-store-like">
				{liked && (
					<p className="desktop-likee">
						<FaHeart color={"red"} size={20} />
					</p>
				)}
			</div>
			<img style={_x(_mobile).storeImg} src={image} alt={shopName} />

			<div style={_x().right}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<p style={_x().header}>{toTitleCase(shopName ?? "")}</p>

					<div style={{ display: "flex" }}>
						{instant && (
							<div
								style={{
									background: "#31AA52",
									paddingInline: 10,
									borderRadius: 35,
									height: 25,
									display: "grid",
									placeItems: "center",
									marginTop: 15,
									color: "white",
									fontSize: 8,
								}}
							>
								Instant Service
							</div>
						)}

						{mobile && !_mobile && (
							<div
								style={{
									background: "#FBB500",
									paddingInline: 10,
									borderRadius: 35,
									height: 25,
									display: "grid",
									placeItems: "center",
									marginTop: 15,
									color: "white",
									fontSize: 8,
									marginLeft: 10,
								}}
							>
								Mobile Service
							</div>
						)}
					</div>
				</div>

				<p style={_x().location}>{toTitleCase(address ?? "")}</p>

				<p style={_x().description} className="twolines">
					{description}
				</p>

				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<div style={{ transform: "rotate(180deg)" }}>
							<StarRatingComponent
								editing={false}
								starCount={5}
								value={customerReviews?.average_overall_rating || 0}
								emptyStarColor={"#c4c4c4"}
							/>
						</div>

						<Spacing horizontal={8} />

						<p style={_x().text8}>
							<span style={{ fontWeight: 600, marginRight: 5 }}>
								{customerReviews?.average_overall_rating || 0}
							</span>
							Excellent
						</p>

						<Spacing horizontal={8} />

						<p style={_x().text8}>
							({/* {customerReviews.total_rating} */}
							Reviews)
						</p>
					</div>
					{mobile && _mobile && (
						<div
							style={{
								background: "#FBB500",
								paddingInline: 10,
								borderRadius: 35,
								height: 25,
								display: "grid",
								placeItems: "center",
								marginTop: 15,
								color: "white",
								fontSize: 8,
								marginLeft: 10,
							}}
						>
							Mobile Service
						</div>
					)}
				</div>
			</div>
		</Paper>
	);
}

export default MechStorePost;

const _x = (_mobile) => ({
	post: {
		height: _mobile ? "100%" : 183,
		width: _mobile ? "100%" : 730,
		backgroundColor: "white",
		borderRadius: 10,
		display: "grid",
		gridTemplateColumns: _mobile ? null : "auto 1fr",
		gridTemplateRow: _mobile ? null : "auto 1fr",
		marginBottom: 20,
		cursor: "pointer",
		border: "1px solid #dedddd",
		position: "relative",
	},
	header: {
		fontSize: 15,
		fontWeight: 500,
		color: "var(--secondary1)",
	},
	storeImg: {
		height: 180,
		width: _mobile ? "100%" : 230,
		background: "var(--primary1)",
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: _mobile ? 0 : 10,
		borderTopRightRadius: _mobile ? 10 : 0,
		objectFit: "cover",
	},
	right: {
		padding: 20,
		paddingTop: 5,
		paddingBottom: 5,
	},
	shopName: {
		fontSize: 15,
		color: "var(--secondary1)",
		fontWeight: "bold",
	},
	location: {
		fontSize: 11,
		color: "var(--secondary5)",
		fontWeight: 500,
	},
	description: {
		fontSize: 10,
		color: "var(--secondary5)",
		fontWeight: 500,
	},
	text1: {
		color: "var(--secondary5)",
		textSize: "10px",
		fontWeight: 500,
		marginLeft: 20,
	},
	text8: {
		fontSize: 13,
		color: "#646464",
		fontWeight: 500,
		lineHeight: 1.8,
	},
});
