import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { isEmpty } from "src/Global/Global";

export default function DropDownBox({
	isObject,
	options,
	ontoggle,
	placeholder,
	onOptionClicked,
	styles,
	hideScroll,
	value,
	endIcon,
}) {
	const [dropdown, setDropdown] = React.useState({
		visible: false,
		selection: "",
	});

	useEffect(() => {
		setDropdown({ ...dropdown, selection: value });
	}, [value]);

	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<div
			className="home-panel-search-bar-box"
			onClick={(e) => {
				e.stopPropagation();
				ontoggle?.();
				setDropdown({ ...dropdown, visible: !dropdown.visible });
			}}
			style={{
				position: "relative",
				cursor: "pointer",
				...styles,
				...(_mobile ? { width: "80vw" } : {}),
			}}
		>
			<div
				style={{
					marginLeft: 10,
					fontWeight: 500,
					width: "100%",
					color: isEmpty(dropdown.selection) ? "lightgrey" : "black",
				}}
			>
				{isEmpty(dropdown.selection) ? placeholder : dropdown.selection}
			</div>

			{endIcon}

			{dropdown.visible && (
				<div
					className={hideScroll ? "hideScroll" : null}
					style={{ ..._x().suggestion_box, height: 200 }}
				>
					{isObject ? (
						<>
							{Object.keys(options).map((key, val) => {
								return (
									<div
										key={key}
										value={key}
										style={_x().suggestion_post}
										onClick={() => {
											setDropdown({ ...dropdown, selection: key });
											onOptionClicked?.(key);
										}}
									>
										{key}
									</div>
								);
							})}
						</>
					) : (
						<>
							{options.map((e) => {
								return (
									<div
										key={e}
										value={e}
										style={_x().suggestion_post}
										onClick={() => {
											setDropdown({ ...dropdown, selection: e });
											onOptionClicked?.(e);
										}}
									>
										{e}
									</div>
								);
							})}
						</>
					)}
				</div>
			)}
		</div>
	);
}

const _x = (_mobile) => ({
	suggestion_box: {
		position: "absolute",
		width: "100%",
		// minHeight: 100,
		background: "white",
		boxShadow:
			"rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
		height: 400,
		overflowY: "scroll",
		// zIndex: 1,
		top: 40,
		borderBottomRightRadius: 12,
		borderBottomLeftRadius: 12,
	},
	suggestion_post: {
		height: 30,
		display: "flex",
		alignItems: "center",
		padding: 20,
		cursor: "pointer",
		fontWeight: 600,
	},
});
