import { Modal } from "@material-ui/core";
import React, { useEffect } from "react";
import authService from "src/services/auth.service";
import Spinner from "../Spinner/Spinner";
import Emitter from "src/services/emitter";

export default function TokenRefresher({ setIsOpen, isOpen }) {
	useEffect(() => {
		(async () => {
			const resp = await authService.refreshTokens();

			if (resp.success) {
				setIsOpen(false);
			} else {
				setIsOpen(false);

				Emitter.emit("token refresh failed");
			}
		})();
	}, []);

	return (
		<></>
		// <Modal open={isOpen}>
		// 	<div style={_x.t_refresher}>
		// 		<div style={_x.header}>Refreshing Tokens</div>

		// 		<Spinner
		// 			type={"pulse"}
		// 			fullscreen={false}
		// 			size={5}
		// 			spinnerstyles={{ height: 30 }}
		// 			isloading={isOpen}
		// 		/>
		// 	</div>
		// </Modal>
	);
}

const _x = {
	t_refresher: {
		background: "white",
		width: 374,
		height: 200,
		outline: "none",
		borderRadius: 6,
		margin: "270px auto",
		display: "grid",
		justifyContent: "center",
		paddingTop: 50,
		paddingBottom: 50,
	},

	header: {
		fontWeight: "bold",
		color: "var(--accent1)",
		fontSize: 20,
	},
};
