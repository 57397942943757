import { Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import Footer from '../../../Components/Functional/Footer/Footer';
import DownloadApp from '../../../Components/Functional/DownloadApp/DownloadApp';
import { Align, Spacing } from '../../../Components/Style/Styles';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import AuthService from '../../../services/auth.service';
import { authHeader } from '../../../services/auth-header';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import Select from 'react-validation/build/select';
import Textarea from 'react-validation/build/textarea';
import '../Advert/freead.css';
import ImageUploading from 'react-images-uploading';
import { makes3, years, category } from '../../../Resources/Resources';
import close2 from '../../../Resources/Icons/close2.png';
import Spinner from 'src/Components/Functional/Spinner/Spinner';
import { Modal, Paper } from '@material-ui/core';
import { Helmet } from 'react-helmet';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const required = (value) => {
  if (!value) {
    return (
      <div className="warning" role="alert">
        &#9432; This field is required!
      </div>
    );
  }
};

const EditAd = () => {
  const { id } = useParams();

  const allProducts =
    API_URL + 'products/find/' + `${id}` + '?fullDetails=true';

  const form = useRef();
  const checkBtn = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [currProduct, setCurrProduct] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(true);

  function closeModal() {
    setSubmitted(false);
    window.location.reload();
  }

  function selectDropDown(e, field) {
    const { value } = e.target;

    if (field === 'make') {
      if (value === '') {
      } else setInputs({ ...inputs, make: value });
    }
  }
  const currentUser = AuthService.getCurrentUser('user');

  const token = currentUser.data.access_token;

  const getData = async () => {
    setisloading(true);

    axios
      .get(allProducts)
      .then((response) => {
        const currentProduct = response.data.data;
        setCurrProduct(currentProduct);
        console.log(currentProduct);
        setisloading(false);
        const listPix = currentProduct.product_photo.map((item) => item);
        const newList = listPix;
        setImages(newList);

        setInputs({
          ...inputs,
          product_title: currentProduct.product_title,
          condition: currentProduct.condition,
          price: currentProduct.price,
          year: currentProduct.year,
          make: currentProduct.make,
          model: currentProduct.model,
          warranty: currentProduct.warranty,
          keyword: currentProduct.keyword,
          description: currentProduct.description,
          product_type: currentProduct.product_type,
          category_name: currentProduct.category,
          sub_category_name: currentProduct.sub_category,
          product_photo: newList,
          // negotiable: currentProduct.negotiable,
        });
        if (currentProduct.negotiable === 1) {
          setCheckboxValue(true);
        } else if (currentProduct.negotiable === 0) {
          setCheckboxValue(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setisloading(false);
      });
  };

  const [inputs, setInputs] = useState({
    category_name: 'Select Category',
    sub_category_name: '',
    product_title: '',
    product_type: '',
    keyword: '',
    condition: '',
    year: '',
    make: 'Select Make',
    model: '',
    warranty: '',
    description: '',
    price: '',
    product_photo: '',
    negotiable: '',
  });

  const handleLogin = (e) => {
    e.preventDefault();
    setisloading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      axios
        .patch(API_URL + 'products/update/' + `${id}`, inputs, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);

          setisloading(false);
          setSubmitted(true);
          window.scrollTo(0, 0);

          setInputs({
            category_name: 'Select Category',
            sub_category_name: '',
            product_title: '',
            product_type: '',
            keyword: '',
            condition: '',
            year: '',
            make: 'Audi',
            model: '2.0T',
            warranty: '',
            description: '',
            price: '',
            product_photo: '',
            negotiable: '',
            product_title: '',
          });
        })
        .catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setisloading(false);
          console.log(resMessage);
        });
      console.log(inputs);
    } else {
      setisloading(false);
    }
  };
  const changeHandle = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 5;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    const oldArry = images;

    setImages(imageList);

    const loadedImage = imageList.filter((item) => {
      const ee = item.data_url;
      if (ee !== undefined) {
        const gg = [...oldArry, ee];
        // console.log(gg);
        setImages(gg);
        setInputs({
          ...inputs,
          product_photo: gg,
        });
      }
    });
  };

  const checkchange = (e) => {
    setCheckboxValue(!checkboxValue);
    if (checkboxValue) {
      setInputs({
        ...inputs,
        negotiable: 0,
      });
    } else if (!checkboxValue) {
      setInputs({
        ...inputs,
        negotiable: 1,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {' Update ' + inputs.product_title + ' - quickmechs Solutions'}
        </title>
      </Helmet>
      <main className="edit-wrapper">
        <section>
          <div style={_x.title_sect}>
            <h2>Update Your Advert</h2>
            <p className="product-edit-tag">
              Edit the product fields as desired, when done please click on the
              update button to update your advert.
            </p>
          </div>
          <div className="form_title">
            <p>Current Ad Details</p>
          </div>
          <Form
            id="ad-form"
            className="ad-form"
            onSubmit={handleLogin}
            ref={form}
          >
            <div>
              <label htmlFor="Product">
                Product Category
                <Select
                  name="category_name"
                  value={inputs.category_name}
                  validations={[required]}
                  onChange={changeHandle}
                  onClick={(e) => selectDropDown(e, 'category_name')}
                >
                  <option value="">Select Category...</option>

                  {Object.keys(category).map((key, val) => {
                    return (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    );
                  })}
                </Select>
              </label>

              <label htmlFor="Product">
                Sub-Category
                <Select
                  name="sub_category_name"
                  value={inputs.sub_category_name}
                  validations={[required]}
                  onChange={changeHandle}
                  onClick={(e) => selectDropDown(e, 'sub_category_name')}
                >
                  <option value="">Select sub-category...</option>

                  {category[inputs.category_name].map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Select>
              </label>
            </div>
            <div>
              <label htmlFor="Product">
                Product Title
                <Input
                  type="text"
                  name="product_title"
                  maxLength="60"
                  validations={[required]}
                  onChange={changeHandle}
                  value={inputs.product_title}
                />
              </label>

              {/* <label htmlFor="Product">
								Type
								<Select
									name="product_type"
									value={inputs.product_type}
									validations={[required]}
									onChange={changeHandle}
								>
									<option value="">Select Type...</option>
									<option value="china">China</option>
									<option value="Belgium">Belgium</option>
								</Select>
							</label> */}
            </div>
            <div>
              <label htmlFor="Product">
                Relative Keyword
                <input
                  type="text"
                  placeholder="Enter a keyword"
                  name="keyword"
                  onChange={changeHandle}
                  value={inputs.keyword}
                />
              </label>

              <label htmlFor="condition">
                Condition
                <Select
                  name="condition"
                  value={inputs.condition}
                  validations={[required]}
                  onChange={changeHandle}
                >
                  <option value="">Select Condition...</option>
                  <option value="New">New</option>
                  <option value="Fairly Used">Fairly Used </option>
                </Select>
              </label>
            </div>
            <div>
              <label htmlFor="make">
                Make
                <Select
                  name="make"
                  value={inputs.make}
                  validations={[required]}
                  onChange={changeHandle}
                  onClick={(e) => selectDropDown(e, 'make')}
                >
                  {Object.keys(makes3).map((key, val) => {
                    return (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    );
                  })}
                </Select>
              </label>

              <label htmlFor="model">
                Model
                <Select
                  name="model"
                  value={inputs.model}
                  validations={[required]}
                  onChange={changeHandle}
                  onClick={(e) => selectDropDown(e, 'model')}
                >
                  <option value="">Select Model</option>

                  {makes3[inputs.make].map((eachModel) => {
                    return (
                      <option key={eachModel} value={eachModel}>
                        {eachModel}
                      </option>
                    );
                  })}
                </Select>
              </label>
            </div>
            <div>
              <label htmlFor="year">
                Year
                <Select
                  name="year"
                  value={inputs.year}
                  validations={[required]}
                  onChange={changeHandle}
                >
                  <option value="">Select Year</option>
                  {years.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </Select>
              </label>

              <label htmlFor="warranty">
                Warranty
                <Select
                  name="warranty"
                  value={inputs.warranty}
                  validations={[required]}
                  onChange={changeHandle}
                >
                  <option value="make">Select Duration</option>
                  <option value="1 week">1 Week</option>
                  <option value="2 weeks">2 Weeks</option>
                  <option value="1 month">1 Month</option>
                  <option value="2 months">2 Months</option>
                  <option value="3 months">3 Months</option>
                  <option value="4 months">4 Months</option>
                  <option value="6 months">6 Months</option>
                  <option value="1 year">1 year</option>
                  <option value="1 year and above">1 year and above</option>
                </Select>
              </label>
            </div>
            <div>
              <label htmlFor="text-area">
                Description
                <Textarea
                  type="text"
                  placeholder="Type Description"
                  rows="3"
                  name="description"
                  maxLength="500"
                  validations={[required]}
                  onChange={changeHandle}
                  value={inputs.description}
                />
                {/* <p className='input-info'>Field must be between 10 and 500  characters. Note: CAPS LOCK text will be automatically edited</p> */}
              </label>
            </div>

            <div>
              <label htmlFor="price" id="price">
                Price
                <div className="price-col">
                  <p>$</p>
                  <NumberFormat
                    thousandSeparator={true}
                    placeholder="Enter Price"
                    name="price"
                    onChange={changeHandle}
                    value={inputs.price}
                    onKeyPress={(e) => {
                      if (e.key === 'e' || e.key === '-' || e.key === '+') {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </label>
              <label htmlFor="photo">
                Upload Item Photo
                <div
                  style={{
                    height: '40px',
                    fontSize: '1.1rem',
                    marginTop: '10px',
                  }}
                >
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div>
                        <p
                          className="image-up"
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          +
                        </p>
                        &nbsp;
                        <section className="image-list-col">
                          {images.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image} alt="" width="50" height="50" />
                              <div className="image-item__btn-wrapper">
                                <p
                                  className="reloadbin"
                                  onClick={() => onImageRemove(index)}
                                >
                                  x
                                </p>
                              </div>
                            </div>
                          ))}
                        </section>
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </label>
            </div>

            <div className="negotiate">
              <input
                type="checkbox"
                className="negotiable"
                name="negotiable"
                value={inputs.negotiable}
                checked={checkboxValue}
                onChange={checkchange}
              />
              <p> Negotiable </p>
            </div>

            <button className="post_btn">Update</button>
            <CheckButton style={{ display: 'none' }} ref={checkBtn} />
          </Form>

          {submitted && (
            <section style={_x.modal_bg}>
              <div
                open={submitted === false}
                // onClick={closeModal}
                style={{ border: 'none' }}
              >
                <div style={_x.modall}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <img
                      onClick={closeModal}
                      style={_x.close}
                      src={close2}
                      alt="close"
                    />
                  </div>

                  <p
                    style={{
                      fontFamily: 'montserrat',
                      fontSize: '14px',
                      textAlign: 'center',
                      color: 'green',
                      fontSize: '1.2rem',
                      fontWeight: '500',
                    }}
                  >
                    Your Ad has been Successfully Updated
                  </p>
                  <article style={{ display: 'flex' }}>
                    <Link
                      to="/home/dashboard"
                      style={{ textDecoration: 'none' }}
                    >
                      <button style={_x.continue}>Go to Dashboard</button>
                    </Link>
                    <Link
                      to={`/home/spare-parts/${id}/${currProduct.product_title}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <button style={_x.continue}>View Updated Ad</button>
                    </Link>
                  </article>
                </div>
              </div>
            </section>
          )}
        </section>
        <Spinner isloading={isloading} />
      </main>

      <Spacing vertical={100} />

      <DownloadApp />
      <Footer />
    </>
  );
};

export default EditAd;

const _x = {
  btn1: {
    backgroundColor: 'var(--primary2)',
    textTransform: 'none',
    fontWeight: 'bolder',
    fontSize: '18px',
    border: '1px solid',
    paddingLeft: 150,
    paddingRight: 150,
    fontFamily: 'montserrat',
    marginTop: '1rem',
    marginLeft: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '5px',
    marginBottom: '3rem',
  },
  btn: {
    background: 'transparent',
    height: 30,
    width: 20,
    fontSize: 22,
    color: 'grey',
    borderRadius: 0,
    padding: 0,
    margin: 0,
  },
  title_sect: {
    marginBottom: '53px',
    textAlign: 'center',
  },

  header: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  header2: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: '2px',
    color: '#222222',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
  },
  btn2: {
    color: 'var(--primary1)',
    fontFamily: 'montserrat',
    paddingTop: '10px',
  },
  modall: {
    background: 'white',
    // width: 374,
    height: 196,
    outline: 'none',
    borderRadius: 6,
    margin: '170px auto',
    display: 'grid',
    placeItems: 'center',
    padding: 20,
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  continue: {
    height: 40,
    // width: 137,
    backgroundColor: 'var(--primary2)',
    paddingLeft: 15,
    paddingRight: 15,
    border: '1px solid',
    borderRadius: 6,
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: '1.7rem',
    marginLeft: '1rem',
  },

  close: {
    cursor: 'pointer',
  },
  modal_bg: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: '#00000099',
  },
};
