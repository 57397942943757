import { BrowserRouter as Router } from "react-router-dom";
import Header from "./Components/Functional/Header/Header";
import { Brim, Control } from "./State/Control";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useContext } from "react";
import Sidebar from "./Components/Functional/Sidebar/Sidebar";
import Routes from "./Routes/Routes";
import LinearProgress from "@material-ui/core/LinearProgress";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: "#1976d2",
        main: "#1976d2",
      },
      secondary: {
        light: "#fdd05d",
        main: "#fdd05d",
      },
    },
    typography: {
      body1: {
        fontSize: 13,
      },
      body2: {
        fontWeight: "bold",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId="49697435067-fdterihcudrts2f8qssud3iftn3oj3hu.apps.googleusercontent.com">
        <Router>
          <Control>
            <div style={_x.app}>
              <Header />
              <Loader />
              <Routes />
              <Sidebar />
            </div>
          </Control>
        </Router>
        ;
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

function Loader() {
  const [control] = useContext(Brim);

  return <>{control.loader.isOpen && <LinearProgress />}</>;
}

export default App;

const _x = {
  app: {
    height: "100vh",
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    position: "relative",
    background: "white",
  },
};
