export const jobTitleoptions = [
  {
    value: "mechanic",
    label: "Mechanic",
    category: [
      { value: "General Repairs", label: "General Repairs" },
      { value: "Computer Diagnosis", label: "Computer Diagnosis" },
      { value: "Routine Servicing", label: "Routine Servicing" },
      { value: "Engine Repair", label: "Engine Repair" },
      { value: "Break Repair", label: "Break Repair" },
    ],
  },
  {
    value: "Body Shop",
    label: "Body Shop",
    category: [
      { value: " Fix Body Part", label: "Fix Body Part" },
      { value: "Remove Dents", label: "Remove Dents" },
      { value: "Install Windshields", label: "Install Windshields" },
      { value: "Painting Straighten", label: "Painting Straighten" },
      { value: "Metal Panels", label: "Metal Panels" },
    ],
  },
  {
    value: "Car Air Conditioning",
    label: "Car Air Conditioning",
    category: [
      { value: "Install", label: "Install" },
      { value: "Compressor", label: "Compressor" },
      { value: "Fix Leakage", label: "Fix Leakage" },
      { value: "Condenser", label: "Condenser" },
      { value: "Fill Gas", label: "Fill Gas" },
    ],
  },
  {
    value: "Electrician",
    label: "Electrician",
    category: [
      { value: "Lighting", label: "Lighting" },
      { value: "System", label: "System" },
      { value: "Coil", label: "Coil" },
      { value: "Fuse", label: "Fuse" },
      { value: "Fill Gas", label: "Fill Gas" },
      {
        value: "Fuel Pump",
        label: "Fuel Pump",
      },
      {
        value: "Rewire",
        label: "Rewire",
      },
    ],
  },
  {
    value: "Tire Shop",
    label: "Tire Shop",
    category: [
      { value: "Flat Tire", label: "Flat Tire" },
      { value: "Gauge Tire", label: "Gauge Tire" },
      { value: "Wheel Balancing", label: "Wheel Balancing" },
      { value: "Alignment Rotating", label: "Alignment Rotating" },
      { value: " Tire Replacement", label: " Tire Replacement" },
    ],
  },
  {
    value: "Towing",
    label: "Towing",
    category: [
      { value: "Accident Car", label: "Accident Car" },
      { value: "Gauge Tire", label: "Gauge Tire" },
      { value: "Road Assistant", label: "Road Assistant" },
      { value: "Transportation", label: "Transportation" },
      { value: "Heavy Duty", label: "Heavy Duty" },
      { value: "Small Duty", label: "Small Duty" },
    ],
  },
  {
    value: "Security",
    label: "Security",
    category: [
      { value: " Car Alarm", label: " Car Alarm" },
      { value: "GPS", label: "GPS" },
      { value: "Key Programmer", label: "Key Programmer" },
      { value: "Camera", label: "Camera" },
    ],
  },
];
