import React from "react";

export default function Sectioner({ currentIndex, sections }) {
	return (
		<div
			style={{
				..._x.sectioner,
				display: "grid",
				gridTemplateColumns: `repeat(${sections.length},1fr)`,
			}}
		>
			{sections.map((e, idx) => (
				<Slide key={e} name={e} slideIndex={idx} currentIndex={currentIndex} />
			))}
		</div>
	);
}

function Slide({ name, currentIndex, slideIndex }) {
	return (
		<div style={_x.slide}>
			<div
				style={{
					..._x.bar,
					background: currentIndex === slideIndex ? "var(--primary1)" : "grey",
				}}
			/>

			<div style={{ textAlign: "center", fontSize: 9 }}>{name}</div>

			<div style={{ textAlign: "center", fontSize: 9, margin: 0 }}>
				{slideIndex + 1}
			</div>
		</div>
	);
}

const _x = {
	sectioner: {
		height: 50,
		width: "100%",
		display: "flex",
		background: "white",
		zIndex: 300,
	},

	slide: {
		height: 8,
		width: "100%",
	},

	bar: {
		borderRadius: 5,
		height: 5,
		margin: 10,
	},
};
