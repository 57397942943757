import React from "react";
import { Spacing } from "../../Style/Styles";
import ad1 from "../../../Resources/Images/ad.jpg";
import ad2 from "../../../Resources/Images/ad2.jpg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function AdService({ img, vertical, horizontal, width }) {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			{vertical && (
				<div
					style={{
						..._x(_mobile).ad_vertical_type,
						backgroundImage: `url(${img ?? ad1})`,
					}}
				></div>
			)}

			{horizontal && (
				<div
					style={{
						..._x(_mobile).ad_horizontal_type,
						backgroundImage: `url(${img ?? ad1})`,
						width: _mobile ? "100%" : width ?? "100%",
						maxWidth: 1100,
					}}
				>
					<div style={_x().text1}>Perfect Engine Oil for your Vehicle</div>
					<Spacing />
					<button style={_x().btn}>Click here</button>
				</div>
			)}
		</>
	);
}

export default AdService;

const _x = (_mobile) => ({
	ad: {
		// backgroundImage:
	},

	ad_vertical_type: {
		height: 427,
		width: 124,
		backgroundPosition: "center",
		backgroundSize: "cover",
		borderRadius: 6,
		backgroundColor: "var(--secondary1)",
		// opacity: 0.8,
	},

	ad_horizontal_type: {
		height: 90,
		borderRadius: 6,
		backgroundPosition: "center",
		backgroundSize: "cover",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "flex-end",
		padding: 10,
		paddingRight: 20,
	},

	text1: {
		fontSize: 21,
		color: "#faff00",
		fontWeight: "bold",
	},

	btn: {
		background: "var(--primary1)",
		fontSize: 15,
		color: "white",
		fontWeight: "bold",
		borderRadius: 6,
		height: 36,
		width: 90,
		// padding: 10,
		textAlign: "center",
	},
});
