import axios from "axios";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, withRouter } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import hide from "../../../Resources/Icons/hide.svg";
import reg1 from "../../../Resources/Icons/reg1.png";
import reg2 from "../../../Resources/Icons/reg2.png";
import reg3 from "../../../Resources/Icons/reg3.png";
import show from "../../../Resources/Icons/show.svg";
import { authHeader } from "../../../services/auth-header";
import AuthService from "../../../services/auth.service";
import FacebookSignIn from "./FacebookSignIn";
import GoogleSignin from "./GoogleSignin";
import "./login.css";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				{/* This field is required! */}
			</div>
		);
	}
};

const Login = () => {
	const form = useRef();
	const checkBtn = useRef();
	const loadref = useRef(20);
	let history = useHistory();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState("");
	const [showpassword, setshowpassword] = useState(false);
	const [isloading, setisloading] = useState(false);

	function togglePassword() {
		setshowpassword(!showpassword);
	}

	const onChangeEmail = (e) => {
		const email = e.target.value;
		setEmail(email);
	};

	const onChangePassword = (e) => {
		const password = e.target.value;
		setPassword(password);
	};

	const handleLogin = (e) => {
		e.preventDefault();

		setMessage("");
		setisloading(true);

		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			loadref.current.continuousStart(93);
			AuthService.login(email, password).then(
				() => {
					const currentUser = AuthService.getCurrentUser("user");
					const verified = currentUser.data.user_info.verified;

					if (verified === "yes") {
						history.push("/home");
					} else if (verified === "no") {
						axios
							.get(API_URL + "users/resend-code", {
								headers: authHeader(),
							})
							.then((res) => {
								history.push("/auth/verify");
							})
							.catch((err) => {
								console.log(err);
							});
					}
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					// setLoading(false);
					setisloading(false);
					loadref.current.complete();
					setMessage("Incorrect Email or Password");
				}
			);
		} else {
			setisloading(false);
		}
	};

	return (
		<main className="login-wrapper">
			{/* <h4 className="mobile_title">Sign in or select an option</h4> */}
			<div className="login">
				<div className="login-title">Sign in with your email</div>

				<Form className="reg-box-left " onSubmit={handleLogin} ref={form}>
					{message === "Network Error" && (
						<p className="topbarError">{message}</p>
					)}
					<div className="input-box space">
						<label htmlFor="email">Email Address</label>
						<Input
							type="text"
							className="form-control"
							name="email"
							value={email}
							onChange={onChangeEmail}
							validations={[required]}
						/>
					</div>

					<div className="input-box space ">
						<label htmlFor="password">Password</label>
						<div className="pass_field">
							<Input
								type={showpassword ? "text" : "password"}
								className="form-control pass"
								name="password"
								value={password}
								onChange={onChangePassword}
								validations={[required]}
							/>
							<img
								alt="eye"
								src={showpassword ? hide : show}
								style={_x.eye}
								onClick={togglePassword}
							/>
						</div>
					</div>
					{message && (
						<div className="form-group">
							<div className="alert alert-danger" role="alert">
								&#9432; {message}
							</div>
						</div>
					)}
					<Link
						to="/auth/forgot-password"
						style={{ textDecoration: "none", color: " #587FC0" }}
					>
						<div className="space-2">Forgot Password?</div>
					</Link>

					<div className="form-group">
						<button className="signin-btn">
							<span>Sign In</span>
						</button>
						<p className="signUp-info">
							Don't have an account?
							<Link to="/auth/register"> Sign Up Now </Link>
						</p>
					</div>

					<CheckButton style={{ display: "none" }} ref={checkBtn} />
				</Form>

				<div className="reg-box-right">
					<div className="reg-box-inner">
						<GoogleSignin />
						<FacebookSignIn />
						{/* <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            /> */}

						<section className="login-details">
							<div style={_x.tiny}>
								<img style={_x.tiny_img} src={reg1} alt="tiny" />
								We keep it Private
							</div>
							<div style={_x.tiny}>
								<img style={_x.tiny_img} src={reg2} alt="tiny" />
								Share only with Permission
							</div>
							<div style={_x.tiny}>
								<img style={_x.tiny_img} src={reg3} alt="tiny" />
								Quick Sign in - No password
							</div>
						</section>
					</div>
				</div>
			</div>
			<Spinner isloading={isloading} />
			<LoadingBar color="#587FC0" ref={loadref} loaderSpeed={4000} />
			<Helmet>
				<title>{"Login - Quickmechs"}</title>
			</Helmet>
		</main>
	);
};

export default withRouter(Login);

const _x = {
	modal: {
		outline: "none",
		height: "100%",
		display: "grid",
		placeItems: "center",
	},
	tiny_img: {
		objectFit: "contain",
		marginRight: 10,
	},
	tiny: {
		display: "flex",
		marginBottom: "11px",
		fontSize: "10px",
	},
	eye: {
		width: "20px",
		marginTop: "7px",
		height: "20px",
		cursor: "pointer",
		padding: "0 10px 0",
	},
};
