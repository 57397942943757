import { Modal } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { useState } from "react";
import ReactCodeInput from "react-code-input";
import { IoClose } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import authService from "src/services/auth.service";
import { Spacing } from "../../../Components/Style/Styles";
import close2 from "../../../Resources/Icons/close2.png";
import email from "../../../Resources/Icons/ver-email.png";
import sms from "../../../Resources/Icons/ver-sms.png";
import whatsapp from "../../../Resources/Icons/ver-whatsapp.png";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const VerificationStates = {
	EMPTY_FIELD: "EMPTY_FIELD",
	VERIFICATION_FAILED: "VERIFICATION_FAILED",
	VERIFICATION_SUCCESSFUL: "VERIFICATION_SUCCESSFUL",
	NONE: "NONE",
};

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;

function Verification() {
	const [isverified, setisverified] = useState(VerificationStates.NONE);
	const [showErr, setshowErr] = useState(false);
	const [code, setCode] = useState(false);
	const [userToVerify, setuserToVerify] = useState({});
	const [isloading, setisloading] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const location = useLocation();

	const history = useHistory();
	const _mobile = useMediaQuery("(max-width:800px)");

	// useEffect(() => {
	//   const user = authService.getCurrentUser();
	//   setuserToVerify(user?.data.user_info);
	// }, []);

	const [via, setVia] = useState({
		open: false,
		options: {
			"Email Address": email,
			"Text Message (SMS)": sms,
			Whatsapp: whatsapp,
		},
		active: "Email Address",
	});
	const token = { Authorization: "Bearer " + authService.getToken() };

	async function submit() {
		setshowErr(true);

		if (inputValue.length < 4) {
			setisverified(VerificationStates.EMPTY_FIELD);
		} else {
			setisloading(true);
			const resp = await authService.verifyUser(inputValue);
			resp.success
				? setisverified(VerificationStates.VERIFICATION_SUCCESSFUL)
				: setisverified(VerificationStates.VERIFICATION_FAILED);
			setisloading(false);
		}
	}

	const resendOtp = () => {
		setVia({ ...via, open: false });

		let path = "users/resend-code";

		axios
			.get(
				API_URL +
					path +
					`${
						via.active === "Email Address"
							? ``
							: via.active === "Text Message (SMS)"
							? `/phone-number?type=sms`
							: `/phone-number?type=whatsapp`
					}`,
				{
					headers: token,
				}
			)
			.then((res) => {
				setCode(true);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => setisloading(false));
	};

	async function continueToHome() {
		setisloading(true);

		const resp = await authService.login(
			location.state.email,
			location.state.password
		);

		setisloading(false);

		if (resp.status === "success") {
			history.push("/auth/upload-profile-photo");
			localStorage.removeItem("eTuBd");
			localStorage.removeItem("pTuBd");
		}
	}

	function closeModal() {
		// setisverified(VerificationStates.EMPTY_FIELD);
		// setVia({ ...via, open: false });
	}

	return (
		<main
			style={{
				backgroundColor: "#fff",
				height: "100vh",
				padding: _mobile ? 0 : 0,
			}}
		>
			<section style={{ height: "400px", padding: "60px 0" }}>
				<p style={_x().text1}>
					Enter the four digit code that was sent to
					{via.active === "Email Address"
						? ` your Email Address ${localStorage.getItem("eTuBd")}`
						: via.active === "Text Message (SMS)"
						? ` your Phone Number ${localStorage.getItem("pTuBd")}`
						: ` your whatsapp Number ${localStorage.getItem("pTuBd")}`}
				</p>
				<div style={_x(_mobile).verification} elevation={_mobile ? 0 : 1}>
					<div>
						<div style={_x(_mobile).topside}>
							<p style={_x().text3}>Enter Code</p>

							<ReactCodeInput
								type="number"
								value={inputValue}
								fields={4}
								inputStyle={_x().inputBit}
								onChange={(e) => {
									setshowErr(false);
									setInputValue(e);
								}}
							/>
						</div>

						<p style={_x().text2}>
							{isverified === VerificationStates.EMPTY_FIELD &&
								showErr &&
								"Code entered is incomplete"}

							{isverified === VerificationStates.VERIFICATION_FAILED &&
								showErr &&
								"Code entered is incorrect. Enter correct code"}
						</p>

						<Spacing />

						<div style={_x(_mobile).bottom}>
							<button onClick={submit} style={_x().submit}>
								Submit
							</button>

							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									width: "100%",
									fontWeight: "500",
								}}
							>
								<p onClick={resendOtp} style={_x().bounto}>
									Resend Code
								</p>
								<p
									onClick={() => setVia({ ...via, open: true })}
									style={_x().bounto}
								>
									More Options
								</p>
							</div>
						</div>
					</div>

					{code ? <div style={_x().codeModalBg}></div> : null}

					{code ? (
						<div style={_x().codeModal}>
							Code has been sent to{" "}
							{via.active === "Email Address"
								? ` your Email Address ${localStorage.getItem("eTuBd")}`
								: via.active === "Text Message (SMS)"
								? ` your Phone Number ${localStorage.getItem("pTuBd")}`
								: ` your whatsapp Number ${localStorage.getItem("pTuBd")}`}
							<p onClick={() => setCode(false)} style={_x().closeBtn}>
								<IoClose />
							</p>
						</div>
					) : null}

					<Modal
						open={isverified === VerificationStates.VERIFICATION_SUCCESSFUL}
						onClick={closeModal}
						style={{ border: "none" }}
					>
						<div style={_x(_mobile).modal}>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<img
									onClick={closeModal}
									style={_x().close}
									src={close2}
									alt="close"
								/>
							</div>

							<p style={{ fontFamily: "montserrat", fontSize: "16px" }}>
								{via.active} Verification Successful
							</p>

							<button onClick={continueToHome} style={_x().continue}>
								Continue
							</button>
						</div>
					</Modal>
					<Modal
						open={via.open}
						onClick={closeModal}
						style={{ border: "none" }}
					>
						<div style={_x().modal3}>
							<div style={{ ..._x().text1, ..._x().title }}>More Options</div>

							<div style={_x(_mobile).modal_main}>
								<div style={{ paddingInline: 20 }}>
									<div style={_x().text3}>
										Choose another way to get a verification
									</div>
									<div
										style={{ fontSize: 10, marginTop: 10, marginBottom: 10 }}
									>
										make sure your notifications are turned on
									</div>
								</div>

								<div>
									{Object.keys(via.options).map((e) => (
										<div
											style={_x().option}
											onClick={() => setVia({ ...via, active: e })}
										>
											<div
												style={{
													display: "flex",
													alignItems: "center",
													fontWeight: 500,
												}}
											>
												<img
													src={via.options[e]}
													style={{ marginRight: 10, width: 20 }}
												/>
												{e}
											</div>

											<div
												style={{
													width: 16,
													height: 16,
													borderRadius: "100%",
													border: `${e === via.active ? 5 : 1}px solid ${
														e !== via.active ? "lightgrey" : "var(--primary1)"
													}`,
												}}
											/>
										</div>
									))}
								</div>
							</div>

							<div
								style={{
									width: "100%",
									display: "grid",
									placeItems: "center",
								}}
							>
								<button
									onClick={resendOtp}
									style={{ ..._x().continue, ..._x().resend }}
								>
									Resend Code
								</button>
							</div>
						</div>
					</Modal>
				</div>
				<p className="verify-notice">
					<span>Note:</span>
					Incase you cannot find the code in your inbox, please check your spam
					folder
				</p>
			</section>

			<Spinner isloading={isloading} />
		</main>
	);
}

export default Verification;

const _x = (_mobile) => ({
	modal3: {
		width: _mobile ? "90%" : 374,
		outline: "none",
		borderRadius: 6,
		margin: "170px auto",
		padding: 0,
		backgroundColor: "white",
		height: 370,
		display: "grid",
		gridTemplateRows: "auto 1fr auto",
	},
	option: {
		height: 45,
		width: "100%",
		borderBottom: "1px solid lightgrey",
		display: "flex",
		alignItems: "center",
		paddingInline: 20,
		cursor: "pointer",
		justifyContent: "space-between",
	},

	verification: {
		margin: "1rem auto",
		padding: 25,
		minHeight: _mobile ? "" : 260,
		width: _mobile ? "100%" : 460,
		justifyContent: "center",
		alignItems: "center",
	},

	divBlocks: {
		width: _mobile ? "100%" : 270,
		display: "grid",
		gridTemplateColumns: "repeat(4,1fr)",
		justifyContent: "space-between",
		// backgroundColor: "red",
	},

	inputBit: {
		width: 45,
		height: 45,
		textAlign: "center",
		fontSize: 25,
		fontWeight: "bold",
		borderRadius: 5,
		marginRight: 15,
	},

	topside: {
		width: "100%",
		display: _mobile ? "flex" : "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},

	title: {
		height: 60,
		width: "100%",
		fontWeight: "bold",
		borderBottom: "1px solid lightgrey",
		display: "grid",
		placeItems: "center",
	},

	resend: {
		width: "80%",
	},

	modal_main: {
		width: "100%",
		paddingTop: 20,
	},

	text1: {
		fontSize: 15,
		width: "100%",
		textAlign: "center",
	},

	input: {
		outline: "none",
	},

	text2: {
		fontSize: 15,
		color: "#CF2D48",
		textAlign: "center",
	},

	submit: {
		backgroundColor: "var(--primary1)",
		color: "white",
		paddingLeft: 15,
		paddingRight: 15,
		// border: '1px solid',
		textTransform: "capitalize",
		fontWeight: "600",
		borderRadius: 6,
		height: 40,
		fontSize: 18,
		width: "100%",
		marginBottom: "20px",
	},

	text3: {
		fontSize: 14,
	},

	text4: {
		color: "var(--accent1)",
		fontSize: 13,
	},
	bottom: {
		display: "flex",
		// flexDirection: _mobile ? "column" : "row",
		flexDirection: "column",
		alignItems: "center",
		margin: "0 auto",
		width: _mobile ? "100%" : "60%",
	},
	text5: {
		fontSize: 16,
	},

	modal: {
		background: "white",
		width: _mobile ? "90%" : 374,
		height: 196,
		outline: "none",
		borderRadius: 6,
		margin: "170px auto",
		display: "grid",
		placeItems: "center",
		padding: 20,
	},

	continue: {
		height: 40,
		width: 137,
		backgroundColor: "var(--primary1)",
		paddingLeft: 15,
		paddingRight: 15,
		border: "none",
		borderRadius: 6,
		color: "#fff",
		fontWeight: "bold",
		fontSize: 16,
		marginBottom: "1.7rem",
	},

	close: {
		cursor: "pointer",
	},
	codeModal: {
		width: "370px",
		height: "170px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "4px",
		position: "absolute",
		textAlign: "center",
		padding: "0 20px",
		top: "30%",
		left: "50%",
		fontSize: "16px",
		transform: "translate(-50%, -50%)",
		backgroundColor: "#fff",
		zIndex: "6",
	},
	closeBtn: {
		position: "absolute",
		right: "16px",
		top: "0px",
		cursor: "pointer",
		zIndex: "6",
	},
	codeModalBg: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: "0",
		left: "0",
		backgroundColor: "#000000aa",
		zIndex: "4",
	},

	bounto: {
		// marginLeft: "2rem",
		color: "#587FC0",
		fontSize: "14px",
		cursor: "pointer",
		margin: "10px 10px",
	},
});
