/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import Geocode from "react-geocode";
import {
	GoogleMap,
	Marker,
	withGoogleMap,
	withScriptjs,
} from "react-google-maps";
import { FaHeart, FaRegHeart, FaShareAlt } from "react-icons/fa";
import { compose, withProps } from "recompose";
import Popup from "src/Components/Functional/Popup/Popup";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import LoginPopUp from "src/Components/Functional/loginpop/LoginPopUp";
import ShareBtns from "src/Components/Functional/share-buttons/ShareBtns";
import "src/Components/Style/Style.css";
import { Hr, Spacing } from "src/Components/Style/Styles";
import { authHeader } from "src/services/auth-header";
import authService from "src/services/auth.service";
import mechService from "src/services/mech.service";
import { concatenateDate, toTitleCase, weekmap } from "../../../Global/Global";
import mapIcon from "../../../Resources/Icons/mapIcon.png";
import AvailableSlots from "./AvailableSlots";

function MechOfficeTopside({
	name,
	email,
	phoneNumber,
	address,
	workingHours,
	mechanicId,
	mechanicShopId,
	state,
	city,
	category,
	sub_category,
	vehicle_type,
	customer_reviews,
	liked,
	mobile,
	instant,
}) {
	const [isloading, setisloading] = useState(false);
	const [latitude, setLatitude] = useState(6.4470819);
	const [longitude, setLongitude] = useState(3.4583574);
	const [ultimaschedule, setultimaschedule] = useState([]);
	const [newlybooked, setnewlybooked] = useState({});
	const [loginpopup, setloginpopup] = useState(false);
	const [workweekchips, setworkweekchips] = useState([]);
	const [noHours, setnoHours] = useState("");
	const [showNumber, setshowNumber] = useState(false);
	const [isLiked, setisLiked] = useState(false);
	const _mobile = useMediaQuery("(max-width:800px)");
	const [revealShareBtns, setrevealShareBtns] = useState(false);
	const [workschedule, setworkschedule] = useState({
		booked: [],
		weekdays: "",
		timerange: "",
		totalHrs: "",
	});
	const [calendar, setcalendar] = useState({
		weekday: "",
	});
	const [selected, setselected] = useState({
		time: "",
		date: "",
		meridian: "",
		fulldate: "",
	});
	const [popup, setpopup] = useState({
		isOpen: false,
		message: "",
		headerText: "",
	});

	useEffect(() => fetchSchedules(), [selected, mechanicShopId]);

	useEffect(() => {
		if (authService.getCurrentUser("user")) {
			setshowNumber(true);
		} else {
			setshowNumber(false);
		}
	}, []);

	function slotSelected(time, meridian) {
		setselected({
			...selected,
			time: time,
			meridian: meridian,
		});
	}

	function onAvailabilityComponentInit(slot, value) {
		setworkschedule({ ...setworkschedule, [`${slot}`]: value });

		switch (slot) {
			case "weekday":
				setcalendar({ ...calendar, weekday: value });
				break;

			case "date":
				setselected({ ...selected, date: value.toString().substring(8, 10) });
				break;

			default:
				break;
		}
	}

	async function fetchSchedules() {
		// console.log("=----------- fetching schedules -----------=", mechanicShopId);
		const resp = await mechService.getMechanicSchedule(mechanicShopId);

		if (resp.status === 200) {
			setnoHours(resp.data[0]["hours_available"].length);
			setworkweekchips(reorganiseWeekdays(resp.data));

			setultimaschedule([...resp.data]);
		}

		// mechService.getMechanicSchedule(mechanicShopId).then((resp) => {
		// 	console.log(
		// 		"🚀 ~ file: MechOfficeTopside.js ~ line 121 ~ mechService.getMechanicSchedule ~ resp",
		// 		resp
		// 	);

		// 	setnoHours(resp.data[0]["hours_available"].length);

		// 	setworkweekchips(reorganiseWeekdays(resp.data));

		// 	setultimaschedule([...resp.data]);
		// });
	}

	function onCalendarChange(val, e) {
		if (authService.getCurrentUser("user")) {
			setselected({
				...selected,
				date: val.toString().substring(8, 10),
				fulldate: concatenateDate(val),
			});
			setcalendar({
				...calendar,
				weekday: weekmap[val.toString().substring(0, 3)],
				selectedDate: moment(val).format("YYYY-MM-DD"),
			});
		} else {
			setloginpopup(true);
		}
	}

	async function bookAppointment() {
		setisloading(true);

		const resp = await mechService.bookAppointment(
			mechanicShopId,
			selected.fulldate,
			selected.time,
			"description",
			selected.meridian,
			category,
			sub_category,
			vehicle_type
		);

		setisloading(false);

		if (resp.success) {
			setpopup({
				...popup,
				isOpen: true,
				message:
					"Your Appointment has been booked. Check your email address for confirmation",
				headerText: "Congratulations",
			});

			setnewlybooked({
				date: selected.fulldate,
				slot: selected.time + " " + selected.meridian,
				time: selected.time,
			});
		} else {
			setpopup({
				...popup,
				isOpen: true,
				message:
					Object.values(resp.errorResponse)[0]["message"] ??
					"Could Not Book Appointment",
				headerText: "",
			});
		}
	}

	function highlightDay(e) {
		const chip = document.getElementById(e);

		const allChips = document.getElementsByClassName("mechofficechips");

		Array.from(allChips).forEach((each) => {
			each.style.border = "1px solid lightgrey";
			each.style.fontWeight = "normal";
		});

		chip.style.border = "2px solid var(--secondary1)";
		chip.style.fontWeight = "500";

		setnoHours(
			ultimaschedule.filter((each) => each.day === e)[0]["hours_available"]
				.length
		);
	}

	function getWeekDay(day) {
		return weekmap[Object.keys(weekmap)[day]];
	}

	function reorganiseWeekdays(arr) {
		let oldArr = [];

		let newArr = [];

		arr.map((e) => oldArr.push(e.day));

		Object.keys(weekmap).map((key, _) => {
			if (oldArr.includes(weekmap[key])) {
				newArr.push(weekmap[key]);
			}

			return "";
		});

		return newArr;
	}

	function removeNumberCover() {
		if (authService.getCurrentUser("user")) {
			setshowNumber(true);
		} else {
			setloginpopup(true);
		}
	}

	Geocode.setApiKey("AIzaSyC8MxZB_qWnOrh1E4RnSE4rqDuMsCGIAek");
	Geocode.setLanguage("en");
	Geocode.setLocationType("ROOFTOP");
	Geocode.fromAddress(address).then(
		(response) => {
			const { lat, lng } = response.results[0].geometry.location;
			let latt = lat;
			let longg = lng;
			setLatitude(latt);
			setLongitude(longg);
		},
		(error) => {
			console.error(error);
		}
	);

	useEffect(() => {
		setisLiked(liked);
	}, [liked]);

	const MyMapComponent = compose(
		withProps({
			googleMapURL:
				"https://maps.googleapis.com/maps/api/js?key=AIzaSyC2nYnaejAUiy32kKgu3ijbHoLpca8G2-4&v=3.exp&libraries=geometry,drawing,places",
			loadingElement: <div style={{ height: `100%` }} />,
			containerElement: <div style={{ height: `200px` }} />,
			mapElement: <div style={{ height: `100%` }} />,
		}),
		withScriptjs,
		withGoogleMap
	)((props) => (
		<GoogleMap
			defaultZoom={14}
			defaultCenter={{ lat: latitude, lng: longitude }}
			defaultOptions={{
				zoomControl: false,
				mapTypeControl: false,
				streetViewControl: false,
				keyboardShortcuts: false,
				mapData: false,
				termsOfUse: false,
			}}
		>
			{props.isMarkerShown && (
				<>
					<Marker
						position={{ lat: latitude, lng: longitude }}
						icon={{ url: mapIcon }}
					>
						{/* <InfoWindow>
							<div>{name}</div>
						</InfoWindow> */}
					</Marker>
				</>
			)}
		</GoogleMap>
	));

	const {
		REACT_APP_CURRENT_ENVIRONMENT,
		REACT_APP_DEVELOPMENT_URL,
		REACT_APP_PRODUCTION_URL,
	} = process.env;

	const API_URL =
		REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
			? REACT_APP_DEVELOPMENT_URL
			: REACT_APP_PRODUCTION_URL;

	const likeProduct = () => {
		axios
			.post(API_URL + `mechanic/${mechanicShopId}/like`, "body", {
				headers: authHeader(),
			})
			.then((res) => setisLiked(true))
			.catch((err) => console.log(err));
	};

	const unlikeProduct = () => {
		axios
			.post(API_URL + `mechanic/${mechanicShopId}/unlike`, "body", {
				headers: authHeader(),
			})
			.then((res) => setisLiked(false))
			.catch((err) => console.log(err));
	};

	return (
		<>
			<div className="like-contain">
				<div
					className="like-wrap"
					style={{
						width: "100%",
					}}
				>
					<div
						style={{
							marginBottom: "0.5rem",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "flex-start",
							position: "relative",
						}}
					>
						<div>
							<p style={_x().header}>{toTitleCase(name)}</p>

							<div
								style={{
									display: "grid",
									gridTemplateColumns: "auto 1fr",
									marginTop: 3,
									gap: 10,
								}}
							>
								<div style={{ ..._x().sub_header, marginTop: 5 }}>
									Availability :
								</div>

								<div
									style={{
										display: "flex",
										flexWrap: "wrap",
									}}
								>
									{workweekchips.map((e) => (
										<span
											id={e}
											className={"mechofficechips"}
											style={_x().chip}
											onMouseOver={() => highlightDay(e)}
										>
											{e}
										</span>
									))}
								</div>
							</div>
						</div>

						<div className="mech-like for-desktop">
							<p
								className="likeBtn"
								onClick={isLiked ? unlikeProduct : likeProduct}
							>
								{isLiked ? (
									<FaHeart color={"red"} size={20} />
								) : (
									<FaRegHeart color={"#646464"} size={20} />
								)}
							</p>
							<p
								onClick={() => setrevealShareBtns(!revealShareBtns)}
								className="share"
							>
								<FaShareAlt color={"#646464"} size={20} />
							</p>
							{revealShareBtns && (
								<div className="share-icon-btn">
									<ShareBtns
										setrevealShareBtns={setrevealShareBtns}
										title={name}
										link={`https://staging.quickmechs.com/home/mech-office/${mechanicShopId}/-/${category}/${sub_category}/`}
										// description={'info.description'}
									/>
								</div>
							)}
						</div>
					</div>

					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<div>
							<p style={_x().header}>{workschedule.timerange}</p>
							<p style={_x().sub_header}>{noHours} hours (Working Hours)</p>
						</div>

						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						></div>
					</div>

					<div style={{ display: "flex", marginBottom: 20, flexWrap: "wrap" }}>
						{!instant && (
							<div
								style={{
									background: "#31AA52",
									paddingInline: 10,
									borderRadius: 35,
									height: 25,
									display: "grid",
									placeItems: "center",
									color: "white",
									marginRight: 30,
									marginBottom: 10,
								}}
							>
								Instant Service Available
							</div>
						)}

						{!mobile && (
							<div
								style={{
									background: "#FBB500",
									paddingInline: 10,
									borderRadius: 35,
									height: 25,
									display: "grid",
									placeItems: "center",
									color: "white",
								}}
							>
								Mobile Service Available
							</div>
						)}
					</div>
				</div>
			</div>

			<div style={_x(_mobile).cat_body}>
				<div>
					<p style={_x().header}>Contact Information</p>

					<div>
						<span style={_x().header2}>Shop Address :</span>
						<span style={{ marginLeft: 5 }} />
						<span style={_x().sub_header}>{address}</span>
					</div>

					<Spacing vertical={5} />

					<div style={{ display: "flex" }}>
						<div style={_x().sub_header}>{city}</div>

						<div style={{ ..._x().sub_header, marginLeft: 5 }}>{state}.</div>
					</div>

					<Spacing vertical={10} />

					<div style={_x().top_cat}>
						<div style={_x().header2}>Phone Number :</div>

						<Spacing horizontal={5} />

						{showNumber ? (
							<div style={_x().sub_header}>{phoneNumber}</div>
						) : (
							<div style={_x().num_cover} onClick={removeNumberCover}>
								Show
							</div>
						)}
					</div>

					<Spacing vertical={10} />

					<div style={_x().top_cat}>
						<div style={_x().header2}>Email Address :</div>
						<Spacing horizontal={5} />
						<div style={_x().sub_header}>{email}</div>
					</div>
				</div>

				<div
					style={{ width: _mobile ? "100%" : 250, marginTop: _mobile ? 20 : 0 }}
				>
					<div style={{ height: 200 }}>
						<MyMapComponent isMarkerShown labelVisible />
					</div>

					<div style={_x().map_btn}>View in Map</div>
				</div>
			</div>

			<Hr thickness={0.5} />

			<div style={_x(_mobile).row2}>
				<div>
					<div style={{ height: 50 }}>
						<p style={_x().header}>Book Appointment</p>
						{/* <p style={_x().sub_header}>
							You can shedule up to 14 days from today.
						</p> */}
					</div>

					<Calendar
						className="calendar"
						minDate={new Date()}
						minDetail="month"
						onChange={(val, e) => onCalendarChange(val, e)}
						tileDisabled={({ date, view }) =>
							!workingHours.hasOwnProperty(getWeekDay(date.getDay()))
						}
					/>
				</div>

				<div style={_x(_mobile).row2_right}>
					<div>
						<p style={_x().header}>Pick a time</p>
						{selected.date === "" ? (
							<p style={_x().sub_header}>...</p>
						) : (
							<p style={_x().sub_header}>
								{Object.keys(weekmap).find(
									(key) => weekmap[key] === calendar.weekday
								)}
								, {selected.date}
							</p>
						)}
					</div>

					{workingHours.hasOwnProperty(calendar.weekday) ? (
						<>
							<AvailableSlots
								onInit={onAvailabilityComponentInit}
								selectedWeekday={calendar.weekday}
								selectedDate={calendar.selectedDate}
								workhours={workingHours}
								slotSelected={slotSelected}
								schedule={ultimaschedule}
								concatenateDate={concatenateDate}
								date={selected.fulldate}
								newlybooked={newlybooked}
							/>

							<Spacing />

							<button style={_x().book} onClick={bookAppointment}>
								Book Appointment
							</button>
						</>
					) : (
						<div
							style={{
								fontWeight: "bold",
								fontSize: 20,
								marginTop: 20,
								lineHeight: 1.5,
								height: 200,
								display: "grid",
								placeItems: "center",
							}}
						>
							Select a date
						</div>
					)}
				</div>
			</div>

			<Spinner isloading={isloading} />

			<Popup
				isOpen={popup.isOpen}
				closePressed={() => {
					setpopup({ ...popup, isOpen: false });
				}}
				continuePressed={() => {
					setcalendar({ ...calendar });
				}}
				popupMessage={popup.message}
				headerText={popup.headerText}
				style={{ width: 450 }}
			/>

			<Modal open={loginpopup}>
				<LoginPopUp
					onLogin={() => {
						setloginpopup(false);
						setshowNumber(true);
						fetchSchedules();
					}}
				>
					<p style={{ cursor: "pointer" }} onClick={() => setloginpopup(false)}>
						x
					</p>
				</LoginPopUp>
			</Modal>

			<Hr thickness={0.5} />
		</>
	);
}

export default MechOfficeTopside;

const _x = (_mobile) => ({
	header: {
		fontSize: 16,
		color: "var(--secondary1)",
		fontWeight: 500,
	},

	header2: {
		fontSize: 14,
		color: "var(--secondary1)",
		fontWeight: 500,
	},

	sub_header: {
		fontSize: 14,
		fontWeight: 400,
		color: "var(--secondary1)",
	},

	chip: {
		border: "1px solid lightgrey",
		padding: 5,
		paddingRight: 18,
		paddingLeft: 18,
		maxWidth: 100,
		borderRadius: 9,
		textAlign: "center",
		marginBottom: 10,
		marginRight: 10,
		cursor: "pointer",
	},

	num_cover: {
		height: 30,
		width: 120,
		display: "grid",
		placeItems: "center",
		borderRadius: 4,
		cursor: "pointer",

		color: "var(--primary1)",
		fontWeight: 600,
		borderRadius: 45,
		border: "1px solid var(--primary1)",
	},

	cat_body: {
		display: "grid",
		gridTemplateColumns: _mobile ? null : "1fr auto",
		gridTemplateRows: _mobile ? "1fr auto" : null,
		gridGap: 10,
	},

	top_cat: {
		display: "flex",
		alignItems: "center",
		// width: 400,
	},

	map: {
		height: 167,
		objectFit: "cover",
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		marginTop: "1rem",
	},
	map_btn: {
		background: "white",
		display: "grid",
		placeItems: "center",
		height: 30,
		color: "var(--primary1)",
		fontSize: 12,
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		width: "100%",
		cursor: "pointer",
	},

	row2: {
		display: "grid",
		gridTemplateColumns: _mobile ? null : "1fr 1fr",
		gridTemplateRows: _mobile ? "1fr 1fr" : null,
		gridGap: 30,
	},

	row2_right: {
		marginLeft: _mobile ? 0 : 40,
	},

	book: {
		background: "var(--primary1)",
		color: "white",
		height: 38,
		width: 174,
		borderRadius: 8,
	},
});
