import React, { Fragment, useState } from "react";
import "./confirm.scss";
import { Button } from "@material-ui/core";
import StripPaymentPage from "./StripPaymentPage";
import { Elements } from "@stripe/react-stripe-js";

export default function ConfirmPage() {
  const [open, setOpen] = React.useState(false);
  return (
    // <Elements stripe={stripePromise} options={options}>
    <Fragment>
      <div className="confirm-job-wrapper">
        <div className="confirm-inner-wrapper">
          <div className="row-item-confirm-wrap">
            <span className="left-title-txt">Confirm Purchase</span>
            <div className="left-card">
              <img
                src={
                  require("../../../Resources/Icons/portfolio-icon.png").default
                }
              />
              <div className="left-detail-section">
                <label className="title-left-card">Expires Jul 6, 2023</label>
                <label className="card-small-txt">Mechanic</label>
                <label className="card-small-txt">30062, USA</label>
              </div>
            </div>
            <div className="wrapper-payment-method-req">
              <label className="pay-method-txt">
                {/* Payment Method (Required) */}
              </label>
              {/* <label className="add-txt">Add</label> */}
            </div>
          </div>
          <div className="row-item-confirm-wrap">
            {/* Right pane */}
            <div className="right-row-txt-container">
              <div className="row-text">
                <label className="row-txt">Job listing price:</label>
                <label className="row-txt">$25.00</label>
              </div>
              {/* job-row-2 */}
              <div className="row-text">
                <label className="row-txt">Sale tax:</label>
                <label className="row-txt">$0.00</label>
              </div>
              {/* job-total-2 */}
              <div className="row-text">
                <label className="row-total-txt">Total:</label>
                <label className="row-total-txt">$25.00</label>
              </div>
            </div>
            <div className="confirm-note-txt-wrap">
              <input type="checkbox" className="confirm-check-box" />
              <span className="note-txt">
                By clicking Confirm Purchase you agree to the{" "}
                <label className="terms-color">
                  Quickmechs Terms and Conditions
                </label>
                and acknowledge the Quickmechs
                <label className="terms-color">Privacy Policy</label>
              </span>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                style={{
                  background: "#587FC0",
                  color: "#FFFFFF",
                  width: "100%",
                  borderRadius: 6,
                }}
                variant="contained"
                className="next-button"
              >
                {/* Confirm Purchase */}
                Checkout
              </Button>
            </div>
          </div>
        </div>
      </div>

      <StripPaymentPage open={open} close={setOpen} />
    </Fragment>
    // </Elements>
  );
}
