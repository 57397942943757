import { Button, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Spacing } from '../../../Components/Style/Styles';
import axios from 'axios';
import './forgot.css';
import close2 from '../../../Resources/Icons/close2.png';
import show from '../../../Resources/Icons/show.svg';
import hide from '../../../Resources/Icons/hide.svg';
import { Helmet } from 'react-helmet';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

function UpdatePassword(props) {
  const myInput = localStorage.getItem('email');

  const [email, setEmail] = useState(myInput);
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');

  const [password_confirmation, setPassword_confirmation] = useState('');
  const [message, setMessage] = useState('');
  const [format, setFormat] = useState('');
  const [verified, setVerified] = useState(false);
  const [showpassword, setshowpassword] = useState(false);
  const [showpassword1, setshowpassword1] = useState(false);
  const [notice, setNotice] = useState('');

  // useEffect(()=> {
  // 	const { history } = props;

  // 	window.addEventListener("popstate", () => {
  //     history.go(1);
  // });
  // }, []);

  function togglePassword() {
    setshowpassword(!showpassword);
  }

  function togglePassword1() {
    setshowpassword1(!showpassword1);
  }

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setToken(email);
  };

  const onChangeToken = (e) => {
    const token = e.target.value;
    setToken(token);
  };

  const onChangePassword = (e) => {
    e.preventDefault();
    const password = e.target.value;
    setPassword(password);
    if (password !== password_confirmation) {
      setNotice('Password do not match');
    } else {
      setNotice('');
    }
  };

  const onChangePassword2 = (e) => {
    e.preventDefault();
    const password_confirmation = e.target.value;
    setPassword_confirmation(password_confirmation);
    if (password !== password_confirmation) {
      setNotice('Password do not match');
    } else {
      setNotice('');
    }
  };

  function closeModal() {
    setVerified(false);
  }

  function continueVerification() {
    props.history.push('/auth/login');
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(API_URL + 'users/reset-password', {
        email,
        token,
        password,
        password_confirmation,
      })
      .then((res) => {
        setVerified(true);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);

        setFormat(
          'Password must be 8 or more characters and must contain atleast one number, a capital letter and a symbol'
        );
        setMessage(resMessage);
      });
  };

  return (
    <main style={{ height: '100vh' }}>
      <section
        style={
          {
            // height: "400px",
            // padding: '1rem 0',
          }
        }
      >
        <div className="update-forgot">
          <section className="update-wrapper">
            <div style={_x.header}>Enter a new password to continue</div>

            <Spacing vertical={30} />
            {message && <p className="topbarError">{message}</p>}
            {/* {format && <p className="topbarError">{format}</p>}
            {notice && <p className="topbarError">{notice}</p>} */}

            <Spacing vertical={5} />

            <input
              style={{
                outline: 'none',
                display: 'none',
              }}
              type="text"
              size="small"
              value={myInput}
              onChange={onChangeEmail}
              disabled
              required
            />

            <div style={_x.header2}>Reset Token</div>
            <Spacing vertical={5} />
            <div className="pass_field">
              <input
                style={{
                  outline: 'none',
                  border: 'none',
                }}
                type="text"
                size="small"
                value={token}
                onChange={onChangeToken}
                required
              />
            </div>

            {/* {message && (
              <>
                <div className="alert alert-danger" role="alert">
                  &#9432; {message}
                </div>
              </>
            )} */}

            <Spacing vertical={25} />
            <div style={_x.header2}>New Password</div>
            <Spacing vertical={5} />
            <div className="pass_field">
              <input
                style={{
                  outline: 'none',
                  border: 'none',
                }}
                type={showpassword ? 'text' : 'password'}
                size="small"
                onChange={onChangePassword}
                required
                value={password}
                autoComplete="new-password"
                onPaste={onChangePassword}
                onDrop={onChangePassword}
                onCut={onChangePassword}
                onCopy={onChangePassword}
              />{' '}
              <img
                alt="eye"
                src={showpassword ? hide : show}
                style={_x.eye}
                onClick={togglePassword}
              />{' '}
            </div>
            <Spacing vertical={25} />
            <div style={_x.header2}>Confirm Password</div>
            <Spacing vertical={5} />
            <div className="pass_field">
              <input
                style={{
                  outline: 'none',
                  border: 'none',
                }}
                type={showpassword1 ? 'text' : 'password'}
                size="small"
                onChange={onChangePassword2}
                value={password_confirmation}
                required
                onPaste={onChangePassword2}
                onDrop={onChangePassword2}
                onCut={onChangePassword2}
                onCopy={onChangePassword2}
              />{' '}
              <img
                alt="eye"
                src={showpassword1 ? hide : show}
                style={_x.eye}
                onClick={togglePassword1}
              />
            </div>
            <div className="alert alert-danger"> {notice}</div>
            {format && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  &#9432; {format}
                </div>
              </div>
            )}

            <Spacing vertical={40} />
            <div style={_x.bottom}>
              <Button style={_x.btn11} type="submit" onClick={handleSubmit}>
                Save
              </Button>{' '}
              <Spacing />
            </div>
          </section>
        </div>
        {verified && (
          <section style={_x.modal_bg}>
            <div
              open={verified === false}
              onClick={closeModal}
              style={{ border: 'none' }}
            >
              <div style={_x.modall}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <img
                    onClick={closeModal}
                    style={_x.close}
                    src={close2}
                    alt="close"
                  />
                </div>

                <p
                  style={{
                    fontFamily: 'montserrat',
                    fontSize: '18px',
                    textAlign: 'center',
                  }}
                >
                  <span style={{ fontWeight: '500', marginBottom: '0px' }}>
                    {' '}
                    Congratulations{' '}
                  </span>
                  <br />
                </p>
                <p
                  style={{
                    fontFamily: 'montserrat',
                    fontSize: '16px',
                    margin: '0px 0 20px',
                    textAlign: 'center',
                  }}
                >
                  Your Password has been successfully reset
                </p>

                <button onClick={continueVerification} style={_x.continue}>
                  Continue
                </button>
              </div>
            </div>
          </section>
        )}
      </section>
      <Helmet>
        <title>{'Update Password - quickmechs Solutions'}</title>
      </Helmet>
    </main>
  );
}

export default withRouter(UpdatePassword);

const _x = {
  forgott: {
    background: 'white',
    margin: '4rem auto',
    padding: 30,
    borderRadius: '8px',
    maxWidth: '1070px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    width: '100%',
  },

  eye: {
    width: '20px',
    marginTop: '7px',
    height: '20px',
    cursor: 'pointer',
    padding: '0 10px 0',
  },

  header: {
    fontWeight: 'bold',
    fontSize: 15,
    width: '100%',
    textAlign: 'center',
  },
  header2: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: '2px',
    color: '#222222',
  },
  bottom: {
    width: '100%',
  },
  btn11: {
    backgroundColor: 'var(--primary1)',
    textTransform: 'none',
    fontWeight: 'bolder',
    fontSize: '1rem',
    border: '1px solid',
    width: '100%',
    fontFamily: 'montserrat',
  },
  btn2: {
    color: 'var(--primary1)',
    fontFamily: 'montserrat',
    paddingTop: '10px',
  },

  modall: {
    background: 'white',
    width: 374,
    // height: 196,
    outline: 'none',
    borderRadius: 6,
    margin: '170px auto',
    display: 'grid',
    placeItems: 'center',
    padding: 20,
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  continue: {
    height: 40,
    width: 137,
    backgroundColor: 'var(--primary1)',
    paddingLeft: 15,
    paddingRight: 15,
    // border: '1px solid',
    color: 'white',
    borderRadius: 6,
    fontWeight: 'bold',
    fontSize: 16,
    // marginBottom: '1.7rem',
  },

  close: {
    cursor: 'pointer',
  },

  modal_bg: {
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00000090',
  },
};
