import { Modal } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import authService from "src/services/auth.service";
import mechService from "src/services/mech.service";
import close2 from "../../../Resources/Icons/close2.png";
import mask from "../../../Resources/Icons/mask.svg";
import sparkle from "../../../Resources/Icons/sparkle.svg";
import spray from "../../../Resources/Icons/spray.svg";
import temperature from "../../../Resources/Icons/temperature.svg";
import { Brim } from "../../../State/Control";
import { Hr, Spacing } from "../../Style/Styles";
import LoginPopUp from "../loginpop/LoginPopUp";

function Sidebar() {
	const [control, setcontrol] = useContext(Brim);
	const [workSchedule, setworkSchedule] = useState([]);
	const [workweekchips, setworkweekchips] = useState([]);
	const [noHours, setnoHours] = useState("");
	const [loginpopup, setloginpopup] = useState(false);
	const [showdetails, setshowdetails] = useState(false);
	const [showNumber, setshowNumber] = useState(false);
	const _mobile = useMediaQuery("(max-width:800px)");

	const {
		// email,
		// time,
		// name,
		// mechanicId,
		// mechanicShopId,

		city,
		shopName,
		state,
		customerReviews,
		phoneNumber,
		description,
		vehicleType,
		address,
		category,
		subCategory,
		mechanicId,
		mechanicShopId,
		email,
		instant,
		mobile,

		//*................................................................
		time,
	} = control.sidebar.values;

	// const history = useHistory();

	useEffect(() => {
		setshowNumber(false);
	}, []);

	useEffect(() => {
		if (control.sidebar.isOpen === true) {
			(async () => {
				const resp = await mechService.getMechanicSchedule(mechanicShopId);
				console.log(`🚀~Sidebar.js:64~resp: ${resp}`);

				if (resp.status === 200) {
					// console.log("success");
					setworkSchedule(resp.data);
				}
			})();
		}
	}, [control.sidebar.isOpen, mechanicShopId]);

	useEffect(() => {
		setworkweekchips(reorganiseWeekdays(workSchedule));
	}, [workSchedule]);

	function reorganiseWeekdays(arr) {
		const weekmap = {
			Sun: "Sunday",
			Mon: "Monday",
			Tue: "Tuesday",
			Wed: "Wednesday",
			Thu: "Thursday",
			Fri: "Friday",
			Sat: "Saturday",
		};

		let oldArr = [];

		let newArr = [];

		arr.map((e) => oldArr.push(e.day));

		Object.keys(weekmap).map((key, _) => {
			if (oldArr.includes(weekmap[key])) {
				newArr.push(weekmap[key]);
			}

			return "";
		});

		return newArr;
	}

	function closeSidebar() {
		setcontrol({
			...control,
			sidebar: { ...control.sidebar, isOpen: false },
		});
	}

	// function goToMechanicOffice() {
	// 	history.push({
	// 		pathname: `/home/mech-office/${mechanicId}/`,
	// 	});
	// }

	function highlightDay(e) {
		const chip = document.getElementById(e);

		const allChips = document.getElementsByClassName("sidebarchips");

		Array.from(allChips).forEach((each) => {
			each.style.border = "1px solid lightgrey";
			each.style.fontWeight = "normal";
		});

		chip.style.border = "2px solid var(--secondary1)";
		chip.style.fontWeight = "500";

		// console.log(
		// 	e,
		// 	schedule,
		// 	schedule.filter((each) => each.day === e)
		// );

		setnoHours(
			workSchedule.filter((each) => each.day === e)?.[0]?.["hours_available"]
				?.length
		);
	}

	function toggleDetails() {
		setshowdetails(!showdetails);
	}

	function removeNumberCover() {
		if (authService.getCurrentUser("user")) {
			setshowNumber(true);
		} else {
			setloginpopup(true);
		}
	}

	return (
		<>
			<Modal
				onClick={(e) => {
					e.stopPropagation();
					closeSidebar();
				}}
				open={control.sidebar.isOpen}
				style={{ border: "none" }}
				hideBackdrop={true}
			>
				<div
					className="useSlideAnimation"
					style={_x(_mobile).sidebar}
					onClick={(e) => e.stopPropagation()}
				>
					<div>
						<div style={_x().header}>
							<img
								style={{ cursor: "pointer" }}
								src={close2}
								alt="close"
								onClick={closeSidebar}
							/>
						</div>

						<p style={_x().text1}>{shopName}</p>

						<div
							style={{
								display: "flex",
								alignItems: "flex-start",
								flexWrap: "wrap",
							}}
						>
							<span style={{ ..._x().text2, marginTop: 7 }}>
								Availability :
							</span>

							<span style={{ marginRight: 10 }} />

							{workweekchips.map((e) => (
								<span
									id={e}
									className={"sidebarchips"}
									style={_x().chip}
									onMouseOver={() => highlightDay(e)}
								>
									{e}
								</span>
							))}
						</div>

						<Spacing vertical={5} />

						<p style={_x().text1}>{time}</p>

						<p style={_x().text2}>{noHours} hours (Working Hours)</p>

						<Spacing vertical={5} />

						{showdetails && (
							<>
								<div>
									<p style={_x().text8}>{description}</p>
								</div>

								<div style={{ display: "flex", alignItems: "center" }}>
									<div style={{ transform: "rotate(180deg)" }}>
										<StarRatingComponent
											editing={false}
											starCount={5}
											value={customerReviews?.average_overall_rating}
											emptyStarColor={"#c4c4c4"}
										/>
									</div>

									<Spacing horizontal={8} />

									<p style={_x().text8}>
										<span style={{ fontWeight: 600, marginRight: 5 }}>
											{customerReviews?.average_overall_rating}
										</span>
										Excellent
									</p>

									<Spacing horizontal={8} />

									<p style={_x().text8}>
										({customerReviews?.total_rating} Reviews)
									</p>
								</div>
							</>
						)}

						<div
							style={
								{
									//  display: "flex",
									//  alignItems: "center"
								}
							}
						>
							<p style={_x().show_details} onClick={toggleDetails}>
								{showdetails ? "Hide" : "Show"} Details
							</p>

							<div style={{ width: 130 }}>
								{instant && (
									<div
										style={{
											height: 20,
											background: "limegreen",
											borderRadius: 20,
											paddingLeft: 10,
											paddingRight: 10,
											display: "grid",
											placeItems: "center",
											color: "white",
											marginBottom: 10,
										}}
									>
										instant service
									</div>
								)}

								{mobile && (
									<div
										style={{
											paddingInline: 10,
											height: 20,
											background: "#FBB500",
											borderRadius: 20,
											paddingLeft: 10,
											paddingRight: 10,
											display: "grid",
											placeItems: "center",
											color: "white",
										}}
									>
										mobile service
									</div>
								)}
							</div>
						</div>

						<Hr thickness={0.3} />

						<p style={_x().text1}>Contact Information</p>

						<div style={_x().box1}>
							<div style={{ ..._x().flat, alignItems: "flex-start" }}>
								<p>
									<span
										style={{
											..._x().text6,
											whiteSpace: "nowrap",
											marginRight: 5,
										}}
									>
										Shop Address:
									</span>

									<span style={_x().text7}>
										{address},{" " + city},{" " + state}
									</span>
								</p>
							</div>

							<div style={_x().flat}>
								<p style={_x().text6}>Phone Number:</p>

								<Spacing horizontal={5} />

								{showNumber ? (
									<p style={_x().text7}>{phoneNumber}</p>
								) : (
									<div style={_x().num_cover} onClick={removeNumberCover}>
										Show
									</div>
								)}
							</div>

							<div style={_x().flat}>
								<p style={_x().text6}>Email Address:</p>

								<Spacing horizontal={5} />

								<p style={_x().text7}>{email}</p>
							</div>
						</div>

						<p style={_x().text3}>
							Most of our shops may have restrictions in place. including
							self-quarantine, due to COVID-19.
						</p>

						<Spacing />

						<Hr thickness={0.3} />

						<p style={_x().text4}>
							4 cleaning and safety practices for {shopName}.
						</p>

						<div style={_x().flat}>
							<img alt="descript" style={_x().sidebar_icon} src={spray} />
							<Spacing horizontal={5} />
							<p style={_x().text5}>clean with disinfectant</p>
						</div>

						<div style={_x().flat}>
							<img alt="descript" style={_x().sidebar_icon} src={sparkle} />
							<Spacing horizontal={5} />
							<p style={_x().text5}>Cleaned between repairs</p>
						</div>

						<div style={_x().flat}>
							<img alt="descript" style={_x().sidebar_icon} src={mask} />
							<Spacing horizontal={5} />
							<p style={_x().text5}>Mask Required</p>
						</div>

						<div style={_x().flat}>
							<img alt="descript" style={_x().sidebar_icon} src={temperature} />
							<Spacing horizontal={5} />
							<p style={_x().text5}>Temperature Checks</p>
						</div>
					</div>

					<div style={_x().btn_box}>
						<Link
							// to={{
							// 	pathname: `/home/mech-office`,
							// 	state: {
							// 		mechanicId: "464",
							// 		subCategory,
							// 		category,
							// 		vehicleType,
							// 	},
							// }}

							to={`/home/mech-office/${mechanicId ?? "-"}/${
								vehicleType === "" ? "-" : vehicleType
							}/${category ?? "-"}/${subCategory ?? "-"}/`}
							target="_blank"
						>
							<button style={_x().continue}>Book Appointment</button>
						</Link>
					</div>
				</div>
			</Modal>

			<Modal open={loginpopup}>
				<LoginPopUp
					onLogin={() => {
						setloginpopup(false);
						setshowNumber(true);
					}}
					noReload
				>
					<p style={{ cursor: "pointer" }} onClick={() => setloginpopup(false)}>
						x
					</p>
				</LoginPopUp>
			</Modal>
		</>
	);
}

export default Sidebar;

const _x = (_mobile) => ({
	sidebar: {
		height: "100%",
		overflowY: "scroll",
		width: _mobile ? "100%" : 628,
		backgroundColor: "white",
		position: "fixed",
		right: 0,
		top: 0,
		padding: 30,
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		boxShadow: "0px 10px 20px #27272780",
		zIndex: 9999,
		outline: "none",
	},

	header: {
		height: 30,
		borderBottom: "0.5px solid lightgrey",
	},

	text1: {
		fontWeight: 500,
		color: "var(--secondary1)",
		fontSize: 14,
	},

	sidebar_icon: {
		width: 20,
		color: "blue",
	},

	chip: {
		border: "1px solid lightgrey",
		padding: 5,
		paddingRight: 18,
		paddingLeft: 18,
		maxWidth: 100,
		borderRadius: 15,
		textAlign: "center",
		marginBottom: 10,
		marginRight: 10,
		cursor: "pointer",
	},

	text2: {
		color: "var(--secondary1)",
		fontSize: 12,
		fontWeight: 500,
	},

	contact: {
		fontWeight: "bold",
		color: "var(--secondary1)",
		fontSize: 14,
	},

	show_details: {
		fontSize: 12,
		color: "var(--primary1)",
		cursor: "pointer",
	},

	num_cover: {
		height: 30,
		background: "white",
		width: 120,
		color: "var(--primary1)",
		display: "grid",
		placeItems: "center",
		cursor: "pointer",
		fontWeight: 600,
		border: "1px solid var(--primary1)",
		borderRadius: 45,
	},

	box1: {
		border: "0.5px solid lightslategrey",
		// width: 334,
		// height: 129,
		borderRadius: 6,
		padding: 10,
	},

	text3: {
		fontSize: 10,
		color: "var(--secondary1)",
		fontWeight: 500,
	},

	text4: {
		fontSize: 13,
		color: "var(--secondary1)",
		fontWeight: 500,
	},

	flat: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	text5: {
		fontSize: 12,
		color: "var(--secondary1)",
		fontWeight: 500,
	},

	text6: {
		fontWeight: "bold",
		fontSize: 11,
		color: "var(--secondary1)",
	},

	text7: {
		fontSize: 11,
		color: "var(--secondary1)",
		fontWeight: 500,
	},

	text8: {
		fontSize: 13,
		color: "#646464",
		fontWeight: 500,
		lineHeight: 1.8,
	},

	btn_box: {},

	continue: {
		background: "var(--primary1)",
		color: "white",
		fontWeight: "bold",
		// fontSize: 18,
		borderRadius: 6,
		height: 40,
		width: 174,
	},
});
