/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, useMediaQuery } from "@material-ui/core";
import { Fragment, useContext, useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import { IoClose, IoCopyOutline, IoMenuOutline } from "react-icons/io5";
import { Link, useHistory, useLocation } from "react-router-dom";
import ProfileDP from "src/Components/Functional/profileImage/ProfileDP";
import icon from "../../../Resources/Logo/icon.png";
import logo from "../../../Resources/Logo/main_logo.svg";
import logo_small from "../../../Resources/Logo/main_logo_small.png";
import { Brim } from "../../../State/Control";
import AuthService from "../../../services/auth.service";
import { Spacing } from "../../Style/Styles";
import SwitchToAppButton from "../SwitchToApp";
import LoginPopUp from "../loginpop/LoginPopUp";
import "./header.css";

function Header() {
  const currentUser = AuthService.getCurrentUser("user");
  const [modalOpen, setModalOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [menu, setMenu] = useState(false);
  const [regular, setRegular] = useState(false);
  const [data, setData] = useState([]);
  const [control] = useContext(Brim);
  const location = useLocation();
  const [copied, setCopied] = useState(false);
  const [code, setCode] = useState("");
  const [appNotice, setappNotice] = useState(true);
  const history = useHistory();
  const _mobile = useMediaQuery("(max-width:800px)");
  const [isMech, setIsMech] = useState(false);
  const [isDealer, setisDealer] = useState(false);
  const [loginpopup, setloginpopup] = useState(false);

  const logout = () => {
    // signOut();
    AuthService.logout("user");
    history.push("/auth/login");
  };

  const checkUserType = () => {
    if (currentUser) {
      const userType = currentUser.data?.user_info?.permissions.map((role) => {
        return role;
      });

      const dealer = userType?.includes("part_dealer");
      const mech = userType?.includes("mechanic");
      if (mech) setIsMech(true);
      if (dealer) setisDealer(true);

      if (dealer || mech) {
        setRegular(true);
      } else {
        setRegular(false);
      }
    } else {
      setRegular(false);
    }
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const appUrl = isIOS
    ? "https://apps.apple.com/ng/app/quickmechs/id6443885659"
    : "https://play.google.com/store/apps/details?id=com.quickmechs";
  const schemeUrl = "Quickmechs://";

  const handleButtonClick = () => {
    window.location.href = appUrl;

    setTimeout(() => {
      window.location.href = schemeUrl;
    }, 1000);
  };

  async function grabLoggedInUser() {
    const resp = await AuthService.getCurrentLoggedInUser();
    if (resp.success === true) {
      const userType = resp.data?.user_info?.permissions?.map((role) => {
        return role;
      });
      const rcode = resp.data?.user_info?.referral_code;
      setCode(rcode);
      setData(userType);
    } else {
      // console.log("none")
    }
  }

  const dealer = data?.includes("part_dealer");
  const mech = data?.includes("mechanic");

  const checkRoleType = () => {
    if (mech === true) {
      let user_id = `${currentUser.data?.user_info?.id}`;
      history.push(`/auth/edit/${user_id}/mechanic/form`);
    } else if (mech !== true) {
      let user_id = `${currentUser.data?.user_info?.id}`;
      history.push(`/auth/dashboard/${user_id}/mechanic/form`);
    }
  };

  useEffect(() => {
    if (!isAuth) {
      document.getElementById("mechanicAndServices").style.color =
        "var(--accent1)";
    }
    checkUserType();
    grabLoggedInUser();
  }, [location]);

  function headerBtnClicked(e) {
    var children = document.getElementsByClassName("header-btn");

    Array.from(children).forEach((btn) => {
      btn.style.color = "var(--secondary1)";
    });

    const _btnx = document.getElementById(e.target.id);

    // console.log(_btnx);

    if (_btnx) {
      _btnx.style.color = "var(--accent1)";
      // _btnx.style.border = "1px solid var(--primary1)";
    }

    if (e.target.id === "registerashop") {
      regularUserAdvert();
    }
  }

  function openSiginOption() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  function isAuth() {
    return location.pathname === "/auth/login" ||
      location.pathname === "/auth/register"
      ? true
      : false;
  }

  const regularUserAdvertategory = () => {
    if (currentUser) {
      let user_id = `${currentUser.data?.user_info?.id}`;
      let firstname = `${currentUser.data?.user_info?.first_name}`;
      history.push(`/home/${user_id}/${firstname}=/usercategory`);
    } else if (!currentUser) {
      history.push(`/auth/login`);
    }
  };

  const regularUserAdvert = () => {
    if (currentUser) {
      let user_id = `${currentUser.data?.user_info?.id}`;
      let firstname = `${currentUser.data?.user_info?.first_name}`;
      history.push(`/home/dashboard`);
      setMenu(false);
    } else if (!currentUser) {
      history.push(`/auth/login`);
      setMenu(false);
    }
  };

  const loadProfile = () => {
    if (currentUser) {
      let user_id = `${currentUser.data?.user_info?.id}`;
      let firstname = `${currentUser.data?.user_info?.first_name}`;
      history.push(`/home/profile-id/${user_id}/`);
    } else if (!currentUser) {
      history.push(`/auth/login`);
    }
  };

  const _y = {
    mainHeader: {
      border: control.step1 ? "0.1px solid rgb(215, 219, 219)" : "none",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px !important",
    },
    step2: {
      borderBottom: control.step2 ? "0.1px solid rgb(215, 219, 219)" : "",
    },
  };

  const clientId =
    "582686540627-ef47p19lrie8c74jv55oa39289e6fu6e.apps.googleusercontent.com";

  const onFailure = (res) => {
    console.log("failed:", res);
  };

  const onLogoutSuccess = () => {
    localStorage.clear();
    history.push("/auth/login");
    window.location.reload();
  };

  useEffect(() => {
    const data = window.localStorage.getItem("MY_APP_STATE");
    if (data !== null) setappNotice(JSON.parse(data));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("MY_APP_STATE", JSON.stringify(appNotice));
  }, [appNotice]);

  return (
    <main className="main_head_wrapper">
      <div className="switch-top">
        <div className="switch-top-side">
          <div className="switch-icon">
            <img src={icon} alt="site icon" />
          </div>

          <div className="switch-top-text">
            <h5>Quickmechs: Vehicle Repairs & Spare Parts</h5>
            <p>Open in the Quickmecks app</p>
          </div>
        </div>

        <button onClick={handleButtonClick}>Open</button>
      </div>

      {appNotice && (
        <div className="switch-to-app">
          <div className="switch-body">
            <p className="switch-close" onClick={() => setappNotice(false)}>
              <IoClose size={20} />
            </p>
            <div className="switch-icon2">
              <img src={icon} alt="site icon" />
            </div>
            <p className="content22">
              Get Best deals on vehicle repairs <br />
              and purchase on vehicle spare <br /> parts on Quickmechs
            </p>
            <SwitchToAppButton />
          </div>
        </div>
      )}

      {!isAuth ? (
        // <div
        // 	style={{
        // 		..._x.header2_alt,
        // 	}}
        // 	className="header"
        // >
        <div className="logo">
          <img src={_mobile ? logo_small : logo} alt="logo" />
        </div>
      ) : (
        // </div>
        <div className="header">
          <div className="hamburger_head">
            <div className="user_head_col">
              {location.pathname === "/home/m-s-querylist/" ? (
                <FiArrowLeft color="#587ebf" onClick={() => history.goBack()} />
              ) : (
                <Fragment>
                  <IoMenuOutline
                    color="#587ebf"
                    onClick={() => setMenu(!menu)}
                  />
                  <img
                    alt=""
                    style={{ marginLeft: "1rem" }}
                    src={
                      require("../../../Resources/Icons/Quickmech-Logo-.png")
                        .default
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push("/home/mechanic-and-services/");
                    }}
                  />
                </Fragment>
              )}
            </div>
          </div>

          <Link
            to="/home/mechanic-and-services/"
            style={{ textDecoration: "none" }}
          >
            {/* peter  */}
            {_mobile ? null : (
              <div className="logo">
                <img src={logo} alt="logo" />
              </div>
            )}
          </Link>

          <div className="toolbar">
            <Link to="/home/services/" style={{ textDecoration: "none" }}>
              <button
                id="mechanicAndServices"
                onClick={headerBtnClicked}
                className="header-btn"
                style={{ width: 9 }}
              >
                Services
              </button>
            </Link>

            <Link to="/home/contact-us" style={{ textDecoration: "none" }}>
              <button
                id="contactus"
                onClick={headerBtnClicked}
                className="header-btn"
              >
                Help
              </button>
            </Link>

            <a
              href="https://blog.quickmechs.com/"
              target="_blank"
              style={{
                textDecoration: "none",
              }}
              rel="noreferrer"
            >
              <button
                style={{
                  // background: "red",
                  paddingLeft: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  paddingRight: 0,
                }}
                id="blog"
                className="header-btn"
              >
                Blog
              </button>
            </a>

            {currentUser && (
              <button
                id="registerashop"
                onClick={headerBtnClicked}
                className="header-btn"
              >
                Dashboard
              </button>
            )}

            <Link
              to="/home/jobs/"
              onClick={(e) => {
                if (!currentUser) {
                  setloginpopup(true);
                  console.log(currentUser);
                }
                setMenu(false);
              }}
              style={{ textDecoration: "none", color: "#587FC0" }}
            >
              <button
                onClick={headerBtnClicked}
                className="header-btn"
                style={{ marginLeft: 0 }}
              >
                Job
              </button>
            </Link>

            <button
              id="sellerregistration"
              onClick={(e) => {
                headerBtnClicked(e);
                if (currentUser) {
                  history.push(
                    `/auth/create/${currentUser?.data?.user_info?.id}/mechanic/form`
                  );
                } else {
                  setloginpopup(true);
                }
              }}
              style={_x.seller_btn}
            >
              Post
            </button>

            <button
              id="signin"
              onClick={openSiginOption}
              className="header-btn"
            >
              {currentUser ? (
                <div style={_x.first_rt}>
                  <div className="user-profile-image">
                    <ProfileDP
                      style={_x.user_prof}
                      size={{ fontSize: "1.1rem" }}
                    />
                  </div>
                  {currentUser.data?.user_info?.first_name} &nbsp;
                </div>
              ) : (
                <div id="signin" style={_x.log_btn}>
                  <FaUserCircle id="signin" style={_x.facircle} />
                  Sign in &nbsp;
                </div>
              )}
            </button>

            <Modal
              open={searchOpen}
              onClick={() => setSearchOpen(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ border: "none" }}
              hideBackdrop={false}
              className="search-mod"
            >
              <div className="searchDrop">
                <p className="search_title">Services</p>
                <ul className="search_head_wrapper">
                  <li className="search_head_item">Mechanic</li>
                  <li className="search_head_item">Spare Parts</li>
                  <li className="search_head_item"> Body Shop</li>
                  <li className="search_head_item">Car Ac</li>
                  <li className="search_head_item">Electrician</li>
                  <li className="search_head_item">Tire Shop</li>
                  <li className="search_head_item">Towing</li>
                  <li className="search_head_item">Security</li>
                </ul>
              </div>
            </Modal>

            <Modal
              open={searchOpen}
              onClick={() => setSearchOpen(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ border: "none" }}
              hideBackdrop={false}
              className="search-mod"
            >
              <div className="searchDrop">
                <p className="search_title">Services</p>
                <ul className="search_head_wrapper">
                  <li className="search_head_item">Mechanic</li>
                  <li className="search_head_item">Spare Parts</li>
                  <li className="search_head_item"> Body Work</li>
                  <li className="search_head_item">Car Ac</li>
                  <li className="search_head_item">Electrician</li>
                  <li className="search_head_item">Tire Shop</li>
                  <li className="search_head_item">Towing</li>
                  <li className="search_head_item">Security</li>
                </ul>
              </div>
            </Modal>

            <Modal
              open={modalOpen}
              onClick={closeModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ border: "none" }}
              hideBackdrop={false}
            >
              <div
                className="head_modal"
                style={{ marginTop: appNotice && _mobile && "425px" }}
              >
                <div style={_x.row1}>
                  {currentUser ? (
                    <>
                      {/* <div className="user-modal-image">
												<ProfileDP
													style={{ width: "60px", height: "60px" }}
													size={{ fontSize: "2.1rem" }}
												/>
											</div> */}

                      <p style={_x.first_name_x}>
                        Hi, {currentUser.data?.user_info?.first_name}
                      </p>
                    </>
                  ) : (
                    <div
                      style={{
                        fontWeight: "500",
                        padding: "30px 0 0",
                        fontSize: "14px",
                        color: "#081438",
                      }}
                    >
                      You could be getting lower prices
                    </div>
                  )}

                  <Spacing />

                  {currentUser ? (
                    <>
                      <p style={_x.current_user_x}>
                        {currentUser.data?.user_info?.email}

                        <p
                          style={{
                            margin: "5px 10px 0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              currentUser.data?.user_info?.email
                            );
                            setCopied(true);
                            setTimeout(function () {
                              setCopied(false);
                            }, 3000);
                          }}
                        >
                          <IoCopyOutline />
                        </p>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          marginTop: "5px",
                          marginBottom: "0",
                          padding: "0",
                        }}
                        className="edit-btn"
                      >
                        <p
                          style={{
                            fontWeight: "500",
                            fontSize: "12px",
                            margin: "0",
                          }}
                        >
                          Referral Code:
                        </p>
                        <p style={{ fontSize: "12px", margin: "0" }}>
                          &nbsp; {code}
                        </p>
                        &nbsp; &nbsp;
                        <p
                          style={{ margin: "5px 0 0", cursor: "pointer" }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              currentUser.data?.user_info?.referral_code
                            );
                            setCopied(true);
                            setTimeout(function () {
                              setCopied(false);
                            }, 3000);
                          }}
                        >
                          <IoCopyOutline />
                        </p>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        fontWeight: "300",
                        paddingTop: "0",
                        fontSize: "11px",
                        color: "#081438",
                      }}
                    >
                      Get 10% or more off repairs for members
                    </div>
                  )}

                  <Spacing vertical={30} />

                  <Link
                    to="/auth/login"
                    style={{ textDecoration: "none", width: "90%" }}
                  >
                    {currentUser ? null : (
                      <Button style={_x.btn}>Sign In</Button>
                    )}
                  </Link>

                  {/* <Spacing /> */}

                  <Link to="/auth/register" style={_x.reg_btn}>
                    {currentUser ? null : (
                      <button style={_x.btn1}>Sign Up, it’s free</button>
                    )}
                  </Link>
                </div>

                <div
                  style={
                    currentUser
                      ? { paddingBottom: "0px" }
                      : { paddingBottom: "20px" }
                  }
                >
                  {currentUser && (
                    <p style={_x.account} onClick={loadProfile}>
                      Account
                    </p>
                  )}

                  <Spacing />

                  {currentUser && (
                    <p onClick={checkRoleType} style={_x.feedback_btn}>
                      Post
                    </p>
                  )}

                  {currentUser && <Spacing />}

                  {currentUser ? (
                    <Link to="/home/contact-us" style={_x.feedback_btn}>
                      Customer Support
                    </Link>
                  ) : (
                    <Link to="/home/contact-us" style={_x.cust_support_btn}>
                      Contact Us
                    </Link>
                  )}

                  <Spacing />

                  {currentUser ? (
                    <Link to="/home/satisfaction" style={_x.feedback_btn}>
                      Feedback
                    </Link>
                  ) : (
                    <Link to="/home/about-us" style={_x.cust_support_btn}>
                      About quickmechs
                    </Link>
                  )}

                  <Spacing />
                  {currentUser && (
                    <Link to="/home/saveditems/mech" style={_x.feedback_btn}>
                      Saved Items (Services Provider)
                    </Link>
                  )}
                  <Spacing />
                  {currentUser && (
                    <Link to="/home/saveditems/parts" style={_x.feedback_btn}>
                      Saved Items (Spare Parts)
                    </Link>
                  )}
                  <Spacing />

                  <hr style={{ backgroundColor: "#c4c4c460" }} />

                  {currentUser ? (
                    <button
                      onClick={logout}
                      className="social-btn "
                      style={_x.logout_btn}
                    >
                      Sign out
                    </button>
                  ) : (
                    <Link to="/home/satisfaction" style={_x.cust_support_btn}>
                      Feedback
                    </Link>
                  )}
                </div>
              </div>
            </Modal>

            <Modal open={loginpopup}>
              <LoginPopUp
                onLogin={() => {
                  setloginpopup(false);
                  history.push(
                    `/auth/${isMech ? "edit" : "create"}/${
                      currentUser?.data?.user_info?.id
                    }/mechanic/form`
                  );
                }}
              >
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => setloginpopup(false)}
                >
                  x
                </p>
              </LoginPopUp>
            </Modal>
          </div>

          <div
            onClick={headerBtnClicked}
            className="user_head_col main_head_icon"
            style={{
              display: _mobile ? "flex" : "none",
            }}
          >
            <button
              style={{
                height: 25,
                width: 35,
                border: "1px solid #587FC0",
                display: "grid",
                placeItems: "center",
                color: "#587FC0",
                borderRadius: 5,
                marginRight: 20,
                padding: 0,
              }}
              onClick={(e) => {
                headerBtnClicked(e);
                if (currentUser) {
                  history.push("/home/jobs/");
                } else {
                  setloginpopup(true);
                }
              }}
            >
              Job
            </button>

            <button
              style={{
                height: 25,
                width: 25,
                border: "1px solid var(--primary1)",
                display: "grid",
                placeItems: "center",
                borderRadius: 5,
                marginRight: 20,
                padding: 0,
              }}
              onClick={(e) => {
                headerBtnClicked(e);
                // history.push('/home/seller-registration/');
                // history.push(
                // 	`/auth/${isMech ? "edit" : "create"}/${
                // 		currentUser?.data?.user_info?.id
                // 	}/mechanic/form`
                // );

                if (currentUser) {
                  history.push(
                    // `/auth/${isMech ? "edit" : "create"}/${
                    `/auth/create/${currentUser?.data?.user_info?.id}/mechanic/form`
                  );
                } else {
                  setloginpopup(true);
                }
              }}
            >
              <BiPlus color="var(--primary1)" size={20} />
            </button>

            {currentUser ? (
              <div onClick={() => setModalOpen(!modalOpen)}>
                <ProfileDP
                  style={{
                    width: "22px",
                    height: "22px",
                    marginBottom: "0.4rem",
                  }}
                  size={{ fontSize: "1.1rem" }}
                />
              </div>
            ) : (
              <FaUserCircle
                color="#587FC0"
                onClick={() => setModalOpen(!modalOpen)}
              />
            )}
          </div>

          {menu && (
            <>
              <div
                onClick={() => setMenu(false)}
                className="menu_mob_wrapper"
              />

              <ul
                className="mobile_menu"
                style={{ marginTop: appNotice && "335px" }}
              >
                <Link to="/home/services/" onClick={() => setMenu(false)}>
                  <li className="menu_mobile">Services</li>
                </Link>

                <a
                  href="https://blog.quickmechs.com/"
                  target="_blank"
                  onClick={() => setMenu(false)}
                  rel="noreferrer"
                >
                  {/* <li className="menu_mobile">Blog</li> */}
                </a>

                <Link to="/home/contact-us" onClick={() => setMenu(false)}>
                  <li className="menu_mobile">Help</li>
                </Link>

                {currentUser && (
                  <li onClick={regularUserAdvert} className="menu_mobile">
                    Dashboard
                  </li>
                )}

                <Link
                  to="/home/seller-registration/"
                  onClick={() => setMenu(false)}
                >
                  <li className="menu_mobile-list-btn">List your Shop/Sell</li>
                </Link>
              </ul>
            </>
          )}
        </div>
      )}
    </main>
  );
}

export default Header;

const _x = {
  account: {
    borderTop: "0.5px solid #c4c4c460",
    paddingTop: "20px",
    marginTop: "-20px",
    marginBottom: "-5px",
    textDecoration: "none",
    color: "#01195A",
    cursor: "pointer",
    fontWeight: "500",
    paddingLeft: 20,
  },

  seller_btn: {
    padding: 5,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 20,
    fontWeight: "bold",
    fontSize: 14,
    marginLeft: 10,
    color: "#587FC0",
    border: "1px solid var(--primary1)",
  },

  first_rt: {
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
    color: "#000",
  },

  user_prof: {
    width: "30px",
    height: "30px",
    color: "#587FC0",
  },

  log_btn: {
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
    color: "var(--primary1)",
  },

  facircle: {
    color: "var(--primary1)",
    fontSize: "1.5rem",
    marginRight: "5px",
  },

  first_name_x: {
    fontWeight: "600",
    fontSize: "18px",
    marginBottom: "0",
    textTransform: "capitalize",
  },

  current_user_x: {
    fontSize: "14px",
    marginTop: "-50px",
    marginBottom: "-40px",
    display: "flex",
    alignItems: "center",
  },

  logout_btn: {
    border: "none",
    padding: "0",
    fontWeight: "500",
    paddingLeft: 20,
    color: "#01195A",
  },

  reg_btn: {
    textDecoration: "none",
    width: "100%",
    display: "grid",
    placeItems: "center",
  },

  feedback_btn: {
    textDecoration: "none",
    color: "#01195A",
    fontWeight: "500",
    paddingLeft: 20,
    cursor: "pointer",
    margin: "0",
  },

  cust_support_btn: {
    textDecoration: "none",
    color: "#01195A",
    fontWeight: "400",
    paddingLeft: 20,
  },

  header2: {
    marginLeft: 100,
    background: "white",
    width: 80,
    display: "grid",
    placeItems: "center",
  },

  header2_alt: {
    paddingLeft: 100,
    background: "white",
    // display: "grid",
    // placeItems: "center",
  },

  row1: {
    display: "grid",
    placeItems: "center",
  },
  btn: {
    textTransform: "none",
    backgroundColor: "var(--primary1)",
    width: "100%",
    color: "white",
    fontFamily: "montserrat",
    fontWeight: "bold",
  },

  btn1: {
    color: "var(--primary1)",
    fontWeight: "bold",
    fontSize: "15px",
    fontFamily: "montserrat",
  },

  step2: {
    height: 72,
    background: "white",
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingRight: 250,
    paddingLeft: 250,
  },

  step2_txt: {
    fontSize: 12,
    marginRight: 30,
  },

  btn2: {
    height: 32,
    width: 128,
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
    background: "var(--primary1)",
    borderRadius: 6,
  },

  step3_row1: {
    display: "flex",
    background: "white",
    width: "100%",
    alignItems: "center",
    paddingRight: 250,
    paddingLeft: 250,
  },

  step3_txt: {
    color: "var(--primary1)",
    fontSize: 12,
    marginRight: 30,
  },

  step3_row2: {
    display: "flex",
    background: "white",
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingRight: 150,
    paddingLeft: 250,
    paddingBottom: 30,
  },

  step3_header: {
    fontSize: 14,
    color: "var(--secondary1)",
    marginBottom: 5,
  },

  input: {
    border: "1px solid var(--secondary5)",
    fontSize: 15,
    width: 361,
    height: 32,
    borderRadius: 6,
  },
};
