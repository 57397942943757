/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import { Spacing } from "src/Components/Style/Styles";

export default function ShopSection({
	title,
	form,
	_mobile,
	updateTextField,
	simpleValidator,
	selectDropDown,
	setform,
	sectionIndex,
	locationErr,
	setlocationErr,
}) {
	// console.log(form.office_address.length, locationErr);

	useEffect(() => {
		if (sectionIndex === 4 && form.office_address.length < 1) {
			setlocationErr(true);
		} else {
			setlocationErr(false);
		}
	}, [sectionIndex, form.office_address]);

	return (
		<div style={{ width: "100%" }}>
			{title}

			<div
				style={{
					..._x(_mobile).row,
					width: "100%",
				}}
			>
				<div>
					<p>Phone Number</p>
					<input
						style={{ ..._x().input, height: 43, width: "100%" }}
						value={form.phoneNumber}
						onChange={(e) => updateTextField(e.target.value, "phoneNumber")}
						onBlur={simpleValidator.current.showMessageFor("Phone Number")}
						placeholder="Enter Phone Number"
					/>

					<Spacing vertical={10} />

					<div
						style={{
							visibility: form.showfielderror ? "visible" : "hidden",
						}}
					>
						{simpleValidator.current.message(
							"Phone Number",
							form.phoneNumber,
							"required",
							{
								element: (msg) => <div style={_x().error}>{msg}</div>,
							}
						)}
					</div>
				</div>

				<div>
					<p>Shop Name</p>
					<input
						style={{ ..._x().input, height: 43, width: "100%" }}
						value={form.shopName}
						onChange={(e) => updateTextField(e.target.value, "name")}
						onBlur={simpleValidator.current.showMessageFor("Shop Name")}
					/>

					<Spacing vertical={10} />

					<div
						style={{
							visibility: form.showfielderror ? "visible" : "hidden",
						}}
					>
						{simpleValidator.current.message(
							"Shop Name",
							form.shopName,
							"required",
							{
								element: (msg) => <div style={_x().error}>{msg}</div>,
							}
						)}
					</div>
				</div>
			</div>
			<div>
				<p>Shop Address</p>

				<Autocomplete
					defaultValue={form.office_address}
					value={form.office_address}
					className="autolocation"
					onPlaceSelected={(place) => {
						const selectedAddress = place.formatted_address;
						// console.log(place.geometry, place.geometry.location.lat());
						setform({
							...form,
							office_address: selectedAddress,
							office_address_geolocation: {
								lat: place.geometry.location.lat(),
								long: place.geometry.location.lng(),
							},
						});
					}}
					onChange={(e) => setform({ ...form, office_address: e.target.value })}
					options={{
						types: ["establishment", "geocode"],
					}}
					onBlur={simpleValidator.current.showMessageFor("Office Address")}
				/>

				<Spacing vertical={10} />

				<div
					style={{
						visibility: form.showfielderror ? "visible" : "hidden",
					}}
				>
					{simpleValidator.current.message(
						"Office Address",
						form.office_address.length === 0 ? "" : form.office_address,
						"required",
						{
							element: (msg) => (
								<div style={_x().error}>office address is required !</div>
							),
						}
					)}
				</div>

				{locationErr && <div style={_x().error}>Address is required.</div>}
			</div>

			<div>
				<div>
					<p>Shop Description</p>

					<input
						style={{ ..._x().input, height: 43 }}
						placeholder="Enter you Shop Description"
						value={form.shopDescription}
						onChange={(e) => updateTextField(e.target.value, "description")}
					/>
				</div>
			</div>

			<Spacing />

			<div style={_x().row}></div>
		</div>
	);
}

const _x = (_mobile) => ({
	error: {
		color: "red",
		fontSize: 10,
	},

	row: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
		gridGap: 20,
		width: _mobile ? "90vw" : 0,
		// background: "red",
	},

	input: {
		width: "100%",
		border: "1px solid #DBDEE7",
		borderRadius: 3,
	},

	select: {
		height: 32,
		width: "100%",
		border: "1px solid #DBDEE7",
		borderRadius: 3,
	},

	textarea: {
		resize: "none",
		width: "100%",
		height: 150,
		borderColor: "#DBDEE7",
		padding: 10,
		overflowY: "scroll",
	},
});
