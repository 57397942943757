import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../Resources/Logo/main_logo.png";
import { Spacing } from "../../Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function Footer({ useExplore, sideMargins, paddingInline }) {
  const _mobile = useMediaQuery("(max-width:800px)");

  return (
    <div
      style={{
        ..._x(_mobile).footer,
        // marginLeft: sideMargins,
        // marginRight: sideMargins,
        margin: "10px auto",
        padding: _mobile ? 0 : paddingInline ?? 10,
      }}
    >
      {useExplore && (
        <>
          <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
            <p style={_x().text3}>Explore the world of Quickmechs</p>
            <div style={_x().text4}>See our best features</div>
          </div>

          <Spacing />
        </>
      )}

      <div style={_x(_mobile).subTab}>
        <Link to="/home/about-us" style={_x(_mobile).footer_link}>
          <p style={_x(_mobile).text1}>About Us</p>
        </Link>
        <Link to="/home/advertising" style={_x(_mobile).footer_link}>
          <p style={_x(_mobile).text1}>Advertising</p>
        </Link>
        <Link to="/home/become-a-member" style={_x(_mobile).footer_link}>
          <p style={_x(_mobile).text1}>Become a Member</p>
        </Link>
        <Link to="/home/faq" style={_x(_mobile).footer_link}>
          <p style={_x(_mobile).text1}>FAQ</p>
        </Link>

        <Link to="/home/privacy-policy" style={_x(_mobile).footer_link}>
          <p style={_x(_mobile).text1}>Privacy Policy</p>
        </Link>
        <Link to="/home/services" style={_x(_mobile).footer_link}>
          <p style={_x(_mobile).text1}>Services</p>
        </Link>

        <Link to="/home/spare-part-info/" style={_x(_mobile).footer_link}>
          <p style={_x(_mobile).text1}>Spare Part</p>
        </Link>

        <Link to="/home/support" style={_x(_mobile).footer_link}>
          <p style={_x(_mobile).text1}>Support</p>
        </Link>
        <Link to="/home/site-map" style={_x().footer_link}>
          <p style={_x().text1}>Site Map</p>
        </Link>
      </div>

      <p style={_x(_mobile).text2}>
        Quickmechs, Inc. is not responsible for content on external Web sites.{" "}
        &copy; {new Date().getFullYear()} Quickmechs, Inc, a Quickmechs Group
        Company. All rights reserved.
      </p>

      <img style={_x(_mobile).logo} src={logo} alt="Quickmechs" />
    </div>
  );
}

export default Footer;

const _x = (_mobile) => ({
  footer: {
    height: _mobile ? 335 : null,
    display: "grid",
    gridTemplateRows: "0.9fr 0.3fr 0.3fr",
    placeItems: "center",
    marginTop: _mobile ? "1rem" : "0",
    maxWidth: "1400px",
    width: _mobile ? "90%" : "100%",
  },

  subTab: _mobile
    ? {
        display: "grid",
        padding: "0",
        gridTemplateColumns: "0.8fr 1fr 1fr",
        width: "100%",
        alignSelf: "center",
      }
    : {
        display: "flex",
        justifyContent: "space-between",
        minWidth: "67%",
        maxWidth: 1168,
        width: "100%",
      },

  logo: {
    width: _mobile ? 136 : 250,
  },

  footer_link: {
    textDecoration: "none",
  },

  text1: {
    color: "var(--primary1)",
    cursor: "pointer",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: _mobile ? "12px" : "13px",
    // marginTop: ,
    marginBottom: 20,
  },

  text2: {
    textAlign: "center",
    // paddingLeft: 180,
    // paddingRight: 180,
    fontSize: 12,
    color: "#738EAD",
    width: _mobile ? null : 750,
    lineHeight: 1.8,
    color: "var(--primary1)",
    fontWeight: 500,
  },

  text3: {
    fontWeight: "bold",
    fontSize: 15,
  },

  text4: {
    color: "var(--primary1)",
    fontSize: 14,
    cursor: "pointer",
  },
});
