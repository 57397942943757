import { Modal } from "@material-ui/core";
import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import { RatingStar } from "rating-star";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import LoginPopUp from "src/Components/Functional/loginpop/LoginPopUp";
import { authHeader } from "src/services/auth-header";
import AuthService from "src/services/auth.service";
import { Hr } from "../../../Components/Style/Styles";
import star from "../../../Resources/Icons/star-yellow.png";
import HepfulTip from "./HepfulTip";
import "./caseReview.css";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;

const ShowCaseReview = () => {
	const { id } = useParams();
	let history = useHistory();
	const { product_title } = useParams();
	const currentUser = AuthService.getCurrentUser("user");

	const [percentage, setPercentage] = useState([]);
	const [overallData, setOverallData] = useState("");
	const [info, setInfo] = useState("");
	const [imgData, setImgData] = useState([]);
	const [revData, setRevData] = useState([]);
	const [progress, setProgress] = useState(0);
	const [loginpopup, setloginpopup] = useState(false);

	const [isloading, setisloading] = useState(false);

	const RevAPI = API_URL + "review/get-product-reviews/" + `${id}`;
	const Single_product_API = API_URL + "products/find/" + `${id}`;

	const getProduct = () => {
		setisloading(true);
		setProgress(93);
		axios
			.get(Single_product_API, {
				headers: authHeader(),
			})
			.then((res) => {
				const response = res.data.data;
				setOverallData(response.customer_reviews);
				setImgData(res.data.data.product_photo[0]);
				setInfo(response);
				const percentages = response.customer_reviews.percentageRatings;
				setPercentage(percentages);
				setProgress(100);
				setisloading(false);
			})
			.catch((err) => {
				console.log(err);
				setisloading(false);
			});
	};

	const grabReview = () => {
		axios
			.get(RevAPI, {
				headers: authHeader(),
			})
			.then((res) => {
				console.log(res.data.data);
				const response = res.data.data;
				setRevData(response);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const writeReview = () => {
		if (currentUser) {
			history.push(
				"/home/spare-part/" +
					`${info.product_title}` +
					"/product-review=" +
					`${id}`
			);
		} else {
			setloginpopup(true);
		}
	};

	function onLogin(status) {
		if (status === "success") {
			setloginpopup(false);
		}
	}

	useEffect(() => {
		getProduct();
		grabReview();
	}, []);

	return (
		<main className="main-coll">
			{isloading ? (
				<Spinner fullscreen={false} isloading={isloading} size={100} />
			) : (
				<>
					<div className="rating-title">Ratings & Review</div>
					<div className="hr_line">
						{" "}
						<Hr thickness color />
					</div>
					<div className="display-image-pr">
						<div className="pr-image">
							<img src={imgData} alt="" />
						</div>
						<div className="pr-details-coll">
							<p className="details-name"> {info.product_title} </p>
							<p className="details-numb"> {info.product_no} </p>
							<p className="details-price">$ {info.price}</p>
						</div>
					</div>
					<Hr thickness color />
					<div className="dashboard-ratings">
						<div className="rating-title case-rev">Ratings & Review</div>

						<div className="rating-col">
							<div className="rate-stars">
								<p className="average-rating">
									{overallData.average_overall_rating}{" "}
								</p>
								<RatingStar
									rating={overallData.average_overall_rating}
									noBorder
									size={20}
									colors={{ mask: "#FBB500", rear: "#c4c4c4" }}
								/>
								<p className="tot-rating">
									{overallData.total_rating} Customer Ratings
								</p>
							</div>
							<div className="progress-container">
								<div className="progress-col">
									<p className="col-rate-numb">5</p>
									<img src={star} alt="star" className="col-rate-star" />

									<ProgressBar
										height={7}
										width={210}
										bgcolor="#01195A"
										isLabelVisible={false}
										completed={percentage[5]}
									/>
								</div>
								<div className="progress-col">
									<p className="col-rate-numb">4</p>
									<img src={star} alt="star" className="col-rate-star" />

									<ProgressBar
										height={7}
										width={210}
										bgcolor="#01195A"
										isLabelVisible={false}
										completed={percentage[4]}
									/>
								</div>
								<div className="progress-col">
									<p className="col-rate-numb">3</p>
									<img src={star} alt="star" className="col-rate-star" />

									<ProgressBar
										height={7}
										width={210}
										bgcolor="#01195A"
										isLabelVisible={false}
										completed={percentage[3]}
									/>
								</div>
								<div className="progress-col">
									<p className="col-rate-numb">2</p>
									<img src={star} alt="star" className="col-rate-star" />

									<ProgressBar
										height={7}
										width={210}
										bgcolor="#01195A"
										isLabelVisible={false}
										completed={percentage[2]}
									/>
								</div>
								<div className="progress-col">
									<p className="col-rate-numb">1</p>
									<img src={star} alt="star" className="col-rate-star" />

									<ProgressBar
										height={7}
										width={210}
										bgcolor="#01195A"
										isLabelVisible={false}
										completed={percentage[1]}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="cta-review">
						<div className="cta-review-title">Review this store</div>
						<p className="cta-tag">Share your thoughts with other customers</p>

						<button className="cta-rev" onClick={writeReview}>
							Write a customer review
						</button>
					</div>
					<Hr thickness color />
					<div className="reviews-container">
						<p className="review-heading">
							{revData.length === 0 ? "" : "Top Reviews"}
						</p>
						{revData.map((rev, index) => {
							const pix = rev.review_photo;
							console.log("bro", pix);
							const fName = rev.display_name;
							const dp = rev.display_photo;
							let firstChar = fName.charAt(0);

							return (
								<article className="review-comment-container" key={index}>
									<div className="image-tab">
										<div className="review-image">
											<article
												style={{
													width: "30px",
													height: "30px",
													backgroundColor: "#01195A",
													borderRadius: "50%",
													overflow: "hidden",
												}}
											>
												{dp === "" ? (
													<p
														style={{
															fontSize: "1.1rem",
															textTransform: "uppercase",
															color: "#fff",
															padding: "4px 0",
															margin: "0",
															fontWeight: "700",
															objectFit: "cover",
															objectPosition: "center",
															display: "flex",
															justifyContent: "center",
															width: "100%",
															height: "100%",
														}}
													>
														{firstChar}
													</p>
												) : (
													<img
														src={rev.display_photo}
														alt=""
														style={{
															objectFit: "cover",
															width: "100%",
															height: "100%",
														}}
													/>
												)}
											</article>
										</div>
										<p style={{ textTransform: "capitalize" }}>
											{rev.display_name}{" "}
										</p>
									</div>
									<div className="review-date">
										<p>Reviewed on {rev.date_created}</p>
									</div>
									<SimpleReactLightbox>
										<SRLWrapper>
											<div className="review-images-tab">
												<ul className="rev-images-coll">
													{pix.map((i, index) => {
														return (
															<li className="rev-photo-item" key={index}>
																<img src={i} alt="" />
															</li>
														);
													})}
												</ul>
											</div>
										</SRLWrapper>
									</SimpleReactLightbox>
									<div className="review-comment-description">
										<p> {rev.written_review} </p>
									</div>
									<div className="helpful-tip">
										<p>6 people found this helpful </p>
									</div>
									<HepfulTip />
								</article>
							);
						})}
					</div>
				</>
			)}
			{isloading && (
				<LoadingBar
					color="#587FC0"
					loaderSpeed={4000}
					progress={progress}
					onLoaderFinished={() => setProgress(0)}
				/>
			)}
			<Modal open={loginpopup}>
				<LoginPopUp onLogin={onLogin}>
					<p style={{ cursor: "pointer" }} onClick={() => setloginpopup(false)}>
						x
					</p>
				</LoginPopUp>
			</Modal>
		</main>
	);
};

export default ShowCaseReview;
