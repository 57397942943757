import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';
// import { compose, withProps } from "recompose";
import Geocode from 'react-geocode';
import './map.css';
import { useState } from 'react';
import { compose, withProps } from 'recompose';
import mapIcon from '../../../Resources/Icons/mapIcon.png';

const Map = () => {
  const [latitude, setLatitude] = useState([]);
  const [longitude, setLongitude] = useState([]);
  const [pointers, setpointers] = useState([]);
  const [openMap, setOpenMap] = useState(false);
  const [singleMechStore, setSingleMechStore] = useState({
    isOpen: false,
    data: [],
  });
  const [serviceData, setserviceData] = useState([]);


	// const API_KEY = "AIzaSyC2nYnaejAUiy32kKgu3ijbHoLpca8G2-4"
	const API_KEY = "AIzaSyC8MxZB_qWnOrh1E4RnSE4rqDuMsCGIAek";

	Geocode.setApiKey(API_KEY);
	Geocode.setLanguage("en");
	Geocode.setLocationType("ROOFTOP");


  function markerPressed(name) {
    setSingleMechStore(!singleMechStore);

    let _singleStoreData = serviceData.filter((each) => each.key === name);

    setSingleMechStore({
      ...singleMechStore,
      isOpen: true,
      data: _singleStoreData,
    });
  }


	const MyMapComponent = compose(
		withProps({
			googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
			loadingElement: <div style={{ height: `100%` }} />,
			containerElement: (
				<div style={openMap ? { height: `100vh` } : { height: `167px` }} />
			),
			mapElement: <div style={{ height: `100%` }} />,
		}),
		withScriptjs,
		withGoogleMap
	)((props) => (
		<GoogleMap
			defaultZoom={10}
			defaultCenter={{ lat: latitude, lng: longitude }}
			defaultOptions={{
				disableDefaultUI: true,
				zoomControl: true,
				mapTypeControl: false,
				streetViewControl: false,
				keyboardShortcuts: false,
				mapData: false,
				termsOfUse: false,
				google: false,
				fullscreenControl: true,
			}}
		>
			{pointers.map((marker) => (
				<Marker
					key={marker.id}
					position={{ lat: marker.latitude, lng: marker.longitude }}
					icon={{ url: mapIcon }}
					onClick={() => markerPressed(marker.name)}
				>
					{/* {openMap && (

						<InfoWindow>
							<div>{marker.name}</div>
						</InfoWindow>
					)} */}
        </Marker>
      ))}
    </GoogleMap>
  ));

  return <MyMapComponent isMarkerShown labelVisible />;
};

// compose(
// 	withProps({
// 		googleMapURL:
// 			"https://maps.googleapis.com/maps/api/js?key=AIzaSyC2nYnaejAUiy32kKgu3ijbHoLpca8G2-4&v=3.exp&libraries=geometry,drawing,places",
// 		loadingElement: <div style={{ height: `100%` }} />,
// 		containerElement: <div style={{ height: `300px` }} />,
// 		mapElement: <div style={{ height: `100%` }} />,
// 	}),
// 	withScriptjs,
// 	withGoogleMap
// )((props) => {
// 	Geocode.setApiKey("AIzaSyC8MxZB_qWnOrh1E4RnSE4rqDuMsCGIAek");
// 	Geocode.setLanguage("en");
// 	Geocode.setLocationType("ROOFTOP");

// 	return (
// 		<GoogleMap
// 			defaultZoom={10}
// 			defaultCenter={{ lat: props.latitude, lng: props.longitude }}
// 			defaultOptions={{
// 				zoomControl: false,
// 				mapTypeControl: false,
// 				streetViewControl: false,
// 				keyboardShortcuts: false,
// 				mapData: false,
// 				termsOfUse: false,
// 			}}
// 		>
// 			{props.pointers?.map((marker) => {
// 				return (
// 					<Marker
// 						key={marker.id}
// 						position={{ lat: marker.latitude, lng: marker.longitude }}
// 					>
// 						<InfoWindow>
// 							<div>{marker.name}</div>
// 						</InfoWindow>
// 					</Marker>
// 				);
// 			})}
// 		</GoogleMap>
// 	);
// });

export default Map;
