import { Button, Modal } from "@material-ui/core";
import { OutlinedInput, useMediaQuery } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import authService from "src/services/auth.service";
import { getAllJobs, searchJobs } from "src/services/job/job.service";
import EmployerImage from "../../../Resources/Images/owner-img.png";
import CardElement from "./CardElement";
import LoaderComponent from "./LoaderComponent";
import { EmptyPageElement } from "./MyJob";
import "./findjob.scss";

export default function FindJob({ location, ...props }) {
	const matches = useMediaQuery("(max-width:1000px)");
	const currentUser = authService.getCurrentUser("user");
	const [jobList, setJobList] = useState([]);
	const [active, setActive] = useState({});
	const [page, setPage] = useState(1);
	const [modalOpen, setModalOpen] = useState(false);
	const handleClose = () => setModalOpen(false);
	const history = useHistory();
	const [progress, setProgress] = useState(false);
	const [loader, setLoader] = useState(false);
	const getAllJobss = async () => {
		setProgress(!progress);
		const result = await getAllJobs();
		try {
			console.log("result", result, "jobs");
			if (
				result.status === 200 ||
				result.status === 201 ||
				result.data.data.status === "success"
			) {
				setProgress(!progress);
				setActive(result.data.data[0]);
				setJobList([...result.data.data]);
			}
			setProgress(!progress);
		} catch (error) {
			setProgress(!progress);
			console.log(error.message);
		}
	};
	useEffect(() => {
		if (!currentUser) {
			history.push("/auth/login");
		}
	}, []);
	useEffect(() => {
		getAllJobss();
	}, []);
	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const onSearch = async (e) => {
		const {
			target: { value },
		} = e;
		const result = await searchJobs(value);
		if (result.data.status === "success") {
			if (result.data.data.length === 0) {
				getAllJobs();
				return;
			}
			setJobList(result.data.data);
			return;
		}
	};
	if (jobList.length === 0) {
		return !progress ? <EmptyPageElement /> : <LoaderComponent />;
	} else
		return (
			<Fragment>
				<div className="find-job-wrapper">
					<div className="inner-job-find-wrap">
						<div
							style={{ display: matches && page === 2 && "none" }}
							className="sections"
						>
							<div style={{ marginBottom: "10px" }}>
								<span className="title-job-row">Jobs</span>
								<div style={{ width: "100%" }}>
									<div style={styles.searchWrap}>
										<img
											src={
												require("../../../Resources/Icons/search1.svg").default
											}
											alt=""
										/>
										<OutlinedInput
											onChange={onSearch}
											placeholder="search.."
											// value={}
											sx={{ ...styles.input, outline: "none", border: "none" }}
										/>
									</div>
								</div>
							</div>
							<div className="content-list">
								{jobList.map((item, index) => (
									<CardElement
										data={item}
										onClick={() => {
											setActive(item);
											// window.scrollTo(0, 0);
											setPage(2);
										}}
										style={{
											border: active.id === item.id && "1px solid #587FC0",
											borderRadius: active.id === item.id && "4px",
										}}
										key={`${Math.random()}`}
									/>
								))}
							</div>
						</div>

						{/* ============================ */}
						{Object.keys(active).length > 0 && (
							<div
								style={{ display: matches && page === 1 && "none" }}
								className="sections"
							>
								<div style={{}} className="section-2-wrap">
									<div
										style={{
											marginBottom: "10px",
											display: "flex",
											width: "100%",
											alignItems: "center",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<span className="title-job-row">
											{capitalizeFirstLetter(`${active?.title}`)}
										</span>
										{matches && (
											<Button
												onClick={() => {
													setPage(1);
												}}
												variant="contained"
											>
												Back
											</Button>
										)}
									</div>
									<div
										style={{
											width: "100%",
											display: "flex",
											flexDirection: "column",
										}}
									>
										<span className="sub-title">{active?.location}</span>
										<span className="sub-title">{active?.category}</span>
										<span className="job-type">{active?.employment_type}</span>
										<div
											style={{
												height: "fit-content",
												borderRadius: "15px",
												marginBottom: "10px",
												marginTop: "10px",
											}}
										>
											<Button
												onClick={(e) => {
													const mailto = `mailto:${active?.apply_detail}`;
													if (active?.apply_method === "email") {
														window.location.href = mailto;
														e.preventDefault();
													}

													if (active.apply_method === "url")
														window.open(`${active?.apply_detail}`);
													if (active.apply_method === "phone")
														setModalOpen(true);
												}}
												style={{
													background: " #587FC0",
													borderRadius: "20px",
													cursor: "pointer",
												}}
												variant="contained"
											>
												<img
													style={{ marginRight: 2 }}
													alt="call-icon"
													src={
														active?.apply_method === "email"
															? require("../../../Resources/Icons/email.png")
																	.default
															: require("../../../Resources/Icons/call-icon.png")
																	.default
													}
												/>
												<span style={{ color: "white" }}>
													{active?.apply_method === "email"
														? " Email now"
														: active?.apply_method === "url"
														? "Apply now"
														: "Call now"}
												</span>
											</Button>
										</div>
										<label className="small-txt-bellow-call">
											Clicking “Email now” will open your default email app
										</label>
									</div>
									<div className="profile-area">
										<img
											src={active?.logo ?? EmployerImage}
											alt="profile image"
											style={{
												width: "48px",
												height: "48px",
												borderRadius: 24,
											}}
										/>
										<div className="text-wrap">
											<span className="title-txt-prod">
												{active?.company_name}
											</span>
											<span className="small-text-bellow-call">Employer</span>
										</div>
									</div>
									<div className="body-wrapper">
										<span className="title-txt-prod">Description</span>
										<span className="small-description">Job Description</span>
										<div className="small-txt-body">{active?.description}</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<Modal
					open={modalOpen}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<div className="call-modal-inner">
						<div className="inner-call-card">
							<span className="call-title-txt">
								{active?.apply_method === "phone"
									? `Call ${active?.company_name} on`
									: `Email ${active?.company_name} on`}
							</span>
							<span className="phone-txt-call">{active?.apply_detail}</span>
							<Button
								onClick={handleClose}
								style={{
									background: " #587FC0",
									borderRadius: "20px",
									marginTop: "1rem",
									cursor: "pointer",
								}}
								variant="contained"
							>
								<span style={{ marginLeft: 3, color: "white" }}>Cancel</span>
							</Button>
						</div>
					</div>
				</Modal>
			</Fragment>
		);
}

const styles = {
	input: {
		fontSize: "12px",
		flexGrow: 1,
		height: "100%",
		"& fieldset": { border: "none" },
	},
	searchWrap: {
		display: "flex",
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		// width: "100%",
		borderRadius: "6px",
		paddingLeft: 5,
		paddingRight: 5,

		height: "38px",
		gap: 5,
		marginTop: "5px",
		background: "#fff",
		border: "1px solid #C4C4C4",
		outLine: "none",
	},
};
