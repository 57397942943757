import React from "react";
import { Paper } from "@material-ui/core";
import Footer from "src/Components/Functional/Footer/Footer";
import { Align } from "src/Components/Style/Styles";
import '../home.css';

function TermsofUse() {
  return (
    <>
      <Align>
	  <Paper style={_x.paper}>
          <div className="card-header padding-lg">
            <h3 style={_x.header}>Terms and Condition</h3>
          </div>
          
          <section className="card-content " style={_x.main}>
            <div>
              <p>
                The app is the property of AFTJ digital and it's licensors. By
                using the app, you agree to these terms of use; if you do not
                agree,do not use the app.
              </p>
              <p>
                AFTJ church app reserves the right, at it's sole discretion, to
                change, modify, add or remove portions of these terms of use, at
                any time. And it is your responsibility to check these terms of
                use periodically for changes. Your continued use of the app
                following the posting of changes will mean that you accept and
                agree to the changes. As long as you comply with these terms of
                use, AFTJ church app grants you a personal,
                non-exclusive,non-transferable, limited privilege to enter and
                use the app.
              </p>
              <p>
                We process personal data in accordancewith our privacy policy.
                As part of thisagreement you consent to our doing so.You should
                read the policy carefully, especially if you have any concerns
                aboutyour privacy.
              </p>
            </div>
           
            <div>
              <h4>Warning:</h4>
              <p>
                unless we have agreed aparticular level of service with you,
                wemake absolutely no promises about thequality or existence of
                any of the servicesprovided on our platform.
              </p>
            </div>
           
            <div>
              <h4>Disclaimer:</h4>
              <p>
                In as much as we are proud to create aplatform that offers great
                solution to thebody of Christ and also try to make themreliable
                and useful, we make no promisesthat the services or feature of
                the app willbe error-free or uninterrupted, or that anydefects
                will be corrected, or that your useof the app will provide
                specific results.The mobile applications are certain to failsome
                of the time. We adapt, change andupdate our services from time
                to time, soyou may find that something that workedfor you may
                cease to work. We may alsostop supplying any service,
                temporarily or permanently or block access to ourservices to
                anyone for any reason.May you require some level of
                otherservices, please do not hesitate tocontact us.
              </p>
              <p>
                The above disclaimer applies to anydamages, liability or
                injuries caused byany failure of performance, error,omission,
                interruption, deletion, defect,delay in operation or
                transmission,computer virus, communication linefailure,
                destruction of or unauthorizedaccess to, alteration of, or use,
                whetherfor breach of contract, tort, negligence orany other
                cause of action.
              </p>
              <p>
                AFTJ church app reserves the right to doany of the following, at
                any time, withoutnotice: (1) to modify, suspend orterminate
                operation of or access to theapp, or any portion of the app, for
                anyreason; (2) to modify or change the app,or any portion of the
                app, and any applicable policies or terms; and (3) tointerrupt
                the operation of the app, or anyportion of the app, as necessary
                toperform routine or non-routinemaintenance, error correction,
                or otherchanges.
              </p>
            </div>
           
            <div>
              <h4>
                Content and intellectual property <br />
                What we do with your content:
              </h4>
              <p>
                If you contribute content to any of ourservices, for example by
                uploading data,then as a general rule you agree tolicence that
                content to us.
              </p>
            </div>
           
            <div>
              <h4>Our Content</h4>
              <p>
                Unless otherwise stated all our servicesare offered under open
                content or datalicences, some of our content belongs tothird
                parties. Most third party data issubject to an open licence, but
                we cannot guarantee it. You should refer to the thirdparty if
                you are in doubt.
              </p>
            </div>
           
            <div>
              <h4>Ownership does not change</h4>
              <p>
                As a general rule, this agreement will notchange the ownership
                of any intellectualproperty belonging to either party.What not
                to do You agree not to use our app to do any ofthe following:
                (1) Anything which is illegaleither wherever you are in the
                world. (2)Cause nuisance to other users of our app.(3) Interfere
                with the normal running ofour app. (4) Try to access our systems
                ina way other than to use suspicious appsthat does not respect
                the policy.
              </p>
            </div>
           
            <div>
              <h4>General conditions</h4>
              <p>
                Its important to note that for thisagreement is that we may
                update theseterms and conditions at any time. If we doso, we
                will send you a notification. Any changes will be binding on you
                from themoment we announce them.
              </p>
            </div>
           
            <div>
              <h4>Accounts, passwords and security</h4>
              <p>
                Some features or services offered on ourapp may require you to
                open an accountwhich includes unique id/email andpassword. You
                are entirely responsible formaintaining the confidentiality of
                theinformation you hold for your account,including your
                password, and for any andall activity that occurs under your
                accountas a result of your failing to keep thisinformation
                secure and confidential. Youagree to notify AFTJ immediately of
                anyunauthorized use of your account orpassword, or any other
                breach of security.You may be held liable for losses incurredby
                AFTJ church app or any other user of orvisitor to the app due to
                someone elseusing your username, password oraccount as a result
                of your failing to keepyour account information secure
                andconfidential.
              </p>
            </div>
           
            <div>
              <h4>Privacy</h4>
              <p>
                AFTJ church app privacy policy applies to use of this app, and
                its terms are made apart of these terms of use by this
                reference. Visit privacy policy to read more. Additionally, by
                using the app, you acknowledge and agree that internet
                transmissions are never completely private or secure. You
                understand that any message or information you send to the app
                may be read or intercepted by others, even if there is a special
                notice that a particular transmission is encrypted.
              </p>
            </div>
           
            <div>
              <h4>LiabilityIndemnities when you may owe us</h4>
              <p>
                If you breach any of your obligationsunder this agreement and,
                as a result,cause us to be sued by anyone else, you will have to
                compensate us for any losswe have suffered as a result,
                whichincludes any costs, such as payinglawyers, or for our own
                time, we incurdefending a claim as well as any damagesawarded.If
                your breach causes you to be sued bysomeone else, you will not
                sue us for anyloss you suffer as a result.
              </p>
            </div>
           
            <div>
              <h4>Exclusion what we do not owe you</h4>
              <p>
                We limit our liability in several differentways all of which we
                believe to be fair. Incase any one of them is found to
                beunenforceable by a court, each of thefollowing limitations of
                liability is separateand our liability to you is limited by all
                ofthem.
              </p>
              <p>
                All exclusions of liability are only in so faras we are allowed
                to do so by whateverlaw applies to the situation We will not be
                liable for any damage thatwas not reasonably foreseeable at
                thetime we made this agreement.
              </p>
              <p>
                Any liability to you is limited by theamount you have paid us
                for a service,where:Our liability is zero for a
                freeservice.Where you make periodicpayments, our liability is
                limited to thevalue of the last payment you paid us.
              </p>
              <p>
                We are not liable for any loss which isindirect or
                consequential. That includesany loss of business or profit.
              </p>
              <p>
                We exclude, in so far as we are allowed,any warranties that
                would be implied bylaw.
              </p>
            </div>
           
            <div>
              <h4>Violation of these terms of use</h4>
              <p>
                AFTJ church app may disclose anyinformation we have about you if
                wedetermine that such disclosure isnecessary in connection with
                any investigation or complaint regarding youruse of the app, or
                to identify, contact orbring legal action against someone whomay
                be causing injury to or interferencewith AFTj church app rights
                or property,or the rights or property of visitors to orusers of
                the app, including AFTJcustomers. AFTJ church app reserves
                theright at all times to disclose anyinformation that AFTJ deems
                necessary tocomply with any applicable law,regulation, legal
                process or governmentalrequest. AFTJ may also disclose
                yourinformation when AFTJ determines thatapplicable law requires
                or permits suchdisclosure, including exchanginginformation with
                other companies andorganizations for fraud protectionpurposes.
              </p>
              <p>
                You acknowledge and agree that we maypreserve any transmittal
                orcommunication by you with us throughthe app or any service
                offered on orthrough the app, and may also disclose such data if
                required to do so by law orAFTJ determines that such
                preservation ordisclosure is reasonably necessary to (1)comply
                with legal process, (2) enforcethese terms of use, (3) respond
                to claimsthat any such data violates the rights ofothers, or (4)
                protect the rights, propertyor personal safety of AFTJ, its
                employees,users of or visitors to the app, and thepublic.
              </p>
              <p>
                You agree that AFTJ may, in its solediscretion and without prior
                notice,terminate your access to the app and/orblock your future
                access to the app if wedetermine that you have violated
                theseterms of use or other agreements orguidelines which may be
                associated withyour use of the app. You also agree thatany
                violation by you of these terms of usewill constitute an
                unlawful and unfairbusiness practice, and will causeirreparable
                harm to AFTJ, for whichmonetary damages would be inadequate,and
                you consent to AFTJ obtaining any injunctive or equitable relief
                that AFTJdeems necessary or appropriate in suchcircumstances.
                These remedies are inaddition to any other remedies AFTJ mayhave
                at law or in equity.
              </p>
              <p>
                You agree that AFTJ may, in its solediscretion and without prior
                notice,terminate your access to the app forcause, which includes
                (but not limited to)(1) requests by law enforcement or
                othergovernment agencies, (2) a request byyou (self-initiated
                account deletions), (3)discontinuance or material modification
                ofthe app or any service offered on orthrough the app, or (4)
                unexpectedtechnical issues or problems.
              </p>
              <p>
                If AFTJ does take any legal action againstyou as a result of
                your violation of theseterms of use, AFTJ will be entitled
                torecover from you, and you agree to pay,all reasonable
                attorneys’ fees and costs ofsuch action, in addition to any
                other reliefgranted to AFTJ. You agree that AFTJ will not be
                liable to you or to any third partyfor termination of your
                access to the appas a result of any violation of these termsof
                use.
              </p>
            </div>
           
            <div>
              <h4>Governing law; dispute resolution</h4>
              <p>
                You agree that all matters relating to youraccess to or use of
                the app, including alldisputes, will be governed by the laws
                ofthe United States without regard to itsconflicts of laws
                provisions. You agree tothe personal jurisdiction by and venue
                inthe state and federal courtsand waiveany objection to such
                jurisdiction orvenue.Any claim under these terms ofuse must be
                brought within one (1) yearafter the cause of action arises, or
                suchclaim or cause of action is barred. Claimsmade under the
                separate terms andconditions of purchase for goods andservices
                are not subject to this limitation.No recovery may be sought or
                receivedfor damages other than out-of-pocketexpenses, except
                that the prevailing party will be entitled to costs
                andattorneys’ fees. In the event of anycontroversy or dispute
                between AFTJ andyou arising out of or in connection withyour use
                of the app, the parties shallattempt, promptly and in good
                faith, toresolve any such dispute. If we are unableto resolve
                any such dispute within areasonable time (not to exceed thirty
                (30)working days), then either party maysubmit such controversy
                or dispute tomediation. If the dispute cannot beresolved through
                mediation, then theparties shall be free to pursue any right
                orremedy available to them underapplicable law.
              </p>
            </div>
           
            <div>
              <h4>Feedback and information</h4>
              <p>
                Any feedback you provide at this Appshall be deemed to be
                non-confidential.AFTJ shall be free to use such informationon an
                unrestricted basis.
              </p>
            </div>
           
          </section>
		</Paper>
      </Align>
      <Footer />
    </>
  );
}

export default TermsofUse;

const _x = {
	paper: {
		background: "white",
		width: 860,
		// padding: 30,
		// paddingLeft: 50,
		// paddingRight: 50,
	},
	header: {
		color:'#fff',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: 18,
		
	  },
    main: {
      paddingLeft: 80,
      paddingRight: 80,
      paddingBottom: 130,
      paddingTop: 30,
    },
};
