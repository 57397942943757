import { Paper } from "@material-ui/core";
import React from "react";
import DownloadApp from "src/Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Align, Spacing } from "../../../Components/Style/Styles";

function Advertising() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			<Align verticalHeight={20}>
				<Paper style={_x(_mobile).advertising} elevation={_mobile ? 0 : 1}>
					<div style={_x(_mobile).header}>
						<div style={_x().title}>Content For Advertising Plan</div>
					</div>

					<div style={_x(_mobile).main}>
						<span style={_x().title2}>(SPARE-PART DEALERS ONLY)</span>

						<p style={_x().title2}>Why advertise with us?</p>

						<p style={_x().text3}>
							At quickmechs we offer deluxe services that give your product an
							extra advantage and increase brand exposure above others, by
							granting you the opportunity to further create awareness, sales,
							and traffic for your product.
						</p>

						<Spacing vertical={10} />

						<p style={_x().text1}>Advert type and validity</p>

						<div style={_x().bullet}>
							<li style={_x().text1}>Long Term:</li>

							<li style={_x().li}>1 Year</li>

							<li style={_x().li}>6 Months</li>

							<li style={_x().li}>3 Months</li>

							<Spacing />

							<li style={_x().text1}>Short Term:</li>

							<li style={_x().li}>1 Month</li>

							<li style={_x().li}>2 Weeks</li>

							<li style={_x().li}>1 Week</li>

							<li style={_x().li}>3 Days</li>

							<Spacing />
						</div>

						<p style={_x().text1}>Where Adverts are placed</p>

						<div style={_x.bullet}>
							<li style={_x().li2}>Front Page</li>

							<li style={_x().li2}>Mechanical/Service Page</li>

							<li style={_x().li2}>Spare Part Page</li>
						</div>

						<Spacing />

						<p style={_x().text1}>How to place an order for Adverts</p>

						<div style={_x().bullet}>
							<li style={_x().li2}>
								Visit the advertise with us page adn fill in all the details and
								wait for an agent to contact you for verification, approval and
								payment
							</li>

							<li style={_x().li2}>
								Or send an email to ads@aftjdigital.com with the request details
							</li>
						</div>

						<Spacing />

						<p style={_x().text1}>How to make payment</p>

						<div style={_x().bullet}>
							<li style={_x().li2}>
								We accept payments using the following forms of payment: Bank
								payment, transfer, or via our payment gateway (Guarantees safe
								and secure transactions).
							</li>

							<li style={_x().li2}>
								Send proof of payment to ads@aftjdigital.com and wait for an
								approval email.
							</li>
						</div>

						<Spacing />

						<p style={_x().text1}>Product Requirements</p>

						<div style={_x().bullet}>
							<li style={_x().li2}>
								Product size of a .........x.........size is required
							</li>

							<li style={_x().li2}>
								The Product must be clear and well detailed.
							</li>
						</div>

						<Spacing />
					</div>
				</Paper>
			</Align>

			<DownloadApp />

			<Footer />
		</>
	);
}

export default Advertising;

const _x = (_mobile) => ({
	advertising: {
		// width: _mobile ? "100%" : 860,
		background: "white",
		marginTop: _mobile ? 5 : 50,
		maxWidth: _mobile ? "100vw" : "1200px",
		borderRadius: 15,
		// display: "none",
	},

	header: {
		background: "var(--primary1)",
		height: 80,
		padding: 10,
		color: "white",
		display: "grid",
		alignItems: "center",
		borderTopRightRadius: _mobile ? 0 : 10,
		borderTopLeftRadius: _mobile ? 0 : 10,
		paddingInline: _mobile ? 50 : 200,
	},

	main: {
		padding: 30,
		paddingInline: _mobile ? 50 : 200,
	},

	title: {
		fontSize: 18,
		fontWeight: "bold",
		fontStyle: "normal",
		fontSize: 18,
		marginTop: 15,
		marginBottom: 10,
	},

	title2: {
		fontSize: 18,
		fontWeight: 500,
		color: "var(--secondary1)",
		// lineHeight: 1.8,
	},

	text1: {
		color: "var(--baseBg)",
		fontWeight: 500,
		fontSize: 15,
	},

	text2: {
		fontSize: 15,
		fontWeight: "bold",
		color: "var(--secondary1)",
		lineHeight: 1.8,
	},

	text3: {
		fontSize: 13,
		lineHeight: 1.8,
	},

	bullet: {},

	bullet_header: {
		fontWeight: 500,
		fontSize: 15,
		marginBottom: 5,
	},

	li: {
		lineHeight: 1.8,
		marginLeft: 20,
		marginBottom: 5,
	},

	li2: {
		lineHeight: 1.8,
		marginBottom: 5,
	},
});
