import { Paper } from "@material-ui/core";
import React from "react";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Align, Spacing } from "../../../Components/Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { siteMapData } from "./sitemapdata";

function SiteMap() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			<Align verticalHeight={_mobile ? 0 : 20}>
				<Paper style={_x(_mobile).siteMap} elevation={_mobile ? 0 : 1}>
					<div style={_x(_mobile).header}>
						<p style={_x(_mobile).title}>Site Map</p>
					</div>

					<div style={_x(_mobile).main}>
						<p style={_x(_mobile).subtitle}>Information</p>
						{Object.keys(siteMapData).map((k) => (
							<>
								<p style={_x(_mobile).topic}>{k}</p>
								{siteMapData[k].map((e) => (
									<div key={e} className="site-list-item">
										{e}
									</div>
								))}
							</>
						))}
					</div>
				</Paper>
			</Align>

			<DownloadApp />

			<Footer />
		</>
	);
}

export default SiteMap;

const _x = (_mobile) => ({
	siteMap: {
		width: _mobile ? "100vw" : "1200px",
		background: "white",
		marginTop: _mobile ? 0 : 50,
		borderRadius: 10,
	},

	header: {
		background: "var(--primary1)",
		borderTopRightRadius: _mobile ? 0 : 10,
		borderTopLeftRadius: _mobile ? 0 : 10,
		display: "flex",
		flexDirection: "column",
		paddingInline: _mobile ? 50 : 200,
	},

	main: {
		padding: 50,
		paddingTop: 0,
		paddingInline: _mobile ? 50 : 200,
	},

	subtitle: {
		fontWeight: 500,
		marginRight: 8,
		color: "var(--secondary1)",
		fontSize: 18,
	},

	title: {
		fontSize: 17,
		fontWeight: "bold",
		color: "white",
	},

	topic: {
		fontSize: 15,
		color: "var(--secondary1)",
		fontWeight: 500,
	},

	text1: {
		fontSize: 14,
		fontWeight: 400,
		lineHeight: 1.8,
	},

	b: {
		fontWeight: 500,
		marginLeft: 3,
		marginRight: 3,
		color: "lightslategrey",
	},
});

// import React from "react";
// import { Paper } from "@material-ui/core";
// import Footer from "src/Components/Functional/Footer/Footer";
// import { Align } from "src/Components/Style/Styles";
// import { customer_support, group, our_sites } from "./sitemapdata";
// import "../home.css";
// import DownloadApp from "src/Components/Functional/DownloadApp/DownloadApp";
// import useMediaQuery from "@material-ui/core/useMediaQuery";

// function SiteMap() {
// 	const _mobile = useMediaQuery("(max-width:800px)");

// 	return (
// 		<>
// 			<div style={{ marginTop: 50, marginBottom: 45 }}>
// 				<div
// 					style={_mobile ? {} : { display: "grid", justifyContent: "center" }}
// 				>
// 					<Paper style={_x(_mobile).paper}>
// 						<div style={_x().header}>
// 							<p style={_x(_mobile).title}>Site Map</p>
// 						</div>

// 						<section className="card-content padding-sm">
// 							<div>
// 								<h5 className="site" style={_x.text1}>
// 									Customer Support
// 								</h5>

// 								<ul style={_x.ul}>
// 									{customer_support.map((item) => (
{
	/* <li key={item.id} className="site-list-item">
	{item.title}
</li> */
}
// 									))}
// 								</ul>
// 							</div>

// 							<div>
// 								<h5 className="site" style={_x.text1}>
// 									quickmechs Group
// 								</h5>

// 								<ul style={_x.ul}>
// 									{group.map((item) => (
// 										<li key={item.id} className="site-list-item">
// 											{item.title}
// 										</li>
// 									))}
// 								</ul>
// 							</div>

// 							<hr style={{ marginTop: 20, marginBottom: 20 }} />

// 							<div>
// 								<h5 className="site" style={_x.text1}>
// 									Our Sites
// 								</h5>

// 								<ul style={_x.ul}>
// 									{our_sites.map((item) => (
// 										<li key={item.id} className="site-list-item">
// 											{item.title}
// 										</li>
// 									))}
// 								</ul>
// 							</div>
// 						</section>
// 					</Paper>
// 				</div>
// 			</div>

// 			<DownloadApp />

// 			<Footer />
// 		</>
// 	);
// }

// export default SiteMap;

// const _x = (_mobile) => ({
// 	paper: {
// 		maxWidth: _mobile ? "100%" : "1200px",
// 		background: "white",
// 		marginTop: _mobile ? 0 : 50,
// 		borderRadius: 10,
// 	},
// 	header: {
// 		background: "var(--primary1)",
// 		borderTopRightRadius: 10,
// 		borderTopLeftRadius: 10,
// 		display: "flex",
// 		flexDirection: "column",
// 		paddingInline: _mobile ? 50 : 200,
// 	},
// 	title: {
// 		fontSize: 17,
// 		fontWeight: "bold",
// 		color: "white",
// 	},
// 	ul: {
// 		paddingLeft: 0,
// 	},
// 	text1: {
// 		color: "var(--primary1)",
// 		fontWeight: "bold",
// 		fontSize: 15,
// 	},
// });
