import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ImageContextMenu({
  image,
  onImageRemove,
  component,
  onImageUpdate,
  setAllImages,
  images,
  index,
  handleMarkAsCoverPhoto,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {component}
      </div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Box
          sx={{
            width: "360px",
            padding: 0,
            margin: 0,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              sx={{
                margin: 0,
                cursor: "pointer",
                marginRight: "10px",
                padding: "0px 0px 4px 0px",
              }}
              onClick={handleClose}
              aria-label="delete"
            >
              <CloseIcon
                sx={{
                  color: "#587FC0",
                }}
              />
            </IconButton>
          </div>
          <MenuItem
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              color: "#222",
              fontFamily: "Montserrat",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "14px",
              borderTop: "1px solid #C4C4C4",
              borderBottom: "1px solid #C4C4C4",
              padding: "4px 0px 4px 0px",
            }}
            onClick={handleMarkAsCoverPhoto}
          >
            <label for="">Set as Cover Photo</label>
          </MenuItem>
          <MenuItem
            sx={{
              color: " #FE0101",
              fontFamily: "Montserrat",
              fontSize: "12px",
              fontStyle: "normal",
              width: "100%",
              justifyContent: "center",
              display: "flex",
              fontWeight: "400",
              lineHeight: "14px",
            }}
            onClick={() => {
              onImageRemove(index);
              handleClose();
            }}
          >
            <label for="">Delete</label>
          </MenuItem>
        </Box>
        {/* <MenuItem
          onClick={() => {
            onImageRemove(index);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
        <MenuItem onClick={handleMarkAsCoverPhoto}>
          Mark as cover photo
        </MenuItem> */}
      </Menu>
    </div>
  );
}
