import React from "react";
import loan from "../../../Resources/Icons/loan.png";
import insurance from "../../../Resources/Icons/insurance.png";
import dealer from "../../../Resources/Icons/dealer.png";
import battery1 from "../../../Resources/Images/battery1.jpeg";
import battery2 from "../../../Resources/Images/battery2.jpeg";
import battery3 from "../../../Resources/Images/battery3.jpeg";
import battery4 from "../../../Resources/Images/battery4.jpeg";
import lexus from "../../../Resources/Images/lexus.png";
import tire from "../../../Resources/Images/tire.png";
import cleanBodyWork from "../../../Resources/Images/cleanbodywork.jpg";
import instagram from "../../../Resources/Icons/instagram.svg";
import twitter from "../../../Resources/Icons/twitter.svg";
import { Margin } from "../../../Components/Style/Styles";
import Banner from "../../../Components/Functional/Banner/Banner";
import Footer from "../../../Components/Functional/Footer/Footer";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import AdService from "../../../Components/Functional/AdService/AdService";
import { Helmet } from "react-helmet";

function SparePartsShop() {
	return (
		<>
			<Banner type={2} />

			<div
				className="home-row-1"
				style={{
					display: "flex",
					justifyContent: "center",
					padding: "2rem 10rem",
				}}
			>
				<div className="home-row-1-container">
					<h2 className="need-help">Do you need help?</h2>

					<div className="home-row-1-row-1">
						<button className="home-row-1-row-1-box">
							<img src={loan} alt="loan" />
							Get a Car Loan
						</button>
						<button className="home-row-1-row-1-box">
							<img src={insurance} alt="insurance" />
							Insure your Car
						</button>
						<button className="home-row-1-row-1-box">
							<img src={dealer} alt="dealer" />
							Car Dealer
						</button>
					</div>
				</div>
			</div>

			<Margin>
				<div className="home-row-2" style={_x.homerow_2}>
					<h2 className="home-subtitle">Do you need help?</h2>

					<div className="home-row-2-grid-box">
						<div className="home-row-2-grid-box-b1">
							<img
								className="home-row-2-grid-box-b1-row-1"
								src={lexus}
								alt="lexus"
							/>

							<div className="home-row-2-grid-box-b1-row-2">
								<div style={{ marginTop: "3px" }}>Lexus LC 500</div>

								<div>
									Get the latest Lexus LC 500 from your best dealer in Lagos,
									Nigeria
								</div>
							</div>
						</div>

						<div className="home-row-2-grid-box-b2">
							<div className="home-row-2-grid-box-b2-col-1">
								<img src={tire} alt="tire" />

								<div style={_x.ad_column}>
									<div style={_x.tire_header}>Superb tire & Wheels</div>

									<div>Get your tire & wheels at 50% off today</div>
								</div>
							</div>

							<div className="home-row-2-grid-box-b2-col-2">
								<img src={cleanBodyWork} alt="tire" />

								<div style={_x.ad_column}>
									<div style={_x.tire_header}>Clean Body Shop</div>

									<div>Get a $ 50,000 value for a $ 5,000 job.</div>
								</div>
							</div>
						</div>

						<div
							className="home-row-2-grid-box-b3"
							style={{ marginTop: "2rem" }}
						>
							<div className="home-row-2-grid-box-b3-row-1">
								<div>One week warranty on all our batteries</div>
							</div>

							<div className="home-row-2-grid-box-b3-row-2">
								<div>
									<img className="four-hombres" src={battery1} alt="battery1" />
									<img className="four-hombres" src={battery2} alt="battery2" />
									<img className="four-hombres" src={battery3} alt="battery3" />
									<img className="four-hombres" src={battery4} alt="battery4" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Margin>

			<div style={{ width: "100%", display: "grid", placeItems: "center" }}>
				<AdService horizontal />
			</div>

			<Margin>
				<div className="home-row-4" style={_x.ad_oil}>
					<h2 className="home-subtitle">24hours streaming service</h2>

					<div className="home-row-4-row-1">
						<div className="home-row-4-row-1-b1">
							<h3 className="home-subtitle">
								Have a free tow when you use us for the first time
							</h3>
						</div>

						<div className="home-row-4-row-1-b2">
							<div className="h4 mb2">
								Share your Vehicle experience with #quickmechs
							</div>

							<div className="h6">connect and follow us</div>

							<div className="home-row-4-row-1-b3">
								<button className="social-icon">
									<img src={instagram} alt="instagram" />
								</button>

								<button className="social-icon">
									<img src={twitter} alt="facebook" />
								</button>

								<button className="social-icon">
									<img src={twitter} alt="twitter" />
								</button>
							</div>
						</div>
					</div>
				</div>
				<Helmet>
					<title>{"Spare Parts Shop - quickmechs Solutions"}</title>
					<meta
						name="description"
						content="At quickmechs we offer deluxe services 
												that give your product an extra advantage and increase brand exposure above 
												others, by granting you the opportunity to further create awareness, sales, 
												and traffic for your product."
					/>
				</Helmet>
			</Margin>

			<DownloadApp />

			<Footer useExplore />
		</>
	);
}

export default SparePartsShop;

const _x = {
	ad_column: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		padding: 10,
	},
	tire_header: {
		fontSize: 16,
		color: "var(--secondary1)",
		fontWeight: "bold",
		marginBottom: 10,
	},
	homerow_2: {
		margin: "4rem auto ",
		maxWidth: "1170px",
		justifyContent: "center",
	},
	ad_oil: {
		maxWidth: "1200px",
		margin: "3rem auto",
	},
	ad_oil_main: {
		maxWidth: "960px",
		margin: "3rem auto",
	},
};
