import React from "react";
import "./newpartssteps.css";
import { RiDeleteBin5Line } from "react-icons/ri";
function ShPhotoWrap({ onImageRemove, index, image, onClickDelete }) {
  return (
    <div key={index} className="sh_photo_wrap">
      <img
        src={image["data_url"]}
        alt="quickmechs-product-image"
        width="50"
        height="50"
      />
      <div
        className="st_delete"
        onClick={
         () => onImageRemove(index)
        }
      >
        <RiDeleteBin5Line />
      </div>

      {/*
                  </div> */}
    </div>
  );
}

export default ShPhotoWrap;
