import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import AuthService from '../../../services/auth.service';
import { authHeader } from '../../../services/auth-header';
import { useHistory } from 'react-router-dom';
import { IoClose, IoCopyOutline, IoClipboardOutline } from 'react-icons/io5';
import { MdModeEdit } from 'react-icons/md';
import ImageUploading from 'react-images-uploading';
import {
  AiOutlinePicture,
  AiOutlineEyeInvisible,
  AiOutlineEye,
} from 'react-icons/ai';
import { FiEdit3 } from 'react-icons/fi';
import './profile.css';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ImgDialog from './ImgDialog';
import getCroppedImg from './cropImage';
import { styles } from './styles';
import ProfileDP from 'src/Components/Functional/profileImage/ProfileDP';
import { Helmet } from 'react-helmet';
import LoadingBar from 'react-top-loading-bar';
import Webcam from 'react-webcam';
import Autocomplete from 'react-google-autocomplete';
import { FiArrowLeft } from 'react-icons/fi';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const Profile = ({ classes }) => {
  const cam = useRef(null);
  const webcamRef = React.useRef(null);
  const currentUser = AuthService.getCurrentUser('user');
  const activeUser = currentUser.data.user_info;
  const [isloading, setisloading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [profile_image, setProfile_image] = useState([]);
  const [oldImageShowcase, setOldImageShowcase] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropWrapper, setCropWrapper] = useState(false);
  const [btnUp, setBtnUp] = useState(false);
  const [dogImg, setDogImg] = useState('');
  const [newImage, setNewImage] = useState(false);
  const [emailError, setemailError] = useState(false);

  const [valid, setValid] = useState('');
  const [verified, setVerified] = useState(false);
  const [emailErrorMessage, setemailErrorMessage] = useState('');

  const [imageBox, setImageBox] = useState(false);
  const [oldProfile, setOldProfile] = useState(false);
  const [profileDetails, setProfileDetails] = useState('');
  const [showpassword, setshowpassword] = useState(false);
  const [showEditBox, setEditBox] = useState(false);
  const [showEditBoxLastName, setEditBoxLastName] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [message, setMessage] = useState('');
  const [format, setFormat] = useState('');
  const [pass, setPass] = useState(false);
  const [captured, setCaptured] = useState('');
  const [copied, setCopied] = useState(false);
  const [webcam, setwebcam] = useState(false);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [shoplocation, setshoplocation] = useState('');
  const [editPword, setEditPword] = useState(false);

  async function grabLoggedInUser() {
    // setisloading(true);
    setProgress(94);
    axios
      .get(API_URL + 'users/auth-user?fullDetails=true', {
        headers: authHeader(),
      })
      .then((resp) => {
        const fData = resp.data?.data.user_info;
        setInputs({
          ...inputs,
          first_name: fData.first_name,
          last_name: fData.last_name,
          email: fData.email,
          email_confirmation: fData.email,
          current_password: fData.password,
          password: '',
          password_confirmation: '',
          phone_number: fData.phone_number,
          address: fData.address,
          state: fData.state,
          city: fData.city,
          referral_code: fData.referral_code,
        });

        setProgress(0);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        setProgress(0);
      });
  }

  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    email: '',
    email_confirmation: '',
    current_password: '',
    password: '',
    password_confirmation: '',
    phone_number: '',
    address: '',
    state: '',
    city: '',
  });

  let history = useHistory();

  function togglePassword() {
    setshowpassword(!showpassword);
  }

  const handleUpdate = async (e) => {
    e.preventDefault();

    await AuthService.updateProfile(inputs)
      .then((response) => {
        console.log(response);
        setEditBox(false);
        setEditBoxLastName(false);
        setShowEmail(false);
        setShowPassword(false);
        setSuccess(true);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setFailure(true);
        setemailError(true);
        setemailErrorMessage(error.response.data.errorSource.email);

        setFormat(
          'Password must be 8 or more characters and must contain atleast one number, a capital letter and a symbol'
        );
        setMessage(resMessage);
      });
  };

  const getuserDetails = () => {
    axios
      .get(API_URL + 'users/current-user?fullDetails=true', {
        headers: authHeader(),
      })
      .then((response) => {
        const updatedDetails = response.data.data.user_info;
        setProfileDetails(updatedDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getuserDetails();
    grabLoggedInUser();
  }, []);

  const switchField = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const revealemail = () => {
    setShowEmail(true);
  };
  const revealpass = () => {
    setShowPassword(true);
    window.scrollTo(0, 0);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const maxSize = 10485760;

  const onChange = (imageList, addUpdateIndex) => {
    const loadedImage = imageList;
    let newArry = imageList.map((item) => item.data_url);
    let profilepix = newArry[0];
    setDogImg(profilepix);
    setOldImageShowcase(false);
    setCropWrapper(true);

    setImages(imageList);
  };
  const handleSubmit = (e) => {
    setisloading(true);
    setProgress(97);

    axios
      .post(
        API_URL + 'profile/upload-profile-image',
        { profile_image },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setisloading(false);
        setVerified(true);
        const finalImage = res.data.data.profile_image;

        let profileDp = JSON.parse(localStorage.getItem('user'));
        profileDp.data.user_info.profile_image = finalImage;
        localStorage.setItem('user', JSON.stringify(profileDp));
        setImageBox(false);
        window.location.reload();
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setValid('Something went wrong');
        setMessage(resMessage);
      });
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        dogImg,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      const finalcrop = croppedImage;
      setProfile_image(finalcrop);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setCaptured(imageSrc);
    setCroppedAreaPixels(imageSrc);
    setProfile_image(imageSrc);
  }, [webcamRef, setImgSrc]);

  const updateLocation = () => {
    setInputs({
      ...inputs,
      address: shoplocation,
    });
  };

  useEffect(() => {
    updateLocation();
  }, [shoplocation]);

  return (
    <>
      {!isloading && (
        <section className="account-wrapper">
          <h3
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: 30,
              paddingRight: 80,
              alignItems: 'center',
            }}
          >
            <FiArrowLeft
              style={{ cursor: 'pointer' }}
              size={25}
              onClick={() => history.goBack()}
              color="black"
            />
            <span> My Account </span>
            <div />
          </h3>
          <div className="profile-container">
            <div
              className="user-account-image"
              onClick={() => {
                setImageBox(true);
                setBtnUp(true);
                setOldProfile(true);
              }}
            >
              <ProfileDP
                style={{ width: '70px', height: '70px' }}
                size={{ fontSize: '2.5rem' }}
              />
            </div>
            <p
              className="profile-edit-pen"
              onClick={() => {
                setImageBox(true);
                setBtnUp(true);
                setOldProfile(true);
              }}
            >
              <MdModeEdit />
            </p>
          </div>
          <section className="profile-form">
            <div className="account-details">
              <p>
                <span>First Name</span>
              </p>
              <div className="account-inline-detail">
                <input
                  type="text"
                  onChange={switchField}
                  value={inputs.first_name}
                  name="first_name"
                />
              </div>
            </div>
            <div className="account-details">
              <p>
                <span>Last Name</span>
              </p>
              <div className="account-inline-detail">
                <input
                  type="text"
                  onChange={switchField}
                  value={inputs.last_name}
                  name="last_name"
                />
              </div>
            </div>
            <div className="account-details">
              <p>
                <span>Email Address</span>
              </p>

              <div className="account-inline-detail">
                <input
                  type="email"
                  onChange={switchField}
                  value={inputs.email}
                  name="email"
                />

                {/* <div className="edit-btn" onClick={revealemail}>
									Edit
								</div> */}
              </div>
            </div>

            <div className="account-details">
              <p>
                <span>Phone Number</span>
              </p>
              <div className="account-inline-detail">
                <input
                  type="text"
                  onChange={switchField}
                  value={inputs.phone_number}
                  name="phone_number"
                />
              </div>
            </div>

            <div className="account-details" style={{ width: '100%' }}>
              <p>
                <span>Country</span>
              </p>

              <Autocomplete
                defaultValue={inputs.address}
                className="autolocation"
                onPlaceSelected={(place) => {
                  const selectedAddress = place.formatted_address;
                  setshoplocation(selectedAddress);
                }}
                onChange={updateLocation}
                options={{
                  types: ['establishment', 'geocode'],
                }}
              />
            </div>

            <div className="account-details">
              <p>
                <span>Password</span>
              </p>

              <div className="account-inline-detail">
                {/* <div className="password-reveal">
									<input
										type="password"
										value='new-password"'
										readOnly
										placeholder="new-password"
									/>
								</div> */}

                <div
                  className="password-reveal passwordPopLabel"
                  style={{
                    width: 200,
                    borderRight: 'none',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <input
                    type={showpassword ? 'text' : 'password'}
                    onChange={switchField}
                    value={inputs.current_password}
                    name="password"
                    disabled={!editPword}
                    style={{ border: 'none' }}
                  />

                  <p
                    className="pw-display pw-new-display"
                    onClick={() => {
                      togglePassword();
                      setPass(!pass);
                    }}
                    style={{
                      border: '1px solid lightgrey',
                      height: 26.5,
                      width: 30,
                      borderLeft: 0,
                      display: 'grid',
                      placeItems: 'center',
                    }}
                  >
                    {pass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  </p>

                  <p
                    className="pw-display pw-new-display"
                    onClick={() => {
                      setEditPword(!editPword);
                    }}
                    style={{
                      border: '1px solid lightgrey',
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                      height: 26.5,
                      width: 30,
                      display: 'grid',
                      placeItems: 'center',
                    }}
                  >
                    <FiEdit3 />
                  </p>
                </div>

                {/* <div className="edit-btn" onClick={revealpass}>
									Edit
								</div> */}
              </div>
            </div>
            <div className="account-details">
              <p>
                <span>Referral Code</span>
              </p>
              <div className=" passwordTab">
                <div className="">
                  <input
                    style={{ fontWeight: '500', fontSize: '14px' }}
                    type="text"
                    value={inputs.referral_code}
                    readOnly
                    placeholder="new-password"
                  />
                </div>
                <div
                  style={{ fontSize: '14px' }}
                  className="edit-btn copy"
                  onClick={() => {
                    navigator.clipboard.writeText(inputs.referral_code);
                    setCopied(true);
                    setTimeout(function () {
                      setCopied(false);
                    }, 3000);
                  }}
                >
                  <IoCopyOutline />
                </div>
              </div>
            </div>

            <div className="btns account-details" style={{ marginTop: '1rem' }}>
              <button className="save" onClick={handleUpdate}>
                Save
              </button>
            </div>
          </section>
          {copied && (
            <div className="copy_notice">
              <IoClipboardOutline /> Copied to Clipboard
            </div>
          )}

          {showEmail ? (
            <section className="update-pop">
              <div className="firstname">
                <h4>Change your Email</h4>
                <label htmlFor="email">
                  Email
                  <input
                    type="text"
                    onChange={switchField}
                    value={inputs.email}
                    name="email"
                  />
                </label>
                <label htmlFor="email_confirmation">
                  Retype Email
                  <input
                    type="text"
                    onChange={switchField}
                    value={inputs.email_confirmation}
                    name="email_confirmation"
                  />
                </label>
                <div className="btns">
                  <button className="save" onClick={handleUpdate}>
                    Save
                  </button>
                  <button
                    className="cancel"
                    onClick={() => {
                      setShowEmail(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </section>
          ) : null}

          {showPassword ? (
            <section className="update-pop">
              <div className="firstname">
                <h4>Change Your Password</h4>
                <label htmlFor="password ">
                  Current Password
                  <div className="password-reveal passwordPopLabel">
                    <input
                      type={showpassword ? 'text' : 'password'}
                      onChange={switchField}
                      value={inputs.current_password}
                      name="current_password"
                    />
                    <p
                      className="pw-display pw-new-display"
                      onClick={() => {
                        togglePassword();
                        setPass(!pass);
                      }}
                    >
                      {pass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                    </p>
                  </div>
                </label>
                <label htmlFor="password">
                  New Password
                  <div className="password-reveal passwordPopLabel">
                    <input
                      type={showpassword ? 'text' : 'password'}
                      onChange={switchField}
                      value={inputs.password}
                      name="password"
                    />
                    <p
                      className="pw-display pw-new-display"
                      onClick={() => {
                        togglePassword();
                        setPass(!pass);
                      }}
                    >
                      {pass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                    </p>
                  </div>
                </label>
                {message && (
                  <div className="form-group">
                    <div className="alert-error" role="alert">
                      &#9432; {message}
                    </div>
                    <div className="alert-error" role="alert">
                      &#9432; {format}
                    </div>
                  </div>
                )}
                <label htmlFor="password_confirmation">
                  Retype New Password
                  <div className="password-reveal passwordPopLabel">
                    <input
                      type={showpassword ? 'text' : 'password'}
                      onChange={switchField}
                      value={inputs.password_confirmation}
                      name="password_confirmation"
                    />
                    <p
                      className="pw-display pw-new-display"
                      onClick={() => {
                        togglePassword();
                        setPass(!pass);
                      }}
                    >
                      {pass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                    </p>
                  </div>
                </label>
                <div className="btns">
                  <button className="save" onClick={handleUpdate}>
                    Save
                  </button>
                  <button
                    className="cancel"
                    onClick={() => {
                      setShowPassword(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </section>
          ) : null}
          {success ? (
            <section className="update-pop">
              <div className="firstname">
                <p
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    fontWeight: '500',
                    marginTop: '4rem',
                  }}
                >
                  Update Successful
                </p>

                <div className="btns">
                  <button
                    className="cancel"
                    onClick={() => {
                      setSuccess(false);
                      const firstNName = inputs.first_name;

                      let fname = JSON.parse(localStorage.getItem('user'));
                      fname.data.user_info.first_name = firstNName;
                      localStorage.setItem('user', JSON.stringify(fname));
                      setImageBox(false);

                      const oldMail = inputs.email;

                      let fmail = JSON.parse(localStorage.getItem('user'));
                      fmail.data.user_info.email = oldMail;
                      localStorage.setItem('user', JSON.stringify(fmail));

                      window.location.reload();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </section>
          ) : null}
          {failure ? (
            <section className="update-pop">
              <div className="firstname">
                <p
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    fontWeight: '500',
                    marginTop: '4rem',
                  }}
                >
                  Update was not Successful
                </p>
                {emailError && (
                  <div className="mail-error">&#9432; {emailErrorMessage}</div>
                )}
                <div className="btns">
                  <button
                    className="cancel"
                    onClick={() => {
                      setFailure(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </section>
          ) : null}
          {imageBox ? (
            <section
              className="imageUpload-container"
              style={{ height: '150vh' }}
            >
              <div className="imageUpload-wrapper">
                <div className="photo-ring">
                  {oldProfile ? (
                    <div className="old-image">
                      <ProfileDP
                        style={{ width: '250px', height: '250px' }}
                        size={{ fontSize: '10.5rem' }}
                      />
                    </div>
                  ) : null}

                  <ImageUploading
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <>
                        <div className="upload-col">
                          &nbsp;
                          <section className="image-list-colum">
                            {imageList.map((image, index) => (
                              <div className="image-item__btun-wrapper"></div>
                            ))}
                          </section>
                          <div
                            className="image-upload-btn"
                            style={
                              isDragging
                                ? { color: 'red', width: '150px' }
                                : undefined
                            }
                            {...dragProps}
                          >
                            {btnUp && (
                              <p
                                style={{
                                  fontSize: '14px',
                                  padding: '0',
                                  margin: '0px 0 30px',
                                  borderRadius: '5px',
                                  backgroundColor: 'var(--primary1)',
                                  color: '#000',
                                  fontWeight: '700',
                                  border: '1px solid #000',
                                  cursor: 'pointer',
                                  padding: '5px 15px',
                                }}
                                onClick={() => {
                                  setwebcam(true);
                                  setBtnUp(false);
                                  setOldProfile(false);
                                }}
                              >
                                Take Photo
                              </p>
                            )}
                          </div>
                          {btnUp && (
                            <p
                              style={{
                                fontSize: '14px',
                                fontWeight: '500',
                                padding: '0',
                                margin: '10px 0 20px',
                                textAlign: 'center',
                              }}
                            >
                              or
                            </p>
                          )}
                          <div
                            className="image-upload-btn"
                            style={
                              isDragging
                                ? { color: 'red', width: '150px' }
                                : undefined
                            }
                            onClick={() => {
                              onImageUpload();
                              setBtnUp(false);
                              setOldProfile(false);
                            }}
                            {...dragProps}
                          >
                            {isDragging ? (
                              'Drop image here'
                            ) : (
                              <>
                                {btnUp ? (
                                  <>
                                    <AiOutlinePicture />
                                    <p
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        padding: '0',
                                        margin: '10px 0 10px',
                                      }}
                                    >
                                      Choose from Gallery
                                    </p>
                                  </>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                        {webcam && (
                          <div
                            className="newProfile-wrapper"
                            style={{ flexDirection: 'column' }}
                          >
                            <Webcam
                              style={{
                                marginTop: '5rem',
                                border: '2px solid var(--primary1)',
                              }}
                              width={'70%'}
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              mirrored={true}
                            />

                            <div>
                              <button
                                style={{
                                  fontSize: '14px',
                                  padding: '0',
                                  margin: '40px 0 30px',
                                  borderRadius: '5px',
                                  zIndex: '500',
                                  backgroundColor: 'var(--primary1)',
                                  color: '#000',
                                  fontWeight: '700',
                                  border: '1px solid #000',
                                  cursor: 'pointer',
                                  padding: '5px 15px',
                                }}
                                onClick={() => {
                                  capture();
                                  setwebcam(false);
                                  setCropWrapper(true);
                                }}
                              >
                                Capture
                              </button>
                            </div>
                          </div>
                        )}
                        {oldImageShowcase ? (
                          <div className="oldImage-wrapper">
                            <div className="oldProf">
                              <img
                                src={currentUser.data.user_info.profile_image}
                                alt=""
                              />
                            </div>
                            <button
                              className="main-upload-btn"
                              onClick={() => {
                                onImageUpload();
                                setBtnUp(false);
                              }}
                            >
                              Upload Photo
                            </button>
                          </div>
                        ) : null}
                        <>
                          {cropWrapper ? (
                            <div className="zoom-container">
                              <div className={classes.cropContainer}>
                                <Cropper
                                  image={dogImg || imgSrc}
                                  crop={crop}
                                  rotation={rotation}
                                  zoom={zoom}
                                  aspect={4 / 3}
                                  onCropChange={setCrop}
                                  onRotationChange={setRotation}
                                  onCropComplete={onCropComplete}
                                  onZoomChange={setZoom}
                                />
                              </div>
                              <div className={classes.controls}>
                                <div className={classes.sliderContainer}>
                                  <Typography
                                    variant="overline"
                                    classes={{ root: classes.sliderLabel }}
                                  >
                                    Zoom
                                  </Typography>
                                  <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    classes={{ root: classes.slider }}
                                    style={{ fontFamily: 'montserrat' }}
                                    onChange={(e, zoom) => setZoom(zoom)}
                                  />
                                </div>
                                <div className={classes.sliderContainer}>
                                  <Typography
                                    variant="overline"
                                    classes={{ root: classes.sliderLabel }}
                                  >
                                    Rotation
                                  </Typography>
                                  <Slider
                                    value={rotation}
                                    min={0}
                                    max={360}
                                    step={1}
                                    aria-labelledby="Rotation"
                                    classes={{ root: classes.slider }}
                                    onChange={(e, rotation) =>
                                      setRotation(rotation)
                                    }
                                  />
                                </div>
                              </div>
                              <div style={{ width: '100%', display: 'flex' }}>
                                <Button
                                  onClick={() => {
                                    showCroppedImage();
                                    setCropWrapper(false);
                                    setBtnUp(false);
                                    setNewImage(true);
                                  }}
                                  variant="contained"
                                  className="upload_btn_profile"
                                  classes={{ root: classes.cropButton }}
                                >
                                  Done
                                </Button>
                              </div>
                              <ImgDialog img={croppedImage} onClose={onClose} />
                            </div>
                          ) : null}
                          {newImage ? (
                            <>
                              <div className="newProfile-wrapper">
                                <div className="oldProf">
                                  <img
                                    src={croppedImage || imgSrc}
                                    alt="profile-photo"
                                  />

                                  <button
                                    className="new-upload-btn"
                                    style={{
                                      bottom: -50,
                                    }}
                                    onClick={() => {
                                      handleSubmit();
                                      setBtnUp(false);
                                    }}
                                  >
                                    Confirm
                                  </button>

                                  <button
                                    className="new-upload-btn"
                                    style={{
                                      bottom: -110,
                                      width: 105,
                                    }}
                                    onClick={() => {
                                      setwebcam(true);
                                      setBtnUp(false);
                                      setOldProfile(false);
                                      setNewImage(false);
                                    }}
                                  >
                                    Retake
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </>
                      </>
                    )}
                  </ImageUploading>
                  <div className="updat-btn-wrap">
                    <button
                      className="cancel-main-box"
                      onClick={() => {
                        setImageBox(false);
                        setBtnUp(false);
                        setNewImage(false);
                        setOldImageShowcase(false);
                        setOldProfile(false);
                        setwebcam(false);
                      }}
                    >
                      <IoClose />
                    </button>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          <Helmet>
            <title>
              {currentUser.data.user_info.first_name +
                ' ' +
                currentUser.data.user_info.last_name +
                ' - Profile'}
            </title>
          </Helmet>
        </section>
      )}
      {isloading && (
        <LoadingBar
          color="#587FC0"
          loaderSpeed={4000}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      )}
    </>
  );
};

export default withStyles(styles)(Profile);
