import { Paper } from "@material-ui/core";
import React from "react";
import { Align, Spacing } from "../../../Components/Style/Styles";
import Footer from "../../../Components/Functional/Footer/Footer";
import DownloadApp from "src/Components/Functional/DownloadApp/DownloadApp";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./privacy.css";

function PrivacyPolicy() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<div className="privacy">
			<div style={_mobile ? {} : { marginTop: 50, marginBottom: 45 }}>
				<Align verticalHeight={_mobile ? 0 : 10}>
					<Paper style={_x(_mobile).privacyPolicy} elevation={_mobile ? 0 : 1}>
						<h1 class="c16" id="h.o57rgzsimwia">
							<span class="c12">Privacy Policy</span>
						</h1>
						<p class="c9">
							<span>Updated: 05/06/2023</span>
						</p>
						<hr />
						<p class="c8">
							<span class="c0"></span>
						</p>
						<h2 class="c15" id="h.z7hmas2a7glb">
							<span class="c1">Introduction</span>
						</h2>
						<p class="c9">
							<span class="c0">
								quickmechs herein referred to as &ldquo;we&rdquo;
								&ldquo;us&rdquo; or &ldquo;our&rdquo; is a company that deals
								mainly in automobile-related fields, including automobile
								repairs, sales of spare parts, towing services, etc., is
								committed to protecting your privacy and building technology
								that gives you the most powerful, unique and safe online
								experience.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								This privacy statement applies to how the quickmechs.com website
								collects and utilizes site users&rsquo; (referred to as
								&ldquo;you&rdquo;, &ldquo;your&rdquo; &ldquo;and user&rdquo;)
								data and personal information. By using our website, you consent
								to the collection and use of your data according to this policy.
							</span>
						</p>
						<hr />
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c13">
							<span class="c11 c18">Table of Content</span>
						</p>
						<ol class="c3 lst-kix_ojrgw9saa5c3-0 start" start="1">
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.4nwjl4hcrb0m">
										Collecting Your Personal Information
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.chcjync6gra3">
										The Data We Collect
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.9iunf7mhrdwu">
										How we use your data
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.35xm82hd9dji">
										Who We Share Your Data With
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.jt8im6rrgdjt">
										Cookies
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.fko9b1z1jhc5">
										Google DoubleClick DART Cookie
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.wz1d1giv4guj">
										How we protect your data
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.v28qkhgieymj">
										Advertising Partners Privacy Policies
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.n08ovs7pfrbd">
										Third Party Privacy Policies
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.uymthbjjp4v7">
										Links To Other Websites/Third-Party Websites
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.qnott0g4mjrb">
										Rights to Your Personal Information
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.psz7fd4z138e">
										Age Limitation
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.oqkrujph977i">
										Law Enforcement
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.1sw8qkd8rjsp">
										Changes To This Privacy Policy
									</a>
								</span>
							</li>
							<li class="c4 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.yvp5bv107utg">
										Compliance
									</a>
								</span>
							</li>
							<li class="c17 li-bullet-0">
								<span class="c2">
									<a class="c5" href="#h.4ak2fo3anpzq">
										Contact Us
									</a>
								</span>
							</li>
						</ol>
						<hr style={{ pageBreakBefore: "always", display: "none;" }} />
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0 start" start="1">
							<li class="c7 li-bullet-0">
								<h2 id="h.4nwjl4hcrb0m" style={{ display: "inline" }}>
									<span class="c1">Collecting Your Personal Information</span>
								</h2>
							</li>
						</ol>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								While using our Site, we may ask you to provide us with certain
								personally identifiable information that can be used to contact
								or identify you. Personally Identifiable Information (PII) or
								Personal Information. This personal information comprises your
								name, email address, and phone number. At the point of
								collection, the reasons for requesting these details will be
								made clear to you.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								We may also collect your relevant payment or credit card
								information if you wish to pay for any services offered on our
								platform. Please be aware that all payment information shall be
								stored and processed by our third-party payment processors.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								For business owners or vendors, the information we collect
								includes your name, company name, address, email address, and
								telephone number.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								In cases where you contact us directly, we may also keep a
								record of the contents of the message and/or attachments you may
								send us, and any other information you may choose to provide.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="2">
							<li class="c7 li-bullet-0">
								<h2 id="h.chcjync6gra3" style={{ display: "inline" }}>
									<span class="c1">The Data We Collect</span>
								</h2>
							</li>
						</ol>
						<p class="c9">
							<span>Like many site operators, </span>
							<span>quickmechs</span>
							<span class="c0">
								&nbsp;collects information that your browser sends whenever you
								visit our Site (&quot;Log Data&quot;).
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								This Log Data may include information such as your
								computer&#39;s Internet Protocol (&quot;IP&quot;) address,
								Internet Service Provider (ISP), browser type, browser version,
								the pages of our Site that you visit, the time and date of your
								visit, time spent on those pages, and other statistics.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								We may also aggregate anonymous data such as statistical or
								demographic data for any purpose. Anonymous data refers to data
								that does not identify you as an individual. Aggregated data may
								be derived from your personal data but is not considered
								personal information in law because it does not reveal your
								identity.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								The purpose of the information is for analyzing trends,
								administering the site, tracking users&#39; movement on the
								website, and gathering demographic information.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								In addition, we may use third-party services such as Google
								Analytics that collect, monitor, and analyze data relevant to
								monitoring the performance of our website.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="3">
							<li class="c7 li-bullet-0">
								<h2 id="h.9iunf7mhrdwu" style={{ display: "inline" }}>
									<span class="c1">How we use your data</span>
								</h2>
							</li>
						</ol>
						<p class="c9">
							<span class="c0">
								Please note that we will not give out or sell your information
								without your consent. However, we may use your Personal
								Information in the following ways;
							</span>
						</p>
						<ul class="c3 lst-kix_cn7e7uyx2kwm-0 start">
							<li class="c6 li-bullet-0">
								<span class="c0">
									Provide, operate, and maintain our website
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									Improve, personalize, and expand our website
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									Understand and analyze how you use our website
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									Develop new products, services, features, and functionality
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">Find and prevent fraudulent activities</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">Process your requests</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									Record your comments or inquiries on our website.
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									Contact you with newsletters, marketing or promotional
									materials, and other information about our services
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									Process your transactions upon the events that you wish to
									make payments via the online mediums we provide on our website
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									Enable you to download content from our website.
								</span>
							</li>
						</ul>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="4">
							<li class="c7 li-bullet-0">
								<h2 id="h.35xm82hd9dji" style={{ display: "inline" }}>
									<span class="c1">Who We Share Your Data With</span>
								</h2>
							</li>
						</ol>
						<p class="c13">
							<span class="c0">
								quickmechs shares your information with our third-party partners
								or hired agents who perform functions and provide services to us
								subject to the obligations consistent with this Privacy Policy.
								Their use of your data is based on the condition that the third
								parties use your information only on our behalf and according to
								our instructions. We also share your data with:
							</span>
						</p>
						<ul class="c3 lst-kix_xcq9vqe6mfuy-0 start">
							<li class="c6 li-bullet-0">
								<span class="c0">
									Third-party service providers like cloud storage platforms.
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									Data analytics providers (Google Analytics)
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									Login service providers (Google, Facebook, Apple),
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">Online marketing service providers.</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									Email marketing platforms we use (MailChimp)
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">Payment services providers.</span>
							</li>
						</ul>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="5">
							<li class="c7 li-bullet-0">
								<h2 id="h.jt8im6rrgdjt" style={{ display: "inline" }}>
									<span class="c1">Cookies</span>
								</h2>
							</li>
						</ol>
						<p class="c13">
							<span class="c0">
								Cookies are files with a small amount of data, which may include
								an anonymous unique identifier. Cookies are sent to your browser
								from a website and stored on your computer&#39;s hard drive.
							</span>
						</p>
						<p class="c13">
							<span class="c0">
								They differentiate you from other users of the quickmechs site
								and help us monitor the movement of site users. Third parties
								including search engines, providers of measurement and analytics
								services, social media networks, and advertising companies that
								we use may also have access to these cookies.
							</span>
						</p>
						<p class="c13">
							<span class="c0">
								Like many sites, we use &quot;cookies&quot; to collect
								information. You can instruct your browser to refuse all cookies
								or to indicate when a cookie is being sent. However, if you do
								not accept cookies, you may not be able to use some portions of
								our Site.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="6">
							<li class="c7 li-bullet-0">
								<h2 id="h.fko9b1z1jhc5" style={{ display: "inline" }}>
									<span class="c1">Google DoubleClick DART Cookie</span>
								</h2>
							</li>
						</ol>
						<p class="c9">
							<span>
								Google is one of the third-party vendors on our site. It also
								uses cookies, known as DART cookies, to show ads to our site
								visitors based on their visit to www.quickmechs.com and other
								sites on the internet. However, you may choose to decline the
								use of DART cookies by visiting the Google ad and content
								network Privacy Policy
							</span>
							<span class="c2">
								<a
									class="c5"
									href="https://www.google.com/url?q=https://policies.google.com/technologies/ads&amp;sa=D&amp;source=editors&amp;ust=1660214907043873&amp;usg=AOvVaw0fXtBHjIk2sn03-keP3mDN"
								>
									here
								</a>
							</span>
							<span>. Also, </span>
							<span class="c0">
								Google allows its users to opt-out of its personalized ads and
								to exempt their data from being used by Google Analytics.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="7">
							<li class="c7 li-bullet-0">
								<h2 id="h.wz1d1giv4guj" style={{ display: "inline" }}>
									<span class="c1">How we protect your data</span>
								</h2>
							</li>
						</ol>
						<p class="c9">
							<span class="c0">
								We would like you to know that we prioritize the security and
								privacy of your data. As a result, we take the following
								measures to keep them safe;
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<ul class="c3 lst-kix_7514hczglmhr-0 start">
							<li class="c6 li-bullet-0">
								<span class="c0">
									We use physical and electronic safeguards like SSL
									certificates to enhance our platform security and prevent
									unauthorized access to our systems.
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span>
									We restrict access to personal information to the employees
									and contractors of
								</span>
								<span>quickmechs</span>
								<span class="c0">
									&nbsp;who need the information to perform their assigned
									tasks.
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c0">
									We employ measures to ensure that anyone with this access is
									under strict confidentiality contract agreements with us and
									will be penalized if they go against any provisions of this
									privacy policy.
								</span>
							</li>
						</ul>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								The security of your Personal Information is important to us,
								but you must note that no method of transmission over the
								Internet, or method of electronic storage, is 100% secure. So,
								while we strive to use commercially acceptable means to protect
								your Personal Information, we cannot guarantee its absolute
								security.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								For this reason, we recommend that you use anti-virus software,
								firewalls, and other precautionary measures to protect yourself
								from security and privacy threats.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>

						<ol class="c3 lst-kix_eanx224riv1t-0" start="8">
							<li class="c7 li-bullet-0">
								<h2 id="h.4ak2fo3anpzq" style={{ display: "inline" }}>
									<span class="c1">How to Manage your account Information</span>
								</h2>
							</li>
						</ol>
						<p class="c9">
							<span>
								You have the right to delete your account at any time, subject
								to our verification process and any applicable legal or
								regulatory requirements. To delete your account, please contact
								us at [contact@quickmechs.com] or follow the steps outlined in
								your account settings.
							</span>
							<p>Steps to permanently delete your account on Quickmechs</p>
							<span class="c2">
								<ol class="c3 lst-kix_eanx224riv1t-0" start="1">
									<li class="c7 li-bullet-0">
										<p id="h.4ak2fo3anpzq">
											<span class="c1">
												Download the Quickmechs Application.
											</span>
										</p>
									</li>
									<li class="c7 li-bullet-0">
										<p id="h.4ak2fo3anpzq"></p>
										<p id="h.4ak2fo3anpzq">
											<span class="c1">Go to Account</span>
										</p>
									</li>
									<li class="c7 li-bullet-0">
										<p id="h.4ak2fo3anpzq"></p>
										<p id="h.4ak2fo3anpzq" style={{ display: "inline" }}>
											<span class="c1">How can we help you</span>
										</p>
									</li>
									<li class="c7 li-bullet-0">
										<p id="h.4ak2fo3anpzq"></p>
										<p id="h.4ak2fo3anpzq" style={{ display: "inline" }}>
											<span class="c1">Manage Account</span>
										</p>
									</li>
									<li class="c7 li-bullet-0">
										<p id="h.4ak2fo3anpzq"></p>
										<p id="h.4ak2fo3anpzq" style={{ display: "inline" }}>
											<span class="c1">Click on Delete Account</span>
										</p>
									</li>
									<li class="c7 li-bullet-0">
										<p id="h.4ak2fo3anpzq"></p>
										<p id="h.4ak2fo3anpzq" style={{ display: "inline" }}>
											<span class="c1">
												Give reasons why you want your account deleted.
											</span>
										</p>
									</li>
									<li class="c7 li-bullet-0">
										<p id="h.4ak2fo3anpzq"></p>
										<p id="h.4ak2fo3anpzq" style={{ display: "inline" }}>
											<span class="c1">Proceed</span>
										</p>
									</li>
								</ol>
							</span>
							<p>
								Please note that once you delete your account, all of your data
								and information associated with your account will be permanently
								deleted and cannot be recovered. This includes your personal
								information, payment information, and any other data or content
								you have shared or created on Quickmechs.
							</p>
							<p>
								Please note that once you delete your account, all of your data
								and information associated with your account will be permanently
								deleted and cannot be recovered. This includes your personal
								information, payment information, and any other data or content
								you have shared or created on Quickmechs.
							</p>
							<p>
								If you have any outstanding obligations or disputes with
								Quickmechs, such as outstanding payments or unresolved issues
								with other users, we reserve the right to retain your
								information and data as necessary to fulfill our legal
								obligations, protect our rights or the rights of others, and
								resolve any disputes.
							</p>
							<p>
								We may also retain certain information and data for our
								legitimate business purposes, such as maintaining our records
								and analyzing our user base and services. However, we will
								ensure that any retained data is de-identified and anonymized to
								the extent possible to protect your privacy.
							</p>
							<p>
								Please note that we may also retain certain data and information
								as required by law or to comply with our legal obligations, such
								as tax or accounting requirements.
							</p>
							<p>
								If you have any questions or concerns about our account deletion
								process or data retention practices, please contact us at
								[contact@quickmechs.com].
							</p>
						</p>

						<ol class="c3 lst-kix_eanx224riv1t-0" start="9">
							<li class="c7 li-bullet-0">
								<h2 id="h.v28qkhgieymj" style={{ display: "inline" }}>
									<span class="c1">Advertising Partners Privacy Policies</span>
								</h2>
							</li>
						</ol>
						<p class="c9">
							<span class="c0">
								Third-party ad servers or ad networks use technologies like
								cookies, JavaScript, or Web Beacons that are used in their
								respective advertisements and links that appear on
								quickmechs.com which are sent directly to users&#39; browsers.
								They automatically receive your IP address when this occurs.
								These technologies are used to measure the effectiveness of
								their advertising campaigns and/or to personalize the
								advertising content that you see on websites that you visit.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								Note that quickmechs has no access to or control over these
								cookies that are used by third-party advertisers.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="10">
							<li class="c7 li-bullet-0">
								<h2 id="h.n08ovs7pfrbd" style={{ display: "inline" }}>
									<span class="c1">Third Party Privacy Policies</span>
								</h2>
							</li>
						</ol>
						<p class="c9">
							<span class="c0">
								quickmechs&#39;s Privacy Policy does not apply to other
								advertisers or websites. As a result, we suggest that you review
								the individual Privacy Policies of these third-party ad servers
								for more information. It might also contain information on their
								procedures and how to decline certain options.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								You can choose to disable cookies through your browser options.
								To know more detailed information about cookie management with
								specific web browsers, it can be found on the browsers&#39;
								respective websites. Alternatively, you may opt-out of seeing
								some of these ads by visiting the platforms below:
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<ul class="c3 lst-kix_arr34jfekkv6-0 start">
							<li class="c6 li-bullet-0">
								<span>Network Advertising Initiative - </span>
								<span class="c2">
									<a
										class="c5"
										href="https://www.google.com/url?q=http://optout.networkadvertising.org/&amp;sa=D&amp;source=editors&amp;ust=1660214907046354&amp;usg=AOvVaw0lT3S98btOYkCk1IJB3dJf"
									>
										http://optout.networkadvertising.org/
									</a>
								</span>
								<span>&nbsp;</span>
							</li>
							<li class="c6 li-bullet-0">
								<span>Digital Advertising Alliance - </span>
								<span class="c2">
									<a
										class="c5"
										href="https://www.google.com/url?q=http://optout.aboutads.info/&amp;sa=D&amp;source=editors&amp;ust=1660214907046853&amp;usg=AOvVaw0gWqsCzBeNhMCfvrIW_WCI"
									>
										http://optout.aboutads.info/
									</a>
								</span>
								<span>&nbsp;</span>
							</li>
							<li class="c6 li-bullet-0">
								<span>DAA AppChoices page - </span>
								<span class="c2">
									<a
										class="c5"
										href="https://www.google.com/url?q=http://www.aboutads.info/appchoices&amp;sa=D&amp;source=editors&amp;ust=1660214907047270&amp;usg=AOvVaw3LRMpieGbp9k0xae74RWJZ"
									>
										http://www.aboutads.info/appchoices
									</a>
								</span>
								<span class="c0">&nbsp;</span>
							</li>
						</ul>
						<p class="c13">
							<span>
								You can also adjust your ad settings and preferences on relevant
								social media platforms like Facebook, Instagram, and Twitter.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="11">
							<li class="c7 li-bullet-0">
								<h2 id="h.uymthbjjp4v7" style={{ display: "inline" }}>
									<span class="c1">
										Links To Other Websites/Third-Party Websites
									</span>
								</h2>
							</li>
						</ol>
						<p class="c9">
							<span class="c0">
								Our website may contain links that will allow you to quickly
								access other websites of interest. However, you should note that
								we have no control over this other website. As a result, we
								cannot guarantee the security or privacy of any information you
								provide on these other sites as these sites are not covered by
								quickmechs&rsquo;s privacy statement. Therefore, you should
								observe your due diligence and carefully read the privacy policy
								for the website in question.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="12">
							<li class="c7 li-bullet-0">
								<h2 id="h.qnott0g4mjrb" style={{ display: "inline" }}>
									<span class="c1">Rights to Your Personal Information</span>
								</h2>
							</li>
						</ol>
						<p class="c13">
							<span class="c0">
								We would like to make sure you are fully aware of all of your
								data protection rights. Every user is entitled to the following:
							</span>
						</p>
						<p class="c13 c19">
							<span class="c0"></span>
						</p>
						<ul class="c3 lst-kix_u7it4npkzi8a-0 start">
							<li class="c6 li-bullet-0">
								<span class="c11">The right to access</span>
								<span class="c0">
									&nbsp;&ndash; You have the right to request copies of your
									personal data. We may charge you a small fee for this service.
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c11">The right to rectification</span>
								<span class="c0">
									&nbsp;&ndash; You have the right to request that we correct
									any information you believe is inaccurate. You also have the
									right to request that we complete the information you believe
									is incomplete.
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c11">The right to erasure</span>
								<span class="c0">
									&nbsp;&ndash; You have the right to request that we erase your
									personal data, under certain conditions.
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c11">The right to restrict processing</span>
								<span class="c0">
									&nbsp;&ndash; You have the right to request that we restrict
									the processing of your personal data, under certain
									conditions.
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c11">The right to object to processing</span>
								<span class="c0">
									&nbsp;&ndash; You have the right to object to our processing
									of your personal data, under certain conditions.
								</span>
							</li>
							<li class="c6 li-bullet-0">
								<span class="c11">The right to data portability</span>
								<span class="c0">
									&nbsp;&ndash; You have the right to request that we transfer
									the data that we have collected to another organization, or
									directly to you, under certain conditions.
								</span>
							</li>
						</ul>
						<p class="c13">
							<span class="c0">
								If you make a request, we have one month to respond to you. If
								you would like to exercise any of these rights, please contact
								us.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="13">
							<li class="c7 li-bullet-0">
								<h2 id="h.psz7fd4z138e" style={{ display: "inline" }}>
									<span class="c1">Age Limitation</span>
								</h2>
							</li>
						</ol>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								Another part of our priority is adding protection for children
								while using the internet. We encourage parents and guardians to
								observe, participate in, and/or monitor and guide their online
								activity.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								quickmechs does not knowingly collect any Personal Identifiable
								Information from children under the age of 18. If you think that
								your child provided this kind of information on our website, we
								strongly encourage you to contact us immediately and we will do
								our best efforts to promptly remove such information from our
								records.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="14">
							<li class="c7 li-bullet-0">
								<h2 id="h.oqkrujph977i" style={{ display: "inline" }}>
									<span class="c1">Law Enforcement</span>
								</h2>
							</li>
						</ol>
						<p class="c13">
							<span class="c0">
								You agree that we may reveal your information to authorities if
								compelled to by a court order. We may also disclose your
								information if we believe you have violated the laws of the US,
								the terms of our Privacy policy, or at the notice of the risk of
								a third party. On such occasions, we may notify you before we
								release such information.
							</span>
						</p>
						<p class="c13">
							<span class="c0">
								However, we may also disclose your information if there arises
								an urgent need for it by United States Law Enforcements, at the
								request of any state which has jurisdiction over our operations
								and Platforms. You hereby release us from any damages which may
								arise from or related to the release of your personal
								information upon orders received from law enforcement agencies
								and litigants whether they are individuals or entities, be it
								State or Federal authorities.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="15">
							<li class="c7 li-bullet-0">
								<h2 id="h.1sw8qkd8rjsp" style={{ display: "inline" }}>
									<span class="c1">Changes To This Privacy Policy</span>
								</h2>
							</li>
						</ol>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								This Privacy Policy is effective as of 9th August 2022 and will
								remain in effect except concerning any changes in its provisions
								in the future, which will be in effect immediately after being
								posted on this page.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								We reserve the right to update or change our Privacy Policy at
								any time and you should check this Privacy Policy periodically.
								Your continued use of the Service after we post any
								modifications to the Privacy Policy on this page will constitute
								your acknowledgment of the modifications and your consent to
								abide and be bound by the modified Privacy Policy.
							</span>
						</p>
						<p class="c8">
							<span class="c0"></span>
						</p>
						<p class="c9">
							<span class="c0">
								If we make any material changes to this Privacy Policy, we will
								notify you either through the email address you have provided us
								or by placing a prominent notice on our website.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="16">
							<li class="c7 li-bullet-0">
								<h2 id="h.yvp5bv107utg" style={{ display: "inline" }}>
									<span class="c1">Compliance</span>
								</h2>
							</li>
						</ol>
						<p class="c9">
							<span class="c0">
								This Privacy Policy is reviewed regularly to ensure that we
								process your information in a manner that complies with the
								conditions stated herein.
							</span>
						</p>
						<ol class="c3 lst-kix_eanx224riv1t-0" start="17">
							<li class="c7 li-bullet-0">
								<h2 id="h.4ak2fo3anpzq" style={{ display: "inline" }}>
									<span class="c1">Contact Us</span>
								</h2>
							</li>
						</ol>
						<p class="c9">
							<span>
								We welcome your feedback regarding this Privacy Statement.
								Please contact us via email
							</span>
							<span class="c2">
								<a class="c5" href="mailto:atsupport@quickmechs.com">
									support@quickmechs.com
								</a>
							</span>
							{/* <span class="c0">&nbsp;or call 09134444776</span> */}
						</p>
					</Paper>
				</Align>
			</div>

			<DownloadApp />

			<Footer />
		</div>
	);
}

export default PrivacyPolicy;

const _x = (_mobile) => ({
	privacyPolicy: {
		background: "white",
		width: _mobile ? "100%" : 860,
		padding: 30,
		paddingLeft: 30,
		paddingRight: 30,
	},

	text1: {
		fontSize: 18,
		fontWeight: "bold",
		color: "var(--secondary1)",
		marginTop: 40,
	},

	text2: {
		color: "#545C70",
		fontSize: 13,
		lineHeight: 2,
	},

	text3: {
		color: "var(--primary1)",
		fontWeight: "bold",
		fontSize: 18,
	},

	text4: {
		color: "var(--primary1)",
		fontSize: 13,
		lineHeight: 2,
		textDecoration: "underline",
		cursor: "pointer",
	},

	text5: {
		color: "var(--primary1)",
		fontSize: 13,
		lineHeight: 2,
		cursor: "pointer",
	},

	text6: {
		background: "yellow",
		marginLeft: 5,
	},
});
