import React, { useState } from 'react';
import moment from 'moment';
import { useLocation, useHistory, Link } from 'react-router-dom';
import camera from '../../../Resources/Images/camera.png';
import {
  RiEditLine,
  RiDeleteBin6Line,
  RiCloseCircleLine,
  RiMenuFill,
  RiCameraFill,
} from 'react-icons/ri';
import { MdAutorenew } from 'react-icons/md';
import Rating from '../../../Routes/Home/ReviewMechanic/Rating';
import '../../../Routes/Home/seller/dashboard.css';
import { RatingStar } from 'rating-star';
import StarRatingComponent from 'react-star-rating-component';

const DealerSingleAd = ({
  image,
  title,
  image_number,
  product_no,
  price,
  date,
  adLink,
  editLink,
  views,
  deleteLink,
  deactivateLink,
  deactivateText,
  deactivateIcon,
  activateLink,
  activateIcon,
  activateText,
  pricing,
  checking,
  statusCheck,
  statusCheck2,
  pricestyle,
  pricestyle2,
  loadItemReview,
  overallRating,
  rating,
  notice,
  phone_views,
}) => {
  const [display, setDisplay] = useState(false);
  const [BgDisplay, setBgDisplay] = useState(false);

  const hideMenu = () => {
    setDisplay(false);
  };

  return (
    <>
      {BgDisplay ? (
        <div
          className="editPanelBg"
          onClick={() => {
            hideMenu();
            setBgDisplay(false);
          }}
        ></div>
      ) : (
        ''
      )}
      <article style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="product-container dealer_prod">
          <div className="prd-image">
            <img src={image} alt={title} />
          </div>
          <div className="single-product-detail">
            <Link
              to={`/home/spare-parts/${adLink}`}
              id="adlink-col"
              style={pricestyle}
            >
              <div className="pr-name pr-item" id="main-pr-title">
                {title}
              </div>
            </Link>
            <div className="pr-numb  pr-item" style={pricestyle2}>
              {product_no}
            </div>
            <div className="pr-price pr-item" style={pricestyle}>
              $ {price}
            </div>
            {/* <Link to={`/home/spare-parts/${adLink}`} id = "adlink-col"
										 style ={pricestyle} > */}

            {/* </Link> */}
            <div className="pr-date  pr-item" style={pricestyle2}>
              Created {moment(date).format('LL')}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default DealerSingleAd;
