import { useMediaQuery } from '@material-ui/core';
import React, { useEffect,useState } from 'react'
import { useHistory } from 'react-router-dom';
import Container from './sparePartComp/Container';
import { Divider } from '@mui/material';
import TitleSection from './sparePartComp/TitleSection';
import EditShopSection from './sparePartComp/EditShopSection';
import ActiveUserAds from './ActiveUserAds';

function AuthUserSparPartPageOnMobilePage(props) {
const history = useHistory()
	 const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 800px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(max-width: 800px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  useEffect(()=>{
    if(!matches){
      history.push('/home/dashboard')
    }
  },[matches])
  return (
    <Container>
    <TitleSection/>
    <EditShopSection onClick={()=>history.push('/home/update-dealer-details')}/>
    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'6%'}}>
      <ActiveUserAds/>
    </div>
    </Container>
  )
}


export default AuthUserSparPartPageOnMobilePage
