import React from "react";
import "./PaymentSuccess.scss";
import { Button, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";

function PaymentSuccess() {
  const history = useHistory();
  const matches = useMediaQuery("(max-width:1000px)");
  return (
    <div className="payment-success-container">
      <img
        src={require("../../../Resources/Images/success.ill.svg").default}
        alt=""
      />
      <div className="txt-success-pay-wrap">
        <label for="title">Congratulations</label>
        <span>You have successfully posted a Job opening</span>
      </div>
      <div className="success-page-button-container">
        <Button
          onClick={() => history.push("/home/post-job")}
          sx={{ ...styles.postAnother, width: matches ? "90%" : 200 }}
          variant="contained"
        >
          Post another Job
        </Button>
        <Button
          onClick={() => history.push("/home/myjob")}
          sx={{ ...styles.goToMyJob, width: matches ? "90%" : 200 }}
          variant="contained"
        >
          Go To My Jobs
        </Button>
      </div>
    </div>
  );
}
const styles = {
  postAnother: {
    background: "#587FC0",
    borderRadius: "22px",
    color: "#fff",
    width: 200,
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
      boxShadow: "none",
      border: " 3px solid #587FC0",
      color: "#587FC0",
    },
  },
  goToMyJob: {
    borderRadius: "22px",
    border: " 3px solid #587FC0",
    background: "#fff",
    width: 200,
    color: "#587FC0",
    textTransform: "capitalize",
    "&:hover": {
      background: "#587FC0",
      boxShadow: "none",
      color: "#fff",
    },
  },
};
export default PaymentSuccess;
