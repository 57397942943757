import React, { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Box, Modal } from "@mui/material";
import CheckoutForm from "../../../Components/job/CheckoutForm";
import { getStripClientSecret } from "src/services/job/job.service";
import { useMediaQuery } from "@mui/material";

const stripePromise = loadStripe(
  "pk_test_51NXQoKHn72w1qg3g8Pb1OTEV8FuQsnAH7625wq0T1qcd7gsgA5dEo0VoyNCaiSqZ7ZgCvNWOtoXOQQSadatVQEj300h0evZwIh"
);

export default function StripPaymentPage({ ...props }) {
  const matches = useMediaQuery("(max-width:1000px)");
  const [clientSecrett, setClientSecret] = useState("");
  const getClientSecret = async () => {
    try {
      const clientSecret = await getStripClientSecret();
      if (clientSecret.status === 200 || clientSecret.status === 201) {
        setClientSecret(clientSecret.data.data);
        console.log(clientSecret.data.data);
      } else {
        console.error(clientSecret.data, clientSecret.status);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getClientSecret();
  }, []);
  const handleClose = () => props.close(false);

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: matches ? "90%" : 500 }}>
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecrett,
          }}
        >
          <CheckoutForm clientSecret={clientSecrett} />
        </Elements>
      </Box>
    </Modal>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: 5,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 4,
};
