import { useMediaQuery } from "@material-ui/core";
import React from "react";
import Map from "src/Components/Functional/Map/Map";

export default function Playground() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<div style={_x().playground}>
			<p style={_x().title}>Playground</p>

			<Map isMarkerShown labelVisible />
		</div>
	);
}

const _x = (_mobile) => ({
	playground: {
		height: "100vh",
		width: "100vw",
		// display: "grid",
		// placeItems: "center",
		padding: 30,
		paddingInline: 80,
	},
	title: {
		fontWeight: "bold",
		fontSize: 30,
		marginBottom: 30,
	},
});
