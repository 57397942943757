import React from "react";
import { Align } from "../../../Components/Style/Styles";
import { Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function DownloadAppScreen() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<Align verticalHeight={_mobile ? 0 : 100}>
			<Paper style={_x(_mobile).download_app_screen}>
				<div style={_x(_mobile).text}>
					WE ARE CURRENTLY WORKING ON THE MOBILE APPLICATION.
				</div>

				<div style={_x(_mobile).text}>
					Youll be the first to get notified when the application is ready.
				</div>

				<div style={_x(_mobile).text}> STAY TUNED!</div>
			</Paper>
		</Align>
	);
}

const _x = (mobile) => ({
	download_app_screen: {
		background: "white",
		height: mobile ? "100%" : 400,
		minWidth: mobile ? "100%" : 1000,
		display: "grid",
		alignItems: mobile ? "flex-start" : "center",
		justifyContent: "center",
		padding: 50,
	},
	text: {
		fontSize: 30,
		color: "var(--primary)",
		marginBottom: 100,
		textAlign: "center",
		// fontWeight: "bold",
	},
});
