import { Paper } from "@material-ui/core";
import React from "react";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Align, Spacing } from "../../../Components/Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function Services() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			<Align verticalHeight={_mobile ? 0 : 20}>
				<Paper style={_x(_mobile).services} elevation={_mobile ? 0 : 1}>
					<div style={_x(_mobile).header}>
						<p style={_x().title}>Services</p>
					</div>

					<div style={_x(_mobile).main}>
						<p style={_x().text1}>
							Quickmechs is committed to helping car owners in the US find
							mechanics across different locations who can provide them with
							seamless car repair and maintenance services. Our categories of
							service providers and car dealers include:
						</p>

						<li style={_x().li}>Mechanics</li>
						<li style={_x().li}>Bodyworks</li>
						<li style={_x().li}>Car Air Conditioning</li>
						<li style={_x().li}>Electrical</li>
						<li style={_x().li}>Tire Replacement</li>
						<li style={_x().li}>Towing</li>
						<li style={_x().li}>Car Security</li>
						<li style={_x().li}>Car and Spare Parts Dealers</li>

						<Spacing />

						<div style={_x().text2}>Mechanics</div>
						<p style={_x().text1}>
							Quickmechs has highly-equipped mechanics that offer a full range
							of services which include:
						</p>

						<li style={_x().text1}>
							<span style={_x().subtitle}>General Repairs:</span>Experts are
							available on Quickmechs to take care of your vehicles, fix all
							issues and get them to the best working shape.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Computer Diagnosis:</span> Not every
							problem can be resolved or found by merely looking at a car. On
							Quickmechs, you can connect with professionals who will run proper
							diagnostics to identify critical issues your car might have.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Routine Servicing:</span> Routine
							maintenance is necessary for keeping your car systems up to date
							and ensuring great performance. Just like we go for regular
							medical check-ups, routine servicing of your car is also as
							important. And this should be done regularly, be it weekly,
							monthly, bi-annually, or yearly. On Quickmechs, you can get the
							best mechanics that offer regular servicing.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Engine Repair:</span> For car engine
							repair and maintenance, you’ll find mechanics who will check and
							ensure those vehicle components that affect your engine are also
							in good shape and fix any engine-related issues.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Brake Repair:</span> Brake is an
							integral part of a car and you must ensure your car brake is
							functioning perfectly, the sound, the responsiveness, and all must
							be up to par. Whether you suspect a brake problem or you just
							servicing at intervals, you can request an appointment right on
							Quickmechs.
						</li>
						<Spacing />

						<div style={_x().text2}>BodyWorks</div>
						<p style={_x().text1}>
							Everyone enjoys driving a gorgeous, unscratched vehicle. However,
							your car may need some bodily revamp at some point and this is
							where Quickmechs comes in. You’ll find bodyworks services like:
						</p>

						<li style={_x().text1}>
							<span style={_x().subtitle}>
								Body Part Repairs and Replacements:
							</span>
							How your car looks goes a long way in the total representation of
							you and it’s important to fix and replace bad body parts and
							scratches caused by accidents.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Remove Dents:</span>
							All minor or major dents fixing are done by professionals right
							here, and all dents can be removed from any spot in your car.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Install Windshields:</span>
							Installing and replacing windshield cracks or damage with a very
							strong adhesive one can also be professionally done. And with a
							click on Quickmechs, you can also get a glass cleaner, spaying,
							scrub, and other related services.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Painting Straighten:</span>
							Your old car can look almost new with a fresh paint job. Whether
							you’re dealing with rust spots, blemishes, or want to update the
							entire vehicle’s look, body part Mechanics got you. And be rest
							assured they will give you confidence when driving your car.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Metal Panel:</span>
							Rusty panels can be transformed into new ones. Your floor panels
							and entire car metal panel can all get fixed through Quickmechs.
						</li>
						<Spacing />

						<div style={_x().text2}>Car Air Conditioning</div>
						<p style={_x().text1}>
							Your car’s Ac regulates its temperature and humidity so it’s
							important for all your Ac components to work effectively. General
							air condition fixing for any car brand has been made available for
							you on Quickmechs.
						</p>

						<li style={_x().text1}>
							<span style={_x().subtitle}>Install:</span> Full placing of ACs in
							your new or old car can be done easily with help of professionals
							on Quickmechs.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Compressor:</span> Low temperature and
							pressure from your compressor determine the outcome. And once you
							realize issues you get them fixed.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Condenser:</span> This also ensures a
							great cooling experience. Qickmechs has professionals who will
							help you fix your condensers when necessary.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Fill Gas:</span> Enough refrigerant
							ensures a cool condition of your car. As soon as you notice
							declined performance you should get a refill done.
						</li>
						<Spacing />

						<div style={_x().text2}>Electrical</div>
						<p style={_x().text1}>
							Your car’s electrical system also plays an important role in how
							well your car performs. With professionals, the following issues
							can be fixed by professionals on Quickmechs:
						</p>

						<li style={_x().text1}>
							<span style={_x().subtitle}>Lighting System:</span> Your car
							lighting system gives you a proper view of the road and makes the
							vehicle more visible, so having a good/clear one is key. Every
							lighting system for the front, rear, sides, and in some cases on
							the top of a motor vehicle can be worked on.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Coil:</span> A faulty ignition coil
							can cause catastrophe for your car. Your ignition is covered with
							the help of experts on Quickmechs.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Fuse: </span>The fuse box is used to
							protect the electronics within your vehicle and it’s integral that
							it functions properly.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Fuel Pump:</span> The fuel pump should
							also function appropriately and distribute fuel to all connected
							sources in your call, if this is malfunctioning in your vehicle it
							should be fixed.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Re-wire:</span> Rewiring is also
							available.
						</li>
						<Spacing />

						<div style={_x().text2}>Tire Shop</div>
						<p style={_x().text1}>
							Ensuring all the tires in charge of your car movement are in good
							shape.
						</p>

						<li style={_x().text1}>
							<span style={_x().subtitle}>Flat Tire:</span> In cases of
							emergency when you have flat tires, fixing them at wherever
							location you are can be life-saving, and you can get them replaced
							by partners on Quickmechs.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Gauge Tire:</span> Same applies to
							getting your tires in good shape for uninterrupted movement.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Wheel Balancing:</span> This includes
							the safe and efficient operation of your vehicle, including
							control in the bad roads, weather, and generally ensuring good
							driving conditions, and long use of your tires.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Alignment Rotating:</span> Just as
							balancing, proper alignment will ensure car stability.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Tire Replacement:</span> Find
							professionals to replacement for overused, bad, and expired tires.
						</li>
						<Spacing />

						<div style={_x().text2}>Towing</div>
						<p style={_x().text1}>
							In case of sudden fault development or an accident, towing
							services will ensure your car gets moved to any location of your
							choice. This also applies to moving new vehicles from one spot to
							another.
						</p>

						<li style={_x().text1}>
							<span style={_x().subtitle}>Accident Car:</span> After an
							accident, if your car is not drivable it can be towed from the
							scene. And you can easily get such a service on Quickmechs.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Road Assistant:</span> In cases of
							sudden car breakdown, A road assistant will help you navigate
							through any particular city.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Transportation:</span>
							Towing service providers on Quickmechs can also help you move your
							car across the country.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Heavy Duty:</span>
							In moving large and bulky kinds of items, the service providers
							here will help you with moving from one spot to another.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Small duty:</span>
							Also, in moving small equipment, service providers on Quickmechs
							will assist in transferring from one spot to another.
						</li>
						<Spacing />

						<div style={_x().text2}>Security</div>
						<p style={_x().text1}>
							Protect your car from harm, theft, and Burglars.
						</p>

						<li style={_x().text1}>
							<span style={_x().subtitle}>Car Alarm:</span> With good car alarm
							systems, you’ll keep your car safe from theft-related issues.
							While many cars come with some kind of alarm system, others don’t
							and on Quickmechs, you can get someone to work on it for you.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Tracker:</span> If you use your
							vehicles for commercial or business purposes, you should use car
							trackers to monitor them. Quickmechs connects you with
							professionals who will install high-quality trackers in your
							vehicle.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>GPS:</span> For a good navigation
							system help is available, get a good and accurate mapping system
							for your vehicles.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Key Programmer:</span> For a smart key
							reprogramming or a fresh one, services are also available.
						</li>
						<Spacing />

						<li style={_x().text1}>
							<span style={_x().subtitle}>Camera:</span> For all kinds of
							cameras, you are also covered.
						</li>
						<Spacing />

						<div style={_x().text2}>Car and Spare Parts Dealers</div>
						<p style={_x().text1}>
							Quickmechs gives you access to certified dealers of all kinds of
							cars, trucks, SUVs, etc. in the US.
						</p>
					</div>
				</Paper>
			</Align>

			<DownloadApp />

			<Footer />
		</>
	);
}

export default Services;

const _x = (_mobile) => ({
	services: {
		maxWidth: _mobile ? "100%" : "1200px",
		background: "white",
		marginTop: _mobile ? 0 : 50,
		borderRadius: 10,
	},

	header: {
		background: "var(--primary1)",
		borderTopRightRadius: 10,
		borderTopLeftRadius: 10,
		display: "flex",
		flexDirection: "column",
		paddingInline: _mobile ? 50 : 200,
	},

	main: {
		paddingTop: 0,
		paddingBottom: 50,
		paddingInline: _mobile ? 50 : 200,
	},

	subtitle: {
		fontWeight: 500,
		marginRight: 8,
		lineHeight: 1.8,
		color: "var(--secondary1)",
	},

	title: {
		fontSize: 17,
		fontWeight: "bold",
		color: "white",
	},

	text1: {
		fontSize: 14,
		fontWeight: 400,
		lineHeight: 1.8,
	},

	text2: {
		fontSize: 18,
		fontWeight: 500,
		color: "var(--secondary1)",
		lineHeight: 1.8,
	},

	text3: {
		fontSize: 12,
		lineHeight: 1.8,
	},

	bullet: {},

	bullet_header: {
		color: "var(--primary1)",
		fontWeight: "bold",
		fontSize: 14,
	},

	li: {
		lineHeight: 1.8,
	},
});
