import React, { useState, useRef } from "react";
import axios from "axios";
import { Modal, Paper, useMediaQuery } from "@material-ui/core";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../../services/auth.service";
import { authHeader } from "../../../services/auth-header";
import { Link, useHistory, withRouter } from "react-router-dom";
import "../../../Routes/Auth/Login/login.css";
import reg1 from "../../../Resources/Icons/reg1.png";
import reg2 from "../../../Resources/Icons/reg2.png";
import reg3 from "../../../Resources/Icons/reg3.png";
import show from "../../../Resources/Icons/show.svg";
import hide from "../../../Resources/Icons/hide.svg";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import GoogleSignin from "../../../Routes/Auth/Login//GoogleSignin";
import FacebookSignIn from "../../../Routes/Auth/Login//FacebookSignIn";
import "./logpop.css";

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

const LoginPopUp = (props) => {
	const form = useRef();
	const checkBtn = useRef();
	const loadref = useRef(20);
	let history = useHistory();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState("");
	const [showpassword, setshowpassword] = useState(false);
	const [isloading, setisloading] = useState(false);
	const [success, setSuccess] = useState(false);
	const _responsive = useMediaQuery("(max-width:800px)");

	function togglePassword() {
		setshowpassword(!showpassword);
	}

	const onChangeEmail = (e) => {
		const email = e.target.value;
		setEmail(email);
	};

	const onChangePassword = (e) => {
		const password = e.target.value;
		setPassword(password);
	};

	const handleLogin = (e) => {
		e.preventDefault();

		setMessage("");
		setisloading(true);

		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			// loadref.current.continuousStart(93);
			AuthService.login(email, password).then(
				() => {
					setSuccess(true);

					props.onLogin("success");

					if (!props.noReload) {
						window.location.reload();
					}

					props.setpopup && props.setpopup(false);
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					props.onLogin("failed");

					// setLoading(false);
					// setisloading(false);

					// loadref?.current?.complete();

					setMessage("Incorrect Email or Password");
				}
			);
		}
	};

	return (
		<main className="login-wrapper popboxlog">
			<div className="login-col-wrap" style={_x(_responsive).form}>
				{success ? (
					<>
						<Spinner isloading={isloading} />
					</>
				) : (
					<>
						<div className="login-tittle">
							You must be logged in to use this feature
						</div>
						<span
							className="popLogBg"
							style={{
								paddingInline: 15,
								paddingTop: 8,
							}}
						>
							{props.children}
						</span>

						<Form className="reg-box-left" onSubmit={handleLogin} ref={form}>
							<div className="input-box space">
								<label htmlFor="email">Email Address / Phone Number</label>

								<Input
									type="text"
									className="form-control control-pop"
									name="email"
									value={email}
									onChange={onChangeEmail}
									validations={[required]}
									style={_x(_responsive).field}
								/>
							</div>

							<div className="input-box space ">
								<label htmlFor="password">Password</label>

								<div
									className="pass_field control-pop"
									style={_x(_responsive).field}
								>
									<Input
										type={showpassword ? "text" : "password"}
										className="form-control passs"
										name="password"
										value={password}
										onChange={onChangePassword}
										validations={[required]}
									/>

									<img
										alt="eye"
										src={showpassword ? hide : show}
										style={_x.eye}
										onClick={togglePassword}
									/>
								</div>
							</div>

							<Link
								to="/auth/forgot-password"
								style={{ textDecoration: "none", color: " #587FC0" }}
							>
								<div className="space-2">Forgot Password?</div>
							</Link>

							{message && (
								<div className="form-group">
									<div className="alert alert-danger" role="alert">
										&#9432; {message}
									</div>
								</div>
							)}

							<div className="form-group ">
								<button
									className="signin-btn control-pop"
									style={_x(_responsive).field}
								>
									<span>Sign In</span>
								</button>

								<p className="signUp-info">
									Don't have an account?
									<Link to="/auth/register"> Sign Up Now </Link>
								</p>
							</div>

							<CheckButton style={{ display: "none" }} ref={checkBtn} />
						</Form>

						<div className="reg-box-right" style={_x(_responsive).social}>
							<div>
								<GoogleSignin />

								<FacebookSignIn />

								<section className="login-details">
									<div style={_x().tiny}>
										<img style={_x().tiny_img} src={reg1} alt="tiny" />
										We keep it Private
									</div>

									<div style={_x().tiny}>
										<img style={_x().tiny_img} src={reg2} alt="tiny" />
										Share only with Permission
									</div>

									<div style={_x().tiny}>
										<img style={_x().tiny_img} src={reg3} alt="tiny" />
										Quick Sign in - No password
									</div>
								</section>
							</div>
						</div>
					</>
				)}
			</div>

			{/* <Spinner isloading={isloading} /> */}
		</main>
	);
};

export default LoginPopUp;

const _x = (_responsive) => ({
	modal: {
		outline: "none",
		height: "100%",
		display: "grid",
		placeItems: "center",
	},
	form: _responsive
		? {
				zIndex: 9999,
				marginTop: "10vh",
				paddingInline: 20,
		  }
		: {},
	field: _responsive
		? {
				width: "100%",
		  }
		: {},
	social: _responsive
		? {
				display: "grid",
				justifyContent: "center",
		  }
		: {},
	tiny_img: {
		objectFit: "contain",
		marginRight: 10,
	},
	tiny: {
		display: "flex",
		marginBottom: "11px",
		fontSize: "10px",
	},
	eye: {
		width: "20px",
		marginTop: "7px",
		height: "20px",
		cursor: "pointer",
		padding: "0 10px 0",
	},
});
