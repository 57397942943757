import Geocode from "react-geocode";

const GetLatLong = () => {
	Geocode.setApiKey("AIzaSyC8MxZB_qWnOrh1E4RnSE4rqDuMsCGIAek");
	Geocode.setLanguage("en");
	Geocode.setLocationType("ROOFTOP");

	const checkAddress = () => {
		Geocode.fromAddress("12 Admiralty way Lekki Lagos").then(
			(response) => {
				const { lat, lng } = response.results[0].geometry.location;
				console.log(lat, lng);
			},
			(error) => {
				console.error(error);
			}
		);
	};

	// using async
	// for passing it as a function
	// async getCoordinates() {
	//     const response = await Geocode.fromAddress('Denver United States');
	//     const {lat, lng} = response.results[0].geometry.location;
	//     const dataObj = {
	//       latitude: lat,
	//       longitude: lng,
	//     };
	//     console.log(dataObj);
	//   }

	return (
		<div>
			Map Box Goes here
			<button onClick={checkAddress}>Click to check</button>
		</div>
	);
};

export default GetLatLong;
