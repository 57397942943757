import { Paper } from '@material-ui/core';
import React from 'react';
import { Align, Spacing } from 'src/Components/Style/Styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Footer from 'src/Components/Functional/Footer/Footer';

function TermsAndConditions() {
  const _mobile = useMediaQuery('(max-width:800px)');

  return (
    <>
      <Align verticalHeight={_mobile ? 0 : null}>
        <Paper style={_x(_mobile).tAndC} elevation={_mobile ? 0 : 1}>
          <div>
            <h3 style={_x(_mobile).header}>TERMS OF SERVICE</h3>
          </div>

          <section className="card-content" style={_x(_mobile).main}>
            <li style={_x().text2}>
              This term of service is a contract between you (‘you’or‘user’) and
              quickmechs or (‘quickmechs’, ‘administrator’, ‘site’, ‘we’or‘us’)
              and to the extent expressly stated by our subsidiaries and
              aliates.
            </li>

            <Spacing />

            <li style={_x().text2}>
              You agree to read and accept all of the terms and conditions
              contained in this agreement in order to use our website located at
              <span>https://www.quickmechs.com</span>, including mobile websites
              and applications, owned and operated by us or our subsidiaries and
              affiliates (collectively, the ‘Site’), all services, applications,
              and products which are accessible through the site
            </li>

            <Spacing />

            <li style={_x().text2}>
              This agreement includes and hereby incorporates by reference the
              Privacy Policy which addresses among other things, how we collect,
              process, and share information and this includes but is not
              limited to our obligations under the ISO recommendations, Payment
              Card Industry Data Security Standards (PCI-DSS) and jurisdictional
              applicable laws under the laws of the Federal Republic of Nigeria,
              subject to the conditions set forth herein, 1autoshop may, in its
              sole discretion, amend this Agreement and the other Terms and
              Conditions at any time by posting a revised version on the website
              and will provide reasonable advance notice of any amendment that
              includes a substantial change.
            </li>

            <Spacing />

            <li style={_x().text2}>
              Any revisions to the Terms and Conditions will take effect on the
              noted effective date or when posted if there is no noted effective
              date (each, as applicable, the “Effective Date”).
            </li>

            <Spacing />

            <li style={_x().text2}>
              Your continued use of the website or the services after the
              Effective Date of a revised version of this Agreement constitutes
              your acceptance of an agreement to be bound by the Terms and
              Conditions as revised. In the event of a conflict between this
              Agreement and any other agreement, this Agreement will prevail
              unless the other Agreement explicitly states that it will prevail.
            </li>

            <Spacing />

            <p style={_x().text3}>
              PURPOSE AND SERVICES PROVIDED BY THE WEBSITE
            </p>
            <Spacing />

            <li style={_x().text2}>
              1autoshop.com is a marketplace where Clients (or Buyers) and Auto
              mechanics, Auto shops, Spare part dealers, or workshops (or
              Mechanic, Seller, professional, or Service Provider) can identify
              each other and advertise, buy, and sell Car repair Services.
            </li>

            <Spacing />

            <li style={_x().text2}>
              Subject to the Terms and conditions, quickmechs provides the
              website services to Users, including hosting and maintaining the
              Site, enabling the formation of Service Contracts, and
              coordinating disputes related to those Service Contracts.
            </li>

            <Spacing />

            <li style={_x().text2}>
              If Users agree on terms for mechanical Services, a Service
              Contract is formed directly between such Users. When a User enters
              a Service Contract, the User uses the website to engage,
              communicate, provide quotations and pay online. You acknowledge
              and agree that quickmechs is not a party to any Service Contracts;
              and that the formation of a Service Contract between Users will
              not, under any circumstance, create an employment or other service
              relationship between quickmechs and any mechanic or dealer.
            </li>

            <Spacing />

            <li style={_x().text2}>
              Each user of the Service is solely responsible for any and all his
              or her User Content. Because we do not control the User Content,
              you acknowledge and agree that we are not responsible for any User
              Content and we make no guarantees regarding the accuracy,
              currency, suitability, or quality of any User Content, and we
              assume no responsibility for any User Content.
            </li>

            <Spacing />

            <li style={_x().text2}>
              Your interactions with other Service users are solely between you
              and such users.
            </li>

            <Spacing />

            <li style={_x().text2}>
              You agree that the Administrator will not be responsible for any
              loss or damage incurred as the result of any such interactions. If
              there is a dispute between you and any Service user, we are under
              no obligation to become involved.
            </li>

            <Spacing />

            <li style={_x().text2}>
              The Service may contain links to third-party websites or resources
              and advertisements for third parties (collectively, "Third Party
              Ads").
            </li>

            <Spacing />

            <li style={_x().text2}>
              Such Third-Party Ads are not under the control of the
              Administrator and the Administrator is not responsible for any
              Third-Party Ads. The Administrator provides these Third-Party Ads
              only as a convenience and does not review, approve, monitor,
              endorse, warrant, or make any representations with respect to
              Third-Party Ads.
            </li>

            <Spacing />

            <li style={_x().text2}>
              Advertisements and other information provided by online properties
              to which the Third Party Ads lead, may not be wholly accurate. You
              acknowledge sole responsibility for and assume all risk arising
              from your use of any such online properties.
            </li>

            <Spacing />

            <li style={_x().text2}>
              When you link to third-party online properties, the applicable
              service provider's terms and policies, including privacy and data
              gathering practices, govern. You should make whatever
              investigation you feel necessary or appropriate before proceeding
              with any transaction with any third party.
            </li>

            <Spacing />

            <li style={_x().text2}>
              Your transactions and other dealings with Third-Party Ads that are
              found on or through the Service, including payment and delivery of
              related goods or services, are solely between you and such third
              party.
            </li>

            <Spacing />

            <li style={_x().text2}>
              You hereby release us, our officers, employees, agents, and
              successors from claims, demands any and all losses, damages,
              rights, claims, and actions of any kind including personal
              injuries, death, and property damage, that is either directly or
              indirectly related to or arises from any interactions with or
              conduct of any Service users or any Third Party Ads.
            </li>

            <Spacing />

            <p style={_x().text3}>DIGITAL SIGNATURE</p>
            <Spacing />

            <p style={_x().text2}>
              You are considered to have executed this Agreement and the other
              Terms and Conditions electronically, upon the date you register
              your Account, or click to accept the Terms and Conditions by
              registering for a quickmechs account on the website either as a
              seller or buyer. This applies when you click to accept the Terms
              and Conditions when prompted on the Site.
            </p>

            <p style={_x().text3}>YOUR CONSENT AND RIGHT TO WITHDRAW</p>

            <Spacing />

            <li style={_x().text2}>
              By registering for an Account, you consent to electronically
              receive and access, via email, Short Messages Services, Mobile App
              or Desktop Notifications, or the Site, all records and notices for
              the services provided to you under the Terms and Conditions that
              we or our subsidiaries or affiliates would otherwise be required
              to provide to you in paper form.
            </li>

            <Spacing />

            <li style={_x().text2}>
              Your consent to receive records and notices electronically will
              remain in effect until you withdraw it. You may withdraw your
              consent to receive further records and notices electronically at
              any time by contacting our Customer Support via our help site at
              https://quickmechs.com.com or email address help@quickmechs.com.
            </li>

            <Spacing />

            <li style={_x().text2}>
              Any withdrawal of your consent to receive records and notices
              electronically will be effective only after we have a reasonable
              period of time to process your request for withdrawal. Please note
              that your withdrawal of consent to receive records and notices
              electronically will not apply to records and notices
              electronically provided by us to you before the withdrawal of your
              consent becomes effective.
            </li>

            <Spacing />

            <p style={_x().text3}>
              UPDATING YOUR ADDRESS AND EMAIL ADDRESS WITH US
            </p>

            <Spacing />

            <li style={_x().text2}>
              You agree to notify us immediately of any change in your email
              address and phone number, updating your Account information on the
              Site, or by contacting our Customer Support. In addition, you
              agree to notify us immediately of any change in your address. This
              is to ensure that we can provide records and notices to you
              electronically.
            </li>

            <Spacing />

            <p style={_x().text3}>ACCOUNT REGISTRATION</p>

            <Spacing />

            <li style={_x().text2}>
              In order to use certain features of the quickmechs platforms, you
              may need to register an account on the Platform (the “Account”)
              and provide certain information about yourself as prompted by the
              registration form.
            </li>
          </section>
        </Paper>
      </Align>

      <Spacing vertical={50} />

      <Footer />
    </>
  );
}

export default TermsAndConditions;

const _x = (_mobile) => ({
  tAndC: {
    background: 'white',
    width: _mobile ? '100%' : 1200,
    padding: _mobile ? '20px 30px' : '0',
    textAlign: _mobile ? 'justify' : 'left',
  },
  main: {
    padding: _mobile ? '10px 3px' : '10px 200px',
  },

  header: {
    fontSize: 18,
    fontWeight: 'bold',
    background: 'var(--primary1)',
    color: 'white',
    padding: _mobile ? '10px 20px' : '10px 200px',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    margin: 0,
  },

  text1: {
    fontSize: 18,
    color: 'var(--secondary1)',
    fontWeight: 500,
  },

  text2: {
    color: '#545C70',
    fontSize: 14,
    lineHeight: 2,
  },

  text3: {
    color: 'var(--baseBg)',
    fontWeight: '500',
    fontSize: 18,
  },

  text4: {
    color: 'var(--baseBg)',
    fontSize: 14,
    lineHeight: 2,
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  text5: {
    color: 'var(--baseBg)',
    fontSize: 14,
    lineHeight: 2,
    cursor: 'pointer',
  },

  text6: {
    background: 'yellow',
    marginLeft: 5,
  },
});
