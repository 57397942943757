import { Redirect, Route, Switch } from "react-router";
import MechanicAndServices from "src/Routes/Home/MechanicAndServices/MechanicAndServices";
import Dashboard from "src/Routes/Home/seller/Dashboard";
import authService from "src/services/auth.service";
import AboutUs from "./AboutUs/AboutUs";
import PostAd from "./Advert/PostAd";
import Ad from "./Advert/PostFreeAd";
import Advertising from "./Advertising/Advertising";
import BecomeAMember from "./BecomeAMember/BecomeAMember";
import CancelAppointment from "./CancelAppintmnet/CancelAppointment";
import CarDealer from "./CarDealer/CarDealer";
import CarInsurance from "./CarInsureance/CarInsureance";
import CarLoan from "./CarLoan/CarLoan";
import ContactUs from "./ContactUs/ContactUs";
import CovidAssessment from "./CovidAssessment/CovidAssessment";
import FADealershopQ from "./Dealershop/Dealershop";
import DeviceVerification from "./DeviceVerification/DeviceVerification";
import DownloadAppScreen from "./DownloadAppScreen/DownloadAppScreen";
import FAQ from "./FAQ/FAQ2";
import MechOffice from "./MechOffice/MechOffice";
import GetLatLong from "./MechanicAndServices/GetLatLong";
import MSQueryList from "./MechanicAndServices/MSQueryList";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import Profile from "./Profile/Profile";
import ReportAbuse from "./ReportAbuse/ReportAbuse";
import ProductReview from "./ReviewMechanic/ProductReview";
import {
	default as Rating,
	default as ReviewMechanic,
} from "./ReviewMechanic/ReviewMechanic";
import Satisfaction from "./Satisfaction/Satisfaction";
import SellerRegistration from "./SellerRegistration/SellerRegistration";
import ServicesPage from "./ServicesPage/Services2";
import SiteMap from "./SiteMap/SiteMap";
import SparePartInfo from "./SparePartInfo/SparePartInfo";
import ProductReport from "./SparePartShowCase/ProductReport";
import ShowCaseReview from "./SparePartShowCase/ShowCaseReview";
import SparePartShowCase from "./SparePartShowCase/SparePartShowCase";
import SPSQueryList from "./SparePartsShop/SPSQueryList";
import SparePartsShop from "./SparePartsShop/SparePartsShop";
import Support from "./Support/Support";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import TermsofUse from "./TermsofUse/TermsofUse";
import CalenderBookingConfirmation from "./calendaBooking/CalenderBookingConfirmation";
import Edit from "./editAd/EditAd";
import ConfirmPage from "./jobs/ConfirmPage";
import FindJob from "./jobs/FindJob";
import JobBoard from "./jobs/JobBoard";
import MyJob from "./jobs/MyJob";
import PaymentDecline from "./jobs/PaymentDecline";
import PaymentSuccess from "./jobs/PaymentSuccess";
import PostAJob from "./jobs/PostAJob";
import NewEditDealer from "./newPartsForm/NewEditDealer";
import PartsDealerReg from "./newPartsForm/PartsDealerReg";
import MechItems from "./saveditems/MechItems";
import PartsItems from "./saveditems/PartsItems";
import ShopDetails from "./seller/ShopDetails";
import AuthUserSparPartPageOnMobilePage from "./seller/AuthUserSparPartPageOnMobilePage";

function Home() {
	return (
		<div
			style={{
				height: "100%",
				background: "white",
			}}
		>
			<Switch>
				<Route
					path="/home/mechanic-and-services/"
					component={MechanicAndServices}
					exact={true}
				/>
				<Route
					path="/home/covid-assessment/"
					component={CovidAssessment}
					exact={true}
				/>
				<Route
					path="/home/verify-device/"
					component={DeviceVerification}
					exact={true}
				/>
				<Route
					path="/home/mechanic-and-services/rate-service"
					component={ReviewMechanic}
				/>
				<Route path="/home/saveditems/parts" component={PartsItems} />
				<Route path="/home/saveditems/mech" component={MechItems} />
				<Route
					path="/home/spare-part/:product_title/product-review=:id"
					component={ProductReview}
				/>
				<Route path="/home/spare-parts-shop/" component={SparePartsShop} />
				<Route
					path="/home/spare-parts/:id/:product_title/review"
					component={ShowCaseReview}
				/>
				<Route
					path="/home/spare-parts/:id/:product_title"
					children={<SparePartShowCase />}
				/>
				<Route path="/home/become-a-dealer/" component={PartsDealerReg} />
				<Route path="/home/update-dealer-details/" component={NewEditDealer} />
				<Route path="/home/about-us/" component={AboutUs} />
				<Route path="/home/parts-update/:id/:product_title" component={Edit} />
				<Route
					path="/home/product/:id/report-abuse"
					component={ProductReport}
				/>
				<Route path="/home/profile-id/:user_id/" component={Profile} />
				<Route path="/home/post-free-ad/" component={Ad} />
				<Route path="/home/submit-an-ad/" component={PostAd} />
				<Route path="/home/latlong/" component={GetLatLong} />
				<Route path="/home/rating/" component={Rating} />
				<Route path="/home/contact-us/" component={ContactUs} />
				<Route path="/home/jobs/" component={JobBoard} />
				<Route path="/home/myjob" component={MyJob} />
				<Route path="/home/find-job" component={FindJob} />
				<Route path="/home/payment-success" component={PaymentSuccess} />
				<Route path="/home/payment-decline" component={PaymentDecline} />
				<Route path="/home/post-job" component={PostAJob} />
				<Route path="/home/confirm-job" component={ConfirmPage} />
				<Route path="/home/m-s-querylist/" component={MSQueryList} />
				{/* <Route
					path="/home/s-p-s-querylist/:product/:location/:year/:make/:modification/:engine/"
					component={SPSQueryList}
				/> */}
				<Route path="/home/s-p-s-querylist/" component={SPSQueryList} />
				<Route path="/home/satisfaction/" component={Satisfaction} />
				<Route path="/home/privacy-policy/" component={PrivacyPolicy} />
				<Route
					path="/home/terms-and-conditions/"
					component={TermsAndConditions}
				/>
				<Route path="/home/spare-part-info/" component={SparePartInfo} />
						<Route path="/home/user-spare-parts/mobile" component={AuthUserSparPartPageOnMobilePage} />
				<Route path="/home/dashboard/" component={Dashboard} />
				<Route path="/home/dashboard-details/" component={ShopDetails} />
				<Route
					path="/home/seller-registration/"
					component={SellerRegistration}
				/>
				<Route
					path="/home/mech-office/:mechId/:vehicle_query/:category/:sub_category"
					component={MechOffice}
				/>
				<Route path="/home/terms-of-use/" component={TermsofUse} />
				<Route path="/home/site-map/" component={SiteMap} />
				<Route path="/home/advertising/" component={Advertising} />
				<Route path="/home/become-a-member/" component={BecomeAMember} />
				<Route path="/home/services/" component={ServicesPage} />
				<Route path="/home/faq/" component={FAQ} />
				<Route path="/home/Dealer/store/:id" component={FADealershopQ} />
				<Route
					path="/home/confirm-appointment-to-calendar/:id/:visitor_id"
					component={CalenderBookingConfirmation}
				/>
				<Route path="/home/report-abuse/" component={ReportAbuse} />
				<Route path="/home/support" component={Support} exact={true} />
				<Route
					path="/home/car-insurance"
					component={CarInsurance}
					exact={true}
				/>
				<Route path="/home/car-dealer" component={CarDealer} exact={true} />
				<Route path="/home/car-loan" component={CarLoan} exact={true} />
				<Route
					path="/home/download-app/"
					component={DownloadAppScreen}
					exact={true}
				/>
				<Route
					path="/home/cancel-appointment/:appointment_id/:visitor_id"
					component={CancelAppointment}
					exact={true}
				/>
				<Redirect
					to={
						authService.isUserLoggedIn()
							? "/home/mechanic-and-services/"
							: "/auth/login/"
					}
				/>
			</Switch>
		</div>
	);
}

export default Home;
