import React from "react";
import { Paper } from "@material-ui/core";
import Footer from "src/Components/Functional/Footer/Footer";
import { Align } from "src/Components/Style/Styles";
// import "../home.css";
import DownloadApp from "src/Components/Functional/DownloadApp/DownloadApp";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { GoDotFill as GoPrimitiveDot } from "react-icons/go";

function BecomeAMember() {
	const _mobile = useMediaQuery("(max-width:800px)");
	return (
		<>
			<div style={{ marginTop: _mobile ? 10 : 50, marginBottom: 45 }}>
				<Align verticalHeight={10}>
					<Paper style={_x(_mobile).paper}>
						<div>
							<h3 style={_x(_mobile).header}>Become a Member</h3>
						</div>

						<section className="card-content" style={_x(_mobile).main}>
							<h3 style={_x().text1}>
								Join the thousands of car owners getting quality car services on
								Quickmechs
							</h3>
							<div>
								<p>
									Quickmechs is an online platform, which seeks to tackle every
									aspect of automobile repairschallenges, both locally and
									internationally, therefore connecting artisans(mechanics,tire
									shops, spare part dealers, car electricians, towing services,
									body shop experts) tocustomers in their various locations, at
									the snap of your finger.
								</p>

								<h4 style={_x().text1}>Becoming a Member</h4>

								<p>
									Signing up as a member on 1 automech is pretty easy. In four
									words 'YOU JUST SIGN UP',simple! So what are the steps? Come
									along on the journey of becoming a member on quickmechs.
								</p>

								<ul style={{ padding: 0 }}>
									<li style={_x().step}>
										<div style={_x().step_point}>Step 1: </div>
										<div>
											Visit your browser, either on your smartphone,laptop, tab)
											and input the url link''www.quickmechs.com''
										</div>
									</li>

									<li style={_x().step}>
										<div style={_x().step_point}>Step 2: </div>
										<div>
											To fully explore all our services and getthe best from our
											platform, first, you need tocreate an account/profile,
										</div>
									</li>

									<li style={_x().step}>
										<div style={_x().step_point}>Step 3: </div>
										<div>
											Visit the registration page ''Register NowPage'' to create
											and fill out the informationform, ensure all information
											filled(which includes: your address, full legal name,
											contact details,amongst others) are accurate.
										</div>
									</li>
								</ul>

								<h4 style={_x().text1}>Welcome to the Quickmechs family!</h4>

								<p>
									Now, you can enjoy our diverse range of services by logging in
									with your username and password anytime.
								</p>

								<p>
									Great news! We also create an avenue for our members to become
									an affiliate and make someextra cash.
								</p>
								<p>
									For more details on how to be an affiliate visit the AFFILIATE
									PAGE or contact us via:
									<span style={_x().step_email}>support@quickmechs.com </span>
								</p>
							</div>

							<h4 style={_x(_mobile).text1}>Benefits Of Being A Member</h4>

							<p style={_x(_mobile).text6}>
								<span style={{ marginRight: "10px", marginTop: "5px" }}>
									{/* <GoPrimitiveDot /> */}
								</span>
								As a part of Quickmechs’ affiliate community, you get exclusive
								access to our unlimited service options.
							</p>

							<p>
								<span style={{ marginRight: "10px", marginTop: "5px" }}>
									{/* <GoPrimitiveDot /> */}
								</span>
								You receive first-hand information on new opportunities,
								development, services, and products.
							</p>

							<p>
								<span style={{ marginRight: "10px", marginTop: "5px" }}>
									{/* <GoPrimitiveDot /> */}
								</span>
								As an affiliate, you can make some extra cash for yourself
								through referrals.
							</p>

							<p>
								<span style={{ marginRight: "10px", marginTop: "5px" }}>
									{/* <GoPrimitiveDot /> */}
								</span>
								You will receive bonuses and coupons at intervals.
							</p>

							<p>
								On registration, be sure to contact us via:
								<span style={_x().step_email}>support@quickmechs.com</span>
							</p>
						</section>
					</Paper>
				</Align>
			</div>

			<DownloadApp />

			<Footer />
		</>
	);
}

export default BecomeAMember;

const _x = (_mobile) => ({
	paper: {
		background: "white",
		width: _mobile ? "100%" : 1200,
		padding: _mobile ? "20px 30px" : "0",
		fontSize: 13,
	},
	header: {
		fontSize: 18,
		fontWeight: "bold",
		background: "var(--primary1)",
		color: "white",
		padding: _mobile ? "10px 20px" : "10px 200px",
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		margin: 0,
	},
	subheader: {
		fontSize: 17,
		fontWeight: "400",
		color: "var(--baseBg)",
		padding: "10px 0px",

		margin: 0,
	},
	main: {
		padding: _mobile ? "50px 3px" : "10px 200px",
		// paddingBottom: 130,
		// paddingTop: 10,
	},
	text1: {
		color: "var(--baseBg)",
		fontWeight: "500",
		fontSize: 15,
	},
	text6: {
		display: "flex",
		alignItems: "center",
	},
	step: {
		display: "flex",
		alignItems: "flex-start",
		marginBottom: 25,
	},

	step_point: {
		fontWeight: "500",
		color: "var(--baseBg)",
		fontSize: 13,
		marginRight: 10,
		whiteSpace: "nowrap",
	},
	step_email: {
		fontWeight: "500",
		color: "var(--primary1)",
		fontSize: 13,
	},
});
