/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import Emitter from "../services/emitter";
import { authHeader } from "./auth-header";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;

const ENDPOINT = {
	user: "users/",
	profile: "profile/",
	mechanic: "mechanic/",
	part_dealer: "part-dealer/",
};

const USER = {
	regular: "regular",
	mechanic: "mechanic",
	partDealer: "part-dealer/",
};

const ROUTES = {
	login: "login",
	register: "create",
	google: "google/",
	facebook: "facebook",
	verify: "verify-account",
	current: "current-user",
	update: "update",
	refresh: "refresh-token",
	instant: "update/instant-service",
	instant: "update/mobile-service",
};

const saveToken = (userId, token) => {
	return localStorage.setItem("userToken", token);
};

const getToken = () => {
	return localStorage.getItem("userToken");
};

const getCurrentUser = () => {
	return JSON.parse(localStorage.getItem("user"));
};

const updateLocalUserRole = (role) => {
	const user = JSON.parse(localStorage.user);

	user.data.user_info.permissions.push(role);

	localStorage.setItem("user", JSON.stringify(user));
};

const verifyUser = (code, via) => {
	return axios
		.post(
			API_URL + ENDPOINT.user + ROUTES.verify,
			{
				code: code,
			},
			{
				headers: { Authorization: `Bearer ${getToken()}` },
			}
		)
		.then((res) => {
			return {
				success: res.data.status,
			};
		})
		.catch((e) => {
			console.log(e);

			return { success: false };
		});
};

const updateInstantMobileService = (instantState, mobileState) => {
	return axios
		.patch(
			API_URL + ENDPOINT.mechanic + ROUTES.instant,
			{
				instant_service: instantState,
				mobile_service: mobileState,
			},
			{
				headers: authHeader(),
			}
		)
		.then((res) => {
			return {
				success: res.data.status,
			};
		})
		.catch((e) => {
			console.log(e);

			return { success: false };
		});
};

const updateProfile = (inputs) => {
	return axios
		.patch(API_URL + ENDPOINT.profile + ROUTES.update, inputs, {
			headers: authHeader(),
		})
		.then((res) => {
			return {
				success: res.data.status,
			};
		})
		.catch((e) => {
			console.log(e);

			return { success: false };
		});
};

const addUserRole = (
	shopId,
	edit,
	shopName,
	shopDescription,
	userRole,
	phoneNumber,
	taxIdNumber,
	state,
	city,
	skill,
	photo,
	experience,
	idType,
	idNumber,
	office_address,
	workHours,
	vehicles,
	workHrForm,
	skillsForm,
	vehicleForm,
	referralCode,
	instant_service,
	mobile_service,
	country,
	office_address_geolocation,
	specialty
) => {
	let requestBody =
		userRole === "mechanic"
			? {
					cover_image: photo[0]?.replace("jpeg", "png"),
					shop_name: shopName,
					shop_description: shopDescription,
					identity_number: getCurrentUser().data.user_info.id,
					phone_number: phoneNumber,
					tax_identification_no: taxIdNumber,
					state: state ?? "lagos",
					city: city,
					professional_skill: skill,
					company_photo: photo
						?.filter((e, i) => i !== 0)
						?.map((e, i) => {
							return e?.replace("jpeg", "png");
						}),
					experience_years: experience,
					identification_type: idType,
					office_address: office_address,
					working_hours: workHours,
					vehicle_type: vehicles,
					working_hours_data: JSON.stringify(workHrForm),
					professional_skill_data: JSON.stringify(skillsForm),
					vehicle_type_data: JSON.stringify(vehicleForm),
					referral_code: referralCode,
					instant_service,
					mobile_service: mobile_service === true ? "yes" : "no",
					country,
					lat: office_address_geolocation.lat ?? 0.0,
					long: office_address_geolocation.long ?? 0.0,
					service_category: skill?.toLowerCase()?.includes("car")
						? "car ac"
						: skill?.toLowerCase(),
					specialty: JSON.stringify(specialty),
			  }
			: {
					identity_number: getCurrentUser().data.user_info.id,
					phone_number: phoneNumber,
					tax_identification_no: taxIdNumber,
					state: state,
					city: city,
					company_photo: photo,
					identification_type: idType,
					instant_service: instant_service,
					// identity_number: idNumber,
					office_address: office_address,
					shop_name: shopName,
					shop_description: shopDescription,
			  };

	// const testReq = JSON.parse(localStorage.getItem("test-form"));
	// if (!testReq || DEV) {
	// 	localStorage.setItem("test-form", JSON.stringify(requestBody));
	// } else {
	// 	requestBody = testReq;
	// }

	// console.log(requestBody);

	if (!edit) {
		console.log(
			"new mechanic data",
			Object.keys(requestBody).map((e) =>
				e !== "cover_image" ? requestBody[e] : ""
			)
		);
	}

	if (edit) {
		return axios
			.patch(
				API_URL + `mechanic/${shopId}/update`,
				// (userRole === "mechanic" ? ENDPOINT.mechanic : ENDPOINT.part_dealer) +
				// ROUTES.update,
				requestBody,
				{
					headers: authHeader(),
				}
			)
			.then((res, err) => {
				return {
					success: res.data.status,
					data: res.data,
				};
			})
			.catch((e) => {
				console.log(e.response.data.errorSource);

				if (e?.response?.status === 419) {
					Emitter.emit("token expired");
				}

				return { success: false, errorSource: e.response.data.errorSource };
			});
	} else {
		return axios
			.post(
				API_URL +
					(userRole === "mechanic" ? ENDPOINT.mechanic : ENDPOINT.part_dealer) +
					ROUTES.register,
				requestBody,
				{
					headers: authHeader(),
				}
			)
			.then((res, err) => {
				updateLocalUserRole(userRole);

				return {
					success: res.data.status,
					data: res.data,
				};
			})
			.catch((e) => {
				console.log(e.response.data.errorSource);

				if (e?.response?.status === 419) {
					Emitter.emit("token expired");
				}

				return { success: false, errorSource: e.response.data.errorSource };
			});
	}
};

function getCurrentLoggedInUser() {
	return axios
		.get(API_URL + ENDPOINT.user + ROUTES.current + "?fullDetails=true", {
			headers: authHeader(),
		})
		.then((res, e) => {
			return {
				success: true,
				data: res.data,
			};
		})
		.catch((e) => {
			// console.log(e);

			if (e?.response?.status === 419) {
				Emitter.emit("token expired");
			}

			return { success: false };
		});
}

const register = (
	firstName,
	lastName,
	email,
	password,
	cpassword,
	phone,
	statez,
	city,
	address,
	country,
	state
) => {
	// console.log(API_URL, ENDPOINT.user, ROUTES.register);

	return axios
		.post(API_URL + ENDPOINT.user + ROUTES.register, {
			first_name: firstName,
			last_name: lastName,
			email: email,
			password: password,
			password_confirmation: cpassword,
			state: address,
			phone_number: statez,
			city: city,
			address: phone,
			country,
			state,
		})
		.then((res) => {
			const { data } = res.data;
			// console.log(data);

			saveToken(data.user_info.email, data.access_token);

			return {
				success: true,
				user_id: data.user_info.id,
			};
		})
		.catch((e) => {
			console.log(e?.response?.data?.errorSource);

			return { success: false, errorSource: e.response.data.errorSource };
		});
};

const registerWithGoogle = () => {
	axios
		.get(
			API_URL +
				ENDPOINT.user +
				ROUTES.google +
				USER.partDealer +
				"generate_link"
		)
		.then((res) => {
			window.location = res.data.data.href;
			console.log(res.data);
		})
		.catch((e) => {
			console.log(e);
		});
};

const isUserLoggedIn = () => {
	return true;
};

const login = (email, password) => {
	return axios
		.post(API_URL + ENDPOINT.user + ROUTES.login, {
			email,
			password,
		})
		.then((response) => {
			if (response.data.data.access_token) {
				localStorage.setItem("user", JSON.stringify(response.data));

				// setTimeout(function () {
				// 	localStorage.clear();

				// 	window.location = "/auth/login";
				// }, 30 * 60 * 1000);
			}
			return response.data;
		});
};

const logout = (userId) => {
	localStorage.clear();
	localStorage.removeItem("user");
	localStorage.removeItem("userToken");

	//? delete token of userId on logout.
};

const refreshTokens = () => {
	return axios
		.get(API_URL + ENDPOINT.user + ROUTES.refresh, {
			headers: authHeader(),
		})
		.then((res) => {
			if (res.data.data.access_token) {
				localStorage.setItem("user", JSON.stringify(res.data));
			}

			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			if (e?.response?.status === 419) {
				Emitter.emit("token expired");
			}

			return { success: false };
		});
};

export default {
	login,
	logout,
	getCurrentUser,
	register,
	saveToken,
	getToken,
	addUserRole,
	registerWithGoogle,
	verifyUser,
	isUserLoggedIn,
	updateLocalUserRole,
	getCurrentLoggedInUser,
	refreshTokens,
	updateInstantMobileService,
	updateProfile,
};
