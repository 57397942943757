import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div style ={{
                 width:"100%",
                 height:"700px",
                  display:'flex', 
                  justifyContent:"center", 
                  alignItems:"center",
                  flexDirection:"column"
                  }}>
                      <div style ={{width:"400px"}}>
                      <img style ={{width:"100%",
                                    objectFit:"cover"
                                }}
                      src="https://cdni.iconscout.com/illustration/premium/thumb/indian-mechanic-repairing-wheel-with-wrench-2660497-2227268.png" alt="" />
                      </div>
    <h1  style ={{
                 fontSize:"4rem",
                 color:"#1976d3",
                 marginBottom:"20px"
                  }}>Page Not Found!</h1>

                  <h5 style ={{
                 fontSize:"1rem",
                 color:"#646464"
                  }}>Looks like you made a wrong turn, but no worries,
                       we can help you get back home </h5>
    <Link to="/"  style ={{
                backgroundColor:'#1976d3',
                fontSize:"1rem",
                color:"#fff",
                textDecoration:"none",
                padding:"10px 30px",
                borderRadius:"5px"
                  }}>
      Go To HomePage
    </Link>
  </div>
);

export default NotFound;