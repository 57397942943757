import { Paper, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Align, Spacing } from "src/Components/Style/Styles";
import confirmationImg from "../../../Resources/Images/confirmation.svg";

function RegCongfirmation() {
	const { user_type, ref } = useParams();

	const _mobile = useMediaQuery("(max-width:800px)");

	const _x = {
		congrats: {
			padding: 50,
			width: _mobile ? "100vw" : 800,
			paddingLeft: _mobile ? 0 : 100,
			paddingRight: _mobile ? 0 : 100,
			boxShadow: _mobile ? null : "0 2px 2px rgba(0, 0, 0, 0.25)",
			backgroundColor: "white",
			borderRadius: 6,
			display: "grid",
			placeItems: "center",
		},

		congratImg: {
			height: 200,
			width: 300,
		},

		txt1: {
			fontWeight: 500,
			fontSize: 18,
			marginBottom: 15,
		},

		txt2: {
			fontSize: 15,
			fontWeight: 500,
		},

		btn: {
			width: 150,
			border: "1px solid black",
			backgroundColor:
				user_type === "mechanic" ? "var(--primary1)" : "var(--primary2)",
			padding: 8,
			borderRadius: 5,
			fontSize: 15,
			fontWeight: "bold",
		},
	};

	return (
		<Align>
			<Paper style={_x.congrats} elevation={_mobile ? 0 : 1}>
				<img src={confirmationImg} style={_x.congratImg} />

				<Spacing vertical={40} />

				<div
					style={{
						height: 40,
						display: "grid",
						placeItems: "center",
						marginBottom: 30,
					}}
				>
					<div style={_x.txt1}>Congratulations</div>
					<span style={_x.txt2}>Your registration was successful</span>
				</div>

				<Spacing vertical={40} />

				<Link to={`/home/${ref === "ad" ? "post-free-ad" : "dashboard"}`}>
					<button style={_x.btn}>Continue</button>
				</Link>
			</Paper>
		</Align>
	);
}

export default RegCongfirmation;
