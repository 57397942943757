import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Fragment, useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import PlacesAutocomplete from "react-places-autocomplete";
import { useHistory } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import ac_black from "src/Resources/Icons/ac-black.png";
import bodyWork_black from "src/Resources/Icons/bodywork-black.png";
import electrical_black from "src/Resources/Icons/electrical-black.svg";
import history_icon from "src/Resources/Icons/history.png";
import location from "src/Resources/Icons/location.png";
import mechanic_black from "src/Resources/Icons/mechanic-black.svg";
import search from "src/Resources/Icons/search.png";
import security_black from "src/Resources/Icons/security-black.png";
import towing_black from "src/Resources/Icons/towing-black.png";
import vehicletype from "src/Resources/Icons/vehicletype.png";
import vulcanizer_black from "src/Resources/Icons/vulcanizer-black.svg";
import gears from "src/Resources/Images/gears.png";
import mechman from "src/Resources/Images/mechman.png";
import { makes2, skillTree } from "src/Resources/Resources";
import mechService from "src/services/mech.service";
import { Spacing } from "../../Style/Styles";
import Chips from "../Chips/Chips";
import "./banner.css";

function Banner({ type }) {
	const [tab, settab] = useState("Mechanic");
	const [mechImage, setMechImage] = useState(false);
	const [category, setcategory] = useState("Mechanic");
	const [subcategory, setsubcategory] = useState("General Repairs");
	const [location_field, setlocation_field] = useState("");
	const [showerror, setshowerror] = useState(false);
	const simpleValidator = useRef(new SimpleReactValidator());
	const [vehicletypestate, setvehicletypestate] = useState("");
	const [show_vehicle_dropdown, set_show_vehicle_dropdown] = useState(false);
	const history = useHistory();
	const _mobile = useMediaQuery("(max-width:800px)");
	const location_ref = useRef();
	const [history_panel, set_history_panel] = useState({
		isOpen: false,
		history: ["Abuja, Nigeria"],
	});
	const [banner1, setbanner1] = useState({
		// category: "Mechanic",
		// sub_category: "General Repairs",
		location: "",
		vehicletype: "",
		suggestionsOpen: false,
	});
	const [banner2, setbanner2] = useState({
		product: "",
		location: "",
		year: "",
		make: "Select Make",
		model: "",
		engine: "",
		country: "",
		state: "",
		activeDropdown: "NONE",
	});

	const checkType = () => {
		if (type !== 2) setMechImage(true);
		else setMechImage(false);
	};

	useEffect(() => {
		checkType();

		const panel = document.getElementById("home-panel-row-1");

		if (panel) {
			Array.from(panel.childNodes).forEach((btn) => {
				btn.style.borderColor = "transparent";

				Array.from(btn.childNodes).forEach((child) => {
					child.style.color = "black";
				});
			});

			const btn = document.getElementById("home-panel-row-1").childNodes[0];

			btn.style.borderBottom = "4px solid var(--accent1)";

			btn.style.color = "var(--accent1)";
		}
	}, []);

	useEffect(() => {
		mechService
			.getMechStoreNames(location_field)
			.then((res) => {
				console.log(res);
			})
			.catch((e) => console.log(e));
	}, [location_field]);

	useEffect(() => {
		let history = JSON.parse(sessionStorage.getItem("searchHistory"));

		if (!history) {
			sessionStorage.setItem("searchHistory", JSON.stringify([]));
			history = [];
		}

		set_history_panel({ ...history_panel, history });
	}, []);

	useEffect(() => {
		if (location_field) {
			closeHistoryPanel();
		}
	}, [location_field]);

	function tabClicked(e) {
		const { id } = e.target;

		settab(id);

		setcategory(id);

		setsubcategory(skillTree[id][0]);

		const panel = document.getElementById("home-panel-row-1");

		Array.from(panel.childNodes).forEach((btn) => {
			btn.style.borderColor = "transparent";

			Array.from(btn.childNodes).forEach((child) => {
				child.style.color = "black";
			});
		});

		document.getElementById(id).style.borderBottom = "4px solid var(--accent1)";
	}

	function chipChanged(name) {
		// setbanner1({ ...banner1, sub_category: name });
		//! setting subcategory in banner1 is not working for some goddamn reason......

		setsubcategory(name);
	}

	function goToSPSQueryList() {
		history.push({
			pathname: "/home/s-p-s-querylist/",
			state: {
				product: banner2.product,
				engine: banner2.engine,
				model: banner2.model,
				location: banner2.location,
				year: banner2.year,
				make: banner2.make === "Select Make" ? "" : banner2.make,
				country: banner2.country,
				state: banner2.state,
			},
		});
	}

	function goToMSQueryList() {
		setshowerror(true);
		saveSearchHistory();
		if (location_field !== "") {
			history.push({
				pathname: "/home/m-s-querylist/",
				state: {
					category: category,
					sub_category: subcategory,
					location: location_field,
					vehicle_type: vehicletypestate,
				},
			});
		}
	}

	function getImg(name) {
		switch (name) {
			case "Tire Shop":
				return vulcanizer_black;

			case "Mechanic":
				return mechanic_black;

			case "Body Shop":
				return bodyWork_black;

			case "Car Air-Conditioning":
				return ac_black;

			case "Electrical":
				return electrical_black;

			case "Towing":
				return towing_black;

			case "Security":
				return security_black;

			case "Spare Part":
				return security_black;

			default:
				break;
		}
	}

	function getAddressFromLatLng() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					console.log("loading");

					Geocode.fromLatLng(
						position.coords.latitude,
						position.coords.longitude
					).then(
						(response) => {
							console.log("done");

							const address = response.results[0].formatted_address;

							console.log(address);

							setlocation_field(address);
						},
						(error) => {
							console.error(error);
						}
					);
				},
				(err) => {
					alert(err);
				},
				{ maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
			);
		} else {
			alert("geolocation not supported on this device");
		}
	}

	function openHistoryPanel(e) {
		e.stopPropagation();

		// set_history_panel({ ...history_panel, isOpen: true });
		// set_show_vehicle_dropdown(false);

		const panel = document.querySelector("#history-panel");
		if (panel) panel.style.display = "block";
	}

	function closeHistoryPanel(e) {
		const panel = document.querySelector("#history-panel");
		if (panel) panel.style.display = "none";
	}

	function saveSearchHistory(init_address) {
		let final_address;

		if (init_address) final_address = init_address;
		else final_address = location_field;

		if (final_address !== "") {
			let history = JSON.parse(sessionStorage.getItem("searchHistory"));
			history.push(final_address);
			history = [...new Set(history)];

			sessionStorage.setItem("searchHistory", JSON.stringify(history));

			set_history_panel({
				...history_panel,
				history,
				// isOpen: false,
			});
		}
		closeHistoryPanel();
	}

	function closeSuggestionBox(address) {
		// e.stopPropagation();
		// closeHistoryPanel();

		setlocation_field(address);

		setbanner1({ ...banner1, location: address, suggestionsOpen: false });

		saveSearchHistory(address);
	}

	function updateVehicle(name) {
		// setbanner1({ ...banner1, vehicletype: name });
		//! setting vehicletype in banner1 is not working for some goddamn reason......

		setvehicletypestate(name);
	}

	function openVehicleDropDown() {
		set_show_vehicle_dropdown(true);
	}

	function closeVehicleDropDown() {
		set_show_vehicle_dropdown(false);
	}

	function toggleVehicleDropdown() {
		set_show_vehicle_dropdown(!show_vehicle_dropdown);
		// set_history_panel({ ...history_panel, isOpen: false });

		closeHistoryPanel();
	}

	function toggleDropdown(e, dropdownName) {
		e.stopPropagation();
		setbanner2({ ...banner2, activeDropdown: dropdownName });
	}

	function locationDropdown() {
		return (
			<div style={{ display: "flex", width: "100%" }}>
				<div style={{ backgroundColor: "red" }}></div>
			</div>
		);
	}

	return (
		<div
			onClick={(e) => {
				closeVehicleDropDown();
				setbanner1({ ...banner1, suggestionsOpen: false });
				setbanner2({ ...banner2, activeDropdown: "NONE" });

				closeHistoryPanel();
			}}
			className="banner"
			style={_x(_mobile).banner}
		>
			<div style={_x(_mobile).banner_row1}></div>

			<div className="home-panel bounceIn" style={_x(_mobile).banner_row_upper}>
				<div style={_x(_mobile).banner_row2}>
					<div
						id="home-panel-row-1"
						className="home-panel-row-1 hideScroll"
						style={{ borderBottom: "1px solid #ccc" }}
					>
						{Object.keys(skillTree).map((key, i) => {
							return (
								<Fragment key={i.toString()}>
									<button
										style={{
											..._x(_mobile).bannerBtn,
											backgroundImage: `url(${getImg(key)})`,
											backgroundSize:
												key === "Security" ? 25 : key === "Towing" ? 30 : 20,
										}}
										onClick={tabClicked}
										id={key}
									>
										<span
											onClick={(e) => (e.target.id = key)}
											style={_x(_mobile).ban_txt}
										>
											{key === "Car Air-Conditioning" ? "Car AC" : key}
										</span>
									</button>
								</Fragment>
							);
						})}
					</div>

					{category === "Spare Part" ? (
						<div style={_x(_mobile).banner_type2}>
							<div style={_x().searchbar_box}>
								<img src={search} alt="search" style={_x().search_icon} />

								<input
									style={_x().search_bar}
									placeholder="Find Parts and Products"
									onChange={(e) =>
										setbanner2({ ...banner2, product: e.target.value })
									}
								/>
							</div>

							{_mobile && locationDropdown()}

							<Spacing />

							<button
								className="home-panel-search-btn"
								onClick={goToSPSQueryList}
								style={{ width: _mobile ? "100%" : null }}
							>
								Search
							</button>
						</div>
					) : (
						<>
							<div className="home-panel-row-2">
								<div style={{ width: "100%", paddingLeft: _mobile ? 20 : 40 }}>
									<Chips tab={tab} onChipChange={chipChanged} />
								</div>

								<div
									className="home-panel-search-bars"
									onClick={openHistoryPanel}
								>
									<div
										style={{ position: "relative" }}
										onClick={() => {
											closeHistoryPanel();
											closeVehicleDropDown();
										}}
									>
										<PlacesAutocomplete
											value={location_field}
											onChange={(e) => {
												setlocation_field(e);

												setbanner1({
													...banner1,
													location: e,
													suggestionsOpen: true,
												});

												closeHistoryPanel();
											}}
											onSelect={(address) => closeSuggestionBox(address)}
											onBlur={simpleValidator.current.showMessageFor(
												"Location"
											)}
										>
											{({
												getInputProps,
												suggestions,
												getSuggestionItemProps,
												loading,
											}) => (
												<div className="home-panel-search-bar-box">
													<img
														src={location}
														alt=""
														style={{ cursor: "pointer" }}
														onClick={openHistoryPanel}
													/>

													<input
														{...getInputProps({
															// placeholder: "Search by Locations or Shop Name",
															placeholder: "Search by Location",
															className: "home-panel-search-bar",
														})}
														ref={location_ref}
													/>

													{banner1.suggestionsOpen &&
														suggestions.length !== 0 && (
															<div
																style={_x().suggestion_box}
																className="autocomplete-dropdown-container"
															>
																{suggestions.map((each_suggestion) => {
																	// console.log(
																	// 	"each_suggestion",
																	// 	each_suggestion
																	// );

																	return (
																		<div
																			{...getSuggestionItemProps(
																				each_suggestion
																			)}
																			key={each_suggestion.description}
																			style={{
																				..._x().suggestion_post,
																				background: each_suggestion.active
																					? "#fafafa"
																					: "#ffffff",
																				cursor: "pointer",
																			}}
																			onClick={() =>
																				closeSuggestionBox(
																					each_suggestion.description
																				)
																			}
																		>
																			<>{each_suggestion.description}</>
																		</div>
																	);
																})}
															</div>
														)}
												</div>
											)}
										</PlacesAutocomplete>

										<div
											id="history-panel"
											style={{
												..._x().suggestion_box,
												height: 200,
												display: "none",
											}}
											// className="hideScroll"
										>
											{history_panel.history.map((each_suggestion) => (
												<div
													key={each_suggestion}
													style={{
														..._x().suggestion_post,
														cursor: "pointer",
													}}
													onClick={(e) => {
														e.stopPropagation();
														closeSuggestionBox(each_suggestion);
													}}
												>
													<img
														src={history_icon}
														alt="history"
														style={{
															width: 20,
															marginRight: 10,
															objectFit: "contain",
														}}
													/>

													<>{each_suggestion}</>
												</div>
											))}
										</div>

										<Spacing vertical={_mobile ? 0 : 10} />

										{showerror &&
											simpleValidator.current.message(
												"Location",
												location_field,
												"required",
												{
													element: (msg) => (
														<div style={_x().error}>location is required !</div>
													),
												}
											)}
									</div>

									<div>
										<div
											className="home-panel-search-bar-box"
											onClick={(e) => {
												e.stopPropagation();
												toggleVehicleDropdown();
											}}
											style={{ position: "relative" }}
										>
											<img
												src={vehicletype}
												alt=""
												style={{ cursor: "pointer" }}
											/>

											<div
												style={{
													marginLeft: 10,
													fontWeight: 500,
													color: vehicletypestate === "" ? "grey" : "black",
												}}
											>
												{vehicletypestate === ""
													? "Select vehicle type"
													: vehicletypestate}
											</div>

											{show_vehicle_dropdown && (
												<div
													// className="hideScroll"
													style={{ ..._x().suggestion_box, height: 200 }}
												>
													{Object.keys(makes2).map((key, val) => {
														return (
															<div
																key={key}
																value={key}
																style={_x().suggestion_post}
																onClick={() => updateVehicle(key)}
															>
																{key}
															</div>
														);
													})}
												</div>
											)}
										</div>

										<Spacing vertical={10} />

										{/* {showerror && vehicletypestate === "" && (
										<div style={_x().error}>vehicle type is required !</div>
									)} */}
									</div>
								</div>

								<button
									onClick={goToMSQueryList}
									className="home-panel-search-btn"
								>
									Search
								</button>
							</div>
						</>
					)}
				</div>

				{!_mobile && (
					<>
						<img
							src={mechman}
							alt="mechman"
							style={{ width: 400, objectFit: "contain" }}
						/>

						<div
							style={{
								position: "absolute",
								bottom: -140,
								left: 0,
								zIndex: 100,
							}}
						>
							<div style={{ fontWeight: "bold", fontSize: 35, color: "white" }}>
								A Service Provider's Dream
							</div>

							<div style={{ fontWeight: 600, fontSize: 15, color: "white" }}>
								List your shop and sell services, or easily find and connect
								with trusted providers in real time
							</div>
						</div>
					</>
				)}
			</div>

			{!_mobile && (
				<img
					src={gears}
					alt="gears"
					style={{
						width: "100vw",
						objectFit: "contain",
						position: "absolute",
						bottom: 0,
						left: 0,
					}}
				/>
			)}
		</div>
	);
}

export default Banner;

const _x = (_mobile) => ({
	banner: {
		padding: _mobile ? "0px" : "1rem 10rem",
		position: "relative",
		boxShadow: _mobile ? "none" : "rgba(0, 0, 0, 0.24) 0px 3px 8px",
		height: _mobile ? "" : "95vh",
		width: "100%",
		display: "grid",
		placeItems: _mobile ? "" : "center",
	},

	banner_row1: {
		position: "absolute",
		top: 0,
		left: "0",
		overflow: "hidden",
		width: "100%",
		height: "100%",
		zIndex: "1",
		display: _mobile ? "none" : "block",
		background:
			"linear-gradient(360deg, #587FC0 0%, rgba(88, 127, 192, 0.00) 100%)",
	},

	banner_row1_img: { objectFit: "cover", width: "100%", height: "100%" },

	banner_row_upper: {
		maxWidth: _mobile ? "100%" : "1200px",
		position: "relative",
		zIndex: "5",
		background: "transparent",
		display: "flex",
		justifyContent: "space-between",
	},

	banner_row2: {
		width: _mobile ? "100%" : "850px",
		background: "white",
		paddingBottom: 10,
		borderRadius: 10,
	},

	suggestion_post: {
		height: 30,
		display: "flex",
		alignItems: "center",
		padding: 20,
		cursor: "pointer",
		fontWeight: 600,
	},

	suggestion_box: {
		position: "absolute",
		width: "100%",
		minHeight: 100,
		background: "white",
		boxShadow:
			"rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
		maxHeight: 400,
		overflowY: "scroll",
		zIndex: 3,
		top: 40,
		borderBottomRightRadius: 12,
		borderBottomLeftRadius: 12,
	},

	error: {
		color: "red",
		fontSize: 10,
		width: "100%",
		textAlign: "center",
	},

	select: {
		height: 32,
		width: "100%",
		border: "none",
		// borderRight: "1px solid #DBDEE7",
		borderRadius: 3,
		backgroundColor: "#fff",
		// webkitAppearance: "none",
		// mozAppearance: "none"
	},

	banner_type2: {
		padding: 25,
		gridTemplateRows: "auto auto 1fr",
		display: "grid",
		placeItems: "center",
		// backgroundColor: "red",
		paddingLeft: _mobile ? 20 : 50,
		paddingRight: _mobile ? 20 : 50,
		// marginTop: 35,
	},

	bannerBtn: {
		backgroundSize: 20,
		backgroundRepeat: "no-repeat",
		backgroundPositionX: "center",
		backgroundPositionY: 15,
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "center",
		fontSize: 13,
		borderBottom: "4px solid transparent",
		minWidth: _mobile ? 100 : "",
	},

	ban_txt: {
		fontSize: _mobile ? 12 : 14,
		marginBottom: 15,
		// fontWeight: "bold",
	},

	searchbar_box: {
		border: "1px solid var(--secondary3)",
		display: "flex",
		alignItems: "center",
		// marginRight: 60,
		// marginLeft: 60,
		paddingLeft: 10,
		paddingRight: 10,
		borderRadius: 6,
		width: "100%",
		marginTop: "20px",
	},

	search_icon: {
		height: 20,
		width: 20,
	},

	search_bar: {
		width: "100%",
		border: "none",
	},

	dropDown_box: {
		// height: 149,
		background: "#F2F2F2",
		width: "100%",
		paddingTop: 10,
		paddingLeft: 50,
		paddingRight: 50,
	},

	select_list: {
		height: 54,
		width: "100%",
		background: "white",
		borderRadius: 6,
		display: "grid",
		gridAutoFlow: "column",
		overflow: "hidden",
		paddingRight: "10px",
		border: "1px solid var(--secondary3)",
	},

	select1: {
		marginTop: _mobile ? 20 : 0,
		height: _mobile ? 40 : "100%",
		width: "100%",
		backgroundColor: "#dbdee7",
		borderRadius: _mobile ? 5 : 0,
		borderTopLeftRadius: _mobile ? 6 : 6,
		borderBottomLeftRadius: _mobile ? 6 : 6,
		borderTopRightRadius: _mobile ? 6 : 0,
		borderBottomRightRadius: _mobile ? 6 : 0,
		border: _mobile ? "none" : "1px solid var(--primary1)",
		paddingLeft: _mobile ? 10 : "1rem",
		background: "white !important",
		webkitAppearance: "none",
		mozAppearance: "none",
		borderRadius: "6px",
		appearance: "none",
		backgroundSize: "12px",
		backgroundPosition: "calc(100% - 0px) center",
		backgroundPositionY: "20px",
		backgroundRepeat: "no-repeat",
		backgroundPositionX: "195px",
	},

	select2: {
		height: "70%",
		width: "100%",
		border: "none",
		borderRadius: "none",
		borderRight: "0.5px solid #c4c4c450",
		padding: 10,
		margin: "10px 0 5px",
		paddingLeft: "1rem",
		// background: "blue",
		// display: "flex",
		// justifyContent: "space-between",
	},

	btn: {
		height: 48,
		width: _mobile ? "100%" : 174,
		background: "var(--primary1)",
		color: "white",
		fontSize: 18,
		fontWeight: "bold",
		borderRadius: 6,
	},

	text1: {
		fontSize: 18,
		fontWeight: 500,
	},

	text2: {
		fontSize: 13,
	},
});
