import React from "react";
import "./newpartssteps.css";
import { AiOutlinePlus } from "react-icons/ai";
import { ImImages } from "react-icons/im";
import "./newpartssteps.css";
export default function ShPhotoInfo({ onImageUpload }) {
  return (
    <div className="sh_photo_info" id="info_mob-st" onClick={onImageUpload}>
      <p className="plus_st">
        <AiOutlinePlus />
      </p>
      <p className="addPhoto">
        <span>
          <ImImages />
        </span>
        Add Photo
      </p>
      <p className="num_uploaded">Uploaded 0/1 images</p>
    </div>
  );
}
