import { Paper } from "@material-ui/core";
import React from "react";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Align, Spacing } from "../../../Components/Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function Support() {
	const _mobile = useMediaQuery("(max-width:800px)");

	function mailTo() {
		const email = "mailtosupport@quickmechs.com";
		const subject = "support";
		document.location = "mailto:" + email + "?subject=" + subject;
	}

	function openDialer() {
		const phone = "09134444776";
		document.location.href = `tel:${phone}`;
	}

	return (
		<>
			<Align verticalHeight={_mobile ? 0 : 20}>
				<Paper style={_x(_mobile).support} elevation={_mobile ? 0 : 1}>
					<div style={_x(_mobile).header}>Support</div>

					<div className="card-content" style={_x(_mobile).main}>
						<p style={_x().text3}>
							Our support team is always available to offer any assistance you
							may require. If you need further clarification on the services
							offered on our platform, kindly visit the FAQ page.
						</p>

						<Spacing vertical={8} />

						<div>
							For additional support send a mail to
							<span style={_x().hyperlink} onClick={mailTo}>
								support@quickmechs.com
							</span>
						</div>
						<Spacing vertical={15} />

						<div style={_x().text4}>Service hour:</div>
						<Spacing vertical={10} />

						<div style={_x().text5}>Monday - Friday: 8am - 5pm</div>

						<Spacing vertical={10} />
						<div style={_x().text5}>Weekends & public holidays: 10am - 4pm</div>

						<Spacing vertical={10} />
						<div style={_x().text5}>
							Are you a mechanic, tire shop, spare part dealer, electrician, or
							technician?
						</div>
						<Spacing vertical={10} />

						<div style={_x().text5} onClick={openDialer}>
							Register for free on Quickmechs to open your business to thousands
							of customers both locally and internationally. Visit today
							www.quickmechs.com
						</div>
					</div>
				</Paper>
			</Align>
			<Spacing vertical={50} />

			<DownloadApp />

			<Footer />
		</>
	);
}

export default Support;

const _x = (_mobile) => ({
	support: {
		width: _mobile ? "100%" : 1200,
		background: "white",
		// padding: _mobile ? "20px 30px" : "0",
		marginTop: _mobile ? 0 : 50,
	},
	main: {
		padding: _mobile ? "0px 30px" : "10px 200px",
	},

	text1: {
		fontSize: 18,
		fontWeight: "bold",
		color: "var(--secondary1)",
	},
	header: {
		fontSize: 18,
		fontWeight: _mobile ? 500 : "bold",
		background: _mobile ? "" : "var(--primary1)",
		color: _mobile ? "var(--baseBg)" : "white",
		padding: _mobile ? "0px 30px" : "10px 200px",
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		marginTop: _mobile ? 10 : 0,
	},

	text2: {
		fontSize: 13,
		fontWeight: "bold",
		color: "var(--secondary1)",
		lineHeight: 1.8,
	},

	text3: {
		fontSize: 13,
		padding: 0,
	},

	text4: {
		fontSize: 15,
		fontWeight: 500,
		lineHeight: 1.8,
		color: "var(--baseBg)",
	},

	text5: {
		fontSize: 13,
		lineHeight: 1.8,
	},

	hyperlink: {
		fontWeight: "bold",
		color: "var(--primary1)",
		marginLeft: 6,
		cursor: "pointer",
	},
});
