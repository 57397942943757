import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useState } from "react";
import { BsImages } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { HiPlus } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import ImageUploader from "react-images-uploading";
import { Spacing } from "src/Components/Style/Styles";
import close2 from "src/Resources/Icons/close2.png";

function PhotoBooth({
	routeRef,
	photos,
	onChange,
	multiple,
	droppable,
	reorderImgList,
}) {
	const _mobile = useMediaQuery("(max-width:800px)");

	const _y = (isDragging) => {
		return {
			drag_styles: {
				color: isDragging ? "red" : undefined,
			},

			photobooth: {
				position: "relative",
				width: "100%",
				zIndex: 900,
			},

			photobooth_btn: {
				fontWeight: 600,
				height: 130,
				width: 125,
				fontSize: 13,
				color: "black",
				border: "1px solid",
				borderRadius: 10,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				cursor: "pointer",
			},

			image_box: {
				height: _mobile ? 80 : 100,
				width: _mobile ? 80 : 100,
				position: "relative",
				marginRight: 10,
				borderRadius: 10,
			},

			img_alpha: {
				width: "100%",
				// _mobile ? "100%" : 313,
				height: 165,
				border: "1px solid var(--secondary1)",
				display: "grid",
				placeItems: "center",
				position: "relative",
			},

			img: {
				width: 300,
				height: 150,
				// border: "1px solid",
				objectFit: "cover",
				position: "relative",
			},

			drag_panel: {},

			close_alpha: {
				top: 12,
				right: 12,
				position: "absolute",
				borderRadius: "50%",
				display: "grid",
				placeItems: "center",
				background: "white",
				height: 25,
				width: 25,
			},

			close: {
				height: 18,
				width: 18,
				backgroundImage: `url(${close2})`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
			},

			delete: {
				height: 30,
				width: 30,
				borderRadius: 50,
				position: "absolute",
				top: -10,
				right: -10,
				background: "red",
				display: "grid",
				placeItems: "center",
				cursor: "pointer",
			},

			menu: {
				width: 400,
				background: "white",
				position: "absolute",
				bottom: -100,
				boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
			},

			menuItem: {
				// padding: 20,
				height: 30,
				borderTop: "0.5px solid lightslategrey",
				display: "grid",
				placeItems: "center",
				cursor: "pointer",
			},
		};
	};

	const [previewShown, setpreviewShown] = useState(
		routeRef === "edit" ? true : false
	);

	const [menuOpen, setMenuOpen] = useState({ isOpen: false, index: 0 });

	function openContextMenu(idx) {
		setMenuOpen({ isOpen: true, index: idx });
	}

	function setAsCover() {
		const br = photos.splice(menuOpen.index, 1)[0];
		photos.unshift(br);
		reorderImgList(photos);
		setMenuOpen({ ...menuOpen, isOpen: false });
	}

	function getImageBox({
		imageList,
		onImageUpload,
		onImageRemoveAll,
		onImageUpdate,
		onImageRemove,
		isDragging,
		dragProps,
	}) {
		return (
			<div className="upload__image-wrapper" style={_y().photobooth}>
				<div style={{ display: "flex" }}>
					{imageList.length > 0 && (
						<>
							<div
								style={{
									..._y().image_box,
									backgroundImage: `url(${imageList?.[0].data_url})`,
									height: 130,
									width: 130,
								}}
								onContextMenu={(e) => {
									e.preventDefault();
									openContextMenu(0);
								}}
							>
								<button onClick={() => onImageRemove(0)} style={_y().delete}>
									<RiDeleteBin6Line color="white" />
								</button>
							</div>

							<Spacing horizontal={30} />
						</>
					)}

					<button
						style={{ ..._y().photobooth_btn, zIndex: 800 }}
						onClick={() => {
							return (photos ?? []).length < 5 ? onImageUpload() : null;
						}}
					>
						<HiPlus
							size={50}
							color="var(--primary1)"
							style={{ marginBottom: 10 }}
						/>

						<div style={{ display: "flex" }}>
							<BsImages />

							<div>Add Photo</div>
						</div>

						<Spacing vertical={10} />

						<div style={{ fontSize: 10 }}>
							Uploaded {imageList?.length ?? 0}/5 Photo
						</div>
					</button>
				</div>

				<Spacing vertical={30} />

				<div
					style={{
						display: "grid",
						gridTemplateColumns: `repeat(auto-fit, minmax(${
							_mobile ? 85 : 100
						}px, ${_mobile ? 85 : 110}px))`,
						// gridTemplateRows: "1fr 1fr",
					}}
				>
					{imageList?.map((photoImg, index) =>
						index === 0 ? (
							<></>
						) : (
							<div
								key={index}
								style={{
									..._y().image_box,
									backgroundImage: `url(${photoImg.data_url})`,
									marginBottom: 10,
								}}
								onContextMenu={(e) => {
									e.preventDefault();
									openContextMenu(index);
								}}
							/>
						)
					)}
				</div>

				{menuOpen.isOpen && (
					<div style={_y().menu}>
						<div
							style={{
								..._y().menuItem,
								border: "none",
								justifyContent: "end",
								alignItems: "center",
								marginRight: 30,
								cursor: null,
							}}
						>
							<CgClose
								color="var(--primary1)"
								onClick={() => setMenuOpen(false)}
								style={{
									cursor: "pointer",
								}}
								size={20}
							/>
						</div>

						<div style={_y().menuItem} onClick={setAsCover}>
							Set as Cover Photo
						</div>

						<div
							onClick={() => {
								onImageRemove(menuOpen.index);
								setMenuOpen({ ...menuOpen, isOpen: false });
							}}
							style={{ ..._y().menuItem, color: "red" }}
						>
							Delete
						</div>
					</div>
				)}
			</div>
		);
	}

	return (
		<ImageUploader
			multiple
			value={photos}
			onChange={(img) => {
				onChange(img);
				// setpreviewShown(true);
			}}
			// maxNumber={1}
			dataURLKey="data_url"
		>
			{(
				imageList,
				onImageUpload,
				onImageRemoveAll,
				onImageUpdate,
				onImageRemove,
				isDragging,
				dragProps
			) => {
				return getImageBox(
					imageList,
					onImageUpload,
					onImageRemoveAll,
					onImageUpdate,
					onImageRemove,
					isDragging,
					dragProps
				);
			}}
		</ImageUploader>
	);
}

export default PhotoBooth;
