export const siteMapData = {
	"Customer Support": ["Support", "Feedback", "Contact Us", "Advertizing"],

	"Quichmechs Group": [
		"About Our Ads",
		"About Quickmechs",
		"Register Shop",
		"Spare Part Store",
		"Mechanic & Services",
	],

	"Our Sites": [
		"Alabama",
		"Alaska",
		"Arizona",
		"Arkansas",
		"Califonia",
		"Colorado",
		"Connecticut",
		"Delaware",
		"Florida",
		"Georgia",
		"Hawaii",
		"Idaho",
		"Illinois",
		"Indiana",
		"Iowa",
		"Kansas",
		"Kentucky",
		"Louisiana",
		"Maine",
		"Maryland",
		"Massachusetts",
		"Michigan",
		"Minnesota",
		"Mississippi",
		"Missouri",
		"Montana",
		"Nebraska",
		"Nevada",
		"New Hampshire",
		"New Jersey",
		"New Mexico",
		"New York",
		"North Carolina",
		"North Dakota",
		"Ohio",
		"Oklahoma",
		"Oregon",
		"Pennsylvania",
		"Rhode Island",
		"South Carolina",
		"South Dakota",
		"Tennessee",
		"Texas",
		"Utah",
		"Vermont",
		"Virginia",
		"Washington",
		"West Virginia",
		"Wisconsin",
		"Wyoming",
	],
};

export const customer_support = [
	{
		id: 1,
		title: "Support",
	},
	{
		id: 2,
		title: "Feedback",
	},
	{
		id: 3,
		title: "Contact Us",
	},
	{
		id: 4,
		title: "Advertising",
	},
];

export const group = [
	{
		id: 1,
		title: "About Our Ads",
	},
	{
		id: 2,
		title: "About quickmechs",
	},
	{
		id: 3,
		title: "Register Shop",
	},
	{
		id: 4,
		title: "Spare Part Store",
	},
	{
		id: 5,
		title: "Mechanic & Services",
	},
];

export const our_sites = [
	{
		id: 1,
		title: "Abia",
	},
	{
		id: 2,
		title: "Adamawa",
	},
	{
		id: 3,
		title: "Akwa-Ibom",
	},
	{
		id: 4,
		title: "Anambra",
	},
	{
		id: 5,
		title: "Bauchi",
	},
	{
		id: 6,
		title: "Bayelsa",
	},
	{
		id: 7,
		title: "Benue",
	},
	{
		id: 8,
		title: "Borno",
	},
	{
		id: 9,
		title: "Cross-River",
	},
	{
		id: 10,
		title: "Delta",
	},
	{
		id: 11,
		title: "Ebonyi",
	},
	{
		id: 12,
		title: "Edo",
	},
	{
		id: 13,
		title: "Ekiti",
	},
	{
		id: 14,
		title: "Enugu",
	},
	{
		id: 15,
		title: "Gombe",
	},
	{
		id: 16,
		title: "Imo",
	},
	{
		id: 17,
		title: "Jigawa",
	},
	{
		id: 18,
		title: "Kaduna",
	},
	{
		id: 19,
		title: "Kano",
	},
	{
		id: 20,
		title: "Kastina",
	},
	{
		id: 21,
		title: "Kebbi",
	},
	{
		id: 22,
		title: "Kogi",
	},
	{
		id: 23,
		title: "Kwara",
	},
	{
		id: 24,
		title: "Lagos",
	},
	{
		id: 25,
		title: "Nasarawa",
	},
	{
		id: 26,
		title: "Niger",
	},
	{
		id: 27,
		title: "Ogun",
	},
	{
		id: 28,
		title: "Ondo",
	},
	{
		id: 29,
		title: "Osun",
	},
	{
		id: 30,
		title: "Oyo",
	},
	{
		id: 31,
		title: "Plateau",
	},
	{
		id: 32,
		title: "Rivers",
	},
	{
		id: 33,
		title: "Sokoto",
	},
	{
		id: 34,
		title: "Taraba",
	},
	{
		id: 35,
		title: "Yobe",
	},
	{
		id: 36,
		title: "Zamfara",
	},
];
