import { Paper } from "@material-ui/core";
import React from "react";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Align, Spacing } from "../../../Components/Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { GoDot as GoPrimitiveDot } from "react-icons/go";

function CarInsurance() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			<Align verticalHeight={_mobile ? 0 : 20}>
				<Paper style={_x(_mobile).carInsurance} elevation={_mobile ? 0 : 1}>
					<div>
						<h3 style={_x(_mobile).header}>
							Finding the right Car Dealer In The United States{" "}
						</h3>
					</div>

					<section className="card-content" style={_x(_mobile).main}>
						<p style={_x(_mobile).text3}>
							As you going in search of a car, you must not do so as a complete
							novice. Be sure you carry out your due diligence so you don’t fall
							prey to fraudsters in your quest for a good car deal.
						</p>

						<p style={_x(_mobile).text3}>
							If you want to have a great first-time experience in a car
							purchase, it is important that you carry out quality research—not
							just for what you want but also for the company you intend to deal
							with.
						</p>

						<p style={_x(_mobile).text3}>
							In this article, we’ll show you all you need to know about car
							insurance in the United States. Here we go!
						</p>

						<div
							style={{
								..._x(_mobile).bullet_header,
								marginTop: 30,
								marginBottom: 30,
							}}
						>
							What do you consider when choosing a car dealer in the U.S?
						</div>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>Budget.</span> One of the
							first things to consider is your budget. It is not always about
							what you want but what you have a budget for. You should take a
							close look at your budget and then search for a car dealer that
							has a plan that suits your budget.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>Specification.</span>
							Specification. Not everyone in need of a car would be in need of
							the same car. You should have your specifications in mind and use
							that as a guide in choosing your dealer. Not every dealer would
							have the exact car specifications, and that shouldn’t be much of a
							challenge to you.
						</p>

						<p style={_x(_mobile).text3}>
							You have various dealers to pick from, so keep searching till you
							find what you want. It is possible, you may not get exactly what
							you want as your budget can be a limitation, but some dealers can
							give you something close to the desired car which would serve you
							well enough.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>Price and Operations.</span>
							Of course, price is a major factor to consider and you can’t
							neglect it. In choosing a dealer, it would be wise to compare not
							just prices but also their mode of operation. Inquire about how
							they handle shipment and post-sale activities like servicing and
							part replacement. You should also check the warranty that comes
							with the car.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>Affiliation. </span> If case
							you want to obtain a car loan or work with a company that will
							make your purchase simpler and safer for you, you should also
							inquire about the car dealer's connections to insurance companies
							and auto finance institutions. If they have none, it would be in
							your best interest to search for a company that offers such.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>Reputation.</span> One of the
							most important but undervalued aspects of auto dealers is their
							reputation. In this instance, reputation will be based on the
							reviews that are now visible online. Given how much money an
							automobile represents, you should be careful not to throw money
							away.
						</p>

						<p style={_x(_mobile).text3}>
							Your choice will also be heavily influenced by the experience of
							each dealer. Long-standing dealerships are familiar with the
							workings of the market and how to best serve their clients. Take
							advantage of this opportunity to benefit from their top-notch
							services.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>Customer Service. </span>
							Customers have a lot of power in the unusual service sector known
							as the car dealership. Until you interact with a particular
							company, it can be challenging to determine what they represent.
							Your initial encounter should make a good impression and provide
							you, who is a potential customer the assurance you need to make
							that purchase.
						</p>

						<p style={_x(_mobile).text3}>
							Let your friends and family members tell you about their
							interactions with these car dealerships and if they have done
							business with them in the past. In the event of sincere criticism,
							move on to another dealer who cherishes their clients. This vital
							information can also be found from trustworthy sources such as
							online reviews.
						</p>

						<div
							style={{
								..._x(_mobile).bullet_header,
								marginTop: 30,
								marginBottom: 30,
							}}
						>
							List of Car Dealers in the U.S Mistakes You Should Avoid with Car
							Dealers in the U.S
						</div>

						<p style={_x(_mobile).text3}>
							When searching for a car, it's not so difficult to become
							distracted. This is either due to the overwhelming process or the
							enthusiasm of a potential car owner. However, as a buyer, you must
							exercise caution, particularly if it is your first purchase.
						</p>

						<p style={_x(_mobile).text3}>
							The following are pitfalls to avoid when dealing with your auto
							dealer.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>Not making inquiries. </span>
							Customers have a lot of power in the unusual service sector known
							as the car dealership. Until you interact with a particular
							company, it can be challenging to determine what they represent.
							Your initial encounter should make a good impression and provide
							you, who is a potential customer the assurance you need to make
							that purchase.
						</p>

						<p style={_x(_mobile).text3}>
							You can use this to make more educated purchasing decisions. Avoid
							future issues with government authorities by requesting proof of
							legality for any car sale.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>
								Picking based on appearance.
							</span>
							Customers have a lot of power in the unusual service sector known
							as the car dealership. Until you interact with a particular
							company, it can be challenging to determine what they represent.
							Your initial encounter should make a good impression and provide
							you, who is a potential customer the assurance you need to make
							that purchase.
						</p>

						<p style={_x(_mobile).text3}>
							Examine the car properly and ensure there is no engine noise,
							slipping gears, fluid leaks, or shift lagging. Inspect the warning
							lights as well, they come on if there is a problem with the
							vehicle. Even when purchasing a used vehicle, the auto dealer is
							required to disclose any existing problems with the vehicle.
						</p>

						<p style={_x(_mobile).text3}>
							If you know nothing about cars, as this might be your first car,
							it is advisable that you go with a mechanic you trust, who is also
							experienced.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>Buying in a hurry.</span>
							In a bid to get a car as soon as possible, your haste might cause
							you more harm than good. It is possible that you can spot a “good
							deal” and by trying to jump on it real quick, you may miss out on
							the necessary checks which should be done.
						</p>

						<p style={_x(_mobile).text3}>
							The good deal automatically becomes a terrible one. But it could
							have been avoided if you were not so much in a hurry. It is also
							one of the ways customers get scammed. Fraudsters know that they
							can get people by making an offer too good to be true.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>Going for the hype.</span>
							You might be persuaded to buy a car because the salesperson
							claimed it was unique. If this car doesn't have a service center
							in your city. Or if components are difficult to come by. Choose
							brands and models that are more commonplace so you can replace
							them when necessary.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ fontWeight: "bold" }}>
								Not conducting a brief test drive.
							</span>
							Some customers, get the opportunity to test-drive the car.
							However, they don't finish it completely. They may circle a few
							blocks, but they never shift out of second gear. It is almost
							impossible to form a reliable opinion of how the car really is
							with a brief test drive. Before making a purchase decision, always
							ask the dealer to take the car for a good test drive.
						</p>

						<div
							style={{
								..._x(_mobile).bullet_header,
								marginTop: 30,
								marginBottom: 30,
							}}
						>
							Do not fall victim to fraudulent Car Dealers
						</div>

						<p style={_x(_mobile).text3}>
							Avoid being a victim of fraudulent auto dealers. Dealers like this
							are prevalent around the world, and the United States is no
							different. This is why it's crucial to reiterate that you should
							exercise caution and research any prospective vehicle dealer
							before making a purchase. Here are some significant cautionary
							signs to be aware of:
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Fake vehicle dealers frequently request payment to a separate bank
							account or offer bank information that does not match the company
							name.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							For the kind and caliber of the vehicle they say they are
							"selling," their price offers are too low. In light of this, it's
							a good idea to decide on the price range for the car you wish to
							purchase. Even if a dealer claims that a cheap price is indicative
							of a distress sale, avoid doing business with them.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Dealers without a known address where you can visit the vehicle or
							get in touch with them afterward should be avoided.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Some fraudulent dealers will really offer you a car, but they will
							withhold all the crucial information that would indicate whether
							or not the transaction was legal.
						</p>

						<div
							style={{
								..._x(_mobile).bullet_header,
								marginTop: 30,
								marginBottom: 30,
							}}
						>
							List of Car Dealers in the U.S
						</div>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Ford
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Chevrolet
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Ram Trucks
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Chrysler
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Jeep
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Dodge
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Buick
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							GMC
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Toyota
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Polaris Ace
						</p>

						<div
							style={{
								..._x(_mobile).bullet_header,
								marginTop: 30,
								marginBottom: 30,
							}}
						>
							List of Car Dealers in the U.S
						</div>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Best Deals Auto Sales
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							World’s Best Auto
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Auto Land U.S.A
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							The Best Used Car Sales
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Best Price Dealer
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Best Auto Sales LLC
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Auction Direct U.S.A
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Atlanta Best Used Cars
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Star Auto Sales Car Dealership
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Overdrive U.S.A
						</p>

						<p style={_x(_mobile).text3}>
							Quickmechs provides you with access to reputable and verified car
							dealers in the United States. You don’t have to hop around from
							one platform to another and by so doing play into the hands of
							fraudsters. All the information you need is offered to you at
							Quickmechs
						</p>
					</section>
				</Paper>
			</Align>

			<DownloadApp />

			<Footer />
		</>
	);
}

export default CarInsurance;

const _x = (_mobile) => ({
	carInsurance: {
		width: _mobile ? "100%" : 1200,
		background: "white",
		padding: _mobile ? "20px 30px" : "0",
		marginTop: _mobile ? 0 : 50,
	},
	main: {
		padding: _mobile ? "10px 3px" : "10px 200px",
	},
	header: {
		fontSize: 18,
		fontWeight: "bold",
		background: "var(--primary1)",
		color: "white",
		padding: _mobile ? "10px 20px" : "10px 200px",
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		margin: 0,
	},

	text1: {
		fontSize: 17,
		fontWeight: "bold",
		color: "var(--secondary1)",
		color: "#545C70",
	},

	text2: {
		fontSize: 12,
		fontWeight: "bold",
		color: "var(--secondary1)",
		lineHeight: 1.8,
		color: "#545C70",
	},

	text3: {
		fontSize: 15,
		// lineHeight: 1.8,
		padding: 0,
		color: "#545C70",
	},

	bullet: {},

	bullet_header: {
		color: "var(--baseBg)",
		fontWeight: "500",
		fontSize: 16,
		color: "#545C70",
	},

	li: {
		lineHeight: 1.8,
		color: "#545C70",
	},
});
