import { Modal } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import {
	GoogleMap,
	Marker,
	withGoogleMap,
	withScriptjs,
} from "react-google-maps";
import { BsSearch } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import PlacesAutocomplete from "react-places-autocomplete";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { compose, withProps } from "recompose";
import AdService from "src/Components/Functional/AdService/AdService";
import Chips from "src/Components/Functional/Chips/Chips";
import DownloadApp from "src/Components/Functional/DownloadApp/DownloadApp";
import Footer from "src/Components/Functional/Footer/Footer";
import MechStorePost from "src/Components/Functional/MechStore/MechStorePost";
import PlaceHolder from "src/Components/Functional/PlaceHolder/PlaceHolder";
import SparePartBanner from "src/Components/Functional/SparePartBanner/SparePartBanner";
import TimeBox from "src/Components/Functional/TimeBox/TimeBox";
import { Spacing } from "src/Components/Style/Styles";
import { geocodeFromAddress } from "src/Global/Global";
import caution from "src/Resources/Icons/caution.png";
import history_icon from "src/Resources/Icons/history.png";
import locationImg from "src/Resources/Icons/location.png";
import ad from "src/Resources/Images/ad.jpg";
import ad2 from "src/Resources/Images/ad2.jpg";
import ad3 from "src/Resources/Images/ad3.png";
import { makes, skillTree } from "src/Resources/Resources";
import mechService from "src/services/mech.service";
import close2 from "../../../Resources/Icons/close2.png";
import filter from "../../../Resources/Icons/filter2.png";
import flag from "../../../Resources/Icons/flag.png";
import mapIcon from "../../../Resources/Icons/mapIcon.png";
import vehicletype from "../../../Resources/Icons/vehicletype.png";

function MSQueryList() {
	const [latitude, setLatitude] = useState(37.7749);

	const [longitude, setLongitude] = useState(-122.4194);

	const [pointers, setpointers] = useState([]);

	const [openMap, setOpenMap] = useState(false);

	const [singleMechStore, setSingleMechStore] = useState({
		isOpen: false,
		data: [],
	});

	const [serviceData, setserviceData] = useState([]);

	Geocode.setApiKey("AIzaSyC8MxZB_qWnOrh1E4RnSE4rqDuMsCGIAek");
	Geocode.setLanguage("en");
	Geocode.setLocationType("ROOFTOP");

	function markerPressed(name) {
		setSingleMechStore(!singleMechStore);

		let _singleStoreData = serviceData.filter((each) => each.key === name);

		setSingleMechStore({
			...singleMechStore,
			isOpen: true,
			data: _singleStoreData,
		});
	}

	const MyMapComponent = compose(
		withProps({
			googleMapURL:
				"https://maps.googleapis.com/maps/api/js?key=AIzaSyC2nYnaejAUiy32kKgu3ijbHoLpca8G2-4&v=3.exp&libraries=geometry,drawing,places",
			loadingElement: <div style={{ height: `100%` }} />,
			containerElement: <div style={{ height: `200px` }} />,
			mapElement: <div style={{ height: `100%` }} />,
		}),
		withScriptjs,
		withGoogleMap
	)((props) => {
		console.log(latitude, longitude);

		return (
			<GoogleMap
				defaultZoom={14}
				defaultCenter={{ lat: latitude, lng: longitude }}
				defaultOptions={{
					zoomControl: false,
					mapTypeControl: false,
					streetViewControl: false,
					keyboardShortcuts: false,
					mapData: false,
					termsOfUse: false,
				}}
			>
				{props.isMarkerShown && (
					<GoogleMap>
						<Marker
							position={{ lat: latitude, lng: longitude }}
							icon={{ url: mapIcon }}
						>
							{/* <InfoWindow>
							<div>{name}</div>
						</InfoWindow> */}
						</Marker>
					</GoogleMap>
				)}
			</GoogleMap>
		);
	});

	return (
		<MSQueryListInferior
			MyMapComponent={MyMapComponent}
			setLongitude={setLongitude}
			setLatitude={setLatitude}
			setpointers={setpointers}
			pointers={pointers}
			openMap={openMap}
			setOpenMap={setOpenMap}
			singleMechStore={singleMechStore}
			setSingleMechStore={setSingleMechStore}
			markerPressed={markerPressed}
			serviceData={serviceData}
			setserviceData={setserviceData}
		/>
	);
}

function MSQueryListInferior({
	MyMapComponent,
	setLongitude,
	setLatitude,
	pointers,
	setpointers,
	openMap,
	setOpenMap,
	singleMechStore,
	setSingleMechStore,
	markerPressed,
	setserviceData,
}) {
	const { category, sub_category, location, vehicle_type } =
		useLocation().state;
	const [query, setquery] = useState({
		category,
		sub_category,
		location,
		vehicle_type,
		time_range: "",
	});
	const [vehicletypestate, setvehicletypestate] = useState(vehicle_type);
	const [servicelist, setservicelist] = useState([]);
	const [altservicelist, setaltservicelist] = useState([]);
	const [isloading, setisloading] = useState(true);
	const [isfirstload, setisfirstload] = useState(true);
	const [progress, setProgress] = useState(0);
	const [postlimit, setpostlimit] = useState(1);
	// const [limiter, setlimiter] = useState(1);
	const [instantNotice, setinstantNotice] = useState(false);
	const [mobileNotice, setmobileNotice] = useState(false);
	const [querytags, setquerytags] = useState([
		// query.location,
		// query.vehicle_type,
		query.time_range,
	]);
	const [timeboxmodal, settimeboxmodal] = useState(false);
	const [meta, setmeta] = useState({});
	const [showcovid, setshowcovid] = useState(true);
	const [instantEnabled, setinstantEnabled] = useState(false);
	const [mobileEnabled, setmobileEnabled] = useState(false);
	const _mobile = useMediaQuery("(max-width:800px)");

	const [history_panel, set_history_panel] = useState({
		isOpen: false,
		history: ["Abuja, Nigeria"],
	});
	const [banner1, setbanner1] = useState({
		location: "",
		vehicletype: "",
		suggestionsOpen: false,
	});
	const location_ref = useRef();

	useEffect(() => {
		setisloading(true);
		fetchMechStores();
		fetchAltMechStores();
		setisloading(false);

		const btn2 = document.getElementById(query.category + "query");

		if (btn2) btn2.style.borderBottom = "2px solid var(--accent1)";
	}, []);

	useEffect(() => {
		setquerytags([
			// query.location, query.vehicle_type,
			query.time_range,
		]);
	}, [query]);

	async function fetchMechStores(time, options) {
		let _services = [];
		setProgress(97);

		const geoCode = await geocodeFromAddress(query.location);
		console.log("geoCode", geoCode);

		const resp = await mechService.filterServices(
			query.category,
			query.sub_category,
			options?.defaultLocation != null
				? options?.defaultLocation
				: query.location,
			vehicletypestate,
			time,
			options?.more ? postlimit : 0,
			geoCode.lat,
			geoCode.long
		);

		if (resp.success) {
			// setmeta(resp.meta);
			setProgress(100);

			let _pointer_arr = [];

			resp.data.map((each, idx) => {
				Geocode.fromAddress(each.mechanic?.office_address).then(
					(response) => {
						const { lat, lng } = response.results[0].geometry.location;
						let latt = lat;
						let longg = lng;

						_pointer_arr.push({
							id: idx,
							latitude: latt,
							longitude: lng,
							name: each.mechanic.shop_name,
						});
						setLatitude(latt);
						setLongitude(longg);
					},
					(error) => {
						console.error(error);
					}
				);

				_services.push({
					city: each.user.city,
					image: each.company_photo[0],
					shopName: each.shop_name,
					email: each.user.email,
					state: each.user.state,
					reviews: each.customer_reviews,
					phoneNumber: each.phone_number,
					description: each.shop_description,
					vehicleType: vehicletypestate,
					address: each.office_address,
					category: query.category,
					subCategory: query.sub_category,
					mechanicId: each.id,
					mechanicShopId: each.mechanic_shop_id,
					instant: each.instant_service === "yes" ? true : false,
					mobile: each.mobile_service === "yes" ? true : false,
					liked: each.liked,
				});

				return "";
			});

			setpointers([...pointers, _pointer_arr]);

			setservicelist(_services);
		}

		setisfirstload(false);
	}

	async function fetchAltMechStores(time, options) {
		let _services = [];

		const resp = await mechService.getAlternateMechanics(
			query.category,
			query.sub_category,
			query.location,
			vehicletypestate,
			time,
			options?.more ? postlimit : 0
		);

		if (resp.success) {
			console.log(resp);
			setmeta(resp.meta);
			let _pointer_arr = [];

			resp.data.map((each, idx) => {
				Geocode.fromAddress(each.mechanic?.office_address).then(
					(response) => {
						const { lat, lng } = response.results[0].geometry.location;
						let latt = lat;
						let longg = lng;

						_pointer_arr.push({
							id: idx,
							latitude: latt,
							longitude: lng,
							name: each.mechanic.shop_name,
						});
						setLatitude(latt);
						setLongitude(longg);
					},
					(error) => {
						console.error(error);
					}
				);

				_services.push({
					city: each.city,
					image: each.company_photo[0],
					shopName: each.shop_name,
					email: each.email,
					state: each.state,
					reviews: each.reviews,
					phoneNumber: each.phone_number,
					description: each.shop_description,
					vehicleType: vehicletypestate,
					address: each.office_address,
					category: query.category,
					subCategory: query.sub_category,
					mechanicId: each.id,
					mechanicShopId: each.mechanic_shop_id,
					instant: each.instant_service === "yes" ? true : false,
					mobile: each.mobile_service === "yes" ? true : false,
				});
			});

			setpointers([...pointers, _pointer_arr]);

			setaltservicelist(_services);
		}
	}

	const filterInstant = (val) => {
		// if (val) {
		// 	const instant = fetchedMechs.filter(
		// 		(item) => item.instant_service === "yes"
		// 	);
		// 	setinstantMobileList([...instantMobileList, ...instant]);
		// } else {
		// 	setinstantMobileList([]);
		// }
	};

	const filterMobile = (val) => {
		// console.log(servicelist);
		// if (val) {
		// 	const mobile = servicelist.filter(
		// 		(item) => item.mobile_service === "yes"
		// 	);
		// 	setinstantMobileList([...instantMobileList, ...mobile]);
		// } else {
		// 	setinstantMobileList([]);
		// }
	};

	function catChosen(init_name) {
		const cat_name = init_name.substring(0, init_name.length - 5);

		setquery({ ...query, category: cat_name });

		const step2 = document.querySelector(".step2");

		Array.from(step2.childNodes).forEach(
			(btn) => (btn.style.borderColor = "transparent")
		);

		const btn = document.getElementById(init_name);

		btn.style.borderBottom = "2px solid var(--accent1)";

		fetchMechStores();
	}

	function chipChanged(name) {
		setquery({ ...query, sub_category: name });
		fetchMechStores();
	}

	function updateTimeBox(range) {
		setquery({ ...query, time_range: range });

		if (!_mobile) {
			fetchMechStores(range);
		}
	}

	function showMore() {
		console.log("first");
		setpostlimit(postlimit + 1);
		// fetchMechStores(null, { more: true });
		fetchAltMechStores(null, { more: true });
	}

	function getMinorComponent(name) {
		let _comp;

		switch (name) {
			case "banner":
				_comp = (
					<>
						{showcovid && (
							<>
								{isfirstload && !_mobile ? (
									<PlaceHolder type="banner" />
								) : (
									<div
										style={{
											..._x(_mobile).banner,
											marginTop: 0,
										}}
									>
										<img src={caution} alt="caution" style={_x().caution} />

										<div style={_x().banner_col2}>
											<p style={_x().banner_text}>
												Most of our shops may have restrictions in place.
												including self-quarantine, due to COVID-19.
											</p>

											<div style={{ display: "flex" }}>
												<Link
													to="/home/covid-assessment"
													style={{ textDecoration: "none" }}
												>
													<button style={_x().banner_btn}>Find out more</button>
												</Link>

												<Spacing horizontal={40} />

												<button
													style={_x().banner_btn}
													onClick={() => setshowcovid(false)}
												>
													Dismiss
												</button>
											</div>
										</div>
									</div>
								)}
							</>
						)}
					</>
				);
				break;

			case "timebox":
				_comp = (
					<div>
						{isfirstload && !_mobile ? (
							<PlaceHolder type="timebox" />
						) : (
							<>
								<p style={_x().left_header}>Time of Availability</p>
								<div style={_x().time_boxes}>
									<TimeBox
										isActive={query.time_range === "Morning"}
										morning
										pressable
										onClick={updateTimeBox}
									/>

									<TimeBox
										isActive={query.time_range === "Afternoon"}
										afternoon
										pressable
										onClick={updateTimeBox}
									/>

									<TimeBox
										isActive={query.time_range === "Evening"}
										evening
										pressable
										onClick={updateTimeBox}
									/>
								</div>
								<Spacing />
								<p style={_x().left_header}>Time of Availability</p>
								<div style={_x().time_boxes}>
									<TimeBox
										isActive={query.time_range === "Midnight"}
										midnight
										pressable
										onClick={updateTimeBox}
									/>

									<TimeBox
										isActive={query.time_range === "Morning"}
										morning
										pressable
										onClick={updateTimeBox}
									/>

									<TimeBox
										isActive={query.time_range === "Afternoon"}
										afternoon
										pressable
										onClick={updateTimeBox}
									/>

									<TimeBox
										isActive={query.time_range === "Evening"}
										evening
										pressable
										onClick={updateTimeBox}
									/>
								</div>
								<h4
									style={{
										fontSize: "15px",
										fontWeight: "500",
										color: "var(--secondary1",
									}}
								>
									Instant Service
									<span
										style={{
											marginTop: "30px",
											color: "var(--accent1)",
											cursor: "pointer",
											marginLeft: 10,
										}}
										onClick={() => setinstantNotice(true)}
									>
										Learn More
									</span>
								</h4>
								<h4
									style={{
										fontSize: "15px",
										fontWeight: "500",
										color: "var(--secondary1",
									}}
								>
									Mobile Service
									<span
										style={{
											marginTop: "30px",
											color: "var(--accent1)",
											cursor: "pointer",
											marginLeft: 10,
										}}
										onClick={() => setmobileNotice(true)}
									>
										Learn More
									</span>
								</h4>
								<div
									style={{
										marginTop: "20px",
										backgroundColor: "white",
										height: "32px",
										padding: "10px",
										borderRadius: "6px",
										border: "1px solid #00000040",
									}}
								>
									<div className="instantService">
										<p
											style={{
												fontSize: "14px",
												margin: "0",
												marginTop: "7px",
											}}
											onClick={filterInstant}
										>
											Turn On Instant Service
										</p>

										<div
											className=" instantToggle"
											style={{ marginTop: "-7px" }}
										>
											<input
												type="checkbox"
												id="switch"
												value={instantEnabled}
												onChange={(e) => {
													setinstantEnabled(!instantEnabled);
													filterInstant(e.target.value);
												}}
											/>
											<label for="switch">Toggle</label>
										</div>
									</div>
								</div>
								<div
									style={{
										marginTop: "20px",
										backgroundColor: "white",
										height: "32px",
										padding: "10px",
										borderRadius: "6px",
										border: "1px solid #00000040",
									}}
								>
									<div className="instantService">
										<p
											style={{
												fontSize: "14px",
												margin: "0",
												marginTop: "7px",
											}}
											onClick={filterMobile}
										>
											Turn On Mobile Service
										</p>

										<div
											className=" instantToggle"
											style={{ marginTop: "-7px" }}
										>
											<input
												type="checkbox"
												id="switch2"
												value={mobileEnabled}
												onChange={(e) => {
													setmobileEnabled(!mobileEnabled);
													filterMobile(e.target.value);
												}}
											/>
											<label for="switch2">Toggle</label>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				);
				break;

			default:
				break;
		}

		return _comp;
	}

	function closeHistoryPanel(e) {
		const panel = document.querySelector("#history-panel");
		if (panel) panel.style.display = "none";
	}

	function openHistoryPanel(e) {
		e.stopPropagation();
		const panel = document.querySelector("#history-panel");
		if (panel) panel.style.display = "block";
	}

	function closeHistoryPanel(e) {
		const panel = document.querySelector("#history-panel");
		if (panel) panel.style.display = "none";
	}

	function saveSearchHistory(init_address) {
		let final_address;
		if (init_address) final_address = init_address;
		//! restore ===> else final_address = location_field;

		if (final_address !== "") {
			let history = JSON.parse(sessionStorage.getItem("searchHistory"));
			history.push(final_address);
			history = [...new Set(history)];

			sessionStorage.setItem("searchHistory", JSON.stringify(history));

			set_history_panel({
				...history_panel,
				history,
			});
		}
		closeHistoryPanel();
	}

	function closeSuggestionBox(address) {
		setquery({ ...query, location: address });
		saveSearchHistory(address);
	}

	return (
		<>
			{openMap && (
				<div
					style={{
						zIndex: "60",
						height: "100vh",
						width: "100vw",
						position: "fixed",
						overflow: "hidden",
						top: "0",
						left: "0",
					}}
				>
					<p
						style={{
							position: "absolute",
							borderRadius: "5px",
							width: "100vw",
							height: "52px",
							top: "-40px",
							color: "var(--primary1)",
							zIndex: "90",
							backgroundColor: "#f2f2f2",
							padding: "20px 10px 2px",
							left: "0px",
							fontSize: "20px",
							display: "flex",
							alignItems: "center",
							boxShadow: "2px 0px 2px #22222280",
						}}
					>
						<span
							style={{ cursor: "pointer", paddingTop: "7px" }}
							onClick={() => {
								setOpenMap(false);
								setSingleMechStore({ ...singleMechStore, siOpen: false });
							}}
						>
							<IoClose />
						</span>

						<span
							style={{
								fontSize: "16px",
								fontWeight: "500",
								marginLeft: "1rem",
								color: "#000",
								textTransform: "capitalize",
							}}
						>
							{query.location}
						</span>
					</p>

					<MyMapComponent
						isMarkerShown
						labelVisible
						onMarkerClick={markerPressed}
					/>

					{singleMechStore.isOpen && (
						<div
							style={{
								zIndex: "60",
								position: "absolute",
								bottom: "-12%",
								left: "50%",
								transform: "translate(-50%, -50%)",
							}}
						>
							{/* <MechStorePost
								key={singleMechStore.data.key}
								shop_name={singleMechStore.data[0].shop_name}
								image={singleMechStore.data[0].image}
								user_name={singleMechStore.data[0].user_name}
								address={singleMechStore.data[0].address}
								description={singleMechStore.data[0].description}
								phoneNumber={singleMechStore.data[0].phoneNumber}
								email={singleMechStore.data[0].email}
								mechanicId={singleMechStore.data[0].mechanicId}
								mechanicShopId={singleMechStore.data[0].mechanicShopId}
								reviews={singleMechStore.data[0].reviews}
							/> */}
						</div>
					)}
				</div>
			)}

			<div style={_x(_mobile).homeRoute2} onClick={() => closeHistoryPanel()}>
				<div style={_x(_mobile).homeRoute2_row1}>
					<div style={_x().step2} className="step2">
						{Object.keys(skillTree).map((key, _) => (
							<button
								key={key}
								id={key + "query"}
								style={_x().cat_btn}
								onClick={(e) => catChosen(e.target.id)}
							>
								{key}
							</button>
						))}
					</div>

					<hr style={{ width: "99%", margin: 0 }} />

					<div style={_x().step3}>
						{query.category === "Spare Part" ? (
							<SparePartBanner />
						) : (
							<>
								<div className="step3_row1" style={_x().step3_row1}>
									<Chips
										tab={query.category}
										onChipChange={chipChanged}
										initialChip={query.sub_category}
									/>
								</div>

								<div style={_x().step3_row2}>
									<div>
										<div style={_x().step3_header}>Location</div>

										{/* <div
											className="home-panel-search-bar-box"
											style={{ paddingLeft: 10 }}
										>
											<img src={locationImg} alt="" style={{ width: 10 }} />

											<input
												className="home-panel-search-bar"
												placeholder="Location"
												onChange={(e) =>
													setquery({ ...query, location: e.target.value })
												}
												value={query.location}
											/>
										</div> */}

										<div
											style={{ position: "relative" }}
											onClick={(e) => {
												openHistoryPanel(e);
												// closeVehicleDropDown();
											}}
										>
											<PlacesAutocomplete
												value={query.location}
												onChange={(e) => {
													setquery({ ...query, location: e });

													setbanner1({
														...banner1,
														location: e,
														suggestionsOpen: true,
													});

													closeHistoryPanel();
												}}
												onSelect={(address) => closeSuggestionBox(address)}
												// onBlur={simpleValidator.current.showMessageFor(
												// 	"Location"
												// )}
											>
												{({
													getInputProps,
													suggestions,
													getSuggestionItemProps,
													loading,
												}) => (
													<div className="home-panel-search-bar-box">
														<img
															src={locationImg}
															alt=""
															style={{ cursor: "pointer" }}
															onClick={openHistoryPanel}
														/>

														<input
															{...getInputProps({
																// placeholder: "Search by Locations or Shop Name",
																placeholder: "Search by Location",
																className: "home-panel-search-bar",
															})}
															ref={location_ref}
														/>

														{banner1.suggestionsOpen &&
															suggestions.length !== 0 && (
																<div
																	style={_x().suggestion_box}
																	className="autocomplete-dropdown-container"
																>
																	{suggestions.map((each_suggestion) => {
																		return (
																			<div
																				{...getSuggestionItemProps(
																					each_suggestion
																				)}
																				key={each_suggestion.description}
																				style={{
																					..._x().suggestion_post,
																					background: each_suggestion.active
																						? "#fafafa"
																						: "#ffffff",
																					cursor: "pointer",
																				}}
																				onClick={() =>
																					closeSuggestionBox(
																						each_suggestion.description
																					)
																				}
																			>
																				<>{each_suggestion.description}</>
																			</div>
																		);
																	})}
																</div>
															)}
													</div>
												)}
											</PlacesAutocomplete>

											<div
												id="history-panel"
												style={{
													..._x().suggestion_box,
													height: 200,
													width: "100%",
													display: "none",
													zIndex: 10,
												}}
												// className="hideScroll"
											>
												{history_panel.history.map((each_suggestion) => (
													<div
														key={each_suggestion}
														style={{
															..._x().suggestion_post,
															cursor: "pointer",
														}}
														onClick={(e) => {
															e.stopPropagation();
															closeSuggestionBox(each_suggestion);
														}}
													>
														<img
															src={history_icon}
															alt="history"
															style={{
																width: 20,
																marginRight: 10,
																objectFit: "contain",
															}}
														/>

														<>{each_suggestion}</>
													</div>
												))}
											</div>
										</div>
									</div>

									<div>
										<div style={_x().step3_header}>Vehicle</div>

										<div
											className="home-panel-search-bar-box"
											style={{ paddingLeft: 10 }}
										>
											<img src={vehicletype} alt="" />

											<select
												value={vehicletypestate}
												style={{ ..._x().select, marginLeft: 5 }}
												onChange={(e) => setvehicletypestate(e.target.value)}
											>
												<option value="">Vehicle type</option>

												{Object.keys(makes).map((key, _) => {
													return (
														<option key={key} value={key}>
															{key}
														</option>
													);
												})}
											</select>
										</div>
									</div>

									<button
										style={_x().btn2}
										onClick={() => fetchMechStores(query.time_range)}
									>
										Search
									</button>
								</div>
							</>
						)}
					</div>
				</div>

				{_mobile ? (
					<div>
						<div
							style={{
								display: "flex",
								// border: "1px solid",
								height: 50,
								alignItems: "center",
								paddingInline: 10,
							}}
						>
							<BsSearch size={20} color="#587ebf" />

							<Autocomplete
								className="autolocation"
								defaultValue={location}
								style={{ border: "none", color: "#587ebf" }}
								onPlaceSelected={(place) => {
									fetchMechStores(null, {
										defaultLocation: place.formatted_address,
									});
								}}
								// onChange={() => {
								// 	setquery({ ...query, location: place.formatted_address });
								// }}
								options={{
									types: ["establishment", "geocode"],
								}}
							/>
						</div>

						{getMinorComponent("banner")}

						<div style={_x().main_list_top_btn}>
							<button
								style={_x().list_btn}
								onClick={() => settimeboxmodal(true)}
							>
								<img src={filter} alt="" />

								<p style={_x().list_btn_txt}>Sort & Filter</p>
							</button>

							<div style={{ width: 50 }} />

							<button style={_x().list_btn}>
								<img src={flag} alt="" />

								<p
									style={_x().list_btn_txt}
									onClick={() => {
										setOpenMap(true);
										window.scrollTo(0, 0);
									}}
								>
									View in Map
								</p>
							</button>
						</div>

						<div style={{ background: "white" }}>
							<>
								{servicelist
									.filter((e) => {
										let res = true;
										if (instantEnabled) res = e.instant === true;
										if (mobileEnabled) res = e.mobile === true;

										return res;
									})
									.map((each) => {
										console.log("reviews", each.reviews);

										return (
											<MechStorePost
												city={each.city}
												key={each.shopName}
												image={each.image}
												shopName={each.shopName}
												email={each.email}
												state={each.state}
												customerReviews={each.reviews}
												phoneNumber={each.phoneNumber}
												description={each.description}
												vehicleType={each.vehicleType}
												address={each.address}
												category={query.category}
												subCategory={query.sub_category}
												mechanicId={each.mechanicId}
												mechanicShopId={each.mechanicShopId}
												instant={each.instant}
												mobile={each.mobile}
												liked={each.liked}
											/>
										);
									})}
							</>
							<>
								{altservicelist.length !== 0 && (
									<>
										<div
											style={{
												fontWeight: 500,
												fontSize: 17,
												marginBottom: 20,
											}}
										>
											Other related search
										</div>

										{altservicelist
											.filter((e) => {
												let res = true;
												if (instantEnabled) res = e.instant === true;
												if (mobileEnabled) res = e.mobile === true;

												return res;
											})
											.map((each) => {
												console.log("reviews", each.reviews);

												return (
													<MechStorePost
														city={each.city}
														key={each.shopName}
														image={each.image}
														shopName={each.shopName}
														email={each.email}
														state={each.state}
														customerReviews={each.reviews}
														phoneNumber={each.phoneNumber}
														description={each.description}
														vehicleType={each.vehicleType}
														address={each.address}
														category={query.category}
														subCategory={query.sub_category}
														mechanicId={each.mechanicId}
														mechanicShopId={each.mechanicShopId}
														instant={each.instant}
														mobile={each.mobile}
														liked={each.liked}
													/>
												);
											})}
									</>
								)}
							</>
							<div
								style={{
									width: "100%",
									display: "grid",
									placeItems: "center",
								}}
							>
								{meta.to && meta.to < meta.total && (
									<button style={_x().show_more} onClick={showMore}>
										Show More
									</button>
								)}
							</div>
						</div>

						<Modal open={timeboxmodal}>
							<div style={_x().timebox_modal}>
								<div style={_x().timebox_modal_header}>
									<img
										style={{ cursor: "pointer" }}
										src={close2}
										alt="close"
										onClick={() => settimeboxmodal(false)}
									/>
								</div>

								<div style={_x().timebox_modal_main}>
									{getMinorComponent("timebox")}
								</div>

								{/* <div

									style={{
										width: "100%",
										display: "flex",
										justifyContent: "center",
										marginTop: 30,
									}}
								>
									<button
										style={_x().updateTimeBox}
										onClick={() => {
											if (query.time_range !== "") {
												settimeboxmodal(false);
												fetchMechStores();
											}
										}}
									>
										Apply
									</button>
								</div> */}
							</div>
						</Modal>
					</div>
				) : (
					<>
						{!isloading && (
							<div style={_x().main}>
								<div>
									{getMinorComponent("banner")}

									<Spacing vertical={30} />

									<div
										style={{
											display: "grid",
											gridTemplateColumns: "auto 1fr",
											gridGap: 53,
											// background: "red",
										}}
									>
										<div style={_x().main_left}>
											{isfirstload ? (
												<PlaceHolder type="map" />
											) : (
												<div>
													<MyMapComponent isMarkerShown labelVisible />

													<div
														style={_x().map_btn}
														onClick={() => {
															setOpenMap(true);
															window.scrollTo(0, 0);
														}}
													>
														View in Map
													</div>
												</div>
											)}
											<hr />
											{getMinorComponent("timebox")}
										</div>

										<div style={_x().main_center}>
											<>
												{servicelist
													.filter((e) => {
														let res = true;
														if (instantEnabled) res = e.instant === true;
														if (mobileEnabled) res = e.mobile === true;

														return res;
													})
													.map((each) => {
														return (
															<MechStorePost
																city={each.city}
																key={each.shopName}
																image={each.image}
																shopName={each.shopName}
																email={each.email}
																state={each.state}
																customerReviews={each.reviews}
																phoneNumber={each.phoneNumber}
																description={each.description}
																vehicleType={each.vehicleType}
																address={each.address}
																category={query.category}
																subCategory={query.sub_category}
																mechanicId={each.mechanicId}
																mechanicShopId={each.mechanicShopId}
																instant={each.instant}
																mobile={each.mobile}
																liked={each.liked}
															/>
														);
													})}
											</>
											<>
												{altservicelist.length != 0 && (
													<>
														<div
															style={{
																fontWeight: 500,
																fontSize: 17,
																marginBottom: 20,
															}}
														>
															Other related search
														</div>

														{altservicelist
															.filter((e) => {
																let res = true;
																if (instantEnabled) res = e.instant === true;
																if (mobileEnabled) res = e.mobile === true;

																return res;
															})
															.map((each) => {
																return (
																	<MechStorePost
																		city={each.city}
																		key={each.shopName}
																		image={each.image}
																		shopName={each.shopName}
																		email={each.email}
																		state={each.state}
																		customerReviews={each.reviews}
																		phoneNumber={each.phoneNumber}
																		description={each.description}
																		vehicleType={each.vehicleType}
																		address={each.address}
																		category={query.category}
																		subCategory={query.sub_category}
																		mechanicId={each.mechanicId}
																		mechanicShopId={each.mechanicShopId}
																		instant={each.instant}
																		mobile={each.mobile}
																		liked={each.liked}
																	/>
																);
															})}
													</>
												)}
											</>

											<Spacing />

											<div
												style={{
													width: "100%",
													display: "grid",
													placeItems: "center",
												}}
											>
												{meta.to && meta.to < meta.total && (
													<button style={_x().show_more} onClick={showMore}>
														Show More
													</button>
												)}
											</div>

											<Spacing />

											{isfirstload ? (
												<PlaceHolder type="ad-hor" />
											) : (
												<AdService horizontal img={ad} width={730} />
											)}

											<Spacing />

											<div
												style={{
													width: "100%",
													display: "grid",
													placeItems: "center",
												}}
											>
												<p style={_x().text1}>
													Tell us how we can improve our site
												</p>

												<Link
													target="_blank"
													to="/home/satisfaction"
													style={{ textDecoration: "none" }}
												>
													<button style={_x().feedback}>Share Feedback</button>
												</Link>
											</div>
										</div>
									</div>
								</div>

								<div
									style={{ marginLeft: 20, marginTop: showcovid ? 140 : 30 }}
								>
									{isfirstload ? (
										<>
											<PlaceHolder type="ad-ver" />
											<Spacing />
											<PlaceHolder type="ad-ver" />
											<Spacing />
											<PlaceHolder type="ad-ver" />
										</>
									) : (
										<>
											<AdService vertical img={ad2} />
											<Spacing />
											<AdService vertical img={ad3} />
											<Spacing />
											<AdService vertical img={ad3} />
										</>
									)}
								</div>
							</div>
						)}
					</>
				)}

				{isloading && (
					<LoadingBar
						color="#587FC0"
						loaderSpeed={4000}
						progress={progress}
						onLoaderFinished={() => setProgress(0)}
					/>
				)}

				{instantNotice && (
					<section className="update-pop">
						<div className="firstname">
							<p
								style={{
									fontSize: "18px",
									margin: "36px auto 24px",
									colo: "#000",
									fontWeight: "500",
								}}
							>
								Instant Service
							</p>
							<p
								style={{
									margin: "0px auto 36px",
									colo: "#000",
									width: "70%",
									fontSize: "14px",
									lineHeight: "26px",
									letterSpacing: "0.5px",
								}}
							>
								Turn On Instant Service to see Service providers who are ready
								to serve you instantly.
							</p>
							<p
								className="instantCloseBtn"
								onClick={() => setinstantNotice(false)}
							>
								<GrFormClose size={30} />
							</p>
						</div>
					</section>
				)}

				{mobileNotice && (
					<section className="update-pop">
						<div className="firstname">
							<p
								style={{
									fontSize: "18px",
									margin: "36px auto 24px",
									colo: "#000",
									fontWeight: "500",
								}}
							>
								Mobile Service
							</p>
							<p
								style={{
									margin: "0px auto 36px",
									colo: "#000",
									width: "70%",
									fontSize: "14px",
									lineHeight: "26px",
									letterSpacing: "0.5px",
								}}
							>
								Turn On Mobile Service to get a nearby Mechanic to come to your
								location.
							</p>
							<p
								className="instantCloseBtn"
								onClick={() => setmobileNotice(false)}
							>
								<GrFormClose size={30} />
							</p>
						</div>
					</section>
				)}
			</div>

			<div style={{ display: "grid", justifyContent: "center" }}>
				<DownloadApp />
			</div>

			<Footer />
		</>
	);
}

{
	/* {querytags && querytags.length !== 0 && (
											<div style={{ position: "relative", width: 720 }}>
												<div
													style={_x().srollBtn}
													onClick={() => scrollQueryList("left")}
												>
													{"<"}
												</div>
												

												<div
													style={_x().scrollBtn2}
													onClick={() => scrollQueryList("right")}
												>
													{">"}
												</div>
												

												<div
													style={_x().query_tag_list}
													id="query_tag_list"
													className="hideScroll"
												>
													{querytags.map((e) => {
														if (e === "") return null;

														return (
															<div style={_x().query_tag_item}>
																<div>{e}</div>

																<div
																	style={{
																		cursor: "pointer",
																		marginLeft: 20,
																		fontWeight: 600,
																	}}
																	onClick={removeQueryTag}
																>
																	x
																</div>
															</div>
														);
													})}
												</div>
											</div>
										)} */
}

export default MSQueryList;

const _x = (_mobile) => ({
	open_map: {
		zIndex: "60",
		height: "100vh",
		width: "100vw",
		position: "fixed",
		overflow: "hidden",
		top: "0",
		left: "0",
	},

	suggestion_box: {
		position: "absolute",
		width: "100%",
		minHeight: 100,
		background: "white",
		boxShadow:
			"rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
		maxHeight: 400,
		overflowY: "scroll",
		zIndex: 3,
		top: 40,
		borderBottomRightRadius: 12,
		borderBottomLeftRadius: 12,
	},

	suggestion_post: {
		height: 30,
		display: "flex",
		alignItems: "center",
		padding: 20,
		cursor: "pointer",
		fontWeight: 600,
	},

	open_map_text: {
		position: "absolute",
		borderRadius: "5px",
		top: "-40px",
		zIndex: "90",
		backgroundColor: "#f2f2f2",
		padding: "10px 10px 2px",
		cursor: "pointer",
		right: "0px",
		fontSize: "40px",
	},

	open_mech_store: {
		zIndex: "60",
		position: "absolute",
		bottom: "-20%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},

	ad_vertical_type: {
		height: 427,
		width: 124,
		borderRadius: 6,
		backgroundColor: "var(--secondary1)",
		objectFit: "cover",
	},

	select: {
		height: 32,
		width: "100%",
		border: "none",
		borderRadius: 3,
	},

	modal: {
		outline: "none",
		height: "100%",
		display: "grid",
		placeItems: "center",
	},

	homeRoute2: {
		background: "white",
		position: "relative",
		paddingLeft: _mobile ? 20 : 0,
		paddingRight: _mobile ? 20 : 0,
	},

	homeRoute2_row1: {
		width: "100%",
		display: _mobile ? "none" : "flex",
		flexDirection: "column",
		justifyContent: "center",
		// background: "red",
		alignItems: "center",
		backgroundColor: "white",
	},

	banner: {
		backgroundColor: "var(--secondary1)",
		height: _mobile ? "100%" : 75,
		marginTop: 30,
		opacity: 0.7,
		padding: 10,
		borderRadius: 4,
		display: "flex",
		gridGap: 40,
		alignItems: "center",
		paddingRight: _mobile ? 20 : 0,
	},
	caution: {
		width: 25,
		marginLeft: 20,
	},
	banner_col2: {
		// background: "tomato",
		display: "grid",
		alignItems: "center",
		paddingBottom: 10,
	},
	banner_text: {
		color: "white",
	},
	banner_btn: {
		color: "white",
		borderBottom: "1px solid white",
	},

	main_list_top_btn: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyItems: "stretch",
	},

	list_btn: {
		background: "white",
		height: 50,
		borderRadius: 5,
		width: "100%",
		boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
		marginTop: 30,
		marginBottom: 30,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},

	list_btn_txt: { color: "var(--primary1)", fontWeight: 400, marginLeft: 10 },

	main: {
		display: "flex",
		justifyContent: "center",
		// marginLeft: 80,
	},

	main_left: {
		// background: "blue",
		// background: "red",
	},

	query_tag_list: {
		display: "flex",
		// flexWrap: "wrap",
		overflowX: "scroll",
		width: "100%",
		position: "relative",
		// paddingLeft: 30,
		paddingRight: 40,
	},

	srollBtn: {
		height: 30,
		width: 30,
		borderRadius: "50%",
		background: "lightgrey",
		position: "absolute",
		left: -10,
		top: 3,
		display: "grid",
		placeItems: "center",
		fontWeight: "bold",
		zIndex: 200,
		cursor: "pointer",
	},

	scrollBtn2: {
		cursor: "pointer",
		height: 30,
		width: 30,
		borderRadius: "50%",
		background: "lightgrey",
		position: "absolute",
		right: -10,
		top: 3,
		display: "grid",
		placeItems: "center",
		zIndex: 100,
		fontWeight: "bold",
	},

	query_tag_item: {
		padding: 10,
		paddingLeft: 20,
		paddingRight: 20,
		background: "white",
		marginRight: 10,
		borderRadius: 50,
		display: "flex",
		border: "1px solid #dedddd",
		fontWeight: 500,
		flexShrink: 0,
		marginBottom: 20,
	},

	map: {
		height: 167,
		objectFit: "cover",
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
	},
	map_btn: {
		background: "white",
		display: "grid",
		placeItems: "center",
		height: 30,
		color: "var(--primary1)",
		fontSize: 12,
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		width: "100%",
		cursor: "pointer",
	},

	left_header: {
		fontSize: 15,
		color: "var(--secondary1)",
		marginBottom: 8,
	},

	main_center: {
		// background: "red",
	},

	search_box: {
		border: "1px solid var(--secondary5)",
		background: "white",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 6,
		paddingLeft: 10,
	},

	search_icon: {
		width: 20,
	},

	search_box_input: {
		border: "none",
		width: "100%",
		borderRadius: 6,
	},

	timebox_modal: {
		height: "100%",
		width: "100%",
		background: "white",
		display: "grid",
		gridTemplateRows: "auto 1fr auto",
		paddingBottom: 50,
		height: "100%",
		overflowY: "scroll",
	},

	timebox_modal_header: {
		borderBottom: "1px solid lightslategrey",
		padding: 20,
		paddingBottom: 20,
	},

	time_boxes: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
		gridGap: 15,
	},

	timebox_modal_main: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingTop: 30,
	},

	updateTimeBox: {
		background: "var(--primary1)",
		color: "white",
		fontWeight: "bold",
		fontSize: 18,
		borderRadius: 6,
		height: 48,
		width: 174,
	},

	show_more: {
		fontSize: 13,
		fontWeight: "bold",
		minWidth: 100,
		height: 26,
		color: "white",
		background: "var(--primary1)",
		borderRadius: 5,
		marginBottom: 20,
	},

	text1: {
		color: "var(--secondary1)",
		// fontWeight: "bold",
	},

	feedback: {
		width: 122,
		height: 26,
		fontSize: 13,
		color: "var(--primary1)",
		backgroundColor: "white",
		borderRadius: 6,
		dispaly: "grid",
		placeItems: "center",
	},

	step2: {
		height: 72,
		background: "white",
		// width: "100%",
		display: "flex",
		alignItems: "center",
		// paddingRight: 250,
		// paddingLeft: 250,
		width: 1190,
	},

	step3: { width: 1190, paddingRight: 120 },

	cat_btn: {
		fontSize: 12,
		marginRight: 30,
		fontWeight: 500,
		padding: 10,
		// borderRadius: 5,
	},

	btn2: {
		height: 32,
		width: 128,
		fontSize: 18,
		fontWeight: "bold",
		color: "white",
		background: "var(--primary1)",
		borderRadius: 6,
		marginTop: 20,
	},

	step3_row1: {
		display: "flex",
		background: "white",
		width: "100%",
		alignItems: "flex-end",
		// paddingRight: 450,
		// paddingLeft: 250,
		paddingBottom: 20,
		paddingTop: 10,
	},

	sub_cat_btn: {
		color: "var(--primary1)",
		fontSize: 12,
		marginRight: 30,
		paddingBottom: 10,
	},

	step3_row2: {
		display: "grid",
		background: "white",
		width: "100%",
		// paddingRight: 150,
		// paddingLeft: 250,
		paddingBottom: 30,
		gridTemplateColumns: "1fr 1fr auto",
		alignItems: "center",
		gridGap: 40,
		// background: "red",
		paddingRight: 20,
	},

	step3_header: {
		fontSize: 14,
		color: "var(--secondary1)",
		marginBottom: 5,
	},

	input: {
		border: "1px solid var(--secondary5)",
		fontSize: 15,
		width: 361,
		height: 32,
		borderRadius: 6,
	},
});
