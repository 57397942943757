import { Paper } from "@material-ui/core";
import React from "react";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Align, Spacing } from "../../../Components/Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { GoDot as GoPrimitiveDot } from "react-icons/go";

function CarInsurance() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			<Align verticalHeight={_mobile ? 0 : 20}>
				<Paper style={_x(_mobile).carInsurance} elevation={_mobile ? 0 : 1}>
					<div>
						<h3 style={_x(_mobile).header}>
							Car Insurance In the United States
						</h3>
					</div>
					<section className="card-content" style={_x(_mobile).main}>
						<p style={_x(_mobile).text3}>
							It's crucial to safeguard your investment when you purchase or
							lease a car. Having auto insurance can give you peace of mind if
							you are in an accident, your car is stolen, vandalized, or suffers
							natural disaster damage.
						</p>
						<p style={_x(_mobile).text3}>
							People pay annual payments to a car insurance company instead of
							paying for auto accidents out of their pocket. The business then
							covers all or the majority of the costs related to an accident or
							other vehicle damage.
						</p>
						<p style={_x(_mobile).text3}>
							In this article, we’ll show you all you need to know about car
							insurance in the United States. Here we go!
						</p>

						<p style={_x(_mobile).bullet_header}>What is Car Insurance?</p>

						<p style={_x(_mobile).text3}>
							Car insurance is an agreement or contract between you and an
							insurance company. The company takes over the financial burden of
							any accidental damage or theft associated with the insured
							vehicle.
						</p>
						<p style={_x(_mobile).text3}>
							It is crucial to remember that having car insurance in no way
							absolves you of the responsibility of maintaining your vehicle
							properly.
						</p>
						<p style={_x(_mobile).text3}>
							When you acquire car insurance, you are essentially entering into
							a contract with the insurance provider, agreeing to pay premiums
							in return for protection against financial losses resulting from
							accidents or other damage to the vehicle.
						</p>

						<p style={_x(_mobile).bullet_header}>
							How does Car Insurance work in the U.S?
						</p>

						<p style={_x(_mobile).text3}>
							The insurance provider promises to cover your losses following the
							terms of your policy in exchange for a premium payment.
							Individually priced policies enable you to tailor the level of
							coverage to your specific requirements and financial constraints.
						</p>
						<p style={_x(_mobile).text3}>
							Policies often have renewal durations of six or twelve months.
							When it's time to renew the insurance and pay another premium, an
							insurer will let you know.
						</p>
						<p style={_x(_mobile).text3}>
							Virtually all states require car owners to carry bodily injury
							liability insurance, which covers payments related to injuries or
							fatalities that you or another driver causes while operating your
							vehicle.
						</p>
						<p style={_x(_mobile).text3}>
							Additionally, they could demand property damage liability, which
							pays for the damage you or another driver of your automobile
							causes to another vehicle or piece of property.
						</p>
						<p style={_x(_mobile).text3}>
							A few states go beyond that and require auto owners to carry
							medical payments or personal injury protection (PIP), which pays
							for medical costs associated with injuries you or your passengers
							may suffer. Additionally, it will pay for any lost wages and
							associated costs.
						</p>

						<p style={_x(_mobile).bullet_header}>
							Third-Party Car Insurance Vs Comprehensive Vehicle Insurance
						</p>

						<p style={_x(_mobile).text3}>
							The type of coverage it provides is the primary distinction
							between third-party and comprehensive insurance.
						</p>
						<p style={_x(_mobile).text3}>
							Comprehensive auto insurance will also cover your personal damages
							while third-party insurance only protects you against third-party
							losses and damages.
						</p>

						<p style={_x(_mobile).bullet_header}>
							Third-party insurance coverage
						</p>

						<p style={_x(_mobile).text3}>
							Any loss or damage to property, bodily injury, or death of a third
							party (an individual or a corporation) resulting from the insured
							vehicle is covered by third-party insurance.
						</p>
						<p style={_x(_mobile).text3}>
							What this means is that if you damage someone else's car or cause
							them damage with your car, your insurance provider will compensate
							them. This insurance only covers the other individual; it does not
							cover any damage to you or your vehicle. It includes:
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Repairing third-party vehicles or property after an accident.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Third-party medical bills.
						</p>

						<p style={_x(_mobile).bullet_header}>
							Comprehensive car insurance coverage
						</p>
						<p style={_x(_mobile).text3}>
							Comprehensive auto insurance will pay for any damage to your car,
							any family members specified on your policy, and any passengers
							when an accident occurs. Theft, fire, civil unrest, vandalism,
							natural disasters, and other damages are also covered.
						</p>
						<p style={_x(_mobile).text3}>
							Various insurance providers may offer different comprehensive
							insurance packages. A comprehensive insurance package includes:
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							The price of replacing or repairing your car.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Medical expenses for you as a result of vehicle accidents.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Theft of your car.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Repair of third-party property or vehicles as a result of
							negligence.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							The medical bill for the accident of a third party.
						</p>

						<p style={_x(_mobile).text3}>
							Keep in mind that if you use your automobile for business or cab
							services like Bolt and Uber, your personal insurance will not
							protect you only. For a functional package, you must speak with
							your insurance provider.
						</p>

						<p style={_x(_mobile).bullet_header}>
							How to Insure your Car in the U.S
						</p>

						<p style={_x(_mobile).text3}>
							A quick way to get automobile insurance in the U.S is provided
							below:
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Carry out your research. Compare insurance plans and packages
							offered by different businesses to find the best deal. Watch out
							for the company's reputation, the claims procedure, the client
							testimonials and the price of replacing or repairing your car.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Choose an Insurer. Decide what kind of auto insurance you want by
							making your pick. Either visit their office or apply through their
							website. You will be given a form with all the necessary
							information on it. Before applying, you must comprehend the terms
							of any insurance arrangement, including the policy.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							A certificate including all the details of your insurance is
							issued by the insurance company. The authenticity of this policy
							will be verified as the next course of action to take.
						</p>
						<p style={_x(_mobile).bullet_header}>
							Why is Car Insurance Important?
						</p>

						<p style={_x(_mobile).text3}>
							In addition to the required minimum insurance coverage. The
							financial security that auto insurance provides in the event of
							accidents is sufficient justification for purchasing it. Here are
							some more justifications in case you need them.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Auto insurance is frequently mandated by law.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							If you cause an accident, your car insurance will pay for it.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Damages resulting from "acts of God" are covered by auto
							insurance.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Your health insurance can be supplemented with car insurance.{" "}
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Other people may operate your vehicle with insurance.
						</p>
						<p style={_x(_mobile).bullet_header}>
							Some Car Insurance Companies in the U.S.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							USAA.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							State Farm..
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Allstate.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Travelers.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Progressive.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Nationwide.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							AAA.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							American Family.
						</p>
						<p style={_x(_mobile).text3}>
							Quickmechs is an online platform for connecting automobile owners
							with mechanics and auto parts suppliers. Get started today by
							signing up on quickmechs.com
						</p>
					</section>
				</Paper>
			</Align>

			<DownloadApp />

			<Footer />
		</>
	);
}

export default CarInsurance;

const _x = (_mobile) => ({
	carInsurance: {
		width: _mobile ? "100%" : 1200,
		background: "white",
		padding: _mobile ? "20px 30px" : "0",
		marginTop: _mobile ? 0 : 50,
	},
	main: {
		padding: _mobile ? "10px 3px" : "10px 200px",
	},
	header: {
		fontSize: 18,
		fontWeight: "bold",
		background: "var(--primary1)",
		color: "white",
		padding: _mobile ? "10px 20px" : "10px 200px",
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		margin: 0,
	},

	text1: {
		fontSize: 17,
		fontWeight: "bold",
		color: "var(--secondary1)",
		color: "#545C70",
	},

	text2: {
		fontSize: 12,
		fontWeight: "bold",
		color: "var(--secondary1)",
		lineHeight: 1.8,
		color: "#545C70",
	},

	text3: {
		fontSize: 15,
		// lineHeight: 1.8,
		padding: 0,
		color: "#545C70",
	},

	bullet: {},

	bullet_header: {
		color: "var(--baseBg)",
		fontWeight: "500",
		fontSize: 16,
		color: "#545C70",
	},

	li: {
		lineHeight: 1.8,
		color: "#545C70",
	},
});
