import axios from 'axios';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const API_URL_V2 =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const ENDPOINT = {
  products: 'products',
};

const ROUTE = {
  share: '/share',
};

const getToken = () => localStorage.getItem('userToken');

const getProductType = () => {
  return axios
    .post(API_URL_V2 + ENDPOINT.products, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return {
        success: res.data.status,
      };
    })
    .catch((e) => {
      console.log(e);

      return { success: false };
    });
};

const filterProducts = (
  product,
  location,
  year,
  make,
  model,
  engine,
  condition,
  type,
  warranty,
  negotiable,
  product_no,
  category,
  country,
  state
) => {
  let page = localStorage.getItem('pageNum');
  return axios
    .get(
      API_URL +
        ENDPOINT.products +
        '?search=' +
        `${product}` +
        '&location=' +
        `${location}` +
        '&condition=' +
        `${condition}` +
        '&negotiable=' +
        `${negotiable}` +
        '&warranty=' +
        `${warranty}` +
        '&type=' +
        `${type}` +
        '&make=' +
        `${make}` +
        '&model=' +
        `${model}` +
        '&country=' +
        `${country}` +
        '&state=' +
        `${state}` +
        '&year=' +
        `${year}` +
        '&category=' +
        `${category}` +
        '&page=' +
        page,
      null,
      {
        params: {
          search: product,
          condition,
          location,
          year,
          make,
          model,
          engine,
          type,
          warranty,
          category,
          negotiable,

          //! no model or condition specified in the ui....
        },
      }
    )
    .then((res) => {
      // console.log(res);
      return {
        success: true,
        data: res.data.data,
        metaData: res.data.meta,
      };
    })
    .catch((e) => {
      console.log(e);

      return { success: false };
    });
};

function shareProduct(userEmail, productId) {
  return axios.post(API_URL + ENDPOINT.products + ROUTE.share, {
    email: userEmail,
    product_id: productId,
  });
}

export default {
  shareProduct,
  getProductType,
  filterProducts,
};
