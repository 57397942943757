import { useTextWidth } from "@imagemarker/use-text-width";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import ReactPaginate from "react-paginate";
import { Link, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import FilterBtns from "src/Components/Functional/fiterButtons/FilterBtns";
import productService from "src/services/product.service";
import Footer from "../../../Components/Functional/Footer/Footer";
import SparePart from "../../../Components/Functional/SparePart/SparePart";
import { Spacing } from "../../../Components/Style/Styles";
import {
	allCountries,
	category,
	makes,
	partYear,
	skeletonDummy,
} from "../../../Resources/Resources";
import LoadSkeleton from "./LoadSkeleton";
import "./filter.css";
import "./sort.css";

function SPSQueryList() {
	const [ispage, setispage] = useState(false);
	const [productslist, setproductslist] = useState([]);
	const [pageDetails, setPageDetails] = useState([]);
	const [sortFIlter, setSortFilter] = useState(false);
	const [open, setOpen] = useState(true);
	const [productLength, setProductLength] = useState(false);
	const [skeletonLoad, setSkeletonLoad] = useState([]);
	const [perPage] = useState(20);
	const [offset, setOffset] = useState(0);

	const [pageCount, setPageCount] = useState(0);

	const [isloading, setisloading] = useState(true);
	const [progress, setProgress] = useState(0);
	const [filterState, setFilterState] = useState([]);

	const location = useLocation();

	// responsive select box state management starts here
	const [selectedCategorie, setSelectedCategorie] =
		React.useState("Select Category");
	const width = useTextWidth({ text: selectedCategorie, font: "16px Arial" });
	// stops here

	const [query, setquery] = useState({
		product: location.state.product,
		location: location.state.location,
		year: location.state.year,
		category: "",
		make: location.state.make,
		type: "",
		model: location.state.model,
		condition: "",
		warranty: "",
		negotiable: "",
		product_no: "",
		cat: "Select Category",
		country: "",
		state: location.state.state,
	});

	let arrayList = [];

	useEffect(() => {
		fetchProducts();
		// window.scroll(0, 0);
	}, [
		query.location,
		query.product,
		query.category,
		query.year,
		query.make,
		query.model,
		query.engine,
		query.condition,
		query.type,
		query.warranty,
		query.negotiable,
		query.product_no,
		query.country,
		query.state,
		offset,
		filterState,
	]); //? these fields in the array trigger the useEffect if their values change.....

	async function fetchProducts() {
		let parts = [];

		localStorage.setItem("pageNum", offset);

		setproductslist("");

		setisloading(true);
		setProgress(94);

		const resp = await productService.filterProducts(
			query.product,
			query.location,
			query.year,
			query.make,
			query.model,
			query.engine,
			query.condition,
			query.type,
			query.warranty,
			query.negotiable,
			query.product_no,
			query.category,
			query.country,
			query.state
		);

		if (resp.success) {
			// Sorting product by date
			let sorted = resp.data;
			let metaDetails = resp.metaData;
			setPageDetails(metaDetails);

			let sortedByDate = sorted.sort(function (a, b) {
				return new Date(b.date_created) - new Date(a.date_created);
			});
			setProgress(100);
			const slice = sortedByDate.slice(offset, offset + perPage);

			if (sortedByDate.length === 0) {
				setProductLength(true);
			} else {
				setProductLength(false);
			}
			setSkeletonLoad(sorted);
			console.log(sortedByDate);

			sortedByDate.map((each) => {
				parts.push(
					<SparePart
						key={each.id}
						name={each.product_title}
						price={each.price}
						code={each.product_no}
						image={each.product_photo && each.product_photo[0]}
						// ratingStar={3.6}
						ratingStar={
							each.customer_reviews.total_rating === 0
								? 0
								: Number(each.customer_reviews.average_overall_rating)
						}
						totalRate={each.customer_reviews.total_rating}
						averageRate={
							each.customer_reviews.total_rating === 0
								? "Not Rated"
								: each.customer_reviews.total_rating !== 0
								? each.customer_reviews.average_overall_rating
								: "0.0"
						}
						product_link={`${each.id}` + "/" + `${each.product_title}`}
					/>
				);
			});

			setproductslist(parts);
			setPageCount(Math.ceil(metaDetails.total / perPage));
		}

		setisloading(false);
	}

	const handlePageClick = (e) => {
		const selectedPage = e.selected;
		setOffset(selectedPage + 1);
		window.scrollTo(0, 0);
	};

	const conditionState = (e) => {
		if (e.target.id === "condition_new") {
			const used = document.getElementById("condition_used");
			used.checked = false;
		}

		if (e.target.id === "condition_used") {
			const _new = document.getElementById("condition_new");
			_new.checked = false;
		}

		if (e.target.id === "warranty_type") {
			document.getElementById("warranty3").checked = false;
			document.getElementById("warranty4").checked = false;
			document.getElementById("warranty5").checked = false;
			document.getElementById("warranty6").checked = false;
			document.getElementById("warranty2").checked = false;
		}
		if (e.target.id === "warranty2") {
			document.getElementById("warranty3").checked = false;
			document.getElementById("warranty4").checked = false;
			document.getElementById("warranty5").checked = false;
			document.getElementById("warranty6").checked = false;
			document.getElementById("warranty_type").checked = false;
		}
		if (e.target.id === "warranty3") {
			document.getElementById("warranty2").checked = false;
			document.getElementById("warranty4").checked = false;
			document.getElementById("warranty5").checked = false;
			document.getElementById("warranty6").checked = false;
			document.getElementById("warranty_type").checked = false;
		}
		if (e.target.id === "warranty4") {
			document.getElementById("warranty2").checked = false;
			document.getElementById("warranty3").checked = false;
			document.getElementById("warranty5").checked = false;
			document.getElementById("warranty6").checked = false;
			document.getElementById("warranty_type").checked = false;
		}
		if (e.target.id === "warranty5") {
			document.getElementById("warranty2").checked = false;
			document.getElementById("warranty3").checked = false;
			document.getElementById("warranty4").checked = false;
			document.getElementById("warranty6").checked = false;
			document.getElementById("warranty_type").checked = false;
		}
		if (e.target.id === "warranty6") {
			document.getElementById("warranty2").checked = false;
			document.getElementById("warranty3").checked = false;
			document.getElementById("warranty4").checked = false;
			document.getElementById("warranty5").checked = false;
			document.getElementById("warranty_type").checked = false;
		}

		if (e.target.type === "checkbox" && !e.target.checked) {
			setquery({ ...query, [e.target.name]: "" });
			const index = filterState.indexOf(e.target.value);
			if (index > -1) {
				return filterState.splice(index, 1);
			}
			setFilterState([index]);
		} else {
			setquery({ ...query, [e.target.name]: e.target.value });
			let array1 = filterState;
			let finalArray = [e.target.value];
			setFilterState([
				...filterState,
				...finalArray.filter((item) => !array1.includes(item)),
			]);
		}
	};

	return (
		<div className="main_filter_wrap">
			<div className="mast-head-filter">
				<div className="search_box_line">
					{/* <Select  items={categoryQuery} onChange={(e) => setquery({ ...query, cat: e.target.value })}/> */}
					<select
						className="selectdropDown sel_desktop"
						style={{ width: width + 74 }}
						value={selectedCategorie}
						onChange={(e) => {
							setquery({ ...query, cat: e.target.value });
							setSelectedCategorie(e.target.value);
						}}
					>
						{Object.keys(category).map((key, val) => {
							return (
								<option key={key} value={key}>
									{key}
								</option>
							);
						})}
					</select>
					<select
						className="selectdropDown sel_mobile"
						onChange={(e) => {
							setquery({ ...query, cat: e.target.value });
							setSelectedCategorie(e.target.value);
						}}
					>
						{Object.keys(category).map((key, val) => {
							return (
								<option key={key} value={key}>
									{key}
								</option>
							);
						})}
					</select>

					<div className="search-col-tab">
						<input
							value={query.product}
							onChange={(e) => setquery({ ...query, product: e.target.value })}
							className="drop_input"
							placeholder="Type to Search..."
						/>
						<div
							className="desktop_search_main_btn"
							style={_x.search_icon}
							onClick={() => fetchProducts()}
						>
							<FiSearch style={_x.search_icon_img} />
						</div>
					</div>
				</div>
			</div>
			<div className="mob-sort-col">
				<div className="mob-found-tab">30 items found</div>
				<div
					className="mob-sort-tab"
					onClick={() => setSortFilter(!sortFIlter)}
				>
					Sort & Filter
				</div>
			</div>

			<div className="row1-filter">
				<div className="row-inside-filter">
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div className="page-filter-total">
							<p style={{ width: "130px" }}>
								{pageDetails.from} - {pageDetails.to} of {pageDetails.total}{" "}
								Results
							</p>
						</div>

						<div className="displayed_filter hideScroll">
							{filterState.map((item) => {
								return <FilterBtns filterItem={item} />;
							})}
						</div>
					</div>

					<div style={_x.row1_row2}>
						<p style={_x.row1_row2_text1}>Items On Page</p>

						<div style={_x.dropDown2}> {productslist.length} </div>
					</div>
				</div>
			</div>

			<div className="main_query_wrapper">
				<div className="filter-left">
					<div
						className="filter-sidebar"
						// style={{width:"200px"}}
					>
						<p className="filter-result-tab">Filter Results</p>
						{/* <p
              className="filter-result-mobile"
              onClick={() => setSortFilter(!sortFIlter)}
            >
              <span>
                <BsFilterRight />
              </span>
              Sort & Filter
            </p> */}

						{query.cat !== "Select Category" && (
							<>
								<p
									style={{ fontSize: 13, color: "#000" }}
									className="filter-teg-sub"
								>
									Sub - Category
								</p>
								<ul className="subcategory">
									{category[query.cat].map((eachModel) => {
										return (
											<li>
												<label htmlFor={eachModel}>
													<input
														type="checkbox"
														name="negotiable"
														id={eachModel}
														value={eachModel}
														onChange={conditionState}
													/>
													{eachModel}
												</label>
											</li>
										);
									})}
								</ul>
							</>
						)}

						<p
							style={{ fontSize: 13, color: "#000" }}
							className="filter-teg-sub"
						>
							Condition
						</p>
						<ul className="subcategory">
							<li>
								<label htmlFor="condition_new">
									<input
										type="checkbox"
										name="condition"
										id="condition_new"
										value="New"
										onChange={conditionState}
									/>
									New
								</label>
							</li>
							<li>
								<label htmlFor="condition_used">
									<input
										type="checkbox"
										value="Used"
										id="condition_used"
										name="condition"
										onChange={conditionState}
									/>
									Used
								</label>
							</li>
						</ul>
						{/* <p
							style={{ fontSize: 13, color: "#000" }}
							className="filter-teg-sub"
						>
							Type
						</p>
						<ul className="subcategory">
							<li>
								<label htmlFor="china_type">
									<input
										type="checkbox"
										value="china"
										id="china_type"
										name="type"
										onChange={conditionState}
									/>
									China
								</label>
							</li>
							<li>
								<label htmlFor="belgium_type">
									<input
										type="checkbox"
										value="belgium"
										id="belgium_type"
										name="type"
										onChange={conditionState}
									/>
									Belgium
								</label>
							</li>
						</ul> */}
						<p
							style={{ fontSize: 13, color: "#000" }}
							className="filter-teg-sub"
						>
							Warranty
						</p>
						<ul className="subcategory">
							<li>
								<label htmlFor="warranty_type">
									<input
										type="checkbox"
										value="1 week"
										id="warranty_type"
										name="warranty"
										onChange={conditionState}
									/>
									1 Week
								</label>
							</li>
							<li>
								<label htmlFor="warranty2">
									<input
										type="checkbox"
										value="2 weeks"
										id="warranty2"
										name="warranty"
										onChange={conditionState}
									/>
									2 Weeks
								</label>
							</li>
							<li>
								<label htmlFor="warranty3">
									<input
										type="checkbox"
										value="1 month"
										id="warranty3"
										name="warranty"
										onChange={conditionState}
									/>
									1 Month
								</label>
							</li>
							<li>
								<label htmlFor="warranty4">
									<input
										type="checkbox"
										value="2 months"
										id="warranty4"
										name="warranty"
										onChange={conditionState}
									/>
									2 Months
								</label>
							</li>
							<li>
								<label htmlFor="warranty5">
									<input
										type="checkbox"
										value="1 year"
										id="warranty5"
										name="warranty"
										onChange={conditionState}
									/>
									1 Year
								</label>
							</li>
							<li>
								<label htmlFor="warranty6">
									<input
										type="checkbox"
										value="1 year and above"
										id="warranty6"
										name="warranty"
										onChange={conditionState}
									/>
									2 Years
								</label>
							</li>
						</ul>

						{/* <p style={{ fontSize: 13, color:'#000' }}>Negotiable</p> */}
						{/* <ul className="subcategory">
							<li> <label htmlFor="negotiable1"> 
								<input type="checkbox" value="negotiable" id="negotiable1" name="category" 
								 onChange={conditionState}/>
								Yes</label>
								 </li>
							<li> <label htmlFor="negotiable2"> 
								<input type="checkbox" value="non-negotiable" id="negotiable2" name="category" 
								 onChange={conditionState}/>
								No</label>
								 </li>
						</ul> */}

						{sortFIlter && (
							<div className="sort-filters-mobile">
								<div className="filters-sort-close">
									<p className="sort-head" onClick={() => setSortFilter(false)}>
										<IoClose />
										<span className="sort-title">Sort & Filter Results</span>
									</p>
								</div>
								{/* <div className="mobile-filters-sort">
                  {filterState.map((item) => {
                    return <FilterBtns filterItem={item} />;
                  })}
                </div> */}
								<div className="sort-main">
									<section className="sort-form">
										<label>
											Country
											<select
												name="vehicle-year"
												className="location-sort"
												onChange={(e) =>
													setquery({ ...query, country: e.target.value })
												}
											>
												{/* <option value="">Worldwide</option> */}

												{Object.keys(allCountries).map((key) => (
													<option>{key}</option>
												))}
											</select>
										</label>
										<label>
											State
											<select
												name="vehicle-year"
												className="location-sort"
												onChange={(e) => {
													setquery({ ...query, state: e.target.value });
												}}
											>
												<option selected="selected" value="">
													State
												</option>

												{allCountries[query.country]?.map((stateKey) => (
													<option key={stateKey} value={stateKey}>
														{stateKey}
													</option>
												))}
											</select>
										</label>

										<label>
											Choose a Car Make
											<select
												name="vehicle-make"
												className="make-sort"
												onChange={(e) =>
													setquery({ ...query, make: e.target.value })
												}
											>
												<option
													selected="selected"
													value={query.make === "Select Make" ? "" : query.make}
												>
													{query.make === "" ? "Make" : query.make}
												</option>
												{Object.keys(makes).map((key, val) => {
													return (
														<option
															key={key}
															value={query.make === "Select Make" ? "" : key}
														>
															{key}
														</option>
													);
												})}
											</select>
										</label>
										<label>
											Model
											<select
												name="vehicle-model"
												className="model-sort"
												onChange={(e) =>
													setquery({ ...query, model: e.target.value })
												}
											>
												<option selected="selected" value="">
													Choose a Car Model
												</option>
												{query.make !== "" || query.make !== undefined ? (
													makes[query.make].map((eachModel) => {
														return (
															<option key={eachModel} value={eachModel}>
																{eachModel}
															</option>
														);
													})
												) : (
													<option value="">Model</option>
												)}
											</select>
										</label>
										<label>
											Enter Car Year
											<select
												name="vehicle-year"
												className="year-sort"
												onChange={(e) =>
													setquery({ ...query, year: e.target.value })
												}
											>
												<option
													selected="selected"
													value={
														query.year === "" || query.year === "Select Year"
															? ""
															: query.year
													}
												>
													{query.year === "" ? "Year" : query.year}
												</option>
												{partYear.map((eachYear) => {
													return (
														<option
															key={eachYear}
															value={eachYear === "Select Year" ? "" : eachYear}
														>
															{eachYear}
														</option>
													);
												})}
											</select>
										</label>

										<label>
											Choose a Condition
											<select
												name="condition"
												className="year-sort"
												onChange={(e) =>
													setquery({ ...query, condition: e.target.value })
												}
											>
												<option
													name="condition"
													id="condition_new"
													value=""
													onChange={conditionState}
												>
													Choose condition
												</option>
												<option
													name="condition"
													id="condition_new"
													value="New"
													onChange={conditionState}
												>
													New
												</option>
												<option
													name="condition"
													id="condition_used"
													value="Used"
													onChange={conditionState}
												>
													Used
												</option>
											</select>
										</label>
										<label>
											Choose a Warranty
											<select>
												<option>1 week</option>
												<option>2 weeks</option>
												<option>1 Month</option>
												<option>2 Months</option>
												<option>1 Year</option>
												<option>2 Years</option>
											</select>
										</label>
									</section>

									{/* <ul className="mobile-subcategory"> */}
									{/* {query.cat !== 'Select Category' && (
                      <>
                        <p
                          style={{ fontSize: 13, color: '#000' }}
                          className="filter-tag-sub"
                        >
                          Sub - Category
                        </p>
                        {category[query.cat].map((eachModel) => {
                          return (
                            <li>
                              <label htmlFor={eachModel}>
                                <input
                                  type="checkbox"
                                  name="negotiable"
                                  id={eachModel}
                                  value={eachModel}
                                  onChange={conditionState}
                                />
                                {eachModel}
                              </label>
                            </li>
                          );
                        })}
                      </>
                    )} */}

									{/* </ul> */}

									{/* <ul className="mobile-subcategory"> */}
									{/* <p
                      style={{ fontSize: 13, color: '#000' }}
                      className="filter-tag-sub"
                    >
                      Condition
                    </p>
                    <li>
                      <label htmlFor="condition_new">
                        <input
                          type="checkbox"
                          name="condition"
                          id="condition_new"
                          value="New"
                          onChange={conditionState}
                        />
                        New
                      </label>
                    </li>
                    <li>
                      <label htmlFor="condition_used">
                        <input
                          type="checkbox"
                          name="condition"
                          id="condition_used"
                          value="Used"
                          onChange={conditionState}
                        />
                        Used
                      </label>
                    </li> */}
									{/* </ul> */}

									{/* <ul className="mobile-subcategory"> */}
									{/* <p
											style={{ fontSize: 13, color: "#000" }}
											className="filter-tag-sub"
										>
											Type
										</p>
										<li>
											<label htmlFor="china_type">
												<input
													type="checkbox"
													value="china"
													id="china_type"
													name="type"
													onChange={conditionState}
												/>
												China
											</label>
										</li>
										<li>
											<label htmlFor="belgium_type">
												<input
													type="checkbox"
													value="belgium"
													id="belgium_type"
													name="type"
													onChange={conditionState}
												/>
												Belgium
											</label>
										</li> */}
									{/* </ul> */}
									{/* <ul className="mobile-subcategory"> */}
									{/* <p
                      style={{ fontSize: 13, color: '#000' }}
                      className="filter-tag-sub"
                    >
                      Warranty
                    </p>
                    <li>
                      <label htmlFor="warranty_type">
                        <input
                          type="checkbox"
                          value="1 week"
                          id="warranty_type"
                          name="warranty"
                          onChange={conditionState}
                        />
                        1 Week
                      </label>
                    </li>
                    <li>
                      <label htmlFor="warranty2">
                        <input
                          type="checkbox"
                          value="2 weeks"
                          id="warranty2"
                          name="warranty"
                          onChange={conditionState}
                        />
                        2 Weeks
                      </label>
                    </li>
                    <li>
                      <label htmlFor="warranty3">
                        <input
                          type="checkbox"
                          value="1 month"
                          id="warranty3"
                          name="warranty"
                          onChange={conditionState}
                        />
                        1 Month
                      </label>
                    </li>
                    <li>
                      <label htmlFor="warranty4">
                        <input
                          type="checkbox"
                          value="2 months"
                          id="warranty4"
                          name="warranty"
                          onChange={conditionState}
                        />
                        2 Months
                      </label>
                    </li>
                    <li>
                      <label htmlFor="warranty5">
                        <input
                          type="checkbox"
                          value="1 year"
                          id="warranty5"
                          name="warranty"
                          onChange={conditionState}
                        />
                        1 Year
                      </label>
                    </li>
                    <li>
                      <label htmlFor="warranty6">
                        <input
                          type="checkbox"
                          value="1 year and above"
                          id="warranty6"
                          name="warranty"
                          onChange={conditionState}
                        />
                        2 Years
                      </label>
                    </li> */}
									{/* </ul> */}
									{/* <ul className="mobile-subcategory"> */}
									{/* <p style={{ fontSize: 13, color: "#000" }}>Negotiable</p>
										<li>
											<label htmlFor="negotiable1">
												<input
													type="checkbox"
													value="negotiable"
													id="negotiable1"
													name="category"
													onChange={conditionState}
												/>
												Yes
											</label>
										</li>
										<li>
											<label htmlFor="negotiable2">
												<input
													type="checkbox"
													value="non-negotiable"
													id="negotiable2"
													name="category"
													onChange={conditionState}
												/>
												No
											</label>
										</li> */}
									{/* </ul> */}
								</div>

								<button
									className="apply-btn"
									onClick={() => setSortFilter(false)}
								>
									Apply
								</button>
							</div>
						)}
					</div>
				</div>

				<div className="filter-right">
					<div className="right-row1">
						<div className="filter-text-col">
							<p className="text1">
								Set Your Vehicle:
								<span className="text2">
									{" "}
									Get an exact fit for your vehicle
								</span>
							</p>
						</div>

						<div style={_x.right_dropDrop_lists}>
							<section className=" search-filter">
								<select
									name="vehicle-year"
									className="location-filter"
									onChange={(e) =>
										setquery({ ...query, country: e.target.value })
									}
								>
									{/* <option value="">Worldwide</option> */}

									{Object.keys(allCountries).map((key) => (
										<option>{key}</option>
									))}
								</select>
								<select
									name="vehicle-year"
									className="location-filter"
									onChange={(e) => {
										setquery({ ...query, state: e.target.value });
									}}
								>
									<option selected="selected" value="">
										State
									</option>

									{allCountries[query.country]?.map((stateKey) => (
										<option key={stateKey} value={stateKey}>
											{stateKey}
										</option>
									))}
								</select>
								<select
									name="vehicle-year"
									className="year-filter"
									onChange={(e) => setquery({ ...query, year: e.target.value })}
								>
									<option
										selected="selected"
										value={
											query.year === "" || query.year === "Select Year"
												? ""
												: query.year
										}
									>
										{query.year === "" ? "Year" : query.year}
									</option>
									{partYear.map((eachYear) => {
										return (
											<option
												key={eachYear}
												value={eachYear === "Select Year" ? "" : eachYear}
											>
												{eachYear}
											</option>
										);
									})}
								</select>
								<select
									name="vehicle-make"
									className="make-filter"
									onChange={(e) => setquery({ ...query, make: e.target.value })}
								>
									<option
										selected="selected"
										value={query.make === "Select Make" ? "" : query.make}
									>
										{query.make === "" ? "Make" : query.make}
									</option>
									{Object.keys(makes).map((key, val) => {
										return (
											<option
												key={key}
												value={query.make === "Select Make" ? "" : key}
											>
												{key}
											</option>
										);
									})}
								</select>
								<select
									name="vehicle-model"
									className="model-filter"
									onChange={(e) =>
										setquery({ ...query, model: e.target.value })
									}
								>
									<option selected="selected" value="">
										Model
									</option>
									{query.make !== "" || query.make !== undefined ? (
										makes[query.make].map((eachModel) => {
											return (
												<option key={eachModel} value={eachModel}>
													{eachModel}
												</option>
											);
										})
									) : (
										<option value="">Model</option>
									)}
								</select>
							</section>
						</div>
					</div>

					<div>
						{isloading ? (
							<div className="skel-dummy">
								<div className="right_row4">
									{skeletonDummy.map((each, index) => {
										return <LoadSkeleton key={index} />;
									})}
								</div>
							</div>
						) : (
							<div className="right-row2">
								{!productLength ? (
									productslist
								) : (
									<p className="no-result">No items match your search</p>
								)}
							</div>
						)}
					</div>

					{pageDetails.total > 20 && (
						<ReactPaginate
							previousLabel={" <  Prev"}
							nextLabel={"Next  >"}
							breakLabel={"..."}
							breakClassName={"break-me"}
							pageCount={pageCount}
							marginPagesDisplayed={1}
							pageRangeDisplayed={3}
							onPageChange={handlePageClick}
							containerClassName={"pagination"}
							subContainerClassName={"pages pagination"}
							activeClassName={"active"}
						/>
					)}

					<div
						style={{
							width: "100%",
							display: "grid",
							placeItems: "center",
						}}
					>
						<p style={_x.text11}>Tell us how we can improve our site</p>

						<Spacing />

						<Link
							target="_blank"
							to="/home/satisfaction"
							style={{ textDecoration: "none" }}
						>
							<button style={_x.feedback}>Feedback</button>
						</Link>
					</div>

					<Footer />
				</div>
			</div>

			{/* <DownloadApp />
			<Footer /> */}
			{isloading && (
				<LoadingBar
					color="#587FC0"
					loaderSpeed={13000}
					progress={progress}
					onLoaderFinished={() => setProgress(0)}
				/>
			)}
			<Helmet>
				<title>{"Search Products - quickmechs Solutions"}</title>
			</Helmet>
		</div>
	);
}

export default SPSQueryList;

const _x = {
	text11: {
		color: "var(--secondary1)",
	},

	feedback: {
		width: 122,
		height: 26,
		fontSize: 13,
		color: "var(--primary1)",
		backgroundColor: "#fff",
		borderRadius: 6,
		dispaly: "grid",
		placeItems: "center",
		boxShadow: "1px 1px 4px #64646480",
	},

	search_icon: {
		height: 42,
		width: 42,
		background: "var(--primary1)",
		borderTopRightRadius: 6,
		borderBottomRightRadius: 6,
		display: "grid",
		placeItems: "center",
		padding: "10px",
	},

	search_icon_img: {
		height: 18,
		width: 20,
		color: "#fff",
		fontSize: "16px",
	},

	row1_row2: {
		display: "flex",
		alignItems: "center",
	},

	// row1_row2_text1: {
	// 	color: "var(",
	// },

	dropDown2: {
		width: 58,
		background: "#DBDEE7",
		textAlign: "center",
		height: 28,
		display: "grid",
		placeItems: "center",
		fontSize: 15,
		boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
	},

	subheader_text: {
		fontSize: 18,
	},

	checkbox: {
		height: 15,
		width: 15,
	},

	checkbox_text: {
		fontSize: 12,
	},

	right_dropDrop_lists: {
		height: 64,
		background: "transparent",
		width: "100%",
		marginTop: "-15px",
	},

	page_scroller: {
		height: 30,
		width: "20%",
		display: "grid",
		gridTemplateColumns: "repeat(8, 1fr)",
		placeItems: "center",
	},

	text3_box: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		paddingLeft: 20,
	},

	sub: {
		height: 80,
		width: 80,
		border: "1px solid var(--secondary3)",
		display: "grid",
		placeItems: "center",
	},
	row1_row2_text1: {
		fontSize: "14px",
		marginRight: "3px",
		color: "#646464",
		width: "130px",
	},
};
