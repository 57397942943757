import React, { useEffect, useState } from "react";
import { Spacing } from "../../../Components/Style/Styles";
import close from "../../../Resources/Icons/close.png";
import star from "../../../Resources/Icons/star.png";
import star_blue from "../../../Resources/Icons/star-blue.png";
import star_yellow from "../../../Resources/Icons/star-yellow.png";


function Rating({
	size,
	header,
	headerStyle,
	closeable,
	closeCLicked,
	color,
	onClicked,
	adjustible,
}) {
	const _x = {
		rating: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		header: {
			color: "var(--secondary1)",
			fontSize: 12,
		},
		close: {
			cursor: "pointer",
		},
		star: {
			height: size ?? 20,
			width: size ?? 20,
			marginRight: 5,
			cursor: "pointer",
		},
	};

	const [randId, setrandId] = useState(0);

	useEffect(() => {
		setrandId(getRandomInt(100));

		window.scrollTo(0, 0);
	}, []);

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	function starClicked(e) {
		e.stopPropagation();

		if (adjustible) {
			const { id } = e.target;

			const allStars = document.getElementById(randId + "starlist").childNodes;

			let index = 0;

			let limit = id[id.length - 1];

			Array.from(allStars).forEach((eachstar) => {
				eachstar.src = star;
			});

			Array.from(allStars).forEach((eachstar) => {
				if (index < limit) {
					eachstar.src = color === "blue" ? star_blue : star_yellow;
				}

				index++;
			});

			onClicked(limit, header);
		}
	}

	return (
		<div style={_x.rating}>
			<div style={_x.row1}>
				{header && (
					<>
						<div style={{ ..._x.header, ...headerStyle }}>{header}</div>

						<Spacing vertical={5} />
					</>
				)}

				<div id={`${randId}starlist`} style={_x.stars}>
					<img
						onClick={starClicked}
						id={`${randId}star1`}
						style={_x.star}
						src={star}
						alt="close"
					/>

					<img
						onClick={starClicked}
						id={`${randId}star2`}
						style={_x.star}
						src={star}
						alt="close"
					/>

					<img
						onClick={starClicked}
						id={`${randId}star3`}
						style={_x.star}
						src={star}
						alt="close"
					/>

					<img
						onClick={starClicked}
						id={`${randId}star4`}
						style={_x.star}
						src={star}
						alt="close"
					/>

					<img
						onClick={starClicked}
						id={`${randId}star5`}
						style={_x.star}
						src={star}
						alt="close"
					/>
				</div>
			</div>

			{closeable && (
				<img
					onClick={closeCLicked}
					style={_x.close}
					src={close}
					alt="close"
					height={10}
					width={10}
				/>
			)}
		</div>
	);
}

export default Rating;
