import { useEffect, useState } from "react";
import MobileAd from "src/Routes/Home/Advert/MobileAd";
import AuthService from "src/services/auth.service";
import "./postAd.css";

const PartsDealerReg = () => {
	const [dealer, setDealer] = useState(true);

	const currentUser = AuthService.getCurrentUser("user");

	async function grabLoggedInUser() {
		const resp = await AuthService.getCurrentLoggedInUser();
		if (resp.success === true && resp.data.is_service_provider) {
		} else {
		}
	}

	useEffect(() => {
		grabLoggedInUser();
	}, []);

	return (
		<>
			<main className="new_post_ad">
				<section className="mob_wrap">
					{/* <div className="main-area-col"> */}
					{/* <div className="desktop_form"> <PostFreeAd /></div> */}
					<div
					// className="mobile_form"
					>
						<MobileAd />
					</div>
					{/* </div> */}
				</section>
			</main>
		</>
	);
};

export default PartsDealerReg;
