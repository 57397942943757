import { Paper } from "@material-ui/core";
import React from "react";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Align, Spacing } from "../../../Components/Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function SparePart() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			<Align verticalHeight={_mobile ? 0 : 20}>
				<Paper style={_x(_mobile).fAQ} elevation={_mobile ? 0 : 1}>
					<div style={_x(_mobile).header}>
						<p style={_x(_mobile).title}>SPARE PART DEALER</p>
					</div>

					<div style={_x(_mobile).main}>
						<p style={_x(_mobile).text2}>
							The term spare parts can be defined as parts purchased separately
							to replace old or damagedparts in a vehicle.
						</p>

						<p style={_x(_mobile).text2}>
							In recent times spare parts have become an essential part of
							automobiles in general,emphasizing cars, vehicles, SUVs, and other
							related categories. And we have all encounteredsituations when you
							needed some parts urgently but could not get to access them
							eitherbecause of distance, time, or lack of expertise.quickmechs
							understands these struggles the stress that comes with replacing
							and acquiringspare parts for your vehicle, which is why we came up
							with a seamless solution for all car users.This site offers you a
							simplified, quick, and easy search, which provides you with the
							options ofsorting by the price and selection by grades, location,
							and brands.
						</p>

						<p style={_x(_mobile).text2}>
							On quickmechs, you can get all parts for your automobiles like the
							body, engine, and other sparefacilities. We have made finding and
							purchasing car parts online easier by providing accurateand
							detailed fit information to encourage a straightforward and
							hassle-free experience. Theparts are high-quality aftermarket
							parts by year, make, and model, so you're always guaranteeda
							perfect fit for your vehicle.
						</p>

						<p style={_x(_mobile).text2}>
							For Dealers, we have provided a hassle-free platform where you can
							access potentialcustomers locally/ intentionally and sell our
							products/ parts. With quickmechs, you can easilyadd, remove and
							update parts based on availability, quickmechs also provides easy
							access forcustomers getting in touch with you.
						</p>
					</div>
				</Paper>
			</Align>

			<DownloadApp />

			<Footer />
		</>
	);
}

export default SparePart;

const _x = (_mobile) => ({
	fAQ: {
		maxWidth: _mobile ? "100%" : "1200px",
		background: "white",
		marginTop: _mobile ? 0 : 50,
		borderRadius: 10,
	},

	header: {
		background: "var(--primary1)",
		borderTopRightRadius: _mobile ? 0 : 10,
		borderTopLeftRadius: _mobile ? 0 : 10,
		display: "flex",
		flexDirection: "column",
		paddingInline: _mobile ? 50 : 200,
	},

	title: {
		fontSize: 17,
		fontWeight: "bold",
		color: "white",
	},

	main: {
		paddingBottom: 50,
		paddingInline: _mobile ? 50 : 200,
	},

	subtitle: {
		fontSize: 18,
		fontWeight: 500,
		color: "var(--baseBg)",
	},

	text1: {
		fontSize: 15,
		fontWeight: 400,
		color: "var(--baseBg)",
	},

	text2: {
		fontSize: 14,
		fontWeight: 400,
		color: "var(--baseBg)",
		lineHeight: 1.8,
	},

	text3: {
		fontSize: 12,
		lineHeight: 1.8,
	},

	li: {
		marginBottom: 20,
	},
});
