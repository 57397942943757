import { Paper, useMediaQuery } from "@material-ui/core";
import { useState } from "react";

export default function CovidAssessment() {
	const [covid, setcovid] = useState({
		truthRatio: 0,

		questions: {
			result: { visible: true },

			"Do you have direct contact with or are you taking care of a positive COVID-19 PATIENT?":
				{
					answer: true,
					visible: true,
				},

			"Do you have a history of traveling to an area infected with COVID-19 ?":
				{
					answer: true,
					visible: true,
				},

			"Have you traveled recently during the past 14 days?": {
				answer: true,
				visible: true,
			},

			"Are you experiencing fatigue?": {
				answer: true,
				visible: true,
			},

			"Are you having difficulty breathing?": {
				answer: true,
				visible: true,
			},

			"Do you have fever (Temperature 37.8°C and above)?": {
				answer: true,
				visible: true,
			},

			"Are you having a headache?": { answer: true, visible: true },

			"Are you having body aches?": { answer: true, visible: true },

			"Are you having sorethroat?": { answer: true, visible: true },

			"Are you having Diarrhea?": { answer: true, visible: true },

			"Do you have a cold?": { answer: true, visible: true },

			"Do you have a cough?": { answer: true, visible: true },
		},
	});

	const _responsive = useMediaQuery("(max-width:800px)");

	function chooseAnswer(ques, ans) {
		console.log(covid.truthRatio);

		setcovid({
			...covid,
			truthRatio: ans ? covid.truthRatio + 1 : covid.truthRatio - 1,
			questions: {
				...covid.questions,
				[`${ques}`]: {
					...covid.questions[ques],
					answer: ans,
					visible: false,
				},
			},
		});
	}

	function resolveRisk() {
		if (covid.truthRatio <= (Object.keys(covid.questions).length - 1) / 2)
			return "LOW";
		else return "HIGH";
	}

	function restartTest() {
		const resolution = covid.questions;

		Object.keys(resolution).map((e) => {
			resolution[e]["visible"] = true;
		});

		setcovid({ ...covid, questions: resolution });
	}

	return (
		<div style={_x().assessment}>
			<div style={_x().main}>
				{Object.keys(covid.questions).map((e) => (
					<Paper
						key={e}
						style={{
							..._x(_responsive).card,
							display: covid.questions[e]["visible"] === true ? "grid" : "none",
						}}
						elevation={_responsive ? 0 : 1}
					>
						{e === "result" ? (
							<>
								<div style={_x().question}>
									Result
									<div style={_x().txt1}>
										Your risk of having covid 19 is :
										<span style={_x().txt2}>{resolveRisk()}</span>
									</div>
									<div style={_x().txt3}>
										{resolveRisk() === "HIGH"
											? "Please see a doctor immediately. Do not panic, isolate yourself from friends and family"
											: "Remember to always practise social distancing and wear a mask."}
									</div>
								</div>

								<button onClick={restartTest} style={_x().btn}>
									RESTART TEST
								</button>
							</>
						) : (
							<>
								<div style={_x().question}>{e}</div>

								<button onClick={() => chooseAnswer(e, true)} style={_x().btn}>
									Yes
								</button>

								<button onClick={() => chooseAnswer(e, false)} style={_x().btn}>
									No
								</button>
							</>
						)}
					</Paper>
				))}
			</div>
		</div>
	);
}

const _x = (_responsive) => ({
	assessment: {
		display: "grid",
		gridTemplateColumns: "1fr auto",
	},

	main: {
		position: "relative",
		// left: "-50%",

		left: "50%",
		transform: "translate(-50%, 0)",
	},

	card: {
		position: "absolute",
		left: "50%",
		top: 350,
		transform: "translate(-50%,-50%)",

		height: 600,
		width: _responsive ? "100%" : 600,
		backgroundColor: "white",
		padding: 20,
		display: "grid",
		gridTemplateRows: "1fr auto auto",
		// placeItems: "center",
		paddingBottom: 70,
		paddingTop: 100,
		boxShadow: "0 2px 20px 0 .5rem 1rem rgba(0,0,0,.15)",
	},

	question: {
		color: "var(--secondary1)",
		fontWeight: "bold",
		fontSize: 40,
		textAlign: "center",
	},

	btn: {
		height: 60,
		backgroundColor: "var(--primary1)",
		borderRadius: 5,
		color: "white",
		fontWeight: "bold",
		fontSize: 30,
		marginBottom: 20,
		marginLeft: 50,
		marginRight: 50,
	},

	txt1: {
		marginTop: 10,
	},

	txt2: {
		color: "tomato",
		fontWeight: "bold",
		fontSize: 40,
		marginLeft: 10,
	},

	txt3: {
		marginTop: 80,
		// textAlign: "left",
		padding: 30,
	},
});
