export const cities = [];

export const countryCodes = [
	{ country: "United States", code: "1", iso: "US" },
	{ country: "Afghanistan", code: "93", iso: "AF" },
	{ country: "Albania", code: "355", iso: "AL" },
	{ country: "Algeria", code: "213", iso: "DZ" },
	{ country: "American Samoa", code: "1-684", iso: "AS" },
	{ country: "Andorra", code: "376", iso: "AD" },
	{ country: "Angola", code: "244", iso: "AO" },
	{ country: "Anguilla", code: "1-264", iso: "AI" },
	{ country: "Antarctica", code: "672", iso: "AQ" },
	{ country: "Antigua and Barbuda", code: "1-268", iso: "AG" },
	{ country: "Argentina", code: "54", iso: "AR" },
	{ country: "Armenia", code: "374", iso: "AM" },
	{ country: "Aruba", code: "297", iso: "AW" },
	{ country: "Australia", code: "61", iso: "AU" },
	{ country: "Austria", code: "43", iso: "AT" },
	{ country: "Azerbaijan", code: "994", iso: "AZ" },
	{ country: "Bahamas", code: "1-242", iso: "BS" },
	{ country: "Bahrain", code: "973", iso: "BH" },
	{ country: "Bangladesh", code: "880", iso: "BD" },
	{ country: "Barbados", code: "1-246", iso: "BB" },
	{ country: "Belarus", code: "375", iso: "BY" },
	{ country: "Belgium", code: "32", iso: "BE" },
	{ country: "Belize", code: "501", iso: "BZ" },
	{ country: "Benin", code: "229", iso: "BJ" },
	{ country: "Bermuda", code: "1-441", iso: "BM" },
	{ country: "Bhutan", code: "975", iso: "BT" },
	{ country: "Bolivia", code: "591", iso: "BO" },
	{ country: "Bosnia and Herzegovina", code: "387", iso: "BA" },
	{ country: "Botswana", code: "267", iso: "BW" },
	{ country: "Brazil", code: "55", iso: "BR" },
	{ country: "British Indian Ocean Territory", code: "246", iso: "IO" },
	{ country: "British Virgin Islands", code: "1-284", iso: "VG" },
	{ country: "Brunei", code: "673", iso: "BN" },
	{ country: "Bulgaria", code: "359", iso: "BG" },
	{ country: "Burkina Faso", code: "226", iso: "BF" },
	{ country: "Burundi", code: "257", iso: "BI" },
	{ country: "Cambodia", code: "855", iso: "KH" },
	{ country: "Cameroon", code: "237", iso: "CM" },
	{ country: "Canada", code: "1", iso: "CA" },
	{ country: "Cape Verde", code: "238", iso: "CV" },
	{ country: "Cayman Islands", code: "1-345", iso: "KY" },
	{ country: "Central African Republic", code: "236", iso: "CF" },
	{ country: "Chad", code: "235", iso: "TD" },
	{ country: "Chile", code: "56", iso: "CL" },
	{ country: "China", code: "86", iso: "CN" },
	{ country: "Christmas Island", code: "61", iso: "CX" },
	{ country: "Cocos Islands", code: "61", iso: "CC" },
	{ country: "Colombia", code: "57", iso: "CO" },
	{ country: "Comoros", code: "269", iso: "KM" },
	{ country: "Cook Islands", code: "682", iso: "CK" },
	{ country: "Costa Rica", code: "506", iso: "CR" },
	{ country: "Croatia", code: "385", iso: "HR" },
	{ country: "Cuba", code: "53", iso: "CU" },
	{ country: "Curacao", code: "599", iso: "CW" },
	{ country: "Cyprus", code: "357", iso: "CY" },
	{ country: "Czech Republic", code: "420", iso: "CZ" },
	{ country: "Democratic Republic of the Congo", code: "243", iso: "CD" },
	{ country: "Denmark", code: "45", iso: "DK" },
	{ country: "Djibouti", code: "253", iso: "DJ" },
	{ country: "Dominica", code: "1-767", iso: "DM" },
	{ country: "Dominican Republic", code: "1-809, 1-829, 1-849", iso: "DO" },
	{ country: "East Timor", code: "670", iso: "TL" },
	{ country: "Ecuador", code: "593", iso: "EC" },
	{ country: "Egypt", code: "20", iso: "EG" },
	{ country: "El Salvador", code: "503", iso: "SV" },
	{ country: "Equatorial Guinea", code: "240", iso: "GQ" },
	{ country: "Eritrea", code: "291", iso: "ER" },
	{ country: "Estonia", code: "372", iso: "EE" },
	{ country: "Ethiopia", code: "251", iso: "ET" },
	{ country: "Falkland Islands", code: "500", iso: "FK" },
	{ country: "Faroe Islands", code: "298", iso: "FO" },
	{ country: "Fiji", code: "679", iso: "FJ" },
	{ country: "Finland", code: "358", iso: "FI" },
	{ country: "France", code: "33", iso: "FR" },
	{ country: "French Polynesia", code: "689", iso: "PF" },
	{ country: "Gabon", code: "241", iso: "GA" },
	{ country: "Gambia", code: "220", iso: "GM" },
	{ country: "Georgia", code: "995", iso: "GE" },
	{ country: "Germany", code: "49", iso: "DE" },
	{ country: "Ghana", code: "233", iso: "GH" },
	{ country: "Gibraltar", code: "350", iso: "GI" },
	{ country: "Greece", code: "30", iso: "GR" },
	{ country: "Greenland", code: "299", iso: "GL" },
	{ country: "Grenada", code: "1-473", iso: "GD" },
	{ country: "Guam", code: "1-671", iso: "GU" },
	{ country: "Guatemala", code: "502", iso: "GT" },
	{ country: "Guernsey", code: "44-1481", iso: "GG" },
	{ country: "Guinea", code: "224", iso: "GN" },
	{ country: "Guinea-Bissau", code: "245", iso: "GW" },
	{ country: "Guyana", code: "592", iso: "GY" },
	{ country: "Haiti", code: "509", iso: "HT" },
	{ country: "Honduras", code: "504", iso: "HN" },
	{ country: "Hong Kong", code: "852", iso: "HK" },
	{ country: "Hungary", code: "36", iso: "HU" },
	{ country: "Iceland", code: "354", iso: "IS" },
	{ country: "India", code: "91", iso: "IN" },
	{ country: "Indonesia", code: "62", iso: "ID" },
	{ country: "Iran", code: "98", iso: "IR" },
	{ country: "Iraq", code: "964", iso: "IQ" },
	{ country: "Ireland", code: "353", iso: "IE" },
	{ country: "Isle of Man", code: "44-1624", iso: "IM" },
	{ country: "Israel", code: "972", iso: "IL" },
	{ country: "Italy", code: "39", iso: "IT" },
	{ country: "Ivory Coast", code: "225", iso: "CI" },
	{ country: "Jamaica", code: "1-876", iso: "JM" },
	{ country: "Japan", code: "81", iso: "JP" },
	{ country: "Jersey", code: "44-1534", iso: "JE" },
	{ country: "Jordan", code: "962", iso: "JO" },
	{ country: "Kazakhstan", code: "7", iso: "KZ" },
	{ country: "Kenya", code: "254", iso: "KE" },
	{ country: "Kiribati", code: "686", iso: "KI" },
	{ country: "Kosovo", code: "383", iso: "XK" },
	{ country: "Kuwait", code: "965", iso: "KW" },
	{ country: "Kyrgyzstan", code: "996", iso: "KG" },
	{ country: "Laos", code: "856", iso: "LA" },
	{ country: "Latvia", code: "371", iso: "LV" },
	{ country: "Lebanon", code: "961", iso: "LB" },
	{ country: "Lesotho", code: "266", iso: "LS" },
	{ country: "Liberia", code: "231", iso: "LR" },
	{ country: "Libya", code: "218", iso: "LY" },
	{ country: "Liechtenstein", code: "423", iso: "LI" },
	{ country: "Lithuania", code: "370", iso: "LT" },
	{ country: "Luxembourg", code: "352", iso: "LU" },
	{ country: "Macao", code: "853", iso: "MO" },
	{ country: "Macedonia", code: "389", iso: "MK" },
	{ country: "Madagascar", code: "261", iso: "MG" },
	{ country: "Malawi", code: "265", iso: "MW" },
	{ country: "Malaysia", code: "60", iso: "MY" },
	{ country: "Maldives", code: "960", iso: "MV" },
	{ country: "Mali", code: "223", iso: "ML" },
	{ country: "Malta", code: "356", iso: "MT" },
	{ country: "Marshall Islands", code: "692", iso: "MH" },
	{ country: "Mauritania", code: "222", iso: "MR" },
	{ country: "Mauritius", code: "230", iso: "MU" },
	{ country: "Mayotte", code: "262", iso: "YT" },
	{ country: "Mexico", code: "52", iso: "MX" },
	{ country: "Micronesia", code: "691", iso: "FM" },
	{ country: "Moldova", code: "373", iso: "MD" },
	{ country: "Monaco", code: "377", iso: "MC" },
	{ country: "Mongolia", code: "976", iso: "MN" },
	{ country: "Montenegro", code: "382", iso: "ME" },
	{ country: "Montserrat", code: "1-664", iso: "MS" },
	{ country: "Morocco", code: "212", iso: "MA" },
	{ country: "Mozambique", code: "258", iso: "MZ" },
	{ country: "Myanmar", code: "95", iso: "MM" },
	{ country: "Namibia", code: "264", iso: "NA" },
	{ country: "Nauru", code: "674", iso: "NR" },
	{ country: "Nepal", code: "977", iso: "NP" },
	{ country: "Netherlands", code: "31", iso: "NL" },
	{ country: "Netherlands Antilles", code: "599", iso: "AN" },
	{ country: "New Caledonia", code: "687", iso: "NC" },
	{ country: "New Zealand", code: "64", iso: "NZ" },
	{ country: "Nicaragua", code: "505", iso: "NI" },
	{ country: "Niger", code: "227", iso: "NE" },
	{ country: "Nigeria", code: "234", iso: "NG" },
	{ country: "Niue", code: "683", iso: "NU" },
	{ country: "North Korea", code: "850", iso: "KP" },
	{ country: "Northern Mariana Islands", code: "1-670", iso: "MP" },
	{ country: "Norway", code: "47", iso: "NO" },
	{ country: "Oman", code: "968", iso: "OM" },
	{ country: "Pakistan", code: "92", iso: "PK" },
	{ country: "Palau", code: "680", iso: "PW" },
	{ country: "Palestine", code: "970", iso: "PS" },
	{ country: "Panama", code: "507", iso: "PA" },
	{ country: "Papua New Guinea", code: "675", iso: "PG" },
	{ country: "Paraguay", code: "595", iso: "PY" },
	{ country: "Peru", code: "51", iso: "PE" },
	{ country: "Philippines", code: "63", iso: "PH" },
	{ country: "Pitcairn", code: "64", iso: "PN" },
	{ country: "Poland", code: "48", iso: "PL" },
	{ country: "Portugal", code: "351", iso: "PT" },
	{ country: "Puerto Rico", code: "1-787, 1-939", iso: "PR" },
	{ country: "Qatar", code: "974", iso: "QA" },
	{ country: "Republic of the Congo", code: "242", iso: "CG" },
	{ country: "Reunion", code: "262", iso: "RE" },
	{ country: "Romania", code: "40", iso: "RO" },
	{ country: "Russia", code: "7", iso: "RU" },
	{ country: "Rwanda", code: "250", iso: "RW" },
	{ country: "Saint Barthelemy", code: "590", iso: "BL" },
	{ country: "Saint Helena", code: "290", iso: "SH" },
	{ country: "Saint Kitts and Nevis", code: "1-869", iso: "KN" },
	{ country: "Saint Lucia", code: "1-758", iso: "LC" },
	{ country: "Saint Martin", code: "590", iso: "MF" },
	{ country: "Saint Pierre and Miquelon", code: "508", iso: "PM" },
	{ country: "Saint Vincent and the Grenadines", code: "1-784", iso: "VC" },
	{ country: "Samoa", code: "685", iso: "WS" },
	{ country: "San Marino", code: "378", iso: "SM" },
	{ country: "Sao Tome and Principe", code: "239", iso: "ST" },
	{ country: "Saudi Arabia", code: "966", iso: "SA" },
	{ country: "Senegal", code: "221", iso: "SN" },
	{ country: "Serbia", code: "381", iso: "RS" },
	{ country: "Seychelles", code: "248", iso: "SC" },
	{ country: "Sierra Leone", code: "232", iso: "SL" },
	{ country: "Singapore", code: "65", iso: "SG" },
	{ country: "Sint Maarten", code: "1-721", iso: "SX" },
	{ country: "Slovakia", code: "421", iso: "SK" },
	{ country: "Slovenia", code: "386", iso: "SI" },
	{ country: "Solomon Islands", code: "677", iso: "SB" },
	{ country: "Somalia", code: "252", iso: "SO" },
	{ country: "South Africa", code: "27", iso: "ZA" },
	{ country: "South Korea", code: "82", iso: "KR" },
	{ country: "South Sudan", code: "211", iso: "SS" },
	{ country: "Spain", code: "34", iso: "ES" },
	{ country: "Sri Lanka", code: "94", iso: "LK" },
	{ country: "Sudan", code: "249", iso: "SD" },
	{ country: "Suriname", code: "597", iso: "SR" },
	{ country: "Svalbard and Jan Mayen", code: "47", iso: "SJ" },
	{ country: "Swaziland", code: "268", iso: "SZ" },
	{ country: "Sweden", code: "46", iso: "SE" },
	{ country: "Switzerland", code: "41", iso: "CH" },
	{ country: "Syria", code: "963", iso: "SY" },
	{ country: "Taiwan", code: "886", iso: "TW" },
	{ country: "Tajikistan", code: "992", iso: "TJ" },
	{ country: "Tanzania", code: "255", iso: "TZ" },
	{ country: "Thailand", code: "66", iso: "TH" },
	{ country: "Togo", code: "228", iso: "TG" },
	{ country: "Tokelau", code: "690", iso: "TK" },
	{ country: "Tonga", code: "676", iso: "TO" },
	{ country: "Trinidad and Tobago", code: "1-868", iso: "TT" },
	{ country: "Tunisia", code: "216", iso: "TN" },
	{ country: "Turkey", code: "90", iso: "TR" },
	{ country: "Turkmenistan", code: "993", iso: "TM" },
	{ country: "Turks and Caicos Islands", code: "1-649", iso: "TC" },
	{ country: "Tuvalu", code: "688", iso: "TV" },
	{ country: "U.S. Virgin Islands", code: "1-340", iso: "VI" },
	{ country: "Uganda", code: "256", iso: "UG" },
	{ country: "Ukraine", code: "380", iso: "UA" },
	{ country: "United Arab Emirates", code: "971", iso: "AE" },
	{ country: "United Kingdom", code: "44", iso: "GB" },

	{ country: "Uruguay", code: "598", iso: "UY" },
	{ country: "Uzbekistan", code: "998", iso: "UZ" },
	{ country: "Vanuatu", code: "678", iso: "VU" },
	{ country: "Vatican", code: "379", iso: "VA" },
	{ country: "Venezuela", code: "58", iso: "VE" },
	{ country: "Vietnam", code: "84", iso: "VN" },
	{ country: "Wallis and Futuna", code: "681", iso: "WF" },
	{ country: "Western Sahara", code: "212", iso: "EH" },
	{ country: "Yemen", code: "967", iso: "YE" },
	{ country: "Zambia", code: "260", iso: "ZM" },
	{ country: "Zimbabwe", code: "263", iso: "ZW" },
];

export const countries = {
	"United States of America": [
		"Alabama",
		"Alaska",
		"Arizona",
		"Arkansas",
		"California",
		"Colorado",
		"Connecticut",
		"Delaware",
		"Florida",
		"Georgia",
		"Hawaii",
		"Idaho",
		"Illinois",
		"Indiana",
		"Iowa",
		"Kansas",
		"Kentucky",
		"Louisiana",
		"Maine",
		"Maryland",
		"Massachusetts",
		"Michigan",
		"Minnesota",
		"Mississippi",
		"Missouri",
		"Montana",
		"Nebraska",
		"Nevada",
		"Ohio",
		"Oklahoma",
		"Oregon",
		"Pennsylvania",
		"Rhode",
		"South",
		"South",
		"Tennessee",
		"Texas",
		"Utah",
		"Vermont",
		"Virginia",
		"Washington",
		"West",
		"Wisconsin",
		"Wyoming",
	],
	Afghanistan: [],
	Albania: [],
	Algeria: [],
	"American Samoa": [],
	Andorra: [],
	Angola: [],
	Anguilla: [],
	Antarctica: [],
	"Antigua and Barbuda": [],
	Argentina: [],
	Armenia: [],
	Aruba: [],
	Australia: [],
	Austria: [],
	Azerbaijan: [],
	"Bahamas (the)": [],
	Bahrain: [],
	Bangladesh: [],
	Barbados: [],
	Belarus: [],
	Belgium: [],
	Belize: [],
	Benin: [],
	Bermuda: [],
	Bhutan: [],
	"Bolivia (Plurinational State of)": [],
	"Bonaire, Sint Eustatius and Saba": [],
	"Bosnia and Herzegovina": [],
	Botswana: [],
	"Bouvet Island": [],
	Brazil: [],
	"British Indian Ocean Territory (the)": [],
	"Brunei Darussalam": [],
	Bulgaria: [],
	"Burkina Faso": [],
	Burundi: [],
	"Cabo Verde": [],
	Cambodia: [],
	Cameroon: [],
	Canada: [],
	"Cayman Islands (the)": [],
	"Central African Republic (the)": [],
	Chad: [],
	Chile: [],
	China: [],
	"Christmas Island": [],
	"Cocos (Keeling) Islands (the)": [],
	Colombia: [],
	"Comoros (the)": [],
	"Congo (the Democratic Republic of the)": [],
	"Congo (the)": [],
	"Cook Islands (the)": [],
	"Costa Rica": [],
	Croatia: [],
	Cuba: [],
	Curaçao: [],
	Cyprus: [],
	Czechia: [],
	"Côte d'Ivoire": [],
	Denmark: [],
	Djibouti: [],
	Dominica: [],
	"Dominican Republic (the)": [],
	Ecuador: [],
	Egypt: [],
	"El Salvador": [],
	"Equatorial Guinea": [],
	Eritrea: [],
	Estonia: [],
	Eswatini: [],
	Ethiopia: [],
	"Falkland Islands (the) [Malvinas]": [],
	"Faroe Islands (the)": [],
	Fiji: [],
	Finland: [],
	France: [],
	"French Guiana": [],
	"French Polynesia": [],
	"French Southern Territories (the)": [],
	Gabon: [],
	"Gambia (the)": [],
	Georgia: [],
	Germany: [],
	Ghana: [],
	Gibraltar: [],
	Greece: [],
	Greenland: [],
	Grenada: [],
	Guadeloupe: [],
	Guam: [],
	Guatemala: [],
	Guernsey: [],
	Guinea: [],
	"Guinea-Bissau": [],
	Guyana: [],
	Haiti: [],
	"Heard Island and McDonald Islands": [],
	"Holy See (the)": [],
	Honduras: [],
	"Hong Kong": [],
	Hungary: [],
	Iceland: [],
	India: [],
	Indonesia: [],
	"Iran (Islamic Republic of)": [],
	Iraq: [],
	Ireland: [],
	"Isle of Man": [],
	Israel: [],
	Italy: [],
	Jamaica: [],
	Japan: [],
	Jersey: [],
	Jordan: [],
	Kazakhstan: [],
	Kenya: [],
	Kiribati: [],
	"Korea (the Democratic People's Republic of)": [],
	"Korea (the Republic of)": [],
	Kuwait: [],
	Kyrgyzstan: [],
	"Lao People's Democratic Republic (the)": [],
	Latvia: [],
	Lebanon: [],
	Lesotho: [],
	Liberia: [],
	Libya: [],
	Liechtenstein: [],
	Lithuania: [],
	Luxembourg: [],
	Macao: [],
	Madagascar: [],
	Malawi: [],
	Malaysia: [],
	Maldives: [],
	Mali: [],
	Malta: [],
	"Marshall Islands (the)": [],
	Martinique: [],
	Mauritania: [],
	Mauritius: [],
	Mayotte: [],
	Mexico: [],
	"Micronesia (Federated States of)": [],
	"Moldova (the Republic of)": [],
	Monaco: [],
	Mongolia: [],
	Montenegro: [],
	Montserrat: [],
	Morocco: [],
	Mozambique: [],
	Myanmar: [],
	Namibia: [],
	Nauru: [],
	Nepal: [],
	"Netherlands (the)": [],
	"New Caledonia": [],
	"New Zealand": [],
	Nicaragua: [],
	"Niger (the)": [],
	Nigeria: [
		"Abia",
		"Adamawa",
		"Anambra",
		"Akwa Ibom",
		"Bauchi",
		"Bayelsa",
		"Benue",
		"Borno",
		"Cross River",
		"Delta",
		"Ebonyi",
		"Enugu",
		"Edo",
		"Ekiti",
		"Imo",
		"Jigawa",
		"Kaduna",
		"Kano",
		"Katsina",
		"Kebbi",
		"Kogi",
		"Kwara",
		"Lagos",
		"Nasarawa",
		"Niger",
		"Ogun",
		"Ondo",
		"Osun",
		"Oyo",
		"Plateau",
		"Rivers",
		"Sokoto",
		"Taraba",
		"Yobe",
		"Zamfara",
	],
	Niue: [],
	"Norfolk Island": [],
	"Northern Mariana Islands (the)": [],
	Norway: [],
	Oman: [],
	Pakistan: [],
	Palau: [],
	"Palestine, State of": [],
	Panama: [],
	"Papua New Guinea": [],
	Paraguay: [],
	Peru: [],
	"Philippines (the)": [],
	Pitcairn: [],
	Poland: [],
	Portugal: [],
	"Puerto Rico": [],
	Qatar: [],
	"Republic of North Macedonia": [],
	Romania: [],
	"Russian Federation (the)": [],
	Rwanda: [],
	Réunion: [],
	"Saint Barthélemy": [],
	"Saint Helena, Ascension and Tristan da Cunha": [],
	"Saint Kitts and Nevis": [],
	"Saint Lucia": [],
	"Saint Martin (French part)": [],
	"Saint Pierre and Miquelon": [],
	"Saint Vincent and the Grenadines": [],
	Samoa: [],
	"San Marino": [],
	"Sao Tome and Principe": [],
	"Saudi Arabia": [],
	Senegal: [],
	Serbia: [],
	Seychelles: [],
	"Sierra Leone": [],
	Singapore: [],
	"Sint Maarten (Dutch part)": [],
	Slovakia: [],
	Slovenia: [],
	"Solomon Islands": [],
	Somalia: [],
	"South Africa": [],
	"South Georgia and the South Sandwich Islands": [],
	"South Sudan": [],
	Spain: [],
	"Sri Lanka": [],
	"Sudan (the)": [],
	Suriname: [],
	"Svalbard and Jan Mayen": [],
	Sweden: [],
	Switzerland: [],
	"Syrian Arab Republic": [],
	Taiwan: [],
	Tajikistan: [],
	"Tanzania, United Republic of": [],
	Thailand: [],
	"Timor-Leste": [],
	Togo: [],
	Tokelau: [],
	Tonga: [],
	"Trinidad and Tobago": [],
	Tunisia: [],
	Turkey: [],
	Turkmenistan: [],
	"Turks and Caicos Islands (the)": [],
	Tuvalu: [],
	Uganda: [],
	Ukraine: [],
	"United Arab Emirates (the)": [],
	"United Kingdom of Great Britain and Northern Ireland (the)": [],
	"United States Minor Outlying Islands (the)": [],
	"United States of America (the)": [
		"Alabama",
		"Alaska",
		"Arizona",
		"Arkansas",
		"California",
		"Colorado",
		"Connecticut",
		"Delaware",
		"Florida",
		"Georgia",
		"Hawaii",
		"Idaho",
		"Illinois",
		"Indiana",
		"Iowa",
		"Kansas",
		"Kentucky",
		"Louisiana",
		"Maine",
		"Maryland",
		"Massachusetts",
		"Michigan",
		"Minnesota",
		"Mississippi",
		"Missouri",
		"Montana",
		"Nebraska",
		"Nevada",
		"Ohio",
		"Oklahoma",
		"Oregon",
		"Pennsylvania",
		"Rhode",
		"South",
		"South",
		"Tennessee",
		"Texas",
		"Utah",
		"Vermont",
		"Virginia",
		"Washington",
		"West",
		"Wisconsin",
		"Wyoming",
	],
	Uruguay: [],
	Uzbekistan: [],
	Vanuatu: [],
	"Venezuela (Bolivarian Republic of)": [],
	"Viet Nam": [],
	"Virgin Islands (British)": [],
	"Virgin Islands (U.S.)": [],
	"Wallis and Futuna": [],
	"Western Sahara": [],
	Yemen: [],
	Zambia: [],
	Zimbabwe: [],
	"Åland Islands": [],
};

export const allCountries = {
	"United State": [
		"Alabama",
		"Alaska",
		"Arizona",
		"Arkansas",
		"California",
		"Colorado",
		"Connecticut",
		"Delaware",
		"District of Columbia",
		"Florida",
		"Georgia",
		"Hawaii",
		"Idaho",
		"Illinois",
		"Indiana",
		"Iowa",
		"Kansas",
		"Kentucky",
		"Louisiana",
		"Maine",
		"Maryland",
		"Massachusetts",
		"Michigan",
		"Minnesota",
		"Mississippi",
		"Missouri",
		"Montana",
		"Nebraska",
		"Nevada",
		"New Hampshire",
		"New Jersey",
		"New Mexico",
		"New York",
		"North Carolina",
		"North Dakota",
		"Ohio",
		"Oklahoma",
		"Oregon",
		"Pennsylvania",
		"Rhode Island",
		"South Carolina",
		"South Dakota",
		"Tennessee",
		"Texas",
		"Utah",
		"Vermont",
		"Virginia",
		"Washington",
		"West Virginia",
		"Wisconsin",
		"Wyoming",
	],

	Afghanistan: [
		"Badakhshan",
		"Badghis",
		"Baghlan",
		"Balkh",
		"Bamian",
		"Daykondi",
		"Farah",
		"Faryab",
		"Ghazni",
		"Ghowr",
		"Helmand",
		"Herat",
		"Jowzjan",
		"Kabul",
		"Kandahar",
		"Kapisa",
		"Khost",
		"Konar",
		"Kondoz",
		"Laghman",
		"Lowgar",
		"Nangarhar",
		"Nimruz",
		"Nurestan",
		"Oruzgan",
		"Paktia",
		"Paktika",
		"Panjshir",
		"Parvan",
		"Samangan",
		"Sar-e Pol",
		"Takhar",
		"Vardak",
		"Zabol",
	],
	Albania: [
		"Berat",
		"Dibres",
		"Durres",
		"Elbasan",
		"Fier",
		"Gjirokastre",
		"Korce",
		"Kukes",
		"Lezhe",
		"Shkoder",
		"Tirane",
		"Vlore",
	],
	Algeria: [
		"Adrar",
		"Ain Defla",
		"Ain Temouchent",
		"Alger",
		"Annaba",
		"Batna",
		"Bechar",
		"Bejaia",
		"Biskra",
		"Blida",
		"Bordj Bou Arreridj",
		"Bouira",
		"Boumerdes",
		"Chlef",
		"Constantine",
		"Djelfa",
		"El Bayadh",
		"El Oued",
		"El Tarf",
		"Ghardaia",
		"Guelma",
		"Illizi",
		"Jijel",
		"Khenchela",
		"Laghouat",
		"Muaskar",
		"Medea",
		"Mila",
		"Mostaganem",
		"M'Sila",
		"Naama",
		"Oran",
		"Ouargla",
		"Oum el Bouaghi",
		"Relizane",
		"Saida",
		"Setif",
		"Sidi Bel Abbes",
		"Skikda",
		"Souk Ahras",
		"Tamanghasset",
		"Tebessa",
		"Tiaret",
		"Tindouf",
		"Tipaza",
		"Tissemsilt",
		"Tizi Ouzou",
		"Tlemcen",
	],
	Andorra: [
		"Andorra la Vella",
		"Canillo",
		"Encamp",
		"Escaldes-Engordany",
		"La Massana",
		"Ordino",
		"Sant Julia de Loria",
	],

	Angola: [
		"Bengo",
		"Benguela",
		"Bie",
		"Cabinda",
		"Cuando Cubango",
		"Cuanza Norte",
		"Cuanza Sul",
		"Cunene",
		"Huambo",
		"Huila",
		"Luanda",
		"Lunda Norte",
		"Lunda Sul",
		"Malanje",
		"Moxico",
		"Namibe",
		"Uige",
		"Zaire",
	],

	Antarctica: [],

	"Antigua and Barbuda": [
		"Barbuda",
		"Redonda",
		"Saint George",
		"Saint John",
		"Saint Mary",
		"Saint Paul",
		"Saint Peter",
		"Saint Philip",
	],

	Argentina: [
		"Buenos Aires",
		"Buenos Aires Capital",
		"Catamarca",
		"Chaco",
		"Chubut",
		"Cordoba",
		"Corrientes",
		"Entre Rios",
		"Formosa",
		"Jujuy",
		"La Pampa",
		"La Rioja",
		"Mendoza",
		"Misiones",
		"Neuquen",
		"Rio Negro",
		"Salta",
		"San Juan",
		"San Luis",
		"Santa Cruz",
		"Santa Fe",
		"Santiago del Estero",
		"Tierra del Fuego",
		"Tucuman",
	],

	Armenia: [
		"Aragatsotn",
		"Ararat",
		"Armavir",
		"Geghark'unik'",
		"Kotayk'",
		"Lorri",
		"Shirak",
		"Syunik'",
		"Tavush",
		"Vayots' Dzor",
		"Yerevan",
	],

	Australia: [],

	Austria: [
		"Burgenland",
		"Kaernten",
		"Niederoesterreich",
		"Oberoesterreich",
		"Salzburg",
		"Steiermark",
		"Tirol",
		"Vorarlberg",
		"Wien",
	],

	Azerbaijan: [
		"Abseron Rayonu",
		"Agcabadi Rayonu",
		"Agdam Rayonu",
		"Agdas Rayonu",
		"Agstafa Rayonu",
		"Agsu Rayonu",
		"Astara Rayonu",
		"Balakan Rayonu",
		"Barda Rayonu",
		"Beylaqan Rayonu",
		"Bilasuvar Rayonu",
		"Cabrayil Rayonu",
		"Calilabad Rayonu",
		"Daskasan Rayonu",
		"Davaci Rayonu",
		"Fuzuli Rayonu",
		"Gadabay Rayonu",
		"Goranboy Rayonu",
		"Goycay Rayonu",
		"Haciqabul Rayonu",
		"Imisli Rayonu",
		"Ismayilli Rayonu",
		"Kalbacar Rayonu",
		"Kurdamir Rayonu",
		"Lacin Rayonu",
		"Lankaran Rayonu",
		"Lerik Rayonu",
		"Masalli Rayonu",
		"Neftcala Rayonu",
		"Oguz Rayonu",
		"Qabala Rayonu",
		"Qax Rayonu",
		"Qazax Rayonu",
		"Qobustan Rayonu",
		"Quba Rayonu",
		"Qubadli Rayonu",
		"Qusar Rayonu",
		"Saatli Rayonu",
		"Sabirabad Rayonu",
		"Saki Rayonu",
		"Salyan Rayonu",
		"Samaxi Rayonu",
		"Samkir Rayonu",
		"Samux Rayonu",
		"Siyazan Rayonu",
		"Susa Rayonu",
		"Tartar Rayonu",
		"Tovuz Rayonu",
		"Ucar Rayonu",
		"Xacmaz Rayonu",
		"Xanlar Rayonu",
		"Xizi Rayonu",
		"Xocali Rayonu",
		"Xocavand Rayonu",
		"Yardimli Rayonu",
		"Yevlax Rayonu",
		"Zangilan Rayonu",
		"Zaqatala Rayonu",
		"Zardab Rayonu",
		"Ali Bayramli Sahari",
		"Baki Sahari",
		"Ganca Sahari",
		"Lankaran Sahari",
		"Mingacevir Sahari",
		"Naftalan Sahari",
		"Saki Sahari",
		"Sumqayit Sahari",
		"Susa Sahari",
		"Xankandi Sahari",
		"Yevlax Sahari",
		"Naxcivan Muxtar",
	],

	Bahamas: [
		"Acklins and Crooked Islands",
		"Bimini",
		"Cat Island",
		"Exuma",
		"Freeport",
		"Fresh Creek",
		"Governor's Harbour",
		"Green Turtle Cay",
		"Harbour Island",
		"High Rock",
		"Inagua",
		"Kemps Bay",
		"Long Island",
		"Marsh Harbour",
		"Mayaguana",
		"New Providence",
		"Nichollstown and Berry Islands",
		"Ragged Island",
		"Rock Sound",
		"Sandy Point",
		"San Salvador and Rum Cay",
	],

	Bahrain: [
		"Al Hadd",
		"Al Manamah",
		"Al Mintaqah al Gharbiyah",
		"Al Mintaqah al Wusta",
		"Al Mintaqah ash Shamaliyah",
		"Al Muharraq",
		"Ar Rifa' wa al Mintaqah al Janubiyah",
		"Jidd Hafs",
		"Madinat Hamad",
		"Madinat 'Isa",
		"Juzur Hawar",
		"Sitrah",
	],

	Bangladesh: [
		"Barisal",
		"Chittagong",
		"Dhaka",
		"Khulna",
		"Rajshahi",
		"Sylhet",
	],

	Barbados: [
		"Christ Church",
		"Saint Andrew",
		"Saint George",
		"Saint James",
		"Saint John",
		"Saint Joseph",
		"Saint Lucy",
		"Saint Michael",
		"Saint Peter",
		"Saint Philip",
		"Saint Thomas",
	],

	Belarus: [
		"Brest",
		"Homyel",
		"Horad Minsk",
		"Hrodna",
		"Mahilyow",
		"Minsk",
		"Vitsyebsk",
	],

	Belgium: [
		"Antwerpen",
		"Brabant Wallon",
		"Brussels",
		"Flanders",
		"Hainaut",
		"Liege",
		"Limburg",
		"Luxembourg",
		"Namur",
		"Oost-Vlaanderen",
		"Vlaams-Brabant",
		"Wallonia",
		"West-Vlaanderen",
	],

	Belize: ["Belize", "Cayo", "Corozal", "Orange Walk", "Stann Creek", "Toledo"],

	Benin: [
		"Alibori",
		"Atakora",
		"Atlantique",
		"Borgou",
		"Collines",
		"Donga",
		"Kouffo",
		"Littoral",
		"Mono",
		"Oueme",
		"Plateau",
		"Zou",
	],

	Bermuda: [
		"Devonshire",
		"Hamilton",
		"Hamilton",
		"Paget",
		"Pembroke",
		"Saint George",
		"Saint George's",
		"Sandys",
		"Smith's",
		"Southampton",
		"Warwick",
	],

	Bhutan: [
		"Bumthang",
		"Chukha",
		"Dagana",
		"Gasa",
		"Haa",
		"Lhuntse",
		"Mongar",
		"Paro",
		"Pemagatshel",
		"Punakha",
		"Samdrup Jongkhar",
		"Samtse",
		"Sarpang",
		"Thimphu",
		"Trashigang",
		"Trashiyangste",
		"Trongsa",
		"Tsirang",
		"Wangdue Phodrang",
		"Zhemgang",
	],

	Bolivia: [
		"Chuquisaca",
		"Cochabamba",
		"Beni",
		"La Paz",
		"Oruro",
		"Pando",
		"Potosi",
		"Santa Cruz",
		"Tarija",
	],

	"Bosnia and Herzegovina": [
		"Una-Sana [Federation]",
		"Posavina [Federation]",
		"Tuzla [Federation]",
		"Zenica-Doboj [Federation]",
		"Bosnian Podrinje [Federation]",
		"Central Bosnia [Federation]",
		"Herzegovina-Neretva [Federation]",
		"West Herzegovina [Federation]",
		"Sarajevo [Federation]",
		" West Bosnia [Federation]",
		"Banja Luka [RS]",
		"Bijeljina [RS]",
		"Doboj [RS]",
		"Fo?a [RS]",
		"Sarajevo-Romanija [RS]",
		"Trebinje [RS]",
		"Vlasenica [RS]",
	],

	Botswana: [
		"Central",
		"Ghanzi",
		"Kgalagadi",
		"Kgatleng",
		"Kweneng",
		"North East",
		"North West",
		"South East",
		"Southern",
	],

	Brazil: [
		"Acre",
		"Alagoas",
		"Amapa",
		"Amazonas",
		"Bahia",
		"Ceara",
		"Distrito Federal",
		"Espirito Santo",
		"Goias",
		"Maranhao",
		"Mato Grosso",
		"Mato Grosso do Sul",
		"Minas Gerais",
		"Para",
		"Paraiba",
		"Parana",
		"Pernambuco",
		"Piaui",
		"Rio de Janeiro",
		"Rio Grande do Norte",
		"Rio Grande do Sul",
		"Rondonia",
		"Roraima",
		"Santa Catarina",
		"Sao Paulo",
		"Sergipe",
		"Tocantins",
	],

	Brunei: ["Belait", "Brunei and Muara", "Temburong", "Tutong"],

	Bulgaria: [
		"Blagoevgrad",
		"Burgas",
		"Dobrich",
		"Gabrovo",
		"Khaskovo",
		"Kurdzhali",
		"Kyustendil",
		"Lovech",
		"Montana",
		"Pazardzhik",
		"Pernik",
		"Pleven",
		"Plovdiv",
		"Razgrad",
		"Ruse",
		"Shumen",
		"Silistra",
		"Sliven",
		"Smolyan",
		"Sofiya",
		"Sofiya-Grad",
		"Stara Zagora",
		"Turgovishte",
		"Varna",
		"Veliko Turnovo",
		"Vidin",
		"Vratsa",
		"Yambol",
	],

	"Burkina Faso": [
		"Bale",
		"Bam",
		"Banwa",
		"Bazega",
		"Bougouriba",
		"Boulgou",
		"Boulkiemde",
		"Comoe",
		"Ganzourgou",
		"Gnagna",
		"Gourma",
		"Houet",
		"Ioba",
		"Kadiogo",
		"Kenedougou",
		"Komondjari",
		"Kompienga",
		"Kossi",
		"Koulpelogo",
		"Kouritenga",
		"Kourweogo",
		"Leraba",
		"Loroum",
		"Mouhoun",
		"Namentenga",
		"Nahouri",
		"Nayala",
		"Noumbiel",
		"Oubritenga",
		"Oudalan",
		"Passore",
		"Poni",
		"Sanguie",
		"Sanmatenga",
		"Seno",
		"Sissili",
		"Soum",
		"Sourou",
		"Tapoa",
		"Tuy",
		"Yagha",
		"Yatenga",
		"Ziro",
		"Zondoma",
		"Zoundweogo",
	],

	Burma: [
		"Ayeyarwady",
		"Bago",
		"Magway",
		"Mandalay",
		"Sagaing",
		"Tanintharyi",
		"Yangon",
		"Chin State",
		"Kachin State",
		"Kayin State",
		"Kayah State",
		"Mon State",
		"Rakhine State",
		"Shan State",
	],

	Burundi: [
		"Bubanza",
		"Bujumbura Mairie",
		"Bujumbura Rural",
		"Bururi",
		"Cankuzo",
		"Cibitoke",
		"Gitega",
		"Karuzi",
		"Kayanza",
		"Kirundo",
		"Makamba",
		"Muramvya",
		"Muyinga",
		"Mwaro",
		"Ngozi",
		"Rutana",
		"Ruyigi",
	],

	Cambodia: [
		"Banteay Mean Chey",
		"Batdambang",
		"Kampong Cham",
		"Kampong Chhnang",
		"Kampong Spoe",
		"Kampong Thum",
		"Kampot",
		"Kandal",
		"Koh Kong",
		"Kracheh",
		"Mondol Kiri",
		"Otdar Mean Chey",
		"Pouthisat",
		"Preah Vihear",
		"Prey Veng",
		"Rotanakir",
		"Siem Reab",
		"Stoeng Treng",
		"Svay Rieng",
		"Takao",
		"Keb",
		"Pailin",
		"Phnom Penh",
		"Preah Seihanu",
	],

	Cameroon: [
		"Adamaoua",
		"Centre",
		"Est",
		"Extreme-Nord",
		"Littoral",
		"Nord",
		"Nord-Ouest",
		"Ouest",
		"Sud",
		"Sud-Ouest",
	],

	Canada: [
		"Alberta",
		"British Columbia",
		"Manitoba",
		"New Brunswick",
		"Newfoundland and Labrador",
		"Northwest Territories",
		"Nova Scotia",
		"Nunavut",
		"Ontario",
		"Prince Edward Island",
		"Quebec",
		"Saskatchewan",
		"Yukon Territory",
	],

	"Cape Verde": [],

	"Central African Republic": [
		"Bamingui-Bangoran",
		"Bangui",
		"Basse-Kotto",
		"Haute-Kotto",
		"Haut-Mbomou",
		"Kemo",
		"Lobaye",
		"Mambere-Kadei",
		"Mbomou",
		"Nana-Grebizi",
		"Nana-Mambere",
		"Ombella-Mpoko",
		"Ouaka",
		"Ouham",
		"Ouham-Pende",
		"Sangha-Mbaere",
		"Vakaga",
	],

	Chad: [
		"Batha",
		"Biltine",
		"Borkou-Ennedi-Tibesti",
		"Chari-Baguirmi",
		"Guéra",
		"Kanem",
		"Lac",
		"Logone Occidental",
		"Logone Oriental",
		"Mayo-Kebbi",
		"Moyen-Chari",
		"Ouaddaï",
		"Salamat",
		"Tandjile",
	],

	Chile: [
		"Aysen",
		"Antofagasta",
		"Araucania",
		"Atacama",
		"Bio-Bio",
		"Coquimbo",
		"O'Higgins",
		"Los Lagos",
		"Magallanes y la Antartica Chilena",
		"Maule",
		"Santiago Region Metropolitana",
		"Tarapaca",
		"Valparaiso",
	],

	China: [
		"Anhui",
		"Fujian",
		"Gansu",
		"Guangdong",
		"Guizhou",
		"Hainan",
		"Hebei",
		"Heilongjiang",
		"Henan",
		"Hubei",
		"Hunan",
		"Jiangsu",
		"Jiangxi",
		"Jilin",
		"Liaoning",
		"Qinghai",
		"Shaanxi",
		"Shandong",
		"Shanxi",
		"Sichuan",
		"Yunnan",
		"Zhejiang",
		"Guangxi",
		"Nei Mongol",
		"Ningxia",
		"Xinjiang",
		"Xizang (Tibet)",
		"Beijing",
		"Chongqing",
		"Shanghai",
		"Tianjin",
	],

	Colombia: [
		"Amazonas",
		"Antioquia",
		"Arauca",
		"Atlantico",
		"Bogota District Capital",
		"Bolivar",
		"Boyaca",
		"Caldas",
		"Caqueta",
		"Casanare",
		"Cauca",
		"Cesar",
		"Choco",
		"Cordoba",
		"Cundinamarca",
		"Guainia",
		"Guaviare",
		"Huila",
		"La Guajira",
		"Magdalena",
		"Meta",
		"Narino",
		"Norte de Santander",
		"Putumayo",
		"Quindio",
		"Risaralda",
		"San Andres & Providencia",
		"Santander",
		"Sucre",
		"Tolima",
		"Valle del Cauca",
		"Vaupes",
		"Vichada",
	],

	Comoros: ["Grande Comore (Njazidja)", "Anjouan (Nzwani)", "Moheli (Mwali)"],

	"Congo, Democratic Republic": [
		"Bandundu",
		"Bas-Congo",
		"Equateur",
		"Kasai-Occidental",
		"Kasai-Oriental",
		"Katanga",
		"Kinshasa",
		"Maniema",
		"Nord-Kivu",
		"Orientale",
		"Sud-Kivu",
	],

	"Congo, Republic of the": [
		"Bouenza",
		"Brazzaville",
		"Cuvette",
		"Cuvette-Ouest",
		"Kouilou",
		"Lekoumou",
		"Likouala",
		"Niari",
		"Plateaux",
		"Pool",
		"Sangha",
	],

	"Costa Rica": [
		"Alajuela",
		"Cartago",
		"Guanacaste",
		"Heredia",
		"Limon",
		"Puntarenas",
		"San Jose",
	],

	"Cote d'Ivoire": [],

	Croatia: [
		"Bjelovarsko-Bilogorska",
		"Brodsko-Posavska",
		"Dubrovacko-Neretvanska",
		"Istarska",
		"Karlovacka",
		"Koprivnicko-Krizevacka",
		"Krapinsko-Zagorska",
		"Licko-Senjska",
		"Medimurska",
		"Osjecko-Baranjska",
		"Pozesko-Slavonska",
		"Primorsko-Goranska",
		"Sibensko-Kninska",
		"Sisacko-Moslavacka",
		"Splitsko-Dalmatinska",
		"Varazdinska",
		"Viroviticko-Podravska",
		"Vukovarsko-Srijemska",
		"Zadarska",
		"Zagreb",
		"Zagrebacka",
	],

	Cuba: [
		"Camaguey",
		"Ciego de Avila",
		"Cienfuegos",
		"Ciudad de La Habana",
		"Granma",
		"Guantanamo",
		"Holguin",
		"Isla de la Juventud",
		"La Habana",
		"Las Tunas",
		"Matanzas",
		"Pinar del Rio",
		"Sancti Spiritus",
		"Santiago de Cuba",
		"Villa Clara",
	],

	Cyprus: ["Famagusta", "Kyrenia", "Larnaca", "Limassol", "Nicosia", "Paphos"],

	"Czech Republic": [
		"Jihocesky Kraj",
		"Jihomoravsky Kraj",
		"Karlovarsky Kraj",
		"Kralovehradecky Kraj",
		"Liberecky Kraj",
		"Moravskoslezsky Kraj",
		"Olomoucky Kraj",
		"Pardubicky Kraj",
		"Plzensky Kraj",
		"Praha",
		"Stredocesky Kraj",
		"Ustecky Kraj",
		"Vysocina",
		"Zlinsky Kraj",
	],

	Denmark: [
		"Arhus",
		"Bornholm",
		"Frederiksberg",
		"Frederiksborg",
		"Fyn",
		"Kobenhavn",
		"Kobenhavns",
		"Nordjylland",
		"Ribe",
		"Ringkobing",
		"Roskilde",
		"Sonderjylland",
		"Storstrom",
		"Vejle",
		"Vestsjalland",
		"Viborg",
	],

	Djibouti: ["Ali Sabih", "Dikhil", "Djibouti", "Obock", "Tadjoura"],

	Dominica: [
		"Saint Andrew",
		"Saint David",
		"Saint George",
		"Saint John",
		"Saint Joseph",
		"Saint Luke",
		"Saint Mark",
		"Saint Patrick",
		"Saint Paul",
		"Saint Peter",
	],

	"Dominican Republic": [
		"Azua",
		"Baoruco",
		"Barahona",
		"Dajabon",
		"Distrito Nacional",
		"Duarte",
		"Elias Pina",
		"El Seibo",
		"Espaillat",
		"Hato Mayor",
		"Independencia",
		"La Altagracia",
		"La Romana",
		"La Vega",
		"Maria Trinidad Sanchez",
		"Monsenor Nouel",
		"Monte Cristi",
		"Monte Plata",
		"Pedernales",
		"Peravia",
		"Puerto Plata",
		"Salcedo",
		"Samana",
		"Sanchez Ramirez",
		"San Cristobal",
		"San Jose de Ocoa",
		"San Juan",
		"San Pedro de Macoris",
		"Santiago",
		"Santiago Rodriguez",
		"Santo Domingo",
		"Valverde",
	],

	"East Timor": [
		"Aileu",
		"Ainaro",
		"Baucau",
		"Bobonaro",
		"Cova-Lima",
		"Dili",
		"Ermera",
		"Lautem",
		"Liquica",
		"Manatuto",
		"Manufahi",
		"Oecussi",
		"Viqueque",
	],

	Ecuador: [
		"Azuay",
		"Bolivar",
		"Canar",
		"Carchi",
		"Chimborazo",
		"Cotopaxi",
		"El Oro",
		"Esmeraldas",
		"Galapagos",
		"Guayas",
		"Imbabura",
		"Loja",
		"Los Rios",
		"Manabi",
		"Morona-Santiago",
		"Napo",
		"Orellana",
		"Pastaza",
		"Pichincha",
		"Sucumbios",
		"Tungurahua",
		"Zamora-Chinchipe",
	],

	Egypt: [
		"Ad Daqahliyah",
		"Al Bahr al Ahmar",
		"Al Buhayrah",
		"Al Fayyum",
		"Al Gharbiyah",
		"Al Iskandariyah",
		"Al Isma'iliyah",
		"Al Jizah",
		"Al Minufiyah",
		"Al Minya",
		"Al Qahirah",
		"Al Qalyubiyah",
		"Al Wadi al Jadid",
		"Ash Sharqiyah",
		"As Suways",
		"Aswan",
		"Asyut",
		"Bani Suwayf",
		"Bur Sa'id",
		"Dumyat",
		"Janub Sina'",
		"Kafr ash Shaykh",
		"Matruh",
		"Qina",
		"Shamal Sina'",
		"Suhaj",
	],

	"El Salvador": [
		"Ahuachapan",
		"Cabanas",
		"Chalatenango",
		"Cuscatlan",
		"La Libertad",
		"La Paz",
		"La Union",
		"Morazan",
		"San Miguel",
		"San Salvador",
		"Santa Ana",
		"San Vicente",
		"Sonsonate",
		"Usulutan",
	],

	"Equatorial Guinea": [
		"Annobon",
		"Bioko Norte",
		"Bioko Sur",
		"Centro Sur",
		"Kie-Ntem",
		"Litoral",
		"Wele-Nzas",
	],

	Eritrea: [
		"Anseba",
		"Debub",
		"Debubawi K'eyih Bahri",
		"Gash Barka",
		"Ma'akel",
		"Semenawi Keyih Bahri",
	],

	Estonia: [
		"Harjumaa (Tallinn)",
		"Hiiumaa (Kardla)",
		"Ida-Virumaa (Johvi)",
		"Jarvamaa (Paide)",
		"Jogevamaa (Jogeva)",
		"Laanemaa (Haapsalu)",
		"Laane-Virumaa (Rakvere)",
		"Parnumaa (Parnu)",
		"Polvamaa (Polva)",
		"Raplamaa (Rapla)",
		"Saaremaa (Kuressaare)",
		"Tartumaa (Tartu)",
		"Valgamaa (Valga)",
		"Viljandimaa (Viljandi)",
		"Vorumaa (Voru)",
	],

	Ethiopia: [
		"Addis Ababa",
		"Afar",
		"Amhara",
		"Binshangul Gumuz",
		"Dire Dawa",
		"Gambela Hizboch",
		"Harari",
		"Oromia",
		"Somali",
		"Tigray",
		"Southern Nations, Nationalities, and Peoples Region",
	],

	Fiji: [
		"Central (Suva)",
		"Eastern (Levuka)",
		"Northern (Labasa)",
		"Rotuma",
		"Western (Lautoka)",
	],

	Finland: [
		"Aland",
		"Etela-Suomen Laani",
		"Ita-Suomen Laani",
		"Lansi-Suomen Laani",
		"Lappi",
		"Oulun Laani",
	],

	France: [
		"Alsace",
		"Aquitaine",
		"Auvergne",
		"Basse-Normandie",
		"Bourgogne",
		"Bretagne",
		"Centre",
		"Champagne-Ardenne",
		"Corse",
		"Franche-Comte",
		"Haute-Normandie",
		"Ile-de-France",
		"Languedoc-Roussillon",
		"Limousin",
		"Lorraine",
		"Midi-Pyrenees",
		"Nord-Pas-de-Calais",
		"Pays de la Loire",
		"Picardie",
		"Poitou-Charentes",
		"Provence-Alpes-Cote d'Azur",
		"Rhone-Alpes",
	],

	Gabon: [
		"Estuaire",
		"Haut-Ogooue",
		"Moyen-Ogooue",
		"Ngounie",
		"Nyanga",
		"Ogooue-Ivindo",
		"Ogooue-Lolo",
		"Ogooue-Maritime",
		"Woleu-Ntem",
	],

	Gambia: [
		"Banjul",
		"Central River",
		"Lower River",
		"North Bank",
		"Upper River",
		"Western",
	],

	Georgia: [],

	Germany: [
		"Baden-Wuerttemberg",
		"Bayern",
		"Berlin",
		"Brandenburg",
		"Bremen",
		"Hamburg",
		"Hessen",
		"Mecklenburg-Vorpommern",
		"Niedersachsen",
		"Nordrhein-Westfalen",
		"Rheinland-Pfalz",
		"Saarland",
		"Sachsen",
		"Sachsen-Anhalt",
		"Schleswig-Holstein",
		"Thueringen",
	],

	Ghana: [
		"Ashanti",
		"Brong-Ahafo",
		"Central",
		"Eastern",
		"Greater Accra",
		"Northern",
		"Upper East",
		"Upper West",
		"Volta",
		"Western",
	],

	Greece: [
		"Agion Oros",
		"Achaia",
		"Aitolia kai Akarmania",
		"Argolis",
		"Arkadia",
		"Arta",
		"Attiki",
		"Chalkidiki",
		"Chanion",
		"Chios",
		"Dodekanisos",
		"Drama",
		"Evros",
		"Evrytania",
		"Evvoia",
		"Florina",
		"Fokidos",
		"Fthiotis",
		"Grevena",
		"Ileia",
		"Imathia",
		"Ioannina",
		"Irakleion",
		"Karditsa",
		"Kastoria",
		"Kavala",
		"Kefallinia",
		"Kerkyra",
		"Kilkis",
		"Korinthia",
		"Kozani",
		"Kyklades",
		"Lakonia",
		"Larisa",
		"Lasithi",
		"Lefkas",
		"Lesvos",
		"Magnisia",
		"Messinia",
		"Pella",
		"Pieria",
		"Preveza",
		"Rethynnis",
		"Rodopi",
		"Samos",
		"Serrai",
		"Thesprotia",
		"Thessaloniki",
		"Trikala",
		"Voiotia",
		"Xanthi",
		"Zakynthos",
	],

	Greenland: [
		"Avannaa (Nordgronland)",
		"Tunu (Ostgronland)",
		"Kitaa (Vestgronland)",
	],

	Grenada: [
		"Carriacou and Petit Martinique",
		"Saint Andrew",
		"Saint David",
		"Saint George",
		"Saint John",
		"Saint Mark",
		"Saint Patrick",
	],

	Guatemala: [
		"Alta Verapaz",
		"Baja Verapaz",
		"Chimaltenango",
		"Chiquimula",
		"El Progreso",
		"Escuintla",
		"Guatemala",
		"Huehuetenango",
		"Izabal",
		"Jalapa",
		"Jutiapa",
		"Peten",
		"Quetzaltenango",
		"Quiche",
		"Retalhuleu",
		"Sacatepequez",
		"San Marcos",
		"Santa Rosa",
		"Solola",
		"Suchitepequez",
		"Totonicapan",
		"Zacapa",
	],

	Guinea: [
		"Beyla",
		"Boffa",
		"Boke",
		"Conakry",
		"Coyah",
		"Dabola",
		"Dalaba",
		"Dinguiraye",
		"Dubreka",
		"Faranah",
		"Forecariah",
		"Fria",
		"Gaoual",
		"Gueckedou",
		"Kankan",
		"Kerouane",
		"Kindia",
		"Kissidougou",
		"Koubia",
		"Koundara",
		"Kouroussa",
		"Labe",
		"Lelouma",
		"Lola",
		"Macenta",
		"Mali",
		"Mamou",
		"Mandiana",
		"Nzerekore",
		"Pita",
		"Siguiri",
		"Telimele",
		"Tougue",
		"Yomou",
	],

	"Guinea-Bissau": [
		"Bafata",
		"Biombo",
		"Bissau",
		"Bolama",
		"Cacheu",
		"Gabu",
		"Oio",
		"Quinara",
		"Tombali",
	],

	Guyana: [
		"Barima-Waini",
		"Cuyuni-Mazaruni",
		"Demerara-Mahaica",
		"East Berbice-Corentyne",
		"Essequibo Islands-West Demerara",
		"Mahaica-Berbice",
		"Pomeroon-Supenaam",
		"Potaro-Siparuni",
		"Upper Demerara-Berbice",
		"Upper Takutu-Upper Essequibo",
	],

	Haiti: [
		"Artibonite",
		"Centre",
		"Grand 'Anse",
		"Nord",
		"Nord-Est",
		"Nord-Ouest",
		"Ouest",
		"Sud",
		"Sud-Est",
	],

	Honduras: [
		"Atlantida",
		"Choluteca",
		"Colon",
		"Comayagua",
		"Copan",
		"Cortes",
		"El Paraiso",
		"Francisco Morazan",
		"Gracias a Dios",
		"Intibuca",
		"Islas de la Bahia",
		"La Paz",
		"Lempira",
		"Ocotepeque",
		"Olancho",
		"Santa Barbara",
		"Valle",
		"Yoro",
	],

	"Hong Kong": [],

	Hungary: [
		"Bacs-Kiskun",
		"Baranya",
		"Bekes",
		"Borsod-Abauj-Zemplen",
		"Csongrad",
		"Fejer",
		"Gyor-Moson-Sopron",
		"Hajdu-Bihar",
		"Heves",
		"Jasz-Nagykun-Szolnok",
		"Komarom-Esztergom",
		"Nograd",
		"Pest",
		"Somogy",
		"Szabolcs-Szatmar-Bereg",
		"Tolna",
		"Vas",
		"Veszprem",
		"Zala",
		"Bekescsaba",
		"Debrecen",
		"Dunaujvaros",
		"Eger",
		"Gyor",
		"Hodmezovasarhely",
		"Kaposvar",
		"Kecskemet",
		"Miskolc",
		"Nagykanizsa",
		"Nyiregyhaza",
		"Pecs",
		"Sopron",
		"Szeged",
		"Szekesfehervar",
		"Szolnok",
		"Szombathely",
		"Tatabanya",
		"Veszprem",
		"Zalaegerszeg",
	],

	Iceland: [
		"Austurland",
		"Hofudhborgarsvaedhi",
		"Nordhurland Eystra",
		"Nordhurland Vestra",
		"Sudhurland",
		"Sudhurnes",
		"Vestfirdhir",
		"Vesturland",
	],

	India: [
		"Andaman and Nicobar Islands",
		"Andhra Pradesh",
		"Arunachal Pradesh",
		"Assam",
		"Bihar",
		"Chandigarh",
		"Chhattisgarh",
		"Dadra and Nagar Haveli",
		"Daman and Diu",
		"Delhi",
		"Goa",
		"Gujarat",
		"Haryana",
		"Himachal Pradesh",
		"Jammu and Kashmir",
		"Jharkhand",
		"Karnataka",
		"Kerala",
		"Lakshadweep",
		"Madhya Pradesh",
		"Maharashtra",
		"Manipur",
		"Meghalaya",
		"Mizoram",
		"Nagaland",
		"Orissa",
		"Pondicherry",
		"Punjab",
		"Rajasthan",
		"Sikkim",
		"Tamil Nadu",
		"Tripura",
		"Uttaranchal",
		"Uttar Pradesh",
		"West Bengal",
	],

	Indonesia: [
		"Aceh",
		"Bali",
		"Banten",
		"Bengkulu",
		"Gorontalo",
		"Irian Jaya Barat",
		"Jakarta Raya",
		"Jambi",
		"Jawa Barat",
		"Jawa Tengah",
		"Jawa Timur",
		"Kalimantan Barat",
		"Kalimantan Selatan",
		"Kalimantan Tengah",
		"Kalimantan Timur",
		"Kepulauan Bangka Belitung",
		"Kepulauan Riau",
		"Lampung",
		"Maluku",
		"Maluku Utara",
		"Nusa Tenggara Barat",
		"Nusa Tenggara Timur",
		"Papua",
		"Riau",
		"Sulawesi Barat",
		"Sulawesi Selatan",
		"Sulawesi Tengah",
		"Sulawesi Tenggara",
		"Sulawesi Utara",
		"Sumatera Barat",
		"Sumatera Selatan",
		"Sumatera Utara",
		"Yogyakarta",
	],

	Iran: [
		"Ardabil",
		"Azarbayjan-e Gharbi",
		"Azarbayjan-e Sharqi",
		"Bushehr",
		"Chahar Mahall va Bakhtiari",
		"Esfahan",
		"Fars",
		"Gilan",
		"Golestan",
		"Hamadan",
		"Hormozgan",
		"Ilam",
		"Kerman",
		"Kermanshah",
		"Khorasan-e Janubi",
		"Khorasan-e Razavi",
		"Khorasan-e Shemali",
		"Khuzestan",
		"Kohgiluyeh va Buyer Ahmad",
		"Kordestan",
		"Lorestan",
		"Markazi",
		"Mazandaran",
		"Qazvin",
		"Qom",
		"Semnan",
		"Sistan va Baluchestan",
		"Tehran",
		"Yazd",
		"Zanjan",
	],

	Iraq: [
		"Al Anbar",
		"Al Basrah",
		"Al Muthanna",
		"Al Qadisiyah",
		"An Najaf",
		"Arbil",
		"As Sulaymaniyah",
		"At Ta'mim",
		"Babil",
		"Baghdad",
		"Dahuk",
		"Dhi Qar",
		"Diyala",
		"Karbala'",
		"Maysan",
		"Ninawa",
		"Salah ad Din",
		"Wasit",
	],

	Ireland: [
		"Carlow",
		"Cavan",
		"Clare",
		"Cork",
		"Donegal",
		"Dublin",
		"Galway",
		"Kerry",
		"Kildare",
		"Kilkenny",
		"Laois",
		"Leitrim",
		"Limerick",
		"Longford",
		"Louth",
		"Mayo",
		"Meath",
		"Monaghan",
		"Offaly",
		"Roscommon",
		"Sligo",
		"Tipperary",
		"Waterford",
		"Westmeath",
		"Wexford",
		"Wicklow",
	],

	Israel: ["Central", "Haifa", "Jerusalem", "Northern", "Southern", "Tel Aviv"],

	Italy: [
		"Abruzzo",
		"Basilicata",
		"Calabria",
		"Campania",
		"Emilia-Romagna",
		"Friuli-Venezia Giulia",
		"Lazio",
		"Liguria",
		"Lombardia",
		"Marche",
		"Molise",
		"Piemonte",
		"Puglia",
		"Sardegna",
		"Sicilia",
		"Toscana",
		"Trentino-Alto Adige",
		"Umbria",
		"Valle d'Aosta",
		"Veneto",
	],

	Jamaica: [
		"Clarendon",
		"Hanover",
		"Kingston",
		"Manchester",
		"Portland",
		"Saint Andrew",
		"Saint Ann",
		"Saint Catherine",
		"Saint Elizabeth",
		"Saint James",
		"Saint Mary",
		"Saint Thomas",
		"Trelawny",
		"Westmoreland",
	],

	Japan: [
		"Aichi",
		"Akita",
		"Aomori",
		"Chiba",
		"Ehime",
		"Fukui",
		"Fukuoka",
		"Fukushima",
		"Gifu",
		"Gumma",
		"Hiroshima",
		"Hokkaido",
		"Hyogo",
		"Ibaraki",
		"Ishikawa",
		"Iwate",
		"Kagawa",
		"Kagoshima",
		"Kanagawa",
		"Kochi",
		"Kumamoto",
		"Kyoto",
		"Mie",
		"Miyagi",
		"Miyazaki",
		"Nagano",
		"Nagasaki",
		"Nara",
		"Niigata",
		"Oita",
		"Okayama",
		"Okinawa",
		"Osaka",
		"Saga",
		"Saitama",
		"Shiga",
		"Shimane",
		"Shizuoka",
		"Tochigi",
		"Tokushima",
		"Tokyo",
		"Tottori",
		"Toyama",
		"Wakayama",
		"Yamagata",
		"Yamaguchi",
		"Yamanashi",
	],

	Jordan: [
		"Ajlun",
		"Al 'Aqabah",
		"Al Balqa'",
		"Al Karak",
		"Al Mafraq",
		"'Amman",
		"At Tafilah",
		"Az Zarqa'",
		"Irbid",
		"Jarash",
		"Ma'an",
		"Madaba",
	],

	Kazakhstan: [
		"Almaty Oblysy",
		"Almaty Qalasy",
		"Aqmola Oblysy",
		"Aqtobe Oblysy",
		"Astana Qalasy",
		"Atyrau Oblysy",
		"Batys Qazaqstan Oblysy",
		"Bayqongyr Qalasy",
		"Mangghystau Oblysy",
		"Ongtustik Qazaqstan Oblysy",
		"Pavlodar Oblysy",
		"Qaraghandy Oblysy",
		"Qostanay Oblysy",
		"Qyzylorda Oblysy",
		"Shyghys Qazaqstan Oblysy",
		"Soltustik Qazaqstan Oblysy",
		"Zhambyl Oblysy",
	],

	Kenya: [
		"Central",
		"Coast",
		"Eastern",
		"Nairobi Area",
		"North Eastern",
		"Nyanza",
		"Rift Valley",
		"Western",
	],

	Kiribati: [],

	"Korea North": [
		"Chagang",
		"North Hamgyong",
		"South Hamgyong",
		"North Hwanghae",
		"South Hwanghae",
		"Kangwon",
		"North P'yongan",
		"South P'yongan",
		"Yanggang",
		"Kaesong",
		"Najin",
		"Namp'o",
		"Pyongyang",
	],

	"Korea South": [
		"Seoul",
		"Busan City",
		"Daegu City",
		"Incheon City",
		"Gwangju City",
		"Daejeon City",
		"Ulsan",
		"Gyeonggi Province",
		"Gangwon Province",
		"North Chungcheong Province",
		"South Chungcheong Province",
		"North Jeolla Province",
		"South Jeolla Province",
		"North Gyeongsang Province",
		"South Gyeongsang Province",
		"Jeju",
	],

	Kuwait: [
		"Al Ahmadi",
		"Al Farwaniyah",
		"Al Asimah",
		"Al Jahra",
		"Hawalli",
		"Mubarak Al-Kabeer",
	],

	Kyrgyzstan: [
		"Batken Oblasty",
		"Bishkek Shaary",
		"Chuy Oblasty",
		"Jalal-Abad Oblasty",
		"Naryn Oblasty",
		"Osh Oblasty",
		"Talas Oblasty",
		"Ysyk-Kol Oblasty",
	],

	Laos: [
		"Attapu",
		"Bokeo",
		"Bolikhamxai",
		"Champasak",
		"Houaphan",
		"Khammouan",
		"Louangnamtha",
		"Louangphrabang",
		"Oudomxai",
		"Phongsali",
		"Salavan",
		"Savannakhet",
		"Viangchan",
		"Viangchan",
		"Xaignabouli",
		"Xaisomboun",
		"Xekong",
		"Xiangkhoang",
	],

	Latvia: [
		"Aizkraukles Rajons",
		"Aluksnes Rajons",
		"Balvu Rajons",
		"Bauskas Rajons",
		"Cesu Rajons",
		"Daugavpils",
		"Daugavpils Rajons",
		"Dobeles Rajons",
		"Gulbenes Rajons",
		"Jekabpils Rajons",
		"Jelgava",
		"Jelgavas Rajons",
		"Jurmala",
		"Kraslavas Rajons",
		"Kuldigas Rajons",
		"Liepaja",
		"Liepajas Rajons",
		"Limbazu Rajons",
		"Ludzas Rajons",
		"Madonas Rajons",
		"Ogres Rajons",
		"Preilu Rajons",
		"Rezekne",
		"Rezeknes Rajons",
		"Riga",
		"Rigas Rajons",
		"Saldus Rajons",
		"Talsu Rajons",
		"Tukuma Rajons",
		"Valkas Rajons",
		"Valmieras Rajons",
		"Ventspils",
		"Ventspils Rajons",
	],

	Lebanon: [
		"Beyrouth",
		"Beqaa",
		"Liban-Nord",
		"Liban-Sud",
		"Mont-Liban",
		"Nabatiye",
	],

	Lesotho: [
		"Berea",
		"Butha-Buthe",
		"Leribe",
		"Mafeteng",
		"Maseru",
		"Mohale's Hoek",
		"Mokhotlong",
		"Qacha's Nek",
		"Quthing",
		"Thaba-Tseka",
	],

	Liberia: [
		"Bomi",
		"Bong",
		"Gbarpolu",
		"Grand Bassa",
		"Grand Cape Mount",
		"Grand Gedeh",
		"Grand Kru",
		"Lofa",
		"Margibi",
		"Maryland",
		"Montserrado",
		"Nimba",
		"River Cess",
		"River Gee",
		"Sinoe",
	],

	Libya: [
		"Ajdabiya",
		"Al 'Aziziyah",
		"Al Fatih",
		"Al Jabal al Akhdar",
		"Al Jufrah",
		"Al Khums",
		"Al Kufrah",
		"An Nuqat al Khams",
		"Ash Shati'",
		"Awbari",
		"Az Zawiyah",
		"Banghazi",
		"Darnah",
		"Ghadamis",
		"Gharyan",
		"Misratah",
		"Murzuq",
		"Sabha",
		"Sawfajjin",
		"Surt",
		"Tarabulus",
		"Tarhunah",
		"Tubruq",
		"Yafran",
		"Zlitan",
	],

	Liechtenstein: [
		"Balzers",
		"Eschen",
		"Gamprin",
		"Mauren",
		"Planken",
		"Ruggell",
		"Schaan",
		"Schellenberg",
		"Triesen",
		"Triesenberg",
		"Vaduz",
	],

	Lithuania: [
		"Alytaus",
		"Kauno",
		"Klaipedos",
		"Marijampoles",
		"Panevezio",
		"Siauliu",
		"Taurages",
		"Telsiu",
		"Utenos",
		"Vilniaus",
	],

	Luxembourg: ["Diekirch", "Grevenmacher", "Luxembourg"],

	Macedonia: [
		"Aerodrom",
		"Aracinovo",
		"Berovo",
		"Bitola",
		"Bogdanci",
		"Bogovinje",
		"Bosilovo",
		"Brvenica",
		"Butel",
		"Cair",
		"Caska",
		"Centar",
		"Centar Zupa",
		"Cesinovo",
		"Cucer-Sandevo",
		"Debar",
		"Debartsa",
		"Delcevo",
		"Demir Hisar",
		"Demir Kapija",
		"Dojran",
		"Dolneni",
		"Drugovo",
		"Gazi Baba",
		"Gevgelija",
		"Gjorce Petrov",
		"Gostivar",
		"Gradsko",
		"Ilinden",
		"Jegunovce",
		"Karbinci",
		"Karpos",
		"Kavadarci",
		"Kicevo",
		"Kisela Voda",
		"Kocani",
		"Konce",
		"Kratovo",
		"Kriva Palanka",
		"Krivogastani",
		"Krusevo",
		"Kumanovo",
		"Lipkovo",
		"Lozovo",
		"Makedonska Kamenica",
		"Makedonski Brod",
		"Mavrovo i Rastusa",
		"Mogila",
		"Negotino",
		"Novaci",
		"Novo Selo",
		"Ohrid",
		"Oslomej",
		"Pehcevo",
		"Petrovec",
		"Plasnica",
		"Prilep",
		"Probistip",
		"Radovis",
		"Rankovce",
		"Resen",
		"Rosoman",
		"Saraj",
		"Skopje",
		"Sopiste",
		"Staro Nagoricane",
		"Stip",
		"Struga",
		"Strumica",
		"Studenicani",
		"Suto Orizari",
		"Sveti Nikole",
		"Tearce",
		"Tetovo",
		"Valandovo",
		"Vasilevo",
		"Veles",
		"Vevcani",
		"Vinica",
		"Vranestica",
		"Vrapciste",
		"Zajas",
		"Zelenikovo",
		"Zelino",
		"Zrnovci",
	],

	Madagascar: [
		"Antananarivo",
		"Antsiranana",
		"Fianarantsoa",
		"Mahajanga",
		"Toamasina",
		"Toliara",
	],

	Malawi: [
		"Balaka",
		"Blantyre",
		"Chikwawa",
		"Chiradzulu",
		"Chitipa",
		"Dedza",
		"Dowa",
		"Karonga",
		"Kasungu",
		"Likoma",
		"Lilongwe",
		"Machinga",
		"Mangochi",
		"Mchinji",
		"Mulanje",
		"Mwanza",
		"Mzimba",
		"Ntcheu",
		"Nkhata Bay",
		"Nkhotakota",
		"Nsanje",
		"Ntchisi",
		"Phalombe",
		"Rumphi",
		"Salima",
		"Thyolo",
		"Zomba",
	],

	Malaysia: [
		"Johor",
		"Kedah",
		"Kelantan",
		"Kuala Lumpur",
		"Labuan",
		"Malacca",
		"Negeri Sembilan",
		"Pahang",
		"Perak",
		"Perlis",
		"Penang",
		"Sabah",
		"Sarawak",
		"Selangor",
		"Terengganu",
	],

	Maldives: [
		"Alifu",
		"Baa",
		"Dhaalu",
		"Faafu",
		"Gaafu Alifu",
		"Gaafu Dhaalu",
		"Gnaviyani",
		"Haa Alifu",
		"Haa Dhaalu",
		"Kaafu",
		"Laamu",
		"Lhaviyani",
		"Maale",
		"Meemu",
		"Noonu",
		"Raa",
		"Seenu",
		"Shaviyani",
		"Thaa",
		"Vaavu",
	],

	Mali: [
		"Bamako (Capital)",
		"Gao",
		"Kayes",
		"Kidal",
		"Koulikoro",
		"Mopti",
		"Segou",
		"Sikasso",
		"Tombouctou",
	],

	Malta: [],

	"Marshall Islands": [],

	Mauritania: [
		"Adrar",
		"Assaba",
		"Brakna",
		"Dakhlet Nouadhibou",
		"Gorgol",
		"Guidimaka",
		"Hodh Ech Chargui",
		"Hodh El Gharbi",
		"Inchiri",
		"Nouakchott",
		"Tagant",
		"Tiris Zemmour",
		"Trarza",
	],

	Mauritius: [
		"Agalega Islands",
		"Black River",
		"Cargados Carajos Shoals",
		"Flacq",
		"Grand Port",
		"Moka",
		"Pamplemousses",
		"Plaines Wilhems",
		"Port Louis",
		"Riviere du Rempart",
		"Rodrigues",
		"Savanne",
	],

	Mexico: [
		"Aguascalientes",
		"Baja California",
		"Baja California Sur",
		"Campeche",
		"Chiapas",
		"Chihuahua",
		"Coahuila de Zaragoza",
		"Colima",
		"Distrito Federal",
		"Durango",
		"Guanajuato",
		"Guerrero",
		"Hidalgo",
		"Jalisco",
		"Mexico",
		"Michoacan de Ocampo",
		"Morelos",
		"Nayarit",
		"Nuevo Leon",
		"Oaxaca",
		"Puebla",
		"Queretaro de Arteaga",
		"Quintana Roo",
		"San Luis Potosi",
		"Sinaloa",
		"Sonora",
		"Tabasco",
		"Tamaulipas",
		"Tlaxcala",
		"Veracruz-Llave",
		"Yucatan",
		"Zacatecas",
	],

	Micronesia: [],

	Moldova: [
		"Anenii Noi",
		"Basarabeasca",
		"Briceni",
		"Cahul",
		"Cantemir",
		"Calarasi",
		"Causeni",
		"Cimislia",
		"Criuleni",
		"Donduseni",
		"Drochia",
		"Dubasari",
		"Edinet",
		"Falesti",
		"Floresti",
		"Glodeni",
		"Hincesti",
		"Ialoveni",
		"Leova",
		"Nisporeni",
		"Ocnita",
		"Orhei",
		"Rezina",
		"Riscani",
		"Singerei",
		"Soldanesti",
		"Soroca",
		"Stefan-Voda",
		"Straseni",
		"Taraclia",
		"Telenesti",
		"Ungheni",
		"Balti",
		"Bender",
		"Chisinau",
		"Gagauzia",
		"Stinga Nistrului",
	],

	Mongolia: [
		"Arhangay",
		"Bayanhongor",
		"Bayan-Olgiy",
		"Bulgan",
		"Darhan Uul",
		"Dornod",
		"Dornogovi",
		"Dundgovi",
		"Dzavhan",
		"Govi-Altay",
		"Govi-Sumber",
		"Hentiy",
		"Hovd",
		"Hovsgol",
		"Omnogovi",
		"Orhon",
		"Ovorhangay",
		"Selenge",
		"Suhbaatar",
		"Tov",
		"Ulaanbaatar",
		"Uvs",
	],

	Morocco: [
		"Agadir",
		"Al Hoceima",
		"Azilal",
		"Beni Mellal",
		"Ben Slimane",
		"Boulemane",
		"Casablanca",
		"Chaouen",
		"El Jadida",
		"El Kelaa des Sraghna",
		"Er Rachidia",
		"Essaouira",
		"Fes",
		"Figuig",
		"Guelmim",
		"Ifrane",
		"Kenitra",
		"Khemisset",
		"Khenifra",
		"Khouribga",
		"Laayoune",
		"Larache",
		"Marrakech",
		"Meknes",
		"Nador",
		"Ouarzazate",
		"Oujda",
		"Rabat-Sale",
		"Safi",
		"Settat",
		"Sidi Kacem",
		"Tangier",
		"Tan-Tan",
		"Taounate",
		"Taroudannt",
		"Tata",
		"Taza",
		"Tetouan",
		"Tiznit",
	],

	Monaco: [],

	Mozambique: [
		"Cabo Delgado",
		"Gaza",
		"Inhambane",
		"Manica",
		"Maputo",
		"Cidade de Maputo",
		"Nampula",
		"Niassa",
		"Sofala",
		"Tete",
		"Zambezia",
	],

	Namibia: [
		"Caprivi",
		"Erongo",
		"Hardap",
		"Karas",
		"Khomas",
		"Kunene",
		"Ohangwena",
		"Okavango",
		"Omaheke",
		"Omusati",
		"Oshana",
		"Oshikoto",
		"Otjozondjupa",
	],

	Nauru: [],

	Nepal: [
		"Bagmati",
		"Bheri",
		"Dhawalagiri",
		"Gandaki",
		"Janakpur",
		"Karnali",
		"Kosi",
		"Lumbini",
		"Mahakali",
		"Mechi",
		"Narayani",
		"Rapti",
		"Sagarmatha",
		"Seti",
	],

	Netherlands: [
		"Drenthe",
		"Flevoland",
		"Friesland",
		"Gelderland",
		"Groningen",
		"Limburg",
		"Noord-Brabant",
		"Noord-Holland",
		"Overijssel",
		"Utrecht",
		"Zeeland",
		"Zuid-Holland",
	],

	"New Zealand": [
		"Auckland",
		"Bay of Plenty",
		"Canterbury",
		"Chatham Islands",
		"Gisborne",
		"Hawke's Bay",
		"Manawatu-Wanganui",
		"Marlborough",
		"Nelson",
		"Northland",
		"Otago",
		"Southland",
		"Taranaki",
		"Tasman",
		"Waikato",
		"Wellington",
		"West Coast",
	],

	Nicaragua: [
		"Atlantico Norte",
		"Atlantico Sur",
		"Boaco",
		"Carazo",
		"Chinandega",
		"Chontales",
		"Esteli",
		"Granada",
		"Jinotega",
		"Leon",
		"Madriz",
		"Managua",
		"Masaya",
		"Matagalpa",
		"Nueva Segovia",
		"Rio San Juan",
		"Rivas",
	],

	Niger: [
		"Agadez",
		"Diffa",
		"Dosso",
		"Maradi",
		"Niamey",
		"Tahoua",
		"Tillaberi",
		"Zinder",
	],

	Nigeria: [
		"Abia",
		"Abuja Federal Capital",
		"Adamawa",
		"Akwa Ibom",
		"Anambra",
		"Bauchi",
		"Bayelsa",
		"Benue",
		"Borno",
		"Cross River",
		"Delta",
		"Ebonyi",
		"Edo",
		"Ekiti",
		"Enugu",
		"Gombe",
		"Imo",
		"Jigawa",
		"Kaduna",
		"Kano",
		"Katsina",
		"Kebbi",
		"Kogi",
		"Kwara",
		"Lagos",
		"Nassarawa",
		"Niger",
		"Ogun",
		"Ondo",
		"Osun",
		"Oyo",
		"Plateau",
		"Rivers",
		"Sokoto",
		"Taraba",
		"Yobe",
		"Zamfara",
	],

	Norway: [
		"Akershus",
		"Aust-Agder",
		"Buskerud",
		"Finnmark",
		"Hedmark",
		"Hordaland",
		"More og Romsdal",
		"Nordland",
		"Nord-Trondelag",
		"Oppland",
		"Oslo",
		"Ostfold",
		"Rogaland",
		"Sogn og Fjordane",
		"Sor-Trondelag",
		"Telemark",
		"Troms",
		"Vest-Agder",
		"Vestfold",
	],

	Oman: [
		"Ad Dakhiliyah",
		"Al Batinah",
		"Al Wusta",
		"Ash Sharqiyah",
		"Az Zahirah",
		"Masqat",
		"Musandam",
		"Dhofar",
	],

	Pakistan: [
		"Balochistan",
		"North-West Frontier Province",
		"Punjab",
		"Sindh",
		"Islamabad Capital Territory",
		"Federally Administered Tribal Areas",
	],

	Panama: [
		"Bocas del Toro",
		"Chiriqui",
		"Cocle",
		"Colon",
		"Darien",
		"Herrera",
		"Los Santos",
		"Panama",
		"San Blas",
		"Veraguas",
	],

	"Papua New Guinea": [
		"Bougainville",
		"Central",
		"Chimbu",
		"Eastern Highlands",
		"East New Britain",
		"East Sepik",
		"Enga",
		"Gulf",
		"Madang",
		"Manus",
		"Milne Bay",
		"Morobe",
		"National Capital",
		"New Ireland",
		"Northern",
		"Sandaun",
		"Southern Highlands",
		"Western",
		"Western Highlands",
		"West New Britain",
	],

	Paraguay: [
		"Alto Paraguay",
		"Alto Parana",
		"Amambay",
		"Asuncion",
		"Boqueron",
		"Caaguazu",
		"Caazapa",
		"Canindeyu",
		"Central",
		"Concepcion",
		"Cordillera",
		"Guaira",
		"Itapua",
		"Misiones",
		"Neembucu",
		"Paraguari",
		"Presidente Hayes",
		"San Pedro",
	],

	Peru: [
		"Amazonas",
		"Ancash",
		"Apurimac",
		"Arequipa",
		"Ayacucho",
		"Cajamarca",
		"Callao",
		"Cusco",
		"Huancavelica",
		"Huanuco",
		"Ica",
		"Junin",
		"La Libertad",
		"Lambayeque",
		"Lima",
		"Loreto",
		"Madre de Dios",
		"Moquegua",
		"Pasco",
		"Piura",
		"Puno",
		"San Martin",
		"Tacna",
		"Tumbes",
		"Ucayali",
	],

	Philippines: [
		"Abra",
		"Agusan del Norte",
		"Agusan del Sur",
		"Aklan",
		"Albay",
		"Antique",
		"Apayao",
		"Aurora",
		"Basilan",
		"Bataan",
		"Batanes",
		"Batangas",
		"Biliran",
		"Benguet",
		"Bohol",
		"Bukidnon",
		"Bulacan",
		"Cagayan",
		"Camarines Norte",
		"Camarines Sur",
		"Camiguin",
		"Capiz",
		"Catanduanes",
		"Cavite",
		"Cebu",
		"Compostela",
		"Davao del Norte",
		"Davao del Sur",
		"Davao Oriental",
		"Eastern Samar",
		"Guimaras",
		"Ifugao",
		"Ilocos Norte",
		"Ilocos Sur",
		"Iloilo",
		"Isabela",
		"Kalinga",
		"Laguna",
		"Lanao del Norte",
		"Lanao del Sur",
		"La Union",
		"Leyte",
		"Maguindanao",
		"Marinduque",
		"Masbate",
		"Mindoro Occidental",
		"Mindoro Oriental",
		"Misamis Occidental",
		"Misamis Oriental",
		"Mountain Province",
		"Negros Occidental",
		"Negros Oriental",
		"North Cotabato",
		"Northern Samar",
		"Nueva Ecija",
		"Nueva Vizcaya",
		"Palawan",
		"Pampanga",
		"Pangasinan",
		"Quezon",
		"Quirino",
		"Rizal",
		"Romblon",
		"Samar",
		"Sarangani",
		"Siquijor",
		"Sorsogon",
		"South Cotabato",
		"Southern Leyte",
		"Sultan Kudarat",
		"Sulu",
		"Surigao del Norte",
		"Surigao del Sur",
		"Tarlac",
		"Tawi-Tawi",
		"Zambales",
		"Zamboanga del Norte",
		"Zamboanga del Sur",
		"Zamboanga Sibugay",
	],

	Poland: [
		"Greater Poland (Wielkopolskie)",
		"Kuyavian-Pomeranian (Kujawsko-Pomorskie)",
		"Lesser Poland (Malopolskie)",
		"Lodz (Lodzkie)",
		"Lower Silesian (Dolnoslaskie)",
		"Lublin (Lubelskie)",
		"Lubusz (Lubuskie)",
		"Masovian (Mazowieckie)",
		"Opole (Opolskie)",
		"Podlasie (Podlaskie)",
		"Pomeranian (Pomorskie)",
		"Silesian (Slaskie)",
		"Subcarpathian (Podkarpackie)",
		"Swietokrzyskie (Swietokrzyskie)",
		"Warmian-Masurian (Warminsko-Mazurskie)",
		"West Pomeranian (Zachodniopomorskie)",
	],

	Portugal: [
		"Aveiro",
		"Acores",
		"Beja",
		"Braga",
		"Braganca",
		"Castelo Branco",
		"Coimbra",
		"Evora",
		"Faro",
		"Guarda",
		"Leiria",
		"Lisboa",
		"Madeira",
		"Portalegre",
		"Porto",
		"Santarem",
		"Setubal",
		"Viana do Castelo",
		"Vila Real",
		"Viseu",
	],

	Qatar: [
		"Ad Dawhah",
		"Al Ghuwayriyah",
		"Al Jumayliyah",
		"Al Khawr",
		"Al Wakrah",
		"Ar Rayyan",
		"Jarayan al Batinah",
		"Madinat ash Shamal",
		"Umm Sa'id",
		"Umm Salal",
	],

	Romania: [
		"Alba",
		"Arad",
		"Arges",
		"Bacau",
		"Bihor",
		"Bistrita-Nasaud",
		"Botosani",
		"Braila",
		"Brasov",
		"Bucuresti",
		"Buzau",
		"Calarasi",
		"Caras-Severin",
		"Cluj",
		"Constanta",
		"Covasna",
		"Dimbovita",
		"Dolj",
		"Galati",
		"Gorj",
		"Giurgiu",
		"Harghita",
		"Hunedoara",
		"Ialomita",
		"Iasi",
		"Ilfov",
		"Maramures",
		"Mehedinti",
		"Mures",
		"Neamt",
		"Olt",
		"Prahova",
		"Salaj",
		"Satu Mare",
		"Sibiu",
		"Suceava",
		"Teleorman",
		"Timis",
		"Tulcea",
		"Vaslui",
		"Vilcea",
		"Vrancea",
	],

	Russia: [
		"Amur",
		"Arkhangel'sk",
		"Astrakhan'",
		"Belgorod",
		"Bryansk",
		"Chelyabinsk",
		"Chita",
		"Irkutsk",
		"Ivanovo",
		"Kaliningrad",
		"Kaluga",
		"Kamchatka",
		"Kemerovo",
		"Kirov",
		"Kostroma",
		"Kurgan",
		"Kursk",
		"Leningrad",
		"Lipetsk",
		"Magadan",
		"Moscow",
		"Murmansk",
		"Nizhniy Novgorod",
		"Novgorod",
		"Novosibirsk",
		"Omsk",
		"Orenburg",
		"Orel",
		"Penza",
		"Perm'",
		"Pskov",
		"Rostov",
		"Ryazan'",
		"Sakhalin",
		"Samara",
		"Saratov",
		"Smolensk",
		"Sverdlovsk",
		"Tambov",
		"Tomsk",
		"Tula",
		"Tver'",
		"Tyumen'",
		"Ul'yanovsk",
		"Vladimir",
		"Volgograd",
		"Vologda",
		"Voronezh",
		"Yaroslavl'",
		"Adygeya",
		"Altay",
		"Bashkortostan",
		"Buryatiya",
		"Chechnya",
		"Chuvashiya",
		"Dagestan",
		"Ingushetiya",
		"Kabardino-Balkariya",
		"Kalmykiya",
		"Karachayevo-Cherkesiya",
		"Kareliya",
		"Khakasiya",
		"Komi",
		"Mariy-El",
		"Mordoviya",
		"Sakha",
		"North Ossetia",
		"Tatarstan",
		"Tyva",
		"Udmurtiya",
		"Aga Buryat",
		"Chukotka",
		"Evenk",
		"Khanty-Mansi",
		"Komi-Permyak",
		"Koryak",
		"Nenets",
		"Taymyr",
		"Ust'-Orda Buryat",
		"Yamalo-Nenets",
		"Altay",
		"Khabarovsk",
		"Krasnodar",
		"Krasnoyarsk",
		"Primorskiy",
		"Stavropol'",
		"Moscow",
		"St. Petersburg",
		"Yevrey",
	],

	Rwanda: [
		"Butare",
		"Byumba",
		"Cyangugu",
		"Gikongoro",
		"Gisenyi",
		"Gitarama",
		"Kibungo",
		"Kibuye",
		"Kigali Rurale",
		"Kigali-ville",
		"Umutara",
		"Ruhengeri",
	],

	Samoa: [
		"A'ana",
		"Aiga-i-le-Tai",
		"Atua",
		"Fa'asaleleaga",
		"Gaga'emauga",
		"Gagaifomauga",
		"Palauli",
		"Satupa'itea",
		"Tuamasaga",
		"Va'a-o-Fonoti",
		"Vaisigano",
	],

	"San Marino": [
		"Acquaviva",
		"Borgo Maggiore",
		"Chiesanuova",
		"Domagnano",
		"Faetano",
		"Fiorentino",
		"Montegiardino",
		"San Marino Citta",
		"Serravalle",
	],

	"Sao Tome": [],

	"Saudi Arabia": [
		"Al Bahah",
		"Al Hudud ash Shamaliyah",
		"Al Jawf",
		"Al Madinah",
		"Al Qasim",
		"Ar Riyad",
		"Ash Sharqiyah",
		"'Asir",
		"Ha'il",
		"Jizan",
		"Makkah",
		"Najran",
		"Tabuk",
	],

	Senegal: [
		"Dakar",
		"Diourbel",
		"Fatick",
		"Kaolack",
		"Kolda",
		"Louga",
		"Matam",
		"Saint-Louis",
		"Tambacounda",
		"Thies",
		"Ziguinchor",
	],

	"Serbia and Montenegro": ["Kosovo", "Montenegro", "Serbia", "Vojvodina"],

	Seychelles: [
		"Anse aux Pins",
		"Anse Boileau",
		"Anse Etoile",
		"Anse Louis",
		"Anse Royale",
		"Baie Lazare",
		"Baie Sainte Anne",
		"Beau Vallon",
		"Bel Air",
		"Bel Ombre",
		"Cascade",
		"Glacis",
		"Grand' Anse",
		"Grand' Anse",
		"La Digue",
		"La Riviere Anglaise",
		"Mont Buxton",
		"Mont Fleuri",
		"Plaisance",
		"Pointe La Rue",
		"Port Glaud",
		"Saint Louis",
		"Takamaka",
	],

	"Sierra Leone": [],

	Singapore: [],

	Slovakia: [
		"Banskobystricky",
		"Bratislavsky",
		"Kosicky",
		"Nitriansky",
		"Presovsky",
		"Trenciansky",
		"Trnavsky",
		"Zilinsky",
	],

	Slovenia: [
		"Ajdovscina",
		"Beltinci",
		"Benedikt",
		"Bistrica ob Sotli",
		"Bled",
		"Bloke",
		"Bohinj",
		"Borovnica",
		"Bovec",
		"Braslovce",
		"Brda",
		"Brezice",
		"Brezovica",
		"Cankova",
		"Celje",
		"Cerklje na Gorenjskem",
		"Cerknica",
		"Cerkno",
		"Cerkvenjak",
		"Crensovci",
		"Crna na Koroskem",
		"Crnomelj",
		"Destrnik",
		"Divaca",
		"Dobje",
		"Dobrepolje",
		"Dobrna",
		"Dobrova-Horjul-Polhov Gradec",
		"Dobrovnik-Dobronak",
		"Dolenjske Toplice",
		"Dol pri Ljubljani",
		"Domzale",
		"Dornava",
		"Dravograd",
		"Duplek",
		"Gorenja Vas-Poljane",
		"Gorisnica",
		"Gornja Radgona",
		"Gornji Grad",
		"Gornji Petrovci",
		"Grad",
		"Grosuplje",
		"Hajdina",
		"Hoce-Slivnica",
		"Hodos-Hodos",
		"Horjul",
		"Hrastnik",
		"Hrpelje-Kozina",
		"Idrija",
		"Ig",
		"Ilirska Bistrica",
		"Ivancna Gorica",
		"Izola-Isola",
		"Jesenice",
		"Jezersko",
		"Jursinci",
		"Kamnik",
		"Kanal",
		"Kidricevo",
		"Kobarid",
		"Kobilje",
		"Kocevje",
		"Komen",
		"Komenda",
		"Koper-Capodistria",
		"Kostel",
		"Kozje",
		"Kranj",
		"Kranjska Gora",
		"Krizevci",
		"Krsko",
		"Kungota",
		"Kuzma",
		"Lasko",
		"Lenart",
		"Lendava-Lendva",
		"Litija",
		"Ljubljana",
		"Ljubno",
		"Ljutomer",
		"Logatec",
		"Loska Dolina",
		"Loski Potok",
		"Lovrenc na Pohorju",
		"Luce",
		"Lukovica",
		"Majsperk",
		"Maribor",
		"Markovci",
		"Medvode",
		"Menges",
		"Metlika",
		"Mezica",
		"Miklavz na Dravskem Polju",
		"Miren-Kostanjevica",
		"Mirna Pec",
		"Mislinja",
		"Moravce",
		"Moravske Toplice",
		"Mozirje",
		"Murska Sobota",
		"Muta",
		"Naklo",
		"Nazarje",
		"Nova Gorica",
		"Novo Mesto",
		"Odranci",
		"Oplotnica",
		"Ormoz",
		"Osilnica",
		"Pesnica",
		"Piran-Pirano",
		"Pivka",
		"Podcetrtek",
		"Podlehnik",
		"Podvelka",
		"Polzela",
		"Postojna",
		"Prebold",
		"Preddvor",
		"Prevalje",
		"Ptuj",
		"Puconci",
		"Race-Fram",
		"Radece",
		"Radenci",
		"Radlje ob Dravi",
		"Radovljica",
		"Ravne na Koroskem",
		"Razkrizje",
		"Ribnica",
		"Ribnica na Pohorju",
		"Rogasovci",
		"Rogaska Slatina",
		"Rogatec",
		"Ruse",
		"Salovci",
		"Selnica ob Dravi",
		"Semic",
		"Sempeter-Vrtojba",
		"Sencur",
		"Sentilj",
		"Sentjernej",
		"Sentjur pri Celju",
		"Sevnica",
		"Sezana",
		"Skocjan",
		"Skofja Loka",
		"Skofljica",
		"Slovenj Gradec",
		"Slovenska Bistrica",
		"Slovenske Konjice",
		"Smarje pri Jelsah",
		"Smartno ob Paki",
		"Smartno pri Litiji",
		"Sodrazica",
		"Solcava",
		"Sostanj",
		"Starse",
		"Store",
		"Sveta Ana",
		"Sveti Andraz v Slovenskih Goricah",
		"Sveti Jurij",
		"Tabor",
		"Tisina",
		"Tolmin",
		"Trbovlje",
		"Trebnje",
		"Trnovska Vas",
		"Trzic",
		"Trzin",
		"Turnisce",
		"Velenje",
		"Velika Polana",
		"Velike Lasce",
		"Verzej",
		"Videm",
		"Vipava",
		"Vitanje",
		"Vodice",
		"Vojnik",
		"Vransko",
		"Vrhnika",
		"Vuzenica",
		"Zagorje ob Savi",
		"Zalec",
		"Zavrc",
		"Zelezniki",
		"Zetale",
		"Ziri",
		"Zirovnica",
		"Zuzemberk",
		"Zrece",
	],

	"Solomon Islands": [
		"Central",
		"Choiseul",
		"Guadalcanal",
		"Honiara",
		"Isabel",
		"Makira",
		"Malaita",
		"Rennell and Bellona",
		"Temotu",
		"Western",
	],

	Somalia: [
		"Awdal",
		"Bakool",
		"Banaadir",
		"Bari",
		"Bay",
		"Galguduud",
		"Gedo",
		"Hiiraan",
		"Jubbada Dhexe",
		"Jubbada Hoose",
		"Mudug",
		"Nugaal",
		"Sanaag",
		"Shabeellaha Dhexe",
		"Shabeellaha Hoose",
		"Sool",
		"Togdheer",
		"Woqooyi Galbeed",
	],

	"South Africa": [
		"Eastern Cape",
		"Free State",
		"Gauteng",
		"KwaZulu-Natal",
		"Limpopo",
		"Mpumalanga",
		"North-West",
		"Northern Cape",
		"Western Cape",
	],

	Spain: [
		"Andalucia",
		"Aragon",
		"Asturias",
		"Baleares",
		"Ceuta",
		"Canarias",
		"Cantabria",
		"Castilla-La Mancha",
		"Castilla y Leon",
		"Cataluna",
		"Comunidad Valenciana",
		"Extremadura",
		"Galicia",
		"La Rioja",
		"Madrid",
		"Melilla",
		"Murcia",
		"Navarra",
		"Pais Vasco",
	],

	"Sri Lanka": [
		"Central",
		"North Central",
		"North Eastern",
		"North Western",
		"Sabaragamuwa",
		"Southern",
		"Uva",
		"Western",
	],

	Sudan: [
		"A'ali an Nil",
		"Al Bahr al Ahmar",
		"Al Buhayrat",
		"Al Jazirah",
		"Al Khartum",
		"Al Qadarif",
		"Al Wahdah",
		"An Nil al Abyad",
		"An Nil al Azraq",
		"Ash Shamaliyah",
		"Bahr al Jabal",
		"Gharb al Istiwa'iyah",
		"Gharb Bahr al Ghazal",
		"Gharb Darfur",
		"Gharb Kurdufan",
		"Janub Darfur",
		"Janub Kurdufan",
		"Junqali",
		"Kassala",
		"Nahr an Nil",
		"Shamal Bahr al Ghazal",
		"Shamal Darfur",
		"Shamal Kurdufan",
		"Sharq al Istiwa'iyah",
		"Sinnar",
		"Warab",
	],

	Suriname: [
		"Brokopondo",
		"Commewijne",
		"Coronie",
		"Marowijne",
		"Nickerie",
		"Para",
		"Paramaribo",
		"Saramacca",
		"Sipaliwini",
		"Wanica",
	],

	Swaziland: ["Hhohho", "Lubombo", "Manzini", "Shiselweni"],

	Sweden: [
		"Blekinge",
		"Dalarnas",
		"Gavleborgs",
		"Gotlands",
		"Hallands",
		"Jamtlands",
		"Jonkopings",
		"Kalmar",
		"Kronobergs",
		"Norrbottens",
		"Orebro",
		"Ostergotlands",
		"Skane",
		"Sodermanlands",
		"Stockholms",
		"Uppsala",
		"Varmlands",
		"Vasterbottens",
		"Vasternorrlands",
		"Vastmanlands",
		"Vastra Gotalands",
	],

	Switzerland: [
		"Aargau",
		"Appenzell Ausser-Rhoden",
		"Appenzell Inner-Rhoden",
		"Basel-Landschaft",
		"Basel-Stadt",
		"Bern",
		"Fribourg",
		"Geneve",
		"Glarus",
		"Graubunden",
		"Jura",
		"Luzern",
		"Neuchatel",
		"Nidwalden",
		"Obwalden",
		"Sankt Gallen",
		"Schaffhausen",
		"Schwyz",
		"Solothurn",
		"Thurgau",
		"Ticino",
		"Uri",
		"Valais",
		"Vaud",
		"Zug",
		"Zurich",
	],

	Syria: [
		"Al Hasakah",
		"Al Ladhiqiyah",
		"Al Qunaytirah",
		"Ar Raqqah",
		"As Suwayda'",
		"Dar'a",
		"Dayr az Zawr",
		"Dimashq",
		"Halab",
		"Hamah",
		"Hims",
		"Idlib",
		"Rif Dimashq",
		"Tartus",
	],

	Taiwan: [
		"Chang-hua",
		"Chia-i",
		"Hsin-chu",
		"Hua-lien",
		"I-lan",
		"Kao-hsiung",
		"Kin-men",
		"Lien-chiang",
		"Miao-li",
		"Nan-t'ou",
		"P'eng-hu",
		"P'ing-tung",
		"T'ai-chung",
		"T'ai-nan",
		"T'ai-pei",
		"T'ai-tung",
		"T'ao-yuan",
		"Yun-lin",
		"Chia-i",
		"Chi-lung",
		"Hsin-chu",
		"T'ai-chung",
		"T'ai-nan",
		"Kao-hsiung city",
		"T'ai-pei city",
	],

	Tajikistan: [],

	Tanzania: [
		"Arusha",
		"Dar es Salaam",
		"Dodoma",
		"Iringa",
		"Kagera",
		"Kigoma",
		"Kilimanjaro",
		"Lindi",
		"Manyara",
		"Mara",
		"Mbeya",
		"Morogoro",
		"Mtwara",
		"Mwanza",
		"Pemba North",
		"Pemba South",
		"Pwani",
		"Rukwa",
		"Ruvuma",
		"Shinyanga",
		"Singida",
		"Tabora",
		"Tanga",
		"Zanzibar Central/South",
		"Zanzibar North",
		"Zanzibar Urban/West",
	],

	Thailand: [
		"Amnat Charoen",
		"Ang Thong",
		"Buriram",
		"Chachoengsao",
		"Chai Nat",
		"Chaiyaphum",
		"Chanthaburi",
		"Chiang Mai",
		"Chiang Rai",
		"Chon Buri",
		"Chumphon",
		"Kalasin",
		"Kamphaeng Phet",
		"Kanchanaburi",
		"Khon Kaen",
		"Krabi",
		"Krung Thep Mahanakhon",
		"Lampang",
		"Lamphun",
		"Loei",
		"Lop Buri",
		"Mae Hong Son",
		"Maha Sarakham",
		"Mukdahan",
		"Nakhon Nayok",
		"Nakhon Pathom",
		"Nakhon Phanom",
		"Nakhon Ratchasima",
		"Nakhon Sawan",
		"Nakhon Si Thammarat",
		"Nan",
		"Narathiwat",
		"Nong Bua Lamphu",
		"Nong Khai",
		"Nonthaburi",
		"Pathum Thani",
		"Pattani",
		"Phangnga",
		"Phatthalung",
		"Phayao",
		"Phetchabun",
		"Phetchaburi",
		"Phichit",
		"Phitsanulok",
		"Phra Nakhon Si Ayutthaya",
		"Phrae",
		"Phuket",
		"Prachin Buri",
		"Prachuap Khiri Khan",
		"Ranong",
		"Ratchaburi",
		"Rayong",
		"Roi Et",
		"Sa Kaeo",
		"Sakon Nakhon",
		"Samut Prakan",
		"Samut Sakhon",
		"Samut Songkhram",
		"Sara Buri",
		"Satun",
		"Sing Buri",
		"Sisaket",
		"Songkhla",
		"Sukhothai",
		"Suphan Buri",
		"Surat Thani",
		"Surin",
		"Tak",
		"Trang",
		"Trat",
		"Ubon Ratchathani",
		"Udon Thani",
		"Uthai Thani",
		"Uttaradit",
		"Yala",
		"Yasothon",
	],

	Togo: ["Kara", "Plateaux", "Savanes", "Centrale", "Maritime"],

	Tonga: [],

	"Trinidad and Tobago": [
		"Couva",
		"Diego Martin",
		"Mayaro",
		"Penal",
		"Princes Town",
		"Sangre Grande",
		"San Juan",
		"Siparia",
		"Tunapuna",
		"Port-of-Spain",
		"San Fernando",
		"Arima",
		"Point Fortin",
		"Chaguanas",
		"Tobago",
	],

	Tunisia: [
		"Ariana (Aryanah)",
		"Beja (Bajah)",
		"Ben Arous (Bin 'Arus)",
		"Bizerte (Banzart)",
		"Gabes (Qabis)",
		"Gafsa (Qafsah)",
		"Jendouba (Jundubah)",
		"Kairouan (Al Qayrawan)",
		"Kasserine (Al Qasrayn)",
		"Kebili (Qibili)",
		"Kef (Al Kaf)",
		"Mahdia (Al Mahdiyah)",
		"Manouba (Manubah)",
		"Medenine (Madanin)",
		"Monastir (Al Munastir)",
		"Nabeul (Nabul)",
		"Sfax (Safaqis)",
		"Sidi Bou Zid (Sidi Bu Zayd)",
		"Siliana (Silyanah)",
		"Sousse (Susah)",
		"Tataouine (Tatawin)",
		"Tozeur (Tawzar)",
		"Tunis",
		"Zaghouan (Zaghwan)",
	],

	Turkey: [
		"Adana",
		"Adiyaman",
		"Afyonkarahisar",
		"Agri",
		"Aksaray",
		"Amasya",
		"Ankara",
		"Antalya",
		"Ardahan",
		"Artvin",
		"Aydin",
		"Balikesir",
		"Bartin",
		"Batman",
		"Bayburt",
		"Bilecik",
		"Bingol",
		"Bitlis",
		"Bolu",
		"Burdur",
		"Bursa",
		"Canakkale",
		"Cankiri",
		"Corum",
		"Denizli",
		"Diyarbakir",
		"Duzce",
		"Edirne",
		"Elazig",
		"Erzincan",
		"Erzurum",
		"Eskisehir",
		"Gaziantep",
		"Giresun",
		"Gumushane",
		"Hakkari",
		"Hatay",
		"Igdir",
		"Isparta",
		"Istanbul",
		"Izmir",
		"Kahramanmaras",
		"Karabuk",
		"Karaman",
		"Kars",
		"Kastamonu",
		"Kayseri",
		"Kilis",
		"Kirikkale",
		"Kirklareli",
		"Kirsehir",
		"Kocaeli",
		"Konya",
		"Kutahya",
		"Malatya",
		"Manisa",
		"Mardin",
		"Mersin",
		"Mugla",
		"Mus",
		"Nevsehir",
		"Nigde",
		"Ordu",
		"Osmaniye",
		"Rize",
		"Sakarya",
		"Samsun",
		"Sanliurfa",
		"Siirt",
		"Sinop",
		"Sirnak",
		"Sivas",
		"Tekirdag",
		"Tokat",
		"Trabzon",
		"Tunceli",
		"Usak",
		"Van",
		"Yalova",
		"Yozgat",
		"Zonguldak",
	],

	Turkmenistan: [
		"Ahal Welayaty (Ashgabat)",
		"Balkan Welayaty (Balkanabat)",
		"Dashoguz Welayaty",
		"Lebap Welayaty (Turkmenabat)",
		"Mary Welayaty",
	],

	Uganda: [
		"Adjumani",
		"Apac",
		"Arua",
		"Bugiri",
		"Bundibugyo",
		"Bushenyi",
		"Busia",
		"Gulu",
		"Hoima",
		"Iganga",
		"Jinja",
		"Kabale",
		"Kabarole",
		"Kaberamaido",
		"Kalangala",
		"Kampala",
		"Kamuli",
		"Kamwenge",
		"Kanungu",
		"Kapchorwa",
		"Kasese",
		"Katakwi",
		"Kayunga",
		"Kibale",
		"Kiboga",
		"Kisoro",
		"Kitgum",
		"Kotido",
		"Kumi",
		"Kyenjojo",
		"Lira",
		"Luwero",
		"Masaka",
		"Masindi",
		"Mayuge",
		"Mbale",
		"Mbarara",
		"Moroto",
		"Moyo",
		"Mpigi",
		"Mubende",
		"Mukono",
		"Nakapiripirit",
		"Nakasongola",
		"Nebbi",
		"Ntungamo",
		"Pader",
		"Pallisa",
		"Rakai",
		"Rukungiri",
		"Sembabule",
		"Sironko",
		"Soroti",
		"Tororo",
		"Wakiso",
		"Yumbe",
	],

	Ukraine: [
		"Cherkasy",
		"Chernihiv",
		"Chernivtsi",
		"Crimea",
		"Dnipropetrovs'k",
		"Donets'k",
		"Ivano-Frankivs'k",
		"Kharkiv",
		"Kherson",
		"Khmel'nyts'kyy",
		"Kirovohrad",
		"Kiev",
		"Kyyiv",
		"Luhans'k",
		"L'viv",
		"Mykolayiv",
		"Odesa",
		"Poltava",
		"Rivne",
		"Sevastopol'",
		"Sumy",
		"Ternopil'",
		"Vinnytsya",
		"Volyn'",
		"Zakarpattya",
		"Zaporizhzhya",
		"Zhytomyr",
	],

	"United Arab Emirates": [
		"Abu Dhabi",
		"'Ajman",
		"Al Fujayrah",
		"Sharjah",
		"Dubai",
		"Ra's al Khaymah",
		"Umm al Qaywayn",
	],

	"United Kingdom": [],

	Uruguay: [
		"Artigas",
		"Canelones",
		"Cerro Largo",
		"Colonia",
		"Durazno",
		"Flores",
		"Florida",
		"Lavalleja",
		"Maldonado",
		"Montevideo",
		"Paysandu",
		"Rio Negro",
		"Rivera",
		"Rocha",
		"Salto",
		"San Jose",
		"Soriano",
		"Tacuarembo",
		"Treinta y Tres",
	],

	Uzbekistan: [
		"Andijon Viloyati",
		"Buxoro Viloyati",
		"Farg'ona Viloyati",
		"Jizzax Viloyati",
		"Namangan Viloyati",
		"Navoiy Viloyati",
		"Qashqadaryo Viloyati",
		"Qaraqalpog'iston Respublikasi",
		"Samarqand Viloyati",
		"Sirdaryo Viloyati",
		"Surxondaryo Viloyati",
		"Toshkent Shahri",
		"Toshkent Viloyati",
		"Xorazm Viloyati",
	],

	Vanuatu: ["Malampa", "Penama", "Sanma", "Shefa", "Tafea", "Torba"],

	Venezuela: [
		"Amazonas",
		"Anzoategui",
		"Apure",
		"Aragua",
		"Barinas",
		"Bolivar",
		"Carabobo",
		"Cojedes",
		"Delta Amacuro",
		"Dependencias Federales",
		"Distrito Federal",
		"Falcon",
		"Guarico",
		"Lara",
		"Merida",
		"Miranda",
		"Monagas",
		"Nueva Esparta",
		"Portuguesa",
		"Sucre",
		"Tachira",
		"Trujillo",
		"Vargas",
		"Yaracuy",
		"Zulia",
	],

	Vietnam: [
		"An Giang",
		"Bac Giang",
		"Bac Kan",
		"Bac Lieu",
		"Bac Ninh",
		"Ba Ria-Vung Tau",
		"Ben Tre",
		"Binh Dinh",
		"Binh Duong",
		"Binh Phuoc",
		"Binh Thuan",
		"Ca Mau",
		"Cao Bang",
		"Dac Lak",
		"Dac Nong",
		"Dien Bien",
		"Dong Nai",
		"Dong Thap",
		"Gia Lai",
		"Ha Giang",
		"Hai Duong",
		"Ha Nam",
		"Ha Tay",
		"Ha Tinh",
		"Hau Giang",
		"Hoa Binh",
		"Hung Yen",
		"Khanh Hoa",
		"Kien Giang",
		"Kon Tum",
		"Lai Chau",
		"Lam Dong",
		"Lang Son",
		"Lao Cai",
		"Long An",
		"Nam Dinh",
		"Nghe An",
		"Ninh Binh",
		"Ninh Thuan",
		"Phu Tho",
		"Phu Yen",
		"Quang Binh",
		"Quang Nam",
		"Quang Ngai",
		"Quang Ninh",
		"Quang Tri",
		"Soc Trang",
		"Son La",
		"Tay Ninh",
		"Thai Binh",
		"Thai Nguyen",
		"Thanh Hoa",
		"Thua Thien-Hue",
		"Tien Giang",
		"Tra Vinh",
		"Tuyen Quang",
		"Vinh Long",
		"Vinh Phuc",
		"Yen Bai",
		"Can Tho",
		"Da Nang",
		"Hai Phong",
		"Hanoi",
		"Ho Chi Minh",
	],

	Yemen: [
		"Abyan",
		"'Adan",
		"Ad Dali'",
		"Al Bayda'",
		"Al Hudaydah",
		"Al Jawf",
		"Al Mahrah",
		"Al Mahwit",
		"'Amran",
		"Dhamar",
		"Hadramawt",
		"Hajjah",
		"Ibb",
		"Lahij",
		"Ma'rib",
		"Sa'dah",
		"San'a'",
		"Shabwah",
		"Ta'izz",
	],

	Zambia: [
		"Central",
		"Copperbelt",
		"Eastern",
		"Luapula",
		"Lusaka",
		"Northern",
		"North-Western",
		"Southern",
		"Western",
	],

	Zimbabwe: [
		"Bulawayo",
		"Harare",
		"Manicaland",
		"Mashonaland Central",
		"Mashonaland East",
		"Mashonaland West",
		"Masvingo",
		"Matabeleland North",
		"Matabeleland South",
		"Midlands",
	],
};

export const skillTree = {
	Mechanic: [
		"General Repairs",
		"Computer Diagnosis",
		"Routine  Servicing",
		"Engine Repair",
		"Brake Repair",
	],

	"Spare Part": [],

	"Body Shop": [
		"Fix body part",
		"Remove dents",
		"Install windshields",
		"Painting Straighten",
		"Metal panels",
	],

	"Car Air-Conditioning": [
		"Install",
		"Compressor",
		"Leakage",
		"Fix",
		"Condenser",
		"Fill Gas",
	],

	Electrical: ["Lighting system", "Coil", "Fuse", "Fuelpump", "Re-wire"],

	"Tire Shop": [
		"Flat tire",
		"Gauge tire",
		"Wheel Balancing",
		"Alignment Rotating",
		"Tire replacement",
	],

	Towing: [
		"Accident  Car",
		"Road Assistant",
		"Transportation",
		"Heavy Duty",
		"Small Duty",
	],

	Security: ["Car Alarm", "Tracker", "GPS", "Key Programmer", "Camera"],
};

export const states = {
	"": [""],
	"Select State": ["Select City"],
	Abia: [
		"Aba North",
		"Aba South",
		"Arochukwu",
		"Bende",
		"Ikwuano",
		"Isiala-Ngwa North",
		"Isiala-Ngwa South",
		"Isuikwato",
		"Obi Nwa",
		"Ohafia",
		"Osisioma",
		"Ngwa",
		"Ugwunagbo",
		"Ukwa East",
		"Ukwa West",
		"Umuahia North",
		"Umuahia South",
		"Umu-Neochi",
	],
	Adamawa: [
		"Demsa",
		"Fufore",
		"Ganaye",
		"Gireri",
		"Gombi",
		"Guyuk",
		"Hong",
		"Jada",
		"Lamurde",
		"Madagali",
		"Maiha",
		"Mayo-Belwa",
		"Michika",
		"Mubi North",
		"Mubi South",
		"Numan",
		"Shelleng",
		"Song",
		"Toungo",
		"Yola North",
		"Yola South",
	],
	Anambra: [
		"Aguata",
		"Anambra East",
		"Anambra West",
		"Anaocha",
		"Awka North",
		"Awka South",
		"Ayamelum",
		"Dunukofia",
		"Ekwusigo",
		"Idemili North",
		"Idemili south",
		"Ihiala",
		"Njikoka",
		"Nnewi North",
		"Nnewi South",
		"Ogbaru",
		"Onitsha North",
		"Onitsha South",
		"Orumba North",
		"Orumba South",
		"Oyi",
	],
	"Akwa Ibom": [
		"Abak",
		"Eastern Obolo",
		"Eket",
		"Esit Eket",
		"Essien Udim",
		"Etim Ekpo",
		"Etinan",
		"Ibeno",
		"Ibesikpo Asutan",
		"Ibiono Ibom",
		"Ika",
		"Ikono",
		"Ikot Abasi",
		"Ikot Ekpene",
		"Ini",
		"Itu",
		"Mbo",
		"Mkpat Enin",
		"Nsit Atai",
		"Nsit Ibom",
		"Nsit Ubium",
		"Obot Akara",
		"Okobo",
		"Onna",
		"Oron",
		"Oruk Anam",
		"Udung Uko",
		"Ukanafun",
		"Uruan",
		"Urue-Offong/Oruko ",
		"Uyo",
	],
	Bauchi: [
		"Alkaleri",
		"Bauchi",
		"Bogoro",
		"Damban",
		"Darazo",
		"Dass",
		"Ganjuwa",
		"Giade",
		"Itas/Gadau",
		"Jama'are",
		"Katagum",
		"Kirfi",
		"Misau",
		"Ningi",
		"Shira",
		"Tafawa-Balewa",
		"Toro",
		"Warji",
		"Zaki",
	],
	Bayelsa: [
		"Brass",
		"Ekeremor",
		"Kolokuma/Opokuma",
		"Nembe",
		"Ogbia",
		"Sagbama",
		"Southern Jaw",
		"Yenegoa",
	],
	Benue: [
		"Ado",
		"Agatu",
		"Apa",
		"Buruku",
		"Gboko",
		"Guma",
		"Gwer East",
		"Gwer West",
		"Katsina-Ala",
		"Konshisha",
		"Kwande",
		"Logo",
		"Makurdi",
		"Obi",
		"Ogbadibo",
		"Oju",
		"Okpokwu",
		"Ohimini",
		"Oturkpo",
		"Tarka",
		"Ukum",
		"Ushongo",
		"Vandeikya",
	],
	Borno: [
		"Abadam",
		"Askira/Uba",
		"Bama",
		"Bayo",
		"Biu",
		"Chibok",
		"Damboa",
		"Dikwa",
		"Gubio",
		"Guzamala",
		"Gwoza",
		"Hawul",
		"Jere",
		"Kaga",
		"Kala/Balge",
		"Konduga",
		"Kukawa",
		"Kwaya Kusar",
		"Mafa",
		"Magumeri",
		"Maiduguri",
		"Marte",
		"Mobbar",
		"Monguno",
		"Ngala",
		"Nganzai",
		"Shani",
	],
	"Cross River": [
		"Akpabuyo",
		"Odukpani",
		"Akamkpa",
		"Biase",
		"Abi",
		"Ikom",
		"Yarkur",
		"Odubra",
		"Boki",
		"Ogoja",
		"Yala",
		"Obanliku",
		"Obudu",
		"Calabar South",
		"Etung",
		"Bekwara",
		"Bakassi",
		"Calabar Municipality",
	],
	Delta: [
		"Oshimili",
		"Aniocha",
		"Aniocha South",
		"Ika South",
		"Ika North-East",
		"Ndokwa West",
		"Ndokwa East",
		"Isoko south",
		"Isoko North",
		"Bomadi",
		"Burutu",
		"Ughelli South",
		"Ughelli North",
		"Ethiope West",
		"Ethiope East",
		"Sapele",
		"Okpe",
		"Warri North",
		"Warri South",
		"Uvwie",
		"Udu",
		"Warri Central",
		"Ukwani",
		"Oshimili North",
		"Patani",
	],
	Ebonyi: [
		"Afikpo South",
		"Afikpo North",
		"Onicha",
		"Ohaozara",
		"Abakaliki",
		"Ishielu",
		"lkwo",
		"Ezza",
		"Ezza South",
		"Ohaukwu",
		"Ebonyi",
		"Ivo",
	],
	Enugu: [
		"Enugu South,",
		"Igbo-Eze South",
		"Enugu North",
		"Nkanu",
		"Udi Agwu",
		"Oji-River",
		"Ezeagu",
		"IgboEze North",
		"Isi-Uzo",
		"Nsukka",
		"Igbo-Ekiti",
		"Uzo-Uwani",
		"Enugu Eas",
		"Aninri",
		"Nkanu East",
		"Udenu.",
	],
	Edo: [
		"Esan North-East",
		"Esan Central",
		"Esan West",
		"Egor",
		"Ukpoba",
		"Central",
		"Etsako Central",
		"Igueben",
		"Oredo",
		"Ovia SouthWest",
		"Ovia South-East",
		"Orhionwon",
		"Uhunmwonde",
		"Etsako East",
		"Esan South-East",
	],
	Ekiti: [
		"Ado",
		"Ekiti-East",
		"Ekiti-West",
		"Emure/Ise/Orun",
		"Ekiti South-West",
		"Ikare",
		"Irepodun",
		"Ijero,",
		"Ido/Osi",
		"Oye",
		"Ikole",
		"Moba",
		"Gbonyin",
		"Efon",
		"Ise/Orun",
		"Ilejemeje.",
	],
	Abuja: ["Abaji", "Abuja Municipal", "Bwari", "Gwagwalada", "Kuje", "Kwali"],
	Gombe: [
		"Akko",
		"Balanga",
		"Billiri",
		"Dukku",
		"Kaltungo",
		"Kwami",
		"Shomgom",
		"Funakaye",
		"Gombe",
		"Nafada/Bajoga",
		"Yamaltu/Delta.",
	],
	Imo: [
		"Aboh-Mbaise",
		"Ahiazu-Mbaise",
		"Ehime-Mbano",
		"Ezinihitte",
		"Ideato North",
		"Ideato South",
		"Ihitte/Uboma",
		"Ikeduru",
		"Isiala Mbano",
		"Isu",
		"Mbaitoli",
		"Mbaitoli",
		"Ngor-Okpala",
		"Njaba",
		"Nwangele",
		"Nkwerre",
		"Obowo",
		"Oguta",
		"Ohaji/Egbema",
		"Okigwe",
		"Orlu",
		"Orsu",
		"Oru East",
		"Oru West",
		"Owerri-Municipal",
		"Owerri North",
		"Owerri West",
	],
	Jigawa: [
		"Auyo",
		"Babura",
		"Birni Kudu",
		"Biriniwa",
		"Buji",
		"Dutse",
		"Gagarawa",
		"Garki",
		"Gumel",
		"Guri",
		"Gwaram",
		"Gwiwa",
		"Hadejia",
		"Jahun",
		"Kafin Hausa",
		"Kaugama Kazaure",
		"Kiri Kasamma",
		"Kiyawa",
		"Maigatari",
		"Malam Madori",
		"Miga",
		"Ringim",
		"Roni",
		"Sule-Tankarkar",
		"Taura",
		"Yankwashi",
	],
	Kaduna: [
		"Birni-Gwari",
		"Chikun",
		"Giwa",
		"Igabi",
		"Ikara",
		"jaba",
		"Jema'a",
		"Kachia",
		"Kaduna North",
		"Kaduna South",
		"Kagarko",
		"Kajuru",
		"Kaura",
		"Kauru",
		"Kubau",
		"Kudan",
		"Lere",
		"Makarfi",
		"Sabon-Gari",
		"Sanga",
		"Soba",
		"Zango-Kataf",
		"Zaria",
	],
	Kano: [
		"Ajingi",
		"Albasu",
		"Bagwai",
		"Bebeji",
		"Bichi",
		"Bunkure",
		"Dala",
		"Dambatta",
		"Dawakin Kudu",
		"Dawakin Tofa",
		"Doguwa",
		"Fagge",
		"Gabasawa",
		"Garko",
		"Garum",
		"Mallam",
		"Gaya",
		"Gezawa",
		"Gwale",
		"Gwarzo",
		"Kabo",
		"Kano Municipal",
		"Karaye",
		"Kibiya",
		"Kiru",
		"kumbotso",
		"Kunchi",
		"Kura",
		"Madobi",
		"Makoda",
		"Minjibir",
		"Nasarawa",
		"Rano",
		"Rimin Gado",
		"Rogo",
		"Shanono",
		"Sumaila",
		"Takali",
		"Tarauni",
		"Tofa",
		"Tsanyawa",
		"Tudun Wada",
		"Ungogo",
		"Warawa",
		"Wudil",
	],
	Katsina: [
		"Bakori",
		"Batagarawa",
		"Batsari",
		"Baure",
		"Bindawa",
		"Charanchi",
		"Dandume",
		"Danja",
		"Dan Musa",
		"Daura",
		"Dutsi",
		"Dutsin-Ma",
		"Faskari",
		"Funtua",
		"Ingawa",
		"Jibia",
		"Kafur",
		"Kaita",
		"Kankara",
		"Kankia",
		"Katsina",
		"Kurfi",
		"Kusada",
		"Mai'Adua",
		"Malumfashi",
		"Mani",
		"Mashi",
		"Matazuu",
		"Musawa",
		"Rimi",
		"Sabuwa",
		"Safana",
		"Sandamu",
		"Zango",
	],
	Kebbi: [
		"Aleiro",
		"Arewa-Dandi",
		"Argungu",
		"Augie",
		"Bagudo",
		"Birnin Kebbi",
		"Bunza",
		"Dandi",
		"Fakai",
		"Gwandu",
		"Jega",
		"Kalgo",
		"Koko/Besse",
		"Maiyama",
		"Ngaski",
		"Sakaba",
		"Shanga",
		"Suru",
		"Wasagu/Danko",
		"Yauri",
		"Zuru",
	],
	Kogi: [
		"Adavi",
		"Ajaokuta",
		"Ankpa",
		"Bassa",
		"Dekina",
		"Ibaji",
		"Idah",
		"Igalamela-Odolu",
		"Ijumu",
		"Kabba/Bunu",
		"Kogi",
		"Lokoja",
		"Mopa-Muro",
		"Ofu",
		"Ogori/Mangongo",
		"Okehi",
		"Okene",
		"Olamabolo",
		"Omala",
		"Yagba East",
		"Yagba West",
	],
	Kwara: [
		"Asa",
		"Baruten",
		"Edu",
		"Ekiti",
		"Ifelodun",
		"Ilorin East",
		"Ilorin West",
		"Irepodun",
		"Isin",
		"Kaiama",
		"Moro",
		"Offa",
		"Oke-Ero",
		"Oyun",
		"Pategi",
	],
	Lagos: [
		"Agege",
		"Ajeromi-Ifelodun",
		"Alimosho",
		"Amuwo-Odofin",
		"Apapa",
		"Badagry",
		"Epe",
		"Eti-Osa",
		"Ibeju/Lekki",
		"Ifako-Ijaye",
		"Ikeja",
		"Ikorodu",
		"Kosofe",
		"Lagos Island",
		"Lagos Mainland",
		"Mushin",
		"Ojo",
		"Oshodi-Isolo",
		"Shomolu",
		"Surulere",
	],
	Nasarawa: [
		"Akwanga",
		"Awe",
		"Doma",
		"Karu",
		"Keana",
		"Keffi",
		"Kokona",
		"Lafia",
		"Nasarawa",
		"Nasarawa-Eggon",
		"Obi",
		"Toto",
		"Wamba",
	],
	Niger: [
		"Agaie",
		"Agwara",
		"Bida",
		"Borgu",
		"Bosso",
		"Chanchaga",
		"Edati",
		"Gbako",
		"Gurara",
		"Katcha",
		"Kontagora",
		"Lapai",
		"Lavun",
		"Magama",
		"Mariga",
		"Mashegu",
		"Mokwa",
		"Muya",
		"Pailoro",
		"Rafi",
		"Rijau",
		"Shiroro",
		"Suleja",
		"Tafa",
		"Wushishi",
	],
	Ogun: [
		"Abeokuta North",
		"Abeokuta South",
		"Ado-Odo/Ota",
		"Egbado North",
		"Egbado South",
		"Ewekoro",
		"Ifo",
		"Ijebu East",
		"Ijebu North",
		"Ijebu North East",
		"Ijebu Ode",
		"Ikenne",
		"Imeko-Afon",
		"Ipokia",
		"Obafemi-Owode",
		"Ogun Waterside",
		"Odeda",
		"Odogbolu",
		"Remo North",
		"Shagamu",
	],
	Ondo: [
		"Akoko North East",
		"Akoko North West",
		"Akoko South Akure East",
		"Akoko South West",
		"Akure North",
		"Akure South",
		"Ese-Odo",
		"Idanre",
		"Ifedore",
		"Ilaje",
		"Ile-Oluji",
		"Okeigbo",
		"Irele",
		"Odigbo",
		"Okitipupa",
		"Ondo East",
		"Ondo West",
		"Ose",
		"Owo",
	],
	Osun: [
		"Aiyedade",
		"Aiyedire",
		"Atakumosa East",
		"Atakumosa West",
		"Boluwaduro",
		"Boripe",
		"Ede North",
		"Ede South",
		"Egbedore",
		"Ejigbo",
		"Ife Central",
		"Ife East",
		"Ife North",
		"Ife South",
		"Ifedayo",
		"Ifelodun",
		"Ila",
		"Ilesha East",
		"Ilesha West",
		"Irepodun",
		"Irewole",
		"Isokan",
		"Iwo",
		"Obokun",
		"Odo-Otin",
		"Ola-Oluwa",
		"Olorunda",
		"Oriade",
		"Orolu",
		"Osogbo",
	],
	Oyo: [
		"Afijio",
		"Akinyele",
		"Atiba",
		"Atigbo",
		"Egbeda",
		"Ibadan Central",
		"Ibadan North",
		"Ibadan North West",
		"Ibadan South East",
		"Ibadan South West",
		"Ibarapa Central",
		"Ibarapa East",
		"Ibarapa North",
		"Ido",
		"Irepo",
		"Iseyin",
		"Itesiwaju",
		"Iwajowa",
		"Kajola",
		"Lagelu Ogbomosho North",
		"Ogbmosho South",
		"Ogo Oluwa",
		"Olorunsogo",
		"Oluyole",
		"Ona-Ara",
		"Orelope",
		"Ori Ire",
		"Oyo East",
		"Oyo West",
		"Saki East",
		"Saki West",
		"Surulere",
	],
	Plateau: [
		"Barikin Ladi",
		"Bassa",
		"Bokkos",
		"Jos East",
		"Jos North",
		"Jos South",
		"Kanam",
		"Kanke",
		"Langtang North",
		"Langtang South",
		"Mangu",
		"Mikang",
		"Pankshin",
		"Qua'an Pan",
		"Riyom",
		"Shendam",
		"Wase",
	],
	Rivers: [
		"Abua/Odual",
		"Ahoada East",
		"Ahoada West",
		"Akuku Toru",
		"Andoni",
		"Asari-Toru",
		"Bonny",
		"Degema",
		"Emohua",
		"Eleme",
		"Etche",
		"Gokana",
		"Ikwerre",
		"Khana",
		"Obia/Akpor",
		"Ogba/Egbema/Ndoni",
		"Ogu/Bolo",
		"Okrika",
		"Omumma",
		"Opobo/Nkoro",
		"Oyigbo",
		"Port-Harcourt",
		"Tai",
	],
	Sokoto: [
		"Binji",
		"Bodinga",
		"Dange-shnsi",
		"Gada",
		"Goronyo",
		"Gudu",
		"Gawabawa",
		"Illela",
		"Isa",
		"Kware",
		"kebbe",
		"Rabah",
		"Sabon birni",
		"Shagari",
		"Silame",
		"Sokoto North",
		"Sokoto South",
		"Tambuwal",
		"Tqngaza",
		"Tureta",
		"Wamako",
		"Wurno",
		"Yabo",
	],
	Taraba: [
		"Ardo-kola",
		"Bali",
		"Donga",
		"Gashaka",
		"Cassol",
		"Ibi",
		"Jalingo",
		"Karin-Lamido",
		"Kurmi",
		"Lau",
		"Sardauna",
		"Takum",
		"Ussa",
		"Wukari",
		"Yorro",
		"Zing",
	],
	Yobe: [
		"Bade",
		"Bursari",
		"Damaturu",
		"Fika",
		"Fune",
		"Geidam",
		"Gujba",
		"Gulani",
		"Jakusko",
		"Karasuwa",
		"Karawa",
		"Machina",
		"Nangere",
		"Nguru Potiskum",
		"Tarmua",
		"Yunusari",
		"Yusufari",
	],
	Zamfara: [
		"Anka",
		"Bakura",
		"Birnin Magaji",
		"Bukkuyum",
		"Bungudu",
		"Gummi",
		"Gusau",
		"Kaura",
		"Namoda",
		"Maradun",
		"Maru",
		"Shinkafi",
		"Talata Mafara",
		"Tsafe",
		"Zurmi",
	],
};

export const statez = {
	// "All Locations": ["All Locations"],
	Abia: [
		"Aba North",
		"Aba South",
		"Arochukwu",
		"Bende",
		"Ikwuano",
		"Isiala-Ngwa North",
		"Isiala-Ngwa South",
		"Isuikwato",
		"Obi Nwa",
		"Ohafia",
		"Osisioma",
		"Ngwa",
		"Ugwunagbo",
		"Ukwa East",
		"Ukwa West",
		"Umuahia North",
		"Umuahia South",
		"Umu-Neochi",
	],
	Adamawa: [
		"Demsa",
		"Fufore",
		"Ganaye",
		"Gireri",
		"Gombi",
		"Guyuk",
		"Hong",
		"Jada",
		"Lamurde",
		"Madagali",
		"Maiha",
		"Mayo-Belwa",
		"Michika",
		"Mubi North",
		"Mubi South",
		"Numan",
		"Shelleng",
		"Song",
		"Toungo",
		"Yola North",
		"Yola South",
	],
	Anambra: [
		"Aguata",
		"Anambra East",
		"Anambra West",
		"Anaocha",
		"Awka North",
		"Awka South",
		"Ayamelum",
		"Dunukofia",
		"Ekwusigo",
		"Idemili North",
		"Idemili south",
		"Ihiala",
		"Njikoka",
		"Nnewi North",
		"Nnewi South",
		"Ogbaru",
		"Onitsha North",
		"Onitsha South",
		"Orumba North",
		"Orumba South",
		"Oyi",
	],
	"Akwa Ibom": [
		"Abak",
		"Eastern Obolo",
		"Eket",
		"Esit Eket",
		"Essien Udim",
		"Etim Ekpo",
		"Etinan",
		"Ibeno",
		"Ibesikpo Asutan",
		"Ibiono Ibom",
		"Ika",
		"Ikono",
		"Ikot Abasi",
		"Ikot Ekpene",
		"Ini",
		"Itu",
		"Mbo",
		"Mkpat Enin",
		"Nsit Atai",
		"Nsit Ibom",
		"Nsit Ubium",
		"Obot Akara",
		"Okobo",
		"Onna",
		"Oron",
		"Oruk Anam",
		"Udung Uko",
		"Ukanafun",
		"Uruan",
		"Urue-Offong/Oruko ",
		"Uyo",
	],
	Bauchi: [
		"Alkaleri",
		"Bauchi",
		"Bogoro",
		"Damban",
		"Darazo",
		"Dass",
		"Ganjuwa",
		"Giade",
		"Itas/Gadau",
		"Jama'are",
		"Katagum",
		"Kirfi",
		"Misau",
		"Ningi",
		"Shira",
		"Tafawa-Balewa",
		"Toro",
		"Warji",
		"Zaki",
	],
	Bayelsa: [
		"Brass",
		"Ekeremor",
		"Kolokuma/Opokuma",
		"Nembe",
		"Ogbia",
		"Sagbama",
		"Southern Jaw",
		"Yenegoa",
	],
	Benue: [
		"Ado",
		"Agatu",
		"Apa",
		"Buruku",
		"Gboko",
		"Guma",
		"Gwer East",
		"Gwer West",
		"Katsina-Ala",
		"Konshisha",
		"Kwande",
		"Logo",
		"Makurdi",
		"Obi",
		"Ogbadibo",
		"Oju",
		"Okpokwu",
		"Ohimini",
		"Oturkpo",
		"Tarka",
		"Ukum",
		"Ushongo",
		"Vandeikya",
	],
	Borno: [
		"Abadam",
		"Askira/Uba",
		"Bama",
		"Bayo",
		"Biu",
		"Chibok",
		"Damboa",
		"Dikwa",
		"Gubio",
		"Guzamala",
		"Gwoza",
		"Hawul",
		"Jere",
		"Kaga",
		"Kala/Balge",
		"Konduga",
		"Kukawa",
		"Kwaya Kusar",
		"Mafa",
		"Magumeri",
		"Maiduguri",
		"Marte",
		"Mobbar",
		"Monguno",
		"Ngala",
		"Nganzai",
		"Shani",
	],
	"Cross River": [
		"Akpabuyo",
		"Odukpani",
		"Akamkpa",
		"Biase",
		"Abi",
		"Ikom",
		"Yarkur",
		"Odubra",
		"Boki",
		"Ogoja",
		"Yala",
		"Obanliku",
		"Obudu",
		"Calabar South",
		"Etung",
		"Bekwara",
		"Bakassi",
		"Calabar Municipality",
	],
	Delta: [
		"Oshimili",
		"Aniocha",
		"Aniocha South",
		"Ika South",
		"Ika North-East",
		"Ndokwa West",
		"Ndokwa East",
		"Isoko south",
		"Isoko North",
		"Bomadi",
		"Burutu",
		"Ughelli South",
		"Ughelli North",
		"Ethiope West",
		"Ethiope East",
		"Sapele",
		"Okpe",
		"Warri North",
		"Warri South",
		"Uvwie",
		"Udu",
		"Warri Central",
		"Ukwani",
		"Oshimili North",
		"Patani",
	],
	Ebonyi: [
		"Afikpo South",
		"Afikpo North",
		"Onicha",
		"Ohaozara",
		"Abakaliki",
		"Ishielu",
		"lkwo",
		"Ezza",
		"Ezza South",
		"Ohaukwu",
		"Ebonyi",
		"Ivo",
	],
	Enugu: [
		"Enugu South,",
		"Igbo-Eze South",
		"Enugu North",
		"Nkanu",
		"Udi Agwu",
		"Oji-River",
		"Ezeagu",
		"IgboEze North",
		"Isi-Uzo",
		"Nsukka",
		"Igbo-Ekiti",
		"Uzo-Uwani",
		"Enugu Eas",
		"Aninri",
		"Nkanu East",
		"Udenu.",
	],
	Edo: [
		"Esan North-East",
		"Esan Central",
		"Esan West",
		"Egor",
		"Ukpoba",
		"Central",
		"Etsako Central",
		"Igueben",
		"Oredo",
		"Ovia SouthWest",
		"Ovia South-East",
		"Orhionwon",
		"Uhunmwonde",
		"Etsako East",
		"Esan South-East",
	],
	Ekiti: [
		"Ado",
		"Ekiti-East",
		"Ekiti-West",
		"Emure/Ise/Orun",
		"Ekiti South-West",
		"Ikare",
		"Irepodun",
		"Ijero,",
		"Ido/Osi",
		"Oye",
		"Ikole",
		"Moba",
		"Gbonyin",
		"Efon",
		"Ise/Orun",
		"Ilejemeje.",
	],
	Abuja: ["Abaji", "Abuja Municipal", "Bwari", "Gwagwalada", "Kuje", "Kwali"],
	Gombe: [
		"Akko",
		"Balanga",
		"Billiri",
		"Dukku",
		"Kaltungo",
		"Kwami",
		"Shomgom",
		"Funakaye",
		"Gombe",
		"Nafada/Bajoga",
		"Yamaltu/Delta.",
	],
	Imo: [
		"Aboh-Mbaise",
		"Ahiazu-Mbaise",
		"Ehime-Mbano",
		"Ezinihitte",
		"Ideato North",
		"Ideato South",
		"Ihitte/Uboma",
		"Ikeduru",
		"Isiala Mbano",
		"Isu",
		"Mbaitoli",
		"Mbaitoli",
		"Ngor-Okpala",
		"Njaba",
		"Nwangele",
		"Nkwerre",
		"Obowo",
		"Oguta",
		"Ohaji/Egbema",
		"Okigwe",
		"Orlu",
		"Orsu",
		"Oru East",
		"Oru West",
		"Owerri-Municipal",
		"Owerri North",
		"Owerri West",
	],
	Jigawa: [
		"Auyo",
		"Babura",
		"Birni Kudu",
		"Biriniwa",
		"Buji",
		"Dutse",
		"Gagarawa",
		"Garki",
		"Gumel",
		"Guri",
		"Gwaram",
		"Gwiwa",
		"Hadejia",
		"Jahun",
		"Kafin Hausa",
		"Kaugama Kazaure",
		"Kiri Kasamma",
		"Kiyawa",
		"Maigatari",
		"Malam Madori",
		"Miga",
		"Ringim",
		"Roni",
		"Sule-Tankarkar",
		"Taura",
		"Yankwashi",
	],
	Kaduna: [
		"Birni-Gwari",
		"Chikun",
		"Giwa",
		"Igabi",
		"Ikara",
		"jaba",
		"Jema'a",
		"Kachia",
		"Kaduna North",
		"Kaduna South",
		"Kagarko",
		"Kajuru",
		"Kaura",
		"Kauru",
		"Kubau",
		"Kudan",
		"Lere",
		"Makarfi",
		"Sabon-Gari",
		"Sanga",
		"Soba",
		"Zango-Kataf",
		"Zaria",
	],
	Kano: [
		"Ajingi",
		"Albasu",
		"Bagwai",
		"Bebeji",
		"Bichi",
		"Bunkure",
		"Dala",
		"Dambatta",
		"Dawakin Kudu",
		"Dawakin Tofa",
		"Doguwa",
		"Fagge",
		"Gabasawa",
		"Garko",
		"Garum",
		"Mallam",
		"Gaya",
		"Gezawa",
		"Gwale",
		"Gwarzo",
		"Kabo",
		"Kano Municipal",
		"Karaye",
		"Kibiya",
		"Kiru",
		"kumbotso",
		"Kunchi",
		"Kura",
		"Madobi",
		"Makoda",
		"Minjibir",
		"Nasarawa",
		"Rano",
		"Rimin Gado",
		"Rogo",
		"Shanono",
		"Sumaila",
		"Takali",
		"Tarauni",
		"Tofa",
		"Tsanyawa",
		"Tudun Wada",
		"Ungogo",
		"Warawa",
		"Wudil",
	],
	Katsina: [
		"Bakori",
		"Batagarawa",
		"Batsari",
		"Baure",
		"Bindawa",
		"Charanchi",
		"Dandume",
		"Danja",
		"Dan Musa",
		"Daura",
		"Dutsi",
		"Dutsin-Ma",
		"Faskari",
		"Funtua",
		"Ingawa",
		"Jibia",
		"Kafur",
		"Kaita",
		"Kankara",
		"Kankia",
		"Katsina",
		"Kurfi",
		"Kusada",
		"Mai'Adua",
		"Malumfashi",
		"Mani",
		"Mashi",
		"Matazuu",
		"Musawa",
		"Rimi",
		"Sabuwa",
		"Safana",
		"Sandamu",
		"Zango",
	],
	Kebbi: [
		"Aleiro",
		"Arewa-Dandi",
		"Argungu",
		"Augie",
		"Bagudo",
		"Birnin Kebbi",
		"Bunza",
		"Dandi",
		"Fakai",
		"Gwandu",
		"Jega",
		"Kalgo",
		"Koko/Besse",
		"Maiyama",
		"Ngaski",
		"Sakaba",
		"Shanga",
		"Suru",
		"Wasagu/Danko",
		"Yauri",
		"Zuru",
	],
	Kogi: [
		"Adavi",
		"Ajaokuta",
		"Ankpa",
		"Bassa",
		"Dekina",
		"Ibaji",
		"Idah",
		"Igalamela-Odolu",
		"Ijumu",
		"Kabba/Bunu",
		"Kogi",
		"Lokoja",
		"Mopa-Muro",
		"Ofu",
		"Ogori/Mangongo",
		"Okehi",
		"Okene",
		"Olamabolo",
		"Omala",
		"Yagba East",
		"Yagba West",
	],
	Kwara: [
		"Asa",
		"Baruten",
		"Edu",
		"Ekiti",
		"Ifelodun",
		"Ilorin East",
		"Ilorin West",
		"Irepodun",
		"Isin",
		"Kaiama",
		"Moro",
		"Offa",
		"Oke-Ero",
		"Oyun",
		"Pategi",
	],
	Lagos: [
		"Agege",
		"Ajeromi-Ifelodun",
		"Alimosho",
		"Amuwo-Odofin",
		"Apapa",
		"Badagry",
		"Epe",
		"Eti-Osa",
		"Ibeju/Lekki",
		"Ifako-Ijaye",
		"Ikeja",
		"Ikorodu",
		"Kosofe",
		"Lagos Island",
		"Lagos Mainland",
		"Mushin",
		"Ojo",
		"Oshodi-Isolo",
		"Shomolu",
		"Surulere",
	],
	Nasarawa: [
		"Akwanga",
		"Awe",
		"Doma",
		"Karu",
		"Keana",
		"Keffi",
		"Kokona",
		"Lafia",
		"Nasarawa",
		"Nasarawa-Eggon",
		"Obi",
		"Toto",
		"Wamba",
	],
	Niger: [
		"Agaie",
		"Agwara",
		"Bida",
		"Borgu",
		"Bosso",
		"Chanchaga",
		"Edati",
		"Gbako",
		"Gurara",
		"Katcha",
		"Kontagora",
		"Lapai",
		"Lavun",
		"Magama",
		"Mariga",
		"Mashegu",
		"Mokwa",
		"Muya",
		"Pailoro",
		"Rafi",
		"Rijau",
		"Shiroro",
		"Suleja",
		"Tafa",
		"Wushishi",
	],
	Ogun: [
		"Abeokuta North",
		"Abeokuta South",
		"Ado-Odo/Ota",
		"Egbado North",
		"Egbado South",
		"Ewekoro",
		"Ifo",
		"Ijebu East",
		"Ijebu North",
		"Ijebu North East",
		"Ijebu Ode",
		"Ikenne",
		"Imeko-Afon",
		"Ipokia",
		"Obafemi-Owode",
		"Ogun Waterside",
		"Odeda",
		"Odogbolu",
		"Remo North",
		"Shagamu",
	],
	Ondo: [
		"Akoko North East",
		"Akoko North West",
		"Akoko South Akure East",
		"Akoko South West",
		"Akure North",
		"Akure South",
		"Ese-Odo",
		"Idanre",
		"Ifedore",
		"Ilaje",
		"Ile-Oluji",
		"Okeigbo",
		"Irele",
		"Odigbo",
		"Okitipupa",
		"Ondo East",
		"Ondo West",
		"Ose",
		"Owo",
	],
	Osun: [
		"Aiyedade",
		"Aiyedire",
		"Atakumosa East",
		"Atakumosa West",
		"Boluwaduro",
		"Boripe",
		"Ede North",
		"Ede South",
		"Egbedore",
		"Ejigbo",
		"Ife Central",
		"Ife East",
		"Ife North",
		"Ife South",
		"Ifedayo",
		"Ifelodun",
		"Ila",
		"Ilesha East",
		"Ilesha West",
		"Irepodun",
		"Irewole",
		"Isokan",
		"Iwo",
		"Obokun",
		"Odo-Otin",
		"Ola-Oluwa",
		"Olorunda",
		"Oriade",
		"Orolu",
		"Osogbo",
	],
	Oyo: [
		"Afijio",
		"Akinyele",
		"Atiba",
		"Atigbo",
		"Egbeda",
		"Ibadan Central",
		"Ibadan North",
		"Ibadan North West",
		"Ibadan South East",
		"Ibadan South West",
		"Ibarapa Central",
		"Ibarapa East",
		"Ibarapa North",
		"Ido",
		"Irepo",
		"Iseyin",
		"Itesiwaju",
		"Iwajowa",
		"Kajola",
		"Lagelu Ogbomosho North",
		"Ogbmosho South",
		"Ogo Oluwa",
		"Olorunsogo",
		"Oluyole",
		"Ona-Ara",
		"Orelope",
		"Ori Ire",
		"Oyo East",
		"Oyo West",
		"Saki East",
		"Saki West",
		"Surulere",
	],
	Plateau: [
		"Barikin Ladi",
		"Bassa",
		"Bokkos",
		"Jos East",
		"Jos North",
		"Jos South",
		"Kanam",
		"Kanke",
		"Langtang North",
		"Langtang South",
		"Mangu",
		"Mikang",
		"Pankshin",
		"Qua'an Pan",
		"Riyom",
		"Shendam",
		"Wase",
	],
	Rivers: [
		"Abua/Odual",
		"Ahoada East",
		"Ahoada West",
		"Akuku Toru",
		"Andoni",
		"Asari-Toru",
		"Bonny",
		"Degema",
		"Emohua",
		"Eleme",
		"Etche",
		"Gokana",
		"Ikwerre",
		"Khana",
		"Obia/Akpor",
		"Ogba/Egbema/Ndoni",
		"Ogu/Bolo",
		"Okrika",
		"Omumma",
		"Opobo/Nkoro",
		"Oyigbo",
		"Port-Harcourt",
		"Tai",
	],
	Sokoto: [
		"Binji",
		"Bodinga",
		"Dange-shnsi",
		"Gada",
		"Goronyo",
		"Gudu",
		"Gawabawa",
		"Illela",
		"Isa",
		"Kware",
		"kebbe",
		"Rabah",
		"Sabon birni",
		"Shagari",
		"Silame",
		"Sokoto North",
		"Sokoto South",
		"Tambuwal",
		"Tqngaza",
		"Tureta",
		"Wamako",
		"Wurno",
		"Yabo",
	],
	Taraba: [
		"Ardo-kola",
		"Bali",
		"Donga",
		"Gashaka",
		"Cassol",
		"Ibi",
		"Jalingo",
		"Karin-Lamido",
		"Kurmi",
		"Lau",
		"Sardauna",
		"Takum",
		"Ussa",
		"Wukari",
		"Yorro",
		"Zing",
	],
	Yobe: [
		"Bade",
		"Bursari",
		"Damaturu",
		"Fika",
		"Fune",
		"Geidam",
		"Gujba",
		"Gulani",
		"Jakusko",
		"Karasuwa",
		"Karawa",
		"Machina",
		"Nangere",
		"Nguru Potiskum",
		"Tarmua",
		"Yunusari",
		"Yusufari",
	],
	Zamfara: [
		"Anka",
		"Bakura",
		"Birnin Magaji",
		"Bukkuyum",
		"Bungudu",
		"Gummi",
		"Gusau",
		"Kaura",
		"Namoda",
		"Maradun",
		"Maru",
		"Shinkafi",
		"Talata Mafara",
		"Tsafe",
		"Zurmi",
	],
};

export const stateNames = [
	"Alabama",
	"Alaska",
	"Arizona",
	"Arkansas",
	"California",
	"Colorado",
	"Connecticut",
	"Delaware",
	"Florida",
	"Georgia",
	"Hawaii",
	"Idaho",
	"Illinois",
	"Indiana",
	"Iowa",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Maine",
	"Maryland",
	"Massachusetts",
	"Michigan",
	"Minnesota",
	"Mississippi",
	"Missouri",
	"Montana",
	"Nebraska",
	"Nevada",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"New York",
	"North Carolina",
	"North Dakota",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"Utah",
	"Vermont",
	"Virginia",
	"Washington",
	"West Virginia",
	"Wisconsin",
	"Wyoming",
];

export const partYear = [
	"Select Year",
	"1999",
	"2000",
	"2001",
	"2002",
	"2003",
	"2004",
	"2005",
	"2006",
	"2007",
	"2008",
	"2009",
	"2010",
	"2011",
	"2012",
	"2013",
	"2014",
	"2015",
	"2016",
	"2017",
	"2018",
	"2019",
	"2020",
	"2021",
];

export const makes = {
	"Select Make": [""],
	"": [""],

	Audi: [
		"2.0T",
		"A3",
		"A4",
		"A5",
		"A6",
		"A7",
		"A8",
		"ALLROAD6",
		"Q3",
		"Q5",
		"Q7",
		"Q8",
		"R8",
		"RS4",
		"RS5",
		"RS7",
		"S3",
		"S4",
		"S5",
		"S6",
		"S7",
		"S8",
		"SQ5",
		"TT",
	],
	Acura: [
		"CL",
		"CSX",
		"ILX",
		"INTEGRA",
		"LEGEND",
		"MDX",
		"RDX",
		"RL",
		"RLX",
		"RSX",
		"TL",
		"TSX",
		"ZDX",
	],
	"Alfa Romeo": ["GIULIA", "SPIDER", "STELVIO"],
	"Aston Martin": ["DB9", "VANTAGE"],
	BMW: [
		"1 SERIES",
		"2 SERIES",
		"3 SERIES",
		"4 SERIES",
		"5 SERIES",
		"6 SERIES",
		"7 SERIES",
		"ALPINA",
		"I3",
		"I8",
		"M SERIES",
		"X1",
		"X2",
		"X3",
		"X4",
		"X5",
		"X6",
		"X7",
		"Z SERIES",
	],
	Buick: [
		"ALLURE CX",
		"CASCADA",
		"CENTURY",
		"ELECTRA",
		"ENCLAVE",
		"ENCORE",
		"ENVISION",
		"LACROSSE CX",
		"LESABRE",
		"LUCERNE",
		"PARK AVENUE",
		"RAINIER",
		"REGAL",
		"RENDEZVOUS",
		"RIVIERA",
		"ROADMASTER",
		"SUPER",
		"TERRAZA",
		"VERANO",
	],
	Cadillac: [
		"60 SPECIAL",
		"62 SERIES",
		"ALLANTE",
		"ATS",
		"BROUGHAM",
		"CATERA",
		"CT6",
		"CTS",
		"DEVILLE",
		"DTS",
		"ELDORADO",
		"ELR",
		"ESCALADE",
		"FLEETWOOD",
		"KRYSTAL KOACH",
		"LASALLE",
		"LIMO",
		"SEVILLE",
		"SRX",
		"STS",
		"XLR",
		"XT4",
		"XT5",
		"XTS",
	],
	Cheverolet: [
		"APACHE",
		"ASTRO",
		"AVALANCHE",
		"AVEO",
		"BEL AIR",
		"BERETTA",
		"BLAZER",
		"BOLT",
		"C SERIES",
		"CAMARO",
		"CAPRICE",
		"CAPTIVA",
		"CAVALIER",
		"CELEBRITY",
		"CHEVELLE",
		"CLASSIC",
		"COBALT",
		"CORSICA",
		"CORVAIR",
		"CORVETTE",
		"CRUZE",
		"D SERIES",
		"DELUXE",
		"ECO",
		"EL CAMINO",
		"EQUINOX",
		"FLEETLINE",
		"G SERIES",
		"GMT",
		"HHR",
		"IMPALA",
		"K SERIES",
		"LUMINA",
		"MALIBU",
		"METRO",
		"MONTE CARLO",
		"NOVA",
		"OPTRA",
		"PICKUP",
		"PRIZM",
		"S 10",
		"SEDAN",
		"SONIC",
		"SPORT VAN",
		"SS",
		"SSR",
	],
	Chrysler: [
		"200",
		"300",
		"ASPEN",
		"CIRRUS",
		"CONCORDE",
		"CROSSFIRE",
		"GRAND VOYAGER",
		"LEBARON",
		"LHS",
		"NEW YORKER",
		"NEWPORT",
		"PACIFICA",
		"PT CRUISER",
		"SEBRING",
		"TOWN & COUNTRY",
		"VOYAGER",
	],
	Dodge: [
		"1500",
		"2500",
		"6000",
		"AVENGER",
		"CALIBER",
		"CARAVAN",
		"CHALLENGER",
		"CHARGER",
		"DAKOTA",
		"DART",
		"DURANGO",
		"DYNASTY",
		"GRAND CARAVAN",
		"INTREPID",
		"JOURNEY",
		"MAGNUM",
		"NEON",
		"NITRO",
		"PICKUP",
		"SPIRIT",
		"STEALTH",
		"STRATUS",
		"VIPER",
		"W SERIES",
	],
	Ferrari: [
		"360",
		"458",
		"488",
		"575",
		"599",
		"CALIFORNIA",
		"F355",
		"F430",
		"FF",
		"TESTAROSSA",
	],
	FIAT: ["124", "500", "SPIDER"],
	Ford: [
		"Ford Mustang",
		"Ford F-150",
		"Ford Explorer",
		"Ford Escape",
		"Ford Focus",
		"Ford Fusion",
		"Ford Edge",
		"Ford Expedition",
		"Ford Ranger",
		"Ford Bronco",
		"Ford Transit",
		"Ford EcoSport",
		"Ford Flex",
		"Ford Taurus",
		"Ford Fiesta",
	],
	Genesis: ["G80", "G90"],
	GMC: [
		"ACADIA",
		"C-SERIES",
		"DENALI",
		"ENVOY",
		"JIMMY",
		"K SERIES",
		"R SERIES",
		"SAFARI",
		"SONOMA",
		"SUBURBAN",
		"TERRAIN",
		"V SERIES",
		"YUKON",
	],
	Honda: [
		"ACCORD",
		"CIVIC",
		"CLARITY",
		"CROSSTOUR",
		"CR-V1",
		"CR-Z",
		"DEL SOL",
		"ELEMENT",
		"FIT",
		"HR-V",
		"INSIGHT",
		"ODYSSEY",
		"PASSPORT",
		"PILOT",
		"PRELUDE",
		"RIDGELINE",
		"S2000",
	],
	Hyundai: [
		"Accent",
		"Azera",
		"Civic Bus",
		"County",
		"Creta",
		"Elantra",
		"Entourage",
		"G30",
		"Galloper",
		"Genesis",
		"Getz",
		"Grandeur",
		"H1",
		"HD65",
		"I10",
		"I20",
		"Ix35",
		"Santa Fe",
		"Sonata",
		"Terracan",
		"Tiburon",
		"Trajet",
		"Tucson",
		"Veloster",
		"Veracruz",
		"Verna",
		"Xg 350",
	],
	Hummer: ["H1", "H2", "H3"],
	INFINITI: [
		"EX",
		"FX",
		"G20",
		"G25",
		"G35",
		"G37",
		"I30",
		"I35",
		"J30",
		"JX",
		"M CLASS",
		"Q40",
		"Q45",
		"Q50",
		"Q60",
		"Q70",
		"QX",
		"QX4",
		"QX56",
	],
	isuzu: ["AMIGO", "ASCENDER", "AXIOM", "I-SERIES", "RODEO", "TROOPER"],
	Jaguar: [
		"F-PACE",
		"F-TYPE",
		"S-TYPE",
		"VANDEN PLAS",
		"XE",
		"XF",
		"XJ",
		"XK",
		"X-TYPE",
	],
	Jeep: [
		"CHEROKEE",
		"CJ",
		"COMMANDER",
		"COMPASS",
		"GLADIATOR",
		"GRAND CHEROKEE",
		"LIBERTY",
		"PATRIOT",
		"RENEGADE",
		"TRUCK",
		"WAGONEER",
		"WILLY",
		"WRANGLER",
	],
	Kia: [
		"AMANTI",
		"BORREGO",
		"CADENZA",
		"FORTE",
		"K900",
		"NIRO",
		"OPTIMA",
		"RIO",
		"RONDO",
		"SEDONA",
		"SEPHIA",
		"SORENTO",
		"SOUL",
		"SPECTRA",
		"SPORTAGE",
		"STINGER",
		"TELLURIDE",
	],
	Lamborghini: ["AVENTADOR", "DIABLO", "GALLARDO", "HURACAN", "URRACO"],
	"Land Rover": [
		"DEFENDER",
		"DISCOVERY",
		"FREELANDER",
		"LR2",
		"LR3",
		"LR4",
		"RANGE ROVER",
		"RANGE ROVER SPORT",
	],
	Lincoln: [
		"AVIATOR",
		"CONTINENTAL",
		"LS",
		"MARK",
		"MKC",
		"MKS",
		"MKT",
		"MKX",
		"MKZ",
		"NAUTILUS",
		"NAVIGATOR",
		"PREMIERE",
		"TOWN CAR",
		"ZEPHYR",
	],
	Lotus: ["ESPRIT", "EVORA", "EXIGE"],

	Lexus: [
		"CT200H",
		"ES SERIES",
		"GS SERIES",
		"GX SERIES",
		"HS SERIES",
		"IS SERIES",
		"LC SERIES",
		"LS SERIES",
		"LX SERIES",
		"NX SERIES",
		"RC",
		"RX SERIES",
		"SC",
	],
	Maserati: ["COUPÉ", "GHIBLI", "GRANTURISMO", "LEVANTE", "QUATTROPORTE"],
	Mercury: [
		"CAPRI",
		"COUGAR",
		"GRAND MARQUIS",
		"MARAUDER",
		"MARINER",
		"MARQUIS",
		"MILAN",
		"MONTEGO",
		"MONTEREY",
		"MOUNTAINEER",
		"MYSTIQUE",
		"SABLE",
		"TOPAZ",
		"TRACER",
		"VILLAGER",
	],
	Mazda: [
		"SERIES",
		"CX-3",
		"CX-5",
		"CX-7",
		"CX-9",
		"MAZDA2",
		"MAZDA3",
		"MAZDA5",
		"MAZDA6",
		"MILLENIA",
		"MPV22",
		"MX-5 MIATA",
		"MX-6",
		"PROTEGE",
		"RX-7",
		"RX-8",
		"SPEED",
		"TRIBUTE",
		"TRUCK",
	],
	"Mercedes-Benz": [
		"180",
		"190",
		"220",
		"230",
		"240D",
		"250",
		"280",
		"300",
		"300T",
		"350",
		"450",
		"AMG GT",
		"B CLASS",
		"C CLASS",
		"CL CLASS",
		"CLA CLASS",
		"CLS",
		"E CLASS",
		"G CLASS",
		"GL CLASS",
		"GLA-CLASS",
		"GLC CLASS",
		"GLE CLASS",
		"GLK CLASS",
		"GLS CLASS",
		"M-CLASS",
		"METRIS",
		"R-CLASS",
		"S-CLASS",
		"SLC-CLASS",
		"SL-CLASS",
		"SLK",
		"SLR",
		"SLS AMG",
		"SPRINTER",
	],
	MINI: ["CLUBMAN BASE", "COOPER", "COUNTRYMAN"],
	Mitsubishi: [
		"3000",
		"DIAMANTE",
		"ECLIPSE",
		"ENDEAVOR",
		"EXPO",
		"GALANT",
		"I-MIEV",
		"LANCER",
		"MIGHTY MAX",
		"MIRAGE",
		"MONTERO",
		"OUTLANDER",
		"RAIDER",
	],
	Polestar: [
		"Volvo C30 ",
		"S60 ",
		"S/V60",
		"S/V60 DRIVE-E",
		"S60 T8 ",
		"V60 T8 ",
		"XC60 T8",
	],
	Pontiac: [
		"AZTEK",
		"BONNEVILLE",
		"FIERO",
		"FIREBIRD",
		"G3",
		"G5",
		"G6",
		"G8",
		"GRAND AM",
		"GRAND PRIX",
		"GTO",
		"MONTANA",
		"PURSUIT",
		"SOLSTICE",
		"SUNFIRE",
		"TORRENT",
		"VIBE",
		"WAVE",
	],

	Porsche: [
		"356",
		"718 BOXTER",
		"911",
		"918",
		"924",
		"928",
		"944",
		"968",
		"BOXSTER",
		"CAYENNE",
		"CAYMAN",
		"MACAN",
		"PANAMERA 4",
	],
	Ram: [
		"1500 DS Crew Cab",
		"Cargo Van",
		"1500 ProMaster Tradesman SWB std roof",
		"PROMASTER CITY® WAGON",
		"700",
		"Pickup",
		"PROMASTER CITY® TRADESMAN",
		"3500 SRW 10K GVWR CHASSIS CAB",
		"3500 CHASSIS CAB",
		"4500 CHASSIS CAB",
		"5500 CHASSIS CAB",
		"PROMASTER® CARGO VAN",
		"PROMASTER® WINDOW VAN",
		"PROMASTER® CUTAWAY",
	],
	Saab: ["900", "9000", "92", "93", "9-3", "9-4", "9-5", "97X", "9-7X"],
	Saturn: [
		"ASTRA",
		"AURA",
		"ION",
		"L SERIES",
		"LS",
		"OUTLOOK",
		"RELAY",
		"S SERIES",
		"SC",
		"SKY",
		"SL",
		"SW",
		"VUE",
	],
	Scion: ["FR-S", "IA", "IM", "IQ", "TC", "XA", "XB", "XD"],
	smart: ["FORTWO"],
	Subaru: [
		"ASCENT",
		"BAJA",
		"BRZ",
		"CROSSTREK",
		"FORESTER",
		"IMPREZA",
		"LEGACY",
		"OUTBACK",
		"TRIBECA",
		"WRX",
		"XV",
	],
	Suzuki: [
		"AERIO",
		"EQUATOR",
		"ESTEEM",
		"FORENZA",
		"GRAND VITARA",
		"KIZASHI",
		"RENO",
		"SAMURAI",
		"SIDEKICK",
		"SX4",
		"VITARA",
		"XL",
	],
	Tesla: ["MODEL 3", "MODEL S", "MODEL X"],
	Toyota: [
		"1/2 TON WORK TRUCK",
		"4RUNNER",
		"86",
		"AVALON",
		"CAMRY",
		"CAMRY SOLARA",
		"CELICA GT",
		"C-HR",
		"COROLLA",
		"CRESSIDA",
		"ECHO",
		"EXTRA LONG",
		"FJ CRUISER",
		"HIGHLANDER",
		"LAND CRUISER",
		"MATRIX",
		"MIRAI",
		"MR",
		"PASEO",
		"PICKUP",
		"PREVIA",
		"PRIUS",
		"RAV4",
		"SCION",
		"SEQUOIA",
		"SHORT BED",
		"SIENN",
		"SUPRA",
		"T100",
		"TACOMA",
		"TERCEL",
		"TUNDRA",
		"VAN CARGO",
		"VENZA",
		"XTRACAB",
		"YARIS",
	],
	Volkswagen: [
		"ATLAS",
		"BEETLE",
		"BUS",
		"CABRIO",
		"CC",
		"EOS",
		"EUROVAN",
		"GLI",
		"GOLF",
		"JETTA",
		"KARMANN GHIA",
		"PASSAT",
		"PHAETON",
		"R32",
		"RABBIT",
		"ROUTAN",
		"TIGUAN",
		"TOUAREG",
		"VANAGON",
	],
	Volvo: [
		"240",
		"740",
		"850",
		"940",
		"C30",
		"C70",
		"S40",
		"S60",
		"S70",
		"S80",
		"S90",
		"V40",
		"V50",
		"V60",
		"V70",
		"V90",
		"XC40",
		"XC60",
		"XC7022",
		"XC90",
	],
};

export const makes2 = {
	Acura: [],
	Audi: [],
	Aston: [],
	Martin: [],
	Abarth: [],
	IVM: [],
	Daihatsu: [],
	Porsche: [],
	Bentely: [],
	Alfa: [],
	Romeo: [],
	Datsun: [],
	"Mercedes-Benz": [],
	Jaguar: [],
	Ferrari: [],
	Honda: [],
	Opel: [],
	Lotus: [],
	Fiat: [],
	Infiniti: [],
	Ford: [],
	McLaren: [],
	Lamborghini: [],
	Isuzu: [],
	Volkswagen: [],
	Mini: [],
	Lancia: [],
	Lexus: [],
	BMW: [],
	"Rolls-Royce": [],
	Maserati: [],
	Mazda: [],
	Vauxhall: [],
	Pegan: [],
	Mitsubishi: [],
	Ultima: [],
	Morris: [],
	Nissan: [],
	Noble: [],
	Nord: [],
	Suzuki: [],
	Mercury: [],
	Renault: [],
	Toyota: [],
	Lincoln: [],
	Pontiac: [],
	Kia: [],
	Chevrolet: [],
	MG: [],
	Hyundai: [],
	Buick: [],
	Jaguar: [],
	Joylong: [],
	Cadillac: [],
	Jeep: [],
	Geely: [],
	Chrysler: [],
	Jac: [],
	Changan: [],
	Iveco: [],
	Daewoo: [],
	Hummer: [],
	Saab: [],
	Great: [],
	wall: [],
	Samsung: [],
	GMC: [],
	Saturn: [],
	Foton: [],
	Scion: [],
	Fiat: [],
	Seat: [],
	Dongfeng: [],
	Skoda: [],
	Dodge: [],
	Ssang: [],
	Yong: [],
	Citroen: [],
	Subaru: [],
	//? NEW.........................................................................................................

	//? NEW....................................................................................................
	Audi: [
		"2.0T",
		"A3",
		"A4",
		"A5",
		"A6",
		"A7",
		"A8",
		"ALLROAD6",
		"Q3",
		"Q5",
		"Q7",
		"Q8",
		"R8",
		"RS4",
		"RS5",
		"RS7",
		"S3",
		"S4",
		"S5",
		"S6",
		"S7",
		"S8",
		"SQ5",
		"TT",
	],
	Acura: [
		"CL",
		"CSX",
		"ILX",
		"INTEGRA",
		"LEGEND",
		"MDX",
		"RDX",
		"RL",
		"RLX",
		"RSX",
		"TL",
		"TSX",
		"ZDX",
	],
	"Alfa Romeo": ["GIULIA", "SPIDER", "STELVIO"],
	"Aston Martin": ["DB9", "VANTAGE"],
	BMW: [
		"1 SERIES",
		"2 SERIES",
		"3 SERIES",
		"4 SERIES",
		"5 SERIES",
		"6 SERIES",
		"7 SERIES",
		"ALPINA",
		"I3",
		"I8",
		"M SERIES",
		"X1",
		"X2",
		"X3",
		"X4",
		"X5",
		"X6",
		"X7",
		"Z SERIES",
	],
	Buick: [
		"ALLURE CX",
		"CASCADA",
		"CENTURY",
		"ELECTRA",
		"ENCLAVE",
		"ENCORE",
		"ENVISION",
		"LACROSSE CX",
		"LESABRE",
		"LUCERNE",
		"PARK AVENUE",
		"RAINIER",
		"REGAL",
		"RENDEZVOUS",
		"RIVIERA",
		"ROADMASTER",
		"SUPER",
		"TERRAZA",
		"VERANO",
	],
	Cadillac: [
		"60 SPECIAL",
		"62 SERIES",
		"ALLANTE",
		"ATS",
		"BROUGHAM",
		"CATERA",
		"CT6",
		"CTS",
		"DEVILLE",
		"DTS",
		"ELDORADO",
		"ELR",
		"ESCALADE",
		"FLEETWOOD",
		"KRYSTAL KOACH",
		"LASALLE",
		"LIMO",
		"SEVILLE",
		"SRX",
		"STS",
		"XLR",
		"XT4",
		"XT5",
		"XTS",
	],
	Cheverolet: [
		"APACHE",
		"ASTRO",
		"AVALANCHE",
		"AVEO",
		"BEL AIR",
		"BERETTA",
		"BLAZER",
		"BOLT",
		"C SERIES",
		"CAMARO",
		"CAPRICE",
		"CAPTIVA",
		"CAVALIER",
		"CELEBRITY",
		"CHEVELLE",
		"CLASSIC",
		"COBALT",
		"CORSICA",
		"CORVAIR",
		"CORVETTE",
		"CRUZE",
		"D SERIES",
		"DELUXE",
		"ECO",
		"EL CAMINO",
		"EQUINOX",
		"FLEETLINE",
		"G SERIES",
		"GMT",
		"HHR",
		"IMPALA",
		"K SERIES",
		"LUMINA",
		"MALIBU",
		"METRO",
		"MONTE CARLO",
		"NOVA",
		"OPTRA",
		"PICKUP",
		"PRIZM",
		"S 10",
		"SEDAN",
		"SONIC",
		"SPORT VAN",
		"SS",
		"SSR",
	],
	Chrysler: [
		"200",
		"300",
		"ASPEN",
		"CIRRUS",
		"CONCORDE",
		"CROSSFIRE",
		"GRAND VOYAGER",
		"LEBARON",
		"LHS",
		"NEW YORKER",
		"NEWPORT",
		"PACIFICA",
		"PT CRUISER",
		"SEBRING",
		"TOWN & COUNTRY",
		"VOYAGER",
	],
	Dodge: [
		"1500",
		"2500",
		"6000",
		"AVENGER",
		"CALIBER",
		"CARAVAN",
		"CHALLENGER",
		"CHARGER",
		"DAKOTA",
		"DART",
		"DURANGO",
		"DYNASTY",
		"GRAND CARAVAN",
		"INTREPID",
		"JOURNEY",
		"MAGNUM",
		"NEON",
		"NITRO",
		"PICKUP",
		"SPIRIT",
		"STEALTH",
		"STRATUS",
		"VIPER",
		"W SERIES",
	],
	Ferrari: [
		"360",
		"458",
		"488",
		"575",
		"599",
		"CALIFORNIA",
		"F355",
		"F430",
		"FF",
		"TESTAROSSA",
	],
	FIAT: ["124", "500", "SPIDER"],
	Ford: [],
	Genesis: ["G80", "G90"],
	GMC: [
		"ACADIA",
		"C-SERIES",
		"DENALI",
		"ENVOY",
		"JIMMY",
		"K SERIES",
		"R SERIES",
		"SAFARI",
		"SONOMA",
		"SUBURBAN",
		"TERRAIN",
		"V SERIES",
		"YUKON",
	],
	Honda: [
		"ACCORD",
		"CIVIC",
		"CLARITY",
		"CROSSTOUR",
		"CR-V1",
		"CR-Z",
		"DEL SOL",
		"ELEMENT",
		"FIT",
		"HR-V",
		"INSIGHT",
		"ODYSSEY",
		"PASSPORT",
		"PILOT",
		"PRELUDE",
		"RIDGELINE",
		"S2000",
	],
	Hyundai: [
		"Accent",
		"Azera",
		"Civic Bus",
		"County",
		"Creta",
		"Elantra",
		"Entourage",
		"G30",
		"Galloper",
		"Genesis",
		"Getz",
		"Grandeur",
		"H1",
		"HD65",
		"I10",
		"I20",
		"Ix35",
		"Santa Fe",
		"Sonata",
		"Terracan",
		"Tiburon",
		"Trajet",
		"Tucson",
		"Veloster",
		"Veracruz",
		"Verna",
		"Xg 350",
	],
	Hummer: ["H1", "H2", "H3"],
	INFINITI: [
		"EX",
		"FX",
		"G20",
		"G25",
		"G35",
		"G37",
		"I30",
		"I35",
		"J30",
		"JX",
		"M CLASS",
		"Q40",
		"Q45",
		"Q50",
		"Q60",
		"Q70",
		"QX",
		"QX4",
		"QX56",
	],
	isuzu: ["AMIGO", "ASCENDER", "AXIOM", "I-SERIES", "RODEO", "TROOPER"],
	Jaguar: [
		"F-PACE",
		"F-TYPE",
		"S-TYPE",
		"VANDEN PLAS",
		"XE",
		"XF",
		"XJ",
		"XK",
		"X-TYPE",
	],
	Jeep: [
		"CHEROKEE",
		"CJ",
		"COMMANDER",
		"COMPASS",
		"GLADIATOR",
		"GRAND CHEROKEE",
		"LIBERTY",
		"PATRIOT",
		"RENEGADE",
		"TRUCK",
		"WAGONEER",
		"WILLY",
		"WRANGLER",
	],
	Kia: [
		"AMANTI",
		"BORREGO",
		"CADENZA",
		"FORTE",
		"K900",
		"NIRO",
		"OPTIMA",
		"RIO",
		"RONDO",
		"SEDONA",
		"SEPHIA",
		"SORENTO",
		"SOUL",
		"SPECTRA",
		"SPORTAGE",
		"STINGER",
		"TELLURIDE",
	],
	Lamborghini: ["AVENTADOR", "DIABLO", "GALLARDO", "HURACAN", "URRACO"],
	"Land Rover": [
		"DEFENDER",
		"DISCOVERY",
		"FREELANDER",
		"LR2",
		"LR3",
		"LR4",
		"RANGE ROVER",
		"RANGE ROVER SPORT",
	],
	Lincoln: [
		"AVIATOR",
		"CONTINENTAL",
		"LS",
		"MARK",
		"MKC",
		"MKS",
		"MKT",
		"MKX",
		"MKZ",
		"NAUTILUS",
		"NAVIGATOR",
		"PREMIERE",
		"TOWN CAR",
		"ZEPHYR",
	],
	Lotus: ["ESPRIT", "EVORA", "EXIGE"],

	Lexus: [
		"CT200H",
		"ES SERIES",
		"GS SERIES",
		"GX SERIES",
		"HS SERIES",
		"IS SERIES",
		"LC SERIES",
		"LS SERIES",
		"LX SERIES",
		"NX SERIES",
		"RC",
		"RX SERIES",
		"SC",
	],
	Maserati: ["COUPÉ", "GHIBLI", "GRANTURISMO", "LEVANTE", "QUATTROPORTE"],
	Mercury: [
		"CAPRI",
		"COUGAR",
		"GRAND MARQUIS",
		"MARAUDER",
		"MARINER",
		"MARQUIS",
		"MILAN",
		"MONTEGO",
		"MONTEREY",
		"MOUNTAINEER",
		"MYSTIQUE",
		"SABLE",
		"TOPAZ",
		"TRACER",
		"VILLAGER",
	],
	Mazda: [
		"SERIES",
		"CX-3",
		"CX-5",
		"CX-7",
		"CX-9",
		"MAZDA2",
		"MAZDA3",
		"MAZDA5",
		"MAZDA6",
		"MILLENIA",
		"MPV22",
		"MX-5 MIATA",
		"MX-6",
		"PROTEGE",
		"RX-7",
		"RX-8",
		"SPEED",
		"TRIBUTE",
		"TRUCK",
	],
	"Mercedes-Benz": [
		"180",
		"190",
		"220",
		"230",
		"240D",
		"250",
		"280",
		"300",
		"300T",
		"350",
		"450",
		"AMG GT",
		"B CLASS",
		"C CLASS",
		"CL CLASS",
		"CLA CLASS",
		"CLS",
		"E CLASS",
		"G CLASS",
		"GL CLASS",
		"GLA-CLASS",
		"GLC CLASS",
		"GLE CLASS",
		"GLK CLASS",
		"GLS CLASS",
		"M-CLASS",
		"METRIS",
		"R-CLASS",
		"S-CLASS",
		"SLC-CLASS",
		"SL-CLASS",
		"SLK",
		"SLR",
		"SLS AMG",
		"SPRINTER",
	],
	MINI: ["CLUBMAN BASE", "COOPER", "COUNTRYMAN"],
	Mitsubishi: [
		"3000",
		"DIAMANTE",
		"ECLIPSE",
		"ENDEAVOR",
		"EXPO",
		"GALANT",
		"I-MIEV",
		"LANCER",
		"MIGHTY MAX",
		"MIRAGE",
		"MONTERO",
		"OUTLANDER",
		"RAIDER",
	],
	Polestar: [
		"Volvo C30 ",
		"S60 ",
		"S/V60",
		"S/V60 DRIVE-E",
		"S60 T8 ",
		"V60 T8 ",
		"XC60 T8",
	],
	Pontiac: [
		"AZTEK",
		"BONNEVILLE",
		"FIERO",
		"FIREBIRD",
		"G3",
		"G5",
		"G6",
		"G8",
		"GRAND AM",
		"GRAND PRIX",
		"GTO",
		"MONTANA",
		"PURSUIT",
		"SOLSTICE",
		"SUNFIRE",
		"TORRENT",
		"VIBE",
		"WAVE",
	],

	Porsche: [
		"356",
		"718 BOXTER",
		"911",
		"918",
		"924",
		"928",
		"944",
		"968",
		"BOXSTER",
		"CAYENNE",
		"CAYMAN",
		"MACAN",
		"PANAMERA 4",
	],
	Ram: [
		"1500 DS Crew Cab",
		"Cargo Van",
		"1500 ProMaster Tradesman SWB std roof",
		"PROMASTER CITY® WAGON",
		"700",
		"Pickup",
		"PROMASTER CITY® TRADESMAN",
		"3500 SRW 10K GVWR CHASSIS CAB",
		"3500 CHASSIS CAB",
		"4500 CHASSIS CAB",
		"5500 CHASSIS CAB",
		"PROMASTER® CARGO VAN",
		"PROMASTER® WINDOW VAN",
		"PROMASTER® CUTAWAY",
	],
	Saab: ["900", "9000", "92", "93", "9-3", "9-4", "9-5", "97X", "9-7X"],
	Saturn: [
		"ASTRA",
		"AURA",
		"ION",
		"L SERIES",
		"LS",
		"OUTLOOK",
		"RELAY",
		"S SERIES",
		"SC",
		"SKY",
		"SL",
		"SW",
		"VUE",
	],
	Scion: ["FR-S", "IA", "IM", "IQ", "TC", "XA", "XB", "XD"],
	smart: ["FORTWO"],
	Subaru: [
		"ASCENT",
		"BAJA",
		"BRZ",
		"CROSSTREK",
		"FORESTER",
		"IMPREZA",
		"LEGACY",
		"OUTBACK",
		"TRIBECA",
		"WRX",
		"XV",
	],
	Suzuki: [
		"AERIO",
		"EQUATOR",
		"ESTEEM",
		"FORENZA",
		"GRAND VITARA",
		"KIZASHI",
		"RENO",
		"SAMURAI",
		"SIDEKICK",
		"SX4",
		"VITARA",
		"XL",
	],
	Tesla: ["MODEL 3", "MODEL S", "MODEL X"],
	Toyota: [
		"1/2 TON WORK TRUCK",
		"4RUNNER",
		"86",
		"AVALON",
		"CAMRY",
		"CAMRY SOLARA",
		"CELICA GT",
		"C-HR",
		"COROLLA",
		"CRESSIDA",
		"ECHO",
		"EXTRA LONG",
		"FJ CRUISER",
		"HIGHLANDER",
		"LAND CRUISER",
		"MATRIX",
		"MIRAI",
		"MR",
		"PASEO",
		"PICKUP",
		"PREVIA",
		"PRIUS",
		"RAV4",
		"SCION",
		"SEQUOIA",
		"SHORT BED",
		"SIENN",
		"SUPRA",
		"T100",
		"TACOMA",
		"TERCEL",
		"TUNDRA",
		"VAN CARGO",
		"VENZA",
		"XTRACAB",
		"YARIS",
	],
	Volkswagen: [
		"ATLAS",
		"BEETLE",
		"BUS",
		"CABRIO",
		"CC",
		"EOS",
		"EUROVAN",
		"GLI",
		"GOLF",
		"JETTA",
		"KARMANN GHIA",
		"PASSAT",
		"PHAETON",
		"R32",
		"RABBIT",
		"ROUTAN",
		"TIGUAN",
		"TOUAREG",
		"VANAGON",
	],
	Volvo: [
		"240",
		"740",
		"850",
		"940",
		"C30",
		"C70",
		"S40",
		"S60",
		"S70",
		"S80",
		"S90",
		"V40",
		"V50",
		"V60",
		"V70",
		"V90",
		"XC40",
		"XC60",
		"XC7022",
		"XC90",
	],
};

export const makes3 = {
	"Select Make": ["Select Modal"],

	Audi: [
		"2.0T",
		"A3",
		"A4",
		"A5",
		"A6",
		"A7",
		"A8",
		"ALLROAD6",
		"Q3",
		"Q5",
		"Q7",
		"Q8",
		"R8",
		"RS4",
		"RS5",
		"RS7",
		"S3",
		"S4",
		"S5",
		"S6",
		"S7",
		"S8",
		"SQ5",
		"TT",
	],
	Acura: [
		"CL",
		"CSX",
		"ILX",
		"INTEGRA",
		"LEGEND",
		"MDX",
		"RDX",
		"RL",
		"RLX",
		"RSX",
		"TL",
		"TSX",
		"ZDX",
	],
	"Alfa Romeo": ["GIULIA", "SPIDER", "STELVIO"],
	"Aston Martin": ["DB9", "VANTAGE"],
	BMW: [
		"1 SERIES",
		"2 SERIES",
		"3 SERIES",
		"4 SERIES",
		"5 SERIES",
		"6 SERIES",
		"7 SERIES",
		"ALPINA",
		"I3",
		"I8",
		"M SERIES",
		"X1",
		"X2",
		"X3",
		"X4",
		"X5",
		"X6",
		"X7",
		"Z SERIES",
	],
	Buick: [
		"ALLURE CX",
		"CASCADA",
		"CENTURY",
		"ELECTRA",
		"ENCLAVE",
		"ENCORE",
		"ENVISION",
		"LACROSSE CX",
		"LESABRE",
		"LUCERNE",
		"PARK AVENUE",
		"RAINIER",
		"REGAL",
		"RENDEZVOUS",
		"RIVIERA",
		"ROADMASTER",
		"SUPER",
		"TERRAZA",
		"VERANO",
	],
	Cadillac: [
		"60 SPECIAL",
		"62 SERIES",
		"ALLANTE",
		"ATS",
		"BROUGHAM",
		"CATERA",
		"CT6",
		"CTS",
		"DEVILLE",
		"DTS",
		"ELDORADO",
		"ELR",
		"ESCALADE",
		"FLEETWOOD",
		"KRYSTAL KOACH",
		"LASALLE",
		"LIMO",
		"SEVILLE",
		"SRX",
		"STS",
		"XLR",
		"XT4",
		"XT5",
		"XTS",
	],
	Cheverolet: [
		"APACHE",
		"ASTRO",
		"AVALANCHE",
		"AVEO",
		"BEL AIR",
		"BERETTA",
		"BLAZER",
		"BOLT",
		"C SERIES",
		"CAMARO",
		"CAPRICE",
		"CAPTIVA",
		"CAVALIER",
		"CELEBRITY",
		"CHEVELLE",
		"CLASSIC",
		"COBALT",
		"CORSICA",
		"CORVAIR",
		"CORVETTE",
		"CRUZE",
		"D SERIES",
		"DELUXE",
		"ECO",
		"EL CAMINO",
		"EQUINOX",
		"FLEETLINE",
		"G SERIES",
		"GMT",
		"HHR",
		"IMPALA",
		"K SERIES",
		"LUMINA",
		"MALIBU",
		"METRO",
		"MONTE CARLO",
		"NOVA",
		"OPTRA",
		"PICKUP",
		"PRIZM",
		"S 10",
		"SEDAN",
		"SONIC",
		"SPORT VAN",
		"SS",
		"SSR",
	],
	Chrysler: [
		"200",
		"300",
		"ASPEN",
		"CIRRUS",
		"CONCORDE",
		"CROSSFIRE",
		"GRAND VOYAGER",
		"LEBARON",
		"LHS",
		"NEW YORKER",
		"NEWPORT",
		"PACIFICA",
		"PT CRUISER",
		"SEBRING",
		"TOWN & COUNTRY",
		"VOYAGER",
	],
	Dodge: [
		"1500",
		"2500",
		"6000",
		"AVENGER",
		"CALIBER",
		"CARAVAN",
		"CHALLENGER",
		"CHARGER",
		"DAKOTA",
		"DART",
		"DURANGO",
		"DYNASTY",
		"GRAND CARAVAN",
		"INTREPID",
		"JOURNEY",
		"MAGNUM",
		"NEON",
		"NITRO",
		"PICKUP",
		"SPIRIT",
		"STEALTH",
		"STRATUS",
		"VIPER",
		"W SERIES",
	],
	Ferrari: [
		"360",
		"458",
		"488",
		"575",
		"599",
		"CALIFORNIA",
		"F355",
		"F430",
		"FF",
		"TESTAROSSA",
	],
	FIAT: ["124", "500", "SPIDER"],
	Ford: [
		"Ford Mustang",
		"Ford F-150",
		"Ford Explorer",
		"Ford Escape",
		"Ford Focus",
		"Ford Fusion",
		"Ford Edge",
		"Ford Expedition",
		"Ford Ranger",
		"Ford Bronco",
		"Ford Transit",
		"Ford EcoSport",
		"Ford Flex",
		"Ford Taurus",
		"Ford Fiesta",
	],
	Genesis: ["G80", "G90"],
	GMC: [
		"ACADIA",
		"C-SERIES",
		"DENALI",
		"ENVOY",
		"JIMMY",
		"K SERIES",
		"R SERIES",
		"SAFARI",
		"SONOMA",
		"SUBURBAN",
		"TERRAIN",
		"V SERIES",
		"YUKON",
	],
	Honda: [
		"ACCORD",
		"CIVIC",
		"CLARITY",
		"CROSSTOUR",
		"CR-V1",
		"CR-Z",
		"DEL SOL",
		"ELEMENT",
		"FIT",
		"HR-V",
		"INSIGHT",
		"ODYSSEY",
		"PASSPORT",
		"PILOT",
		"PRELUDE",
		"RIDGELINE",
		"S2000",
	],
	Hyundai: [
		"Accent",
		"Azera",
		"Civic Bus",
		"County",
		"Creta",
		"Elantra",
		"Entourage",
		"G30",
		"Galloper",
		"Genesis",
		"Getz",
		"Grandeur",
		"H1",
		"HD65",
		"I10",
		"I20",
		"Ix35",
		"Santa Fe",
		"Sonata",
		"Terracan",
		"Tiburon",
		"Trajet",
		"Tucson",
		"Veloster",
		"Veracruz",
		"Verna",
		"Xg 350",
	],
	Hummer: ["H1", "H2", "H3"],
	INFINITI: [
		"EX",
		"FX",
		"G20",
		"G25",
		"G35",
		"G37",
		"I30",
		"I35",
		"J30",
		"JX",
		"M CLASS",
		"Q40",
		"Q45",
		"Q50",
		"Q60",
		"Q70",
		"QX",
		"QX4",
		"QX56",
	],
	isuzu: ["AMIGO", "ASCENDER", "AXIOM", "I-SERIES", "RODEO", "TROOPER"],
	Jaguar: [
		"F-PACE",
		"F-TYPE",
		"S-TYPE",
		"VANDEN PLAS",
		"XE",
		"XF",
		"XJ",
		"XK",
		"X-TYPE",
	],
	Jeep: [
		"CHEROKEE",
		"CJ",
		"COMMANDER",
		"COMPASS",
		"GLADIATOR",
		"GRAND CHEROKEE",
		"LIBERTY",
		"PATRIOT",
		"RENEGADE",
		"TRUCK",
		"WAGONEER",
		"WILLY",
		"WRANGLER",
	],
	Kia: [
		"AMANTI",
		"BORREGO",
		"CADENZA",
		"FORTE",
		"K900",
		"NIRO",
		"OPTIMA",
		"RIO",
		"RONDO",
		"SEDONA",
		"SEPHIA",
		"SORENTO",
		"SOUL",
		"SPECTRA",
		"SPORTAGE",
		"STINGER",
		"TELLURIDE",
	],
	Lamborghini: ["AVENTADOR", "DIABLO", "GALLARDO", "HURACAN", "URRACO"],
	"Land Rover": [
		"DEFENDER",
		"DISCOVERY",
		"FREELANDER",
		"LR2",
		"LR3",
		"LR4",
		"RANGE ROVER",
		"RANGE ROVER SPORT",
	],
	Lincoln: [
		"AVIATOR",
		"CONTINENTAL",
		"LS",
		"MARK",
		"MKC",
		"MKS",
		"MKT",
		"MKX",
		"MKZ",
		"NAUTILUS",
		"NAVIGATOR",
		"PREMIERE",
		"TOWN CAR",
		"ZEPHYR",
	],
	Lotus: ["ESPRIT", "EVORA", "EXIGE"],

	Lexus: [
		"CT200H",
		"ES SERIES",
		"GS SERIES",
		"GX SERIES",
		"HS SERIES",
		"IS SERIES",
		"LC SERIES",
		"LS SERIES",
		"LX SERIES",
		"NX SERIES",
		"RC",
		"RX SERIES",
		"SC",
	],
	Maserati: ["COUPÉ", "GHIBLI", "GRANTURISMO", "LEVANTE", "QUATTROPORTE"],
	Mercury: [
		"CAPRI",
		"COUGAR",
		"GRAND MARQUIS",
		"MARAUDER",
		"MARINER",
		"MARQUIS",
		"MILAN",
		"MONTEGO",
		"MONTEREY",
		"MOUNTAINEER",
		"MYSTIQUE",
		"SABLE",
		"TOPAZ",
		"TRACER",
		"VILLAGER",
	],
	Mazda: [
		"SERIES",
		"CX-3",
		"CX-5",
		"CX-7",
		"CX-9",
		"MAZDA2",
		"MAZDA3",
		"MAZDA5",
		"MAZDA6",
		"MILLENIA",
		"MPV22",
		"MX-5 MIATA",
		"MX-6",
		"PROTEGE",
		"RX-7",
		"RX-8",
		"SPEED",
		"TRIBUTE",
		"TRUCK",
	],
	"Mercedes-Benz": [
		"180",
		"190",
		"220",
		"230",
		"240D",
		"250",
		"280",
		"300",
		"300T",
		"350",
		"450",
		"AMG GT",
		"B CLASS",
		"C CLASS",
		"CL CLASS",
		"CLA CLASS",
		"CLS",
		"E CLASS",
		"G CLASS",
		"GL CLASS",
		"GLA-CLASS",
		"GLC CLASS",
		"GLE CLASS",
		"GLK CLASS",
		"GLS CLASS",
		"M-CLASS",
		"METRIS",
		"R-CLASS",
		"S-CLASS",
		"SLC-CLASS",
		"SL-CLASS",
		"SLK",
		"SLR",
		"SLS AMG",
		"SPRINTER",
	],
	MINI: ["CLUBMAN BASE", "COOPER", "COUNTRYMAN"],
	Mitsubishi: [
		"3000",
		"DIAMANTE",
		"ECLIPSE",
		"ENDEAVOR",
		"EXPO",
		"GALANT",
		"I-MIEV",
		"LANCER",
		"MIGHTY MAX",
		"MIRAGE",
		"MONTERO",
		"OUTLANDER",
		"RAIDER",
	],
	Polestar: [
		"Volvo C30 ",
		"S60 ",
		"S/V60",
		"S/V60 DRIVE-E",
		"S60 T8 ",
		"V60 T8 ",
		"XC60 T8",
	],
	Pontiac: [
		"AZTEK",
		"BONNEVILLE",
		"FIERO",
		"FIREBIRD",
		"G3",
		"G5",
		"G6",
		"G8",
		"GRAND AM",
		"GRAND PRIX",
		"GTO",
		"MONTANA",
		"PURSUIT",
		"SOLSTICE",
		"SUNFIRE",
		"TORRENT",
		"VIBE",
		"WAVE",
	],

	Porsche: [
		"356",
		"718 BOXTER",
		"911",
		"918",
		"924",
		"928",
		"944",
		"968",
		"BOXSTER",
		"CAYENNE",
		"CAYMAN",
		"MACAN",
		"PANAMERA 4",
	],
	Ram: [
		"1500 DS Crew Cab",
		"Cargo Van",
		"1500 ProMaster Tradesman SWB std roof",
		"PROMASTER CITY® WAGON",
		"700",
		"Pickup",
		"PROMASTER CITY® TRADESMAN",
		"3500 SRW 10K GVWR CHASSIS CAB",
		"3500 CHASSIS CAB",
		"4500 CHASSIS CAB",
		"5500 CHASSIS CAB",
		"PROMASTER® CARGO VAN",
		"PROMASTER® WINDOW VAN",
		"PROMASTER® CUTAWAY",
	],
	Saab: ["900", "9000", "92", "93", "9-3", "9-4", "9-5", "97X", "9-7X"],
	Saturn: [
		"ASTRA",
		"AURA",
		"ION",
		"L SERIES",
		"LS",
		"OUTLOOK",
		"RELAY",
		"S SERIES",
		"SC",
		"SKY",
		"SL",
		"SW",
		"VUE",
	],
	Scion: ["FR-S", "IA", "IM", "IQ", "TC", "XA", "XB", "XD"],
	smart: ["FORTWO"],
	Subaru: [
		"ASCENT",
		"BAJA",
		"BRZ",
		"CROSSTREK",
		"FORESTER",
		"IMPREZA",
		"LEGACY",
		"OUTBACK",
		"TRIBECA",
		"WRX",
		"XV",
	],
	Suzuki: [
		"AERIO",
		"EQUATOR",
		"ESTEEM",
		"FORENZA",
		"GRAND VITARA",
		"KIZASHI",
		"RENO",
		"SAMURAI",
		"SIDEKICK",
		"SX4",
		"VITARA",
		"XL",
	],
	Tesla: ["MODEL 3", "MODEL S", "MODEL X"],
	Toyota: [
		"1/2 TON WORK TRUCK",
		"4RUNNER",
		"86",
		"AVALON",
		"CAMRY",
		"CAMRY SOLARA",
		"CELICA GT",
		"C-HR",
		"COROLLA",
		"CRESSIDA",
		"ECHO",
		"EXTRA LONG",
		"FJ CRUISER",
		"HIGHLANDER",
		"LAND CRUISER",
		"MATRIX",
		"MIRAI",
		"MR",
		"PASEO",
		"PICKUP",
		"PREVIA",
		"PRIUS",
		"RAV4",
		"SCION",
		"SEQUOIA",
		"SHORT BED",
		"SIENN",
		"SUPRA",
		"T100",
		"TACOMA",
		"TERCEL",
		"TUNDRA",
		"VAN CARGO",
		"VENZA",
		"XTRACAB",
		"YARIS",
	],
	Volkswagen: [
		"ATLAS",
		"BEETLE",
		"BUS",
		"CABRIO",
		"CC",
		"EOS",
		"EUROVAN",
		"GLI",
		"GOLF",
		"JETTA",
		"KARMANN GHIA",
		"PASSAT",
		"PHAETON",
		"R32",
		"RABBIT",
		"ROUTAN",
		"TIGUAN",
		"TOUAREG",
		"VANAGON",
	],
	Volvo: [
		"240",
		"740",
		"850",
		"940",
		"C30",
		"C70",
		"S40",
		"S60",
		"S70",
		"S80",
		"S90",
		"V40",
		"V50",
		"V60",
		"V70",
		"V90",
		"XC40",
		"XC60",
		"XC7022",
		"XC90",
	],
};

export const years = [
	"1999",
	"2000",
	"2001",
	"2002",
	"2003",
	"2004",
	"2005",
	"2006",
	"2007",
	"2008",
	"2009",
	"2010",
	"2011",
	"2012",
	"2013",
	"2014",
	"2015",
	"2016",
	"2017",
	"2018",
	"2019",
	"2020",
	"2021",
	"2022",
	"2023",
	"2024",
	"2025",
];

export const category = {
	"Select Category": ["Select Sub-category"],
	"Air Conditioning System": [
		"Automobile air conditioning",
		"A/C Clutch",
		"A/C Compressor",
		" A/C Condenser",
		"A/C Hose high pressure",
		"A/C Kit",
		"A/C Relay",
		"A/C Valve",
		"A/C Expansion Valve",
		"A/C Low-pressure Valve",
		"A/C Schroeder Valve",
		"A/C INNER PLATE",
		"A/C Cooler",
		"A/C Evaporator",
		"A/C Suction Hose Pipe",
		"A/C Discharge Hose Pipe",
		"A/C Gas Receiver",
		"A/C Condenser Filter",
		"A/C Cabin Filter",
	],
	"Audio/Video Device": [
		"Antenna assembly",
		"Antenna cable",
		"Radio and media player",
		"Speaker",
		"Tuner",
		"Subwoofer",
		"Video player",
	],
	"Battery Management System": [
		"Nickel–metal hydride battery",
		"Lithium-ion battery",
		" Fuel cell",
		"Hydrogen tank",
		"DC-DC converter",
		"Inverter",
	],
	Bearing: [
		"Grooved ball bearing",
		"Needle bearing",
		"Roller bearing",
		"Sleeve bearing",
		"Wheel bearing",
	],
	"Braking System": [
		"Anti-lock braking system",
		"ABS steel pin",
		"FR Side Sensor",
		"FL Side Sensor",
		"RR Side Sensor",
		"RL Side Sensor",
		"ABS Motor Circuit",
		"Adjusting mechanism",
		"Anchor",
		"Bleed nipple",
		"Brake backing plate",
		"Brake backing pad",
		"Brake cooling duct",
		"Brake disc",
		"Brake Fluid",
		"Brake drum",
		"Brake lining",
		"Brake pad",
		"Brake pedal",
		"Brake piston",
		"Brake pump",
		"Brake roll",
		"Brake rotor",
		"Brake servo",
		"Brake shoe",

		"Shoe web",
		"Brake warning light",
		"Calibrated friction brake",
		"Caliper",
		"Combination valve",
		"Dual circuit brake system",
		"Hold-down springs",
		"Hose' Brake booster hose",
		"Air brake nylon hose",
		"Brake duct hose",
		"Hydraulic booster unit",
		"Load-sensing valve",
		"Master cylinder",
		"Metering valve",
		"Other braking system parts",
		"Park brake lever/handle",
		"Pressure differential valve",
		"Proportioning valve",
		"Reservoir",
		"Shoe return spring",
		"Tyre",
		"Vacuum brake booster",
		"Wheel cylinder",
		"Wheel stud",
	],
	Camshaft: [
		"Camshaft bearing",
		"Camshaft fastener",
		"Camshaft follower",
		"Camshaft locking plate",
		"Camshaft pushrod",
		"Camshaft spacer ring",
		"Camshaft phase variator",
	],
	Camera: ["Backup camera", "Dashcam"],
	"Car Seat": [
		"Arm Rest",
		"Bench seat",
		"Bucket seat",
		"Children and baby car seat",
		"Fastener",
		"Headrest",
		"Seat belt",
		"Seat bracket",
		"Seat cover",
		"Seat track",
		"Other seat components",
		"Back seat",
		"Front seat",
	],
	"Charge Port": ["SAE J1772", "Type 2 connector", "CHAdeMO", "CCS"],
	"Clutch Lining": [
		"Clutch pedal",
		"Clutch pressure plate",
		"Clutch shoe",
		"Clutch spring",
	],
	"Connecting rod": [
		"Connecting rod bearing",
		"Connecting rod bolt",
		"Connecting rod washer",
		"Crank case",
		"Crank pulley",
	],
	"Cylinder head": [
		"Cylinder head cover",
		"Other cylinder head cover parts",
		"Cylinder head gasket",
		"Distributor",
		"Distributor cap",
		"Drive belt",
		"Engine block",
		"Engine cradle",
		"Engine shake damper and vibration absorber",
		"Engine valve",
		"Fan belt",
		"Gudgeon pin",
		"Harmonic balancer",
		"Heater",
		"Mounting",
	],
	"Differential Case": [
		"Pinion bearing",
		"Differential clutch",
		"Spider gears",
		"Differential casing",
		"Differential flange",
		"Differential gear",
		"Differential seal",
	],
	Door: [
		"Outer door handle",
		"Inner door handle",
		"Window motor",
		"Door control module",
		"Door seal",
		"Door water-shield",
		"Hinge",
		"Door latch",
		"Door lock  and power door locks",
		"Central-locking",
		"Fuel tank door",
	],
	"Electrical Switches": [
		"Battery",
		"Door switch",
		"Ignition switch",
		"Power window switch",
		"Steering column switch",
		"Switch cover",
		"Switch panel",
		"Thermostat",
		"Frame switch",
		"Fan ditch",
		"Parts and functions of starter system",
		"Neutral Safety Switch",
	],
	"Engine Cooling": [
		"Air blower",
		"Coolant hose",
		"Cooling fan",
		"Fan blade",
		"Fan belt",
		"Fan clutch",
	],
	"Engine Oil System": [
		"Oil filter",
		"Oil gasket",
		"Oil pan",
		"Oil pipe",
		"Oil pump",
		"Oil strainer",
		"Oil suction filter",
	],
	"Exhaust System": [
		"Catalytic converter",
		"Exhaust clamp and bracket",
		"Exhaust flange gasket",
		"Exhaust gasket",
		"Exhaust manifold",
		"Exhaust manifold gasket",
		"Exhaust pipe",
		"Heat shield",
		"Heat sleeving and tape",
		"Resonator",
		"Muffler (_Silencer_)",
		"Spacer ring",
	],
	"Fuel Supply System": [
		"Air filter",
		"Carburetor",
		"Carburetor parts",
		"Choke cable",
		"Exhaust gas recirculation valve",
		"Fuel cap or fuel filler cap",
	],
	"Fuel cell component": [
		"Fuel cooler",
		"Fuel distributor",
		"Fuel filter",
		"Fuel filter seal",
		"Fuel injector",
		"Fuel injector nozzle",
		"Fuel line",
		"Fuel pump",
		"Fuel pump gasket",
		"Fuel pressure regulator",
		"Fuel rail",
	],
	"Fuel Tank": [
		"Fuel tank cover",
		"Fuel water separator",
		"Intake manifold",
		"Intake manifold gasket",
		"LPG (Liquefied petroleum gas) system assembly",
		"Throttle body",
	],
	"Floor Components and Parts": [
		"Carpet",
		"rubber and other floor material",
		"Center console (front and rear)",
	],
	Flywheel: ["Flywheel ring gear", "Flywheel clutch"],
	Hose: [
		"Fuel vapour hose",
		"Reinforced hose",
		"Non-reinforced hose",
		"Radiator hose",
	],
	"Gauges and Meters": [
		"Ammeter",
		"Clinometer",
		"Dynamometer",
		"Fuel gauge",
		"Manometer",
		"Hydrometer",
		"Odometer ",
		"Speedometer",
		"Tachometer",
		"Temperature gauge",
		"Tire pressure gauge",
		"Vacuum gauge",
		"Voltmeter",
		"Water temperature meter",
		"Oil pressure gauge",
	],
	Gear: [
		"Gear coupling",
		"Gear pump",
		"Gear ring",
		"Gear stick ",

		"Gearbox",
		"Idler gear",
		"Knuckle",
		"Master cylinder",
		"Output shaft",
		"Pinion",
		"Planetary gear set",
		"Prop shaft ",
		"Shift cable",
		"Shift fork",
		"Shift knob",
		"Shift lever",
		"Slave cylinder",
		"Speed reducer",
		"Speedometer gear",
		"Steering gear",
		"Torque converter",
		"Trans-axle housing",
		"Transfer case",
		"Transmission gear",
		"Transmission pan",
		"Transmission seal and bonded piston",
		"Transmission spring",
		"Transmission yoke",
		"Universal joint",
	],
	"Ignition Systen": [
		"Sparking cable",
		"Distributor",
		"Distributor Cap",
		"Electronic timing controller",
		"Ignition box",
		"Ignition coil",
		"Ignition Coil Connector",
		"Ignition coil parts",
		"Ignition magneto",
		"Spark plug",
		"Glow Plug",
	],
	"Lighting and Signaling System": [
		"Automotive lighting",
		"Engine bay lighting",
		"Fog light",
		"Spotlight",
		"Headlight",
		"Headlight motor",
		"Interior light and lamp",
		"Center dome",
		" Vanity-Sun visor and rear side",
		"Floor and Door-front back",
		"License plate lamp",
		"Side lighting",
		"Brake light Third or Center Brake light",
		"Tail light",
		"Tail light cover",
		"Indicator light",
		"Turn signal control",
	],
	"Low voltage/auxiliary electrical system and electronics": [
		"Audio/video devices",
		"Cameras",
		"Low voltage electrical supply system",
		"Gauges and meters",
		"Ignition system",
		"Lighting and signaling system",
		"Sensors",
		"Starting system",
		"Electrical switches",
		"Wiring harnesses",
		"Miscellaneous",
	],
	Miscellaneous: [
		"Air bag control module",
		"Alarm [136] and siren",
		"Central locking system",
		"Chassis control computer",
		"Cruise control computer",
		"Door contact",
		"Engine computer and management system",
		"Engine control unit",
		"Fuse",
		"Fuse box",
		"Ground strap",
		"Grab Handle",
		"Navigation system/GPS navigation device",
		"Performance chip",
		"Performance monitor",
		"Relay connector",
		"Remote lock",
		"Shift improver",
		"Speed controller",
		"Speedometer calibrator",
		"Transmission computer",
		"Wiring connector",
	],
	"Other Components": [
		"Trap (secret compartment)",
		"Roll cage or Exo cage",
		"Dash Panels",
	],
	"Low Voltage Electrical Supply System": [
		"Alternator",
		"Battery",
		"Performance Battery",
		"Battery Box",
		"Battery Cable terminal",
		"Battery Cable",
		"Battery Control system",
		"Battery Plate",
		"Battery tray",
		"Battery Cap",
		"Sulphuric Acid",
		"Distilled Water",
		"Voltage regulator",
	],
	Radiator: [
		"Radiator bolt",
		"Radiator (fan) shroud",
		"Radiator gasket",
		"Radiator pressure cap",
		"Overflow tank",
		"Thermostat",
		"Water neck",
		"Water neck o-ring",
		"Water pipe",
		"Water pump",
		"Water pump gasket",
		"Water tank",
	],
	Sensors: [
		"Airbag sensors",
		"Transmission/Speed sensor",
		"Camshaft position sensor",
		"Crankshaft position sensor",
		"Coolant temperature sensor",
		"Fuel level sensor",
		"Fuel pressure sensor",
		"Knock sensor",
		"Light sensor",
		"MAP sensor",
		"Mass airflow sensor",
		"Oil level sensor",
		"Oil pressure sensor",
		"Oxygen sensor",
		"Throttle position sensor",
		"ABS Sensor",
	],
	"Starting System": [
		"Starter",
		"Starter drive",
		"starter pinion gear",
		"Starter motor",
		"Starter solenoid",
		"Glowplug",
	],
	"Suspension and Steering System": [
		"Axle",
		"Camber arm",
		"Control arm",
		"Beam axle",
		"Idler arm",
		"Kingpin",
		"Lateral link",
		"Pan-hard rod",
		"Pit-man arm",
		"Power steering",
		"Rack end",
		"Shock absorber",
		"Spindle",
		"Air spring",
		"Coil spring",
		"Leaf and parabolic leaf spring",
		"Ball joint",
		"Rubber spring",
		"Spiral spring",
		"Stabilizer bars and link",
		"Steering arm",
		"Steering box",
		"Steering pump",
		"Steering column assembly",
		"Steering rack",
		"Steering shaft",
		"Steering wheel",
		"Strut",
		"Stub axle",
		"Suspension link and bolt",
		"Trailing arm",
	],
	"Thermal Management System": ["Radiator", "Fan", "Glycol", "Charger"],
	"Transmission System": [
		"Adjustable pedal",
		"Axle shaft",
		"Bell housing",
		"Universal joint",
		"Other belts",
		"Carrier assembly",
		"Chain wheel and sprocket",
		"Clutch cable",
		"Clutch disk",
		"Clutch fan",
		"Clutch fork",
		"Clutch hose",
		"Clutch lever",
	],
	Trim: [
		"Bonnet/hood",
		"Unexposed bumper",
		"Exposed Bumper",
		"Cowl screen",
		"Decklid",
		" Fascia",
		"Fender",
		"Front clip",
		"Front fascia and header panel",
		"Grille (also called grill)",
		"Pillar and hard trim",
		"Quarter panel",
		"Radiator",
		"Rocker",
		"Roof rack",
		"Spoiler",
		"Front spoiler",
		"Rear spoiler",
		"Rims",
		"Hubcap",
		"Tire/Tyre",
		"Trim package",
		"Trunk/boot/hatch",
		"Trunk/boot latch",
		"Valance",
		" Welded assembly",
	],

	Windows: [
		"Glass",
		"Sunroof",
		"Sunroof motor",
		"Sunroof Rail",
		"Sunroof Glass",
		"Window motor",
		"Window regulator",
		"Windshield ",
		"Windshield washer",
		"Window seal",
	],
	"Wiring Harness": [
		"Air conditioning harness",
		"Engine compartment harness",
		"Interior harness",
		"floor harness",
		"Main harness",
		"control harness",
	],
	"Other Miscellaneous Parts": [
		"Adhesive tape and foil",
		"Air bag",
		"Bolt cap",
		"License plate bracket",
		"Cables",
		"Speedometer cable",
		"Cotter pin",
		"Dashboard",
		"Center console",
		"Glove compartment",
		"Drag link",
		"Dynamic seal",
		"Fastener",
		"Gasket",
		"Flat",
		"moulded",
		"profiled",
		"Hood and trunk release cable",
		"Horn  and trumpet horn",
		"Injection-molded parts",
		"Instrument cluster",
		"Label",
		"Mirror",
		"Phone Mount",
		"Name plate",
		"Nut",
		"Flange nut",
		"Hex nut",
		"O-ring",
		"Paint",
		"Rivet",
		"Rubber (extruded and molded)",
		"Screw",
		"Shim",
		"Sun visor",
		"Washer",
	],
};

export const categoryQuery = [
	,
	{ value: "default", label: "Select Category" },
	{ value: "Air Conditioning System", label: "Air Conditioning System" },
	{ value: "Audio/Video Device", label: "Audio/Video Device" },
	{ value: "Battery Management System", label: "Battery Management System" },
	{ value: "Bearing", label: "Bearing" },
	{ value: "Braking System", label: "Braking System" },
	{ value: "Camshaft", label: "Camshaft" },
	{ value: "Camera", label: "Camera" },
	{ value: "Car Seat", label: "Car Seat" },
	{ value: "Charge Port", label: "Charge Port" },
	{ value: "Clutch Lining", label: "Clutch Lining" },
	{ value: "Connecting rod", label: "Connecting rod" },
	{ value: "Cylinder head", label: "Cylinder head" },
	{ value: "Differential Case", label: "Differential Case" },
	{ value: "Door", label: "Door" },
	{ value: "Electrical Switches", label: "Electrical Switches" },
	{ value: "Engine Cooling", label: "Engine Cooling" },
	{ value: "Engine Oil System", label: "Engine Oil System" },
	{ value: "Exhaust System", label: "Exhaust System" },
	{ value: "Fuel Supply System", label: "Fuel Supply System" },
	{ value: "Fuel cell component", label: "Fuel cell component" },
	{ value: "Fuel Tank", label: "Fuel Tank" },
	{ value: "Floor Components and Parts", label: "Floor Components and Parts" },
	{ value: "Flywheel", label: "Flywheel" },
	{ value: "Hose", label: "Hose" },
	{ value: "Gauges and Meters", label: "Gauges and Meters" },
	{ value: "Gear", label: "Gear" },
	{ value: "Ignition Systen", label: "Ignition Systen" },
	{
		value: "Lighting and Signaling System",
		label: "Lighting and Signaling System",
	},
	{
		value: "Low voltage/auxiliary electrical system and electronics",
		label: "Low voltage/auxiliary electrical system and electronics",
	},
	{ value: "Miscellaneous", label: "Miscellaneous" },
	{ value: "Other Components", label: "Other Components" },
	{
		value: "Low Voltage Electrical Supply System",
		label: "Low Voltage Electrical Supply System",
	},
	{ value: "Radiator", label: "Radiator" },
	{ value: "Sensors", label: "Sensors" },
	{ value: "Starting System", label: "Starting System" },
	{
		value: "Suspension and Steering System",
		label: "Suspension and Steering System",
	},
	{ value: "Thermal Management System", label: "Thermal Management System" },
	{ value: "Transmission System", label: "Transmission System" },
	{ value: "Trim", label: "Trim" },

	{ value: "Windows", label: "Windows" },
	{ value: "Wiring Harness", label: "Wiring Harness" },
	{ value: "Other Miscellaneous Parts", label: "Other Miscellaneous Parts" },
];

export const skeletonDummy = [
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
	"dummy",
];
