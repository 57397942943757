import { Modal } from '@material-ui/core';
import React from 'react';
import Loader from 'react-loader-spinner';
import RotateLoader from 'react-spinners/RotateLoader';
import PuffLoader from 'react-spinners/PuffLoader';
import RingLoader from 'react-spinners/RingLoader';
import PulseLoader from 'react-spinners/PulseLoader';
import RiseLoader from 'react-spinners/RiseLoader';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CircleLoader from 'react-spinners/CircleLoader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logo from '../../../Resources/Logo/logo5122.png';

import { css } from '@emotion/react';
import Footer from '../Footer/Footer';

function Spinner({
  fullscreen,
  isloading,
  size,
  type,
  color,
  modalstyles,
  spinnerstyles,
}) {
  const _loader_type = 'circle';
  const _mobile = useMediaQuery('(max-width:800px)');

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const _loader_props = {
    color: color ?? '#587FC0',
    loading: isloading,
    css: override,
    size: size ?? 60,
  };

  function getLoader() {
    let _loader_comp;

    switch (type ?? _loader_type) {
      case 'scale':
        _loader_comp = <ScaleLoader {..._loader_props} />;
        break;

      case 'ring':
        _loader_comp = <RingLoader {..._loader_props} />;
        break;

      case 'rise':
        _loader_comp = <RiseLoader {..._loader_props} />;
        break;

      case 'circle':
        _loader_comp = <CircleLoader {..._loader_props} />;
        break;

      case 'puff':
        _loader_comp = <PuffLoader {..._loader_props} />;
        break;

      case 'rotate':
        _loader_comp = <RotateLoader {..._loader_props} />;
        break;

      case 'pulse':
        _loader_comp = <PulseLoader {..._loader_props} />;
        break;

      default:
        break;
    }

    return _loader_comp;
  }

  const _x = (_mobile) => ({
    spinner: {
      height: fullscreen === false ? 300 : 0,
      width: '100%',
      display: 'grid',
      placeItems: 'center',
      outline: 'none',
    },
    modal: {
      outline: 'none',
      height: '100%',
      display: 'grid',
      placeItems: 'center',
      width: '100%',
      background: fullscreen === false ? 'none' : 'white',
    },
    footerCheck: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
    },
    logoWrap: {
      width: _mobile ? '20vw' : '7vw',
    },
  });

  return (
    <>
      {fullscreen === false ? (
        <div style={{ ..._x(_mobile).modal, ...modalstyles }}>
          {/* <div style={{ ..._x.spinner, ...spinnerstyles }}> */}
          {/* <Loader
						type={_loader_type}
						color={color ?? "#587FC0"}
						height={size ?? 100}
						width={size ?? 100}
					/> */}
          {/* <img src={logo} alt="logo" style={{ width: "4vw", marginTop:"-4rem" }} /> */}
          {getLoader()}
          {/* </div> */}
        </div>
      ) : (
        <>
          <Modal open={isloading} style={{ border: 'none' }}>
            <div style={_x(_mobile).modal}>
              <div style={{ marginTop: '-250px' }}>
                <img src={logo} alt="logo" style={_x(_mobile).logoWrap} />
                {/* <div style={_x.spinner}>{getLoader()}</div> */}
              </div>
              <div style={_x(_mobile).footerCheck}>
                <Footer />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}

export default Spinner;
