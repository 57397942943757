import { Paper, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import Rating from "../../../Routes/Home/ReviewMechanic/Rating";
import { Link } from "react-router-dom";
import { RatingStar } from "rating-star";
import StarRatingComponent from "react-star-rating-component";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function SparePart({
	margin,
	image,
	name,
	code,
	price,
	product_link,
	ratingStar,
	totalRate,
	averageRate,
}) {
	const _mobile = useMediaQuery("(max-width: 800px)");
	// const [skel, setskel] = useState("loading");
	return (
		<Link
			to={`/home/spare-parts/${product_link}`}
			style={{ width: "100%", textDecoration: "none", marginRight: "20px" }}
			target="_blank"
		>
			{/* {skel === "loading" ?  */}
			{/* // <SkeletonTheme color="#f2f2f2" highlightColor="#fcfcfc">
			// <p>
			//   <Skeleton  height={100} count={1} />
			//   <Skeleton count={4} />
			// </p>
		//   </SkeletonTheme> */}

			{/* : */}

			<Paper
				style={{
					..._x(_mobile).spare_part,
					marginLeft: margin && 20,
					marginRight: margin && 20,
				}}
			>
				<div
					style={{
						width: "100px",
						height: "100px",
						display: "flex",
						justifyContent: "center",
						margin: "0 auto",
					}}
				>
					<img
						src={image}
						alt=""
						style={{
							objectFit: "cover",
							width: "100%",
							height: "100%",
						}}
					/>
				</div>

				<p style={_x(_mobile).text1}>{name}</p>
				<p style={_x(_mobile).text2}>{code}</p>
				<p style={_x(_mobile).text3}>$ {price}</p>

				<div style={{ display: "flex", alignItems: "center" }}>
					<StarRatingComponent
						className={_x(_mobile).stars}
						editing={false}
						starCount={5}
						value={ratingStar}
						emptyStarColor={"#c4c4c4"}
					/>
					{/* <RatingStar rating={ratingStar} noBorder size={12} colors={{ mask: "#FBB500", rear:"#c4c4c4" }}/> */}
					<p style={_x(_mobile).text4}>
						{averageRate} ({totalRate})
					</p>
				</div>

				<button style={_x(_mobile).btn}>Buy</button>
			</Paper>
			{/* } */}
		</Link>
	);
}

export default SparePart;

const _x = (_mobile) => ({
	spare_part: {
		// height: 235,
		width: _mobile ? 156 : 200,
		display: "flex",
		alignItems: "flex-start",
		flexDirection: "column",
		padding: 10,
		border: "none",
		boxShadow: "0px 0px 10px rgba(58, 137, 248, 0.15)",

		margin: _mobile ? "1rem 0rem 1rem 0rem" : "1rem 0rem 1rem 0rem",
	},

	text1: {
		fontSize: 11,

		marginBottom: "2px",

		textTransform: "capitalize",
	},

	text2: {
		fontSize: 10,
		marginBottom: "3px",
	},
	stars: {
		fontSize: "10px",
		display: "flex",
	},

	text3: {
		fontSize: 13,
		marginBottom: "3px",
	},

	text4: {
		fontSize: "9px",
		fontFamily: "montserrat",
		marginLeft: "0.5rem",
	},

	btn: {
		fontWeight: "bold",
		color: "white",
		background: "var(--primary1)",
		width: 90,
		height: 23,
		borderRadius: 3,
		display: "flex",
		justifyContent: "center",
		margin: "0.5rem auto",
		alignItems: "center",
		fontSize: "10px",
		fontFamily: "montserrat",
	},
});
