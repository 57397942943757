import moment from "moment";
import { useState } from "react";
import {
	RiCameraFill,
	RiDeleteBin6Line,
	RiEditLine,
	RiMenuFill,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import "../../../Routes/Home/seller/dashboard.css";

const UserAdvert = ({
	image,
	title,
	image_number,
	product_no,
	price,
	date,
	adLink,
	editLink,
	views,
	deleteLink,
	deactivateLink,
	deactivateText,
	deactivateIcon,
	activateLink,
	activateIcon,
	activateText,
	borderCheck,
	pricing,
	checking,
	statusCheck,
	statusCheck2,
	pricestyle,
	pricestyle2,
	loadItemReview,
	overallRating,
	rating,
	notice,
	phone_views,
}) => {
	const [display, setDisplay] = useState(false);
	const [BgDisplay, setBgDisplay] = useState(false);

	const hideMenu = () => {
		setDisplay(false);
	};

	return (
		<>
			{BgDisplay ? (
				<div
					className="editPanelBg"
					onClick={() => {
						hideMenu();
						setBgDisplay(false);
					}}
				></div>
			) : (
				""
			)}
			<article>
				<div className="product-container">
					<div className="prd-image">
						<img src={image} alt={title} />

						<div id="pr-image-numb">
							<RiCameraFill />
							<p id="cam-numb"> {image_number} </p>
						</div>
					</div>
					<div className="single-product-detail">
						<Link
							to={`/home/spare-parts/${adLink}`}
							id="adlink-col"
							style={pricestyle}
						>
							<div className="pr-name pr-item" id="main-pr-title">
								{title}
							</div>
						</Link>
						<div className="pr-numb  pr-item" style={pricestyle2}>
							{product_no}
						</div>
						<div className="pr-price pr-item" style={pricestyle}>
							$ {price}
						</div>
						{/* <Link to={`/home/spare-parts/${adLink}`} id = "adlink-col"
										 style ={pricestyle} > */}
						<div className="review-star" onClick={loadItemReview}>
							<p className="rating-title_active_ads">
								{" "}
								Ratings & Review
								{notice > 0 && <span>{notice}</span>}
							</p>
							<div className="total-rateing-col" style={{ textAlign: "left" }}>
								<p>{overallRating}</p>
								{/* <RatingStar rating={rating} noBorder size={18} colors={{ mask: "#FBB500", rear:"#c4c4c4" }}/> */}
								<StarRatingComponent
									className="star-ratt"
									editing={false}
									starCount={5}
									value={rating}
									emptyStarColor={"#c4c4c4"}
								/>
							</div>
						</div>
						{/* </Link> */}
						<div className="pr-date  pr-item" style={pricestyle2}>
							Created {moment(date).format("LL")}
						</div>
					</div>
					<div className="views">
						<div className="norm-views" style={pricestyle2}>
							{views} Views
						</div>
						<div className="phone-views" style={pricestyle2}>
							{" "}
							{phone_views} Phone Views
						</div>
					</div>
					<div className="mobile-ad-navigation">
						<div className="mob-edit-div">
							<Link
								style={{ textDecoration: "none" }}
								to={`/home/parts-update/${editLink}`}
							>
								<p>Edit</p>
							</Link>
							{/* <p onClick={deleteLink}>Delete</p> */}
						</div>
						<div className="mob-delete-div">
							{statusCheck === true ? (
								<p
									style={borderCheck}
									id="dectivate-mob"
									onClick={activateLink}
								>
									{activateText}
								</p>
							) : (
								<p
									style={borderCheck}
									id="dectivate-mob"
									onClick={deactivateLink}
								>
									{deactivateText}
								</p>
							)}
							<p id="renew-mob" onClick={deleteLink}>
								Delete
							</p>
						</div>
					</div>

					{display ? (
						<div className="edit-panel" onClick={hideMenu}>
							<p
								className="close-edit"
								onClick={() => {
									hideMenu();
									setBgDisplay();
								}}
							>
								X
							</p>
							<ul>
								<li>
									<div className="edit-icon">
										<RiEditLine />
									</div>
									<Link
										to={`/home/parts-update/${editLink}`}
										style={{ textDecoration: "none", color: "#587FC0" }}
									>
										<p> Edit </p>
									</Link>
								</li>
								{statusCheck === true ? (
									<li onClick={activateLink}>
										<div className="edit-icon">{activateIcon}</div>
										<p> {activateText} </p>
									</li>
								) : null}
								{statusCheck2 === true ? (
									<li onClick={deactivateLink}>
										<div className="edit-icon">{deactivateIcon}</div>
										<p> {deactivateText} </p>
									</li>
								) : null}
								<li onClick={deleteLink}>
									<div className="edit-icon">
										<RiDeleteBin6Line />
									</div>
									<p> Delete </p>
								</li>
							</ul>
						</div>
					) : null}

					<div className="edit-menu-icon">
						<RiMenuFill
							onClick={() => {
								setDisplay(true);
								setBgDisplay(true);
							}}
						/>
					</div>
				</div>
			</article>
		</>
	);
};

export default UserAdvert;
