import React, { useState } from 'react';
import { Modal, Paper } from '@material-ui/core';
import './caseReview.css';
import { TiTick } from 'react-icons/ti';
import LoginPopUp from 'src/Components/Functional/loginpop/LoginPopUp';
import AuthService from 'src/services/auth.service';
import { useHistory } from 'react-router-dom';

const HepfulTip = (status) => {
  let history = useHistory();
  const currentUser = AuthService.getCurrentUser('user');

  const [tip, setTip] = useState(true);
  const [checkLogin, setcheckLogin] = useState(false);
  const [checkLogin1, setcheckLogin1] = useState(false);
  const [loginpopup, setloginpopup] = useState(false);
  const [abusePopUp, setAbusePopUp] = useState(false);
  const [reported, setReported] = useState(false);

  const confirmLogin = () => {
    if (currentUser) {
      setTip(false);
    } else {
      setloginpopup(true);
      window.scroll(0, 0);
    }
  };
  const confirmLogin1 = () => {
    if (currentUser) {
      history.push(`/home/report-abuse/`);
    } else {
      setloginpopup(true);
    }
  };

  function onLogin(status) {
    if (status === 'success') {
      setloginpopup(false);
    }
  }

  const reportAbuseTab = () => {
    if (currentUser) {
      setAbusePopUp(true);
    } else {
      setloginpopup(true);
    }
  };

  return (
    <div className="tip">
      {tip ? (
        <p className="tip-btn" onClick={confirmLogin}>
          Helpful
        </p>
      ) : (
        <p className="tip-bton">
          <span>
            <TiTick />
          </span>
          Thanks for your Feedback
        </p>
      )}

      <p onClick={reportAbuseTab} className="abuse-bttn">
        Report Abuse
      </p>

      <Modal open={loginpopup}>
        <LoginPopUp onLogin={onLogin}>
          <p style={{ cursor: 'pointer' }} onClick={() => setloginpopup(false)}>
            x
          </p>
        </LoginPopUp>
      </Modal>
      <Modal open={abusePopUp}>
        <section className="abuse-pop">
          {reported ? (
            <p style={{ marginTop: '5rem' }}>
              Thank you for your feedback
              <span
                className="abuse-pop-close"
                onClick={() => {
                  setAbusePopUp(false);
                  setReported(false);
                }}
              >
                x
              </span>
            </p>
          ) : (
            <>
              <h2>
                Report Abuse
                <span
                  className="abuse-pop-close"
                  onClick={() => {
                    setAbusePopUp(false);
                    setReported(false);
                  }}
                >
                  x
                </span>
              </h2>
              <p>
                If you find this content inappropriate and think it should be
                removed from the quickmechs Platform, let us know by clicking
                the button below.
              </p>
              <button
                onClick={() => {
                  setReported(true);
                }}
              >
                Report
              </button>
            </>
          )}
        </section>
      </Modal>
    </div>
  );
};

export default HepfulTip;
