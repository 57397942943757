import { Paper } from '@material-ui/core';
import React, { useRef, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Spacing } from 'src/Components/Style/Styles';
import reg1 from 'src/Resources/Icons/reg1.png';
import reg2 from 'src/Resources/Icons/reg2.png';
import reg3 from 'src/Resources/Icons/reg3.png';
import show from 'src/Resources/Icons/show.svg';
import hide from 'src/Resources/Icons/hide.svg';
import authService from 'src/services/auth.service';
import Spinner from 'src/Components/Functional/Spinner/Spinner';
import SimpleReactValidator from 'simple-react-validator';
import Popup from 'src/Components/Functional/Popup/Popup';
import GoogleSignin from 'src/Routes/Auth/Login/GoogleSignin';
import FacebookSignIn from 'src/Routes/Auth/Login/FacebookSignIn';
import { states } from 'src/Resources/Resources';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Helmet } from 'react-helmet';
import Autocomplete from 'react-google-autocomplete';
import AuthService from '../../../services/auth.service';
import { authHeader } from '../../../services/auth-header';

function Register() {
  const [showpassword1, setshowpassword1] = useState(false);

  const [showpassword2, setshowpassword2] = useState(false);
  const [shoplocation, setshoplocation] = useState('');
  const [newState, setNewState] = useState('');
  const [locationError, setlocationError] = useState(false);
  const [country, setcountry] = useState('');
  const [state, setstate] = useState('');

  const [form, setform] = useState({
    values: {
      firstName: '',
      lastName: '',
      phone: '',
      statez: '',
      city: '',
      address: '',
      email: '',
      password: '',
      state: state,
      country: country,
      cpassword: '',
      registrant: 'regular',
    },
    passwordMatch: true,
    agreed: false,
    emailTaken: false,
  });

  const [isloading, setisloading] = useState(false);

  const [formerror, setformerror] = useState({
    visible: false,
    messages: {},
  });

  const [showfielderror, setshowfielderror] = useState(false);
  const [errorMessages, seterrorMessages] = useState();

  const simpleValidator = useRef(new SimpleReactValidator());

  const history = useHistory();

  const _mobile = useMediaQuery('(max-width:800px)');

  async function defaultRegister() {
    // console.log("checking", form);

    setshowfielderror(true);

    const {
      firstName,
      lastName,
      email,
      password,
      cpassword,
      address,
      phone,
      city,
      statez,
      country,
      state,
    } = form.values;

    if (shoplocation === '') {
      setlocationError(true);
    } else {
      setlocationError(false);
    }

    if (simpleValidator.current.allValid() && form.agreed) {
      setisloading(true);

      // console.log(newState);

      const resp = await authService.register(
        firstName,
        lastName,
        email,
        password,
        cpassword,
        address,
        phone,
        city,
        statez,
        country,
        state
      );

      setisloading(false);
      localStorage.setItem('eTuBd', email);
      localStorage.setItem('pTuBd', phone);

      if (resp.success) {
        // AuthService.login(email, password).then(() => {});

        history.push({
          pathname: '/auth/verify',
          state: {
            email: email,
            password: password,
          },
        });
      } else {
        //? check if the error is legitimately an email issue....
        setformerror({
          ...formerror,
          visible: true,
          messages: resp.errorSource,
        });
        // console.log(
        // 	"🚀 ~ file: Register.js:132 ~ defaultRegister ~ resp.errorSource:",
        // 	resp.errorSource
        // );
      }
    } else {
      console.log('one or more fields are empty');
    }
  }

  function togglePassword1() {
    setshowpassword1(!showpassword1);
  }

  function togglePassword2() {
    setshowpassword2(!showpassword2);
  }

  function updateForm(field, text) {
    const {
      firstName,
      lastName,
      email,
      password,
      cpassword,
      phone,
      statez,
      city,
      address,
    } = form.values;

    setform({
      ...form,
      values: {
        ...form.values,
        firstName: field === 'firstName' ? text : firstName,
        lastName: field === 'lastName' ? text : lastName,
        email: field === 'email' ? text : email,
        password: field === 'password' ? text : password,
        cpassword: field === 'cpassword' ? text : cpassword,
        phone: field === 'phone' ? text : phone,
        statez: field === 'statez' ? text : statez,
        city: field === 'city' ? text : city,
        address: field === 'address' ? text : shoplocation,
        country: field === 'country' ? text : country,
        state: field === 'state' ? text : state,
      },
      passwordMatch:
        // field === "password"
        // 	? cpassword === text
        // 		? true
        // 		: false
        // 	:
        field === 'cpassword'
          ? password === text
            ? true
            : false
          : form.passwordMatch,
    });
  }

  const updateLocation = () => {
    updateForm('address', shoplocation);
    if (locationError) {
      setlocationError(false);
    }
  };

  const updateState = () => {
    updateForm('statez', newState);
  };

  useEffect(() => {
    updateState();
  }, [shoplocation]);

  useEffect(() => {
    updateLocation();
  }, [newState]);

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: 'white',
      }}
    >
      <div style={_x(_mobile).register} elevation={_mobile ? 0 : 1}>
        {/* <Tabs onTabChange={(id) => tabChange(id)} /> */}

        <Spacing />
        <div style={_x(_mobile).header}>Create an account with your email</div>

        <Spacing />
        <div style={_x(_mobile).row2}>
          <div style={_x(_mobile).left}>
            <div style={_x(_mobile).fields_box}>
              <div style={_x(_mobile).input_body}>
                <div>First Name</div>
                <Spacing vertical={8} />
                <input
                  style={_x(_mobile).input}
                  value={form.values.firstName}
                  onChange={(e) => updateForm('firstName', e.target.value)}
                  onBlur={simpleValidator.current.showMessageFor('FirstName')}
                  name="firstname"
                />
                <Spacing vertical={10} />
                {showfielderror &&
                  simpleValidator.current.message(
                    'FirstName',
                    form.values.firstName,
                    'required',
                    {
                      element: (msg) => <div style={_x().error}>{msg}</div>,
                    }
                  )}
              </div>

              <Spacing />

              <div style={_x(_mobile).input_body}>
                <div>Last Name</div>
                <Spacing vertical={8} />
                <input
                  style={_x(_mobile).input}
                  value={form.values.lastName}
                  onChange={(e) => updateForm('lastName', e.target.value)}
                  onBlur={simpleValidator.current.showMessageFor('lastName')}
                  name="lastname"
                />
                <Spacing vertical={10} />
                {showfielderror &&
                  simpleValidator.current.message(
                    'lastName',
                    form.values.lastName,
                    'required',
                    {
                      element: (msg) => (
                        <div style={_x().error}>Last name is required.</div>
                      ),
                    }
                  )}
              </div>
            </div>

            <Spacing />

            <div
              style={{
                display: 'flex',
                flexDirection: _mobile ? 'column' : 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: _mobile ? 0 : '1.5rem',
                  width: _mobile ? '100%' : '219px',
                }}
              >
                <div>Phone Number</div>
                <Spacing vertical={8} />
                <input
                  type="number"
                  style={_x(_mobile).input}
                  value={form.values.phone}
                  onChange={(e) => updateForm('phone', e.target.value)}
                  onBlur={simpleValidator.current.showMessageFor('phone')}
                  name="phone"
                />
                <Spacing vertical={10} />
                {showfielderror &&
                  simpleValidator.current.message(
                    'phone',
                    form.values.phone,
                    'required',
                    {
                      element: (msg) => (
                        <div style={_x().error}>phone no. is required.</div>
                      ),
                    }
                  )}
              </div>

              {_mobile && <Spacing horizontal={40} />}

              <div style={{ width: _mobile ? '100%' : '216px' }}>
                <div>Email Address</div>

                <Spacing vertical={8} />

                <input
                  style={_x(_mobile).input}
                  value={form.values.email}
                  onChange={(e) => updateForm('email', e.target.value)}
                  onBlur={simpleValidator.current.showMessageFor('email')}
                  type="email"
                  name="email"
                />

                <Spacing vertical={10} />

                {showfielderror &&
                  simpleValidator.current.message(
                    'email',
                    form.values.email,
                    'required|email',
                    {
                      element: (msg) => (
                        <div style={_x().error}>email is required.</div>
                      ),
                    }
                  )}
              </div>
            </div>

            <Spacing />

            <div
              style={{
                display: 'flex',
                flexDirection: _mobile ? 'column' : 'row',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    ..._x(_mobile).input_body,
                    width: _mobile ? '100%' : '216px',
                    marginRight: _mobile ? 0 : '1.5rem',
                  }}
                >
                  <div>Password</div>

                  <Spacing vertical={8} />

                  <div style={_x(_mobile).password}>
                    <input
                      value={form.values.password}
                      type={showpassword1 ? 'text' : 'password'}
                      style={_x().password_input}
                      onChange={(e) => updateForm('password', e.target.value)}
                      onBlur={simpleValidator.current.showMessageFor(
                        'password'
                      )}
                      name="password"
                      autoComplete="new-password"
                    />

                    <img
                      alt="eye"
                      src={showpassword1 ? hide : show}
                      style={_x().eye}
                      onClick={togglePassword1}
                    />
                  </div>
                </div>
                <Spacing vertical={10} />
                {showfielderror &&
                  simpleValidator.current.message(
                    'password',
                    form.values.password,
                    'required',
                    {
                      element: (msg) => (
                        <div style={_x().error}>password is required.</div>
                      ),
                    }
                  )}

                {!form.passwordMatch && !_mobile ? (
                  <>
                    <div style={_x().match}>Passwords does not match</div>
                    <Spacing />
                  </>
                ) : (
                  <Spacing vertical={_mobile ? 0 : 10} />
                )}
              </div>

              {_mobile && <Spacing horizontal={40} />}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    ..._x(_mobile).input_body,
                    width: _mobile ? '100%' : '216px',
                  }}
                >
                  <div>Confirm Password</div>
                  <Spacing vertical={8} />
                  <div style={_x(_mobile).password}>
                    <input
                      value={form.values.cpassword}
                      type={showpassword2 ? 'text' : 'password'}
                      style={_x(_mobile).password_input}
                      onChange={(e) => updateForm('cpassword', e.target.value)}
                      onBlur={simpleValidator.current.showMessageFor(
                        'confirm password'
                      )}
                      name="confirm password"
                    />
                    <img
                      alt="eye"
                      src={showpassword2 ? hide : show}
                      style={_x().eye}
                      onClick={togglePassword2}
                    />
                  </div>
                </div>

                <Spacing vertical={0} />
                {showfielderror &&
                  simpleValidator.current.message(
                    'confirm password',
                    form.values.cpassword,
                    'required',
                    {
                      element: (msg) => (
                        <div style={{ position: 'relative' }}>
                          <div
                            style={{
                              ..._x().error,
                              position: 'absolute',
                              top: '-20px',
                            }}
                          >
                            confirm password is required.
                          </div>
                        </div>
                      ),
                    }
                  )}
              </div>

              {!form.passwordMatch && _mobile && (
                <>
                  {!_mobile && <Spacing />}
                  <div style={_x().match}>Passwords does not match</div>
                  <Spacing />
                </>
              )}
            </div>

            <div style={_x(_mobile).input_body}>
              <div style={_x(_mobile).addresss}>Address</div>
              <Spacing vertical={8} />
              <Autocomplete
                className="autolocation"
                onPlaceSelected={(place) => {
                  const selectedAddress = place.formatted_address;
                  const addressComponents = place.address_components;

                  let state = '';
                  let country = '';

                  for (let i = 0; i < addressComponents.length; i++) {
                    const types = addressComponents[i].types;

                    for (let j = 0; j < types.length; j++) {
                      if (types[j] === 'administrative_area_level_1') {
                        state = addressComponents[i].long_name;
                      }
                      if (types[j] === 'country') {
                        country = addressComponents[i].long_name;
                      }
                    }
                  }
                  setstate(state);
                  setcountry(country);

                  setshoplocation(selectedAddress);
                }}
                onChange={updateLocation}
                options={{
                  types: ['establishment', 'geocode'],
                }}
              />
            </div>

            <Spacing />

            <div
              className="terms-and-service"
              style={{ width: _mobile ? '100%' : '457px' }}
            >
              <input
                type="checkbox"
                value={form.agreed}
                onChange={() => setform({ ...form, agreed: !form.agreed })}
                onBlur={simpleValidator.current.showMessageFor('Terms')}
                name="Terms"
              />
              <div
                style={{
                  display: 'inline',
                  color: '#222222',
                  fontSize: '11px',
                }}
              >
                Join to become a verified member. By becoming a verified member
                I accept all
                <Link
                  to="/home/terms-and-conditions"
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                >
                  <span> Terms and Conditions.</span>
                </Link>
              </div>
            </div>

            <Spacing vertical={10} />

            {showfielderror &&
              simpleValidator.current.message(
                'Terms',
                form.agreed,
                'required|accepted',
                {
                  element: (msg) => (
                    <div style={_x().error}>
                      terms and conditions must be accepted to register.
                    </div>
                  ),
                }
              )}

            <Spacing vertical={30} />

            <button
              style={_x(_mobile).btn4}
              className="signin-btn"
              onClick={defaultRegister}
            >
              Create Free Account
            </button>

            <div
              style={{
                marginTop: _mobile ? '0px' : '20px',
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              Already Have an account?
              <span style={{ marginLeft: 10 }}>
                <Link
                  to="/auth/login"
                  style={{
                    color: '#587FC0',
                    fontWeight: '500',
                    textDecoration: 'none',
                  }}
                >
                  Sign in
                </Link>
              </span>
            </div>
          </div>

          <p className="or">Or</p>

          <div
            className="reg-box-right"
            style={
              _mobile
                ? {
                    border: 'none',
                    height: 'auto',
                    margin: 0,
                  }
                : {
                    borderLeft: '0.5px solid #c4c4c460',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0px 0px 0 57px',
                    marginTop: '16px',
                    height: '450px',
                    width: '100%',
                  }
            }
          >
            <div
              style={
                _mobile
                  ? {
                      width: '100%',
                      paddingLeft: 20,
                      paddingRight: 20,
                      marginBottom: 30,
                      display: 'flex',
                      flexDirection: 'column',

                      alignItems: 'center',
                    }
                  : {
                      width: '100%',
                    }
              }
            >
              <GoogleSignin />

              <FacebookSignIn />

              <div style={_x().tiny}>
                <img style={_x().tiny_img} src={reg1} alt="tiny" />
                we keep it private
              </div>
              <div style={_x().tiny}>
                <img style={_x().tiny_img} src={reg2} alt="tiny" />
                share only with permission
              </div>
              <div style={_x().tiny}>
                <img style={_x().tiny_img} src={reg3} alt="tiny" />
                Quick sign in - no password
              </div>
            </div>
          </div>
        </div>

        <Popup
          type="error"
          isOpen={formerror.visible}
          errorMessages={formerror.messages}
          closePressed={() => setformerror({ ...formerror, visible: false })}
        />

        <Spinner isloading={isloading} />
        <Helmet>
          <title>{'Register New Account - quickmechs Solutions'}</title>
        </Helmet>
      </div>
    </div>
  );
}

export default Register;

const _x = (_mobile) => ({
  error: {
    color: 'red',
    fontSize: 10,
  },

  modal: {
    outline: 'none',
    height: '100%',
    display: 'grid',
    placeItems: 'center',
  },

  register: {
    background: 'white',
    width: _mobile ? '100%' : 860,
    borderRadius: 10,
    alignItems: 'center',
    margin: _mobile ? 0 : '1rem auto',
    marginTop: '1rem',
    justifyContent: 'center',
    color: '#222222',
    // borderRadius: _mobile ? 0 : 5,
    padding: _mobile ? '0px 20px' : 0,
  },

  addresss: {
    marginTop: _mobile ? '20px' : 0,
  },

  header: {
    fontWeight: 500,
    textAlign: _mobile ? 'center' : 'left',
    color: _mobile ? 'var(--primary1)' : '#000000',
    fontSize: 15,
    margin: _mobile ? 0 : '1rem auto',
    paddingLeft: _mobile ? 0 : '9rem',
  },

  row2: {
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: _mobile ? 'column' : 'row',
    margin: '0 auto',
  },

  left: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: _mobile ? 0 : '1px auto 50px',

    paddingRight: _mobile ? 20 : '41px',
  },

  fields_box: {
    display: 'flex',
    flexDirection: 'row',
    width: _mobile ? '100%' : 457,
  },

  right: {
    // background: "blue",
  },

  input_body: {
    flex: 1,
    width: _mobile ? '100%' : 457,
  },

  input: {
    height: 40,
    width: '100%',
    border: '1px solid #DBDEE7',
    padding: '0px 10px',
    borderRadius: '4px',
  },

  password: {
    width: '100%',
    display: 'flex',
    border: '1px solid #DBDEE7',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10,
    borderRadius: '4px',
  },

  password_input: {
    width: '100%',
    border: 'none',
    height: 40,
  },

  match: {
    fontSize: 10,
    color: 'tomato',
  },

  eye: {
    width: 15,
    height: 15,
    // background: "lightslategrey",
    objectFit: 'contain',
    marginLeft: 10,
  },

  options_box: {
    display: 'flex',
    alignItems: 'center',
  },

  btn: {
    paddingLeft: 30,
    paddingRight: 30,
    textTransform: 'none',
    fontSize: '14px',
    fontFamily: 'montserrat',
  },

  btn1: {
    background: 'var(--secondary3)',
  },

  btn2: {},

  btn3: {
    color: 'var(--primary1)',
    background: 'var(--accent_opacity)',
    height: 35,
    width: 200,
    borderRadius: 5,
    textTransform: 'capitalize',
    fontSize: '14px',
    fontFamily: 'montserrat',
  },

  btn4: {
    textTransform: 'none',
    fontFamily: 'montserrat',
    fontSize: '18px',
    fontWeight: 'bold',
    padding: '10px 20px',
    border: '1px solid',
    width: _mobile ? '100%' : '457px',
  },

  tiny_img: {
    objectFit: 'contain',
    marginRight: 10,
  },
  tiny: {
    display: 'flex',
  },
});
