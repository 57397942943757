import React, { Fragment, useEffect, useState } from "react";
import "./myjob.scss";
import CardElement from "./CardElement";
import { Button } from "@material-ui/core";
import { getMyJobsService } from "src/services/job/job.service";
import { useHistory } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import LoaderComponent from "./LoaderComponent";

export default function MyJob() {
  const navigation = useHistory();
  const [jobPage, setJobPage] = useState(false);
  const [progress, setProgress] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [singleJob, setSingleJob] = useState({});
  const getAllJobs = async () => {
    setProgress(true);
    try {
      const response = await getMyJobsService();
      if (response?.data?.data) {
        setJobs(response?.data?.data);
        setProgress(false);
        return;
      }
      setProgress(false);
      return;
    } catch (e) {
      setProgress(false);
      console.log(e.message);
    }
  };

  useEffect(() => {
    getAllJobs();
  }, []);
  return (
    <div id="my-job">
      <div className="inner-wrapper-my-job">
        <span className="page-title" onClick={() => setJobPage(false)}>
          My Jobs
        </span>
        <div className="card-wrapper">
          {jobs.length !== 0 ? (
            <Fragment>
              {jobs.map((job, index) => (
                <CardElement
                  getAllJobs={getAllJobs}
                  page="my-job"
                  onClick={() => {
                    // navigation.push("/home/job-details", { jobs, job });
                  }}
                  data={job}
                  key={Math.random()}
                />
              ))}
            </Fragment>
          ) : !progress && jobs.length === 0 ? (
            <EmptyPageElement />
          ) : (
            <LoaderComponent />
          )}
        </div>
      </div>
    </div>
  );
}

export const EmptyPageElement = () => {
  const navigation = useHistory();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "5rem",
      }}
    >
      <img
        src={require("../../../Resources/Images/empty-job.png").default}
        alt="no-data"
      />
      <span className="no-job-text">
        Millions of candidates are waiting for you
      </span>
      <Button
        onClick={() => {
          navigation.push("/home/post-job");
        }}
        style={{
          background: "#587FC0",
          color: "#FFFFFF",
          borderRadius: 30,
          marginTop: "1rem",
        }}
        variant="contained"
      >
        Post a Job
      </Button>
    </div>
  );
};
