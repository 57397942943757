import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Spacing } from "src/Components/Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

export default function PlaceHolder({ type }) {
	const _mobile = useMediaQuery("(max-width:800px)");

	const theme_props = {
		baseColor: "red",
		highlightColor: "grey",
	};

	const placeholder_props = {
		type: "rect",
		showLoadingAnimation: true,
		// color: "var(--accent1)",
		color: "lightgrey",
	};

	function resolvePlaceHolderTypes() {
		let resolution;

		switch (type) {
			case "mechpost":
				resolution = (
					<div style={_x(_mobile).mech_placeholder}>
						<Skeleton count={1} {...theme_props} style={_x(_mobile).image} />

						<div style={_x().rightside}>
							<Skeleton
								count={1}
								{...theme_props}
								style={_x(_mobile).shop_name}
							/>

							<Spacing />

							<Skeleton
								count={1}
								{...theme_props}
								style={_x(_mobile).location}
							/>

							<Spacing />

							<Skeleton count={3} style={_x(_mobile).description} />

							<Spacing />

							<div style={{ display: "flex", alignItems: "center" }}>
								<Skeleton count={1} {...theme_props} style={_x().stars} />

								<Spacing horizontal={8} />

								<Skeleton count={1} {...theme_props} style={_x().text8} />

								<Spacing horizontal={8} />

								<Skeleton count={1} {...theme_props} style={_x().text8} />
							</div>
						</div>
					</div>
				);
				break;

			case "map":
				resolution = (
					// <div
					// 	style={{
					// 		border: "1px solid lightgrey",
					// 		borderRadius: 6,
					// 	}}
					// >
					// 	<Skeleton count={1} style={_x().map_pl} />
					// </div>

					<ReactPlaceholder {...placeholder_props} style={_x().map_pl} />
				);
				break;

			case "ad-hor":
				resolution = (
					// <div
					// 	style={{
					// 		border: "1px solid lightgrey",
					// 		borderRadius: 6,
					// 		// background: "white",
					// 	}}
					// >
					// 	<Skeleton count={1} style={_x().ad_hor} />
					// </div>

					<ReactPlaceholder {...placeholder_props} style={_x().ad_hor} />
				);
				break;

			case "ad-ver":
				resolution = (
					// <div
					// 	style={{
					// 		border: "1px solid lightgrey",
					// 		borderRadius: 6,
					// 		// background: "white",
					// 	}}
					// >
					// 	<Skeleton count={1} style={_x().ad_ver} />
					// </div>

					<ReactPlaceholder {...placeholder_props} style={_x().ad_ver} />
				);
				break;

			case "banner":
				resolution = (
					// <div
					// 	style={{
					// 		border: "1px solid lightgrey",
					// 		borderRadius: 6,
					// 		marginTop: 30,
					// 	}}
					// >
					// 		<Skeleton  {...theme_props} count={1} style={_x().banner} />
					// </div>

					<ReactPlaceholder {...placeholder_props} style={_x().banner} />
				);
				break;

			case "timebox":
				resolution = (
					// <div
					// 	style={{
					// 		border: "1px solid lightgrey",
					// 		borderRadius: 6,
					// 		background: "white",
					// 		marginTop: 30,
					// 	}}
					// >
					// 	<Skeleton count={1} style={_x().timebox} />
					// </div>

					<ReactPlaceholder {...placeholder_props} style={_x().timebox} />
				);
				break;
		}

		return resolution;
	}

	return <>{resolvePlaceHolderTypes()}</>;
}

const _x = (_mobile) => ({
	timebox: {
		height: 350,
		marginTop: 200,

		// border: "1px solid lightgrey",
		// background: "white",
		borderRadius: 6,
		marginTop: 30,
	},

	banner: {
		width: "100%",
		height: 75,
		borderRadius: 6,
		marginTop: 30,
		height: 80,
	},

	ad_hor: {
		width: 730,
		height: 90,
		borderRadius: 6,
	},

	ad_ver: {
		height: 427,
		borderRadius: 6,
		width: 124,
	},

	map_pl: {
		height: 200,
		width: 250,
		// background: "teal",
		borderRadius: 10,
		border: "1px solid #ededed",
	},

	mech_placeholder: {
		height: _mobile ? "100%" : 164,
		width: _mobile ? "100%" : 730,
		backgroundColor: "white",
		borderRadius: 10,
		display: "grid",
		gridTemplateColumns: _mobile ? null : "auto 1fr",
		gridTemplateRow: _mobile ? null : "auto 1fr",
		marginBottom: 20,
		cursor: "pointer",
		border: "1px solid #dedddd",
	},
	image: {
		height: 160,
		width: _mobile ? "100%" : 200,
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: _mobile ? 0 : 10,
		borderTopRightRadius: _mobile ? 10 : 0,
	},
	rightside: {
		height: "100%",
		padding: 20,
		paddingTop: 20,
		// paddingBottom: 5,
	},
	shop_name: {
		width: _mobile ? "100%" : 100,
	},
	location: {
		width: _mobile ? "100%" : 100,
	},
	description: {
		width: _mobile ? "100%" : 200,
	},
	stars: {
		width: _mobile ? "100%" : 100,
	},
	text1: {
		width: _mobile ? "100%" : 50,
	},
	text8: {
		width: _mobile ? "100%" : 50,
	},
});
