import { Modal, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Align, Spacing } from "src/Components/Style/Styles";
import mechcard from "src/Resources/Icons/mechcard.png";
import sparepartcard from "src/Resources/Icons/sparepartcard.png";
import authService from "src/services/auth.service";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Popup from "src/Components/Functional/Popup/Popup";
import LoginPopUp from "src/Components/Functional/loginpop/LoginPopUp";

export default function SellerRegistration() {
	const [currentUser, setCurrentUser] = useState({});
	const [err, setErr] = useState(false);

	const [isMech, setIsMech] = useState(false);
	const [isDealer, setisDealer] = useState(false);

	const _mobile = useMediaQuery("(max-width:800px)");

	const _cards = [
		{
			image: mechcard,
			text: "Want to be a Mechanic, Body Shop, Electritian, Car Ac, Tire Shop, Towing, Security etc.",
			link: `/auth/${isMech ? "edit" : "create"}/${
				currentUser?.data?.user_info?.id
			}/mechanic/form`,
			banner: "List your shop",
			type: "mechanic",
		},
		{
			image: sparepartcard,
			text: "Want to be a Spare Part Dealer or sell anything related to automobile with quickmechs.",
			// link: `/auth/${isMech ? "edit" : "create"}/${currentUser?.data?.user_info?.id}/part_dealer/form`,
			link: "/home/become-a-dealer/",
			banner: "Sell",
			type: "part_dealer",
		},
	];

	const history = useHistory();

	useEffect(() => {
		const res = authService.getCurrentUser("user");
		console.log(res);

		setCurrentUser(res);

		setIsMech(
			res?.data?.user_info?.permissions.map((role) => role).includes("mechanic")
		);
		setisDealer(
			res?.data?.user_info?.permissions
				.map((role) => role)
				.includes("part_dealer")
		);
	}, []);

	function goToDetailsForm(link, type) {
		if (currentUser) {
			if (!currentUser.data.user_info.permissions.includes(type)) {
				history.push(link);
			} else {
				setErr(true);
			}
		} else {
			history.push("/auth/login");
		}
	}

	return (
		<>
			<Align verticalHeight={_mobile ? 0 : 100}>
				<div style={_x(_mobile).seller_reg_superior}>
					<Paper style={_x(_mobile).seller_reg} elevation={_mobile ? 0 : 1}>
						<p style={_x().title}>quickmechs</p>

						<div style={{ width: "100%" }}>
							<p style={_x().subtitle}>
								Reach the closest service provider, Who would help fix your car
								and keep you moving, with quickmechs. Signing up is free, fast
								and easy.
							</p>

							<Spacing />

							<p style={_x().text1}>What would you like to do?</p>
						</div>

						<div style={_x(_mobile).bottom}>
							{_cards.map((e) => (
								<div style={_x().card}>
									<div>
										<div style={_x().banner}>{e.banner}</div>

										<div style={_x().center}>
											<img
												style={_x().image}
												src={e.image}
												alt={e.image.toString()}
											/>
										</div>

										<p style={_x().text2}>{e.text}</p>
									</div>

									<div style={_x().center}>
										<button
											onClick={() => goToDetailsForm(e.link, e.type)}
											style={_x().btn}
										>
											Click Here
										</button>
									</div>
								</div>
							))}
						</div>
					</Paper>
				</div>
			</Align>

			<Popup
				isOpen={err}
				popupMessage={
					isDealer
						? "You are already registered as a Spare Parts Dealer"
						: "You are already registered as a service provider"
				}
				popupMessageStyles={{ fontWeight: 500 }}
				buttonTitle={isDealer ? "Post an Ad" : "Go to Dashboard"}
				hideCloseButton={true}
				buttonStyles={{
					backgroundColor: isDealer ? "#fdd05d" : "var(--primary1)",
					fontWeight: "normal",
					width: isDealer ? "35%" : "45%",
				}}
				vertical={2}
				continuePressed={() => {
					history.push(
						"/home/dashboard"
						// `/auth/${isMech ? "edit" : "create"}/${
						// 	currentUser?.data?.user_info?.id
						// }/part_dealer/form`
					);
				}}
				actionComps={[
					isDealer && (
						<button
							onClick={() => {
								setErr(false);
								history.push("/home/dashboard");
							}}
							style={{
								..._x().popup_btn,
								marginLeft: 15,
								color: "#fdd05d",
							}}
						>
							Go to Dashboard
						</button>
					),
				]}
			/>
		</>
	);
}

const _x = (_mobile) => ({
	seller_reg_superior: {
		width: _mobile ? "100%" : "73vw",
		padding: _mobile ? 0 : 80,
		background: "white",
		paddingLeft: _mobile ? 0 : 100,
		paddingRight: _mobile ? 0 : 100,
		display: "flex",
		justifyContent: "center",
	},

	popup_btn: {
		height: 40,
		// width: 137,
		paddingLeft: 15,
		paddingRight: 15,
		border: "1px solid #fdd05d",
		borderRadius: 6,
		fontWeight: "bold",
		fontSize: 16,
		marginBottom: "1.7rem",
	},

	center: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
	},

	image: {
		width: 80,
		alignSelf: "center",
		marginTop: 30,
	},

	seller_reg: {
		// height: 500,
		background: "white",
		width: _mobile ? "100%" : "80%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: _mobile ? "20px 35px 60px" : 60,
		border: _mobile ? "none" : "1px solid #dedddd",
		// boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
		boxShadow: _mobile ? "none" : "0 6px 35px 0 rgb(0 0 0 / 20%)",
	},

	title: {
		fontWeight: "bold",
		fontSize: 16,
		color: "var(--primary1)",
	},

	subtitle: {
		fontWeight: 500,
		textAlign: "center",
		paddingLeft: 5,
		paddingRight: 5,
	},

	text1: {
		fontWeight: "bold",
		fontSize: 16,
		paddingLeft: 20,
		paddingRight: 20,
	},

	text2: {
		fontWeight: 600,
		textAlign: "center",
		lineHeight: 2,
	},

	bottom: {
		display: "grid",
		...(_mobile
			? { gridTemplateRows: "1fr 1fr" }
			: { gridTemplateColumns: "1fr 1fr" }),
		placeItems: "center",
		gridGap: 20,
	},

	card: {
		border: "1px solid var(--primary1)",
		borderRadius: 10,
		height: 370,
		// width: 300,
		padding: 30,
		position: "relative",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},

	banner: {
		height: 40,
		background: "var(--primary1)",
		padding: 5,
		width: 130,
		color: "white",
		position: "absolute",
		top: 0,
		right: 0,
		borderTopRightRadius: 8,
		display: "grid",
		placeItems: "center",
		fontWeight: "bold",
	},

	btn: {
		border: "1px solid var(--primary1)",
		borderRadius: 30,
		padding: 10,
		paddingLeft: 10,
		paddingRight: 10,
		display: "grid",
		plcaeItems: "center",
		width: 100,
	},
});
