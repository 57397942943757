import { Button, Paper, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Spacing } from '../../../Components/Style/Styles';
import axios from 'axios';
import close2 from '../../../Resources/Icons/close2.png';
import { Helmet } from 'react-helmet';
import './forgot.css';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

function ForgotPassword() {
  let history = useHistory();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [valid, setValid] = useState('');
  const [verified, setVerified] = useState(false);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  function closeModal() {
    setVerified(false);
  }

  function continueVerification() {
    let userInput = localStorage.getItem('email', email);

    history.push(`/auth/${userInput}/status=success/update-password`);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(API_URL + 'users/forgot-password', {
        email,
      })
      .then((res) => {
        setVerified(true);
        localStorage.setItem('email', email);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setValid('Please Enter a valid Email address');
        setMessage(resMessage);
      });
  };

  return (
    <main style={{ height: '100vh' }}>
      <section
        style={{
          padding: '1rem 0',
        }}
      >
        <div className="forgot">
          <div style={_x.header}>Reset your password</div>

          <Spacing vertical={30} />
          {message && <p className="topbarError">{message}</p>}
          {valid && <p className="topbarError">{valid}</p>}

          <div style={_x.header2}>Email Address</div>
          <Spacing vertical={10} />
          <TextField
            className="forgot-input-reset-wrapper"
            InputProps={{
              style: {
                outline: 'none',
                width: '100%',
              },
            }}
            value={email}
            variant="outlined"
            size="small"
            required
            onChange={onChangeEmail}
          />
          {/* {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                &#9432; {message}
              </div>
            </div>
          )} */}

          {/* {valid && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                &#9432; {valid}
              </div>
            </div>
          )} */}

          <Spacing />

          <div style={{ fontSize: '11px', color: '#222222' }}>
            We will email you instructions to reset your password
          </div>

          <Spacing vertical={40} />
          <div className="forgot-bottom">
            <Button style={_x.btn1} type="submit" onClick={handleSubmit}>
              Reset My Password
            </Button>
            <Spacing />
            <div className="down-bottom">
              <Link to="/auth/register">
                <button style={_x.btn2}>Create a new account</button>
              </Link>
              <Spacing />
              <Link to="/auth/login">
                <button style={_x.btn2}>Return to Sign in</button>
              </Link>
            </div>
          </div>
        </div>
        {verified && (
          <section style={_x.modal_bg}>
            <div
              open={verified === false}
              onClick={closeModal}
              style={{ border: 'none' }}
            >
              <div style={_x.modall}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <img
                    onClick={closeModal}
                    style={_x.close}
                    src={close2}
                    alt="close"
                  />
                </div>

                <p
                  style={{
                    fontFamily: 'montserrat',
                    fontSize: '16px',
                    textAlign: 'center',
                  }}
                >
                  A reset code has been sent to your email address.
                </p>

                <button onClick={continueVerification} style={_x.continue}>
                  Continue
                </button>
              </div>
            </div>
          </section>
        )}
      </section>
      <Helmet>
        <title>{'Forgot Password - quickmechs Solutions'}</title>
      </Helmet>
    </main>
  );
}

export default withRouter(ForgotPassword);

const _x = {
  header: {
    fontWeight: 'bold',
    fontSize: 15,
    textAlign: 'center',
  },
  header2: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: '2px',
    color: '#222222',
  },

  btn1: {
    backgroundColor: 'var(--primary1)',
    width: '100%',
    textTransform: 'none',
    fontWeight: 'bolder',
    fontSize: '1rem',
    // border: '1px solid',
    color: 'white',
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'montserrat',
  },
  btn2: {
    color: 'var(--primary1)',
    fontFamily: 'montserrat',
    paddingTop: '10px',
  },
  modall: {
    background: 'white',
    width: 374,
    height: 196,
    outline: 'none',
    borderRadius: 6,
    margin: '170px auto',
    display: 'grid',
    placeItems: 'center',
    padding: 20,
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  continue: {
    height: 40,
    width: 137,
    backgroundColor: 'var(--primary1)',
    paddingLeft: 15,
    paddingRight: 15,
    // border: '1px solid',
    color: 'white',
    borderRadius: 6,
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: '1.7rem',
  },

  close: {
    cursor: 'pointer',
  },
  modal_bg: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00000090',
  },
};
