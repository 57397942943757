import React from "react";
import "./PaymentDecline.scss";
import { Button, useMediaQuery } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

function PaymentDecline() {
  const location = useLocation();
  const matches = useMediaQuery("(max-width:1000px)");
  const history = useHistory();
  const { state } = location;
  return (
    <div className="payment-success-container">
      <img
        src={require("../../../Resources/Images/payment-decline.svg").default}
        alt=""
      />
      <div className="txt-success-pay-wrap">
        <label for="title">Declined</label>
        <span>
          {state.error_message
            ? `Your Job Posting was not successful because your payment declined with
          the error ${state.error_message}`
            : "Your Job Posting was not successful because your payment declined"}
        </span>
      </div>
      <div className="success-page-button-container">
        <Button
          sx={{ ...styles.postAnother, width: matches ? "90%" : 200 }}
          variant="contained"
        >
          Retry
        </Button>
        <Button
          onClick={() => history.push("/home/jobs/")}
          sx={{ ...styles.goToMyJob, width: matches ? "90%" : 200 }}
          variant="contained"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
const styles = {
  postAnother: {
    background: "#587FC0",
    borderRadius: "22px",
    color: "#fff",
    width: 200,
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
      boxShadow: "none",
      border: " 3px solid #587FC0",
      color: "#587FC0",
    },
  },
  goToMyJob: {
    borderRadius: "22px",
    border: " 3px solid #587FC0",
    background: "#fff",
    width: 200,
    color: "#587FC0",
    textTransform: "capitalize",
    "&:hover": {
      background: "#587FC0",
      boxShadow: "none",
      color: "#fff",
    },
  },
};
export default PaymentDecline;
