// import React, { useState, useRef, useEffect } from "react";
// import { AiOutlinePlus } from "react-icons/ai";
// import { Link } from "react-router-dom";
// import { ImImages } from "react-icons/im";
// import { GrFormClose } from "react-icons/gr";
// import { RiDeleteBin5Line, RiCameraFill } from "react-icons/ri";
// import "./newpartssteps.css";
// import axios from "axios";
// import authService from "../../../services/auth.service";
// import { authHeader } from "../../../services/auth-header";
// import { states } from "src/Resources/Resources";
// import ImageUploading from "react-images-uploading";
// import close2 from "../../../Resources/Icons/close2.png";
// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";
// import Select from "react-validation/build/select";
// import Textarea from "react-validation/build/textarea";
// import Spinner from "src/Components/Functional/Spinner/Spinner";
// import Autocomplete from "react-google-autocomplete";

// const {
//   REACT_APP_CURRENT_ENVIRONMENT,
//   REACT_APP_DEVELOPMENT_URL,
//   REACT_APP_PRODUCTION_URL,
// } = process.env;

// const API_URL =
//   REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
//     ? REACT_APP_DEVELOPMENT_URL
//     : REACT_APP_PRODUCTION_URL;

// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="warning" role="alert">
//         &#9432; This field is required!
//       </div>
//     );
//   }
// };

// const NewPartsDealerStepsForm = () => {
//   const form = useRef();
//   const checkBtn = useRef();
//   const [details, setDetails] = useState(true);
//   const [shopUpload, setShopUpload] = useState(false);
//   const [eror, setEror] = useState(false);
//   const [errMessage, setErrMessage] = useState("");
//   const [submitted, setSubmitted] = useState(false);
//   const [isloading, setisloading] = useState(false);
//   const [width, setWidth] = React.useState(window.innerWidth);
//   const [shoplocation, setshoplocation] = useState("");
//   const [locationError, setlocationError] = useState(false);
//   const [country, setcountry] = useState("");
//   const [state, setstate] = useState("");

//   const [formm, setFormm] = useState({
//     phone_number: "",
//     shop_name: "",
//     shop_description: "",
//     city: "",
//     state: state,
//     country: country,
//     identification_type: "",
//     identity_number: "",
//     office_address: "",
//     company_photo: "",
//     referral_code: "",
//   });

//   // console.log(formm.company_photo);

//   async function grabLoggedInUser() {
//     const resp = await authService.getCurrentLoggedInUser();

//     if (resp.success===true) {
//       const response = resp.data;
//       setFormm({
//         ...formm,
//         phone_number: response.phone_number,
//         office_address: response.address,
//         city: response.city,
//         state: response.state,
//       });
//     } else {
//       console.log("none");
//     }
//   }

//   const changeHandle = (e) => {
//     setFormm({
//       ...formm,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const nextBtn = () => {
//     form.current.validateAll();
//     if (checkBtn.current.context._errors.length === 0) {
//       setDetails(false);
//       setShopUpload(true);
//     } else {
//     }
//   };

//   const backBtn = () => {
//     setDetails(true);
//     setShopUpload(false);
//   };

//   const [images, setImages] = React.useState([]);
//   const maxNumber = 1;

//   const [checkboxValue, setCheckboxValue] = useState(true);

//   const onChange = (imageList, addUpdateIndex) => {
//     // data for submit
//     const loadedImage = imageList;
//     let newArry = imageList.map((item) => item.data_url);
//     setImages(imageList);
//     if (loadedImage) {
//       setFormm({
//         ...formm,
//         company_photo: newArry[0],
//       });
//     }
//   };

//   const updateLocation = () => {
//     const comps = shoplocation.address_components;
//     console.log(comps);
//     setFormm({
//       ...formm,
//       office_address: shoplocation,
//     });
//     if (locationError) {
//       setlocationError(false);
//     }
//   };

//   useEffect(() => {
//     updateLocation();
//   }, [shoplocation]);

//   const submitForm = (e) => {
//     setisloading(true);
//     console.log(formm);
//     e.preventDefault();

//     form.current.validateAll();
//     if (shoplocation === "") {
//       setlocationError(true);
//     } else {
//       setlocationError(false);
//     }

//     if (checkBtn.current.context._errors.length === 0) {
//       formm.state = state;
//       formm.country = country;

//       axios
//         .post(API_URL + "part-dealer/create", formm, {
//           headers: authHeader(),
//         })
//         .then((res) => {
//           setisloading(false);
//           setSubmitted(true);
//         })
//         .catch((error) => {
//           const resMessage =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();
//           setisloading(false);
//           setEror(true);
//           setErrMessage(resMessage);
//         });
//     } else {
//       setisloading(false);
//     }
//   };

//   useEffect(() => {
//     grabLoggedInUser();
//   }, []);

//   useEffect(() => {
//     window.addEventListener("resize", () => setWidth(window.innerWidth));
//     if (width > 800) {
//       setShopUpload(true);
//     } else if (width < 800) {
//       setShopUpload(false);
//     }
//   }, [width]);

//   return (
//     <main className="steps_main_wrapper">
//       {width > 800 && (
//         <div className="details_parts_header"> Spare Parts Details</div>
//       )}
//       {width < 800 && (
//         <section className="st_topBar">
//           <p className="st_branding">
//             {details && "Personal Details"}
//             {shopUpload && "Upload Shop Photo"}
//           </p>
//           <p className="st_back" onClick={backBtn}>
//             Back
//           </p>
//         </section>
//       )}
//       <Form
//         id="ad-form "
//         className="ad-form adFormNew become-a-seller"
//         onSubmit={submitForm}
//         ref={form}
//       >
//         {width > 800 && (
//           <div className="personal-details-shop-reg">
//             <p>Personal Details:</p>
//           </div>
//         )}

//         <section>
//           {details && (
//             <section className="details_step_form">
//               <div>
//                 <label htmlFor="phone">
//                   Phone Number
//                   <Input
//                     type="number"
//                     placeholder="Enter Phone Number"
//                     name="phone_number"
//                     maxLength="13"
//                     validations={[required]}
//                     onChange={changeHandle}
//                     value={formm.phone_number}
//                   />
//                 </label>
//                 <label htmlFor="idtype">
//                   Type of ID (Optional)
//                   <Select
//                     name="identification_type"
//                     onChange={changeHandle}
//                     value={formm.identification_type}
//                   >
//                     <option value="">Select ID Type</option>
//                     <option value="International Passport">
//                       International Passport
//                     </option>
//                     <option value="NIN">National Identity Number</option>
//                     <option value="Voters Card Number">
//                       Voters Card Number
//                     </option>
//                   </Select>
//                 </label>
//               </div>
//               <div>
//                 <label htmlFor="id">
//                   ID Number (Optional)
//                   <Input
//                     type="text"
//                     placeholder="Enter ID Number"
//                     name="identity_number"
//                     onChange={changeHandle}
//                     value={formm.identity_number}
//                   />
//                 </label>
//                 <label
//                   htmlFor="referal"
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     flexDirection: "row",
//                   }}
//                 >
//                   <input type="checkbox" name="" id="checkbox_st" /> Not
//                   Applicable
//                 </label>
//               </div>
//               {width > 800 && (
//                 <div className="personal-details-shop-reg">
//                   <p>Shop Details:</p>
//                 </div>
//               )}
//               <div className="shop-deeds">
//                 <label htmlFor="shopName">
//                   Shop Name
//                   <Input
//                     type="text"
//                     placeholder="Enter Shop Name"
//                     name="shop_name"
//                     validations={[required]}
//                     onChange={changeHandle}
//                     value={formm.shop_name}
//                   />
//                 </label>
//                 <label htmlFor="id">
//                   Shop Address
//                   <Autocomplete
//                     className="autolocation"
//                     onPlaceSelected={(place) => {
//                       const selectedAddress = place.formatted_address;
//                       const addressComponents = place.address_components;

//                       let state = "";
//                       let country = "";

//                       for (let i = 0; i < addressComponents.length; i++) {
//                         const types = addressComponents[i].types;

//                         for (let j = 0; j < types.length; j++) {
//                           if (types[j] === "administrative_area_level_1") {
//                             state = addressComponents[i].long_name;
//                           }
//                           if (types[j] === "country") {
//                             country = addressComponents[i].long_name;
//                           }
//                         }
//                       }
//                       setstate(state);
//                       setcountry(country);

//                       setshoplocation(selectedAddress);
//                     }}
//                     onChange={updateLocation}
//                     options={{
//                       types: ["establishment", "geocode"],
//                     }}
//                   />
//                   {locationError && (
//                     <div
//                       style={{
//                         color: "red",
//                         fontSize: "11px",
//                         marginTop: "10px",
//                       }}
//                     >
//                       Address is required.
//                     </div>
//                   )}
//                 </label>
//               </div>
//               <div>
//                 <label htmlFor="idtype" className="textArea-label">
//                   Shop Description
//                   <Textarea
//                     type="text"
//                     style={{ height: "200px" }}
//                     placeholder="Enter Shop Description"
//                     name="shop_description"
//                     validations={[required]}
//                     onChange={changeHandle}
//                     value={formm.shop_description}
//                   />
//                 </label>
//               </div>

//               <div>
//                 <label htmlFor="code">
//                   Referral Code
//                   <Input
//                     type="text"
//                     name="referral_code"
//                     onChange={changeHandle}
//                     value={formm.referral_code}
//                     placeholder="Enter Referal Code"
//                   />
//                 </label>
//                 <label
//                   htmlFor="referal"
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     flexDirection: "row",
//                   }}
//                 >
//                   <input type="checkbox" name="" id="checkbox_st" /> Not
//                   Applicable
//                 </label>
//               </div>
//             </section>
//           )}

//           {shopUpload && (
//             <section className="st_shop_pix">
//               <div style={{ flexDirection: "column" }}>
//                 {width > 800 && (
//                   <div style={{ margin: "20px 0 10px", fontSize: "14px" }}>
//                     Upload Company Photo
//                   </div>
//                 )}
//                 <ImageUploading
//                   value={images}
//                   onChange={onChange}
//                   maxNumber={maxNumber}
//                   dataURLKey="data_url"
//                 >
//                   {({
//                     imageList,
//                     onImageUpload,
//                     onImageRemoveAll,
//                     onImageUpdate,
//                     onImageRemove,
//                     isDragging,
//                     dragProps,
//                   }) => (
//                     <div className="st_shop_image">
//                       <section className="st_ppix">
//                         {imageList.map((image, index) => (
//                           <div key={index} className="sh_photo_wrap">
//                             <img
//                               src={image["data_url"]}
//                               alt="quickmechs-product-image"
//                               width="50"
//                               height="50"
//                             />
//                             <div
//                               className="st_delete"
//                               onClick={() => onImageRemove(index)}
//                             >
//                               <RiDeleteBin5Line />
//                             </div>

//                             {/* </div> */}
//                           </div>
//                         ))}
//                       </section>
//                       {imageList===0 && (
//                         <div
//                           className="sh_photo_info"
//                           id="info_mob-st"
//                           onClick={onImageUpload}
//                         >
//                           <p className="plus_st">
//                             <AiOutlinePlus />
//                           </p>
//                           <p className="addPhoto">
//                             <span>
//                               <ImImages />
//                             </span>
//                             Add Photo
//                           </p>
//                           <p className="num_uploaded">Uploaded 0/1 images</p>
//                         </div>
//                       )}
//                     </div>
//                   )}
//                 </ImageUploading>
//                 <p className="st_notice">
//                   Picture must not exceed 5MB. Suported formats are *jpg, *gif
//                   and *png
//                 </p>
//               </div>
//             </section>
//           )}
//         </section>

//         {width < 800 && (
//           <section className="steps_indicator">
//             <article className="st_indicator">
//               <div
//                 className="indicator"
//                 style={
//                   details
//                     ? { borderColor: "var(--primary2)" }
//                     : { borderColor: "lightgrey" }
//                 }
//               >
//                 1. Details
//               </div>
//               <div
//                 className="indicator"
//                 style={
//                   shopUpload
//                     ? { borderColor: "var(--primary2)" }
//                     : { borderColor: "lightgrey" }
//                 }
//               >
//                 2. Upload
//               </div>
//             </article>
//             <div>
//               {shopUpload ? (
//                 <button className="st_btn">Submit</button>
//               ) : (
//                 <p className="st_btn" onClick={nextBtn}>
//                   Next
//                 </p>
//               )}
//             </div>
//           </section>
//         )}
//         <CheckButton style={{ display: "none" }} ref={checkBtn} />
//         {width > 800 && <button className="st_btn">Submit</button>}
//       </Form>
//       {eror && (
//         <section className="err_wrap">
//           <div className="err">
//             {errMessage === "One or more fields are invalid"
//               ? "Shop Photo is required"
//               : errMessage}
//             <p className="err_btn" onClick={() => setEror(false)}>
//               <GrFormClose />
//             </p>
//           </div>
//         </section>
//       )}
//       {submitted && (
//         <section style={_x.modal_bg}>
//           <div open={submitted === false} style={{ border: "none" }}>
//             <div style={_x.modall}>
//               <div
//                 style={{
//                   width: "100%",
//                   display: "flex",
//                   justifyContent: "flex-end",
//                 }}
//               ></div>

//               <h5
//                 style={{
//                   fontFamily: "montserrat",
//                   fontSize: "16px",
//                   textAlign: "center",
//                   fontWeight: "500",
//                   margin: 0,
//                 }}
//               >
//                 Congratulations
//               </h5>

//               <p
//                 style={{
//                   fontFamily: "montserrat",
//                   fontSize: "14px",
//                   textAlign: "center",
//                   width: "70%",
//                 }}
//               >
//                 You have successfully registered as a Spare Part Dealer
//               </p>
//               <article
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   width: "100%",
//                 }}
//               >
//                 <Link
//                   to="/home/submit-an-ad"
//                   style={{ textDecoration: "none", width: "50%" }}
//                 >
//                   <button style={_x.continue}>Post an Ad</button>
//                 </Link>
//                 <Link
//                   to="/home/dashboard"
//                   style={{ textDecoration: "none", width: "50%" }}
//                 >
//                   <button style={_x.continue2}>Go to Dashboard</button>
//                 </Link>
//               </article>
//             </div>
//           </div>
//         </section>
//       )}
//       <Spinner isloading={isloading} />
//     </main>
//   );
// };

// export default NewPartsDealerStepsForm;

// const _x = {
//   btn: {
//     background: "transparent",
//     height: 30,
//     width: 20,
//     fontSize: 22,
//     color: "grey",
//     borderRadius: 0,
//     padding: 0,
//     margin: 0,
//   },
//   title_sect: {
//     marginBottom: "53px",
//     textAlign: "center",
//   },

//   header: {
//     fontWeight: "bold",
//     fontSize: 15,
//   },
//   header2: {
//     fontWeight: "normal",
//     fontSize: "14px",
//     marginBottom: "2px",
//     color: "#222222",
//   },
//   bottom: {
//     display: "flex",
//     flexDirection: "row",
//   },
//   btn2: {
//     color: "var(--primary1)",
//     fontFamily: "montserrat",
//     paddingTop: "10px",
//   },
//   modall: {
//     background: "white",
//     width: 391,
//     height: 236,
//     outline: "none",
//     borderRadius: 6,
//     margin: "170px auto",
//     display: "grid",
//     placeItems: "center",
//     padding: "20px 15px",
//     position: "absolute",
//     top: "20%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     boxShadow: "1px 1px 18px #00000040",
//   },

//   continue: {
//     height: 40,
//     backgroundColor: "var(--primary2)",
//     // paddingLeft: 15,
//     // paddingRight: 15,
//     color: "#fff",
//     borderRadius: 6,
//     fontWeight: "bold",
//     fontSize: 16,
//     marginBottom: "1.2rem",
//     width: "95%",
//   },
//   continue2: {
//     height: 40,
//     backgroundColor: "#fff",
//     // paddingLeft: 15,
//     // paddingRight: 15,
//     border: "1px solid var(--primary2)",
//     color: "var(--primary2)",
//     borderRadius: 6,
//     fontWeight: "bold",
//     fontSize: 16,
//     marginBottom: "1.2rem",
//     width: "95%",
//   },

//   close: {
//     cursor: "pointer",
//   },
//   modal_bg: {
//     position: "fixed",
//     top: "0",
//     left: "0",
//     height: "100vh",
//     width: "100%",
//     backgroundColor: "#00000090",
//     overflow: "hidden",
//   },
// };

import "./NewPartsDealerStepsForm.scss";
import SparPartDealerForm from "./SparPartDealerForm";

export default function NewPartsDealerStepsForm() {
	return (
		<div className="NewPartsDealerStepsForm-wrapper">
			<div className="NewPartsDealerStepsForm-inner">
				<div className="form-wrap-part-dealer">
					<div className="inner-shadow-header ">
						<span>Spare Parts Details</span>
					</div>
					<div className="form-body-part-dealer">
						<SparPartDealerForm />
					</div>
				</div>
			</div>
		</div>
	);
}
