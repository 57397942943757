import { Modal } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function ModalComponent({ setModalState, modalState }) {
  const handleOpen = () => {
    setModalState(true);
  };
  const handleClose = () => {
    setModalState(false);
  };
  return (
    <Modal
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      open={modalState}
    >
      <div style={{ border: "none" }}>
        <div style={_x.modall}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          ></div>

          <h5
            style={{
              fontFamily: "montserrat",
              fontSize: "16px",
              textAlign: "center",
              fontWeight: "500",
              margin: 0,
            }}
          >
            Congratulations
          </h5>

          <p
            style={{
              fontFamily: "montserrat",
              fontSize: "14px",
              textAlign: "center",
              width: "70%",
            }}
          >
            You have successfully posted an ad
          </p>
          <article
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <button onClick={handleClose} style={_x.continue}>
              Post another Ad
            </button>
            <Link
              to="/home/dashboard"
              style={{ textDecoration: "none", width: "95%" }}
            >
              <button style={_x.continue2}>Go to Dashboard</button>
            </Link>
          </article>
        </div>
      </div>
    </Modal>
  );
}

export default ModalComponent;

const _x = {
  btn: {
    background: "transparent",
    height: 30,
    width: 20,
    fontSize: 22,
    color: "grey",
    borderRadius: 0,
    padding: 0,
    margin: 0,
  },
  title_sect: {
    marginTop: 5,
    // marginBottom: '48px',
    textAlign: "center",
  },

  header: {
    fontWeight: "bold",
    fontSize: 15,
  },
  header2: {
    fontWeight: "normal",
    fontSize: "14px",
    marginBottom: "2px",
    color: "#222222",
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
  },
  btn2: {
    color: "var(--primary1)",
    fontFamily: "montserrat",
    paddingTop: "10px",
  },
  modall: {
    background: "white",
    width: 391,
    height: 236,
    outline: "none",
    borderRadius: 6,
    margin: "170px auto",
    display: "grid",
    placeItems: "center",
    padding: "20px 15px",
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "1px 1px 18px #00000040",
  },
  modal2: {
    background: "white",
    width: 350,
    height: 150,
    outline: "none",
    borderRadius: 6,
    margin: "170px auto",
    display: "grid",
    placeItems: "center",
    padding: 10,
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "1px 1px 18px #00000040",
  },

  continue: {
    height: 40,
    backgroundColor: "var(--primary2)",
    // paddingLeft: 15,
    // paddingRight: 15,
    color: "#fff",
    borderRadius: 6,
    fontWeight: "bold",
    fontSize: 16,
    width: "90%",
    marginRight: "10px",
  },
  continue2: {
    height: 40,
    backgroundColor: "#fff",
    // paddingLeft: 15,
    // paddingRight: 15,
    border: "1px solid var(--primary2)",
    color: "var(--primary2)",
    borderRadius: 6,
    fontWeight: "bold",
    fontSize: 16,
    width: "90%",
  },

  close: {
    cursor: "pointer",
  },
  modal_bg: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100%",
    backgroundColor: "#00000090",
    overflow: "hidden",
  },
};
