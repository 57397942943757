/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import Switch from "src/Components/Functional/Switch/Switch";
import mechService from "src/services/mech.service";

const ShopCalendar = () => {
	const [schedule, setschedule] = useState();
	const [minloader, setminloader] = useState(false);
	const [chosenday, setchosenday] = useState();
	const [working_hours_resp, setworking_hours_resp] = useState({});
	const weekmap = {
		Sun: "Sunday",
		Mon: "Monday",
		Tue: "Tuesday",
		Wed: "Wednesday",
		Thu: "Thursday",
		Fri: "Friday",
		Sat: "Saturday",
	};

	useEffect(() => {
		initScheduler();
	}, []);

	async function initScheduler() {
		setminloader(true);

		const resp = await mechService.getMechanic();

		if (resp.success) {
			setworking_hours_resp(resp.data.mechanic.working_hours);

			setchosenday(Object.keys(resp.data.mechanic.working_hours)[0]);

			const _schedule = resp.data.mechanic.schedule_data;

			if (_schedule) {
				setschedule(_schedule);

				// console.log("recieved: ", _schedule);
			} else {
				loadSchedulesForChosenDay(resp.data.mechanic.working_hours);
			}
		}

		setminloader(false);
	}

	function loadSchedulesForChosenDay(resp) {
		let init_to,
			init_from,
			full_listish = {};

		Object.keys(resp).map((shisui, _) => {
			Object.keys(resp[shisui]).map(() => {
				init_to = resp[shisui]["to"];
				init_from = resp[shisui]["from"];
			});

			let from = _24hourClock(parseInt(init_from.hour), init_from.meridian);

			let to = _24hourClock(parseInt(init_to.hour), init_to.meridian);

			Object.assign(full_listish, generateTimeSlots(shisui, from, to, "AM"));
		});

		setschedule(full_listish);
	}

	function _24hourClock(val, mer) {
		return mer === "AM" ? val : val + 12;
	}

	function inverseMeridian(meridian) {
		return meridian === "PM" ? "AM" : "PM";
	}

	function generateTimeSlots(dayOfWeek, from, to, initMeridian) {
		let _timesish = {};

		for (let hr = from; hr < to + 1; hr++) {
			let _timeframe = `${hr < 13 ? hr : hr - 12}${
				hr >= 12 ? inverseMeridian(initMeridian) : initMeridian
			}`;

			let _timeframePlus = `${1 + (hr < 13 ? hr : hr - 12)}${
				hr >= 12 ? inverseMeridian(initMeridian) : initMeridian
			}`;

			_timesish[`${_timeframe} - ${_timeframePlus}`] = true;
		}

		console.log(_timesish);

		return { [`${dayOfWeek}`]: _timesish };
	}

	function onCalendarChange(val, e) {
		setchosenday(weekmap[val.toString().substring(0, 3)]);
	}

	async function adjustSchedule(e) {
		setschedule({
			...schedule,
			[`${chosenday}`]: {
				...schedule[chosenday],
				[`${e.target.id}`]: !schedule[chosenday][e.target.id],
			},
		});

		let _schedule_tachyon = schedule;

		_schedule_tachyon[chosenday][e.target.id] =
			!_schedule_tachyon[chosenday][e.target.id];

		const schedule_rep_body = collateAdjustedSchedules(e.target.id);

		const resp = await mechService.adjustSchedule(
			schedule_rep_body,
			_schedule_tachyon
		);

		if (resp.success) {
			// reviseResp(resp.data);
			// initScheduler();
		}
	}

	function getWeekDay(day) {
		return weekmap[Object.keys(weekmap)[day]];
	}

	function collateAdjustedSchedules(value) {
		let adjusted_day = [];
		let final = {};

		let split = value.split(" ", 1);

		adjusted_day.push({
			hour: split.toString().substring(0, split.toString().length - 2),
			meridian: value.slice(-2),
			isActive: `${schedule[chosenday][value]}`,
		});
		final[chosenday] = adjusted_day;

		return final;
	}

	return (
		<div style={_x.schedule}>
			<div style={_x.header}>Set Schedule</div>

			{minloader ? (
				<Spinner
					fullscreen={false}
					type="scale"
					isloading={minloader}
					size={100}
				/>
			) : (
				<>
					<div style={_x.left}>
						<Calendar
							className="calendar"
							minDate={new Date()}
							minDetail="month"
							onChange={(val, e) => onCalendarChange(val, e)}
							tileDisabled={({ date }) => {
								return !working_hours_resp?.hasOwnProperty(
									getWeekDay(date.getDay())
								);
							}}
						/>
					</div>

					<div style={_x.right}>
						{chosenday !== undefined &&
							working_hours_resp !== undefined &&
							Object.keys(schedule[chosenday]).map((key, _) => (
								<div key={key} style={_x.switch_row}>
									<div style={_x.time}>{key}</div>

									<Switch
										id={key}
										value={schedule[chosenday][key]}
										onSwitch={adjustSchedule}
									/>
								</div>
							))}
					</div>
				</>
			)}
		</div>
	);
};

export default ShopCalendar;

const _x = {
	schedule: {
		display: "grid",
		gridTemplateRows: "auto 1fr 1fr",
		width: "100%",
		position: "relative",
		gridGap: 10,
		background: "#ededed",
		// minWidth: 952,
	},

	header: {
		textAlign: "center",
		height: 50,
		background: "white",
		display: "grid",
		placeItems: "center",
		borderRadius: 10,
		fontWeight: 500,
		border: "1px solid #dedddd",
		minWidth: "100%",
	},

	left: {
		// width: 100,
		// height: 100,
		borderRadius: 10,
		// border: "5px solid #ededed",
		// borderBottomLeftRadius: 0,
		// borderBottomRightRadius: 0,
		// borderBottomWidth: 10,
		padding: 15,
		background: "white",
		border: "1px solid #dedddd",
	},

	right: {
		display: "grid",
		gridTemplateColumns: "repeat(4, 1fr)",
		// boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0.15)",
		padding: 20,
		borderRadius: 10,
		placeItems: "center",

		// border: "5px solid #ededed",
		// borderTopLeftRadius: 0,
		// borderTopRightRadius: 0,
		// borderTop: 10,
		background: "white",
		border: "1px solid #dedddd",
	},

	switch_row: {
		display: "flex",
		justifyContent: "space-around",
		marginBottom: 20,
		alignItems: "center",
		width: 100,
	},

	time: {
		fontSize: 10,
		fontWeight: 500,

		// background: "red",
	},
};
