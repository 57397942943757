import { useHistory, useLocation } from "react-router-dom";
import freeAdIcon from "../../../Resources/Icons/freead.png";
import AuthService from "../../../services/auth.service";
import "./float.css";

const FloatingAdButton = () => {
	const currentUser = AuthService.getCurrentUser("user");

	let history = useHistory();
	const location = useLocation();

	const loadAd = () => {
		if (currentUser === null) {
			history.push("/auth/login");
		} else {
			const userType = currentUser.data.user_info.permissions.map((role) => {
				return role;
			});
			const dealer = userType.includes("part_dealer");
			const mech = userType.includes("mechanic");
			let user_id = `${currentUser.data.user_info.id}`;

			if (dealer) {
				history.push("/home/submit-an-ad/");
			} else if (!dealer) {
				history.push("/home/become-a-dealer/");
			} else if (mech) {
				history.push("/home/become-a-dealer/");
			} else {
				history.push("/auth/login");
			}
		}
	};

	return (
		<div onClick={loadAd} className="floating-btn">
			<img src={freeAdIcon} />
			Post Your Free Ad
			{/* <div
				style={{
					height: 65,
					width: 65,
					borderRadius: 45,
					border: "1px solid white",
					// display: "grid",
					// placeItems: "center",
					// fontSize: 20,
					display: "flex",
				}}
			>
				Post Your Free Ad
			</div> */}
			<span className="tooltip">Click to Advertise your Product</span>
		</div>
	);
};

export default FloatingAdButton;
