import { Button } from "@mui/material";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import { confirmPayment } from "src/services/job/job.service";
import { ThreeCircles } from "react-loader-spinner";
import { useState } from "react";

const CheckoutForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [progress, setProgress] = useState();

  const getConfirmPaymentResponse = async () => {
    try {
      const result = await confirmPayment(clientSecret);
      if (result.status === 200 || result.status === 201) {
        history.push({
          path: "/home/payment-success",
        });
        return;
      }
      history.push({
        path: "/home/payment-decline",
      });
      console.log(
        "==========ERROR FROM DECLINED STRIPE PAYMENT===========",
        result.data
      );
      return;
    } catch (error) {
      console.error(
        "==========ERROR FROM DECLINED STRIPE PAYMENT===========",
        error.message
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProgress(true);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_HOST_NAME}/home/payment-success`,
      },
      // redirect: "if_required",
    });

    if (result.error) {
      setProgress(false);
      // Show error to your customer (for example, payment details incomplete)
      history.push({
        path: "/home/payment-decline",
        state: { error_message: result.error.message },
      });
      console.log(result.error.message);
    }
    setProgress(false);

    // else {
    //   // Your customer will be redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer will be redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
    //   getConfirmPaymentResponse();
    // }
  };

  return (
    <form>
      <PaymentElement />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          onClick={handleSubmit}
          sx={{
            width: "80%",
            backgroundColor: "#587FC0",
            color: "#fff",
            "&:hover": {
              border: "1px solid #587FC0",
              backgroundColor: "#587FC0",
              color: "#fff",
            },
          }}
          disabled={!stripe}
        >
          {progress ? <SmallLoader /> : "Submit"}
        </Button>
      </div>
    </form>
  );
};
const SmallLoader = () => (
  <ThreeCircles
    height="20"
    width="20"
    color="#fff"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel="three-circles-rotating"
    outerCircleColor=""
    innerCircleColor=""
    middleCircleColor=""
  />
);

export default CheckoutForm;
