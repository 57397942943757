/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import Emitter from "../services/emitter";
import { authHeader } from "./auth-header";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: // "https://api.dev.qm.quickmechs.com/api/v1/"
		  REACT_APP_PRODUCTION_URL;

const API_URL_V2 =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;
// "https://backend.quickmechs.com/api/v2/";

const ROUTES = {
	review: "review/",
	mechanic: "mechanic/",
	user: "users/",
	feedback: "feedback/",
};

const ENDPOINT = {
	getLocation: "get-location",
	filter: "filter-services",
	getReviews: "reviews/",
	appointment: "book-appointment",
	createReview: "rate-mechanic",
	current: "current-user?",
	edit: "edit-schedule",
	getSchedule: "get-work-schedule/",
	create: "create",
	cancel: "cancel-appointment",
	shops: "shops",
};

const FULL_DETAILS = "fullDetails=true";

const getToken = () => {
	return localStorage.getItem("user");
};

function filterServices(
	skill,
	specialization,
	location,
	vehicletype,
	time_box,
	pageNum,
	lat,
	long
) {
	return axios
		.get(
			API_URL_V2 +
				ROUTES.mechanic +
				ENDPOINT.filter +
				`?location=${location}
					&specialization=${specialization}
					&vehicle_type=${vehicletype}
					&professional_skill=${skill}
					&working_hours=${time_box ?? ""}
					&page=${pageNum}
					&instant_service=no
					&mobile_service=no
					&long=${long}&
					&lat=${lat}
				`,

			{ headers: authHeader() }
		)
		.then((res) => {
			return {
				absolute: res.data,
				success: true,
				data: res.data.data,
				meta: res.data.meta,
			};
		})
		.catch((e) => {
			console.log(e);

			return { success: false };
		});
}

function getAlternateMechanics(
	skill,
	specialization,
	location,
	vehicletype,
	time_box,
	pageNum
) {
	return axios
		.get(
			API_URL_V2 +
				ROUTES.mechanic +
				ENDPOINT.filter +
				"/relative" +
				`?locationx=${location}
					&specialization=${specialization}
					&vehicle_type=${vehicletype}
					&professional_skill=${skill}
					&working_hours=${time_box ?? ""}
					&page=${pageNum}
				`,
			null,
			{}
		)
		.then((res) => {
			return {
				absolute: res.data,
				success: true,
				data: res.data.data,
				meta: res.data.meta,
			};
		})
		.catch((e) => {
			console.log(e);

			return { success: false };
		});
}

function locationSuggestions(query) {
	return axios
		.get(API_URL + ENDPOINT.getLocation + `?q=${query}`)
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			console.log(e);

			return { success: false };
		});
}

function getReviews(mechId) {
	return axios
		.get(API_URL + ROUTES.review + ENDPOINT.getReviews + mechId, {
			headers: authHeader(),
		})
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			if (e.response?.status === 419) {
				Emitter.emit("token expired");

				// return authService
				// 	.refreshTokens()
				// 	.then(() => {
				// 		return getReviews(mechId);
				// 	})
				// 	.catch(() => {
				// 		Emitter.emit("token refresh failed");
				// 	});
			}

			return { success: false };
		});
}

function getMechShops() {
	return axios
		.get(API_URL + ROUTES.user + ENDPOINT.shops, {
			headers: authHeader(),
		})
		.then((res) => {
			console.log(`🚀~Dashboard.js:89~mechService`, res);

			return {
				success: true,
				data: res.data,
			};
		})
		.catch((e) => {
			if (e.response?.status === 419) {
				Emitter.emit("token expired");
			}

			return { success: false };
		});
}

function bookAppointment(
	mechId,
	date,
	time,
	description,
	meridian,
	category,
	sub_category,
	vehicle_type
) {
	console.log(
		mechId,
		date,
		time,
		meridian,
		category,
		sub_category,
		vehicle_type
	);

	return axios
		.post(
			API_URL + ENDPOINT.appointment,
			{
				mechanic_id: mechId,
				date,
				time,
				description,
				meridian,
				category,
				sub_category,
				vehicle_type,
			},
			{ headers: authHeader() }
		)
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			console.log(e);

			return {
				success: false,
				errorResponse: e.response,
				errorSource: e.response.data.errorSource,
			};
		});
}

function reviewMechanic(
	mechanicId,
	overall_rating,
	headline,
	written_review,
	experience,
	response_to_time,
	professionalism,
	public_name,
	photos
) {
	return axios
		.post(
			API_URL + ROUTES.review + ENDPOINT.createReview + "/" + mechanicId,
			{
				overall_rating: overall_rating,
				headline: headline,
				written_review: written_review,
				experience: experience,
				response_to_time: response_to_time,
				professionalism: professionalism,
				display_name: public_name,
				review_photo: photos,
			},
			{ headers: authHeader() }
		)
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			if (e.response?.status === 419) {
				Emitter.emit("token expired");

				// return authService
				// 	.refreshTokens()
				// 	.then(() => {
				// 		return reviewMechanic(
				// 			mechanicId,
				// 			overall_rating,
				// 			headline,
				// 			written_review,
				// 			experience,
				// 			response_to_time,
				// 			professionalism,
				// 			public_name,
				// 			photos
				// 		);
				// 	})
				// 	.catch(() => {
				// 		Emitter.emit("token refresh failed");
				// 	});
			}

			console.log(e);
			return { success: false };
		});
}

function getMechanic() {
	const user = JSON.parse(localStorage.getItem("user"));
	// console.log(
	// 	"🚀 ~ file: mech.service.js:285 ~ getMechanic ~ user:",
	// 	user.data.user_info.id
	// );

	return axios
		.get(
			API_URL + ROUTES.mechanic + `${user.data.user_info.id}?` + FULL_DETAILS,
			{
				headers: authHeader(),
			}
		)
		.then((res) => {
			// console.log("🚀 ~ file: mech.service.js:289 ~ .then ~ res:", res);

			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			// console.log(e.response);

			if (e.response?.status === 419) {
				Emitter.emit("token expired");

				// return authService
				// 	.refreshTokens()
				// 	.then(() => {
				// 		return getMechanic();
				// 	})
				// 	.catch(() => {
				// 		Emitter.emit("token refresh failed");
				// 	});
			}

			return { success: false };
		});
}

function getMechanicShop(mechId) {
	return axios
		.get(API_URL + ROUTES.mechanic + `${mechId}?` + FULL_DETAILS, {
			headers: authHeader(),
		})
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			// console.log(e.response);

			if (e.response?.status === 419) {
				Emitter.emit("token expired");

				// return authService
				// 	.refreshTokens()
				// 	.then(() => {
				// 		return getMechanic();
				// 	})
				// 	.catch(() => {
				// 		Emitter.emit("token refresh failed");
				// 	});
			}

			return { success: false };
		});
}

function adjustSchedule(schedule_req, schedule) {
	console.log("sent: ", schedule);

	return axios
		.patch(
			API_URL + ROUTES.mechanic + ENDPOINT.edit,
			{
				schedule: schedule_req,
				schedule_data: schedule,
			},
			{ headers: authHeader() }
		)
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			if (e.response?.status === 419) {
				Emitter.emit("token expired");

				// return authService
				// 	.refreshTokens()
				// 	.then(() => {
				// 		return adjustSchedule(schedule_req, schedule);
				// 	})
				// 	.catch(() => {
				// 		Emitter.emit("token refresh failed");
				// 	});
			}

			console.log(e);
			return { success: false };
		});
}

function getMechanicSchedule(mechId) {
	return axios.get(API_URL + ROUTES.mechanic + ENDPOINT.getSchedule + mechId, {
		headers: authHeader(),
	});
}

function getMechStoreNames(query) {
	return axios.post("url");
}

function submitFeedback(
	satisfaction,
	recommendation,
	comment,
	email,
	category
) {
	return axios
		.post(API_URL + ROUTES.feedback + ENDPOINT.create, {
			satisfaction,
			recommendation,
			comment,
			email,
			category,
		})
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			console.log(e);
			return { success: false };
		});
}

function findHelpful(reviewId) {
	return axios
		.post(API_URL + ROUTES.review + reviewId + "/helpful", null, {
			headers: authHeader(),
		})
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			console.log(e);
			return { success: false };
		});
}

function cancelAppointment(appointmentId, visitorId) {
	return axios
		.get(API_URL + ENDPOINT.cancel + `/${appointmentId}/${visitorId}`)
		.then((res) => {
			return {
				success: true,
			};
		})
		.catch((e) => {
			console.log(e);
			return { success: false };
		});
}

function toggleInstantService(shopId, value) {
	return axios
		.patch(
			API_URL + `mechanic/${shopId}/update/instant-service`,
			{
				instant_service: value ? "yes" : "no",
			},
			{
				headers: authHeader(),
			}
		)
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			console.log(e);

			return { success: false };
		});
}

function toggleMobileService(shopId, value) {
	return axios
		.patch(
			API_URL + `mechanic/${shopId}/update/mobile-service`,
			{
				mobile_service: value ? "yes" : "no",
			},
			{
				headers: authHeader(),
			}
		)
		.then((res) => {
			return {
				success: true,
				data: res.data.data,
			};
		})
		.catch((e) => {
			console.log(e);

			return { success: false };
		});
}

export async function geocodeFromAdress(address) {
	const response = await fetch(
		`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
			address
		)}&key=AIzaSyC8MxZB_qWnOrh1E4RnSE4rqDuMsCGIAek`
	);
	const data = await response.json();
	if (data.status === "OK") {
		const { lat, lng } = data.results[0].geometry.location;
		return { lat, lng };
	} else {
		throw new Error(`Failed to geocode address: ${address}`);
	}
}

export default {
	getAlternateMechanics,
	filterServices,
	locationSuggestions,
	getReviews,
	bookAppointment,
	reviewMechanic,
	getMechanic,
	adjustSchedule,
	getMechanicSchedule,
	submitFeedback,
	findHelpful,
	cancelAppointment,
	getMechanicShop,
	getMechStoreNames,
	getMechShops,
	toggleInstantService,
	toggleMobileService,
};
