import React from "react";
import { Link } from "react-router-dom";
import PhotoBooth from "src/Components/Functional/PhotoBooth/Photobooth";
import { Spacing } from "src/Components/Style/Styles";

export default function UploadSection({
	title,
	routeRef,
	uploadImage,
	form,
	_mobile,
	_form_theme,
	setform,
	simpleValidator,
	reorderImgList,
}) {
	const _y = {
		hyperlink: {
			color: _form_theme,
			cursor: "pointer",
			width: "100%",
			marginLeft: 5,
			marginRight: 5,
		},
	};

	// {
	// 	console.log(form.photos);
	// }

	return (
		<>
			{!_mobile ? (
				<>
					<Spacing />
					<PhotoBooth
						routeRef={routeRef}
						onChange={uploadImage}
						photos={form.photos}
						multiple
						reorderImgList={reorderImgList}
					/>
				</>
			) : (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						width: "88vw",
					}}
				>
					{title}

					<PhotoBooth
						routeRef={routeRef}
						onChange={uploadImage}
						photos={form.photos}
						multiple
						reorderImgList={reorderImgList}
					/>
				</div>
			)}

			<Spacing />

			<div style={_x(_mobile).checkbox_container}>
				<input
					checked={form.terms}
					style={_x().checkbox}
					type="checkbox"
					onChange={() => setform({ ...form, terms: !form.terms })}
					onBlur={simpleValidator.current.showMessageFor("Terms")}
				/>

				<div>
					<p>
						By ticking this you agree to our
						<Link
							to="/home/privacy-policy/"
							target="_blank"
							style={_y.hyperlink}
						>
							Privacy Policy
						</Link>
						and
						<Link to="/home/terms-of-use/" target="_blank" style={_y.hyperlink}>
							Terms and Conditions
						</Link>
					</p>
				</div>
			</div>

			<div
				style={{
					visibility: form.showfielderror ? "visible" : "hidden",
				}}
			>
				{simpleValidator.current.message(
					"Terms",
					form.terms,
					"required|accepted",
					{
						element: (msg) => (
							<div style={_x().error}>
								terms and conditions must be accepted to register !
							</div>
						),
					}
				)}
			</div>
		</>
	);
}

const _x = (_mobile) => ({
	error: {
		color: "red",
		fontSize: 10,
	},

	sectioned_view: {
		height: "100%",
		overflow: "scroll",
		width: "100%",
		paddingBottom: 110,
	},

	header: {
		fontSize: 15,
		fontWeight: 550,
		textAlign: "center",
		padding: 5,
		borderTopRightRadius: _mobile ? 0 : 10,
		borderTopLeftRadius: _mobile ? 0 : 10,
		color: "white",
		boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
	},

	header_txt1: {
		fontWeight: 500,
		fontSize: 20,
		marginBottom: 15,
		letterSpacing: 1.5,
	},

	header_txt2: {
		fontSize: 13,
		letterSpacing: 1.5,
	},

	checkbox_container: {
		display: "flex",
		alignItems: "center",
	},

	checkbox: {
		height: 15,
		width: 15,
		marginRight: 10,
		cursor: "pointer",
	},
});
