import React, { useEffect, useState } from 'react';
import Alternator from '../../../Resources/Images/alternator.png';
import { FaHeart } from 'react-icons/fa';
import { RatingStar } from 'rating-star';
import axios from 'axios';
import { authHeader } from 'src/services/auth-header';
import Spinner from 'src/Components/Functional/Spinner/Spinner';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const truncateString = (string = '', maxLength = 50) =>
  string?.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

const Parts = ({ data }) => {
  return (
    <div className="mech-saved-wrap">
      <div className="mech-image-saved">
        <img src={data?.mechanic?.company_photo} alt="product" />
      </div>
      <div>
        <p className="like-saved">
          <span>
            <FaHeart color="red" size={12} />
          </span>
          Saved
        </p>
        <p className="like-mech-title">{data?.mechanic?.shop_name}</p>
        <p className="like-code">{data?.mechanic?.office_address}</p>
        <p className="like-address mech-address">
          {truncateString(data.mechanic.shop_description, 80)}
        </p>
        <p className="like-mech-rating">
          <RatingStar
            rating={data?.mechanic?.customer_reviews?.average_overall_rating}
            noBorder
            size={14}
            colors={{ mask: '#FBB500', rear: '#c4c4c4' }}
          />
          {data?.mechanic?.customer_reviews?.total_rating && (
            <span>
              {data?.mechanic?.customer_reviews?.average_overall_rating} (
              {data?.mechanic?.customer_reviews?.total_rating} Reviews)
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

const MechItems = () => {
  const [favouritesList, setfavouritesList] = useState([]);
  const [isloading, setisloading] = useState(false);

  const favorites = () => {
    setisloading(true);
    axios
      .get(API_URL + `mechanic/me/favourite`, {
        headers: authHeader(),
      })
      .then((res) => {
        setfavouritesList(res.data.data);
        setisloading(false);
      })
      .catch((err) => setisloading(false));
  };

  useEffect(() => {
    favorites();
  }, []);
  return (
    <section className="main-coll">
      {isloading ? (
        <>
          {' '}
          <Spinner
            fullscreen={false}
            type="puff"
            isloading={isloading}
            size={100}
          />
        </>
      ) : (
        <>
          <h2
            style={{
              marginTop: '0px',
              fontSize: '18px',
              fontWeight: '400',
              color: '#000000',
            }}
          >
            Your Saved Items
          </h2>
          <p style={{ fontSize: '16px' }}>Service Providers</p>
          {favouritesList.length === 0 ? (
            <p>You currently do not have any saved Service Provider</p>
          ) : (
            <div>
              {favouritesList.map((item) => (
                <Parts data={item} />
              ))}
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default MechItems;
