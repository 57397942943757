import { Modal, Paper, useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaHeart, FaRegHeart, FaStar } from "react-icons/fa";
import { MdPhoneInTalk } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import AuthService from "src/services/auth.service";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import SparePart from "../../../Components/Functional/SparePart/SparePart";
import { Spacing } from "../../../Components/Style/Styles";
import alternator2 from "../../../Resources/Images/alternator2.png";
// import { FaShareAlt } from "react-icons/fs";
import moment from "moment";
import { RatingStar } from "rating-star";
import { Helmet } from "react-helmet";
import { GrFormClose } from "react-icons/gr";
import LoadingBar from "react-top-loading-bar";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Popup from "src/Components/Functional/Popup/Popup";
import SparePartsSingle from "src/Components/Functional/SparePartBanner/SparePartsSingle";
import LoginPopUp from "src/Components/Functional/loginpop/LoginPopUp";
import ShareBtns from "src/Components/Functional/share-buttons/ShareBtns";
import { authHeader } from "src/services/auth-header";
import productService from "src/services/product.service";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import "./showcase.css";
import "./styles.css";

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

function SparePartShowCase() {
  SwiperCore.use([Pagination, Navigation]);

  const _mobile = useMediaQuery("(max-width: 800px)");
  const { id } = useParams();
  let history = useHistory();
  const allProducts =
    API_URL + "products/find/" + `${id}` + "?fullDetails=true";

  const currentUser = AuthService.getCurrentUser("user");

  const [ispage, setispage] = useState(false);
  const [info, setInfo] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [mainImage, setMainImage] = useState("");
  const [visibility, setVisibility] = useState(true);
  const [prImages, setPrImages] = useState([]);
  const [related, setRelated] = useState([]);
  const [mechanic, setMechanic] = useState([]);
  const [parts, setParts] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [shop,setShop]=useState()
  const [pix, setPix] = useState([]);
  const [rev, setRev] = useState("");
  const [revealPhone, setRevealPhone] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loginpopup, setloginpopup] = useState(false);
  const [viewerEmail, setViewerEmail] = useState("");
  const [successPopup, setSuccessPopup] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [revealShareBtns, setrevealShareBtns] = useState(false);
  const [isLiked, setisLiked] = useState(false);

  const showPhone = () => {
    setRevealPhone(!revealPhone);
  };

  const getData = async () => {
    setisloading(true);
    setProgress(93);

    axios
      .get(allProducts, { headers: authHeader() })
      .then((response) => {
        const productData = response.data.data;
        console.log(
          productData,
          "+==================== SHOW PRODUCT ================="
        );
        console.log(
          response.data.data.created_by.part_dealer,
          "+==================== SHOW partDealer details ================="
        );
        const imgArr = response.data.data.product_photo;
        const mechDetails = response.data.data.created_by.mechanic;
        const partsDetails = response.data.data.created_by.part_dealer;
        const userDetails = response.data.data.created_by.user_info;
        const prImage = response.data.data.product_photo[0];
        const relatedProduct = productData.related_products;
        setisloading(false);
        setShop(partsDetails)
        setProgress(100);
        setInfo(productData);
        setRev(productData.customer_reviews);
        setPrImages(imgArr);
        setPix(prImage);
        setRelated(relatedProduct);
        setMechanic(mechDetails);
        setUserInfo(productData?.created_by?.user_info);
        setParts(productData);
        setisLiked(productData?.liked);
        localStorage.setItem("shop", partsDetails.id);
        console.log("related", productData);
      })
      .catch((err) => {
        setisloading(false);
      });

    // console.log(related);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 768, itemsToShow: 2, itemsToScroll: 2 },
    { width: 1200, itemsToShow: 4 },
  ];

  const loadThumbnail = (e) => {
    let v = e.target.value;
    setMainImage(alternator2);
    console.log(v);
  };

  useEffect(() => {
    getData();
    window.scroll(0, 0);
  }, [allProducts]);

  function partTapped(info) {
    setispage(true);
  }

  const confirmLogin1 = () => {
    if (currentUser) {
      history.push(
        "/home/spare-part/" +
          `${info.product_title}` +
          "/product-review=" +
          `${id}`
      );
    } else {
      setloginpopup(true);
    }
  };

  const confirmLogin = () => {
    if (currentUser) {
      setRevealPhone(true);
    } else {
      setloginpopup(true);
    }
  };

  const report_Abuse = () => {
    if (currentUser) {
      history.push("/home/product/" + `${id}` + "/report-abuse");
    } else {
      setloginpopup(true);
    }
  };

  function onLogin(status) {
    if (status === "success") {
      setloginpopup(false);
    }
  }

  function shareProduct() {
    if (viewerEmail !== "") {
      setIsSharing(true);
      productService.shareProduct(viewerEmail, id).then((res) => {
        setIsSharing(false);
        setSuccessPopup(true);
      });
    }
  }

  const likeProduct = () => {
    axios
      .post(API_URL + `products/${id}/like`, "body", { headers: authHeader() })
      .then((res) => setisLiked(true))
      .catch((err) => console.log(err));
  };
  const unlikeProduct = () => {
    axios
      .post(API_URL + `products/${id}/unlike`, "body", {
        headers: authHeader(),
      })
      .then((res) => setisLiked(false))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log(
      parts,
      "================================================PARTS================="
    );
  }, []);

  return (
    <div style={_x(_mobile).spare_parts} className="main_showcase_wrap">
      <div style={_x(_mobile).header}>
        <div style={_x(_mobile).search_box}>
          <SparePartsSingle />
        </div>
      </div>

      <div style={_x(_mobile).main}>
        <div style={_x(_mobile).right}>
          {isloading ? (
            <Spinner fullscreen={true} isloading={isloading} size={100} />
          ) : (
            <Paper
              key={info.id}
              style={{
                height: _mobile && "auto",
                width: "90%",
                maxWidth: _mobile ? "100%" : 1180,

                display: "flex",
                padding: _mobile ? 0 : 25,
                flexDirection: _mobile ? "column" : "row",
                justifyContent: "center",
                marginTop: _mobile ? "1.5rem" : "2rem",
                boxShadow: _mobile ? "none" : "0px 0px 8px #ddd",
              }}
            >
              <div
                style={{
                  // width: '100%',
                  paddingLeft: _mobile ? "0" : "3rem",
                }}
              >
                <div
                  className="big-pr-photo-wrapper"
                  style={{
                    // height: '400px',
                    width: _mobile ? "100%" : "500px",

                    marginLeft: _mobile ? "0px" : "0px",
                    // marginTop: _mobile ? '-300px' : '0px',

                    display: "grid",
                    placeItems: "center",
                    // border: "0.5px solid var(--secondary3)",
                  }}
                >
                  <img
                    className="big-pr-photo"
                    // src={info.product_photo[0].encoded}
                    src={visibility ? pix : mainImage}
                    style={{
                      // height: '100%',
                      width: "100%",
                      objectFit: "cover",
                      // backgroundSize: 'contain',
                    }}
                    alt="full spare part"
                  />
                </div>
                <Spacing />
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(5,1fr)",
                        placeItems: "center",
                        gap: "15px",

                        marginRight: _mobile ? "200px" : "0",
                      }}
                    >
                      {prImages.map((thumbnail, index) => {
                        return (
                          <div
                            style={_x(_mobile).sub}
                            key={index}
                            className="thumbnails"
                          >
                            <img
                              alt={info.product_title}
                              src={thumbnail}
                              onMouseEnter={() => {
                                setVisibility(false);
                                setMainImage(thumbnail);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </SRLWrapper>
                </SimpleReactLightbox>

                <div
                  style={{
                    marginLeft: _mobile ? "0" : "0px",
                    maxWidth: _mobile ? "100%" : "400px",
                  }}
                >
                  <p
                    style={{
                      fontSize: _mobile ? 13 : 16,
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Product Name : {info.product_title}
                  </p>

                  <p
                    style={{ fontSize: _mobile ? 13 : 14, fontWeight: "bold" }}
                  >
                    Product Number : {info.product_no}
                  </p>

                  <p
                    style={{ fontSize: _mobile ? 13 : 14, fontWeight: "bold" }}
                  >
                    Product Condition : {info.condition}
                  </p>
                  <p
                    style={{ fontSize: _mobile ? 13 : 14, fontWeight: "bold" }}
                  >
                    Product Make : {info.make}
                  </p>
                  <p
                    style={{ fontSize: _mobile ? 13 : 14, fontWeight: "bold" }}
                  >
                    Product Year : {info.year}
                  </p>

                  <p
                    style={{
                      fontSize: _mobile ? 13 : 13,
                      overflowWrap: _mobile ? "break-word" : "none",
                      width: _mobile ? "100%" : "100%",
                    }}
                  >
                    {info.description}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: _mobile ? "-10px" : "0px",
                    }}
                  >
                    <RatingStar
                      rating={rev.average_overall_rating}
                      noBorder
                      size={18}
                      colors={{ mask: "#FBB500", rear: "#c4c4c4" }}
                    />

                    <p style={{ fontSize: 14 }}>
                      {rev.average_overall_rating} ({rev.total_rating})
                    </p>
                  </div>
                  <Link
                    to={`/home/spare-parts/${info.id}/${info.product_title}/review`}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      style={{
                        backgroundColor: "var(--primary1)",
                        color: "#fff",
                        padding: "10px",
                        borderRadius: "3px",
                      }}
                    >
                      Click to view all reviews
                    </button>
                  </Link>

                  <div>
                    <p
                      style={{
                        fontSize: 14,
                        // marginTop: _mobile ? "-10px" : "0px",
                      }}
                    >
                      Warranty: {info.warranty}
                    </p>
                  </div>
                </div>
              </div>
              <Spacing horizontal={50} />
              <div>
                <div
                  style={{
                    width: _mobile ? "100%" : 347,
                    height: "auto",
                  }}
                >
                  <div
                    style={{
                      height: 51,
                      background: "var(--primary1)",
                      fontWeight: "bold",
                      color: "white",
                      fontSize: 16,
                      textAlign: "center",
                      paddingTop: 20,

                      marginRight: _mobile ? "10px" : "0",
                    }}
                  >
                    $ {info.price}
                    {info.negotiable === 1 ? (
                      <span style={{ fontWeight: "500", fontSize: "12px" }}>
                        (Negotiable)
                      </span>
                    ) : null}
                  </div>

                  <div
                    style={{
                      // height: "100%",
                      border: "1px solid var(--secondary3)",
                      display: "grid",
                      placeItems: "center",
                      padding: 20,
                      borderRadius: "4px",
                      marginRight: _mobile ? "10px" : "0",
                    }}
                  >
                    <div className="shop-img-col">
                      <img
                        style={{
                          width: 60,
                          height: 60,
                          borderRadius: 100,
                          background: "green",
                        }}
                        alt="profile"
                        src={shop?.company_photo}
                      />

                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Spacing horizontal={5} />
                          <Link
                            to={`/home/Dealer/store/${userInfo?.id}`}
                            className="link-to-store"
                          >
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: "500",
                                margin: "0 0 10px",
                                cursor: "pointer",
                                textTransform: "capitalize",
                                lineHeight: "16px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              {shop?.shop_name}
                            </p>
                          </Link>
                          <div className="like-parts">
                            <p className="joined-col">
                              Joined :
                              {moment(parts.created_at).format("MMMM, YYYY")}{" "}
                            </p>
                            <span
                              onClick={isLiked ? unlikeProduct : likeProduct}
                            >
                              {isLiked ? (
                                <FaHeart color={"red"} size={20} />
                              ) : (
                                <FaRegHeart color={"#646464"} size={20} />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      {/* <Spacing horizontal={5} /> */}

                      <p className="address-shop-col">{shop?.office_address}</p>
                      {/* </div> */}
                      {/* <div style={{ display: "flex", alignItems: "center" }}> */}

                      {/* <Spacing horizontal={5} /> */}

                      <div>
                        {revealPhone ? (
                          <p
                            className="click-to-view"
                            style={{ fontSize: "14px" }}
                          >
                            {shop?.phone_number}
                          </p>
                        ) : (
                          <p
                            className="click-to-view"
                            // onClick={()=> setRevealPhone(true)}
                            onClick={confirmLogin}
                          >
                            <span
                              style={{
                                marginRight: "1rem",
                                fontSize: "20px",
                                paddingTop: "5px",
                              }}
                            >
                              <MdPhoneInTalk />
                            </span>
                            Show Number
                          </p>
                        )}
                      </div>
                      {/* </div> */}
                    </div>

                    <button
                      style={{
                        width: 238,
                        height: 42,
                        background: "var(--primary2)",
                        borderRadius: 4,
                        color: "#fff",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={confirmLogin1}
                    >
                      <span
                        style={{
                          marginRight: "1rem",
                          fontWeight: "500",
                          fontSize: "20px",
                          paddingTop: "5px",
                        }}
                      >
                        <FaStar />
                      </span>
                      Rate this store
                    </button>
                    <p
                      className="click-to-view"
                      onClick={() => setrevealShareBtns(!revealShareBtns)}
                      // onClick={confirmLogin}
                    >
                      <span
                        style={{
                          marginRight: "1rem",
                          fontSize: "20px",
                          paddingTop: "5px",
                        }}
                      >
                        {/* <FaShareAlt /> */}
                      </span>
                      Share
                    </p>
                    {revealShareBtns && (
                      <div className="share-icon-btn">
                        <ShareBtns
                          setrevealShareBtns={setrevealShareBtns}
                          title={info.product_title}
                          link={`https://quickmechs.com/home/spare-parts/${id}/${info.product_title}`}
                          description={info.description}
                          quote={info.description}
                        />
                      </div>
                    )}
                    <Modal open={loginpopup}>
                      <LoginPopUp onLogin={onLogin}>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => setloginpopup(false)}
                        >
                          <GrFormClose />
                        </p>
                      </LoginPopUp>
                    </Modal>
                    {/* </Link> */}
                  </div>

                  <Spacing />

                  <div
                    style={{
                      border: "1px solid var(--secondary3)",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "4px",

                      marginRight: _mobile ? "10px" : "0",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: "500",
                        marginBottom: "-12px",
                      }}
                    >
                      Safety Tips
                    </p>

                    <div style={_x(_mobile).text3_box}>
                      <ul style={{ marginLeft: " -25px" }}>
                        <li style={{ fontSize: 11, marginTop: "10px" }}>
                          Do not pay in advance even for the delivery
                        </li>
                        <li style={{ fontSize: 11, marginTop: "10px" }}>
                          Try to meet at a safe, public location
                        </li>
                        <li style={{ fontSize: 11, marginTop: "10px" }}>
                          Check the product BEFORE you pay for it
                        </li>
                        <li style={{ fontSize: 11, marginTop: "10px" }}>
                          Pay only after collecting the product
                        </li>

                        <li style={{ fontSize: 11, marginTop: "10px" }}>
                          Do not post what is not Car related
                        </li>
                      </ul>
                      <button className="abuse-btn" onClick={report_Abuse}>
                        Report Abuse
                      </button>
                    </div>
                  </div>

                  <Helmet>
                    <title>{info.product_title + " - quickmechs"}</title>
                    <meta name="description" content={info.description} />
                  </Helmet>
                  {/* <button className="share-btn"> <RiShareFill style={{ fontSize: 20, marginRight: "0.5rem" }}/> Share this Product</button> */}
                </div>
              </div>
            </Paper>
          )}

          <div></div>

          <Spacing />

          <div
            style={{
              width: _mobile ? "90vw" : "100%",
              maxWidth: 1180,
            }}
          >
            {isloading || related.length === 0 ? null : (
              <p
                style={{
                  fontSize: _mobile ? 14 : 18,
                  fontWeight: "500",
                  marginLeft: _mobile ? "1rem" : "none",
                  marginTop: _mobile ? "1rem" : "1rem",
                }}
              >
                Other Related Products:
              </p>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "flex-start",
                // maxWidth: "1100px",
                Width: _mobile ? "100%" : "0px",
                margin: _mobile ? "0 1rem" : "none",
                overflowY: "scroll",
              }}
              className="hideScroll"
            >
              {isloading ? null : (
                <>
                  <Swiper
                    slidesPerView={_mobile ? 2 : 5}
                    spaceBetween={20}
                    slidesPerGroup={_mobile ? 3 : 3}
                    loop={true}
                    loopFillGroupWithBlank={false}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    className="mySwiper"
                  >
                    {related.slice(0, 4).map((each) => (
                      <SwiperSlide>
                        <SparePart
                          key={each.id}
                          onTap={(info) => partTapped(info)}
                          name={each.product_title}
                          price={each.price}
                          code={each.product_no}
                          image={each.product_photo && each.product_photo[0]}
                          ratingStar={
                            each.customer_reviews.total_rating === 0
                              ? 0
                              : Number(
                                  each.customer_reviews.average_overall_rating
                                )
                          }
                          totalRate={
                            each.customer_reviews.total_rating === null
                              ? 0
                              : each.customer_reviews.total_rating
                          }
                          averageRate={
                            each.customer_reviews.total_rating === null
                              ? 0
                              : each.customer_reviews.average_overall_rating
                          }
                          product_link={
                            `${each.id}` + "/" + `${each.product_title}`
                          }
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Spacing />

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div
        // style={{
        //   width: '72vw',
        //   minWidth: 1020,
        // }}
        >
          <DownloadApp />
          <Footer sideMargins={0} paddingInline={0} />
        </div>
      </div>
      {isloading && (
        <LoadingBar
          color="#587FC0"
          loaderSpeed={4000}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      )}

      <Popup
        isOpen={successPopup}
        closePressed={() => {
          setSuccessPopup(false);
          setViewerEmail("");
        }}
        continuePressed={() => {
          setSuccessPopup(false);
          setViewerEmail("");
        }}
        popupMessage={"Product Shared Successfully"}
        headerText={"Success"}
        style={{ width: 450 }}
      />
    </div>
  );
}

export default SparePartShowCase;

const _x = (_mobile) => ({
  spare_parts: {
    height: "100%",
    background: "white",
    margin: "0",
    overflowX: _mobile ? "hidden" : "none",
    width: "100%",
  },

  header: {
    height: 80,
    boxShadow: "0px 2px 2px #c4c4c480",
    // paddingLeft: 180,
    // paddingRight: 180,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  search_box: {
    display: "flex",
    // background: "red",
    justifyContent: "center",
    // height: "100%",
    alignItems: "center",
    borderRadius: 6,
    width: "100%",
    // maxWidth: _mobile ? "326px" : 1140,
  },

  dropDown: {
    width: 150,
    background: "#DBDEE7",
    textAlign: "center",
    height: 42,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    border: "1px solid var(--primary1)",
    display: "grid",
    placeItems: "center",
  },

  input: {
    height: 42,
    // width: _mobile ? "300px" : "700px",
    // background: "red",
    // width: _mobile ? "100%" : "50%",
    // maxWidth: _mobile ? "100%" : 1140,
  },

  search_icon: {
    height: 44,
    width: 46,
    background: "var(--primary1)",
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    display: "grid",
    placeItems: "center",
  },

  search_icon_img: {
    height: 15,
    width: 15,
    color: "#fff",
    fontSize: "16px",
  },

  row1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },

  row1_row2: {
    display: "flex",
    alignItems: "center",
  },

  row1_row2_text1: {
    color: "var(",
  },

  dropDown2: {
    width: 58,
    background: "#DBDEE7",
    textAlign: "center",
    height: 28,
    display: "grid",
    placeItems: "center",
    fontSize: 15,
  },

  subheader_text: {
    fontSize: 18,
  },

  main: {
    display: "flex",
    justifyContent: "center",
    // alignItems: _mobile ? "center" : "none",
    gridTemplateColumns: "auto 1fr",

    margin: _mobile ? "0 0 0 0rem" : "0 auto",
  },

  left: {
    // width: 300,
    // background: "tomato",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },

  checkbox: {
    height: 15,
    width: 15,
  },

  checkbox_text: {
    fontSize: 12,
  },

  right: {
    // background: "blue",
    // borderTop: "7px solid var(--primary1)",
    // width: _mobile ? "100%" : "990px",
    width: "100%",
    display: "grid",
    placeItems: "center",
  },

  right_row1: {
    height: 140,
    maxWidth: "990px",
    backgroundColor: "var(--secondary4)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingLeft: 30,
    paddingRight: 30,
  },

  right_row2: {
    // backgroundColor: "blue",
    padding: 20,
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: 20,
  },

  text1: {
    fontSize: 17,
  },

  text2: {
    fontSize: 12,
  },

  right_dropDrop_lists: {
    height: 64,
    background: "white",
    width: "100%",
    marginLeft: 50,
  },

  page_scroller: {
    height: 30,
    width: "50%",
    display: "grid",
    gridTemplateColumns: "repeat(8, 1fr)",
    placeItems: "center",
  },

  text3_box: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    // paddingLeft: 20,
  },

  sub: {
    height: _mobile ? 40 : 80,
    width: _mobile ? 40 : 80,
    border: "1px solid var(--secondary3)",
    display: "grid",
    placeItems: "center",
  },
});
