import React, { Fragment, useEffect, useState } from "react";
import "./job.scss";

import { useHistory } from "react-router-dom";
import AuthService from "../../../services/auth.service";
const currentUser = AuthService.getCurrentUser("user");
export default function JobBoard() {
  const history = useHistory();
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 800px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    if (!currentUser) {
      history.push("/auth/login");
    }
  }, []);

  return (
    // <Fragment>
    <div id="job-container">
      {matches ? (
        <div className="mobile-wrapper">
          <span>Post a job, find your next local hire or your next job</span>

          <div className="mobile-wrapper-card-section">
            <span>What would you like to do?</span>
            <div className="column-container">
              <div
                className="card"
                onClick={() => history.push("/home/post-job")}
              >
                <img
                  src={require("../../../Resources/Icons/job-add.svg").default}
                />
                <span className="txt-job">Post A Job</span>
              </div>
              <div className="card">
                <img
                  src={require("../../../Resources/Icons/job-my.svg").default}
                  onClick={() => history.push("/home/myjob")}
                />
                <span className="txt-job">My Job</span>
              </div>
              <div
                className="card"
                onClick={() => history.push("/home/find-job")}
              >
                <img
                  src={
                    require("../../../Resources/Icons/job-search.svg").default
                  }
                />
                <span className="txt-job">Find A Job</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // ===================Mobile section =============
        <div className="job-containner-inner">
          <div className="inner-wrap-item">
            <span className="title-text">Quickmechs</span>
            <label>
              Post a job, find your next local hire or your next job
            </label>
            <div className="card-section">
              <span>What would you like to do?</span>
              <div className="row-card-section">
                <div
                  className="card"
                  onClick={() => history.push("/home/post-job")}
                >
                  <img
                    src={
                      require("../../../Resources/Icons/job-add.svg").default
                    }
                  />
                  <span className="txt-job">Post A Job</span>
                </div>
                <div
                  className="card"
                  onClick={() => history.push("/home/myjob")}
                >
                  <img
                    src={require("../../../Resources/Icons/job-my.svg").default}
                  />
                  <span className="txt-job">My Job</span>
                </div>
                <div
                  className="card"
                  onClick={() => history.push("/home/find-job")}
                >
                  <img
                    src={
                      require("../../../Resources/Icons/job-search.svg").default
                    }
                  />
                  <span className="txt-job">Find A Job</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
