import React, { useRef, useState, useEffect } from "react";
import "./post.scss";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { addJob } from "src/services/job/job.service";
import { Circles } from "react-loader-spinner";
import PlacesAutocomplete from "react-places-autocomplete";
import { Alert, Button } from "@mui/material";
import { jobTitleoptions } from "./data";
import { ValidateEmail, isValidUrl } from "./helper";

const applyMethodOptions = [
  { value: "url", label: "Url" },
  { value: "phone ", label: "Phone Number" },
  { value: "email", label: "Email" },
];

// ghmgmmg
export default function PostAJob() {
  const [jobCategoryOptions, setJobCategoryOptions] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();

  const [zipCode, setZipCode] = useState();
  const [email, setEmail] = useState();
  const [description, setDescription] = useState();
  const [jobTitle, setJobTitle] = React.useState();
  const [category, setCategory] = React.useState();
  const [applyMethod, setApplyMethod] = useState("phone");
  const [employmentType, setEmploymentType] = useState();
  const [file, setFile] = useState("");
  const [progress, setProgress] = useState(false);
  const [Image, setImage] = useState();
  const [methodError, setMethodError] = useState();
  const setInputData = (setState) => (e) => {
    setMethodError(undefined);
    setState(e.target.value);
  };
  const handleChange = (address) => {
    setZipCode(address);
  };
  useEffect(() => {
    if (!user) {
      history.push("/auth/login");
    }
  }, []);

  const handleSelect = (address) => {
    setZipCode(address);
  };
  const handleSaveData = async (e) => {
    e.preventDefault();
    setProgress(true);
    const data = {
      location: zipCode,
      apply_detail: email,
      description: description,
      title: jobTitle,
      category: category,
      apply_method: applyMethod,
      employment_type: employmentType,
      logo: file,
      company_name:
        user?.data?.user_info?.first_name +
        " " +
        user?.data?.user_info?.last_name,
    };
    if (
      zipCode &&
      email &&
      description &&
      jobTitle &&
      category &&
      applyMethod &&
      employmentType &&
      file
    ) {
      if (applyMethod === "url" && !isValidUrl(email)) {
        setMethodError(`Please provide a valid ${applyMethod}`);
        setProgress(false);
        return;
      }
      if (applyMethod === "email" && !ValidateEmail(email)) {
        setMethodError(`Please provide a valid ${applyMethod}`);
        setProgress(false);
        return;
      }

      try {
        const response = await addJob(data);
        console.log(response);

        if (
          response.status === 200 ||
          response.status === 201 ||
          response.data.status === "success"
        ) {
          setProgress(false);
          // console.log(response.data);
          localStorage.setItem("job_id", `${response.data?.data?.id}`);
          history.push("/home/confirm-job", {
            state: { job_id: `${response.data?.data?.id}` },
          });
        }
      } catch (error) {
        setProgress(false);
        console.log(error?.message);
      }
    } else {
      alert("Please fill all fields");
      setProgress(false);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const saveCompanyLogo = async (e) => {
    const file = e.target.files[0];
    setImage(URL.createObjectURL(e.target.files[0]));
    const base64 = await convertToBase64(file);
    setFile(base64);
  };

  return (
    <div className="wrapper-postjob">
      <div className="inner-layout">
        <span className="page-title">Post a Job</span>

        {/* ====================================== */}

        <PlacesAutocomplete
          value={zipCode}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <InputWithLabel
                {...getInputProps({
                  placeholder: "Search Places ...",
                  // className: "location-search-input",
                })}
                value={zipCode}
                smallText="Enter a 5 digit ZIP code"
                label="Job Location"
              />
              <div
                style={{ position: "relative" }}
                className="autocomplete-dropdown-container"
              >
                {loading && <div>Loading...</div>}

                <div
                  className="places-dropdown"
                  style={{
                    top: -30,
                    display: suggestions.length === 0 && "none",
                  }}
                >
                  {!loading &&
                    suggestions.map((suggestion) => (
                      <div
                        {...getSuggestionItemProps(suggestion, {})}
                        className="places-button"
                      >
                        <span style={{}}>{suggestion.description}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {/* ============================== */}

        {/* Job Title  */}
        <AppDropown
          smallText="0/60"
          data={jobTitleoptions}
          label="Job Title"
          handleChange={(data) => {
            setJobCategoryOptions(data.category);
            setJobTitle(data.value);
          }}
          value={jobTitle}
        />
        {/* Job Description */}
        <AppTextArea
          value={description}
          onChange={setInputData(setDescription)}
        />
        {/* Job Category */}
        <AppDropown
          label="Job Category"
          data={jobCategoryOptions}
          handleChange={(data) => setCategory(data.value)}
          value={category}
        />
        {/* =========Job Type================ */}
        <RadioButtonElement
          handleChange={(type) => setEmploymentType(type)}
          label="Employment Type"
        />
        {/* =========Company Logo */}
        <SelectLogoElement
          onChange={saveCompanyLogo}
          file={file}
          Image={Image}
        />
        {/* How people will apply section */}
        <div className="how-people-apply-wrapper">
          <span className="how-people-apply-txt">How people will apply</span>
          <div className="how-to-row-wrapper">
            <div className="row-item-how-to">
              <AppDropown
                data={applyMethodOptions}
                label="Apply Method"
                handleChange={(data) => {
                  console.log(data.value);
                  setApplyMethod(`${data.value}`);
                }}
                value={applyMethod}
              />
            </div>

            <div className="row-item-how-to">
              <InputWithLabel
                type={
                  applyMethod === "url"
                    ? "url"
                    : applyMethod === "email"
                    ? "email"
                    : "phone"
                }
                value={email}
                onChange={setInputData(setEmail)}
                smallText="This is how applicants will contact you"
                label={
                  applyMethod === "url"
                    ? "Contact URL"
                    : applyMethod === "email"
                    ? "Contact Email"
                    : "Contact Phone"
                }
              />
            </div>
          </div>
        </div>
        {/* Alert component */}
        {methodError && (
          <Alert style={{ marginBottom: "1rem" }} severity="error">
            {methodError}
          </Alert>
        )}
        {/* Next Button Section */}

        <div
          style={{ width: "100%", padding: 0, margin: 0, marginTop: "1rem" }}
        >
          <Button
            onClick={handleSaveData}
            disabled={progress}
            sx={{
              background: "#587FC0",
              color: "#FFFFFF",
              borderRadius: 2,
            }}
            variant="contained"
            className="next-button"
          >
            {progress ? (
              <Circles
                height="20"
                width="20"
                color="#587FC0"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            ) : (
              "Next"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

const SelectLogoElement = (props) => {
  const imageRef = useRef();

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };
  return (
    <div
      style={{
        marginTop: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "2rem",
        height: "100%",
      }}
    >
      <label className="input-label" for="Company Logo (Optional)">
        Company Logo (Optional)
      </label>
      <div id="company_logo" onClick={showOpenFileDialog}>
        <img
          style={
            props?.file
              ? {
                  width: 30,
                  height: 30,
                }
              : {}
          }
          src={
            props.file
              ? props.Image
              : require("../../../Resources/Icons/portfolio-icon.png").default
          }
        />
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
        >
          <img
            src={require("../../../Resources/Icons/photo-icon.png").default}
          />
          <span className="small-photo-span-txt">Add photo</span>
        </div>
        <input
          ref={imageRef}
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          onChange={props?.onChange}
        />
      </div>
    </div>
  );
};

const RadioButtonElement = ({ label, ...props }) => {
  const [selectedValue, setSelectedValue] = React.useState("");
  const handleChange = (e) => {
    setSelectedValue(e?.target.value);
    props?.handleChange(e?.target.value);
  };
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <label className="input-label">{label}</label>
      <div className="all-radio-wrapper">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "fit-content",
          }}
        >
          <input
            value="full-time"
            checked={selectedValue === "full-time"}
            className="radio-input"
            type="radio"
            onChange={handleChange}
          />
          <label className="radio-label">Full - Time</label>
        </div>
        {/* part-time */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 10,
            width: "fit-content",
          }}
        >
          <input
            value="part-time"
            checked={selectedValue === "part-time"}
            className="radio-input"
            type="radio"
            onChange={handleChange}
          />
          <label className="radio-label">Part - Time</label>
        </div>

        {/* Gig */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 10,
            width: "fit-content",
          }}
        >
          <input
            value="gig"
            checked={selectedValue === "gig"}
            className="radio-input"
            type="radio"
            onChange={handleChange}
          />
          <label className="radio-label">Gig</label>
        </div>
      </div>
    </div>
  );
};

const InputWithLabel = ({ label, smallText, ...props }) => (
  <div className="input-wrap">
    <label className="input-label">{label}</label>
    <input className="input-element" {...props} />
    <label className="small-txt-below-location">{smallText}</label>
  </div>
);

const AppDropown = ({
  handleChange,
  value,
  animatedComponents,
  smallText,
  ...props
}) => {
  return (
    <div
      style={{
        width: "100%",
        marginBottom: "1rem",
        paddingTop: 5,
      }}
    >
      <label className="input-label">{props?.label}</label>
      <Select
        components={{
          IndicatorSeparator: () => null,
        }}
        onChange={handleChange}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "1px solid #DBDEE7",
            height: 40,
            alignContent: "center",
            borderRadius: 4,
            marginTop: 5,
          }),
        }}
        options={props?.data}
      />
      <label className="small-txt-below-location">{smallText}</label>
    </div>
  );
};

const AppTextArea = ({ ...props }) => {
  return (
    <div
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "1rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <label className="input-label">Job Description</label>
      <textarea className="text-area" multiple name="" {...props} />
      <label className="small-txt-below-location">
        If your State require a wage, add it above 0/2000
      </label>
    </div>
  );
};
const styles = {
  placesDropdown: {
    height: "fit-content",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 3,
    zIndex: 1000,
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    overflow: "scroll",
    top: -30,
    position: "absolute",
    padding: "0px 3px 3px 3px",
  },
};
