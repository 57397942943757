import React, { useState, useEffect, useRef, Fragment } from "react";
import { Spacing } from "../../../Components/Style/Styles";
import axios from "axios";
import { RiDeleteBin5Line } from "react-icons/ri";
import NumberFormat from "react-number-format";
import AuthService from "../../../services/auth.service";
import { authHeader } from "../../../services/auth-header";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import "../newPartsForm/NewPartsDealerStepsForm.scss";
import ImageUploading from "react-images-uploading";
import { makes3, category, years } from "../../../Resources/Resources";
import close2 from "../../../Resources/Icons/close2.png";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import { Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import DownloadApp from "src/Components/Functional/DownloadApp/DownloadApp";
import Footer from "src/Components/Functional/Footer/Footer";
import "./postAd.css";
import { Checkbox, Typography, useMediaQuery } from "@mui/material";
import ImageContextMenu from "./ImageContextMenu";
import ShPhotoInfo from "../newPartsForm/ShPhotoInfo";
import ModalComponent from "./ModalComponent";
import { createProduct } from "src/services/job/job.service";
import { ThreeCircles } from "react-loader-spinner";

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const PostFreeAd = () => {
  const form = useRef();
  const checkBtn = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [showfielderror, setshowfielderror] = useState(false);
  const [err, setErr] = useState(false);
  const [topImage, setTopImage] = React.useState(0);

  const [errMess, setErrMess] = useState("");
  const [isloading, setisloading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [details, setDetails] = useState(true);
  const [price, setPrice] = useState(false);
  const [shopUpload, setShopUpload] = useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);

  const [inputs, setInputs] = useState({
    category_name: "Select Category",
    sub_category_name: "",
    product_title: "",
    product_type: "",
    keyword: "",
    condition: "",
    year: "",
    make: "Select Make",
    model: "",
    warranty: "",
    description: "",
    price: "",
    product_photo: "",
    negotiable: "",
    product_title: "",
  });

  const required = (value) => {
    if (!value && showfielderror) {
      return (
        <div className="warning" role="alert">
          &#9432; This field is required!
        </div>
      );
    }
  };

  function closeModal() {
    setSubmitted(false);
    window.location.reload();
  }

  const nextBtn = () => {
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      if (details) {
        setDetails(false);
        setPrice(true);
        setShopUpload(false);
      } else if (price) {
        setDetails(false);
        setPrice(false);
        setShopUpload(true);
      }
    } else {
      // console.log("nogo")
    }
  };

  const backBtn = () => {
    if (shopUpload) {
      setDetails(false);
      setPrice(true);
      setShopUpload(false);
    } else if (price) {
      setDetails(true);
      setPrice(false);
      setShopUpload(false);
    }
  };

  function selectDropDown(e, field) {
    const { value } = e.target;

    if (field === "make") {
      if (value === "") {
      } else setInputs({ ...inputs, make: value });
    }
  }
  const [modalState, setModalState] = useState(false);
  const currentUser = AuthService.getCurrentUser("user");

  const token = currentUser.data.access_token;

  const submitAdForm = async (e) => {
    setIsLoading2(true);
    e.preventDefault();
    // setModalState(true);
    form.current.validateAll();
    let product = {
      product_title: inputs.product_title,
      product_type: inputs.product_type,
      condition: inputs.condition,
      year: inputs.year,
      make: inputs.make,
      model: inputs.year,
      warranty: inputs.warranty,
      price: inputs.price,
      category_name: inputs.category_name,
      sub_category_name: inputs.sub_category_name,
      description: inputs.description,
      relative_keyword: inputs.keyword,
      product_photo: [...inputs?.product_photo],
    };

    if (checkBtn.current.context._errors.length === 0) {
      try {
        const result = await createProduct(product);
        if (result?.status === 200 || result.status === 201) {
          setIsLoading2(false);
          setSubmitted(true);
          window.scrollTo(0, 0);
          setshowfielderror(false);
          setInputs({
            category_name: "Select Category",
            sub_category_name: "",
            product_title: "",
            product_type: "",
            keyword: "",
            condition: "",
            year: "",
            make: "Audi",
            model: "2.0T",
            warranty: "",
            description: "",
            price: "",
            product_photo: "",
            negotiable: "",
            product_title: "",
          });
        }
      } catch (error) {
        window.scrollTo(0, 0);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setIsLoading2(false);
        setErr(true);
        setErrMess(resMessage);
        console.log(resMessage);
      }

      console.log(inputs);
    } else {
      setIsLoading2(false);
    }
  };

  const changeHandle = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const [images, setImages] = React.useState([]);

  const maxNumber = 5;

  const [checkboxValue, setCheckboxValue] = useState(true);
  const [ImageIndex, setImageIndex] = useState();
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    const loadedImage = imageList;
    let newArry = imageList.map((item) => item.data_url);
    console.log("testing", newArry);
    setImages(imageList);
    if (loadedImage) {
      setInputs({
        ...inputs,
        product_photo: newArry,
      });
    }
  };
  const handleRightClick = (e) => {
    if (e.type === "click") {
      console.log("Left click");
    } else if (e.type === "contextmenu") {
      console.log("Right click");
    }
  };

  const checkchange = (e) => {
    setCheckboxValue(!checkboxValue);
    if (checkboxValue) {
      // console.log("checked")
      setInputs({
        ...inputs,
        negotiable: "1",
      });
    } else {
      // console.log("unchecked");
      setInputs({
        ...inputs,
        negotiable: null,
      });
    }
  };

  const [allImages, setAllImage] = useState([]);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    if (width > 800) {
      setPrice(true);
      setShopUpload(true);
    } else if (width < 800) {
      setPrice(false);
      setShopUpload(false);
    }
  }, [width]);
  const mobile = useMediaQuery("(max-width:1000px)");

  return (
    <>
      <main className="post_wrapper">
        <>
          <div style={_x.title_sect}>
            <h2
              style={{
                paddingBottom: "1rem",
                marginTop: 0,
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Post Your Free Ad
            </h2>
            <p className="post_tag">
              Make sure you fill in all the necessary fields and upload all the
              required files.
            </p>
          </div>
        </>

        <section className="mob-desktop-wrap">
          <Form
            id="ad-form"
            className="ad-form"
            onSubmit={submitAdForm}
            ref={form}
          >
            <div className="form_title">
              <p>Ad Details</p>
            </div>
            <>
              <div>
                <label htmlFor="Product">
                  Product Category
                  <Select
                    className="cat"
                    name="category_name"
                    value={inputs.category_name}
                    validations={[required]}
                    onChange={changeHandle}
                    onClick={(e) => selectDropDown(e, "category_name")}
                  >
                    {Object.keys(category).map((key, val) => {
                      return (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      );
                    })}
                  </Select>
                </label>

                <label htmlFor="Product">
                  Sub-Category
                  <Select
                    name="sub_category_name"
                    value={inputs.sub_category_name}
                    validations={[required]}
                    onChange={changeHandle}
                    onClick={(e) => selectDropDown(e, "sub_category_name")}
                  >
                    <option value="">Select sub-category...</option>
                    {category[inputs.category_name].map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </label>
              </div>
              <div>
                <label htmlFor="Product">
                  Product Title
                  <Input
                    type="text"
                    placeholder="Enter Item Title"
                    name="product_title"
                    maxLength="60"
                    validations={[required]}
                    onChange={changeHandle}
                    value={inputs.product_title}
                  />
                </label>
                <label htmlFor="condition">
                  Condition
                  <Select
                    name="condition"
                    value={inputs.condition}
                    validations={[required]}
                    onChange={changeHandle}
                  >
                    <option value="">Select Condition</option>
                    <option value="New">New</option>
                    <option value=" Used"> Used </option>
                  </Select>
                </label>
              </div>

              <div>
                <label htmlFor="make">
                  Make
                  <Select
                    name="make"
                    value={inputs.make}
                    validations={[required]}
                    onChange={changeHandle}
                    onClick={(e) => selectDropDown(e, "make")}
                  >
                    {Object.keys(makes3).map((key, val) => {
                      return (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      );
                    })}
                  </Select>
                </label>

                <label htmlFor="model">
                  Model
                  <Select
                    name="model"
                    value={inputs.model}
                    validations={[required]}
                    onChange={changeHandle}
                    onClick={(e) => selectDropDown(e, "model")}
                  >
                    <option value="">Select Model</option>

                    {makes3[inputs.make].map((eachModel) => {
                      return (
                        <option key={eachModel} value={eachModel}>
                          {eachModel}
                        </option>
                      );
                    })}
                  </Select>
                </label>
              </div>
              <div>
                <label htmlFor="year">
                  Year
                  <Select
                    name="year"
                    value={inputs.year}
                    validations={[required]}
                    onChange={changeHandle}
                  >
                    <option value="">Select Year</option>
                    {years.map((e) => (
                      <option value={e}>{e}</option>
                    ))}
                  </Select>
                </label>

                <label htmlFor="warranty">
                  Warranty
                  <Select
                    name="warranty"
                    value={inputs.warranty}
                    validations={[required]}
                    onChange={changeHandle}
                  >
                    <option value="make">Select Duration</option>
                    <option value="1 week">1 Week</option>
                    <option value="2 weeks">2 Weeks</option>
                    <option value="1 month">1 Month</option>
                    <option value="2 months">2 Months</option>
                    <option value="3 months">3 Months</option>
                    <option value="4 months">4 Months</option>
                    <option value="6 months">6 Months</option>
                    <option value="1 year">1 year</option>
                    <option value="1 year and above">1 year and above</option>
                  </Select>
                </label>
              </div>
              <div>
                <label htmlFor="Product" id="relative-keyword">
                  Relative Keyword (Optional)
                  <input
                    type="text"
                    placeholder="Enter a keyword"
                    name="keyword"
                    onChange={changeHandle}
                    value={inputs.keyword}
                  />
                </label>
              </div>
              <div>
                <label htmlFor="text-area" id="relative-keyword">
                  Description
                  <Textarea
                    type="text"
                    placeholder="Type Description"
                    rows="3"
                    name="description"
                    maxLength="500"
                    validations={[required]}
                    onChange={changeHandle}
                    value={inputs.description}
                  />
                  <p className="input-info">
                    Field must be between 10 and 500 characters. Note: CAPS LOCK
                    text will be automatically edited
                  </p>
                </label>
              </div>
              <label htmlFor="price" id="price">
                Price
                <div className="price-col">
                  <p>$</p>
                  <NumberFormat
                    thousandSeparator={true}
                    placeholder="Enter Price"
                    name="price"
                    onChange={changeHandle}
                    value={inputs.price}
                    onKeyPress={(e) => {
                      if (e.key === "e" || e.key === "-" || e.key === "+") {
                        e.preventDefault();
                      }
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="negotiate" id="negotiat">
                  <input
                    style={{
                      width: "13px",
                      height: "13px",
                      border: "1px solid #587FC0",
                    }}
                    type="checkbox"
                    className="negotiable"
                    name="negotiable"
                    value={inputs.negotiable}
                    checked={!checkboxValue}
                    onChange={checkchange}
                  />
                  <p
                    style={{
                      color: " #587FC0",
                      fontFamily: "Montserrat",
                      fontSize: "9px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "11px",
                    }}
                  >
                    Negotiable
                  </p>
                </div>
              </label>

              <section className="st_shop_pix">
                <div
                  style={{
                    color: "#222",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "16px",
                    marginBottom: "1rem",
                  }}
                >
                  Upload Product Photo
                </div>
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => {
                    // setAllImage(imageList);
                    return (
                      <Fragment>
                        {/* // write your building UI */}
                        <section
                          style={{
                            width: mobile && "100%",
                          }}
                          className="st_shop_image"
                        >
                          {/* =================== */}
                          {imageList.length !== 0 && (
                            <section className="sh_photo_wrap">
                              <img
                                src={imageList[topImage]?.data_url}
                                alt="quickmechs-product-image"
                                width="50"
                                height="50"
                              />
                              {/* <div className="image-item__btn-wrapper"> */}
                              <section
                                className="st_delete"
                                onClick={() => onImageRemove(topImage)}
                              >
                                <RiDeleteBin5Line />
                              </section>

                              {/* </div> */}
                            </section>
                          )}
                          {/* ===================== */}
                          {imageList.length !== 5 ? (
                            <ShPhotoInfo onImageUpload={onImageUpload} />
                          ) : null}
                        </section>

                        <ImageContextMenu
                          setAllImage={setAllImage}
                          onImageUpdate={onImageUpdate}
                          handleMarkAsCoverPhoto={() => {
                            setTopImage(ImageIndex);
                          }}
                          onImageRemove={onImageRemove}
                          index={ImageIndex}
                          images={imageList}
                          component={
                            <section
                              style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "3%",
                                marginTop: "1rem",
                              }}
                            >
                              {imageList.map((image, index) => {
                                if (index === topImage) {
                                  return;
                                }
                                return (
                                  <section
                                    key={index.toString()}
                                    onClick={() => setImageIndex(index)}
                                    className="sh_photo_wrap"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      position: "relative",
                                    }}
                                  >
                                    <img
                                      src={image["data_url"]}
                                      alt="quickmechs-product-image"
                                      width="50"
                                      height="50"
                                    />
                                  </section>
                                );
                              })}
                            </section>
                          }
                        />
                      </Fragment>
                    );
                  }}
                </ImageUploading>

                <p className="st_notice">
                  Picture must not exceed 5MB. Suported formats are *jpg, *gif
                  and *png
                </p>
              </section>
              <div
                style={{
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "2rem",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <Checkbox sx={{ padding: 0, margin: 0 }} onChange={(e) => {}} />
                <Typography
                  sx={{
                    padding: 0,
                    margin: 0,
                    display: "flex",
                    gap: "5px",
                    fontFamily: "Montserrat",
                  }}
                  for=""
                >
                  By ticking this you agree to our{" "}
                  <Typography
                    sx={{ color: "#FBB500", fontFamily: "Montserrat" }}
                  >
                    Privacy Policy
                  </Typography>
                  and
                  <Typography
                    sx={{ color: "#FBB500", fontFamily: "Montserrat" }}
                  >
                    Terms and Conditions
                  </Typography>
                </Typography>
              </div>
              <button disabled={isLoading2} className="st_btn">
                {isLoading2 ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ThreeCircles
                      height="20"
                      width="20"
                      color="#fff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor=""
                      middleCircleColor=""
                    />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </>
          </Form>

          <Modal open={submitted}>
            <div
              // open={submitted === false}
              // onClick={closeModal}
              style={{ border: "none" }}
            >
              <div style={_x.modall}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                ></div>

                <h5
                  style={{
                    fontFamily: "montserrat",
                    fontSize: "16px",
                    textAlign: "center",
                    fontWeight: "500",
                    margin: 0,
                  }}
                >
                  Congratulations
                </h5>

                <p
                  style={{
                    fontFamily: "montserrat",
                    fontSize: "14px",
                    textAlign: "center",
                    width: "70%",
                  }}
                >
                  You have successfully posted an ad
                </p>
                <article
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <button onClick={closeModal} style={_x.continue}>
                    Post another Ad
                  </button>
                  <Link
                    to="/home/dashboard"
                    style={{ textDecoration: "none", width: "95%" }}
                  >
                    <button style={_x.continue2}>Go to Dashboard</button>
                  </Link>
                </article>
              </div>
            </div>
          </Modal>

          {err && (
            // <section style={_x.modal_bg}>
            <Modal open={err === false}>
              <div
                // onClick={closeModal}
                style={{ border: "none" }}
              >
                <div style={_x.modal2}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img
                      onClick={() => setErr(false)}
                      style={_x.close}
                      src={close2}
                      alt="close"
                    />
                  </div>

                  <p
                    style={{
                      fontFamily: "montserrat",
                      fontSize: "14px",
                      textAlign: "center",
                      color: "red",
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      marginTop: "0px",
                      paddingTop: "0px",
                    }}
                  >
                    {errMess === "Invalid argument supplied for foreach()"
                      ? "No Image Selected"
                      : errMess === "Network Error"
                      ? "Image(s) exceeds size limit"
                      : errMess}
                  </p>
                </div>
              </div>
            </Modal>
            // </section>
          )}
        </section>

        <Spinner isloading={isloading} />
        <Helmet>
          <title>{"Post Free Advert - quickmechs Solutions"}</title>
        </Helmet>
      </main>

      <Spacing vertical={100} />

      <div className="adFooter">
        <DownloadApp />
        <Footer />
      </div>
      <ModalComponent modalState={modalState} setModalState={setModalState} />
    </>
  );
};

export default PostFreeAd;

const _x = {
  btn: {
    background: "transparent",
    height: 30,
    width: 20,
    fontSize: 22,
    color: "grey",
    borderRadius: 0,
    padding: 0,
    margin: 0,
  },
  title_sect: {
    marginTop: 5,
    // marginBottom: '48px',
    textAlign: "center",
  },

  header: {
    fontWeight: "bold",
    fontSize: 15,
  },
  header2: {
    fontWeight: "normal",
    fontSize: "14px",
    marginBottom: "2px",
    color: "#222222",
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
  },
  btn2: {
    color: "var(--primary1)",
    fontFamily: "montserrat",
    paddingTop: "10px",
  },
  modall: {
    background: "white",
    width: 391,
    height: 236,
    outline: "none",
    borderRadius: 6,
    margin: "170px auto",
    display: "grid",
    placeItems: "center",
    padding: "20px 15px",
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "1px 1px 18px #00000040",
  },
  modal2: {
    background: "white",
    width: 350,
    height: 150,
    outline: "none",
    borderRadius: 6,
    margin: "170px auto",
    display: "grid",
    placeItems: "center",
    padding: 10,
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "1px 1px 18px #00000040",
  },

  continue: {
    height: 40,
    backgroundColor: "var(--primary2)",
    // paddingLeft: 15,
    // paddingRight: 15,
    color: "#fff",
    borderRadius: 6,
    fontWeight: "bold",
    fontSize: 16,
    width: "90%",
    marginRight: "10px",
  },
  continue2: {
    height: 40,
    backgroundColor: "#fff",
    // paddingLeft: 15,
    // paddingRight: 15,
    border: "1px solid var(--primary2)",
    color: "var(--primary2)",
    borderRadius: 6,
    fontWeight: "bold",
    fontSize: 16,
    width: "90%",
  },

  close: {
    cursor: "pointer",
  },
  modal_bg: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100%",
    backgroundColor: "#00000090",
    overflow: "hidden",
  },
};

// import React, { useState, useRef, useEffect } from "react";
// import { AiOutlinePlus } from "react-icons/ai";
// import { Link } from "react-router-dom";
// import { ImImages } from "react-icons/im";
// import { GrFormClose } from "react-icons/gr";
// import { RiDeleteBin5Line, RiCameraFill } from "react-icons/ri";
// import "./newpartssteps.css";
// import axios from "axios";
// import authService from "../../../services/auth.service";
// import { authHeader } from "../../../services/auth-header";
// import { states } from "src/Resources/Resources";
// import ImageUploading from "react-images-uploading";
// import close2 from "../../../Resources/Icons/close2.png";
// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";
// import Select from "react-validation/build/select";
// import Textarea from "react-validation/build/textarea";
// import Spinner from "src/Components/Functional/Spinner/Spinner";
// import Autocomplete from "react-google-autocomplete";

// const {
// REACT_APP_CURRENT_ENVIRONMENT,
// REACT_APP_DEVELOPMENT_URL,
// REACT_APP_PRODUCTION_URL,
// } = process.env;

// const API_URL =
// REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
// ? REACT_APP_DEVELOPMENT_URL
// : REACT_APP_PRODUCTION_URL;

// const required = (value) => {
// if (!value) {
// return (
// <div className="warning" role="alert">
//   &#9432; This field is required!
// </div>
// );
// }
// };

// const NewPartsDealerStepsForm = () => {
// const form = useRef();
// const checkBtn = useRef();
// const [details, setDetails] = useState(true);
// const [shopUpload, setShopUpload] = useState(false);
// const [eror, setEror] = useState(false);
// const [errMessage, setErrMessage] = useState("");
// const [submitted, setSubmitted] = useState(false);
// const [isloading, setisloading] = useState(false);
// const [width, setWidth] = React.useState(window.innerWidth);
// const [shoplocation, setshoplocation] = useState("");
// const [locationError, setlocationError] = useState(false);
// const [country, setcountry] = useState("");
// const [state, setstate] = useState("");

// const [formm, setFormm] = useState({
// phone_number: "",
// shop_name: "",
// shop_description: "",
// city: "",
// state: state,
// country: country,
// identification_type: "",
// identity_number: "",
// office_address: "",
// company_photo: "",
// referral_code: "",
// });

// // console.log(formm.company_photo);

// async function grabLoggedInUser() {
// const resp = await authService.getCurrentLoggedInUser();

// if (resp.success == true) {
// const response = resp.data;
// setFormm({
// ...formm,
// phone_number: response.phone_number,
// office_address: response.address,
// city: response.city,
// state: response.state,
// });
// } else {
// console.log("none");
// }
// }

// const changeHandle = (e) => {
// setFormm({
// ...formm,
// [e.target.name]: e.target.value,
// });
// };

// const nextBtn = () => {
// form.current.validateAll();
// if (checkBtn.current.context._errors.length === 0) {
// setDetails(false);
// setShopUpload(true);
// } else {
// }
// };

// const backBtn = () => {
// setDetails(true);
// setShopUpload(false);
// };

// const [images, setImages] = React.useState([]);
// const maxNumber = 1;

// const [checkboxValue, setCheckboxValue] = useState(true);

// const onChange = (imageList, addUpdateIndex) => {
// // data for submit
// const loadedImage = imageList;
// let newArry = imageList.map((item) => item.data_url);
// setImages(imageList);
// if (loadedImage) {
// setFormm({
// ...formm,
// company_photo: newArry[0],
// });
// }
// };

// const updateLocation = () => {
// const comps = shoplocation.address_components;
// console.log(comps);
// setFormm({
// ...formm,
// office_address: shoplocation,
// });
// if (locationError) {
// setlocationError(false);
// }
// };

// useEffect(() => {
// updateLocation();
// }, [shoplocation]);

// const submitForm = (e) => {
// setisloading(true);
// console.log(formm);
// e.preventDefault();

// form.current.validateAll();
// if (shoplocation === "") {
// setlocationError(true);
// } else {
// setlocationError(false);
// }

// if (checkBtn.current.context._errors.length === 0) {
// formm.state = state;
// formm.country = country;

// axios
// .post(API_URL + "part-dealer/create", formm, {
// headers: authHeader(),
// })
// .then((res) => {
// setisloading(false);
// setSubmitted(true);
// })
// .catch((error) => {
// const resMessage =
// (error.response &&
// error.response.data &&
// error.response.data.message) ||
// error.message ||
// error.toString();
// setisloading(false);
// setEror(true);
// setErrMessage(resMessage);
// });
// } else {
// setisloading(false);
// }
// };

// useEffect(() => {
// grabLoggedInUser();
// }, []);

// useEffect(() => {
// window.addEventListener("resize", () => setWidth(window.innerWidth));
// if (width > 800) {
// setShopUpload(true);
// } else if (width < 800) { setShopUpload(false); } }, [width]); return ( <main className="steps_main_wrapper">
//   {width > 800 && (
//   <div className="details_parts_header"> Spare Parts Details</div>
//   )}
//   {width < 800 && ( <section className="st_topBar">
//     <p className="st_branding">
//       {details && "Personal Details"}
//       {shopUpload && "Upload Shop Photo"}
//     </p>
//     <p className="st_back" onClick={backBtn}>
//       Back
//     </p>
//     </section>
//     )}
//     <Form id="ad-form " className="ad-form adFormNew become-a-seller" onSubmit={submitForm} ref={form}>
//       {width > 800 && (
//       <div className="personal-details-shop-reg">
//         <p>Personal Details:</p>
//       </div>
//       )}

//       <section>
//         {details && (
//         <section className="details_step_form">
//           <div>
//             <label htmlFor="phone">
//               Phone Number
//               <Input type="number" placeholder="Enter Phone Number" name="phone_number" maxLength="13"
//                 validations={[required]} onChange={changeHandle} value={formm.phone_number} />
//             </label>
//             <label htmlFor="idtype">
//               Type of ID (Optional)
//               <Select name="identification_type" onChange={changeHandle} value={formm.identification_type}>
//                 <option value="">Select ID Type</option>
//                 <option value="International Passport">
//                   International Passport
//                 </option>
//                 <option value="NIN">National Identity Number</option>
//                 <option value="Voters Card Number">
//                   Voters Card Number
//                 </option>
//               </Select>
//             </label>
//           </div>
//           <div>
//             <label htmlFor="id">
//               ID Number (Optional)
//               <Input type="text" placeholder="Enter ID Number" name="identity_number" onChange={changeHandle}
//                 value={formm.identity_number} />
//             </label>
//             <label htmlFor="referal" style={{ display: "flex" , alignItems: "center" , flexDirection: "row" , }}>
//               <input type="checkbox" name="" id="checkbox_st" /> Not
//               Applicable
//             </label>
//           </div>
//           {width > 800 && (
//           <div className="personal-details-shop-reg">
//             <p>Shop Details:</p>
//           </div>
//           )}
//           <div className="shop-deeds">
//             <label htmlFor="shopName">
//               Shop Name
//               <Input type="text" placeholder="Enter Shop Name" name="shop_name" validations={[required]}
//                 onChange={changeHandle} value={formm.shop_name} />
//             </label>
//             <label htmlFor="id">
//               Shop Address
//               <Autocomplete className="autolocation" onPlaceSelected={(place)=> {
//                 const selectedAddress = place.formatted_address;
//                 const addressComponents = place.address_components;

//                 let state = "";
//                 let country = "";

//                 for (let i = 0; i
//                 < addressComponents.length; i++) { const types=addressComponents[i].types; for (let j=0; j <
//                   types.length; j++) { if (types[j]==="administrative_area_level_1" ) {
//                   state=addressComponents[i].long_name; } if (types[j]==="country" ) {
//                   country=addressComponents[i].long_name; } } } setstate(state); setcountry(country);
//                   setshoplocation(selectedAddress); }} onChange={updateLocation} options={{ types:
//                   ["establishment", "geocode" ], }} />
//                 {locationError && (
//                 <div style={{ color: "red" , fontSize: "11px" , marginTop: "10px" , }}>
//                   Address is required.
//                 </div>
//                 )}
//             </label>
//           </div>
//           <div>
//             <label htmlFor="idtype" className="textArea-label">
//               Shop Description
//               <Textarea type="text" style={{ height: "200px" }} placeholder="Enter Shop Description"
//                 name="shop_description" validations={[required]} onChange={changeHandle}
//                 value={formm.shop_description} />
//             </label>
//           </div>

//           <div>
//             <label htmlFor="code">
//               Referral Code
//               <Input type="text" name="referral_code" onChange={changeHandle} value={formm.referral_code}
//                 placeholder="Enter Referal Code" />
//             </label>
//             <label htmlFor="referal" style={{ display: "flex" , alignItems: "center" , flexDirection: "row" , }}>
//               <input type="checkbox" name="" id="checkbox_st" /> Not
//               Applicable
//             </label>
//           </div>
//         </section>
//         )}

//         {shopUpload && (
//         <section className="st_shop_pix">
//           <div style={{ flexDirection: "column" }}>
//             {width > 800 && (
//             <div style={{ margin: "20px 0 10px" , fontSize: "14px" }}>
//               Upload Company Photo
//             </div>
//             )}
//             <ImageUploading value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
//               {({
//               imageList,
//               onImageUpload,
//               onImageRemoveAll,
//               onImageUpdate,
//               onImageRemove,
//               isDragging,
//               dragProps,
//               }) => (
//               <div className="st_shop_image">
//                 <section className="st_ppix">
//                   {imageList.map((image, index) => (
//                   <div key={index} className="sh_photo_wrap">
//                     <img src={image["data_url"]} alt="quickmechs-product-image" width="50" height="50" />
//                     <div className="st_delete" onClick={()=> onImageRemove(index)}
//                       >
//                       <RiDeleteBin5Line />
//                     </div>

//                     {/*
//                   </div> */}
//               </div>
//               ))}
//         </section>
//         {imageList == 0 && (
//         <div className="sh_photo_info" id="info_mob-st" onClick={onImageUpload}>
//           <p className="plus_st">
//             <AiOutlinePlus />
//           </p>
//           <p className="addPhoto">
//             <span>
//               <ImImages />
//             </span>
//             Add Photo
//           </p>
//           <p className="num_uploaded">Uploaded 0/1 images</p>
//         </div>
//         )}
//         </div>
//         )}
//         </ImageUploading>
//         <p className="st_notice">
//           Picture must not exceed 5MB. Suported formats are *jpg, *gif
//           and *png
//         </p>
//         </div>
//       </section>
//       )}
//       </section>

//       {width < 800 && ( <section className="steps_indicator">
//         <article className="st_indicator">
//           <div className="indicator" style={ details ? { borderColor: "var(--primary2)" } : { borderColor: "lightgrey" }
//             }>
//             1. Details
//           </div>
//           <div className="indicator" style={ shopUpload ? { borderColor: "var(--primary2)" } : {
//             borderColor: "lightgrey" } }>
//             2. Upload
//           </div>
//         </article>
//         <div>
//           {shopUpload ? (
//           <button className="st_btn">Submit</button>
//           ) : (
//           <p className="st_btn" onClick={nextBtn}>
//             Next
//           </p>
//           )}
//         </div>
//         </section>
//         )}
//         <CheckButton style={{ display: "none" }} ref={checkBtn} />
//         {width > 800 && <button className="st_btn">Submit</button>}
//     </Form>
//     {eror && (
//     <section className="err_wrap">
//       <div className="err">
//         {errMessage === "One or more fields are invalid"
//         ? "Shop Photo is required"
//         : errMessage}
//         <p className="err_btn" onClick={()=> setEror(false)}>
//           <GrFormClose />
//         </p>
//       </div>
//     </section>
//     )}
//     {submitted && (
//     <section style={_x.modal_bg}>
//       <div open={submitted===false} style={{ border: "none" }}>
//         <div style={_x.modall}>
//           <div style={{ width: "100%" , display: "flex" , justifyContent: "flex-end" , }}></div>

//           <h5 style={{ fontFamily: "montserrat" , fontSize: "16px" , textAlign: "center" , fontWeight: "500" , margin:
//             0, }}>
//             Congratulations
//           </h5>

//           <p style={{ fontFamily: "montserrat" , fontSize: "14px" , textAlign: "center" , width: "70%" , }}>
//             You have successfully registered as a Spare Part Dealer
//           </p>
//           <article style={{ display: "flex" , alignItems: "center" , width: "100%" , }}>
//             <Link to="/home/submit-an-ad" style={{ textDecoration: "none" , width: "50%" }}>
//             <button style={_x.continue}>Post an Ad</button>
//             </Link>
//             <Link to="/home/dashboard" style={{ textDecoration: "none" , width: "50%" }}>
//             <button style={_x.continue2}>Go to Dashboard</button>
//             </Link>
//           </article>
//         </div>
//       </div>
//     </section>
//     )}
//     <Spinner isloading={isloading} />
//     </main>
//     );
//     };

//     export default NewPartsDealerStepsForm;

//     const _x = {
//     btn: {
//     background: "transparent",
//     height: 30,
//     width: 20,
//     fontSize: 22,
//     color: "grey",
//     borderRadius: 0,
//     padding: 0,
//     margin: 0,
//     },
//     title_sect: {
//     marginBottom: "53px",
//     textAlign: "center",
//     },

//     header: {
//     fontWeight: "bold",
//     fontSize: 15,
//     },
//     header2: {
//     fontWeight: "normal",
//     fontSize: "14px",
//     marginBottom: "2px",
//     color: "#222222",
//     },
//     bottom: {
//     display: "flex",
//     flexDirection: "row",
//     },
//     btn2: {
//     color: "var(--primary1)",
//     fontFamily: "montserrat",
//     paddingTop: "10px",
//     },
//     modall: {
//     background: "white",
//     width: 391,
//     height: 236,
//     outline: "none",
//     borderRadius: 6,
//     margin: "170px auto",
//     display: "grid",
//     placeItems: "center",
//     padding: "20px 15px",
//     position: "absolute",
//     top: "20%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     boxShadow: "1px 1px 18px #00000040",
//     },

//     continue: {
//     height: 40,
//     backgroundColor: "var(--primary2)",
//     // paddingLeft: 15,
//     // paddingRight: 15,
//     color: "#fff",
//     borderRadius: 6,
//     fontWeight: "bold",
//     fontSize: 16,
//     marginBottom: "1.2rem",
//     width: "95%",
//     },
//     continue2: {
//     height: 40,
//     backgroundColor: "#fff",
//     // paddingLeft: 15,
//     // paddingRight: 15,
//     border: "1px solid var(--primary2)",
//     color: "var(--primary2)",
//     borderRadius: 6,
//     fontWeight: "bold",
//     fontSize: 16,
//     marginBottom: "1.2rem",
//     width: "95%",
//     },

//     close: {
//     cursor: "pointer",
//     },
//     modal_bg: {
//     position: "fixed",
//     top: "0",
//     left: "0",
//     height: "100vh",
//     width: "100%",
//     backgroundColor: "#00000090",
//     overflow: "hidden",
//     },
//     };
