import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Banner from "src/Components/Functional/Banner/Banner";
import FloatingAdButton from "src/Components/Functional/floatingAdButton/FloatingAdButton";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Margin } from "../../../Components/Style/Styles";
import dealer from "../../../Resources/Icons/dealer.png";
import insurance from "../../../Resources/Icons/insurance.png";
import loan from "../../../Resources/Icons/loan.png";
import cleanBodyWork from "../../../Resources/Images/cleanbodywork.jpg";
import lexus from "../../../Resources/Images/lexus.png";
import tire from "../../../Resources/Images/tire.png";
import "./MechanicAndServices.css";

function MechanicAndServices() {
	const _mobile = useMediaQuery("(max-width:800px)");
	const _history = useHistory();

	return (
		<>
			<Banner />

			<div className="home-row-1" style={_x(_mobile).homerow_1}>
				<div className="home-row-1-container">
					<h2 className="need-help">Do you need help?</h2>

					<div className="home-row-1-row-1">
						<button
							className="home-row-1-row-1-box"
							onClick={() => _history.push("/home/car-loan")}
						>
							<img src={loan} alt="loan" />
							Get a Car Loan
						</button>

						<button
							className="home-row-1-row-1-box"
							onClick={() => _history.push("/home/car-insurance")}
						>
							<img src={insurance} alt="insurance" />
							Insure your Car
						</button>

						<button
							className="home-row-1-row-1-box"
							onClick={() => _history.push("/home/car-dealer")}
						>
							<img src={dealer} alt="dealer" />
							Car Dealer
						</button>
					</div>
				</div>
			</div>

			<Margin>
				<div style={_x(_mobile).homerow_2}>
					<h2 className="need-help">Advertisement</h2>

					<div className="home-row-2-grid-box">
						<div className="home-row-2-grid-box-b1">
							<img
								className="home-row-2-grid-box-b1-row-1"
								src={lexus}
								alt="lexus"
							/>

							<div className="home-row-2-grid-box-b1-row-2">
								<div style={{ marginTop: "3px" }}>Lexus LC 500</div>

								<div>
									Get the latest Lexus LC 500 from your best dealer in the city
								</div>
							</div>
						</div>

						<div className="home-row-2-grid-box-b2">
							<div className="home-row-2-grid-box-b2-col-1">
								<img src={tire} alt="tire" />

								<div style={_x().ad_column}>
									<div style={_x().tire_header}>Superb tire & Wheels</div>
									<div>Get your tire & wheels at 50% off today</div>

									<FloatingAdButton />
								</div>
							</div>

							<div className="home-row-2-grid-box-b2-col-2">
								<img src={cleanBodyWork} alt="tire" />

								<div style={_x().ad_column}>
									<div style={_x().tire_header}>Clean Body Shop</div>

									<div>Get a $ 50,000 value for a $ 5,000 job.</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Helmet>
					<title>Quickmechs</title>

					<meta
						name="description"
						content="This platform has given you the opportunity to meet 
						with dealers of all kinds of cars, trucks, SUVS, etc, and then 
						get the best repair services. Here Mechanics offer a full range 
						of mechanical services which include"
					/>
				</Helmet>

				<DownloadApp />
			</Margin>

			<Footer useExplore />
		</>
	);
}

export default MechanicAndServices;

const _x = (_mobile) => ({
	twitter: {
		background: "white",
		borderRadius: 5,
		width: 38,
		height: 38,
		marginBottom: 2,
		display: "grid",
		placeItems: "center",
	},

	ad_column: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		padding: 10,
		position: "relative",
	},

	ad_service: {
		width: _mobile ? "90%" : "100%",
		display: "grid",
		placeItems: "center",
		marginRight: _mobile ? 20 : 0,
		marginLeft: _mobile ? 20 : 0,
		// background: "red",
	},

	tire_header: {
		fontSize: 16,
		color: "var(--secondary1)",
		fontWeight: "bold",
		marginBottom: 10,
	},

	homerow_1: {
		display: "flex",
		justifyContent: "center",
		// padding: _mobile ? "0px" : "2rem 10rem",
		maxHeight: _mobile ? "" : 200,
		paddingTop: _mobile ? 0 : "4vh",
		marginBottom: _mobile ? 30 : "",
	},

	homerow_2: {
		margin: _mobile ? 0 : "1rem auto ",
		width: "100%",
		maxWidth: _mobile ? "100%" : 1200,
		justifyContent: "center",
		height: "100%",
		// background: "red",
	},

	ad_oil: {
		maxWidth: "1200px",
		margin: "3rem auto",
	},

	ad_oil_main: {
		maxWidth: "960px",
		margin: "3rem auto",
	},
});
