import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import "./category.css";

const Category = () => {
	const currentUser = AuthService.getCurrentUser("user");
	const userRole = currentUser.data.user_info.permissions.map((role) => {
		// console.log(role);
		return role;
	});

	const dealer = userRole.includes("part_dealer");
	const mech = userRole.includes("mechanic");

	const location = useLocation();
	let history = useHistory();

	const [isMechanic, setIsMechanic] = useState(true);
	const [userType, setUserType] = useState("mechanic");

	const displaySubCategory = (e) => {
		const userType = e.target.value;
		setUserType(userType);

		if (e.target.value !== "mechanic") {
			setIsMechanic(false);
		} else {
			setIsMechanic(true);
		}
	};

	const loadAdvert = (e) => {
		e.preventDefault();

		let user_id = `${currentUser.data.user_info.id}`;
		let user_type = `${currentUser.data.user_info.user_type}`;

		if (dealer && userType === "part_dealer") {
			history.push(`/home/post-free-ad`);
		} else if (!dealer && userType === "part_dealer") {
			history.push(`/auth/ad/${user_id}/part_dealer/form`);
		} else if (mech && userType === "mechanic") {
			history.push(`/home/ad/${user_id}/post-free-ad`);
		} else if (!mech && userType === "mechanic") {
			history.push(`/auth/ad/${user_id}/mechanic/form`);
		} else {
			history.push("/auth/login");
		}
	};

	return (
		<form className="cat-wrapper">
			<div className="title-section">
				<p>Post a free Advert</p>
			</div>
			<section>
				<div className="category">
					<p>Category</p>

					<select
						name="userType"
						id=""
						onChange={displaySubCategory}
						value={userType}
					>
						<option value="mechanic">Mechanic & Services</option>
						<option value="part_dealer">Part Dealer</option>
					</select>

					{isMechanic ? (
						<p id="cat-description">
							{" "}
							For Mechanics, please select the appropriate sub-category or
							categories that apply to your field of specialization.{" "}
						</p>
					) : null}
				</div>
				{isMechanic ? (
					<div className="sub-category">
						<div className="sub-title">
							<p>Sub-Category</p>
						</div>
						<select name="" id="">
							<option value="">Mechanics</option>
							<option value="">Body Shops</option>
							<option value="">Car Ac</option>
							<option value="">Electrical</option>
							<option value="">Tire Shop</option>
							<option value="">Towing</option>
							<option value="">Security</option>
						</select>
					</div>
				) : null}
			</section>
			<button className="cont-btn" onClick={loadAdvert}>
				{" "}
				Continue
			</button>
		</form>
	);
};

export default Category;
