import { Paper } from '@material-ui/core';
import React from 'react';
import Footer from '../../../Components/Functional/Footer/Footer';
import { Spacing } from '../../../Components/Style/Styles';
import { Helmet } from 'react-helmet';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useState } from 'react';
import userService from 'src/services/user.service';
import Popup from 'src/Components/Functional/Popup/Popup';
import { useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';

function ContactUs() {
  const [form, setform] = useState({
    full_name: '',
    email: '',
    message: '',
  });

  const [popup, setpopup] = useState(false);

  const [showfielderror, setshowfielderror] = useState(false);

  const _mobile = useMediaQuery('(max-width:800px)');

  const simpleValidator = useRef(new SimpleReactValidator());

  function updateForm(e, field) {
    setform({ ...form, [`${field}`]: e.target.value });
  }

  async function submit() {
    setshowfielderror(true);

    if (simpleValidator.current.allValid()) {
      const res = await userService.contactUs(
        form.full_name,
        form.email,
        form.message
      );

      if (res.success) {
        setpopup(true);
        setshowfielderror(false);
      }
    }
  }

  function closePopup() {
    setpopup(false);
    setform({ ...form, full_name: '', email: '', message: '' });
  }

  return (
    <>
      <div style={_x(_mobile).contactUs_alpha}>
        <section style={_x(_mobile).contactUs} elevation={_mobile ? 0 : 1}>
          <p style={_x(_mobile).text1}>Help !</p>

          <input
            value={form.full_name}
            className="sigma"
            type="text"
            style={_x(_mobile).input}
            placeholder="Full Name"
            onChange={(e) => updateForm(e, 'full_name')}
          />
          <div
            style={{
              display: showfielderror ? 'block' : 'none',
            }}
          >
            {simpleValidator.current.message(
              'full name',
              form.full_name,
              'required|full_name',
              {
                element: (msg) => <div style={_x().error}>{msg}</div>,
              }
            )}
          </div>

          <input
            value={form.email}
            className="sigma"
            onBlur={simpleValidator.current.showMessageFor('email')}
            type="email"
            style={_x(_mobile).input}
            placeholder="Email Address"
            onChange={(e) => updateForm(e, 'email')}
          />
          <div
            style={{
              display: showfielderror ? 'block' : 'none',
            }}
          >
            {simpleValidator.current.message(
              'email',
              form.email,
              'required|email',
              {
                element: (msg) => <div style={_x().error}>{msg}</div>,
              }
            )}
          </div>

          <textarea
            value={form.message}
            className="sigma"
            style={_x(_mobile).textarea}
            placeholder="Message Here"
            onChange={(e) => updateForm(e, 'message')}
          />
          <div
            style={{
              visibility: showfielderror ? 'visible' : 'hidden',
            }}
          >
            {simpleValidator.current.message(
              'message',
              form.message,
              'required',
              {
                element: (msg) => (
                  <div style={_x().error}>message is required !</div>
                ),
              }
            )}
          </div>

          <button style={_x(_mobile).btn} onClick={submit}>
            Send Message
          </button>

          <p style={_x(_mobile).text2}>
            Thank You for your response. We will get back to you shortly.
          </p>
        </section>
        <Footer />

        <Popup
          isOpen={popup}
          popupMessage="Message Sent"
          popupMessageStyles={{ fontWeight: 'bold' }}
          continuePressed={closePopup}
          closePressed={closePopup}
        />
      </div>

      <Helmet>
        <title>{'Talk to Us - Quickmechs'}</title>
        <meta
          name="description"
          content="quickmechs is your best 
					stop for finding auto mechanics and workshops near you 
					for efficient car repair and great spare part deals."
        />
      </Helmet>

      {/* <Spacing vertical={_mobile ? 0 : 100} /> */}
    </>
  );
}

export default ContactUs;

const _x = (_mobile) => ({
  error: {
    color: 'red',
    fontSize: 10,
    marginTop: 10,
  },

  contactUs_alpha: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: _mobile ? 'white' : 'none',
    minHeight: '100vh',
  },

  contactUs: {
    // background: 'red',
    width: _mobile ? '90%' : 860,
    // height: 600,
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: _mobile ? 0 : 40,
    padding: _mobile ? '20px' : 50,
  },

  text1: {
    color: 'var(--secondary1)',
    fontSize: 20,
    fontWeight: 500,
  },

  text2: {
    color: 'var(--primary1)',
    fontSize: 12,
    fontWeight: 500,
    width: _mobile ? '70%' : '100%',
    textAlign: 'center',
  },

  input: {
    width: _mobile ? '100%' : 408,
    height: 40,
    borderRadius: 6,
    border: '1px solid #DBDEE7',
    fontSize: 12,
    margin: '10px 0',
  },

  textarea: {
    height: 72,
    width: _mobile ? '100%' : 408,
    padding: 10,
    resize: 'none',
    border: '1px solid #DBDEE7',
    fontSize: 12,
    margin: '10px 0',
    borderRadius: '8px',
  },

  btn: {
    width: _mobile ? '100%' : 408,
    height: 40,
    background: 'var(--primary1)',
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    borderRadius: 6,
    fontFamily: 'montserrat',
    margin: '20px 0 0',
  },
});
