import { Spacing } from "src/Components/Style/Styles";
import down_arrow from "src/Resources/Icons/down_arrow.png";

export default function PersonalSection({
	title,
	form,
	setform,
	_mobile,
	simpleValidator,
	updateTextField,
	selectDropDown,
	//...
	vehicledropdown,
	specialtydropdown,
	updateVehicleType,
	updateSpecialty,
	toggleVehicleDropdown,
	toggleSpecialtyDropDown,
}) {
	const _y = {
		super_dropdown_box: {
			transform: `scaleY(${vehicledropdown.visible ? 1 : 0})`,
		},
		super_dropdown_box_2: {
			transform: `scaleY(${specialtydropdown.visible ? 1 : 0})`,
		},
	};

	return (
		<div style={{ height: _mobile ? "60vh" : "100%" }}>
			{title}

			<div
				style={{
					..._x().row,
					width: _mobile ? "105%" : "108%",
				}}
			>
				<div>
					<p>Specialty</p>

					<div style={_x().super_dropdown} onClick={toggleSpecialtyDropDown}>
						<div
							className="hideScroll hideScroll-firefox"
							style={_x(_mobile).vehicle_type_list}
						>
							{specialtydropdown?.tags?.map((e) => (
								<div style={_x().vehcle_tag}>
									<div
										onClick={(ev) => {
											ev.stopPropagation();
										}}
										style={_x().close}
									>
										x
									</div>

									{e}
								</div>
							))}
						</div>

						<img src={down_arrow} style={_x().vehicle_type_icon} alt="" />

						{specialtydropdown.visible && (
							<div
								style={{
									..._x().super_dropdown_box,
									..._y.super_dropdown_box_2,
									overflowX: "hidden",
									width: "100%",
									bottom: -195,
									// background: "red",
									// minHeight: 100,
								}}
								className="scroll-bar-type-2"
							>
								{Object.keys(specialtydropdown.stuff).map((key, val) => {
									return (
										<div
											key={key + Math.floor(Math.random() * 100)}
											style={{
												..._x().vehicle_option,
												justifyContent: "space-between",
											}}
											onClick={(e) => {
												e.stopPropagation();
												updateSpecialty(key);
											}}
										>
											<div
												style={{
													whiteSpace: "nowrap",
													cursor: "pointer",
													// background: "red",
													maxWidth: "70%",
													overflowX: "hidden",
												}}
											>
												{key}
											</div>

											<input
												checked={specialtydropdown.stuff[key]}
												style={_x().checkbox}
												type="checkbox"
												onChange={() => updateSpecialty(key)}
											/>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>

				<div>
					<p>Vehicle type</p>

					<div style={_x().super_dropdown} onClick={toggleVehicleDropdown}>
						<div
							className="hideScroll hideScroll-firefox"
							style={_x(_mobile).vehicle_type_list}
						>
							{vehicledropdown?.tags?.map((e) => (
								<div style={_x().vehcle_tag}>
									<div
										onClick={(ev) => {
											ev.stopPropagation();
											updateVehicleType(e);
										}}
										style={_x().close}
									>
										x
									</div>

									{e}
								</div>
							))}
						</div>

						<img src={down_arrow} style={_x().vehicle_type_icon} />

						{vehicledropdown.visible && (
							<div
								style={{
									..._x().super_dropdown_box,
									..._y.super_dropdown_box,
								}}
								// className="hideScroll"
								className="scroll-bar-type-2"
							>
								{Object.keys(vehicledropdown.stuff).map((key, val) => {
									return (
										<div
											key={key}
											style={_x().vehicle_option}
											onClick={(e) => {
												e.stopPropagation();
												updateVehicleType(key);
											}}
										>
											<div style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
												{key}
											</div>

											<input
												checked={vehicledropdown.stuff[key]}
												style={_x().checkbox}
												type="checkbox"
												onChange={() => updateVehicleType(key)}
											/>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
			<Spacing />
			<div
				style={{
					..._x().row,
					width: _mobile ? "105%" : "108%",
				}}
			>
				<div>
					<p>Years Of Experience</p>

					<input
						style={{
							..._x().input,
							width: "100%",
							textAlign: "start",
						}}
						placeholder="Enter years of experience"
						value={form.experience}
						type="number"
						onChange={(e) => updateTextField(e.target.value, "exp")}
						onBlur={simpleValidator.current.showMessageFor(
							"Years Of Experience"
						)}
						onKeyPress={(e) => {
							if (e.key === "e" || e.key === "-") e.preventDefault();
							if (form.experience.length > 1) e.preventDefault();
						}}
					/>

					<Spacing vertical={10} />

					<div
						style={{
							visibility: form.showfielderror ? "visible" : "hidden",
						}}
					>
						{simpleValidator.current.message(
							"Years Of Experience",
							form.experience,
							"required|integer",
							{
								element: (msg) => <div style={_x().error}>{msg}</div>,
							}
						)}
					</div>
				</div>

				<div style={{ width: "100%" }}>
					<div>
						<p>Referral Code (optional)</p>

						<input
							value={form.referralCode}
							style={{
								..._x().input,
								width: "100%",
								textAlign: "start",
							}}
							placeholder={`Enter referral code`}
							type="number"
							onChange={(e) => updateTextField(e.target.value, "referral")}
							onBlur={simpleValidator.current.showMessageFor("referral")}
							disabled={form.skipReferralCode}
						/>

						<Spacing vertical={10} />

						{/* <div
							style={{
								visibility: form.showfielderror ? "visible" : "hidden",
							}}
						>
							{simpleValidator.current.message(
								"Id No.",
								form.referralCode,
								`${form.skipReferralCode ? "" : "required|integer"}`,

								{
									element: (msg) => <div style={_x().error}>{msg}</div>,
								}
							)}
						</div> */}
					</div>

					{/* <div
						style={{
							display: "flex",
							alignItems: "center",
							// marginTop: 18,
						}}
					>
						<input
							checked={form.skipReferralCode}
							type="checkbox"
							onChange={() =>
								setform({
									...form,
									idNumber: "",
									skipReferralCode: !form.skipReferralCode,
								})
							}
						/>

						<Spacing horizontal={2} />

						<div style={{ fontSize: 10, color: "black" }}>
							i dont have a referral code
						</div>
					</div> */}
				</div>
			</div>
			<Spacing vertical={_mobile ? 0 : 20} />
		</div>
	);
}

const _x = (_mobile) => ({
	error: {
		color: "red",
		fontSize: 10,
	},

	row: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
		gridGap: 20,
	},

	input: {
		width: "100%",
		border: "1px solid #DBDEE7",
		borderRadius: 3,
	},

	select: {
		height: 32,
		width: "100%",
		border: "1px solid #DBDEE7",
		borderRadius: 3,
	},

	ref_row: {
		display: "flex",
		alignItems: "center",
		marginTop: 30,
	},

	//....

	super_dropdown: {
		border: "1px solid #DBDEE7",
		height: 35,
		display: "flex",
		alignItems: "center",
		position: "relative",
		width: "100%",
	},

	vehicle_type_icon: {
		height: "100%",
		width: 30,
		height: 20,
		display: "grid",
		placeItems: "center",
		objectFit: "contain",
	},

	vehicle_type_list: {
		flex: 1,
		height: "100%",
		paddingLeft: 15,
		display: "flex",
		alignItems: "center",
		overflowX: "scroll",
	},

	vehcle_tag: {
		height: 20,
		padding: 2,
		display: "flex",
		background: "var(--accent_opacity)",
		alignItems: "center",
		borderRadius: 3,
		fontWeight: 600,
		color: "var(--primary1)",
		marginRight: 5,
		whiteSpace: "nowrap",
	},

	vehicle_option: {
		display: "flex",
		justifyContent: "space-between",
		marginBottom: 10,
		cursor: "pointer",
	},

	close: {
		fontWeight: "bold",
		color: "var(--primary1)",
		marginRight: 5,
		cursor: "pointer",
	},

	super_dropdown_box: {
		position: "absolute",
		maxHeight: 250,
		width: "100%",
		background: "white",
		bottom: -260,
		left: 0,
		boxShadow: "0 2px 5px rgba(0, 0, 0, 0.25)",
		elevation: 2,
		overflowY: "scroll",
		padding: 15,
		paddingRight: 10,
		zIndex: 10,
		borderRadius: 5,
	},

	custCheckBox: {
		display: "flex",
		alignItems: "center",
		marginRight: 13,
	},

	spec_box: {
		border: "1px solid grey",
		flexWrap: "wrap",
		display: "flex",
		alignItems: "center",
		flexDirection: "horizontal",
		padding: 6,
		paddingLeft: 15,
		paddingRight: 15,
		borderRadius: 3,
	},

	error: {
		color: "red",
		fontSize: 10,
	},

	row: {
		display: "grid",
		gridTemplateColumns: "45% 45%",
		gridGap: 20,
		width: "100%",
	},

	input: {
		width: "100%",
		border: "1px solid #DBDEE7",
		borderRadius: 3,
		width: 50,
		textAlign: "center",
	},

	checkbox_container: {
		display: "flex",
		alignItems: "center",
	},

	checkbox: {
		height: 15,
		width: 15,
		marginRight: 10,
		cursor: "pointer",
	},
});
