import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import TokenRefresher from "src/Components/Functional/TokenRefresher/TokenRefresher";
import authService from "src/services/auth.service";
import Emitter from "src/services/emitter";
import Auth from "./Auth/Auth";
import Home from "./Home/Home";
import NotFound from "./NotFound";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Playground from "./Playground";

function Routes() {
	const history = useHistory();

	const location = useLocation();

	const [refresher, setrefresher] = useState(false);

	const _mobile = useMediaQuery("(max-width:800px)");

	useEffect(() => {
		Emitter.on("token expired", () => {
			setrefresher(true);

			// console.log("token just expired");
		});

		Emitter.on("token refresh failed", () => {
			// console.log("token just expired");
			// setrefresher(false);
			// authService.logout();
			// history.push("/auth/login");
		});
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				backgroundColor: "white",
			}}
		>
			{/* {refresher && (
				<TokenRefresher setIsOpen={setrefresher} isOpen={refresher} />
			)} */}

			<Switch>
				<Route exact path="/" component={Home} />

				<Route path="/auth" component={Auth} />

				<Route path="/home" component={Home} />

				<Route path="/playground" component={Playground} />

				<Route component={NotFound} />

				<Redirect to="/auth" />
			</Switch>
		</div>
	);
}

export default Routes;
