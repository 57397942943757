import { useMediaQuery } from "@material-ui/core";
import { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import breakdown from "../../../Resources/Images/download2.png";
import { countryCodes } from "../../../Resources/Resources";
import "./downloadApp.css";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? "https://dev.aftj.quickmechs.com"
		: REACT_APP_CURRENT_ENVIRONMENT === "STAGING"
		? "https://staging.quickmechs.com"
		: "https://quickmechs.com";

function DownloadApp() {
	const [phone, setphone] = useState();

	const [showfielderror, setshowfielderror] = useState(false);

	const simpleValidator = useRef(new SimpleReactValidator());

	const history = useHistory();

	const _mobile = useMediaQuery("(max-width: 800px)");

	function handleChange() {}

	function downloadApp() {
		setshowfielderror(true);

		if (simpleValidator.current.allValid())
			history.push({ pathname: "/home/download-app/" });
	}

	return (
		<div
			style={_x(_mobile).downloadApp}
			className="download-app home-box-border"
		>
			<img
				className="download-app-img"
				src={breakdown}
				alt="breakdown"
				style={{
					width: 270,
					height: 270,
					objectFit: "cover",
				}}
			/>

			<div className="download-app-col2">
				<div className="download-app-col2-row1">
					<div className="download-app-text1">
						Stay in the know with the Quickmechs App
					</div>

					<div className="download-app-text2">
						Get real-time notification, view your appointments, and access
						mobile-only deals
					</div>
				</div>

				<div className="download-app-col2-row2">
					<div className="download-app-text3">
						Text Yourself a download link
					</div>

					<div className="download-app-col2-row1-row1">
						<select style={_x(_mobile).input} name="NG +234">
							{countryCodes.map((e, i) => (
								<option
									key={i.toString()}
									value={`${e.iso} +${e.code}`}
								>{`${e.iso} +${e.code}`}</option>
							))}

							{/* <option value="SA +233">SA +233</option> */}
						</select>

						<div>
							<input
								value={phone}
								style={_x(_mobile).input}
								placeholder="phone number"
								onChange={(e) => {
									if (showfielderror) setshowfielderror(false);
									setphone(e.target.value);
								}}
								onBlur={simpleValidator.current.showMessageFor("phone number")}
								onKeyPress={(e) => {
									if (e.key === "e" || e.key === "-" || e.key === "+") {
										e.preventDefault();
									}
									if (phone?.length === 11) e.preventDefault();
								}}
							/>

							<div
								style={{ visibility: showfielderror ? "visible" : "hidden" }}
							>
								{simpleValidator.current.message(
									"phone number",
									phone,
									"required|number",
									{
										element: (msg) => (
											// <div style={_x().error}>phone number is required !</div>
											<div style={_x().error}>{msg}</div>
										),
									}
								)}
							</div>
						</div>

						<button style={_x(_mobile).getApp} onClick={downloadApp}>
							Get the app
						</button>
					</div>
				</div>

				<div className="download-app-text4">
					By providing your number, you agree to receive a one-time automated
					text message with a link to get the app. Standard text message rates
					may apply.
				</div>
			</div>

			{!_mobile && (
				<div style={_x(_mobile).qrcode_box}>
					<QRCode value={`${API_URL}/home/verify-device`} size={150} />

					<div style={_x(_mobile).scancode}>Scan the Qr Code</div>
				</div>
			)}
		</div>
	);
}

export default DownloadApp;

const _x = (_mobile) => ({
	downloadApp: {
		margin: _mobile ? "0 auto" : "2rem auto",
		maxWidth: 1200,
		// minWidth: 1200,
		// marginRight: 15,
		// marginLeft: 15,
	},

	input: {
		width: "100%",
		height: 40,
		borderRadius: 5,
		border: "1px solid lightgrey",
	},

	getApp: {
		backgroundColor: " var(--primary1)",
		color: "white",
		padding: 10,
		borderRadius: 5,
		paddingLeft: 12,
		paddingRight: 12,
		width: _mobile ? "100%" : 160,
		height: 40,
		fontWeight: "bold",
	},

	error: { marginTop: 10, color: "red", fontSize: 10 },

	qrcode_box: {
		display: "grid",
		placeItems: "center",
		marginRight: 20,
		paddingBottom: 45,
		paddingTop: 20,
	},

	qrcode: {
		height: 150,
		width: 150,
		// background: "red",
	},

	scancode: {
		fontWeight: "bold",
		fontSize: 15,
	},
});
