import { Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import mechService from "src/services/mech.service";
import { Hr, Spacing } from "../../../Components/Style/Styles";
import { skillTree } from "../../../Resources/Resources";

function Satisfaction() {
	const [form, setform] = useState({
		satisfaction: "",
		recommendation: "",
		comment: "",
		email: "",
		category: "",
	});

	const [isloading, setisloading] = useState(true);

	const [showfielderror, setshowfielderror] = useState(false);

	const [confirmation, setconfirmation] = useState(false);

	const simpleValidator = useRef(new SimpleReactValidator());

	const recoBtn = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	const satBtn = [1, 2, 3, 4, 5];

	const _mobile = useMediaQuery("(max-width:800px)");

	function updateForm(val, field) {
		if (field === "satisfaction" || field === "recommendation") {
			const allChips = document.getElementsByClassName(field + "chips");

			Array.from(allChips).forEach((each) => {
				each.style.background = "white";
				each.style.color = "grey";

				if (field === "satisfaction") {
					each.childNodes[1].childNodes[0].style.fill = "grey";
				}
			});

			const chip = document.getElementById(
				`${field === "recommendation" ? "rec" : "sat"}chip${val}`
			);

			chip.style.background = "var(--primary1)";
			chip.style.color = "white";

			if (field === "satisfaction") {
				chip.childNodes[1].childNodes[0].style.fill = "white";
			}
		}

		setform({ ...form, [`${field}`]: val });
	}

	async function submitForm() {
		setshowfielderror(true);

		setisloading(true);

		if (simpleValidator.current.allValid()) {
			const resp = await mechService.submitFeedback(
				form.satisfaction,
				form.recommendation,
				form.comment,
				form.email,
				form.category
			);

			setisloading(false);

			if (resp.success) {
				// console.log("success");
				setconfirmation(true);
			} else {
				// console.log("failed");
			}
		}
	}

	function selectCategory(e) {
		setform({ ...form, cateory: e.target.value });
	}

	return (
		<div style={{ display: "grid", placeItems: "center" }}>
			{confirmation ? (
				<div style={_x(_mobile).conf_body}>
					<p style={{ color: "var(--secondary1)", fontWeight: "bold" }}>
						Thank you for your feedback.
					</p>

					<p style={{ color: "var(--secondary1)" }}>
						Thank you for taking the time to submit your feedback. We appreciate
						your help and look into each report we receive. While we cannot
						guarantee a response, we can assure you that the report will be read
						and investigated.
					</p>
				</div>
			) : (
				<Paper style={_x(_mobile).satisfaction} elevation={_mobile ? 0 : 1}>
					<div style={_x(_mobile).topbar}>
						<span style={_x().text1}>Alert:</span>

						<Spacing horizontal={5} />

						<span style={_x().text2}>Find out how</span>

						<Spacing horizontal={5} />

						<span style={_x().text1}>
							COVID-19 may affect your repair plans
						</span>
					</div>

					<p style={_x().text3}>Thank you in advance for your feedback</p>

					<Spacing />

					<p style={_x().text3}>
						We’re unable to respond to support quaries raised via this form.
						Please visit our
						<span style={{ marginLeft: 5, marginRight: 5 }}>
							<Link
								to="/home/contact-us/"
								style={{ ..._x().text5, textDecoration: "none" }}
							>
								Customer Support Page
							</Link>
						</span>
						if you require further assistance with your bookings.
					</p>

					<p style={_x().text4}>
						Overall Satisfaction with Page
						<span style={{ color: "#CF2D48" }}>*</span>
					</p>

					<div style={_x().sat_rate}>
						{satBtn.map((e) => (
							<button
								key={e}
								className="satisfactionchips"
								id={`satchip${e}`}
								style={_x().btn}
								onClick={(evt) => updateForm(e, "satisfaction")}
							>
								<div>{e}</div>

								<svg
									height="511pt"
									viewBox="0 -10 511.99143 511"
									width="511pt"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: 8 }}
								>
									<path
										fill="grey"
										d="m510.652344 185.882812c-3.371094-10.367187-12.566406-17.707031-23.402344-18.6875l-147.796875-13.417968-58.410156-136.75c-4.3125-10.046875-14.125-16.53125-25.046875-16.53125s-20.738282 6.484375-25.023438 16.53125l-58.410156 136.75-147.820312 13.417968c-10.835938 1-20.011719 8.339844-23.402344 18.6875-3.371094 10.367188-.257813 21.738282 7.9375 28.925782l111.722656 97.964844-32.941406 145.085937c-2.410156 10.667969 1.730468 21.699219 10.582031 28.097656 4.757813 3.457031 10.347656 5.183594 15.957031 5.183594 4.820313 0 9.644532-1.28125 13.953125-3.859375l127.445313-76.203125 127.421875 76.203125c9.347656 5.585938 21.101562 5.074219 29.933593-1.324219 8.851563-6.398437 12.992188-17.429687 10.582032-28.097656l-32.941406-145.085937 111.722656-97.964844c8.191406-7.1875 11.308594-18.535156 7.9375-28.925782zm-252.203125 223.722657"
									/>
								</svg>
							</button>
						))}
					</div>

					<div style={{ width: "100%", position: "relative" }}>
						<div style={_x().cat_select}>
							<select
								style={{ border: "none" }}
								className="change-select"
								onChange={selectCategory}
								onBlur={simpleValidator.current.showMessageFor("category")}
							>
								<option>select category</option>
								{Object.keys(skillTree).map((key, val) => (
									<option>{key}</option>
								))}
							</select>
						</div>

						{showfielderror &&
							simpleValidator.current.message(
								"category",
								form.category,
								form.category === "" || form.category === "select category"
									? "required"
									: "",
								{
									element: (msg) => (
										<div style={{ ..._x().error, position: "absolute" }}>
											select a category!
										</div>
									),
								}
							)}
					</div>

					<Spacing vertical={10} />

					<p style={_x().text4}>
						How likely is it that you would recommend quickmechs to a friend or
						colleague with 0 being not likely and 10 being extremely likely?
					</p>

					<div style={_x().sat_rate}>
						{recoBtn.map((e) => (
							<button
								className="recommendationchips"
								id={`recchip${e}`}
								onClick={(evt) => updateForm(e, "recommendation")}
								style={_x().btn}
							>
								{e}
							</button>
						))}
					</div>

					<Spacing vertical={30} />

					<textarea
						value={form.comment}
						onChange={(e) => updateForm(e.target.value, "comment")}
						style={_x(_mobile).textarea}
						placeholder="Please include anything else you’d like us to know"
						onBlur={simpleValidator.current.showMessageFor("comment")}
						type="comment"
						name="comment"
					/>
					{showfielderror &&
						simpleValidator.current.message("email", form.comment, "required", {
							element: (msg) => (
								<div style={_x().error}>comment is required !</div>
							),
						})}

					<Spacing vertical={20} />

					<p style={_x().text4}>Email address (optional)</p>

					<input
						value={form.email}
						placeholder="example@gmail.com"
						style={_x().input}
						onChange={(e) => updateForm(e.target.value, "email")}
						onBlur={simpleValidator.current.showMessageFor("email")}
						type="email"
						name="email"
					/>
					{showfielderror &&
						simpleValidator.current.message(
							"email",
							form.email,
							"required|email",
							{
								element: (msg) => <div style={_x().error}>{msg}</div>,
							}
						)}

					<Spacing />

					<p style={{ fontSize: 11, color: "var(--secondary1)" }}>
						The email address you provided helps us further investigate issues
						with your account and will only be used for that purpose.
					</p>

					<Hr thickness={1} />

					{/* <div style={{ display: "flex" }}>
						<span style={{ color: "#cf2d48" }}>*</span>
						<Spacing horizontal={2} />
						<span style={{ color: "var(--secondary1)" }}>= required</span>
					</div> */}

					<Spacing />

					<button style={_x().submit} onClick={submitForm}>
						Submit
					</button>

					<Spacing />

					<Hr thickness={1} />
				</Paper>
			)}
		</div>
	);
}

export default Satisfaction;

const _x = (_mobile) => ({
	satisfaction: {
		width: _mobile ? "100%" : 576,
		backgroundColor: "white",
		padding: 30,
		margin: _mobile ? 0 : 20,
	},

	conf_body: {
		marginTop: 30,
		width: _mobile ? "90%" : "40%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},

	error: {
		color: "red",
		fontSize: 10,
		marginTop: 10,
	},

	topbar: {
		// height: 30,
		display: "flex",
		// flexDirection: "row",
		backgroundColor: "#f0dcb4",
		justifyContent: _mobile ? "flex-start" : "center",
		alignItems: "center",
		borderRadius: 5,
		padding: 10,
		flexWrap: "wrap",
	},

	submit: {
		backgroundColor: "var(--primary1)",
		paddingLeft: 15,
		paddingRight: 15,
		border: "1px solid ",
		height: 32,
		width: "100%",
		color: "white",
		borderRadius: 6,
	},

	text1: {
		fontWeight: "bold",
		color: "#a52a2a",
	},

	text2: {
		color: "var(--primary1)",
		textDecoration: "underline",
		fontWeight: "bold",
		cursor: "pointer",
		whiteSpace: "nowrap",
	},

	text3: {
		color: "var(--secondary2)",
	},

	text4: {
		fontWeight: "bold",
		color: "var(--secondary1)",
		lineHeight: 1.8,
	},

	text5: {
		marginLeft: 2,
		fontWeight: "bold",
		color: "var(--primary1)",
		textDecoration: "underline",
		cursor: "pointer",
	},

	sat_rate: {
		display: "flex",
		flexWrap: "wrap",
		marginBottom: 20,
	},

	btn: {
		height: 25,
		width: 38,
		border: "0.5px solid grey",
		borderRadius: 4,
		marginRight: 20,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 10,
	},

	input: {
		fontSize: 10,
		width: "100%",
		height: 32,
	},

	textarea: {
		fontSize: 15,
		width: _mobile ? "100%" : 525,
		height: 130,
		padding: 15,
		color: "var(--secondary2)",
	},

	cat_select: {
		// borderColor: "grey",
		height: 25,
		width: "100%",
		// border: "0.5px solid #646464",
		border: "0.5px solid grey",
		borderRadius: 5,
		display: "grid",
		justifyContent: "stretch",
		paddingLeft: 10,
		paddingRight: 10,
	},
});
