import React from 'react'

export default function EditShopSection({...props}) {
  return (
   <div style={styles.container} {...props}>
<div style={styles.wrapper}>
    <img src={require('../../../../Resources/Icons/edit-shop-icon.svg').default}/>
    <span style={{fontWeight:500}}>Edit Shop</span>
   </div>
   </div>
  )
}



const styles={
  container:{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',borderBottom:'1px solid #C4C4C4',height:60},
wrapper:{width:'90%',display:'flex',flexDirection:'row',alignItems:'center',gap:10,height:'100%'}
}