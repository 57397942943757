import { Modal } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Align, Spacing } from "src/Components/Style/Styles";
import close2 from "src/Resources/Icons/close2.png";

function Popup({
	type,
	isOpen,
	buttonTitle,
	popupMessage,
	closePressed,
	continuePressed,
	buttonColor,
	vertical,
	popupMessageStyles,
	errorMessages,
	style,
	headerText,
	buttonStyles,
	hideCloseButton,
	actionComps,
	buttonContainerStyles,
}) {
	const _mobile = useMediaQuery("(max-width:800px)");

	function getPopupType() {
		let resolution;

		switch (type) {
			case "error":
				resolution = (
					<Modal
						open={isOpen}
						onClick={closePressed}
						style={{ border: "none" }}
					>
						<Align verticalHeight={vertical || 100}>
							<div style={{ ..._x(_mobile).modal, ...style }}>
								<div style={_x().error_type}>
									<div style={_x().error_header}>
										<div />

										<img
											onClick={closePressed}
											style={_x().close}
											src={close2}
											alt="close"
										/>
									</div>

									<div
										style={{
											width: "100%",
											display: "grid",
											placeItems: "center",
										}}
									>
										<div style={{ fontWeight: 600, fontSize: 18 }}>Error</div>
									</div>

									<div style={_x().message_list}>
										{errorMessages !== null &&
											errorMessages !== undefined &&
											Object?.keys(errorMessages)?.map((key, _) => {
												// console.log("err->", errorMessages, key);

												return errorMessages?.[key]?.map((e) => (
													<div style={_x().message_item}>{e}</div>
												));
											})}
									</div>

									<div
										style={{
											width: "100%",
											display: "grid",
											placeItems: "center",
										}}
									>
										<button
											onClick={continuePressed}
											style={{
												..._x().continue,
												background: buttonColor || "var(--primary1)",
											}}
										>
											{buttonTitle || "Go Back"}
										</button>
									</div>
								</div>
							</div>
						</Align>
					</Modal>
				);
				break;

			case "popup1":
				resolution = (
					<div style={{ padding: 20, display: "grid" }}>
						<div style={{ display: "grid", justifyContent: "center" }}>
							{headerText && (
								<h2
									style={{
										textAlign: "center",
										fontSize: 16,
										fontWeight: 500,
									}}
								>
									{headerText}
								</h2>
							)}

							<p
								style={{
									fontFamily: "montserrat",
									fontSize: "16px",
									textAlign: "center",
									...popupMessageStyles,
								}}
							>
								{popupMessage}
							</p>

							<Spacing />

							<div
								style={{
									display: "flex",
									justifyContent: "center",
									...buttonContainerStyles,
								}}
							>
								<button
									onClick={continuePressed}
									style={{
										..._x().continue,
										background: buttonColor || "var(--primary1)",
										color: "white",
										border: "none",
										width: null,
									}}
								>
									{buttonTitle || "Go To Dashboard"}
								</button>

								{actionComps}
							</div>
						</div>
					</div>
				);
				break;

			default:
				resolution = (
					<Modal
						open={isOpen}
						onClick={closePressed}
						style={{ border: "none" }}
					>
						<Align verticalHeight={vertical || 100}>
							<div style={{ ..._x(_mobile).modal }}>
								{
									<div style={{ padding: 20, display: "grid" }}>
										<div
											style={{
												width: "100%",
												display: "flex",
												justifyContent: "flex-end",
											}}
										>
											{!hideCloseButton && (
												<img
													onClick={closePressed}
													style={_x().close}
													src={close2}
													alt="close"
												/>
											)}
										</div>

										<div style={{ display: "grid", justifyContent: "center" }}>
											{headerText && (
												<h2 style={{ textAlign: "center" }}>{headerText}</h2>
											)}

											<p
												style={{
													fontFamily: "montserrat",
													fontSize: "16px",
													textAlign: "center",
													...popupMessageStyles,
												}}
											>
												{popupMessage}
											</p>

											<Spacing />

											<div
												style={{
													display: "flex",
													justifyContent: "center",
													...buttonContainerStyles,
												}}
											>
												<button
													onClick={continuePressed}
													style={{
														..._x().continue,
														background: buttonColor || "var(--primary1)",
														color: "white",
														...buttonStyles,
													}}
												>
													{buttonTitle || "Continue"}
												</button>

												{actionComps}
											</div>
										</div>
									</div>
								}
							</div>
						</Align>
					</Modal>
				);
				break;
		}

		return resolution;
	}

	return (
		<Modal
			open={isOpen}
			onClick={closePressed}
			style={{
				border: "none",
			}}
		>
			<Align verticalHeight={vertical || 100}>
				<div style={{ ..._x(_mobile).modal }}>{getPopupType()}</div>
			</Align>
		</Modal>
	);
}

export default Popup;

const _x = (_mobile) => ({
	modal: {
		background: "white",
		width: _mobile ? "90vw" : 400,
		minHeight: 196,
		outline: "none",
		borderRadius: 6,
		margin: "170px auto",
		placeItems: "center",
	},

	error_type: {},

	continue: {
		height: 40,
		width: 137,
		paddingLeft: 15,
		paddingRight: 15,
		border: "1px solid",
		borderRadius: 6,
		fontWeight: "bold",
		fontSize: 16,
		marginBottom: "1.7rem",
	},

	error_header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: 40,
		width: "100%",
		// background: "var(--primary1)",
		paddingLeft: 20,
		paddingRight: 20,
		borderTopRightRadius: 6,
		borderTopLeftRadius: 6,
		// color: "white",
	},

	close: {
		cursor: "pointer",
		width: 12,
		objectFit: "contain",
	},

	message_list: {
		fontFamily: "montserrat",
		fontSize: 15,
		fontWeight: "bold",
		textAlign: "center",
		height: "100%",
		display: "grid",
		padding: 20,
		alignItems: "center",
		justifyContent: "center",
	},

	message_item: {
		marginBottom: 10,
		textAlign: "left",
		lineHeight: 1.5,
		fontSize: 16,
		fontWeight: 500,
	},
});
