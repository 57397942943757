import React from "react";
import midnightImg from "../../../Resources/Icons/earlymorning.png";
import morningImg from "../../../Resources/Icons/morning.png";
import afternoonImg from "../../../Resources/Icons/afternoon.png";
import eveningImg from "../../../Resources/Icons/evening.png";
import { Spacing } from "../../Style/Styles";

function TimeBox({
	midnight,
	morning,
	afternoon,
	evening,
	pressable,
	onClick,
	isActive,
}) {
	const timeOfDay = morning
		? "Morning"
		: afternoon
		? "Afternoon"
		: evening
		? "Evening"
		: "Midnight";

	return (
		<button
			style={{
				..._x.timebox,
				transform: pressable ? "0.1s ease-in-out" : "none",
				borderColor: isActive ? "var(--primary1)" : "var(--secondary3)",
			}}
			onClick={() => onClick(isActive ? "" : timeOfDay)}
		>
			<img
				style={_x.star}
				src={
					midnight
						? midnightImg
						: morning
						? morningImg
						: afternoon
						? afternoonImg
						: evening
						? eveningImg
						: null
				}
				alt="sun"
			/>
			<Spacing vertical={5} />

			{<div style={_x.meridian}>{timeOfDay}</div>}

			<p style={_x.time}>
				{midnight && <>12:00 AM - 5:59 PM</>}
				{morning && <>06:00 AM - 11:59 AM</>}
				{afternoon && <>12:00 PM - 5:59 PM</>}
				{evening && <>06:00 PM - 11:59 PM</>}
			</p>
		</button>
	);
}

export default TimeBox;

const _x = {
	timebox: {
		border: "1px solid var(--secondary3)",
		width: 127,
		height: 70,
		display: "grid",
		placeItems: "center",
		padding: 2,
		marginBottom: 10,
		background: "white",
	},

	star: {},

	meridian: {
		fontSize: 11,
		// fontWeight: 500,
	},

	time: {
		fontSize: 11,
	},
};
