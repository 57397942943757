import React, { useState, useEffect } from "react";
import { GiMechanicGarage } from "react-icons/gi";
import "../seller/dashboard.css";
import "./dealer.css";
import AuthService from "../../../services/auth.service";
import { useLocation, useHistory, Link, useParams } from "react-router-dom";
import LoginPopUp from "src/Components/Functional/loginpop/LoginPopUp";
import ProfileDP from "src/Components/Functional/profileImage/ProfileDP";
import { Helmet } from "react-helmet";
import { MdPhoneInTalk } from "react-icons/md";
import DealerAds from "./DealerAds";
import axios from "axios";
import { authHeader } from "../../../services/auth-header";
import moment from "moment";
import { Modal } from "@material-ui/core";
import { GrFormClose } from "react-icons/gr";

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const Dealershop = () => {
  const [isloading, setisloading] = useState(false);

  const [displayMechanic, setDisplayMechanic] = useState(true);
  const [displayCalender, setDisplayCalender] = useState(false);
  const [displayreview, setDisplayReview] = useState(false);
  const [info, setInfo] = useState([]);
  const [revealPhone, setRevealPhone] = useState(false);
  const [loginpopup, setloginpopup] = useState(false);

  const currentUser = AuthService.getCurrentUser("user");
  // const userType = currentUser.data.user_info.permissions.map((role) => {
  // 	return role;
  // });

  // let history = useHistory();
  const { id } = useParams();
  // let shop = localStorage.getItem("shop");
  // const dealer = userType.includes("part_dealer");
  // const mech = userType.includes("mechanic");



  const getUser = () => {
      const storeApi = API_URL + "find-user/" + `${id}` + "?fullDetails=true";
      console.log(storeApi,"================================== api endpoint =================")
    axios
      .get(storeApi, {
        headers: authHeader(),
      })
      .then((response) => {
        const res = response.data.data.part_dealer;
        console.log(response.data,"============DATA DATA DATA ===========DEALER PAGE=============");
        setInfo(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmLogin = () => {
    if (currentUser) {
      setRevealPhone(true);
    } else {
      setloginpopup(true);
    }
  };

  function onLogin(status) {
    if (status === "success") {
      setloginpopup(false);
    }
  }

  useEffect(() => {
    getUser();
    // console.log(currentUser);
  }, []);

  return (
    <main className="wrap-dealer">
      <main className="dashboard dealer_dash">
        <section className="user-panel dealer-panel-main">
          <div className="panel-top dealer-pane">
            <div className="user-image deal-img">
              <img src={info.company_photo} alt="" />
            </div>
            <div className="username user-dealer">
              <p className="mob_shop_name">{info.shop_name}</p>
              <p className="dash-joined">
                Joined: {moment(info.created_at).format("MMMM, YYYY")}{" "}
              </p>
              <div className="dish-div"></div>
              <p className="dealer-address">
                {info.office_address}, {info.city} {info.state}
              </p>

              {revealPhone ? (
                <p
                  className="click-to-view"
                  style={{ fontSize: "14px", width: "80%" }}
                >
                  {info.phone_number}
                </p>
              ) : (
                <p
                  className="click-to-view"
                  style={{ width: "80%" }}
                  onClick={confirmLogin}
                >
                  <span
                    style={{
                      marginRight: "1rem",
                      fontSize: "20px",
                      paddingTop: "5px",
                    }}
                  >
                    <MdPhoneInTalk />
                  </span>
                  Show Number
                </p>
              )}
            </div>
          </div>
        </section>

        <section className="dealer-details dealer-details">
          <div className="details-top">
            <p style={{ fontSize: "18px" }}>{info.shop_name}</p>
          </div>

          <DealerAds />
        </section>

        <Helmet>
          <title>Dealer Shop - quickmechs Solutions</title>
        </Helmet>
        <Modal open={loginpopup}>
          <LoginPopUp onLogin={onLogin}>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => setloginpopup(false)}
            >
              <GrFormClose />
            </p>
          </LoginPopUp>
        </Modal>
      </main>
    </main>
  );
};

export default Dealershop;
