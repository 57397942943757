import { Redirect, Route, Switch } from 'react-router';
import SellersForm from './SellersForm/SellersForm';
import Login from './Login/Login';
import Forgot from './ForgotPassword/ForgotPassword';
import UpdatePassword from './ForgotPassword/UpdatePassword';
import Register from './Register/Register';
import Verification from './Verification/Verification';
import Category from './category/Category';
import RegCongfirmation from './RegConfirmation/RegConfirmation';
import EditMech from './EditRoles/EditMech';
import EditDealer from './EditRoles/EditDealer';
import UpdateProfilePhoto from './profilephoto/UploadProfilePhoto';
import NotFound from '../NotFound';
import Address from './Register/Address';

function Auth() {
  return (
    <div style={{ height: '100%', width: '100%', backgroundColor: 'white' }}>
      <Switch>
        <Route path={`/auth/login`} exact component={Login} />

        <Route
          path={`/auth/:ref/:user_id/:user_type/form`}
          exact
          component={SellersForm}
        />

        <Route path={`/auth/register`} exact component={Register} />

        <Route
          path={`/auth/update-profile-details`}
          exact
          component={Address}
        />

        <Route path={`/auth/forgot-password`} exact component={Forgot} />

        <Route
          path={`/auth/upload-profile-photo`}
          exact
          component={UpdateProfilePhoto}
        />

        <Route
          path={`/auth/:userInput/status=success/update-password`}
          exact
          component={UpdatePassword}
        />

        <Route path={`/auth/verify`} exact component={Verification} />

        <Route path={`/auth/edit-mechanic-form`} exact component={EditMech} />

        <Route path={`/auth/edit-dealer-form`} exact component={EditDealer} />

        <Route
          path="/auth/:user_id/:firstname/advert-category"
          exact
          component={Category}
        />

        <Route
          path={`/auth/:ref/:user_type/confirmation`}
          exact
          component={RegCongfirmation}
        />

        <Route component={NotFound} />

        <Redirect to={`/home/mechanic-and-services`} />
      </Switch>
    </div>
  );
}

export default Auth;
