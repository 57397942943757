import { Paper } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Popup from "src/Components/Functional/Popup/Popup";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import ProfileDP from "src/Components/Functional/profileImage/ProfileDP";
import { authHeader } from "src/services/auth-header";
import AuthService from "src/services/auth.service";
import { Hr, Spacing } from "../../../Components/Style/Styles";
import Rating from "./Rating";
import "./productReview.css";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;

function ProductReview() {
	const currentUser = AuthService.getCurrentUser("user");

	const [reviewform, setreviewform] = useState({
		overall_rating: "",
		headline: "",
		written_review: "",
		value_for_money: "",
		quality: "",
		durability: "",
		display_name: currentUser.data.user_info.first_name,
		photos: [],
	});

	const { id } = useParams();

	const singleProducts = API_URL + "products/find/" + `${id}`;

	useEffect(() => {
		getData();
		checkk();
	}, []);

	const [popup, setpopup] = useState(false);
	const [isloading, setisloading] = useState(false);
	const [message, setMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [displayNameError, setDisplayNameError] = useState("");
	const [headlineError, setHeadlineError] = useState("");
	const [writtenError, setwrittenError] = useState("");
	const [over, setOver] = useState("");

	const history = useHistory();

	const submitReview = () => {
		setisloading(true);

		// console.log(reviewform);

		axios
			.post(API_URL + "review/rate-product/" + `${id}`, reviewform, {
				headers: authHeader(),
			})
			.then((res) => {
				setisloading(false);
				setpopup(true);
			})
			.catch((error) => {
				const resp = error.response.data.errorSource;
				if (resp.display_name) {
					setDisplayNameError(resp.display_name);
				}
				if (resp.headline) {
					setHeadlineError(resp.headline);
				}
				if (resp.overall_rating) {
					setOver(resp.overall_rating);
				}
				if (resp.written_review) {
					setwrittenError(resp.written_review);
				}

				setisloading(false);
				setMessage(true);
				// setErrorMessage(resp);
				// console.log(error.response.data.errorSource.display_name);
				return { success: false, errorSource: error.response.data.errorSource };
			});
	};

	const updateRating = (rating, header) => {
		setreviewform({ ...reviewform, overall_rating: rating });
	};
	const updateDurability = (rating, header) => {
		setreviewform({ ...reviewform, durability: rating });
	};
	const updateQuality = (rating, header) => {
		setreviewform({ ...reviewform, quality: rating });
	};
	const updateMoney = (rating, header) => {
		setreviewform({ ...reviewform, value_for_money: rating });
	};
	const updateHeadline = (e) => {
		setreviewform({ ...reviewform, [e.target.name]: e.target.value });
	};

	const [data, setData] = useState("");
	const [imgData, setImgData] = useState([]);

	const getData = () => {
		axios
			.get(singleProducts)
			.then((res) => {
				setData(res.data.data);
				setImgData(res.data.data.product_photo[0]);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const checkk = () => {
		axios
			.get(API_URL + "review/get-product-reviews/" + `${id}`, {
				headers: authHeader(),
			})
			.then((res) => {
				console.log(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [images, setImages] = React.useState([]);
	const maxNumber = 5;

	const onChange = (imageList, addUpdateIndex) => {
		// data for submit
		const loadedImage = imageList;
		let newArry = imageList.map((item) => item.data_url);
		setImages(imageList);
		if (loadedImage) {
			setreviewform({
				...reviewform,
				photos: newArry,
			});
		}
	};

	return (
		<div style={{ display: "grid", placeItems: "center" }}>
			<Paper className="rate_product_coll">
				<div className="display-image-pr">
					<div className="pr-image">
						<img src={imgData} alt="" />
					</div>
					<div className="pr-details-coll">
						<p className="details-name"> {data.product_title} </p>
						<p className="details-numb"> {data.product_no} </p>
						<p className="details-price">$ {data.price}</p>
					</div>
				</div>
				<Hr thickness color />
				<Rating
					adjustible
					header="Overall Rating"
					headerStyle={{
						fontWeight: 500,
						fontSize: 14,
						color: "var(--secondary1)",
					}}
					name
					onClicked={updateRating}
				/>
				{over !== "" && reviewform.overall_rating === "" ? (
					<p className="review-error"> {over} </p>
				) : null}

				<Hr thickness color />

				<p style={_x.header}>Rate features</p>

				<Rating adjustible header="Value for money" onClicked={updateMoney} />
				<Spacing />

				<Rating
					adjustible
					color="yellow"
					header="Durability"
					onClicked={updateDurability}
				/>
				<Spacing />

				<Rating
					adjustible
					color="yellow"
					header="Quality"
					onClicked={updateQuality}
				/>

				<Hr thickness color />

				<p style={_x.header}>Rate features</p>

				<p style={_x.text1}>
					Customers find images and video more helpful than text alone
				</p>

				<div style={_x.image_row}>
					<ImageUploading
						multiple
						value={images}
						onChange={onChange}
						maxNumber={maxNumber}
						dataURLKey="data_url"
					>
						{({
							imageList,
							onImageUpload,
							onImageRemoveAll,
							onImageUpdate,
							onImageRemove,
							isDragging,
							dragProps,
						}) => (
							// write your building UI
							<div style={{ display: "flex" }}>
								<p
									className="image-up"
									style={isDragging ? { color: "red" } : undefined}
									onClick={onImageUpload}
									{...dragProps}
								>
									+
								</p>
								&nbsp;
								<section
									style={{
										display: "grid",
										gridGap: "5px",
										gridTemplateColumns: "repeat(3, 1fr)",
									}}
								>
									{imageList.map((image, index) => (
										<div key={index} className="image-item">
											<img
												src={image["data_url"]}
												alt=""
												width="100"
												height="100"
											/>
											<div style={{ position: "relative" }}>
												{/* <p onClick={() => onImageUpdate(index)}>
																<MdAutorenew /> 
																</p> */}
												<p
													className="close_prod_rev_btn"
													onClick={() => onImageRemove(index)}
												>
													{/* <RiDeleteBin6Line/> */}x
												</p>
											</div>
										</div>
									))}
								</section>
							</div>
						)}
					</ImageUploading>
				</div>

				<Hr thickness color />

				<p style={_x.header}>Add a headline</p>

				<input
					name="headline"
					value={reviewform.headline}
					style={_x.input}
					placeholder="whats more important to know"
					onChange={updateHeadline}
				/>
				{headlineError !== "" && reviewform.headline === "" ? (
					<p className="review-error"> {headlineError} </p>
				) : null}

				<Spacing />

				<p style={_x.header}>Add a written review</p>

				<textarea
					name="written_review"
					value={reviewform.written_review}
					style={_x.textarea}
					placeholder="What did you like or dislike? What did you use this product for?"
					onChange={updateHeadline}
				/>
				{writtenError !== "" && reviewform.written_review === "" ? (
					<p className="review-error"> {writtenError} </p>
				) : null}

				<Spacing />

				<p style={_x.header}>Choose your Public Name</p>

				<p style={_x.text1}>This is how you’ll appear to other customers</p>

				<Spacing />

				<div style={_x.avatar_box}>
					<div style={_x.avatar}>
						<ProfileDP
							style={{ width: "44px", height: "40px", color: "#587FC0" }}
							size={{ fontSize: "1.1rem" }}
						/>
					</div>

					<input
						name="display_name"
						value={reviewform.display_name}
						style={_x.input2}
						placeholder="Enter Name Here"
						onChange={updateHeadline}
					/>
				</div>
				{setDisplayNameError !== "" && reviewform.display_name === "" ? (
					<p className="review-error"> {displayNameError} </p>
				) : null}

				<p style={_x.text1}>
					Don’t worry, you can always change this on your profile
				</p>

				<Spacing />

				<div style={_x.moveToRight}>
					<button style={_x.submit} onClick={submitReview}>
						Submit
					</button>
				</div>
			</Paper>

			<Popup
				isOpen={popup}
				popupMessage="Review Posted"
				popupMessageStyles={{ fontWeight: "bold" }}
				closePressed={() => {
					history.push(
						"/home/spare-parts/" + `${id}` + "/" + `${data.product_title}`
					);
					setpopup(false);
				}}
			/>

			<Spinner isloading={isloading} />
		</div>
	);
}

export default ProductReview;

const _x = {
	error: { marginTop: 10, color: "red", fontSize: 10 },

	modal: {
		outline: "none",
		height: "100%",
		display: "grid",
		placeItems: "center",
	},

	header: {
		fontWeight: 500,
		fontSize: 14,
		color: "var(--secondary1)",
	},

	image_row: {
		// background: "red",
		display: "flex",
		// alignItems: "center",
		// flexDirection: "row-reverse",
		flexWrap: "wrap",
		// paddingRight: 30,
		width: "100%",
	},

	add_image_icon: {
		height: 50,
		width: 50,
		display: "grid",
		placeItems: "center",
		border: "1px solid var(--secondary2)",
		cursor: "pointer",
	},

	image_box: {
		height: 100,
		width: 100,
		marginRight: 11,
		marginBottom: 10,
		position: "relative",
	},

	close: {
		position: "absolute",
		height: 20,
		width: 20,
		// background: "red",
		top: 0,
		right: 0,
		zIndex: 100,
		display: "grid",
		placeItems: "center",
		cursor: "pointer",
	},

	input: {
		width: "100%",
		// color: "var(--secondary2)",
		fontSize: 12,
		fontFamily: "Montserrat",
	},
	textarea: {
		height: 94,
		width: "100%",
		resize: "none",
		fontSize: 12,
		padding: 10,
		fontFamily: "montserrat",
		// color: "var(--secondary2)",
	},
	submit: {
		height: 32,
		backgroundColor: "var(--primary1)",
		color: "white",
		fontSize: 16,
		width: 174,
		borderRadius: 6,
		fontWeight: "bold",
	},
	moveToRight: {
		display: "flex",
		justifyContent: "flex-end",
	},

	avatar_box: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	avatar: {
		height: 40,
		width: 44,
		borderRadius: "50%",
		background: "var(--secondary3)",
		marginRight: 10,
		display: "grid",
		placeItems: "center",
		overflow: "hidden",
	},

	avatarImg: {
		height: "100%",
		width: "100%",
		color: "white",
		objectFit: "cover",
	},

	input2: {
		height: 30,
		width: "100%",
		fontSize: 12,
	},

	text1: {
		fontSize: 12,
		color: "var(--secondary1)",
	},
};
