import { Modal } from "@material-ui/core";
import { GrFormClose } from "react-icons/gr";

export default function Notice({ onClose, title, description }) {
	return (
		<Modal open>
			<section className="update-pop">
				<div className="firstname">
					<p
						style={{
							fontSize: "18px",
							margin: "36px auto 24px",
							colo: "#000",
							fontWeight: "500",
						}}
					>
						{title}
					</p>

					<p
						style={{
							margin: "0px auto 36px",
							colo: "#000",
							width: "70%",
							fontSize: "14px",
							lineHeight: "26px",
							letterSpacing: "0.5px",
						}}
					>
						{description}
					</p>

					<p className="instantCloseBtn" onClick={onClose}>
						<GrFormClose size={30} />
					</p>
				</div>
			</section>
		</Modal>
	);
}
