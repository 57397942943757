import React, { useState } from "react";
import {
	makes2,
	models,
	skillTree,
	statez,
	years,
	makes3,
} from "src/Resources/Resources";
import search from "src/Resources/Icons/search.png";
import { useHistory } from "react-router-dom";
import { Spacing } from "../../Style/Styles";

export default function SparePartBanner() {
	const [banner2, setbanner2] = useState({
		product: "",
		location: "",
		year: "",
		make: "Select Make",
		model: "",
		engine: "",
	});

	const history = useHistory();

	function goToSPSQueryList() {
		history.push({
			pathname: "/home/s-p-s-querylist/",
			state: {
				product: banner2.product,
				engine: banner2.engine,
				model: banner2.model,
				location: banner2.location,
				year: banner2.year,
				make: banner2.make,
			},
		});
	}

	return (
		<div style={_x.banner_type2}>
			<div style={_x.searchbar_box}>
				<img src={search} alt="search" style={_x.search_icon} />

				<input
					style={_x.search_bar}
					placeholder="Find Parts and Products"
					onChange={(e) => setbanner2({ ...banner2, product: e.target.value })}
				/>
			</div>

			<Spacing />

			<div style={_x.select_list} className="spare-part-banner">
				<select
					value={banner2.location}
					style={{ ..._x.select1, backgroundPositionX: "195px" }}
					onChange={(e) => setbanner2({ ...banner2, location: e.target.value })}
				>
					{Object.keys(statez).map((key, val) => {
						<option value="allLocations">All Locations</option>;

						return (
							<>
								<option key={key} value={key}>
									{key}
								</option>
							</>
						);
					})}
				</select>

				<select
					value={banner2.year}
					style={_x.select2}
					onChange={(e) => setbanner2({ ...banner2, year: e.target.value })}
				>
					<option value="Year">Year</option>

					{years.map((e) => (
						<option value={e}>{e}</option>
					))}
				</select>

				<select
					value={banner2.make}
					style={{ ..._x.select2, backgroundPositionX: "150px" }}
					onChange={(e) => setbanner2({ ...banner2, make: e.target.value })}
				>
					{Object.keys(makes3).map((key, val) => {
						return (
							<option key={key} value={key}>
								{key}
							</option>
						);
					})}
				</select>

				<select
					value={banner2.model}
					id="banner-model"
					style={{
						..._x.select2,
						borderRight: "none",
						backgroundPositionX: "150px",
					}}
					onChange={(e) => setbanner2({ ...banner2, model: e.target.value })}
				>
					<option value="model">Model</option>

					{makes3[banner2.make].map((eachModel) => {
						return (
							<option key={eachModel} value={eachModel}>
								{eachModel}
							</option>
						);
					})}
				</select>
			</div>

			<Spacing />

			<button style={_x.btn} onClick={goToSPSQueryList}>
				Search
			</button>
		</div>
	);
}

const _x = {
	banner_type2: {
		paddingTop: 25,
		paddingBottom: 25,
		gridTemplateRows: "auto auto 1fr",
		display: "grid",
		placeItems: "center",
		width: 1010,
		marginRight: 50,
	},

	bannerBtn: {
		backgroundSize: 20,
		backgroundRepeat: "no-repeat",
		backgroundPositionX: "center",
		backgroundPositionY: 15,
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "center",
		fontSize: 13,
		borderBottom: "4px solid transparent",
	},

	ban_txt: {
		fontSize: 11,
		marginBottom: 15,
		fontWeight: "bold",
	},

	searchbar_box: {
		border: "1px solid var(--secondary3)",
		display: "flex",
		alignItems: "center",
		// marginRight: 60,
		// marginLeft: 60,
		paddingLeft: 10,
		paddingRight: 10,
		borderRadius: 6,
		width: "100%",
	},

	search_icon: {
		height: 20,
		width: 20,
	},

	search_bar: {
		width: "100%",
		border: "none",
	},

	dropDown_box: {
		// height: 149,
		background: "#F2F2F2",
		width: "100%",
		paddingTop: 10,
		paddingLeft: 50,
		paddingRight: 50,
	},

	select_list: {
		height: 54,
		width: "100%",
		background: "white",
		borderRadius: 6,
		display: "grid",
		gridAutoFlow: "column",
		borderRadius: "6px",
		overflow: "hidden",
		paddingRight: "10px",
		border: "1px solid var(--secondary3)",
	},

	select1: {
		height: "100%",
		width: "100%",
		backgroundColor: "#dbdee7",
		borderTopLeftRadius: 6,
		borderBottomLeftRadius: 6,
		border: "1px solid var(--primary1)",
		paddingLeft: "1rem",

		// padding: 20,
		// paddingRight: 20,
		// paddingLeft: 20,
	},

	select2: {
		height: "70%",
		width: "100%",
		border: "none",
		borderRight: "0.5px solid #c4c4c450",
		padding: 10,
		margin: "10px 0 5px",
		paddingLeft: "1rem",
	},

	btn: {
		height: 48,
		width: 174,
		background: "var(--primary1)",
		color: "white",
		fontSize: 18,
		fontWeight: "bold",
		borderRadius: 6,
	},
};
