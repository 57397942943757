import axios from "axios";
import AxiosInstance from "./AxiosInstance";

const user = JSON.parse(localStorage.getItem("user"));
const url = process.env.REACT_APP_JOB_BOARD_URL;
export const addJob = async (data) => {
  const res = await AxiosInstance.post("/job/create", data);
  return res;
};
export const getMyJobsService = async () => {
  const res = await AxiosInstance.get("/job/user");
  return res;
};
export const getAllJobs = async () => {
  const res = await AxiosInstance.get("/job/all");
  return res;
};
export const searchJobs = async (data) => {
  const res = await AxiosInstance.get(`/job/search?title=${data}`);
  return res;
};
export const getStripClientSecret = async () => {
  const job_id = localStorage.getItem("job_id");
  const res = await AxiosInstance.post("/job/initiate-payment", { job_id });
  return res;
};
export const confirmPayment = async (client_secret) => {
  try {
    const res = await AxiosInstance.get(`/verify-payment/${client_secret}`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
export const disableJob = async (id) => {
  const res = await AxiosInstance.put(`/job/${id}/deactivate`);
  return res;
};

export const enableJob = async (id) => {
  const res = await AxiosInstance.put(`/job/${id}/activate`);
  return res;
};
export const deleteJob = async (id) => {
  const res = await AxiosInstance.delete(`/job/${id}/delete`);
  return res;
};
export const createParePartDealer = async (data) => {
  try {
    const res = await AxiosInstance.post(`/part-dealer/create`, data);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
export const createProduct = async (data) => {
  try {
    const res = await AxiosInstance.post(`products/create`, data);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const getShopDetails = async () => {
  try {
    const res = await AxiosInstance.get("/part-dealer/user/shop");
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateShopDetails = async (id, data) => {
  try {
    const res = AxiosInstance.patch(`/part-dealer/${id}/update`, data);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const confirmBookingService = async (id, visitor_id) => {
  try {
    const res = AxiosInstance.get(`/add-appointment-to-calendar/${id}/${visitor_id}`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
