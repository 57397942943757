/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import { GrFormEdit } from "react-icons/gr";
import { TbCalendarStats } from "react-icons/tb";
import { useHistory, useLocation } from "react-router-dom";
import Notice from "src/Components/Notice";
import mechService from "src/services/mech.service";
import ActiveUserAds from "./ActiveUserAds";
import ShopCalendar from "./ShopCalendar";

export default function ShopDetails(props) {
	const [sd, setSd] = useState({
		orderedData: {},
		instantNotice: false,
		mobileNotice: false,
		showCalendar: false,
		showModal: false,
		firstEntry: true,
		showPartsShop: false,
	});
	const [currentShop, setCurrentShop] = useState();
	const history = useHistory();
	const _mobile = useMediaQuery("(max-width:800px)");
	const lS = useLocation();

	

	useEffect(() => {
		if (lS?.state?.cS) {
			setCurrentShop(lS?.state?.cS);
		} else {
			setCurrentShop(props?.currentShop);
		}
	}, [lS.state?.cS, props?.currentShop]);

	useEffect(() => {
		setSd({
			...sd,
			instantNotice: true,
			mobileNotice: false,
		});
	}, [props?.instantNoticeOpen]);

	useEffect(() => {
		setSd({
			...sd,
			instantNotice: false,
			mobileNotice: true,
		});
	}, [props?.mobileNoticeOpen]);

	useEffect(() => {
		setSd({
			...sd,
			showCalendar: props?.showCalendar,
		});
	}, [props?.showCalendar]);

	useEffect(() => {
		setSd({
			...sd,
			showPartsShop: props?.showPartsShop,
		});
	}, [props?.showPartsShop]);

	useEffect(() => {
		const data = currentShop?.working_hours ?? {};

		const sorter = {
			sunday: 0,
			monday: 1,
			tuesday: 2,
			wednesday: 3,
			thursday: 4,
			friday: 5,
			saturday: 6,
		};

		let tmp = [];
		Object.keys?.(data)?.forEach(function (key) {
			let value = data[key];
			let index = sorter[key.toLowerCase()];
			tmp[index] = {
				key: key,
				value: value,
			};
		});

		let orderedData = {};
		tmp.forEach(function (obj) {
			orderedData[obj.key] = obj.value;
		});

		setSd({ ...sd, orderedData });
	}, [currentShop]);

	useEffect(() => {
		if (
			!_mobile &&
			lS.pathname === "/home/dashboard-details" &&
			!sd.firstEntry
		) {
			history.push("/home/dashboard");
		}

		setSd({
			...sd,
			firstEntry: false,
		});
	}, [_mobile]);

	function openCalendar() {
		setSd({
			...sd,
			showCalendar: true,
		});
	}

	function toggleInstantMechanic(shopId, value) {
		mechService.toggleInstantService(shopId, value).then((res) => {
			if (res.success) {
				props?.setAllShops((prevShop) =>
					prevShop.map((shop) => {
						if (shop.encodedKey === shopId) {
							return {
								...shop,
								instant_service: res.data.instant_service,
							};
						}

						return shop;
					})
				);
			}
		});
	}

	function toggleMobileMechanic(shopId, value) {
		mechService.toggleMobileService(shopId, value).then((res) => {
			if (res.success) {
				props?.setAllShops((prevShop) =>
					prevShop.map((shop) => {
						if (shop.encodedKey === shopId) {
							return {
								...shop,
								mobile_service: res.data.mobile_service,
							};
						}

						return shop;
					})
				);
			}
		});
	}

	return (
		<>
			{sd.showCalendar && !_mobile ? (
				<ShopCalendar />
			) : sd.showPartsShop && !_mobile ? (
				<div style={_x().partsShop}>
					<ActiveUserAds singleReview={true} />
				</div>
			) : (
				<div style={_x().sDetails}>
					<div style={_x().r1}>
						<p>{currentShop?.shop_name}</p>
					</div>

					{_mobile && (
						<div style={_x().tabs}>
							<div
								style={{
									..._x().sidebarItemHeader,
									borderRight: "1px solid lightgrey",
								}}
								onClick={openCalendar}
							>
								<TbCalendarStats style={{ fontSize: 20, marginRight: 20 }} />

								<div>Set Schedule</div>

								<div style={{ marginRight: 12 }} />
							</div>

							<div
								style={_x().sidebarItemHeader}
								onClick={() => {
									history.push(
										`/auth/edit/${currentShop?.encodedKey}/mechanic/form`
									);
								}}
							>
								<GrFormEdit style={{ fontSize: 20, marginRight: 20 }} />

								<div>Edit Shop</div>

								<div style={{ marginRight: 12 }} />
							</div>
						</div>
					)}

					{sd.showCalendar && _mobile ? (
						<div style={{ height: "70vh", padding: 0 }}>
							<ShopCalendar />
						</div>
					) : (
						<>
							{_mobile && (
								<div style={_x().r3}>
									<div style={_x().r3_e}>
										<div>
											<span>Instant Service</span>
											<span
												className="learnMoreInstantBtn"
												onClick={() =>
													setSd({
														...sd,
														instantNotice: true,
													})
												}
											>
												Learn More {currentShop?.instant_service}
											</span>
										</div>

										<div
											className="list-icon instantToggle"
											style={{ margin: 0, marginBottom: 10 }}
										>
											<input
												type="checkbox"
												id={`switch-${currentShop?.encodedKey}`}
												onChange={(evt) => {
													toggleInstantMechanic(
														currentShop?.encodedKey,
														evt.target.checked
													);
												}}
												defaultChecked={currentShop?.instant_service === "yes"}
												checked={currentShop?.instant_service === "yes"}
												// style={{ margin: 0, height: "auto" }}
											/>
											<label htmlFor={`switch-${currentShop?.encodedKey}`}>
												Toggle
											</label>
										</div>
									</div>

									<div style={_x().r3_e}>
										<div>
											<span className="edit-sub instantsub">
												Mobile Service
											</span>
											<span
												className="learnMoreInstantBtn"
												onClick={() =>
													setSd({
														...sd,
														mobileNotice: true,
													})
												}
											>
												Learn More
											</span>
										</div>

										<div
											className="list-icon instantToggle"
											style={{ margin: 0, marginBottom: 10 }}
										>
											<input
												type="checkbox"
												id={`switch2-${currentShop?.encodedKey}`}
												onChange={(evt) => {
													toggleMobileMechanic(
														currentShop?.encodedKey,
														evt.target.checked
													);
												}}
												defaultChecked={currentShop?.mobile_service === "yes"}
												checked={currentShop?.mobile_service === "yes"}
											/>

											<label htmlFor={`switch2-${currentShop?.encodedKey}`}>
												Toggle
											</label>
										</div>
									</div>
								</div>
							)}

							{_mobile && (
								<div style={{ display: "grid", placeItems: "center" }}>
									<div
										style={{
											..._x().poster,
											backgroundImage: `url(${currentShop?.cover_image})`,
										}}
									/>
								</div>
							)}

							<div style={_x().r2}>
								<div style={_x(_mobile).r2_sub}>
									<div style={_x().title}>Availability</div>

									<div style={_x().hours}>
										{Object.keys(sd.orderedData).map((key, idx) => {
											return (
												<div style={_x().workDay} key={idx}>
													<div className="available-day" style={_x().dayName}>
														{key}
													</div>
													{sd.orderedData[key].from.hour}:00
													{sd.orderedData[key].from.meridian} -
													{sd.orderedData[key].to.hour}
													:00
													{sd.orderedData[key].to.meridian}
												</div>
											);
										})}
									</div>

									<hr />

									<div className="contact-info-area">
										<p style={_x().title}>Contact Information</p>

										<p className="contact-item">
											<span style={_x().s_title}>Shop Address: </span>
											{currentShop?.office_address}
										</p>

										<p className="contact-item">
											<span style={_x().s_title}>Phone Number: </span>
											{currentShop?.phone_number}
										</p>

										<p className="contact-item">
											<span style={_x().s_title}>Email Address: </span>
											{currentShop?.user?.email}
										</p>
									</div>

									{/* <hr />

					<div className="dashboard-ratings">
						<div style={_x().title}>Ratings & Reviews</div>

						<div className="rating-col">
							<div className="rate-stars">
								<p className="average-rating">{currentShop?.rating.reviewed}</p>

								<RatingStar
									rating={currentShop?.ratings.length}
									noBorder
									colors={{ mask: "#FBB500", rear: "#c4c4c4" }}
								/>

								<p className="tot-rating">
									{currentShop?.ratings.length} Customer Ratings
								</p>
							</div>

							<div className="progress-container">
								<div className="progress-col">
									<p className="col-rate-numb">5</p>

									<img src={star} alt="star" className="col-rate-star" />

									<ProgressBar
										height={7}
										width={210}
										bgcolor="#01195A"
										isLabelVisible={false}
										completed={percentage[5]}
									/>
								</div>

								<div className="progress-col">
									<p className="col-rate-numb">4</p>

									<img src={star} alt="star" className="col-rate-star" />

									<ProgressBar
										height={7}
										width={210}
										bgcolor="#01195A"
										isLabelVisible={false}
										completed={percentage[4]}
									/>
								</div>

								<div className="progress-col">
									<p className="col-rate-numb">3</p>

									<img src={star} alt="star" className="col-rate-star" />

									<ProgressBar
										height={7}
										width={210}
										bgcolor="#01195A"
										isLabelVisible={false}
										completed={percentage[3]}
									/>
								</div>

								<div className="progress-col">
									<p className="col-rate-numb">2</p>

									<img src={star} alt="star" className="col-rate-star" />

									<ProgressBar
										height={7}
										width={210}
										bgcolor="#01195A"
										isLabelVisible={false}
										completed={percentage[2]}
									/>
								</div>

								<div className="progress-col">
									<p className="col-rate-numb">1</p>

									<img src={star} alt="star" className="col-rate-star" />

									<ProgressBar
										height={7}
										width={210}
										bgcolor="#01195A"
										isLabelVisible={false}
										completed={percentage[1]}
									/>
								</div>
							</div>
						</div>
					</div> */}
								</div>
							</div>
						</>
					)}

					{(sd.instantNotice || sd.mobileNotice) && (
						<Notice
							onClose={() =>
								setSd({
									...sd,
									instantNotice: false,
									mobileNotice: false,
								})
							}
							title={sd.instantNotice ? "Instant Service" : "Mobile Service"}
							description={
								sd.instantNotice
									? "Turn On Instant Service to give a quick response to nearby Motorist."
									: "Turn On Mobile Service to give a quick response to nearby Motorist."
							}
						/>
					)}
				</div>
			)}
		</>
	);
}

const _x = (_mobile) => ({
	sDetails: {
		backgroundColor: "#fff",
		height: _mobile ? "100vh" : "100%",
		boxSizing: "border-box",
		borderRadius: _mobile ? 0 : 10,
	},
	tabs: {
		height: 70,
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		borderBottom: "1px solid lightgrey",
	},
	r1: {
		height: 80,
		display: "grid",
		placeItems: "center",
		borderBottom: "1px solid #CCCCCC",
		// marginBottom: 30,
		fontSize: 15,
		fontWeight: 500,
	},
	r2: {
		display: "grid",
		width: "100%",
		placeItems: "center",
		paddingBottom: 30,
	},
	r2_sub: {
		width: _mobile ? "90%" : "50%",
	},
	hours: {
		display: "grid",
		gridTemplateColumns: "repeat(3, 1fr)",
		letterSpacing: "0.5px",
		overflow: "hidden",
		gridGap: "12px",
		marginBottom: "1rem",
	},
	workDay: {
		fontsize: 14,
		position: "relative",
		padding: "0.5rem",
		boxsizing: "borderbox",
		border: "0.5px solid #c4c4c460",
		boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
		color: "#01195a",
		width: 130,
		height: 80,
	},
	dayName: {
		fontWeight: 600,
	},
	title: {
		fontWeight: "500",
		fontSize: "16px",
		color: "#021959",
		marginTop: 20,
		marginBottom: 20,
	},
	s_title: {
		color: "#021959",
		fontWeight: 500,
		marginBottom: 10,
	},
	sidebarItemHeader: {
		paddingInline: 20,
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	},
	r3: {
		// height: 100,
		borderBottom: "1px solid lightgrey",
		borderTop: "1px solid lightgrey",
		marginTop: 10,
		// backgroundColor: "red",
	},
	r3_e: {
		paddingInline: 20,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: 40,
	},
	poster: {
		height: 170,
		width: "90vw",
		borderRadius: 10,
		objectFit: "cover",
		backgroundColor: "lightgrey",
		boxSizing: "border-box",
		marginTop: 20,
	},
	partsShop: {
		display: "grid",
		placeItems: "center",
		padding: 30,
	},
});
