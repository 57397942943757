import axios from "axios";
import { useEffect, useState } from "react";
import { BsFillFunnelFill } from "react-icons/bs";
import { MdAutorenew } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import UserAdvert from "../../../Components/Functional/UserAdvert/UserAdvert";
import search from "../../../Resources/Icons/search.png";
import dashbord from "../../../Resources/Images/empty.svg";
import opps from "../../../Resources/Images/opps.png";
import { authHeader } from "../../../services/auth-header";
import AuthService from "../../../services/auth.service";
import "./dashboard.css";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;

const ActiveUserAds = ({ singleReview }) => {
	const [adData, setAdData] = useState([]);
	const [regular, setRegular] = useState(false);
	const [isloading, setisloading] = useState(false);
	const [message, setMessage] = useState(false);
	const [display, setDisplay] = useState(false);
	const [searching, setSearching] = useState("");
	const [noAd, setNoAd] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [showEditBox, setEditBox] = useState(false);
	const [showEditBox2, setEditBox2] = useState(false);
	const [showEditBox3, setEditBox3] = useState(false);
	const [successBox, setSuccessBox] = useState(false);
	const [successBox2, setSuccessBox2] = useState(false);
	const [delsuccessBox2, setDelSuccessBox2] = useState(false);
	const [activeAd, setActiveAd] = useState(false);
	const [progress, setProgress] = useState(0);
	const [permision, setPermision] = useState([]);

	const currentUser = AuthService.getCurrentUser("user");
	const token = authHeader();
	const token2 = token.Authorization;

	async function grabLoggedInUser() {
		setisloading(true);
		setProgress(94);
		axios
			.get(API_URL + "users/auth-user?fullDetails=true", {
				headers: authHeader(),
			})
			.then((resp) => {
				resp.data.data.user_info.permissions.map((role) => {
					if (role.includes("part_dealer")) {
						getActiveUserAds();
						setRegular(true);
						setisloading(false);
					} else {
						setRegular(false);
						setisloading(false);
					}
				});
				// .catch((err) => {
				//   console.log(err);
				//   setisloading(false);
				// });
			});
	}

	const userType = currentUser.data.user_info.permissions.map((role) => {
		return role;
	});

	const dealer = userType.includes("part_dealer");
	const mech = userType.includes("mechanic");

	let history = useHistory();

	const getEditMenu = (id) => {
		adData.filter((item) => {
			if (item.id === id) {
				setDisplay(true);
			}
		});
	};

	const checkUserType = () => {
		if (dealer || mech) {
			setRegular(true);
		}
	};

	const hideMenu = () => {
		setDisplay(false);
	};

	const getActiveUserAds = async () => {
		// setisloading(true);
		setProgress(94);

		await axios
			.get(API_URL + "products/current-user-products?fullDetails=true", {
				headers: authHeader(),
			})
			.then((response) => {
				setProgress(0);
				let sorted = response.data.data;

				const testing = sorted.length;
				if (testing === 0) {
					setNoAd(true);
				} else {
					setNoAd(false);
				}

				let sortedByDate = sorted.sort(function (a, b) {
					return new Date(b.date_created) - new Date(a.date_created);
				});

				// setisloading(false);
				setAdData(sortedByDate);
				// console.log(sortedByDate);
				sortedByDate.forEach((each, index) => {});
			})
			.catch((err) => {
				// setisloading(false);
				setMessage(true);
				console.log(err);
			});
	};

	useEffect(() => {
		grabLoggedInUser();
		getActiveUserAds();
		checkUserType();
	}, []);

	const handleSearch = (e) => {
		e.preventDefault();
		setSearching(e.target.value);
	};

	useEffect(() => {
		setFilteredData(
			adData.filter((item) =>
				item.product_title.toLowerCase().includes(searching.toLowerCase())
			)
		);
	}, [searching, adData]);

	const confirmDelete = () => {
		const id = localStorage.getItem("pr_id");
		const Delete_Api = API_URL + "products/delete/" + `${id}`;
		setEditBox(false);

		axios
			.delete(Delete_Api, {
				headers: authHeader(),
			})
			.then((response) => {
				setDelSuccessBox2(true);
				getActiveUserAds();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const confirmDeactivate = () => {
		const id = localStorage.getItem("pr_id");
		setEditBox2(false);

		axios
			.patch(
				API_URL + "products/deactivate/" + `${id}`,
				{ id: id },
				{
					headers: authHeader(),
				}
			)
			.then((response) => {
				setSuccessBox(true);
				getActiveUserAds();
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const confirmActivate = () => {
		const id = localStorage.getItem("pr_id");
		setEditBox3(false);

		axios
			.patch(
				API_URL + "products/activate/" + `${id}`,
				{ id: id },
				{
					headers: authHeader(),
				}
			)
			.then((response) => {
				setSuccessBox2(true);
				getActiveUserAds();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<section style={{width:'90%',display:'flex',justifyContent:'center'}}>
			{isloading ? (
				<Spinner
					fullscreen={false}
					type="puff"
					isloading={isloading}
					size={100}
				/>
			) : regular === false ||
			  (regular === true && noAd) ||
			  filteredData.length === 0 ? (
				<div className="details-info">
					<img src={dashbord} alt="" className="no-ad" />
					<p>There are no available adverts</p>
					<p>Add an advert for it to be visible here</p>
				</div>
			) : (
				<section className="ad-container">
					<div className="ad-col-wrapper">
						{!isloading && !message ? (
							<section id="search-pr-col">
								<div className="search-wrapper" style={{
                  borderRadius:6,background:'#fff',
                  overflow:'hidden'
                }}>
									<div  className="dashboard-search-icon">
										<img src={search} alt="" />
									</div>
									<input
                  style={{
                    background:'#fff'
                  }}
										type="text"
										value={searching}
										onChange={handleSearch}
										placeholder="Type your search"
									/>
								</div>
								<div className="dashboard-filter-icon">
									<BsFillFunnelFill color="#C4C4C4" />
									<p>Sort</p>
								</div>
							</section>
						) : null}
						{isloading ? (
							<LoadingBar
								color="#587FC0"
								loaderSpeed={4000}
								progress={progress}
								onLoaderFinished={() => setProgress(0)}
							/>
						) : (
							<>
								{filteredData.length === 0 ? (
									<div
										style={{
											marginTop: "2rem",
											color: "#fbb500",
											fontSize: "1.2rem",
											fontWeight: "500",
										}}
									>
										No Product Matches your Search
									</div>
								) : (
									filteredData.map((item) => {
										return (
											<UserAdvert
												key={item.id}
												title={item.product_title}
												image={item.product_photo[0]}
												image_number={item.product_photo.length}
												overallRating={
													item.customer_reviews.total_rating === 0
														? "0.00"
														: item.customer_reviews.average_overall_rating
												}
												rating={item.customer_reviews.average_overall_rating}
												price={item.price}
												product_no={item.product_no}
												date={item.date_created}
												views={item.views}
												phone_views={item.mobile_views}
												adLink={`${item.id}/${item.product_title}`}
												editLink={`${item.id}/${item.product_title}`}
												deleteLink={() => {
													setEditBox(true);
													localStorage.setItem("pr_id", item.id);
													window.scrollTo(0, 0);
												}}
												activateLink={() => {
													setEditBox2(true);
													localStorage.setItem("pr_id", item.id);
													window.scrollTo(0, 0);
												}}
												deactivateLink={() => {
													setEditBox3(true);
													localStorage.setItem("pr_id", item.id);
													window.scrollTo(0, 0);
												}}
												activateText={
													item.status === "active" ? "Deactivate" : null
												}
												deactivateText={
													item.status === "inactive" ? "Activate" : null
												}
												activateIcon={
													item.status === "active" ? (
														<RiCloseCircleLine />
													) : null
												}
												deactivateIcon={
													item.status === "inactive" ? <MdAutorenew /> : null
												}
												statusCheck={item.status === "active" ? true : false}
												statusCheck2={item.status === "inactive" ? true : false}
												pricestyle={
													item.status === "inactive"
														? { color: "#c4c4c4" }
														: { color: "#587FC0" }
												}
												pricestyle2={
													item.status === "inactive"
														? { color: "#c4c4c4" }
														: { color: "#646464" }
												}
												borderCheck={
													item.status === "inactive"
														? {
																color: "var(--primary2)",
																border: "0.4px solid var(--primary2)",
														  }
														: {
																color: "var(--primary1)",
																border: "0.4px solid var(--primary1)",
														  }
												}
												loadItemReview={() => {
													singleReview();
													localStorage.setItem("idy", item.id);
												}}
												notice={item.new_reviews > 0 ? item.new_reviews : null}
											/>
										);
									})
								)}
							</>
						)}
						{showEditBox ? (
							<section className="update-pop">
								<div className="firstname">
									<p style={{ margin: "2rem 0" }}>
										Are you sure you want to delete this Product?
										<br />
										This action cannot be undone.
									</p>

									<div className="btns">
										<button className="save" onClick={confirmDelete}>
											Yes
										</button>
										<button
											className="cancel"
											onClick={() => {
												setEditBox(false);
											}}
										>
											No
										</button>
									</div>
								</div>
							</section>
						) : null}
						{showEditBox2 ? (
							<section className="update-pop">
								<div className="firstname">
									<p style={{ margin: "2rem 0" }}>
										Are you sure you want to deactivate this Product?
									</p>

									<div className="btns">
										<button className="save" onClick={confirmDeactivate}>
											Yes
										</button>
										<button
											className="cancel"
											onClick={() => {
												setEditBox2(false);
											}}
										>
											No
										</button>
									</div>
								</div>
							</section>
						) : null}
						{showEditBox3 ? (
							<section className="update-pop">
								<div className="firstname">
									<p style={{ margin: "2rem 0" }}>
										Are you sure you want to Activate this Product?
									</p>

									<div className="btns">
										<button className="save" onClick={confirmActivate}>
											Yes
										</button>
										<button
											className="cancel"
											onClick={() => {
												setEditBox3(false);
											}}
										>
											No
										</button>
									</div>
								</div>
							</section>
						) : null}
						{successBox ? (
							<section className="update-pop">
								<div className="firstname">
									<p style={{ margin: "2rem 0" }}>
										Product has been Successfully Deactivated
									</p>

									<div className="btns">
										<button
											className="cancel"
											onClick={() => {
												setSuccessBox(false);
											}}
										>
											Close
										</button>
									</div>
								</div>
							</section>
						) : null}
						{successBox2 ? (
							<section className="update-pop">
								<div className="firstname">
									<p style={{ margin: "2rem 0" }}>
										Product has been Successfully Activated
									</p>

									<div className="btns">
										<button
											className="cancel"
											onClick={() => {
												setSuccessBox2(false);
											}}
										>
											Close
										</button>
									</div>
								</div>
							</section>
						) : null}
						{delsuccessBox2 ? (
							<section className="update-pop">
								<div className="firstname">
									<p style={{ margin: "2rem 0" }}>
										Product has been Successfully Deleted
									</p>

									<div className="btns">
										<button
											className="cancel"
											onClick={() => {
												setDelSuccessBox2(false);
											}}
										>
											Close
										</button>
									</div>
								</div>
							</section>
						) : null}

						{message ? (
							<div className="details-info" style={{ marginTop: "-3rem" }}>
								<img src={opps} alt="" className="no-ad" />

								<p
									style={{
										fontSize: "1rem",
										marginTop: "-3rem",
										fontStyle: "italic",
									}}
								>
									Something went wrong, Please Refresh this Screen
								</p>
							</div>
						) : null}
					</div>
				</section>
			)}
			{isloading && (
				<LoadingBar
					color="#587FC0"
					loaderSpeed={4000}
					progress={progress}
					onLoaderFinished={() => setProgress(0)}
				/>
			)}
		</section>
	);
};

export default ActiveUserAds;
