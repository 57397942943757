import { Paper } from "@material-ui/core";
import React from "react";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Align, Spacing } from "../../../Components/Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function FAQ() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			<Align verticalHeight={_mobile ? 0 : 20}>
				<Paper style={_x(_mobile).fAQ} elevation={_mobile ? 0 : 1}>
					<div style={_x(_mobile).header}>
						<p style={_x(_mobile).title}>Frequently Asked Questions</p>
					</div>

					<div style={_x(_mobile).main}>
						<p style={_x(_mobile).subtitle}>Mechanics</p>

						<p style={_x(_mobile).text1}>
							How do I list my services on Quickmechs?
						</p>

						<p style={_x(_mobile).text2}>
							Register on Quickmech.com with your correct profile details, then
							wait for customers to contact you once your profile is approved.
						</p>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>What should be in my profile?</p>

						<p style={_x(_mobile).text2}>
							Your Quickmechs profile should contain relevant information about
							the services you offer and how to contact you.
						</p>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>Why should I be on Quickmechs?</p>

						<p style={_x(_mobile).text2}>
							Quickmechs helps you reach a wider audience from any location.
							More people can see your profile and reach you in case of
							emergency, especially when they are unfamiliar with a particular
							environment.
						</p>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>Why is my account banned?</p>

						<p style={_x(_mobile).text2}>
							If you received notification that your account is banned, this
							means that you've either breached one of the rules on the platform
							or you have been severally reported by users.
						</p>

						<Spacing vertical={5} />

						<p style={_x(_mobile).subtitle}>Spare Parts</p>

						<p style={_x(_mobile).text1}>
							How do I register as a Spare parts dealer on Quickmechs?
						</p>

						<li style={_x().li}>
							Register on the platform, and fill out your profile.
						</li>
						<li style={_x().li}>Upload and label your product correctly.</li>
						<li style={_x().li}>Wait for approval and calls from customers.</li>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>How many photos can be uploaded? </p>

						<p style={_x(_mobile).text2}>
							You can upload as many as you want based on the product’s
							category.
						</p>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>Why should I sell on Quickmechs?</p>

						<p style={_x(_mobile).text2}>
							As a spare parts dealer, Quickmechs helps you to connect with a
							wider audience including your potential customers.
						</p>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>How can I list my product?</p>

						<p style={_x(_mobile).text2}>
							After creating your profile, you can list your products by
							clicking on the upload button.
						</p>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>How often can I update my product?</p>

						<p style={_x(_mobile).text2}>
							You can edit your product as soon as it gets sold out/out of
							stock, when there is a price change, or when other related issues
							arise.
						</p>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>Rules of spare part dealers?</p>

						<li style={_x().li}>
							Every product must have an exact title and include pictures taken
							by the dealer directly, don't use watermarked pictures.
						</li>
						<li style={_x().li}>
							A proper, brief, and clear description of your product is
							necessary.
						</li>
						<li style={_x().li}>
							Your posted items should be physically available in your store.
						</li>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>How to run an advert?</p>

						<li style={_x().li}>
							Visit the advertise with us page and fill in all details and wait
							for an agent to contact you for verification, approval, and
							payment.
						</li>
						<li style={_x().li}>
							Or send an email to ads@quickmechs.com with the ad details.
						</li>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>How to make your ad more effective?</p>

						<li style={_x().li}>Use clear, high-quality images.</li>
						<li style={_x().li}>
							With a flexible price, you are advised to do your research before
							applying.
						</li>
						<li style={_x().li}>
							Include your correct contact details and respond swiftly to calls.
						</li>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>
							How does Quickmechs handle your ad and what to note?
						</p>

						<li style={_x().li}>
							Your Ad goes through a verification process before it’s live.
						</li>
						<li style={_x().li}>
							If there is something wrong, you will be informed to correct your
							post accordingly.
						</li>
						<li style={_x().li}>Your Ad is published afterward. </li>
						<li style={_x().li}>
							Ads that are too similar will be regarded as duplicates and won't
							go live, at the same time.
						</li>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>How do I see the location of an ad?</p>

						<li style={_x().li}>
							You will get notified as soon as your Ad is approved and running.
						</li>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>Why is my account banned?</p>

						<li style={_x().li}>
							If you received a notification that your account is banned, you've
							either breached one of the rules, or you have been severally
							reported by users, for fake or counterfeit. For any support feel
							free to contact us.
						</li>

						<Spacing vertical={5} />

						<p style={_x(_mobile).subtitle}>Car Owners</p>

						<p style={_x(_mobile).text1}>
							How do I join the Quickmechs community?
						</p>

						<li style={_x().li}>
							To join Quickmechs, you only need to register on the platform and
							start making use of the services available.
						</li>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>How do I book a mechanic?</p>

						<li style={_x().li}>
							Log into Quickmechs and search for the services you need.
						</li>

						<li style={_x().li}>
							When you find your preferred service provider, dial the mechanic’s
							contact and book an appointment.
						</li>

						<li style={_x().li}>
							When you get to the service center, check in on the platform to
							enable proper monitoring of activities.
						</li>

						<li style={_x().li}>
							When done with the services, check out and make payment via
							Quickmechs.
						</li>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>
							How to buy from a spare part dealer?
						</p>

						<li style={_x().li}>
							Log into Quickmechs and search for the part you need.{" "}
						</li>

						<li style={_x().li}>
							When you find your preferred product, check out and make a payment
							on Quickmechs.
						</li>

						<li style={_x().li}>
							Contact the seller on how to get it to your location.
						</li>

						<Spacing vertical={5} />

						<p style={_x(_mobile).text1}>
							Why do I need to make a payment via the platform?
						</p>

						<p style={_x(_mobile).text2}>
							We prioritize your satisfaction and safety so we have agents who
							check the quality of work done before remitting it to the service
							provider. If any issues arise, our agent can easily sort this out
							for you.
						</p>

						<p style={_x(_mobile).text2}>
							In event of delayed deliveries, you can cancel your order and buy
							from another seller on the platform with the same funds. We also
							give discounts and would love for you to enjoy them.
						</p>

						<p style={_x(_mobile).text2}>
							For other support, you can live chat or visit the contact us page.
						</p>
					</div>
				</Paper>
			</Align>

			<DownloadApp />

			<Footer />
		</>
	);
}

export default FAQ;

const _x = (_mobile) => ({
	fAQ: {
		maxWidth: _mobile ? "100%" : "1200px",
		background: "white",
		marginTop: _mobile ? 0 : 50,
		borderRadius: 10,
	},

	header: {
		background: "var(--primary1)",
		borderTopRightRadius: 10,
		borderTopLeftRadius: 10,
		display: "flex",
		flexDirection: "column",
		paddingInline: _mobile ? 10 : 200,
	},

	title: {
		fontSize: 17,
		fontWeight: "bold",
		color: "white",
	},

	main: {
		paddingBottom: 50,
		paddingInline: _mobile ? 50 : 200,
	},

	subtitle: {
		fontSize: 18,
		fontWeight: 500,
		color: "var(--baseBg)",
	},

	text1: {
		fontSize: 15,
		fontWeight: 400,
		color: "var(--baseBg)",
	},

	text2: {
		fontSize: 14,
		fontWeight: 400,
		color: "var(--baseBg)",
		lineHeight: 1.8,
	},

	text3: {
		fontSize: 12,
		lineHeight: 1.8,
	},

	li: {
		marginBottom: 20,
	},
});
