import { Paper } from "@material-ui/core";
import React from "react";
import DownloadApp from "../../../Components/Functional/DownloadApp/DownloadApp";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Align, Spacing } from "../../../Components/Style/Styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { GoDot as GoPrimitiveDot } from "react-icons/go";

function CarLoan() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			<Align verticalHeight={_mobile ? 0 : 20}>
				<Paper style={_x(_mobile).carLoan} elevation={_mobile ? 0 : 1}>
					<div>
						<h3 style={_x(_mobile).header}>
							How To Get A Car Loan In The United State
						</h3>
					</div>
					<section className="card-content" style={_x(_mobile).main}>
						<p style={_x(_mobile).text3}>
							Getting a readily available car loan will aid you in acquiring
							that car you have always dreamt of without having to deal with
							many financial constraints.
						</p>
						<p style={_x(_mobile).text3}>
							vfdIn truth, public transportation can sometimes be inconvenient,
							and if you opt for cab-hailing services, you might have to spend
							more on transportation.
						</p>
						<p style={_x(_mobile).text3}>
							Of course, you would not have to deal with this if you had a car
							of your own. Seeing how much having a car makes living and
							movement easier, it is no wonder why everyone works towards
							acquiring one.
						</p>
						<p style={_x(_mobile).text3}>
							We’ve put this guide together to show you how to easily get a car
							loan in the United States. Let’s dive in!
						</p>
						<p style={_x(_mobile).bullet_header}>Buying a New Car in the U.S</p>
						<p style={_x(_mobile).text3}>
							While acquiring a car might seem easy for some, it is not the case
							for everyone as it might cost a fortune. It would also require
							proper planning and budgeting to purchase a new car.
						</p>
						<p style={_x(_mobile).text3}>
							Beyond looking out for the perfect car, you would also have to
							make research for a cost-effective option. It would be wise to
							check from various vendors and make price comparisons and seal a
							good car deal.
						</p>
						<p style={_x(_mobile).text3}>
							Research can assist you in choosing the type of car you desire
							while car hunting—this is to know the right car for you and enable
							you to buy it. It may be challenging to pay in full for any car
							you decide to purchase, depending on your financial
							responsibilities and capacity.
						</p>
						<p style={_x(_mobile).text3}>
							Fortunately, getting a car loan in the U.S is possible. This means
							that getting a new car won't be hampered by a lack of finances.
						</p>
						<p style={_x(_mobile).text3}>
							Getting a car loan in the U.S is not as difficult as some might
							think. As you read through you will discover how to get a car loan
							and also become aware of car financing companies in the U.S.
						</p>

						<p style={_x(_mobile).bullet_header}>What is a Car Loan?</p>
						<p style={_x(_mobile).text3}>
							The amount of money borrowed to buy an automobile is known as a
							car loan. Financial institutions or an auto-financing business is
							the best option to obtain a car loan. Why this is so is
						</p>
						<p style={_x(_mobile).text3}>
							because they have appropriate structures in place. It is possible
							to get either long-term or short-term loans. With some companies,
							you can get a loan with a five-year repayment schedule.
						</p>
						<p style={_x(_mobile).text3}>
							These organizations add interest to the loans they give out, and
							auto loans are no exception. The amount borrowed and the length of
							the payback period affect the interest rates. Interest rates for
							long-term, large sums auto loans are lower than on short-term,
							smaller amounts loans.
						</p>
						<p style={_x(_mobile).text3}>
							Tip: Only take out loans that you can afford to repay.
						</p>
						<p style={_x(_mobile).bullet_header}>
							Why You Should Get A Car Loan
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							<span>Quick Access:</span> You can avoid any sort of delay and get
							your car quickly when you opt for a car loan. In a situation where
							you urgently need a car, this could be of great help to you.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Purchase a new vehicle. Because they cannot afford new vehicles,
							most people opt for used ones. The drawback is that problems arise
							in old cars earlier than intended. Your finances will be strained
							by the overhead maintenance costs. You can feel at ease purchasing
							a new car with a loan.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Variety. With a car loan, you have numerous options to choose from
							because car financing companies try to come up with the best
							possible plans to attract customers. A plan is not necessarily
							imposed on you, you get to choose the one that resonates with you
							after making numerous comparisons.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Credit history. Your ability to make on-time loan payments
							increase your reputation and creditworthiness. You establish a
							solid credit history that makes you eligible for additional loans
							in the future.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Flexibility. You do not have to break the bank to get a car. You
							can acquire a car and still be able to meet your basic needs while
							having stable finance. This shows how beneficial an auto-financing
							company and a financial institution can be with their repayment
							schedule. Repayments are made at a fixed rate to enable you plan
							your expenses.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							No Collateral. Before obtaining a car loan, you are not required
							to offer any type of collateral. This makes the procedure easier
							for you to use. The minimal necessities are all that is needed.
						</p>
						<p style={_x(_mobile).text3}>
							You should be aware, though, that if you break the terms of the
							loan arrangement by paying past the deadline, your interest costs
							will go up. You will find it harder to maintain your position as a
							result. The car itself acts as security for the loan, and if you
							default, the lender may seize it.
						</p>

						<p style={_x(_mobile).bullet_header}>
							How to Get a Car Loan in Nigeria
						</p>
						<p style={_x(_mobile).text3}>
							Similar processes are used by different financial institutions and
							auto lending businesses to secure automobile loans. There is
							barely any distinction that is unique to the organizations.
						</p>
						<p style={_x(_mobile).bullet_header}>
							Requirements for getting a car loan
						</p>

						<p style={_x(_mobile).text3}>
							When applying for a loan, the loan company will want a
							confirmation of your eligibility. And one efficient way they do
							this is by asking for your bank statement.
						</p>
						<p style={_x(_mobile).text3}>
							The bank statement provides a record of your account history
							within a specific period of time. Before sending your back
							statement, here are two major things to put in place:
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>1.</span>
							Steady income. Possess a steady source of revenue (monthly).
							Either you work for someone else or you run your own business.
							This demonstrates your ability to repay.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>2.</span>
							Consistent deposits. You should have a long history of consistent,
							tangible deposits to your account, according to your bank
							statement.
						</p>

						<p style={_x(_mobile).bullet_header}>
							What to do when getting a car loan in the U.S
						</p>

						<p style={_x(_mobile).text3}>
							Getting a car loan is not something you do without being informed.
							First, ensure that you obtain vital information about the vehicle
							you desire. This will help you determine how much you should
							borrow.
						</p>
						<p style={_x(_mobile).text3}>
							Keep in mind that auto finance businesses only offer about 60% of
							what you already have. Now, the nex steps:
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Collect a proforma invoice from a car dealership that the
							financial institution has approved.
						</p>

						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Pay 5% of the cost of auto insurance to an insurance provider
							connected to the lending institution.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Submit your application and any necessary documentation online or
							at the appropriate location that your account officer has provided
							(the lending institution usually provided a form for this).
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							The documents will be examined and approved if they meet the
							requirements. Following that, you will hear from your account
							officer.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Upon confirmation, the lender makes the payment, and you receive
							your new automobile!
						</p>

						<p style={_x(_mobile).text3}>
							Tip: When the proforma invoice is approved, you should decide on
							the delivery method with the car dealership.
						</p>

						<p style={_x(_mobile).bullet_header}>
							List of Auto-Finance Companies in the U.S
						</p>

						<p style={_x(_mobile).text3}>
							Now that you know what to do, here’s a quick list of some
							auto-finance companies you can look up.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Ally Financial.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Wells Fargo.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Capital One.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Bank of America.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Chase.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							LightStream.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Ford.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							Clearlane.
						</p>
						<p style={_x(_mobile).text3}>
							<span style={{ marginRight: "10px", marginTop: "5px" }}>
								{/* <GoPrimitiveDot /> */}
							</span>
							PNC Bank.
						</p>

						<p style={_x(_mobile).bullet_header}>
							A better solution to your car troubles
						</p>

						<p style={_x(_mobile).text3}>
							There is no doubt that one who owns a car will have to deal with
							sudden car breakdowns and may not be able to get a quick response
							mechanic when this happens, particularly in a location you are not
							familiar with.
						</p>
						<p style={_x(_mobile).text3}>
							We are aware of the inconvenience caused by unforeseen car
							problems and the challenge of finding mechanics whenever this
							occurs.
						</p>
						<p style={_x(_mobile).text3}>
							This is the basis for creating Quickmechs, an online platform for
							connecting automobile owners with mechanics and auto parts
							suppliers. Get started today by signing up on quickmechs.com
						</p>
					</section>
				</Paper>
			</Align>

			<DownloadApp />

			<Footer />
		</>
	);
}

export default CarLoan;

const _x = (_mobile) => ({
	carLoan: {
		width: _mobile ? "100%" : 1200,
		background: "white",
		padding: _mobile ? "20px 30px" : "0",
		marginTop: _mobile ? 0 : 50,
	},
	main: {
		padding: _mobile ? "10px 3px" : "10px 200px",
	},
	header: {
		fontSize: 18,
		fontWeight: "bold",
		background: "var(--primary1)",
		color: "white",
		padding: _mobile ? "10px 20px" : "10px 200px",
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		margin: 0,
	},

	text1: {
		fontSize: 17,
		fontWeight: "bold",
		color: "var(--secondary1)",
	},

	text2: {
		fontSize: 12,
		fontWeight: "bold",
		color: "var(--secondary1)",
		lineHeight: 1.8,
	},

	text3: {
		fontSize: 14,
		fontWeight: "400",
		lineHeight: 1.8,
	},

	bullet: {},

	bullet_header: {
		color: "var(--baseBg)",
		fontWeight: "400",
		fontSize: 14,
	},

	li: {
		lineHeight: 1.8,
	},
});
