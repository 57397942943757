import React, { Fragment, useEffect, useState } from "react";
import "./NewPartsDealerStepsForm.scss";

import "./newpartssteps.css";
import { Link } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import {
  Button,
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ShPhotoInfo from "./ShPhotoInfo";
import ShPhotoWrap from "./ShPhotoWrapEdit";
import {
  createParePartDealer,
  getShopDetails,
  updateShopDetails,
} from "src/services/job/job.service";
import { ThreeCircles } from "react-loader-spinner";
import PlacesAutocomplete from "react-places-autocomplete";
import { isValidUrl } from "../jobs/helper";
function SparPartDealerFormEdit() {
  const mobile = useMediaQuery("(max-width:1000px)");
  const [images, setImages] = React.useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shopName, setShopName] = useState("");
  const [officeAddress, setOfficeAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [shopDescription, setShopDescription] = useState();
  const [companyPhoto, setCompanyPhoto] = useState("");
  const [shopId, setShopId] = useState("");

  const _handleOnchange = (setState) => (e) => {
    setState(e.target.value);
  };

  const handleSelect = (address) => {
    setOfficeAddress(address);
  };
  const handleChange = (address) => {
    setOfficeAddress(address);
  };

  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    const loadedImage = imageList;
    let newArry = imageList.map((item) => item.data_url);
    setImages(imageList);
    if (loadedImage) {
      setCompanyPhoto(newArry[0]);
    }
  };

  const handleSubmitForm = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (companyPhoto === "") {
        alert("Company photo required");
        setLoading(false);
        return;
      }
      if (phone === "") {
        alert("Phone number is required");
        setLoading(false);
        return;
      }
      if (officeAddress === "") {
        alert("Address is required");
        setLoading(false);
        return;
      }
      if (shopDescription === "") {
        alert("Shop description is required");
        setLoading(false);
        return;
      }

      let Payload = {
        shop_name: shopName,
        phone_number: phone,
        office_address: officeAddress,
        referral_code: referralCode,
        shop_description: shopDescription,
        company_photo: companyPhoto,
      };

      console.log(
        Payload,
        "==============update shop payload=================="
      );

      if (!isChecked) {
        setLoading(false);
        alert("Confirm policy by checking the box");
        return;
      }
      const result = await updateShopDetails(shopId, Payload);
      if (result?.status === 200 || result?.status === 201) {
        setSubmitted(true);
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (error) {
      if (error?.message) {
        alert(`${error?.message}`);
      }
      setLoading(false);
    }
  };

  const getUserShop = async () => {
    try {
      const result = await getShopDetails();

      if (result.status === 200 || result.status === 201) {
        const {
          company_photo,
          id,
          office_address,
          phone_number,
          shop_description,
          shop_name,
        } = result.data?.data;
        setPhone(phone_number);
        setShopDescription(shop_description);
        setShopName(shop_name);
        setOfficeAddress(office_address);
        setCompanyPhoto(result.data?.data?.company_photo);
        setShopId(id);

        console.log(
          result.data?.data,
          "=================== SHOP IMAGE ============="
        );
      } else {
        console.log("Shop details not found");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getUserShop();
  }, []);
  return (
    <Fragment>
      <form className="part-dealer-form">
        <label className="title-label" htmlFor="">
          Shop Details:
          <div className="divider-spar-part-dealer" />
        </label>
        <Grid
          container
          rowSpacing={1}
          mt={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={6}>
            <PageTextInput
              mobile={mobile}
              value={shopName}
              onChange={(e) => setShopName(e.target.value)}
              placeholder="Enter Shop Name"
              title=" Shop Name"
            />
          </Grid>
          <Grid item xs={6}>
            <PlacesAutocomplete
              value={officeAddress}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <PageTextInput
                    mobile={mobile}
                    value={officeAddress}
                    {...getInputProps({
                      placeholder: "Search Places ...",
                    })}
                    place
                    title="Shop Address"
                  />
                  <div
                    style={{ position: "relative" }}
                    className="autocomplete-dropdown-container"
                  >
                    {loading && <div>Loading...</div>}

                    <div
                      className="places-dropdown"
                      style={{
                        top: -30,
                        display: suggestions.length === 0 && "none",
                      }}
                    >
                      {!loading &&
                        suggestions.map((suggestion) => (
                          <div
                            {...getSuggestionItemProps(suggestion, {})}
                            className="places-button"
                          >
                            <span style={{}}>{suggestion.description}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Grid>
        </Grid>
        {/* ============================ */}
        <Grid
          container
          rowSpacing={1}
          mt={3}
          mb={4}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={6}>
            <PageTextInput
              mobile={mobile}
              value={phone}
              type="number"
              onChange={_handleOnchange(setPhone)}
              placeholder="Enter Phone Number"
              title="Phone Number"
            />
          </Grid>
          <Grid item xs={6}>
            <PageTextInput
              mobile={mobile}
              value={referralCode}
              onChange={_handleOnchange(setReferralCode)}
              placeholder="Enter Referral Code"
              place
              title="Referral Code (Optional)"
            />
          </Grid>
        </Grid>
        <div style={{ marginBottom: "15px" }}>
          <label className="input-label-spare-part-dealer">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#222",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "16px",
                }}
              >
                Shop Description
              </Typography>
            </div>
            <textarea
              value={shopDescription}
              onChange={_handleOnchange(setShopDescription)}
              placeholder="Enter Shop Description"
              className="text-area-spar-part"
            />
          </label>
        </div>
        <div style={{ marginTop: "10px" }}>
          <label className="input-label-spare-part-dealer">
            Upload Company Photo
          </label>
          <div style={{ marginTop: "10px" }}>
            <ImageUploading
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div className="st_shop_image">
                  <section className="st_ppix">
                    {companyPhoto !== "" ? (
                      <ShPhotoWrap
                        image={companyPhoto}
                        onClickDelete={() => setCompanyPhoto("")}
                        onImageRemove={onImageRemove}
                      />
                    ) : (
                      imageList.map((image, index) => (
                        <ShPhotoWrap
                          image={imageList.length !== 0 ? image : companyPhoto}
                          index={index}
                          onImageRemove={onImageRemove}
                        />
                      ))
                    )}
                  </section>
                  {companyPhoto !== "" ? null : (
                    <ShPhotoInfo onImageUpload={onImageUpload} />
                  )}
                </div>
              )}
            </ImageUploading>
            <div className="instruction-note">
              Each picture must not exceed 5MB. Supported formats are *.jpg,
              *.gif and *.png"
            </div>
            <div className="conf-check-row-wrap">
              <Checkbox
                sx={{ padding: 0, margin: 0 }}
                checked={isChecked}
                onClick={() => setIsChecked(!isChecked)}
              />
              <label for="" className="label-check-box">
                By ticking this you agree to our{" "}
                <label className="color-yellow-txt">Privacy Policy</label> and
                <label className="color-yellow-txt">Terms and Conditions</label>
              </label>
            </div>
            <Button
              disabled={loading}
              onClick={handleSubmitForm}
              sx={{
                width: mobile ? "100%" : "400px",
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "22px",
                background: "#FBB500",
                textTransform: "capitalize",
                "&:hover": {
                  background: "#FBB500",
                },
              }}
            >
              {loading ? (
                <>
                  <ThreeCircles
                    height="20"
                    width="20"
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                  />
                </>
              ) : (
                "Update"
              )}
            </Button>
          </div>
        </div>
      </form>
      {submitted && (
        <section style={_x.modal_bg}>
          <div open={submitted === false} style={{ border: "none" }}>
            <div style={_x.modall}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              ></div>

              <h5
                style={{
                  fontFamily: "montserrat",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "500",
                  margin: 0,
                }}
              >
                Congratulations
              </h5>

              <p
                style={{
                  fontFamily: "montserrat",
                  fontSize: "14px",
                  textAlign: "center",
                  width: "70%",
                }}
              >
                You have successfully updated your shop
              </p>
              <article
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Link
                  to="/home/submit-an-ad"
                  style={{ textDecoration: "none", width: "50%" }}
                >
                  <button style={_x.continue}>Post an Ad</button>
                </Link>
                <Link
                  to="/home/dashboard"
                  style={{ textDecoration: "none", width: "50%" }}
                >
                  <button style={_x.continue2}>Go to Dashboard</button>
                </Link>
              </article>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
}

const PageTextInput = ({ title, mobile, ...props }) => (
  <label
    style={{
      fontSize: mobile ? "12px" : "14px",
    }}
    className="input-label-spare-part-dealer"
    for=""
  >
    {title}
    <input
      style={{ color: "#000" }}
      className="input-spar-part-dealer"
      {...props}
    />
  </label>
);

export default SparPartDealerFormEdit;

const _x = {
  ff: {},
  btn: {
    background: "transparent",
    height: 30,
    width: 20,
    fontSize: 22,
    color: "grey",
    borderRadius: 0,
    padding: 0,
    margin: 0,
  },
  title_sect: {
    marginBottom: "53px",
    textAlign: "center",
  },

  header: {
    fontWeight: "bold",
    fontSize: 15,
  },
  header2: {
    fontWeight: "normal",
    fontSize: "14px",
    marginBottom: "2px",
    color: "#222222",
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
  },
  btn2: {
    color: "var(--primary1)",
    fontFamily: "montserrat",
    paddingTop: "10px",
  },
  modall: {
    background: "white",
    width: 391,
    height: 236,
    outline: "none",
    borderRadius: 6,
    margin: "170px auto",
    display: "grid",
    placeItems: "center",
    padding: "20px 15px",
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "1px 1px 18px #00000040",
  },

  continue: {
    height: 40,
    backgroundColor: "var(--primary2)",
    // paddingLeft: 15,
    // paddingRight: 15,
    color: "#fff",
    borderRadius: 6,
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: "1.2rem",
    width: "95%",
  },
  continue2: {
    height: 40,
    backgroundColor: "#fff",
    // paddingLeft: 15,
    // paddingRight: 15,
    border: "1px solid var(--primary2)",
    color: "var(--primary2)",
    borderRadius: 6,
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: "1.2rem",
    width: "95%",
  },

  close: {
    cursor: "pointer",
  },
  modal_bg: {
    position: "fixed",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100%",
    backgroundColor: "#00000090",
    overflow: "hidden",
  },
};
