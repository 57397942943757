import React, { useEffect, useState } from 'react'
import { ThreeCircles } from 'react-loader-spinner'
import { useParams } from 'react-router-dom'
import { confirmBookingService } from 'src/services/job/job.service'

export default function CalenderBookingConfirmation() {

const [isBookingConfirmed,setBookingConfirmed]=useState(false)

const {id, visitor_id}=useParams()
const confirmBooking = async()=>{
  try {
    const result=await confirmBookingService(id,visitor_id)
    if (result.data===200||result.status===201) {
      setBookingConfirmed(true);
      return
    }
  } catch (error) {
    console.log(error?.message)
  }
}
  useEffect(()=>{
    confirmBooking()
  },[])
  return (
    <div style={{
      height:'100dvh',with:'100%',background:'#ccc',
      display:'flex',
      flexDirection:'row',
      justifyContent:'center',
    alignItems:'center'
    }}>
      {isBookingConfirmed&&<label style={{fontSize:40,color:'#000'}}>
        Booking Confirmed!
      </label>}

      {!isBookingConfirmed&&<ThreeCircles
            height="100"
            width="100"
            color="#587fc0"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />}

    </div>
  )
}
