import { Modal, Paper } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { Hr, Spacing } from "../../../Components/Style/Styles";
import Rating from "./Rating";
import plus from "../../../Resources/Icons/plus.png";
import avatar from "../../../Resources/Icons/avatar.png";
import mechService from "../../../services/mech.service";
import { useHistory, useLocation } from "react-router";
import Popup from "src/Components/Functional/Popup/Popup";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import SimpleReactValidator from "simple-react-validator";
import ImageUploading from "react-images-uploading";
import { RiDeleteBin6Line } from "react-icons/ri";
import authService from "src/services/auth.service";

function RateMechanic() {
	const { mechanicId } = useLocation().state;

	const _currentUser = authService.getCurrentUser().data.user_info;

	const user_name = _currentUser.first_name;

	const dp = _currentUser.profile_image;

	const [reviewform, setreviewform] = useState({
		"Overall Rating": "",
		headline: "",
		written_review: "",
		Experience: "",
		"Response to time": "",
		Professionalism: "",
		public_name: user_name,
		photos: [],
	});

	const [popup, setpopup] = useState(false);

	const [isloading, setisloading] = useState(false);

	const [showfielderror, setshowfielderror] = useState(false);

	const simpleValidator = useRef(new SimpleReactValidator());

	const history = useHistory();

	async function submitReview() {
		setshowfielderror(true);

		if (simpleValidator.current.allValid()) {
			setisloading(true);

			const resp = await mechService.reviewMechanic(
				mechanicId,
				reviewform["Overall Rating"],
				reviewform.headline,
				reviewform.written_review,
				reviewform.Experience,
				reviewform["Response to time"],
				reviewform.Professionalism,
				reviewform.public_name,
				reviewform.photos
			);

			setisloading(false);

			if (resp.success) {
				setpopup(true);
			}
		}
	}

	function updateRating(rating, header) {
		setreviewform({ ...reviewform, [`${header}`]: rating });
	}

	function updateform(text, inputName) {
		setreviewform({ ...reviewform, [`${inputName}`]: text });
	}

	function continueToStore() {
		history.goBack();
	}

	function uploadImage(img) {
		setreviewform({
			...reviewform,
			photos: [...reviewform.photos, img[0].data_url],
		});
	}

	function removeImage(img) {
		setreviewform({
			...reviewform,
			photos: reviewform.photos.filter((photo) => photo !== img),
		});
	}

	return (
		<div style={{ display: "grid", placeItems: "center" }}>
			<Paper style={_x.rateMechanic}>
				<Rating
					adjustible
					header="Overall Rating"
					headerStyle={{
						fontWeight: 500,
						fontSize: 14,
						color: "var(--secondary1)",
					}}
					onClicked={updateRating}
				/>

				<Hr thickness={0.5} />

				<p style={_x.header}>Rate features</p>

				<Rating adjustible header="Professionalism" onClicked={updateRating} />
				<Spacing />

				<Rating
					adjustible
					color="yellow"
					header="Response to time"
					onClicked={updateRating}
				/>
				<Spacing />

				<Rating
					adjustible
					color="yellow"
					header="Experience"
					onClicked={updateRating}
				/>

				<Hr thickness={0.5} />

				<p style={_x.header}>Rate features</p>

				<p style={_x.text1}>
					Customers find images and video more helpful than text alone
				</p>

				<div style={_x.image_row}>
					<ImageUploading
						value={reviewform.photos}
						onChange={uploadImage}
						maxNumber={5}
						dataURLKey="data_url"
					>
						{({ onImageUpload }) => (
							<div style={_x.image_row}>
								{reviewform.photos.map((image, index) => {
									return (
										<div key={index} style={_x.image_box}>
											<img
												src={image}
												alt=""
												style={{ height: 100, width: 100 }}
											/>

											<div style={_x.close}>
												<div onClick={() => removeImage(image)}>
													<RiDeleteBin6Line />
												</div>
											</div>
										</div>
									);
								})}

								<div
									style={{
										height: 100,
										width: 100,
										display: "grid",
										placeItems: "center",
									}}
								>
									<div style={_x.add_image_icon} onClick={onImageUpload}>
										<img src={plus} alt="plus" height={40} width={40} />
									</div>
								</div>
							</div>
						)}
					</ImageUploading>
				</div>

				<Hr thickness={0.5} />

				<p style={_x.header}>Add a headline</p>

				<input
					value={reviewform.headline}
					style={_x.input}
					placeholder="whats more important to know"
					onChange={(e) => updateform(e.target.value, "headline")}
					onBlur={simpleValidator.current.showMessageFor("headline")}
				/>
				<div
					style={{
						visibility: showfielderror ? "visible" : "hidden",
					}}
				>
					{simpleValidator.current.message(
						"headline",
						reviewform.headline,
						"required",
						{
							element: (msg) => (
								<div style={_x.error}>review header is required !</div>
							),
						}
					)}
				</div>

				<Spacing />

				<p style={_x.header}>Add a written review</p>

				<textarea
					value={reviewform.written_review}
					style={_x.textarea}
					placeholder="What did you like or dislike? What did you use this product for?"
					onChange={(e) => updateform(e.target.value, "written_review")}
					onBlur={simpleValidator.current.showMessageFor("written review")}
				/>

				<div
					style={{
						visibility: showfielderror ? "visible" : "hidden",
					}}
				>
					{simpleValidator.current.message(
						"written review",
						reviewform.written_review,
						"required",
						{
							element: (msg) => (
								<div style={_x.error}>written review is required !</div>
							),
						}
					)}
				</div>

				<Spacing />

				<p style={_x.header}>Choose your pubic name</p>

				<p style={_x.text1}>This is how you’ll appear to other customers</p>

				<Spacing />

				<div style={_x.avatar_box}>
					<div style={_x.avatar}>
						{dp === "" ? (
							<p
								style={{
									fontSize: "1.1rem",
									textTransform: "uppercase",
									color: "#fff",
									padding: "4px 0",
									margin: "0",
									fontWeight: "700",
								}}
							>
								{user_name.charAt(0)}
							</p>
						) : (
							<img src={dp} alt="" style={_x.avatar} />
						)}
					</div>

					<input
						value={reviewform.public_name}
						style={_x.input2}
						placeholder={user_name}
						onChange={(e) => updateform(e.target.value, "public_name")}
					/>
				</div>

				<p style={_x.text1}>
					Don’t worry, you can always change this on your profile
				</p>

				<Spacing />

				<div style={_x.moveToRight}>
					<button style={_x.submit} onClick={submitReview}>
						Submit
					</button>
				</div>
			</Paper>

			<Popup
				isOpen={popup}
				popupMessage="Review Posted"
				popupMessageStyles={{ fontWeight: "bold" }}
				continuePressed={continueToStore}
				closePressed={() => {
					setpopup(false);
				}}
			/>

			<Spinner isloading={isloading} />
		</div>
	);
}

export default RateMechanic;

const _x = {
	error: { marginTop: 10, color: "red", fontSize: 10 },

	modal: {
		outline: "none",
		height: "100%",
		display: "grid",
		placeItems: "center",
	},

	rateMechanic: {
		width: 486,
		backgroundColor: "white",
		padding: 20,
		// margin: 300,
		marginTop: 40,
	},

	header: {
		fontWeight: 500,
		fontSize: 14,
		color: "var(--secondary1)",
	},

	image_row: {
		// background: "red",
		display: "flex",
		// alignItems: "center",
		// flexDirection: "row-reverse",
		flexWrap: "wrap",
		// paddingRight: 30,
		width: "100%",
	},

	add_image_icon: {
		height: 50,
		width: 50,
		display: "grid",
		placeItems: "center",
		border: "1px solid var(--secondary2)",
		cursor: "pointer",
	},

	image_box: {
		height: 100,
		width: 100,
		marginRight: 11,
		marginBottom: 10,
		position: "relative",
	},

	close: {
		position: "absolute",
		height: 20,
		width: 20,
		// background: "red",
		top: 0,
		right: 0,
		zIndex: 100,
		display: "grid",
		placeItems: "center",
		cursor: "pointer",
	},

	input: {
		width: "100%",
		// color: "var(--secondary2)",
		fontSize: 10,
	},
	textarea: {
		height: 94,
		width: "100%",
		resize: "none",
		fontSize: 10,
		padding: 10,
		// color: "var(--secondary2)",
	},
	submit: {
		height: 32,
		backgroundColor: "var(--primary1)",
		color: "white",
		fontSize: 16,
		width: 174,
		borderRadius: 6,
		fontWeight: "bold",
	},
	moveToRight: {
		display: "flex",
		justifyContent: "flex-end",
	},

	avatar_box: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	avatar: {
		height: 35,
		width: 40,
		borderRadius: "50%",
		backgroundColor: "#01195a",
		marginRight: 10,
		display: "grid",
		placeItems: "center",
	},

	input2: {
		height: 20,
		width: "100%",
		fontSize: 10,
	},

	text1: {
		fontSize: 10,
		color: "var(--secondary1)",
	},
};
