import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));
function authHeader() {
  if (user?.data?.access_token) {
    console.log(user);
    return {
      baseURL: `${BASE_URL}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${user?.data?.access_token}`,
      },
    };
  }
}
const { REACT_APP_JOB_BOARD_URL } = process.env;

const BASE_URL = REACT_APP_JOB_BOARD_URL;
const AxiosInstance = axios.create(authHeader());
export default AxiosInstance;
