// import axios from "axios";
// import React, { useEffect, useRef, useState } from "react";
// import Autocomplete from "react-google-autocomplete";
// import { AiOutlinePlus } from "react-icons/ai";
// import { GrFormClose } from "react-icons/gr";
// import { ImImages } from "react-icons/im";
// import { RiDeleteBin5Line } from "react-icons/ri";
// import ImageUploading from "react-images-uploading";
// import { Link } from "react-router-dom";
// import CheckButton from "react-validation/build/button";
// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import Select from "react-validation/build/select";
// import { Spacing } from "src/Components/Style/Styles";
// import { authHeader } from "../../../services/auth-header";
// import "./newpartssteps.css";
// import { getShopDetails } from "src/services/job/job.service";

// const {
//   REACT_APP_CURRENT_ENVIRONMENT,
//   REACT_APP_DEVELOPMENT_URL,
//   REACT_APP_PRODUCTION_URL,
// } = process.env;

// const API_URL =
//   REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
//     ? REACT_APP_DEVELOPMENT_URL
//     : REACT_APP_PRODUCTION_URL;

// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="warning" role="alert">
//         &#9432; This field is required!
//       </div>
//     );
//   }
// };

// const NewEditDealer = () => {
//   const form = useRef();
//   const checkBtn = useRef();
//   const [details, setDetails] = useState(true);
//   const [shopUpload, setShopUpload] = useState(false);
//   const [eror, setEror] = useState(false);
//   const [errMessage, setErrMessage] = useState("");
//   const [submitted, setSubmitted] = useState(false);
//   const [isloading, setisloading] = useState(false);
//   const [width, setWidth] = useState(window.innerWidth);
//   const [selectOpen, setSelectOpen] = useState(false);
//   const [shoplocation, setshoplocation] = useState("");
//   const [locationError, setlocationError] = useState(false);
//   const [initialImage, setInitialImage] = useState();
//   const [country, setcountry] = useState("");
//   const [state, setstate] = useState("");

//   const [formm, setFormm] = useState({
//     phone_number: "",
//     shop_name: "",
//     shop_description: "",
//     city: "",
//     state: state,
//     country: country,
//     identification_type: "",
//     identity_number: "",
//     office_address: "",
//     company_photo: "",
//     referral_code: "",
//   });

//   async function grabLoggedInUser() {
//     axios
//       .get(API_URL + "users/auth-user?fullDetails=true", {
//         headers: authHeader(),
//       })
//       .then((resp) => {
//         console.log(resp);

//         const response = resp.data.data.user_info;
//         const partsDetails = resp.data.data.part_dealer;
//         console.log(partsDetails);
//         setFormm({
//           ...formm,
//           phone_number: response.phone_number,
//           company_photo: partsDetails.company_photo,
//           office_address: response.address,
//           city: partsDetails.city,
//           country: partsDetails.country,
//           state: partsDetails.state,
//           shop_name: partsDetails.shop_name,
//           shop_description: partsDetails.shop_description,
//           identification_type:
//             partsDetails.identification_type === null
//               ? ""
//               : partsDetails.identification_type,
//           identity_number:
//             partsDetails.identity_number === null
//               ? ""
//               : partsDetails.identity_number,
//         });
//         setstate(partsDetails.state);
//         setcountry(partsDetails.country);
//         setshoplocation(partsDetails.office_address);
//         let shopImage = partsDetails.company_photo;
//         setImages([{ dataURL: shopImage }]);

//         // console.log(initialImage);
//       })
//       .catch((err) => {
//         console.log("none");
//       });
//   }

//   const changeHandle = (e) => {
//     setFormm({
//       ...formm,
//       [e.target.name]: e.target.value,
//     });
//   };
//   // function closeModal() {
//   // 	setSubmitted(false);
//   // 	window.location.reload();
//   // }

//   const nextBtn = () => {
//     form.current.validateAll();
//     if (checkBtn.current.context._errors.length === 0) {
//       setDetails(false);
//       setShopUpload(true);
//     } else {
//     }
//   };

//   const backBtn = () => {
//     setDetails(true);
//     setShopUpload(false);
//   };

//   const [images, setImages] = React.useState([]);
//   const maxNumber = 1;

//   const [checkboxValue, setCheckboxValue] = useState(true);

//   const onChange = (imageList, addUpdateIndex) => {
//     const loadedImage = imageList;
//     let newArry = imageList.map((item) => item.data_url);
//     setImages(imageList);
//     if (loadedImage) {
//       setFormm({
//         ...formm,
//         company_photo: newArry[0],
//       });
//     }
//   };

//   const updateLocation = () => {
//     setFormm({
//       ...formm,
//       office_address: shoplocation,
//     });
//     if (locationError) {
//       setlocationError(false);
//     }
//   };

//   useEffect(() => {
//     console.log(images);
//   }, [images]);

//   useEffect(() => {
//     updateLocation();
//   }, [shoplocation]);

//   const submitForm = (e) => {
//     setisloading(true);
//     console.log(country, state);
//     e.preventDefault();

//     form.current.validateAll();

//     if (checkBtn.current.context._errors.length === 0) {
//       formm.state = state;
//       formm.country = country;

//       axios
//         .patch(API_URL + "part-dealer/update", formm, {
//           headers: authHeader(),
//         })
//         .then((res) => {
//           setisloading(false);
//           setSubmitted(true);
//         })
//         .catch((error) => {
//           const resMessage =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();
//           console.log(resMessage);
//           setisloading(false);
//           setEror(true);
//           setErrMessage(resMessage);
//         });
//     } else {
//       setisloading(false);
//     }
//   };

//   useEffect(() => {
//     grabLoggedInUser();
//   }, []);

//   useEffect(() => {
//     window.addEventListener("resize", () => setWidth(window.innerWidth));
//     if (width > 800) {
//       setShopUpload(true);
//     } else if (width < 800) {
//       setShopUpload(false);
//     }
//   }, [width]);

//   function dismissSelect() {
//     console.log("---");
//     setSelectOpen(false);
//   }

//   return (
//     <div onClick={dismissSelect}>
//       <main
//         className="steps_main_wrapper"
//         style={{
//           border: "1px solid #e5e5e5",
//           marginTop: "40px",
//           maxWidth: 1200,
//         }}
//       >
//         {width < 800 ? (
//           <section className="st_topBar">
//             <p className="st_branding">
//               {details && " Edit Personal Details"}
//               {shopUpload && "Edit Shop Photo"}
//             </p>
//             <p className="st_back" onClick={backBtn}>
//               Back
//             </p>
//           </section>
//         ) : (
//           <div
//             style={{
//               ..._x.header3,
//               // background: `var(--primary2)`,
//               borderTopRightRadius: width < 800 ? 0 : 10,
//               borderTopLeftRadius: width < 800 ? 0 : 10,
//             }}
//           >
//             <div style={_x.header_txt1}>Edit Seller Shop Details</div>
//           </div>
//         )}
//         <Form
//           id="ad-form"
//           className="ad-form editAdform"
//           onSubmit={submitForm}
//           ref={form}
//         >
//           {width > 800 && (
//             <div className="personal-details-shop-reg">
//               <p>Personal Details:</p>
//             </div>
//           )}
//           <section>
//             {details && (
//               <section className="details_step_form">
//                 <div>
//                   <label htmlFor="phone">
//                     Phone Number
//                     <Input
//                       type="number"
//                       placeholder="Enter Phone Number"
//                       name="phone_number"
//                       maxLength="13"
//                       validations={[required]}
//                       onChange={changeHandle}
//                       value={formm.phone_number}
//                     />
//                   </label>
//                   <label htmlFor="idtype">
//                     Type of Identification
//                     <Select
//                       name="identification_type"
//                       onChange={changeHandle}
//                       value={formm.identification_type}
//                     >
//                       <option value="">Select ID Type</option>
//                       <option value="International Passport">
//                         International Passport
//                       </option>
//                       <option value="NIN">National Identity Number</option>
//                       <option value="Voters Card Number">
//                         Voters Card Number
//                       </option>
//                     </Select>
//                   </label>
//                 </div>
//                 <Spacing vertical={25} />

//                 <div
//                   className
//                   style={{
//                     display: "flex",
//                     alignItems: "flex-end",
//                   }}
//                 >
//                   <label htmlFor="id">
//                     Identification Number
//                     <Input
//                       type="text"
//                       placeholder="Enter ID Number"
//                       name="identity_number"
//                       onChange={changeHandle}
//                       value={formm.identity_number}
//                     />
//                   </label>
//                   <label
//                     htmlFor="referal"
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       flexDirection: "row",
//                     }}
//                   >
//                     <input
//                       type="checkbox"
//                       name=""
//                       id="checkbox_st"
//                       // style={{
//                       //   ..._x.checkbox,
//                       //   height: 40,
//                       //   width: 38,
//                       //   border: '1px solid grey',
//                       // }}
//                     />
//                     Not Applicable
//                   </label>
//                 </div>
//                 {/* <Spacing vertical={25} /> */}

//                 {width > 800 && (
//                   <div className="personal-details-shop-reg">
//                     <p>Shop Details:</p>
//                   </div>
//                 )}

//                 <div>
//                   <label htmlFor="shopName">
//                     Shop Name
//                     <Input
//                       type="text"
//                       placeholder="Enter Shop Name"
//                       name="shop_name"
//                       validations={[required]}
//                       onChange={changeHandle}
//                       value={formm.shop_name}
//                     />
//                   </label>
//                   <label htmlFor="id">
//                     Shop Address
//                     <Autocomplete
//                       className="autolocation"
//                       onPlaceSelected={(place) => {
//                         const selectedAddress = place.formatted_address;
//                         const addressComponents = place.address_components;

//                         let state = "";
//                         let country = "";

//                         for (let i = 0; i < addressComponents.length; i++) {
//                           const types = addressComponents[i].types;

//                           for (let j = 0; j < types.length; j++) {
//                             if (types[j] === "administrative_area_level_1") {
//                               state = addressComponents[i].long_name;
//                             }
//                             if (types[j] === "country") {
//                               country = addressComponents[i].long_name;
//                             }
//                           }
//                         }

//                         setstate(state);
//                         setcountry(country);
//                         setshoplocation(selectedAddress);
//                       }}
//                       defaultValue={shoplocation}
//                       // onChange={updateLocation}
//                       options={{
//                         types: ["establishment", "geocode"],
//                       }}
//                     />
//                     {locationError && (
//                       <div style={{ color: "red", fontSize: "11px" }}>
//                         Address is required.
//                       </div>
//                     )}
//                   </label>
//                   {/* </label> */}
//                 </div>
//                 <Spacing vertical={25} />
//                 {/* <div> */}
//                 <label htmlFor="idtype" className="textEditArea">
//                   Shop Description
//                   <textarea
//                     type="text"
//                     placeholder="Enter Shop Description"
//                     name="shop_description"
//                     onChange={changeHandle}
//                     value={formm.shop_description}
//                     style={_x.textarea}
//                     rows="4"
//                   />
//                 </label>
//                 {/* </div> */}
//                 <Spacing vertical={25} />
//               </section>
//             )}

//             {shopUpload && (
//               <section className="st_shop_pix">
//                 <div style={{ flexDirection: "column" }}>
//                   {width > 800 && (
//                     <div style={{ margin: "20px 0 10px", fontSize: "14px" }}>
//                       Upload Company Photo
//                     </div>
//                   )}
//                   <ImageUploading
//                     value={images}
//                     onChange={onChange}
//                     maxNumber={maxNumber}
//                     dataURLKey="data_url"
//                   >
//                     {({
//                       imageList,
//                       onImageUpload,
//                       onImageRemoveAll,
//                       onImageUpdate,
//                       onImageRemove,
//                       isDragging,
//                       dragProps,
//                     }) => (
//                       // write your building UI
//                       <div className="st_shop_image">
//                         <section className="st_ppix">
//                           {imageList.map((image, index) => {
//                             return (
//                               <div key={index} className="sh_photo_wrap">
//                                 <img
//                                   src={images[0].dataURL || image["data_url"]}
//                                   alt="quickmechs-product-image"
//                                   width="50"
//                                   height="50"
//                                 />
//                                 {/* <div className="image-item__btn-wrapper"> */}
//                                 <div
//                                   className="st_delete"
//                                   onClick={() => onImageRemove(index)}
//                                 >
//                                   <RiDeleteBin5Line />
//                                 </div>

//                                 {/* </div> */}
//                               </div>
//                             );
//                           })}
//                         </section>
//                         <div
//                           className="sh_photo_info"
//                           id="info_mob-st"
//                           onClick={onImageUpload}
//                         >
//                           <p className="plus_st">
//                             <AiOutlinePlus />
//                           </p>
//                           <p className="addPhoto">
//                             <span>
//                               <ImImages />
//                             </span>
//                             Add Photo
//                           </p>
//                           <p className="num_uploaded">Uploaded 1/1 images</p>
//                         </div>
//                       </div>
//                     )}
//                   </ImageUploading>

//                   <p className="st_notice">
//                     Picture must not exceed 5MB. Supported formats are *jpg,
//                     *gif and *png
//                   </p>
//                 </div>
//               </section>
//             )}
//           </section>

//           {width < 800 && (
//             <section className="steps_indicator">
//               <article className="st_indicator">
//                 <div
//                   className="indicator"
//                   style={
//                     details
//                       ? { borderColor: "var(--primary2)" }
//                       : { borderColor: "lightgrey" }
//                   }
//                 >
//                   1. Details
//                 </div>
//                 <div
//                   className="indicator"
//                   style={
//                     shopUpload
//                       ? { borderColor: "var(--primary2)" }
//                       : { borderColor: "lightgrey" }
//                   }
//                 >
//                   2. Upload
//                 </div>
//               </article>
//               <div>
//                 {shopUpload ? (
//                   <button className="st_btn">Submit</button>
//                 ) : (
//                   <p className="st_btn" onClick={nextBtn}>
//                     Next
//                   </p>
//                 )}
//               </div>
//             </section>
//           )}
//           <CheckButton style={{ display: "none" }} ref={checkBtn} />
//           {width > 800 && <button className="st_btn">Save</button>}
//         </Form>
//         {eror && (
//           <section className="err_wrap">
//             <div className="err">
//               {errMessage === "One or more fields are invalid"
//                 ? "Shop Photo is required"
//                 : errMessage}
//               <p className="err_btn" onClick={() => setEror(false)}>
//                 <GrFormClose />
//               </p>
//             </div>
//           </section>
//         )}
//         {submitted && (
//           <section style={_x.modal_bg}>
//             <div
//               open={submitted === false}
//               // onClick={closeModal}
//               style={{ border: "none" }}
//             >
//               <div style={_x.modall}>
//                 <div
//                   style={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "flex-end",
//                   }}
//                 ></div>

//                 <p
//                   style={{
//                     fontFamily: "montserrat",
//                     fontSize: "14px",
//                     textAlign: "center",
//                     // color: "green",
//                     fontSize: "1rem",
//                     // fontWeight: '500',
//                   }}
//                 >
//                   Details Successfully Updated
//                 </p>
//                 <article
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Link to="/home/dashboard" style={{ textDecoration: "none" }}>
//                     <button style={_x.continue}>Return to Dashboard</button>
//                   </Link>
//                 </article>
//               </div>
//             </div>
//           </section>
//         )}
//       </main>
//     </div>
//   );
// };

// export default NewEditDealer;

// const _x = {
//   btn: {
//     background: "transparent",
//     height: 30,
//     width: 20,
//     fontSize: 22,
//     color: "grey",
//     borderRadius: 0,
//     padding: 0,
//     margin: 0,
//   },
//   title_sect: {
//     marginBottom: "53px",
//     textAlign: "center",
//   },
//   header_txt1: {
//     fontWeight: 500,
//     fontSize: 20,
//     marginBottom: 15,
//     letterSpacing: 1.5,
//   },

//   header: {
//     fontWeight: "bold",
//     fontSize: 15,
//   },
//   header2: {
//     fontWeight: "normal",
//     fontSize: "14px",
//     marginBottom: "2px",
//     color: "#222222",
//   },
//   header3: {
//     fontSize: 15,
//     fontWeight: 550,
//     textAlign: "center",
//     width: "100%",
//     padding: 5,
//     // color: 'white',
//     boxShadow: "2px 3px 4px rgb(0 0 0 / 25%)",
//     // borderBottom: '1px solid #e5e5e580',
//     height: 68,
//     display: "grid",
//     placeItems: "center",
//   },

//   bottom: {
//     display: "flex",
//     flexDirection: "row",
//   },
//   btn2: {
//     color: "var(--primary1)",
//     fontFamily: "montserrat",
//     paddingTop: "10px",
//   },
//   modall: {
//     background: "white",
//     width: 320,
//     height: 180,
//     outline: "none",
//     borderRadius: 6,
//     margin: "170px auto",
//     display: "grid",
//     placeItems: "center",
//     padding: 20,
//     position: "absolute",
//     top: "20%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     boxShadow: "1px 1px 18px #00000040",
//   },

//   continue: {
//     height: 40,
//     // width: 137,
//     backgroundColor: "var(--primary2)",
//     paddingLeft: 15,
//     paddingRight: 15,
//     // border: '1px solid',
//     color: "#fff",
//     borderRadius: 6,
//     fontWeight: "bold",
//     fontSize: 16,
//     marginBottom: "1.2rem",
//     // marginLeft: '1rem',
//   },

//   close: {
//     cursor: "pointer",
//   },
//   modal_bg: {
//     position: "fixed",
//     top: "0",
//     left: "0",
//     height: "100vh",
//     width: "100%",
//     backgroundColor: "#00000090",
//     overflow: "hidden",
//   },
//   hyperlink: {
//     color: "var(--primary2)",
//     cursor: "pointer",
//     fontWeight: "bold",
//   },
//   checkbox_container: {
//     display: "flex",
//     alignItems: "flex-start",
//     fontWeight: 500,
//   },
//   checkbox: {
//     height: 25,
//     width: 25,
//     marginRight: 10,
//     marginLeft: 20,
//   },

//   textarea: {
//     // width: '95%',
//     border: "1px solid #DBDEE7",
//     borderRadius: 3,

//     // height: 89,
//     // resize: 'none',
//   },
// };

import React from "react";
import "./NewPartsDealerStepsForm.scss";
import SparPartDealerFormEdit from "./SparPartDealerFormEdit";

function NewEditDealer(props) {
  return (
    <div className="NewPartsDealerStepsForm-wrapper">
      <div className="NewPartsDealerStepsForm-inner">
        <div className="form-wrap-part-dealer">
          <div className="inner-shadow-header ">
            <span>Edit Seller Shop Details</span>
          </div>
          <div className="form-body-part-dealer">
            <SparPartDealerFormEdit />
          </div>
        </div>
      </div>
    </div>
  );
}

NewEditDealer.propTypes = {};

export default NewEditDealer;
