import moment from "moment";
import React, { useEffect, useState } from "react";
import { concatenateDate } from "../../../Global/Global";

function AvailableSlots({
	onInit,
	selectedWeekday,
	workhours,
	slotSelected,
	type,
	schedule,
	date,
	newlybooked,
	selectedDate,
}) {
	const [fullchiplist, setfullchiplist] = useState([]);
	const [unbookedchiplist, setunbookedchiplist] = useState([]);

	useEffect(() => {
		if (Array.isArray(schedule) && schedule.length) {
			const schedule_for_selected_weekday = schedule.filter(
				(each) => each.day === selectedWeekday
			)[0];

			const unbookedList = removeBookedSlots(
				// [
				// 	...
				schedule_for_selected_weekday.booked,
				// ...schedule_for_selected_weekday.off_time,
				// ], //? remove both booked times and deactivated slots
				schedule_for_selected_weekday.hours_available,
				newlybooked
			);

			generateTimeSlots(unbookedList);
		} else {
			console.log("no schedule");
		}
	}, [selectedWeekday, schedule, date, newlybooked]);

	/**
	 * this function converts a 24 hour clock to a 12 hour clock value
	 *
	 * @param {string} val the value to be converted
	 * @param {object} options the options to be passed ( noMeridian, date )
	 */
	function _12hourClock(val, options) {
		if (val > 12) {
			return val - 12 + `${options?.noMeridian === true ? "" : " PM"}`;
		} else {
			return (
				val +
				`${options?.noMeridian === true ? "" : val === 12 ? " PM" : " AM"}`
			);
		}
	}

	function removeBookedSlots(bookedList, fullList, newlybooked) {
		// console.log(bookedList);

		Object.keys(bookedList).map((k, v) => {
			let booked_date = k;
			let booked_times_in_date = bookedList[k];

			console.log(
				"00",
				moment(selectedDate).format("YYYY-MM-DD"),
				moment(booked_date).format("YYYY-MM-DD")
			);

			// console.log(
			// 	"🚀 ~ file: AvailableSlots.js ~ line 58 ~ Object.keys ~ booked_date",
			// 	booked_date,
			// 	booked_times_in_date,
			// 	newlybooked
			// );

			//* check if the date is the same as the selected date
			if (
				moment(selectedDate).format("YYYY-MM-DD") ==
				moment(booked_date).format("YYYY-MM-DD")
			) {
				fullList = fullList.filter(
					(each) => !booked_times_in_date.includes(each.toString())
				);
			}

			//* check for newly booked time and filter it out
			if (
				moment(selectedDate).format("YYYY-MM-DD") ==
				moment(newlybooked.date).format("YYYY-MM-DD")
			) {
				// console.log("removed slot");
				fullList = fullList.filter(
					(each) => newlybooked.time != each.toString()
				);
			}
		});

		return fullList;
	}

	function chipClicked(id) {
		const chip = document.getElementById(id);

		const chipParent = chip.parentElement;

		if (chipParent) {
			Array.from(chipParent.childNodes).forEach((e) => {
				e.style.background = "white";
				e.style.color = "var(--primary1)";
			});
		}

		chip.style.background = "var(--primary1)";
		chip.style.color = "white";

		const _time = id.toString().split(" ", 1)[0];
		const _meridian = id.toString().slice(-2);

		slotSelected(_time, _meridian);
	}

	function generateTimeSlots(slots) {
		let listish = [];
		let totalHrs = 0;

		if (typeof slots === "object") {
			slots = Object.values(slots);
		}

		slots.map((hr) => {
			listish.push(_12hourClock(hr));

			totalHrs++;
		});

		onInit("totalHrs", totalHrs - 1);
		setfullchiplist(listish);
	}

	return (
		<div style={_x.chips}>
			{fullchiplist.map((each) => {
				return (
					<div
						id={each}
						key={each}
						style={_x.chip}
						onClick={() => chipClicked(each)}
					>
						{each}
					</div>
				);
			})}
		</div>
	);
}

export default AvailableSlots;

const _x = {
	chips: {
		// height: 200,
		display: "grid",
		gridTemplateColumns: "repeat(4, 1fr)",
	},

	chip: {
		width: 60,
		height: 26,
		textAlign: "center",
		fontSize: 10,
		border: "1px solid var(--primary1)",
		marginRight: 15,
		marginBottom: 15,
		paddingTop: 6,
		color: "var(--primary1)",
		cursor: "pointer",
		fontWeight: "bold",
	},
};
