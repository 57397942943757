import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import googleIcon from "src/Resources/Icons/google.png";
import AuthService from "../../../services/auth.service";
import "./login.css";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;

const API_URLL = API_URL + "users/create-with-social";

const GoogleSignin = ({ width }) => {
	let history = useHistory();
	const [callData, setCallData] = [""];
	const [email, setEmail] = useState("");
	const [provider, setProvider] = useState("");
	const [provider_id, setProvider_id] = useState("");
	const [first_name, setFirst_name] = useState("");
	const [last_name, setLast_name] = useState("");

	const login = useGoogleLogin({
		onSuccess: async (respose) => {
			try {
				const res = await axios.get(
					"https://www.googleapis.com/oauth2/v3/userinfo",
					{
						headers: {
							Authorization: `Bearer ${respose.access_token}`,
						},
					}
				);

				// console.log(res.data);
				const newData = res.data;
				setEmail(newData.email);
				setFirst_name(newData.given_name);
				setLast_name(newData.family_name);
				setProvider("google");
				setProvider_id(newData.sub);

				return axios
					.post(API_URLL, {
						email: newData.email,
						provider: "google",
						provider_id: newData.sub,
						first_name: newData.given_name,
						last_name: newData.family_name,
					})
					.then((response) => {
						if (response.data.data.access_token) {
							localStorage.setItem("user", JSON.stringify(response.data));
							const currentUser = AuthService.getCurrentUser("user");
							const verified = currentUser.data.user_info.address;

							if (verified === null) {
								history.push("/auth/update-profile-details");
							} else if (verified !== null) {
								history.push("/home/mechanic-and-services/");
							}
						}
					});
			} catch (err) {
				console.log(err);
			}
		},
	});

	return (
		<div style={{ width: width ?? "100%" }}>
			<button onClick={login} className="social-btn">
				<img src={googleIcon} alt="google" />
				<div className="gog">Sign in with Google</div>
			</button>
		</div>
	);
};

export default GoogleSignin;
