import VisibilityOnIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Fab from "@mui/material/Fab";
import { useState } from "react";
import "./myjob.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteJob, disableJob, enableJob } from "src/services/job/job.service";

export default function CardElement({
  getAllJobs,
  data,
  page,
  onClick,
  ...props
}) {
  const [hover, setHover] = useState(false);
  const handleDelete = async (e) => {
    e.preventDefault();
    const result = await deleteJob(data?.id);
    console.log(result.data);
    if (result.status === 200 || result.status === 201) {
      getAllJobs();
    }
  };
  const handleDisable = async (e) => {
    e.preventDefault();
    const result = await disableJob(data?.id);
    console.log(result.data);
    if (result.status === 200 || result.status === 201) {
      getAllJobs();
    }
  };
  const handleEnable = async (e) => {
    e.preventDefault();
    const result = await enableJob(data?.id);
    if (result.status === 200 || result.status === 201) {
      getAllJobs();
    }
  };
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="card"
      onClick={onClick}
      {...props}
    >
      <div className="image-container">
        <img
          style={{ width: "100%", height: "100%" }}
          src={data?.logo}
          alt="employer image"
        />
        {/* vschvhchdvh */}
      </div>
      <div className="details-tex-container">
        <span className="title-incard">{data?.title}</span>
        <span className="sub-title-incard">{data?.company_name}</span>
        <span className="sub-title-incard">{data?.location}</span>
        <span className="sub-title-incard">{data?.category}</span>
        <span className="job-type">{data?.employment_type}</span>
      </div>
      {page === "my-job" && hover ? (
        <>
          <Fab
            onClick={handleDelete}
            sx={{ marginRight: "10px", cursor: "pointer" }}
            size="small"
            color="primary"
            aria-label="add"
          >
            <DeleteIcon />
          </Fab>
          <Fab
            onClick={data?.status ? handleDisable : handleEnable}
            sx={{
              cursor: "pointer",
            }}
            size="small"
            color="primary"
            aria-label="add"
          >
            {data?.status ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
          </Fab>
        </>
      ) : null}
    </div>
  );
}
