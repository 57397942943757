import React from 'react'

const EditDealer = () => {
    return (
        <div>
            <h1>Part Dealer profile Edit Form goes here</h1>
        </div>
    )
}

export default EditDealer
