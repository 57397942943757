import React, { useState, useEffect } from 'react';
import Alternator from '../../../Resources/Images/alternator.png';
import { FaHeart } from 'react-icons/fa';
import { IoLocationOutline } from 'react-icons/io5';
import axios from 'axios';
import { authHeader } from 'src/services/auth-header';
import Spinner from 'src/Components/Functional/Spinner/Spinner';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const truncateString = (string = '', maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

const Parts = ({ data }) => {
  return (
    <div className="part-saved-wrap">
      <div className="parts-image-saved">
        <img src={data.product_photo} alt="product" />
      </div>
      <div>
        <p className="like-saved">
          <span>
            <FaHeart color="red" />
          </span>
          Saved
        </p>
        <a
          style={{
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
          href={`/home/spare-parts/${data.id}/${data.product_title}`}
        >
          <p className="like-title">{data.product_title}</p>
        </a>
        <p className="like-code">{data.product_no} </p>
        <p className="like-address">
          {' '}
          <span>
            <IoLocationOutline color="green" />
          </span>
          {truncateString(data.address, 55)}
        </p>
        <p className="like-price">$ {data.price}</p>
      </div>
    </div>
  );
};

const PartsItems = () => {
  const [favouritesList, setfavouritesList] = useState([]);
  const [isloading, setisloading] = useState(false);

  const favorites = () => {
    setisloading(true);
    axios
      .get(API_URL + `products/me/favourite`, {
        headers: authHeader(),
      })
      .then((res) => {
        setfavouritesList(res.data.data);
        setisloading(false);
      })
      .catch((err) => setisloading(false));
  };

  useEffect(() => {
    favorites();
  }, []);
  return (
    <section className="main-coll">
      {isloading ? (
        <>
          {' '}
          <Spinner
            fullscreen={false}
            type="puff"
            isloading={isloading}
            size={100}
          />
        </>
      ) : (
        <>
          <h2
            style={{
              marginTop: '0px',
              fontSize: '18px',
              fontWeight: '400',
              color: '#000000',
            }}
          >
            Your Saved Items
          </h2>
          <p style={{ fontSize: '16px' }}>Spare Parts</p>
          {favouritesList.length === 0 ? (
            <p>You currently do not have any saved Products</p>
          ) : (
            <div>
              {favouritesList.map((item) => (
                <Parts data={item} />
              ))}
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default PartsItems;
