import React from 'react'

export default function TitleSection() {
  return (
    <div style={styles.container}> <label style={styles.txt}>My Products</label></div>
  )
}


const styles={
  container:{width:'100%',display: 'flex',alignItems:'center',justifyContent:'center',height:60,borderBottom:'1px solid #C4C4C4'},txt:{textAlign:'center',fontWeight:500,}
}