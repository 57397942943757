import TimeBox from "src/Components/Functional/TimeBox/TimeBox";
import { Spacing } from "src/Components/Style/Styles";
import { weekdays } from "src/Global/Global";

export default function WorkHourSection({
	title,
	form,
	updateHour,
	updateMeridian,
	_mobile,
	toggleWorkDay,
	simpleValidator,
	validHrs,
	setinstantNotice,
	setmobileNotice,
	setform,
}) {
	function organize(workHrs) {
		if (_mobile) {
			let limbo = {};

			weekdays.map((e) =>
				Object.keys(form.workHours).map((key, _) => {
					if (e === key) limbo[e] = form.workHours[key];
					return "";
				})
			);

			return limbo;
		} else return workHrs;
	}

	return (
		<div style={{ width: _mobile ? "100%" : null }}>
			{title}

			<Spacing />

			<div
				style={{
					display: _mobile ? "block" : "flex",
					justifyContent: "space-between",
					gap: 30,
				}}
			>
				<div
					style={
						_mobile
							? {
									display: "grid",
									gridTemplateColumns: "1fr 1fr",
									gridGap: 30,
									placeItems: "center",
									justifyContent: "space-between",
							  }
							: {
									display: "flex",
									justifyContent: "space-between",
									paddingInline: 8,
									width: "100%",
							  }
					}
				>
					<TimeBox morning />
					<TimeBox afternoon />
				</div>

				<div
					style={
						_mobile
							? {
									display: "grid",
									gridTemplateColumns: "1fr 1fr",
									gridGap: 30,
									placeItems: "center",
							  }
							: {
									display: "flex",
									justifyContent: "space-between",
									paddingInline: 8,
									width: "100%",
							  }
					}
				>
					<TimeBox evening />
					<TimeBox midnight />
				</div>
			</div>

			<Spacing />

			<div style={_x(_mobile).work_hr_box}>
				{Object.keys(organize(form.workHours)).map((key, _) => {
					return (
						<div>
							<div key={key} style={_x().date_box}>
								<div style={_x().day_box}>
									<input
										type="checkbox"
										checked={form.workHours[key]["isChecked"]}
										onChange={() => toggleWorkDay(key)}
									/>

									<div style={{ marginLeft: 10 }}>{key}</div>
								</div>

								<div>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<div style={_x().time_meridian}>
											<input
												id="am_input"
												value={form.workHours[key]["time"]["from"]["hour"]}
												style={_x().time_input}
												placeholder="00"
												type="number"
												onChange={(e) =>
													form.workHours[key]["isChecked"] === true
														? updateHour(e, key, "from")
														: null
												}
												onBlur={simpleValidator.current.showMessageFor(
													"Work Hour"
												)}
												pattern="[1234567890]"
												maxLength="1"
												onKeyPress={(e) => {
													if (e.key === "e" || e.key === "-") {
														e.preventDefault();
													}

													if (
														!validHrs.includes(
															form.workHours[key]["time"]["from"]["hour"] +
																e.key
														)
													) {
														e.preventDefault();
													}
												}}
											/>

											<select
												style={{
													..._x().select,
													..._x().meridian,
												}}
												onClick={(e) => updateMeridian(e, key, "from")}
											>
												<option value="AM">AM</option>
												<option value="PM">PM</option>
											</select>
										</div>

										<div style={{ margin: 10 }}>-</div>

										<div style={_x().time_meridian}>
											<input
												id="pm_input"
												value={form.workHours[key]["time"]["to"]["hour"]}
												style={_x().time_input}
												placeholder="00"
												type="number"
												onChange={(e) =>
													form.workHours[key]["isChecked"] === true
														? updateHour(e, key, "to")
														: null
												}
												onBlur={simpleValidator.current.showMessageFor(
													"Work Hour"
												)}
												pattern="[1234567890]"
												maxLength="1"
												// name="Work Hour"
												onKeyPress={(e) => {
													if (e.key === "e" || e.key === "-") {
														e.preventDefault();
													}

													if (
														!validHrs.includes(
															form.workHours[key]["time"]["to"]["hour"] + e.key
														)
													) {
														e.preventDefault();
													}
												}}
											/>

											<select
												style={{ ..._x().select, ..._x().meridian }}
												onClick={(e) => updateMeridian(e, key, "to")}
											>
												<option value="PM">PM</option>
												<option value="AM">AM</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<Spacing vertical={10} />

							{form.workHours[key]["isChecked"] === true && (
								<div
									style={{
										visibility: form.showfielderror ? "visible" : "hidden",
									}}
								>
									<div style={{ paddingLeft: 11 }}>
										{simpleValidator.current.message(
											"Work Hour",
											form.workHours[key]["time"]["from"]["hour"] &&
												form.workHours[key]["time"]["to"]["hour"],
											`${
												// form.workHours[key]["isChecked"] === true
												// 	?
												"required|integer"
												// : null
											}`,
											{
												element: (msg) => <div style={_x().error}>{msg}</div>,
											}
										)}
									</div>
								</div>
							)}
						</div>
					);
				})}
			</div>

			<div
				style={{
					display: _mobile ? "" : "flex",
					marginTop: "30px",
					marginLeft: 15,
					marginRight: 12,
					// background: "blue",
				}}
			>
				<div
					className="instantService"
					style={{
						width: _mobile ? "100%" : 372,
						display: "flex",
						alignItems: "flex-end",
						marginRight: _mobile ? 0 : 50,
						marginBottom: _mobile ? 10 : 0,
					}}
				>
					<p>
						Turn On Instant Service
						<span
							className="learnMoreInstantBtn"
							onClick={() => setinstantNotice(true)}
						>
							Learn More
						</span>
					</p>

					<div
						className="list-icon instantToggle"
						style={{
							display: "flex",
							height: 42,
							alignItems: "center",
						}}
					>
						<input
							checked={form.instant_service === "yes" ? true : false}
							type="checkbox"
							id="switch"
							onChange={(e) => {
								e.target.checked === true
									? setform({ ...form, instant_service: "yes" })
									: setform({ ...form, instant_service: "no" });
							}}
						/>
						<label for="switch">Toggle</label>
					</div>
				</div>

				<div
					className="instantService"
					style={{
						display: "flex",
						alignItems: "flex-end",
						width: _mobile ? "100%" : 372,
					}}
				>
					<p>
						Turn On Mobile Service
						<span
							className="learnMoreInstantBtn"
							onClick={() => setmobileNotice(true)}
						>
							Learn More
						</span>
					</p>

					<div
						className="list-icon instantToggle"
						style={{
							display: "flex",
							height: 42,
							alignItems: "center",
						}}
					>
						<input
							checked={form.mobile_service}
							type="checkbox"
							id="switch2"
							onChange={(e) => {
								setform({ ...form, mobile_service: !form.mobile_service });
							}}
						/>
						<label for="switch2">Toggle</label>
					</div>
				</div>
			</div>
		</div>
	);
}

const _x = (_mobile) => ({
	work_hr_box: {
		display: "grid",
		gridTemplateRows: _mobile ? "repeat(7,1fr)" : "repeat(4,1fr)",
		gridTemplateColumns: _mobile ? null : "1fr 1fr",
		gridGap: 20,
		marginTop: 10,
	},

	meridian: {
		border: "none",
		height: 30,
		fontSize: 10,
		color: "lightslategrey",
	},

	time_input: {
		width: 30,
		height: 20,
		textAlign: "center",
		border: "none",
		fontSize: 10,
		// border: "1px solid",
		padding: 0,
	},

	time_meridian: {
		display: "flex",
		alignItems: "center",
		border: "1px solid #DBDEE7",
		borderRadius: 4,
	},

	date_box: {
		height: 30,
		borderRadius: 3,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: 10,
	},

	day_box: {
		display: "grid",
		gridAutoFlow: "column",
		placeItems: "center",
	},

	error: {
		color: "red",
		fontSize: 10,
	},

	select: {
		height: 32,
		width: "100%",
		border: "1px solid #DBDEE7",
		borderRadius: 3,
	},
});
