import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const Brim = createContext();

export const Control = (props) => {
	const location = useLocation();

	useEffect(() => {
		if (location.pathname !== "mechanicAndServices") {
			setcontrol({
				...control,
				sidebar: { ...control.sidebar, isOpen: false },
			});
		}
	}, [location]);

	const [control, setcontrol] = useState({
		sidebar: {
			isOpen: true,
			values: {},
		},

		loader: { isOpen: false },
	});

	return (
		<Brim.Provider value={[control, setcontrol]}>
			{props.children}
		</Brim.Provider>
	);
};
