import { Paper, useMediaQuery } from "@material-ui/core";
import React from "react";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Spacing } from "../../../Components/Style/Styles";

function ReportAbuse() {
	const _mobile = useMediaQuery("(max-width:800px)");

	return (
		<>
			<div style={{ display: "grid", justifyContent: "center" }}>
				<Paper style={_x(_mobile).report_abuse} elevation={_mobile ? 0 : 1}>
					<p style={_x().text1}>Report Abuse</p>

					<Spacing />

					<input
						className="sigma"
						style={_x(_mobile).input}
						placeholder="Full Name"
					/>

					<Spacing />

					<input
						className="sigma"
						style={_x(_mobile).input}
						placeholder="Email Address"
					/>

					<Spacing />

					<textarea
						className="sigma"
						style={_x(_mobile).textarea}
						placeholder="Message Here"
					/>

					<Spacing />

					<button style={_x(_mobile).btn}>Send Message</button>

					<p style={_x().text2}>
						Thank You for your response. We will get back to you shortly.
					</p>
				</Paper>
			</div>

			<Spacing vertical={_mobile ? 0 : 100} />

			<Footer />
		</>
	);
}

export default ReportAbuse;

const _x = (mobile) => ({
	report_abuse: {
		background: "white",
		width: mobile ? "100%" : 860,
		height: "100%",
		display: "grid",
		placeItems: "center",
		marginTop: 60,
		padding: mobile ? 20 : 50,
	},

	text1: {
		color: "var(--secondary1)",
		fontSize: 20,
		fontWeight: 500,
	},

	text2: {
		color: "var(--secondary1)",
		fontSize: 12,
		fontWeight: 500,
	},

	input: {
		width: mobile ? "100%" : 408,
		height: 40,
		borderRadius: 6,
		border: "1px solid #DBDEE7",
		fontSize: 12,
	},

	textarea: {
		height: 72,
		width: mobile ? "100%" : 408,
		padding: 10,
		resize: "none",
		border: "1px solid #DBDEE7",
		fontSize: 12,
	},

	btn: {
		width: mobile ? "100%" : 408,
		height: 40,
		background: "var(--primary1)",
		color: "white",
		fontSize: 18,
		fontWeight: "bold",
		borderRadius: 6,
	},
});
