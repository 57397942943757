import { useEffect } from "react";
import { ClipLoader } from "react-spinners";

export default function DeviceVerification() {
	useEffect(() => {
		if (isIOS()) {
			window.location.href =
				"https://apps.apple.com/ng/app/quickmechs/id6443885659";
		} else if (isAndroid()) {
			window.location.href =
				"https://play.google.com/store/apps/details?id=com.aftjdigital.quickmech";
		} else {
			console.log("This device is not iOS or Android.");
		}
	}, []);

	function isIOS() {
		return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	}

	function isAndroid() {
		return /Android/.test(navigator.userAgent);
	}

	return (
		<div
			style={{
				height: "90vh",
				display: "grid",
				placeItems: "center",
			}}
		>
			<ClipLoader
				loading={true}
				// css={{ position: "fixed" }}
				color={"var(--primary)"}
				size={50}
			/>
		</div>
	);
}
