import { Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import DownloadApp from "src/Components/Functional/DownloadApp/DownloadApp";
import Spinner from "src/Components/Functional/Spinner/Spinner";
import Footer from "../../../Components/Functional/Footer/Footer";
import { Spacing } from "../../../Components/Style/Styles";
import { authHeader } from "../../../services/auth-header";

const {
	REACT_APP_CURRENT_ENVIRONMENT,
	REACT_APP_DEVELOPMENT_URL,
	REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
	REACT_APP_CURRENT_ENVIRONMENT === "DEVELOPMENT"
		? REACT_APP_DEVELOPMENT_URL
		: REACT_APP_PRODUCTION_URL;

function ProductReport() {
	const _mobile = useMediaQuery("(max-width:800px)");

	const { id } = useParams();
	// const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	// const [fullName, setFullName] = useState("")
	const [success, setSuccess] = useState(false);
	const [inputs, setInputs] = useState({
		full_name: "",
		email: "",
		message: "",
	});
	// async function grabLoggedInUser() {

	// 	const resp = await AuthService.getCurrentLoggedInUser();
	// 	if (resp.success===true) {

	// 		setData(resp.data.user_info);
	//         setFullName( resp.data.user_info.first_name + " " + resp.data.user_info.last_name)
	// 	} else {

	// 	}
	// };

	const reportApi = API_URL + "products/" + `${id}` + "/report-abuse";

	const submit_report = () => {
		setProgress(93);
		setLoading(true);
		axios
			.post(reportApi, inputs, { headers: authHeader() })
			.then((response) => {
				setLoading(false);
				setProgress(100);
				window.scrollTo(0, 0);
				setSuccess(true);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	const changeHandle = (e) => {
		setInputs({
			...inputs,
			[e.target.name]: e.target.value,
		});
	};

	// useEffect(() => {
	//     grabLoggedInUser();
	// }, [])

	return (
		<>
			{loading ? (
				<Spinner fullscreen={false} size={100} />
			) : (
				<div style={{ display: "grid", justifyContent: "center" }}>
					<Paper style={_x(_mobile).report_abuse}>
						<p style={_x(_mobile).text1}>Report Abuse</p>

						<Spacing />

						<input
							// className="sigma"
							style={_x(_mobile).input}
							placeholder="Full Name"
							name="full_name"
							onChange={changeHandle}
							value={inputs.full_name}
						/>

						<Spacing />

						<input
							// className="sigma"
							style={_x(_mobile).input}
							placeholder="Email Address"
							name="email"
							onChange={changeHandle}
							value={inputs.email}
						/>

						<Spacing />

						<textarea
							className="sigma"
							style={_x(_mobile).textarea}
							placeholder="Message Here"
							onChange={changeHandle}
							name="message"
							value={inputs.message}
						/>

						<Spacing />

						<button style={_x(_mobile).btn} onClick={submit_report}>
							Send Message
						</button>

						<p style={_x(_mobile).text2}>
							Thank You for your response. We will get back to you shortly.
						</p>
					</Paper>
				</div>
			)}

			<Spacing vertical={100} />
			<DownloadApp />

			<Footer />
			{success && (
				<div style={_x(_mobile).abuse_box}>
					<div style={_x(_mobile).abuse_wrap}>
						<p
							style={_x(_mobile).abuse_btns}
							onClick={() => {
								setSuccess(false);
								setInputs({
									full_name: "",
									email: "",
									message: "",
								});
							}}
						>
							{" "}
							<IoClose />{" "}
						</p>
						<p style={{ margin: "0", fontSize: "14px", fontWeight: "500" }}>
							Thank You.
						</p>
						<p style={{ margin: "10px ", fontSize: "14px" }}>
							Your report has been submitted
						</p>
					</div>
				</div>
			)}

			{loading && (
				<LoadingBar
					color="#587FC0"
					loaderSpeed={4000}
					progress={progress}
					onLoaderFinished={() => setProgress(0)}
				/>
			)}
		</>
	);
}

export default ProductReport;

const _x = (_mobile) => ({
	report_abuse: {
		background: "white",
		width: _mobile ? "100%" : 860,
		height: "100%",
		display: "grid",
		placeItems: "center",
		marginTop: 60,
		padding: _mobile ? "1rem" : 50,
	},
	abuse_box: {
		background: "#00000090",
		position: "absolute",
		width: "100vw",
		height: "100vh",
		top: "0",
		left: "0",
	},

	abuse_wrap: {
		background: "#fff",
		display: "flex",
		flexDirection: "column",
		justifyContents: "center",
		alignItems: "center",
		margin: "30vh auto",
		borderRadius: "6px",
		width: "300px",
		position: "relative",
		padding: "50px 0",
	},
	abuse_btns: {
		position: "absolute",
		top: "-5px",
		right: "10px",
		cursor: "pointer",
		fontWeight: "500",
	},

	text1: {
		color: "var(--secondary1)",
		fontSize: 20,
		fontWeight: 500,
	},

	text2: {
		color: "var(--secondary1)",
		fontSize: 12,
		fontWeight: 500,
		width: _mobile ? "60%" : "100%",
		textAlign: "center",
	},

	input: {
		width: _mobile ? "80%" : 408,
		height: 40,
		borderRadius: 6,
		border: "1px solid #DBDEE7",
		fontSize: 12,
		fontFamily: "montserrat",
	},

	textarea: {
		height: 72,
		width: _mobile ? "80%" : 408,
		padding: 10,
		resize: "none",
		border: "1px solid #DBDEE7",
		fontSize: 12,
		fontFamily: "montserrat",
	},

	btn: {
		width: _mobile ? "80%" : 408,
		height: 40,
		background: "var(--primary1)",
		color: "white",
		fontSize: 18,
		fontWeight: "bold",
		borderRadius: 6,
	},
});
