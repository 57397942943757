import React from 'react'
import "./filter.css"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


const LoadSkeleton = () => {
    return (
        <main className="skeleton">
            <p>
                <Skeleton height={100} />
            </p>
            <Skeleton count={4}/>
            <p>
                <Skeleton width={50} />
            </p>
        </main>
    )
}

export default LoadSkeleton
