import React from 'react'

const IconChevron = require('../../../Resources/Icons/chevron-right-.svg')




export default function SparePardDealerCardMobileView({title,descriptionIcon,...props}) {
  return (
    <div style={styles.container} {...props}>
						<div style={styles.row2}>
              <img  src={descriptionIcon} />
						<span style={{fontWeight:500}}>{title}</span>
            </div>
              <img  src={IconChevron.default} />
					</div>
  )
}


const styles = {
container:{display:'flex',width:'100%',height:48, background:'#fff',border:'0.5px solid #C4C4C4',borderRadius:6,alignSelf:'center',flexDirection:'row',alignItems:'center',cursor:'pointer',justifyContent:'space-between',padding:10},
row2:{display:'flex',flexDirection:'row',alignItems:'center',gap:10},
}