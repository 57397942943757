import { skillTree } from "../../../Resources/Resources";
import React, { useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function Chips({ tab, onChipChange, initialChip }) {
	const _mobile = useMediaQuery("(max-width:800px)");

	useEffect(() => {
		const allChips = document.getElementsByClassName("chips_box")[0].childNodes;

		Array.from(allChips).forEach((each) => {
			each.style.display = "grid";
		});

		// const selected = document.getElementById(allChips[0].id);

		// selected.style.backgroundColor = "var(--accent_opacity)";
		// selected.style.color = "var(--accent1)";
	}, [tab]);

	function chipSelected(e) {
		const { id } = e.target;

		onChipChange(id);

		const allChips = document.getElementsByClassName("chips_box")[0].childNodes;

		Array.from(allChips).forEach((child) => {
			child.style.color = "black";
			child.style.backgroundColor = "transparent";
		});

		const selected = document.getElementById(id);

		selected.style.backgroundColor = "var(--accent_opacity)";
		selected.style.color = "var(--accent1)";
	}

	return (
		<div
			className="chips_box hideScroll hideScroll-firefox"
			style={_x(_mobile).chips}
		>
			{skillTree[tab]?.map((e) => {
				return (
					<button
						className="single_chip"
						key={e}
						onClick={chipSelected}
						id={e}
						style={{
							..._x(_mobile).chip,
							backgroundColor: initialChip
								? e === initialChip
									? "var(--accent_opacity)"
									: "transparent"
								: null,
							color: initialChip
								? e === initialChip
									? "var(--accent1)"
									: "black"
								: null,
						}}
					>
						{e}
					</button>
				);
			})}
		</div>
	);
}

export default Chips;

const _x = (_mobile) => ({
	chips: {
		width: _mobile ? "90vw" : "95%",
		display: "flex",
		// gridAutoFlow: "column",
		// alignSelf: "flex-start",
		overflowX: "scroll",
		// paddingLeft: 20,
		// paddingRight: 20,
	},

	chip: {
		height: 40,
		maxWidth: 150,
		marginRight: 15,
		display: "none",
		placeItems: "center",
		whiteSpace: "nowrap",
		fontSize: _mobile ? 12 : 14,
		borderRadius: 4,
		animation: "animateIn 300ms ease-out",
	},
});
