/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineShop } from "react-icons/ai";
import { GoTools } from "react-icons/go";
import { GrFormEdit } from "react-icons/gr";
import {
	MdOutlineArrowForwardIos,
	MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { RiEditCircleFill, RiNewspaperLine } from "react-icons/ri";
import { TbCalendarStats } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import ProfileDP from "src/Components/Functional/profileImage/ProfileDP";
import { Spacing } from "src/Components/Style/Styles";
import AuthService from "src/services/auth.service";
import mechService from "src/services/mech.service";
import ShopDetails from "./ShopDetails";
import SparePardDealerCardMobileView from "./SparePardDealerCardMobileView";

export default function Dashboard2() {
	const [dashboard, setDashboard] = useState({
		dateJoined: "",
		instantNotice: false,
		mobileNotice: false,
		showCalendar: false,
		showModal: false,
		isMech: false,
		isDealer: false,
		showPartsShop: false,
	});
	const [currentShop, setCurrentShop] = useState({});
	const [allShops, setAllShops] = useState([]);
	const [currentUser, setCurrentUser] = useState({});
	const history = useHistory();
	const _mobile = useMediaQuery("(max-width:800px)");

	const showParts = true;
	const showDealerAds = true;

	useEffect(() => {
		mechService.getMechShops().then((res) => {
			if (res.data?.length > 0) {
				setCurrentShop(res.data[0]);
				setAllShops(res.data);
			}
		});

		setDashboard({
			...dashboard,
			dateJoined: currentUser?.data?.user_info?.createdAt,
		});

		setCurrentUser({ ...AuthService.getCurrentUser("user") });
	}, []);

	useEffect(() => {
		const perm = currentUser?.data?.user_info?.permissions;

		setDashboard((prev) => ({
			...prev,
			isMech: perm?.includes("mechanic"),
			isDealer: perm?.includes("part_dealer"),
		}));
	}, [currentUser]);

	function toggleInstantMechanic(shopId, value) {
		mechService.toggleInstantService(shopId, value).then((res) => {
			if (res.success) {
				setAllShops((prevShop) =>
					prevShop.map((shop) => {
						if (shop.encodedKey === shopId) {
							return {
								...shop,
								instant_service: res.data.instant_service,
							};
						}

						return shop;
					})
				);
			}
		});
	}

	function toggleMobileMechanic(shopId, value) {
		mechService.toggleMobileService(shopId, value).then((res) => {
			if (res.success) {
				setAllShops((prevShop) =>
					prevShop.map((shop) => {
						if (shop.encodedKey === shopId) {
							return {
								...shop,
								mobile_service: res.data.mobile_service,
							};
						}

						return shop;
					})
				);
			}
		});
	}

	function openCalendar() {
		setDashboard({
			...dashboard,
			showCalendar: true,
		});
	}

	function openShopDetails(e) {
		setCurrentShop({ ...e });
		setDashboard({ ...dashboard, showCalendar: false, showPartsShop: false });
	}

	function becomeDealer() {
		if (currentUser && dashboard.isDealer) {
			history.push("/home/become-a-dealer/");
		} else if (currentUser && dashboard.isMech) {
			history.push("/home/become-a-dealer/");
		} else if (currentUser && !dashboard.isDealer) {
			history.push("/home/become-a-dealer/");
		} else {
			history.push(`/auth/login`);
		}
	}

	function regularUserAdvert() {
		if (dashboard.isDealer) {
			history.push("/home/submit-an-ad/");
		} else if (!dashboard.isDealer) {
			history.push("/home/become-a-dealer/");
		} else if (dashboard.isMech) {
			history.push("/home/become-a-dealer/");
		} else {
			history.push("/auth/login");
		}
	}

	function openPS() {
		setDashboard((p) => ({ ...p, showPartsShop: true }));
	}

	function getSidebar() {
		return (
			<div style={_x(_mobile).left}>
				<div style={_x().r1}>
					<ProfileDP
						style={{ width: "50px", height: "50px" }}
						size={{ fontSize: "2rem" }}
					/>

					<div className="username">
						<p>
							{currentUser?.data?.user_info?.first_name} &nbsp;
							{currentUser?.data?.user_info?.last_name}
						</p>

						<p className="dash-joined">
							{moment(dashboard?.dateJoined).format("MMMM, YYYY")}
						</p>
					</div>
				</div>

				<div className="panel-list" style={{ marginTop: 20 }}>
					{allShops.map((e, idx) => {
						const isActive = currentShop.encodedKey === e.encodedKey;

						return (
							<div
								className="shop-list"
								key={idx}
								style={_x(_mobile).sidebarItem}
							>
								<div
									style={{
										..._x().sidebarItemHeader,
										borderBottom:
											isActive && !_mobile ? "1px solid lightgrey" : "none",
										color: isActive && !_mobile ? "var(--primary1)" : "black",
										display: "grid",
										gridTemplateColumns: "auto 1fr auto",
									}}
									onClick={() => {
										if (_mobile) {
											history.push("/home/dashboard-details", {
												cS: currentShop,
											});
										} else {
											openShopDetails(e);
										}
									}}
								>
									<AiOutlineShop style={{ fontSize: 20, marginRight: 20 }} />

									<div style={{ marginRight: 30 }}>{e.shop_name}</div>

									<>
										{isActive && !_mobile ? (
											<MdOutlineKeyboardArrowDown
												style={{
													fontSize: 30,
													color: "var(--primary1)",
												}}
											/>
										) : (
											<MdOutlineArrowForwardIos
												style={{
													fontSize: 15,
												}}
											/>
										)}
									</>
								</div>

								<div
									style={{
										..._x().sidebarItemBody,
										display: isActive && !_mobile ? "block" : "none",
									}}
								>
									<div style={_x().sidebarItemHeader} onClick={openCalendar}>
										<TbCalendarStats
											style={{ fontSize: 20, marginRight: 20 }}
										/>

										<div>Set Schedule</div>

										<div style={{ marginRight: 12 }} />
									</div>

									<div
										style={_x().sidebarItemHeader}
										onClick={() => {
											history.push(`/auth/edit/${e.encodedKey}/mechanic/form`);
										}}
									>
										<GrFormEdit style={{ fontSize: 20, marginRight: 20 }} />

										<div>Edit Shop</div>

										<div style={{ marginRight: 12 }} />
									</div>

									<Spacing vertical={10} />

									<div style={{ display: "flex", flexDirection: "column" }}>
										<div
											className="instantService"
											style={{
												paddingInline: 20,
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<div
												style={{
													display: "flex",
												}}
											>
												<div className=" instantService">
													<GoTools style={{ fontSize: 15, marginRight: 20 }} />
												</div>

												<div className="edit-sub instantsub">
													<p className="instantLearn">
														Instant Service
														<span
															className="learnMoreInstantBtn"
															onClick={() =>
																setDashboard({
																	...dashboard,
																	instantNotice: true,
																})
															}
														>
															Learn More {e.instant_service}
														</span>
													</p>
												</div>
											</div>

											<div className="list-icon instantToggle">
												<input
													type="checkbox"
													id={`switch-${e.encodedKey}`}
													onChange={(evt) => {
														toggleInstantMechanic(
															e.encodedKey,
															evt.target.checked
														);
													}}
													defaultChecked={e.instant_service === "yes"}
													checked={e.instant_service === "yes"}
												/>
												<label htmlFor={`switch-${e.encodedKey}`}>Toggle</label>
											</div>
										</div>

										<div
											className="instantService"
											style={{
												paddingInline: 20,
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<div
												style={{
													display: "flex",
												}}
											>
												<div className=" instantService">
													<GoTools style={{ fontSize: 15, marginRight: 20 }} />
												</div>

												<div className="edit-sub instantsub">
													<p className="instantLearn">
														Mobile Service
														<span
															className="learnMoreInstantBtn"
															onClick={() =>
																setDashboard({
																	...dashboard,
																	mobileNotice: true,
																})
															}
														>
															Learn More
														</span>
													</p>
												</div>
											</div>

											<div className="list-icon instantToggle">
												<input
													type="checkbox"
													id={`switch2-${e.encodedKey}`}
													onChange={(evt) => {
														toggleMobileMechanic(
															e.encodedKey,
															evt.target.checked
														);
													}}
													defaultChecked={e.mobile_service === "yes"}
													checked={e.mobile_service === "yes"}
												/>

												<label htmlFor={`switch2-${e.encodedKey}`}>
													Toggle
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}

					{!_mobile&&<ul className="service-dash">
						<li id="list-item" style={_x().sell}>
							<div className="list-icon">
								<RiNewspaperLine
									style={{ fontSize: "20px", marginRight: "0.5rem" }}
								/>
							</div>

							{dashboard.isDealer ? (
								<div className="drop-btn" onClick={openPS}>
									<p>Sell</p>
								</div>
							) : (
								<p onClick={becomeDealer}> Become a Seller</p>
							)}
						</li>

						<li
							id="list-item"
							className="sub-menu-item"
							style={{ ..._x().sell, paddingTop: 20, boxSizing: "border-box" }}
						>
							{dashboard.isDealer && showParts && (
								<>
									<div className="list-icon">
										<RiEditCircleFill
											style={{ fontSize: "20px", marginRight: "0.5rem" }}
										/>
									</div>

									<div
										className="edit-sub"
										onClick={() => history.push(`/home/update-dealer-details`)}
									>
										Edit Seller Shop
									</div>
								</>
							)}
						</li>
					</ul>}

					{_mobile&&dashboard.isDealer&& 
					<SparePardDealerCardMobileView
					onClick={()=>_mobile&&history.push("/home/user-spare-parts/mobile")}
					title="Spare Parts" 
					descriptionIcon={require('../../../Resources/Icons/spare-part-dealer-icon.svg').default}/>}

					<div
						className="btn-dash-col"
						style={{
							padding: _mobile ? 0 : "0.5rem",
						}}
					>
						<p
							className="ad-btn-dash"
							style={
								_mobile
									? {
											width: "100%",
											boxSizing: "border-box",
									  }
									: {}
							}
							onClick={regularUserAdvert}
						>
							Post an Ad
						</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div style={_x(_mobile).dboard}>
			<div style={_x(_mobile).main}>
				{getSidebar()}

				{!_mobile && (
					<div style={_x().right}>
						<div style={_x().r_inner}>
							<ShopDetails
								currentShop={currentShop}
								mobileNoticeOpen={dashboard.mobileNotice}
								instantNoticeOpen={dashboard.instantNotice}
								setAllShops={setAllShops}
								showCalendar={dashboard.showCalendar}
								showPartsShop={dashboard.showPartsShop}
							/>
						</div>
					</div>
				)}
			</div>

			{/* {dashboard.instantNotice && (
			
			)} */}

			{/* <Modal open={dashboard.showModal} style={{ border: "none" }}>
				<ShopDetails
					currentShop={currentShop}
					openCalendar={openCalendar}
					dashboard={dashboard}
					setDashboard={setDashboard}
				/>
			</Modal> */}
		</div>
	);
}

const _x = (_mobile) => ({
	dboard: {
		width: "100%",
		display: "grid",
		height: _mobile ? "100%" : "100vh",
		background: _mobile ? "white" : "#EDEDED",
		placeItems: "center",
		padding: 30,
		paddingInline: 0,
	},
	main: {
		width: _mobile ? "90%" : "80%",
		maxWidth: 1200,
		height: "100%",
		display: _mobile ? "block" : "grid",
		gridTemplateColumns: "auto 1fr",
		gap: 50,
	},
	left: {
		width: _mobile ? "100%" : 230,
		height: "100%",
		placeItems: "center",
	},
	right: {
		// display: "grid",
		// background: "white",
		borderRadius: 10,
	},
	r1: {
		paddingTop: 20,
		paddingBottom: 10,
		display: "grid",
		placeItems: "center",
		background: "white",
		width: "100%",
		borderRadius: 10,
	},
	sidebarItem: {
		backgroundColor: "white",
		borderRadius: 10,
		marginBottom: 20,
		border: _mobile ? "1px solid lightgrey" : "none",
	},
	sidebarItemHeader: {
		paddingInline: 20,
		height: 50,
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	},
	sidebarItemBody: {},
	r_inner: {
		background: "white",
		borderRadius: 10,
	},
	sell: {
		display: "flex",
		paddingInline: 20,
		alignItems: "center",
		cursor: "pointer",
	},
});
