import React from "react";

export function Margin({ all, children, left, top, right, bottom }) {
	return (
		<div
			style={{
				marginTop: all ? all : top,
				marginBottom: all ? all : bottom,
				marginLeft: all ? all : left,
				marginRight: all ? all : right,
			}}
		>
			{children}
		</div>
	);
}

export function Spacing({ vertical, horizontal }) {
	return (
		<div
			style={{
				height: vertical ?? 20,
				width: horizontal ?? 20,
			}}
		/>
	);
}

export function Hr({ height, width, color, thickness }) {
	return (
		<hr
			style={{
				marginTop: height ?? 20,
				marginBottom: height ?? 20,
				border: `${thickness ?? "1px"} solid ${color ?? "var(--secondary3)"}`,
				background: `${color ?? "lightgrey"}`,
				width: width ?? "100%",
			}}
		/>
	);
}

export function Align({ verticalHeight, children }) {
	return (
		<div
			style={{
				display: "grid",
				justifyContent: "center",
				paddingTop: verticalHeight ?? 100,
				paddingBottom: verticalHeight ?? 100,
			}}
		>
			{children}
		</div>
	);
}
