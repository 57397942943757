import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import AuthService from "src/services/auth.service";
import NewPartsDealerStepsForm from "./NewPartsDealerStepsForm";
import "./partsdealer.css";

const PartsDealerReg = () => {
	let history = useHistory();
	const [form, setForm] = useState(false);
	const [ad, setAd] = useState(false);
	const [isloading, setisloading] = useState(true);
	const [progress, setProgress] = useState(0);

	const currentUser = AuthService.getCurrentUser("user");

	async function grabLoggedInUser() {
		setisloading(true);

		setProgress(98);

		const resp = await AuthService.getCurrentLoggedInUser();
		console.log(resp);
		if (resp.success === true && resp.data.is_service_provider) {
			history.push("/home/submit-an-ad/");
			setForm(false);
			setProgress(100);
			setisloading(false);
		} else {
			setAd(false);
			setForm(true);
			setProgress(100);
			setisloading(false);
		}
	}

	useEffect(() => {
		grabLoggedInUser();
	}, []);

	return (
		<>
			{!isloading && <NewPartsDealerStepsForm />}
			{isloading && (
				<LoadingBar
					color="#587FC0"
					loaderSpeed={13000}
					progress={progress}
					onLoaderFinished={() => setProgress(0)}
				/>
			)}
		</>
	);
};

export default PartsDealerReg;
