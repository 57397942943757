import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import fbIcon from '../../../Resources/Icons/facebook.png';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const FacebookSignIn = () => {
  let history = useHistory();
  const [email, setEmail] = useState('');
  const [provider, setProvider] = useState('');
  const [provider_id, setProvider_id] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');

  const API_URLL = API_URL + 'users/create-with-social';

  const responseFacebook = (res) => {
    const newData = res;
    // console.log(res);

    setEmail(newData.email);
    setFirst_name(newData.first_name);
    setLast_name(newData.last_name);
    setProvider(newData.graphDomain);
    setProvider_id(newData.userID);

    axios
      .post(API_URLL, {
        email: email,
        provider: provider,
        provider_id: provider_id,
        first_name: first_name,
        last_name: last_name,
      })
      .then((response) => {
        console.log(response);

        if (response.data.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
          history.push('/home');
          setTimeout(function () {
            localStorage.clear();

            window.location = '/auth/login';
          }, 30 * 60 * 1000);
        }
        // return response.data;
      });
  };

  return (
    <div style={{ width: '100%' }}>
      <FacebookLogin
        appId="493316405246339"
        // autoLoad ={false}
        fields="first_name, last_name, email, picture"
        callback={responseFacebook}
        render={(renderProps) => (
          <button className="social-btn fb-type" onClick={renderProps.onClick}>
            <img src={fbIcon} alt="facebook" />
            <div className="">Sign in with facebook</div>
          </button>
        )}
      />
    </div>
  );
};

export default FacebookSignIn;
