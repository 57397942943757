import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import "../../../../Components/Style/Style.css";
import { Spacing } from "../../../../Components/Style/Styles";

function Comment({
	id,
	name,
	profile,
	description,
	date,
	photos,
	experience,
	headline,
	helpful,
	reportAbuse,
	userFoundHelpful,
	allThatFoundHelpful,
}) {
	const _mobile = useMediaQuery("(max-width:800px)");

	useEffect(() => {
		axios.get(profile).then((resp) => {
			console.log(resp);
		});
	}, []);

	function foundHelpful() {
		if (!userFoundHelpful) {
			helpful?.(id);
		}
	}

	return (
		<div style={_x().comment}>
			<div style={_x(_mobile).avatar_box}>
				<div style={{ display: "flex" }}>
					{profile === "" ? (
						<FaUserCircle
							size={35}
							style={{ color: "#2076d1", marginRight: 5 }}
						/>
					) : (
						<img src={profile} alt="avatar" style={_x().avatar} />
					)}

					<Spacing />

					<p style={_x().name}>{name}</p>
				</div>

				<p style={_x().date}>Reviewed on {date}</p>
			</div>

			<Spacing vertical={10} />

			<div style={{ display: "flex" }}>
				{photos &&
					photos?.map((e) => (
						<div key={e} style={_x().image_box}>
							<img src={e} alt="" style={{ height: 100, width: 100 }} />
						</div>
					))}
			</div>

			<p style={_x().headline}>{headline}</p>

			<p style={_x().main_comment}>{description}</p>

			<Spacing vertical={5} />

			{allThatFoundHelpful?.length >= 1 && (
				<div>
					<p style={_x().helpful_people}>
						{allThatFoundHelpful?.length}
						{allThatFoundHelpful?.length > 1 ? " people " : " person "}
						found this helpful
					</p>
				</div>
			)}

			<Spacing vertical={10} />

			<div style={{ display: "flex" }}>
				{userFoundHelpful === false ? (
					<button onClick={foundHelpful} style={_x().helpful}>
						Helpful
					</button>
				) : userFoundHelpful === null ? (
					<p style={_x().tipBtn}>Sending Feedback...</p>
				) : (
					<p style={_x().tipBtn}>
						<span style={_x().tipBtn_span}>
							<TiTick />
						</span>
						Thanks for your Feedback
					</p>
				)}

				<Spacing />

				<button
					id="abuse-btn"
					style={_x().abuse}
					onClick={() => reportAbuse(headline, description)}
				>
					Report Abuse
				</button>
			</div>
		</div>
	);
}

export default Comment;

const _x = (_mobile) => ({
	comment: {
		marginBottom: 50,
	},

	headline: {
		lineHeight: 2,
		fontWeight: 450,
	},

	main_comment: {
		lineHeight: 2,
	},

	avatar_box: {
		display: "flex",
		flexDirection: _mobile ? "column" : "row",
		justifyContent: "space-between",
	},

	avatar: {
		height: 40,
		width: 40,
		borderRadius: 40,
	},

	name: {
		color: "var(--secondary2)",
		textSize: 16,
		fontWeight: "bold",
	},

	image_box: {
		height: 100,
		width: 100,
		marginRight: 11,
		marginBottom: 10,
		position: "relative",
	},

	helpful_people: {
		color: "var(--secondary2)",
	},

	helpful: {
		background: "var(--accent_opacity)",
		// opacity: 0.5,
		color: "var(--primary1)",
		height: 28,
		width: 81,
		border: "1px solid var(--primary1)",
		fontSize: 14,
		borderRadius: 15,
	},

	abuse: {
		borderRadius: 15,
		// border: "1px solid var(--primary1)",
		paddingLeft: 10,
		paddingRight: 10,
	},

	date: {
		color: "var(--primary1)",
		// textSize: 16,
		// fontWeight: 500,
	},
	tipBtn: {
		textAlign: "center",
		// padding: 7,
		borderRadius: 2,
		fontSize: 14,
		fontWeight: 500,
		color: "#587FC0",
		display: "flex",
		// alignItems: "center",
		// background: "red",
	},

	tipBtn_span: {
		backgroundColor: "#587FC0",
		color: "#fff",
		width: 20,
		height: 20,
		borderRadius: 30,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: 19,
		marginRight: 7,
	},
});
