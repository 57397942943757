import { useEffect, useState } from "react";
import AuthService from "src/services/auth.service";
import "./dp.css";

const ProfileDP = ({ style, size }) => {
	const [imageDp, setImageDp] = useState(false);
	const currentUser = AuthService.getCurrentUser("user");

	const fName = currentUser.data?.user_info?.first_name;
	const lName = currentUser.data?.user_info?.last_name;
	const dp = currentUser.data?.user_info?.profile_image;

	useEffect(() => {
		if (dp === "") {
			setImageDp(true);
			// console.log("available")
		}
	}, []);

	let firstChar = fName?.charAt(0);
	let lastChar = lName?.charAt(0);

	return (
		<main className="dp-main" style={style}>
			{imageDp ? (
				<p className="dp-inner" style={size}>
					{firstChar}
				</p>
			) : (
				<img
					src={currentUser.data?.user_info?.profile_image}
					alt=""
					style={{ objectFit: "cover", width: "100%", height: "100%" }}
				/>
			)}
		</main>
	);
};

export default ProfileDP;
