import React from 'react';

const SwitchToAppButton = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const appUrl = isIOS
    ? 'https://apps.apple.com/ng/app/quickmechs/id6443885659'
    : 'https://play.google.com/store/apps/details?id=com.quickmechs';
  const schemeUrl = 'Quickmechs://';

  const handleButtonClick = () => {
    window.location.href = appUrl;

    setTimeout(() => {
      window.location.href = schemeUrl;
    }, 1000);
  };

  return (
    <button className="take-me-to-app" onClick={handleButtonClick}>
      Switch to App
    </button>
  );
};

export default SwitchToAppButton;
