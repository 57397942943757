import React from "react";
import "./newpartssteps.css";
import { RiDeleteBin5Line } from "react-icons/ri";
function ShPhotoWrapEdit({ onImageRemove, index, image, onClickDelete }) {
  return (
    <div key={index} className="sh_photo_wrap">
      <img
        src={Array.isArray(image) ? image["data_url"] : image}
        alt="quickmechs-product-image"
        width="50"
        height="50"
      />
      <div
        className="st_delete"
        onClick={
          !Array.isArray(image) ? onClickDelete : () => onImageRemove(index)
        }
      >
        <RiDeleteBin5Line />
      </div>

      {/*
                  </div> */}
    </div>
  );
}

export default ShPhotoWrapEdit;
