import React, { useState, useRef, useEffect } from 'react';
import { states } from 'src/Resources/Resources';
import { authHeader } from '../../../services/auth-header';
import { useHistory } from 'react-router-dom';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import Select from 'react-validation/build/select';
import axios from 'axios';
import './address.css';
import Autocomplete from 'react-google-autocomplete';

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_DEVELOPMENT_URL,
  REACT_APP_PRODUCTION_URL,
} = process.env;

const API_URL =
  REACT_APP_CURRENT_ENVIRONMENT === 'DEVELOPMENT'
    ? REACT_APP_DEVELOPMENT_URL
    : REACT_APP_PRODUCTION_URL;

const required = (value) => {
  if (!value) {
    return (
      <div className="warninng" role="alert">
        &#9432; This field is required!
      </div>
    );
  }
};

const Address = () => {
  const form = useRef();
  const checkBtn = useRef();

  let history = useHistory();

  const [shoplocation, setshoplocation] = useState('');
  const [locationError, setlocationError] = useState(false);
  const [resErrorState, setresErrorState] = useState(true);
  const [resError, setresError] = useState('');

  const [formm, setFormm] = useState({
    phone_number: '',
    address: '',
    state: 'Select State',
    city: '',
  });

  const changeHandle = (e) => {
    setFormm({
      ...formm,
      [e.target.name]: e.target.value,
    });
  };

  function selectDropDown(e, field) {
    const { value } = e.target;

    if (field === 'state') {
      if (value === '') {
      } else setFormm({ ...formm, state: value });
    }
  }

  const submitAddress = (e) => {
    e.preventDefault();

    form.current.validateAll();

    console.log(formm);

    if (shoplocation === '') {
      setlocationError(true);
    } else {
      setlocationError(false);
    }

    if (checkBtn.current.context._errors.length === 0 && shoplocation !== '') {
      axios
        .patch(API_URL + 'profile/update', formm, {
          headers: authHeader(),
        })
        .then((response) => {
          const newAddress = formm.address;
          const newPhone = formm.phone_number;
          const newState = formm.state;
          const newCity = formm.city;

          let oldData = JSON.parse(localStorage.getItem('user'));
          oldData.data.user_info.address = newAddress;
          oldData.data.user_info.phone_number = newPhone;
          oldData.data.user_info.state = newState;
          oldData.data.user_info.city = newCity;
          localStorage.setItem('user', JSON.stringify(oldData));

          // window.location.reload();
          history.push('/home/mechanic-and-services/');
        })
        .catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          const gg = error.response.data.errorSource;
          setresError(gg.phone_number && gg.phone_number[0]);
        });
    }
  };

  const updateLocation = () => {
    setFormm({
      ...formm,
      address: shoplocation,
    });
    if (locationError) {
      setlocationError(false);
    }
  };

  useEffect(() => {
    updateLocation();
  }, [shoplocation]);

  return (
    <>
      <div className="address-banner"></div>
      <main className="address-google-tab">
        <p>Enter your contact details</p>
        <Form className="address-formm" ref={form} onSubmit={submitAddress}>
          <label htmlFor="phone">
            {' '}
            Phone Number
            <Input
              type="number"
              name="phone_number"
              id="ad-input"
              placeholder="Enter your Phone number"
              onChange={changeHandle}
              value={formm.phone_number}
              validations={[required]}
              onKeyPress={(e) => {
                if (e.key === 'e' || e.key === '-') {
                  e.preventDefault();
                }
              }}
            />
          </label>
          <label htmlFor="address">
            {' '}
            Address
            <Autocomplete
              className="autolocation"
              onPlaceSelected={(place) => {
                const selectedAddress = place.formatted_address;
                setshoplocation(selectedAddress);
              }}
              onChange={updateLocation}
              options={{
                types: ['establishment', 'geocode'],
              }}
            />
          </label>
          {locationError && (
            <div style={{ color: 'red', fontSize: '11px', marginTop: '-15px' }}>
              Address is required.
            </div>
          )}
          {resErrorState && (
            <div style={{ color: 'red', fontSize: '11px', marginTop: '-15px' }}>
              {resError}
            </div>
          )}

          <button className="address-submit">Continue</button>
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
      </main>
    </>
  );
};

export default Address;
